import React from 'react'

import { useWorkorderState, WorkorderContext } from '../../../contexts'
import { OrganizationJobBoardHome } from './OrganizationJobBoardHome'

export const OrganizationJobBoardRoot = () => {
    const workorderState = useWorkorderState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <OrganizationJobBoardHome />
        </WorkorderContext.Provider>
    )
}
