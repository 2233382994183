import React, { useState } from 'react'
import { BaseProps } from '../../types'
import { CalendarGridController } from '../../hooks'
import { DAY_CELL_W, HEADER_H } from '../../constants'
import { Container } from '../../../Container'
import { setMidnight, toMMDD } from '../../../../helpers'
import { usePopover } from '../../../../hooks/usePopover'
import { HeaderPopover } from './HeaderPopover'
import { DayOff, Schedule } from '../../../../models'

interface Props extends BaseProps {
    calendarGridState: CalendarGridController
    handleChooseTransferDate: (clickX: number) => void
}

export const CalendarGrid = (props: Props) => {
    const { calendarGridState, theme } = props

    const schedule = props.scheduleController.schedule

    if (schedule === null) {
        return null
    }

    const {
        convertGridPositionToDate,
        getClickedGridPosition,
        gridPositionInfo,
        dateInfo,
        getDateGridPosition,
        gridRef,
    } = calendarGridState

    const today = new Date()
    setMidnight(today)
    const todayMs = today.getTime()

    const scheduleStartDate = new Date(
        schedule.work_start_date || schedule.start_date,
    )
    const scheduleEndDate = new Date(
        schedule.work_end_date || schedule.end_date,
    )
    setMidnight(scheduleStartDate)
    setMidnight(scheduleEndDate)
    const schStartMs = scheduleStartDate.getTime()
    const schEndMs = scheduleEndDate.getTime()

    const popoverState = usePopover()
    const [selectedDate, setSelecteDate] = useState(new Date())

    const startDateMs = dateInfo.projectStart.getTime()
    const endDateMs = dateInfo.projectEnd.getTime()

    const dateLabels: JSX.Element[] = []
    const grid: JSX.Element[] = []
    const dayOffColumns: JSX.Element[] = []

    const daysOff = schedule?.day_off_list ?? []

    daysOff.forEach((dayOff) => {
        if (dayOff.schedule === null) return

        const dayOffDate = new Date(dayOff.date)
        setMidnight(dayOffDate)

        const dayOffMS = dayOffDate.getTime()

        dayOffColumns.push(
            <div
                key={`DAY-OFF-COL-${dayOff.id}-${dayOffMS}`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: getDateGridPosition(dayOffDate) * DAY_CELL_W,
                    bottom: 0,
                    width: DAY_CELL_W,
                    boxSizing: 'border-box',
                    backgroundColor: 'rgba(30, 30, 30, 0.2)',
                    borderRadius: theme.shape.borderRadius,
                    zIndex: theme.zIndex.modal + 1,
                    pointerEvents: 'none',
                }}
            />,
        )
    })

    for (let i = 0; i < gridPositionInfo.numDisplayDays; i++) {
        const currentDate = convertGridPositionToDate(i)
        const currentDateMs = currentDate.getTime()

        const dateLabel = toMMDD(currentDate)
        let dayLabel: string | JSX.Element = weekday[currentDate.getDay()]
        if (currentDateMs === startDateMs) {
            dayLabel = '(Start)'
        } else if (currentDateMs === endDateMs) {
            dayLabel = '(End)'
        }

        if (currentDateMs === todayMs) {
            dayLabel = (
                <div
                    style={{
                        color: 'white',
                        backgroundColor: '#016fb9',
                        border: theme.shape.borderRadius,
                        borderRadius: theme.shape.borderRadius,
                        padding: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {dayLabel}
                </div>
            )
        }

        let backgroundColor = 'white'
        if (i % 2 === 0) {
            backgroundColor = theme.palette.grey[50]
        }

        if (currentDateMs < startDateMs || currentDateMs > endDateMs) {
            backgroundColor = 'white'
        }

        if (currentDateMs === schStartMs || currentDateMs === schEndMs) {
            backgroundColor = 'rgba(148, 178, 255, 0.3)'
        }

        if (currentDateMs === startDateMs || currentDateMs === endDateMs) {
            backgroundColor = 'rgba(216, 255, 234, 0.9)'
        }

        dateLabels.push(
            <DateLabel
                key={`${dateLabel}`}
                {...props}
                dayLabel={dayLabel}
                dateLabel={dateLabel}
                onClick={(e) => {
                    if (gridRef.current === null) return

                    const gridPos = getClickedGridPosition(e.pageX)
                    const clickedDate = convertGridPositionToDate(gridPos)

                    setSelecteDate(clickedDate)
                    popoverState.handleOpen(e)
                }}
            />,
        )

        grid.push(
            <div
                key={`GRID-COL-${i}`}
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    left: i * DAY_CELL_W,
                    bottom: 0,
                    width: DAY_CELL_W,
                    borderLeft: `1px solid ${theme.palette.grey[200]}`,
                    boxSizing: 'border-box',
                    backgroundColor: backgroundColor,
                }}
            />,
        )
    }

    const transferDate = schedule?.transfer_date

    let transferDateColumn: JSX.Element | null = null
    if (transferDate) {
        const td = new Date(transferDate)
        transferDateColumn = (
            <div
                onClick={(e) => {
                    e.bubbles = true
                }}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: getDateGridPosition(td) * DAY_CELL_W,
                    bottom: 0,
                    width: DAY_CELL_W,
                    boxSizing: 'border-box',
                    backgroundColor: 'rgba(86, 210, 254, 0.2)',
                    border: `3px solid rgba(86, 210, 254)`,
                    borderRadius: theme.shape.borderRadius,
                    zIndex: theme.zIndex.modal + 1,
                    pointerEvents: 'none',
                }}
            />
        )
    }

    return (
        <>
            <Container
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    height: HEADER_H,
                    width: 'max-content',
                    padding: 4,
                    zIndex: theme.zIndex.modal + 1,
                }}
            >
                <Container>{dateLabels}</Container>

                <HeaderPopover
                    {...props}
                    popoverState={popoverState}
                    selectedDate={selectedDate}
                />
            </Container>

            {grid}
            {transferDateColumn}
            {dayOffColumns}
        </>
    )
}

const weekday = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

interface DateLabelProps extends BaseProps {
    dateLabel: string
    dayLabel: string | JSX.Element
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const DateLabel = (props: DateLabelProps) => {
    const { dateLabel, theme, dayLabel } = props
    return (
        <Container
            style={{
                flexDirection: 'column',
                minWidth: DAY_CELL_W,
                maxWidth: DAY_CELL_W,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
        >
            <span
                style={{
                    ...theme.typography.subtitle1,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {dateLabel}
            </span>
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightLight,
                }}
            >
                {dayLabel}
            </span>
        </Container>
    )
}
