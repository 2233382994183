import React, { useState, useRef, useMemo } from 'react'

import {
    Typography,
    IconButton,
    List,
    Button,
    Theme,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Collapse,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { Container, Selector } from '../../components'
import {
    convertListToMap,
    InspectionType,
    ListVendor,
    MoveOutRule,
    MoveOutStep,
    Service,
} from '../../models'
import {
    CreateOrUpdateMoveOutStepRequest,
    CreateOrUpdateMoveOutVendorRuleRequest,
} from '../../hooks/useMoveOutRules'
import { MoveOutVendorRuleList } from './MoveOutVendorRuleList'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    stepListContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        padding: theme.spacing(2),
    },
    stepList: {
        width: '100%',
    },
    stepItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            transform: 'translateY(-2px)',
        },
    },
    stepNumber: {
        marginRight: theme.spacing(2),
        minWidth: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    stepContent: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    stepInfo: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
    stepName: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
    },
    stepType: {
        color: theme.palette.text.secondary,
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0.5),
    },
    stepTypeIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem',
    },
    dragHandle: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
        cursor: 'move',
    },
    deleteButton: {
        padding: theme.spacing(1),
    },
    dragging: {
        opacity: 0.6,
        transform: 'scale(1.05)',
    },
    addStepForm: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(1),
    },
    expandButton: {
        marginLeft: 'auto',
    },
    vendorRuleList: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
    },
}))

interface Props {
    selectedMoveOutRule: MoveOutRule
    theme: Theme
    serviceList: Service[]
    inspectionTypeList: InspectionType[]
    vendorList: ListVendor[]
    onBack: () => void
    setSelectedMoveOutRule: React.Dispatch<
        React.SetStateAction<MoveOutRule | null>
    >
    createOrUpdateMoveOutStep: (
        request: CreateOrUpdateMoveOutStepRequest,
    ) => Promise<MoveOutRule | null>
    deleteMoveOutStep: (id: number) => Promise<MoveOutRule | null>
    createOrUpdateVendorRule: (
        request: CreateOrUpdateMoveOutVendorRuleRequest,
    ) => Promise<void>
    onDeleteVendorRule: (vendorRuleId: number) => Promise<void>
}

type StepType = 'service' | 'inspection'

export const MoveOutRuleDetail = (props: Props) => {
    const {
        selectedMoveOutRule,
        theme,
        serviceList,
        inspectionTypeList,
        setSelectedMoveOutRule,
        onBack,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        createOrUpdateVendorRule,
    } = props
    const classes = useStyles()

    const [newStepType, setNewStepType] = useState<StepType>('service')
    const [draggedStep, setDraggedStep] = useState<MoveOutStep | null>(null)
    const [selectedServiceId, setSelectedServiceId] = useState(-1)
    const [selectedInspectionType, setSelectedInspectionType] = useState(-1)
    const [expandedSteps, setExpandedSteps] = useState<number[]>([])

    const dragItem = useRef<number | null>(null)
    const dragOverItem = useRef<number | null>(null)

    const inspectionTypeMap = convertListToMap(inspectionTypeList)
    const serviceMap = convertListToMap(serviceList)

    const availableServices = useMemo(
        () =>
            serviceList.filter(
                (service) =>
                    !selectedMoveOutRule.steps.some(
                        (step) => step.service === service.id,
                    ),
            ),
        [serviceList, selectedMoveOutRule.steps],
    )

    const handleAddStep = async () => {
        const body: CreateOrUpdateMoveOutStepRequest = {
            move_out_rule: selectedMoveOutRule.id,
        }

        if (newStepType === 'service' && selectedServiceId !== -1) {
            body.service = selectedServiceId
        } else if (
            newStepType === 'inspection' &&
            selectedInspectionType !== -1
        ) {
            body.inspection_type = selectedInspectionType
        } else {
            return
        }

        createOrUpdateMoveOutStep(body).then((updatedRule) =>
            setSelectedMoveOutRule(updatedRule),
        )
    }

    const handleDeleteStep = async (stepId: number) => {
        deleteMoveOutStep(stepId).then((updatedRule) =>
            setSelectedMoveOutRule(updatedRule),
        )
    }

    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        index: number,
    ) => {
        dragItem.current = index
        setDraggedStep(selectedMoveOutRule.steps[index])
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', e.currentTarget.outerHTML)
        e.currentTarget.classList.add(classes.dragging)
    }

    const handleDragEnter = (
        e: React.DragEvent<HTMLDivElement>,
        index: number,
    ) => {
        dragOverItem.current = index
        e.preventDefault()
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }

    const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.classList.remove(classes.dragging)
        const startIndex = dragItem.current
        const endIndex = dragOverItem.current

        if (
            startIndex !== null &&
            endIndex !== null &&
            startIndex !== endIndex
        ) {
            const newSteps = [...selectedMoveOutRule.steps]
            const [reorderedItem] = newSteps.splice(startIndex, 1)
            newSteps.splice(endIndex, 0, reorderedItem)

            const updatedSteps = newSteps.map((step, index) => ({
                ...step,
                rank: index + 1,
            }))

            const ruleBeforeUpdate = { ...selectedMoveOutRule }

            setSelectedMoveOutRule({
                ...selectedMoveOutRule,
                steps: updatedSteps,
            })

            const body: CreateOrUpdateMoveOutStepRequest = {
                move_out_rule: selectedMoveOutRule.id,
                rank: endIndex + 1,
            }
            if (draggedStep?.service) {
                body.service = draggedStep.service
            } else if (draggedStep?.inspection_type) {
                body.inspection_type = draggedStep.inspection_type
            }

            createOrUpdateMoveOutStep(body).then((updatedRule) => {
                if (updatedRule) {
                    setSelectedMoveOutRule(updatedRule)
                } else {
                    setSelectedMoveOutRule(ruleBeforeUpdate)
                }
            })
        }

        dragItem.current = null
        dragOverItem.current = null
        setDraggedStep(null)
    }

    const toggleStepExpansion = (stepId: number) => {
        setExpandedSteps((prev) =>
            prev.includes(stepId)
                ? prev.filter((id) => id !== stepId)
                : [...prev, stepId],
        )
    }

    return (
        <Container className={classes.root}>
            <Container className={classes.header}>
                <Container
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <IconButton onClick={onBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {selectedMoveOutRule.name}
                    </Typography>
                </Container>
            </Container>
            <Container className={classes.stepListContainer}>
                {selectedMoveOutRule.steps.length === 0 ? (
                    <Typography
                        variant="body1"
                        align="center"
                        style={{ padding: theme.spacing(2) }}
                    >
                        This rule has no steps. Add a step below to get started.
                    </Typography>
                ) : (
                    <List className={classes.stepList}>
                        {selectedMoveOutRule.steps.map((step, index) => {
                            let displayName = ''
                            let stepType = ''
                            if (step.service) {
                                const service = serviceMap[step.service]
                                displayName = service
                                    ? service.name
                                    : `Unknown Service (${step.service})`
                                stepType = 'Service'
                            } else if (step.inspection_type) {
                                const inspectionType =
                                    inspectionTypeMap[step.inspection_type]
                                displayName = inspectionType
                                    ? inspectionType.name
                                    : `Unknown Inspection Type (${step.inspection_type})`
                                stepType = 'Inspection Type'
                            }

                            return (
                                <Paper
                                    key={step.id}
                                    className={`${classes.stepItem} ${
                                        step === draggedStep
                                            ? classes.dragging
                                            : ''
                                    }`}
                                    draggable
                                    onDragStart={(e) =>
                                        handleDragStart(e, index)
                                    }
                                    onDragEnter={(e) =>
                                        handleDragEnter(e, index)
                                    }
                                    onDragOver={handleDragOver}
                                    onDragEnd={handleDragEnd}
                                >
                                    <div className={classes.stepContent}>
                                        <Typography
                                            className={classes.stepNumber}
                                        >
                                            {index + 1}
                                        </Typography>
                                        <DragIndicatorIcon
                                            className={classes.dragHandle}
                                        />
                                        <div className={classes.stepInfo}>
                                            <Typography
                                                className={classes.stepName}
                                            >
                                                {displayName}
                                            </Typography>
                                            <Typography
                                                className={classes.stepType}
                                            >
                                                {stepType}
                                            </Typography>
                                        </div>
                                        {step.service && (
                                            <>
                                                <Typography
                                                    className={classes.stepType}
                                                >
                                                    {step.vendor_rules.length}{' '}
                                                    vendor rules
                                                </Typography>
                                                <IconButton
                                                    className={
                                                        classes.expandButton
                                                    }
                                                    onClick={() =>
                                                        toggleStepExpansion(
                                                            step.id,
                                                        )
                                                    }
                                                >
                                                    {expandedSteps.includes(
                                                        step.id,
                                                    ) ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </IconButton>
                                            </>
                                        )}
                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={() =>
                                                handleDeleteStep(step.id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                    {step.service && (
                                        <Collapse
                                            in={expandedSteps.includes(step.id)}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <div
                                                className={
                                                    classes.vendorRuleList
                                                }
                                            >
                                                <MoveOutVendorRuleList
                                                    moveOutStepId={step.id}
                                                    vendorRules={
                                                        step.vendor_rules
                                                    }
                                                    vendors={props.vendorList}
                                                    currentServiceId={
                                                        step.service
                                                    }
                                                    createOrUpdateVendorRule={
                                                        createOrUpdateVendorRule
                                                    }
                                                    onDeleteVendorRule={
                                                        props.onDeleteVendorRule
                                                    }
                                                />
                                            </div>
                                        </Collapse>
                                    )}
                                </Paper>
                            )
                        })}
                    </List>
                )}
            </Container>
            <Container
                className={classes.addStepForm}
                style={{ padding: theme.spacing(2) }}
            >
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel id="step-type-label">Step Type</InputLabel>
                    <Select
                        labelId="step-type-label"
                        value={newStepType}
                        onChange={(e) => {
                            setNewStepType(e.target.value as StepType)
                        }}
                        label="Step Type"
                    >
                        <MenuItem value="service">Service</MenuItem>
                        <MenuItem value="inspection">Inspection Type</MenuItem>
                    </Select>
                </FormControl>
                {newStepType === 'service' ? (
                    <Selector
                        label="Service"
                        currentValue={selectedServiceId || ''}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedServiceId(event.target.value as number)
                        }}
                        data={availableServices}
                        getDisplayString={(s: Service) => s.name}
                        size="small"
                        customStyle={{
                            formControl: {
                                flexGrow: 1,
                            },
                        }}
                    />
                ) : (
                    <Selector
                        label="Inspection Type"
                        currentValue={selectedInspectionType || ''}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedInspectionType(
                                event.target.value as number,
                            )
                        }}
                        data={inspectionTypeList}
                        getDisplayString={(insType: InspectionType) =>
                            insType.name
                        }
                        size="small"
                        customStyle={{
                            formControl: {
                                flexGrow: 1,
                            },
                        }}
                    />
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddStep}
                    style={{ marginLeft: theme.spacing(1) }}
                >
                    Add Step
                </Button>
            </Container>
        </Container>
    )
}
