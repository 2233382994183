import React from 'react'
import {
    WorkorderAutomationConfig,
    WorkorderFrequencyAutomation,
    WorkorderTriggerAutomation,
} from '../../../models'
import { BaseProps } from '../types'
import { toMMDDYYYY } from '../../../helpers'
import { Container } from '../../Container'
import { getPeriodOptionLabel } from '../../../models/Frequency/services'
import { ReportProblem } from '@material-ui/icons'
import { useStyles } from '../../../styles'
import { Switch } from '@material-ui/core'

interface Props extends BaseProps {
    automationConfig: WorkorderAutomationConfig
    isSelected?: boolean
    onClick?: () => void
    toggleFrequencyActive?: (id: number) => Promise<unknown>
}

export const WorkorderAutomationRow = (props: Props) => {
    const { automationConfig, isSelected, theme, toggleFrequencyActive } = props

    const classes = useStyles()

    let assignedTo = 'My Team'
    if (automationConfig?.assigned_to_user) {
        assignedTo = automationConfig.assigned_to_user?.name
    }
    if (automationConfig?.assigned_to_company) {
        assignedTo = automationConfig.assigned_to_company?.name
    }

    const priorityIcon = automationConfig.priority ? (
        <ReportProblem color="secondary" fontSize="small" />
    ) : null
    const serviceName = automationConfig.service?.name

    const unit = automationConfig.unit

    let automationTypeSection: JSX.Element | null = null
    if (automationConfig.frequency !== null) {
        const automation = automationConfig as WorkorderFrequencyAutomation
        automationTypeSection = (
            <FrequencySection
                automation={automation}
                toggleFrequencyActive={toggleFrequencyActive}
            />
        )
    }

    if (automationConfig.trigger !== null) {
        const automation = automationConfig as WorkorderTriggerAutomation
        automationTypeSection = (
            <TriggerSubscriptionSection automation={automation} />
        )
    }

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer',
                backgroundColor: isSelected
                    ? theme.palette.grey[300]
                    : undefined,
                maxHeight: 100,
                minHeight: 100,
                alignItems: 'center',
                overflow: 'hidden',
            }}
            className={classes.hoverGrey200}
            onClick={props.onClick}
        >
            <span
                style={{
                    ...infoStyle,
                    overflow: 'hidden',
                }}
            >
                {automationConfig.title}
            </span>
            <span style={infoStyle}>{assignedTo}</span>
            <span style={infoStyle}>{serviceName}</span>
            <span style={infoStyle}>{priorityIcon}</span>
            <span style={infoStyle}>{unit?.name}</span>

            {automationTypeSection}
        </Container>
    )
}

interface FrequencySectionProps {
    automation: WorkorderFrequencyAutomation
    toggleFrequencyActive?: (id: number) => Promise<unknown>
}

const FrequencySection = (props: FrequencySectionProps) => {
    const { automation, toggleFrequencyActive } = props

    const frequency = automation.frequency

    const start = new Date(frequency.start_point)
    const startStr = toMMDDYYYY(start)

    return (
        <>
            <span style={infoStyle}>{startStr}</span>
            <span style={infoStyle}>
                {getPeriodOptionLabel(frequency.period)}
            </span>
            {toggleFrequencyActive && (
                <span style={infoStyle}>
                    <Switch
                        color="primary"
                        checked={frequency.active}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            toggleFrequencyActive(automation.id)
                        }}
                    />
                </span>
            )}
        </>
    )
}

interface TriggerSubscriptionSectionProps {
    automation: WorkorderTriggerAutomation
}

const TriggerSubscriptionSection = (props: TriggerSubscriptionSectionProps) => {
    const automation = props.automation
    const trigger = automation.trigger

    let invLabel = 'All'
    if (trigger.inventory_config) {
        invLabel = trigger.inventory_config.name
    }

    return (
        <>
            <span style={infoStyle}>{trigger.trigger_status.name}</span>
            <span style={infoStyle}>{invLabel}</span>
        </>
    )
}

const infoStyle: React.CSSProperties = {
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
}
