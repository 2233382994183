import { useEffect, useState } from 'react'
import {
    InfrastructureFilterOptions,
    LocationSelection,
    PartialFilterMode,
} from '../../store'

type infrastructureFilterOptionsBoolMap = {
    [key in keyof InfrastructureFilterOptions]: boolean
}
export interface filteredAppliedType
    extends infrastructureFilterOptionsBoolMap {
    folder: boolean
    unit: boolean
}

export const useFilterApplied = (
    filter: InfrastructureFilterOptions,
    locationSelection: LocationSelection,
) => {
    const defaultFilterApplied: filteredAppliedType = {
        areaStatusFilter: true,
        assignmentFilter: true,
        serviceFilter: true,
        vendorFilter: true,
        unitConfigFilter: true,
        unitSearch: true,
        unreadFilter: true,
        statusFilter: true,
        inspectionCompletionFilter: true,
        invConfigFilter: true,
        changeOrder: true,
        customStatusFilter: true,
        priorityFilter: true,
        vacantFilter: true,
        isGhost: true,
        handled: true,
        hasCostDriver: true,
        hasDamages: true,
        hasFlaggedItem: true,
        hasMessages: true,
        dateFilter: true,
        behindWorkorders: true,
        manualServiceAreas: true,
        folder: true,
        unit: true,
        readyUnitFilter: true,
        partialUnitFilter: true,
        displayVacantAreas: true,
        keysFilter: true,
        hasGoBack: true,
        serviceCategoryFilter: true,
    }
    const [filterApplied, setFilterApplied] = useState(defaultFilterApplied)

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            areaStatusFilter: false,
        })
    }, [filter.areaStatusFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            readyUnitFilter: false,
        })
    }, [filter.readyUnitFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            assignmentFilter: false,
        })
    }, [filter.assignmentFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            serviceFilter: false,
        })
    }, [filter.serviceFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            vendorFilter: false,
        })
    }, [filter.vendorFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            unitConfigFilter: false,
        })
    }, [filter.unitConfigFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            unitSearch: false,
        })
    }, [filter.unitSearch])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            unreadFilter: false,
        })
    }, [filter.unreadFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            statusFilter: false,
        })
    }, [filter.statusFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            inspectionCompletionFilter: false,
        })
    }, [filter.inspectionCompletionFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            invConfigFilter: false,
        })
    }, [filter.invConfigFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            changeOrder: false,
        })
    }, [filter.changeOrder])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            customStatusFilter: false,
        })
    }, [filter.customStatusFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            priorityFilter: false,
        })
    }, [filter.priorityFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            vacantFilter: false,
        })
    }, [filter.vacantFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            isGhost: false,
        })
    }, [filter.isGhost])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            handled: false,
        })
    }, [filter.handled])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            hasCostDriver: false,
        })
    }, [filter.hasCostDriver])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            hasDamages: false,
        })
    }, [filter.hasDamages])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            hasFlaggedItem: false,
        })
    }, [filter.hasFlaggedItem])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            hasMessages: false,
        })
    }, [filter.hasMessages])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            dateFilter: false,
        })
    }, [filter.dateFilter])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            behindWorkorders: false,
        })
    }, [filter.behindWorkorders])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            manualServiceAreas: false,
        })
    }, [filter.manualServiceAreas])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            folder: false,
        })
    }, [locationSelection.folder.length])

    useEffect(() => {
        setFilterApplied({
            ...filterApplied,
            unit: false,
        })
    }, [locationSelection.unit.length])

    const setAllApplied = () => {
        setFilterApplied(defaultFilterApplied)
    }

    return { filterApplied: filterApplied, setAllApplied: setAllApplied }
}
