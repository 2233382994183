import React, { PropsWithChildren } from 'react'

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import AlarmIcon from '@material-ui/icons/Alarm'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import { ReactComponent as ListSBIcon } from '../../../../../assets/icons/action-item.svg'
import { Tooltip } from '@material-ui/core'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { CalendarEventType } from '../../../types'
import {
    AssignmentIndOutlined,
    BeachAccess,
    EventBusy,
} from '@material-ui/icons'

interface Props extends BaseProps {
    eventType: CalendarEventType
    htmlColor?: string
}

export const EventTypeWrapper = (props: PropsWithChildren<Props>) => {
    const { eventType, theme, htmlColor } = props

    let Icon: any | null = null
    let title = ''

    let eventIconBgColor: string | undefined = 'white'
    const iconStyle: React.CSSProperties = {}

    switch (eventType) {
        case CalendarEventType.PROJECT:
            title = 'Project'
            Icon = AssignmentTurnedInIcon
            break
        case CalendarEventType.DEADLINE:
            title = 'Deadline'
            Icon = AlarmIcon
            eventIconBgColor = undefined
            break
        case CalendarEventType.MOVE_OUT:
            title = 'Move Out'
            Icon = EmojiPeopleIcon
            eventIconBgColor = undefined
            break
        case CalendarEventType.MOVE_IN:
            title = 'Move In'
            Icon = PersonAddIcon
            eventIconBgColor = undefined
            break
        case CalendarEventType.ACTION_ITEM:
            title = 'Action Item'
            Icon = ListSBIcon
            iconStyle.height = 20
            iconStyle.width = 20
            break
        case CalendarEventType.SERVICE_ORDER:
            title = 'Service Order'
            Icon = FormatPaintIcon
            break
        case CalendarEventType.SERVICE_REQUEST:
            title = 'Service Request'
            Icon = AssignmentIndOutlined
            break
        case CalendarEventType.ON_CALL:
            title = 'On Call'
            Icon = PersonIcon
            eventIconBgColor = undefined
            break
        case CalendarEventType.DAY_OFF:
            title = 'Day Off'
            Icon = EventBusy
            eventIconBgColor = undefined
            break
    }

    return (
        <Container style={{}}>
            <Container
                style={{
                    backgroundColor: eventIconBgColor,
                    marginRight: theme.spacing(1),
                    padding: 2,
                }}
            >
                <Tooltip title={title}>
                    <Icon
                        htmlColor={
                            htmlColor ? htmlColor : theme.palette.grey[800]
                        }
                        style={iconStyle}
                    />
                </Tooltip>
            </Container>

            {props.children}
        </Container>
    )
}
