import React, { useEffect, useState } from 'react'

import {
    Modal,
    Slide,
    Paper,
    Theme,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
} from '@material-ui/core'

import { Container } from '../../../components'
import { CriteriaGroup, EntityClassification, MatchType } from '../../../models'

export const CriteriaGroupModal = (props: {
    theme: Theme
    openState: {
        open: boolean
        groupId: number
    }
    criteriaGroups: CriteriaGroup[]
    onClose: () => void
    onSave: (name: string, criteriaState: MatchCriteriaState) => void
}) => {
    const { theme, openState, criteriaGroups, onClose, onSave } = props

    const [name, setName] = useState('')

    const [criteriaState, setCriteriaState] = useState<MatchCriteriaState>(
        DEFAULT_MATCH_CRITERIA_STATE,
    )

    const activeGroup = criteriaGroups.find((c) => c.id === openState.groupId)

    useEffect(() => {
        if (openState.open) {
            if (activeGroup) {
                setName(activeGroup.name)

                const newCriteriaState = {
                    ...DEFAULT_MATCH_CRITERIA_STATE,
                }
                activeGroup.match_criteria.forEach((c) => {
                    newCriteriaState[c.entity_classification] = c.match_type
                })

                setCriteriaState(newCriteriaState)
            } else {
                setName('')
                setCriteriaState(DEFAULT_MATCH_CRITERIA_STATE)
            }
        } else {
            setName('')
            setCriteriaState(DEFAULT_MATCH_CRITERIA_STATE)
        }
    }, [openState.open])

    return (
        <Modal
            open={openState.open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={openState.open}>
                <Paper>
                    <Container
                        style={{
                            display: 'flex',
                            overflow: 'hidden',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            flexDirection: 'column',
                            flex: 1,
                            height: MODAL_H,
                            width: MODAL_W,
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            <TextField
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                required
                            />
                        </Container>

                        {/* Body */}
                        <Container style={{ flex: 1, overflow: 'auto' }}>
                            {/* Col 1 */}
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    borderRight: `1px solid ${theme.palette.divider}`,
                                    padding: theme.spacing(2),
                                }}
                            >
                                <CriteriaRadioGroup
                                    label="Resident"
                                    value={criteriaState.resident}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            resident: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Guarantor"
                                    value={criteriaState.guarantor}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            guarantor: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Approved Date"
                                    value={criteriaState.approved_date}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            approved_date: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Balance"
                                    value={criteriaState.balance}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            balance: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Building Unit"
                                    value={criteriaState.bldg_unit}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            bldg_unit: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Completed Date"
                                    value={criteriaState.completed_date}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            completed_date: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Employee Discount"
                                    value={criteriaState.employee_discount}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            employee_discount: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Guarantor Waiver Fee"
                                    value={criteriaState.guarantor_waiver_fee}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            guarantor_waiver_fee: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Guarantor Email"
                                    value={criteriaState.guarantor_email}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            guarantor_email: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Late Charges"
                                    value={criteriaState.late_charges}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            late_charges: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease End"
                                    value={criteriaState.lease_end}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_end: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease Start"
                                    value={criteriaState.lease_start}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_start: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease Status"
                                    value={criteriaState.lease_status}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_status: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease Term"
                                    value={criteriaState.lease_term}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_term: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease Term Name"
                                    value={criteriaState.lease_term_name}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_term_name: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Liability Insurance"
                                    value={criteriaState.liability_insurance}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            liability_insurance: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Market Rent"
                                    value={criteriaState.market_rent}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            market_rent: value,
                                        })
                                    }
                                />
                            </Container>

                            {/* Col 2 */}
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    borderRight: `1px solid ${theme.palette.divider}`,
                                    padding: theme.spacing(2),
                                }}
                            >
                                <CriteriaRadioGroup
                                    label="Parking Fee"
                                    value={criteriaState.parking_fee}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            parking_fee: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet Rent"
                                    value={criteriaState.pet_rent}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_rent: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Reletting Charges"
                                    value={criteriaState.reletting_charges}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            reletting_charges: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Rent Concessions"
                                    value={criteriaState.rent_concessions}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            rent_concessions: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Short Term Fee"
                                    value={criteriaState.short_term_fee}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            short_term_fee: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Square Footage"
                                    value={criteriaState.square_footage}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            square_footage: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Unit Status"
                                    value={criteriaState.unit_status}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            unit_status: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Unit Type"
                                    value={criteriaState.unit_type}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            unit_type: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Utility Trash"
                                    value={criteriaState.utility_trash}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            utility_trash: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Total Rent"
                                    value={criteriaState.total_rent}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            total_rent: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Monthly Rent"
                                    value={criteriaState.monthly_rent}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            monthly_rent: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Installments"
                                    value={criteriaState.installments}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            installments: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Security Deposit"
                                    value={criteriaState.security_deposit}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            security_deposit: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Renters Insurance Required"
                                    value={
                                        criteriaState.renters_insurance_required
                                    }
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            renters_insurance_required: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Personal Liability Required"
                                    value={
                                        criteriaState.personal_liability_required
                                    }
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            personal_liability_required: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Renters Insurance Provider"
                                    value={
                                        criteriaState.renters_insurance_provider
                                    }
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            renters_insurance_provider: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Required Policy Amount"
                                    value={criteriaState.required_policy_amount}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            required_policy_amount: value,
                                        })
                                    }
                                />
                            </Container>

                            {/* Col 3 */}
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: theme.spacing(2),
                                }}
                            >
                                <CriteriaRadioGroup
                                    label="Monthly Cost of Insurance"
                                    value={
                                        criteriaState.monthly_cost_of_insurance
                                    }
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            monthly_cost_of_insurance: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Lease Contract Date"
                                    value={criteriaState.lease_contract_date}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            lease_contract_date: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Animals Approved"
                                    value={criteriaState.animals_approved}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            animals_approved: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Animal Deposit"
                                    value={criteriaState.animal_deposit}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            animal_deposit: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Additional Fees"
                                    value={criteriaState.additional_fees}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            additional_fees: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 1 Type"
                                    value={criteriaState.pet_1_type}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_1_type: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 1 Breed"
                                    value={criteriaState.pet_1_breed}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_1_breed: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 1 Weight"
                                    value={criteriaState.pet_1_weight}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_1_weight: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 1 Age"
                                    value={criteriaState.pet_1_age}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_1_age: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 1 House Broken"
                                    value={criteriaState.pet_1_house_broken}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_1_house_broken: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 2 Type"
                                    value={criteriaState.pet_2_type}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_2_type: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 2 Breed"
                                    value={criteriaState.pet_2_breed}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_2_breed: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 2 Weight"
                                    value={criteriaState.pet_2_weight}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_2_weight: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 2 Age"
                                    value={criteriaState.pet_2_age}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_2_age: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 2 House Broken"
                                    value={criteriaState.pet_2_house_broken}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_2_house_broken: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 3 Type"
                                    value={criteriaState.pet_3_type}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_3_type: value,
                                        })
                                    }
                                />
                                <CriteriaRadioGroup
                                    label="Pet 3 Breed"
                                    value={criteriaState.pet_3_breed}
                                    theme={theme}
                                    onChange={(value) =>
                                        setCriteriaState({
                                            ...criteriaState,
                                            pet_3_breed: value,
                                        })
                                    }
                                />
                            </Container>
                        </Container>

                        {/* Footer */}
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                borderTop: `1px solid ${theme.palette.divider}`,
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    textTransform: 'none',
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                                disabled={name === ''}
                                onClick={() => {
                                    props.onSave(name, criteriaState)
                                    props.onClose()
                                }}
                            >
                                Save
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface CriteriaRadioGroupProps {
    label: string
    value: MatchType
    onChange: (value: MatchType) => void
    theme: Theme
}

export const CriteriaRadioGroup: React.FC<CriteriaRadioGroupProps> = ({
    label,
    value,
    onChange,
    theme,
}) => {
    return (
        <Container
            style={{
                marginTop: theme.spacing(1),
                alignItems: 'center',
            }}
        >
            <span
                style={{
                    ...theme.typography.subtitle1,
                    fontWeight: theme.typography.fontWeightBold,
                    width: 200,
                }}
            >
                {label}
            </span>
            <RadioGroup
                value={value}
                style={{ flexDirection: 'row', marginLeft: theme.spacing(1) }}
            >
                <FormControlLabel
                    value="NOT_REQUIRED"
                    control={
                        <Radio
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        />
                    }
                    label="Not Required"
                    onClick={() => onChange('NOT_REQUIRED')}
                />
                <FormControlLabel
                    value="ANY_REQUIRED"
                    control={
                        <Radio
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        />
                    }
                    label="Required"
                    onClick={() => onChange('ANY_REQUIRED')}
                />
            </RadioGroup>
        </Container>
    )
}

type MatchCriteriaState = {
    [entityClassification in EntityClassification]: MatchType
}

const MODAL_H = window.innerHeight * 0.6
const MODAL_W = window.innerWidth * 0.8

const DEFAULT_MATCH_CRITERIA_STATE: MatchCriteriaState = {
    resident: 'NOT_REQUIRED',
    guarantor: 'NOT_REQUIRED',
    approved_date: 'NOT_REQUIRED',
    balance: 'NOT_REQUIRED',
    bldg_unit: 'NOT_REQUIRED',
    completed_date: 'NOT_REQUIRED',
    employee_discount: 'NOT_REQUIRED',
    guarantor_waiver_fee: 'NOT_REQUIRED',
    guarantor_email: 'NOT_REQUIRED',
    late_charges: 'NOT_REQUIRED',
    lease_end: 'NOT_REQUIRED',
    lease_start: 'NOT_REQUIRED',
    lease_status: 'NOT_REQUIRED',
    lease_term: 'NOT_REQUIRED',
    lease_term_name: 'NOT_REQUIRED',
    liability_insurance: 'NOT_REQUIRED',
    market_rent: 'NOT_REQUIRED',
    parking_fee: 'NOT_REQUIRED',
    pet_rent: 'NOT_REQUIRED',
    reletting_charges: 'NOT_REQUIRED',
    rent_concessions: 'NOT_REQUIRED',
    short_term_fee: 'NOT_REQUIRED',
    square_footage: 'NOT_REQUIRED',
    unit_status: 'NOT_REQUIRED',
    unit_type: 'NOT_REQUIRED',
    utility_trash: 'NOT_REQUIRED',
    total_rent: 'NOT_REQUIRED',
    monthly_rent: 'NOT_REQUIRED',
    installments: 'NOT_REQUIRED',
    security_deposit: 'NOT_REQUIRED',
    renters_insurance_required: 'NOT_REQUIRED',
    personal_liability_required: 'NOT_REQUIRED',
    renters_insurance_provider: 'NOT_REQUIRED',
    required_policy_amount: 'NOT_REQUIRED',
    monthly_cost_of_insurance: 'NOT_REQUIRED',
    lease_contract_date: 'NOT_REQUIRED',
    animals_approved: 'NOT_REQUIRED',
    animal_deposit: 'NOT_REQUIRED',
    additional_fees: 'NOT_REQUIRED',
    pet_1_type: 'NOT_REQUIRED',
    pet_1_breed: 'NOT_REQUIRED',
    pet_1_weight: 'NOT_REQUIRED',
    pet_1_age: 'NOT_REQUIRED',
    pet_1_house_broken: 'NOT_REQUIRED',
    pet_2_type: 'NOT_REQUIRED',
    pet_2_breed: 'NOT_REQUIRED',
    pet_2_weight: 'NOT_REQUIRED',
    pet_2_age: 'NOT_REQUIRED',
    pet_2_house_broken: 'NOT_REQUIRED',
    pet_3_type: 'NOT_REQUIRED',
    pet_3_breed: 'NOT_REQUIRED',
    pet_3_weight: 'NOT_REQUIRED',
    pet_3_age: 'NOT_REQUIRED',
    pet_3_house_broken: 'NOT_REQUIRED',
    utility_electricity: 'NOT_REQUIRED',
    amenity_fee: 'NOT_REQUIRED',
}
