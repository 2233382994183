import React, { useState } from 'react'

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import AlarmIcon from '@material-ui/icons/Alarm'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { IconButton, Tooltip, useTheme } from '@material-ui/core'

import { Container } from '../../../../components'
import { ReactComponent as ActionItemIcon } from '../../../../assets/icons/action-item.svg'
import { CalendarEventType, UserCalendarConfig } from '../../types'
import {
    CreateUserCalendarConfigRequest,
    UpdateUserCalendarConfigRequest,
} from '../../../../hooks/useUserCalendarConfigs'
import { AddCalendarEventPopover } from './AddCalendarEventPopover'
import { EditCalendarEventPopover } from './EditCalendarEventPopover'
import { EventBusy } from '@material-ui/icons'

interface Props {
    visibleEventTypes: CalendarEventType[]
    userCalendarConfigs: UserCalendarConfig[]
    setVisibleEventTypes: (
        value: React.SetStateAction<CalendarEventType[]>,
    ) => void
    createUserCalendarConfig: (
        data: CreateUserCalendarConfigRequest,
    ) => Promise<UserCalendarConfig>
    editUserCalendarConfig: (
        data: UpdateUserCalendarConfigRequest,
    ) => Promise<UserCalendarConfig>
    deleteUserCalendarConfig: (id: number) => Promise<void>
}

export const CalendarEventButtons = (props: Props) => {
    const {
        visibleEventTypes,
        userCalendarConfigs,
        setVisibleEventTypes,
        createUserCalendarConfig,
        editUserCalendarConfig,
        deleteUserCalendarConfig,
    } = props

    const theme = useTheme()

    const [
        openAddCalendarEventPopover,
        setOpenAddCalendarEventPopover,
    ] = useState(false)
    const [
        openEditCalendarEventPopover,
        setOpenEditCalendarEventPopover,
    ] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const userCalendarConfigsLength = userCalendarConfigs.length ?? 0

    return (
        <Container style={{ alignItems: 'center' }}>
            <ToggleButtonGroup
                value={visibleEventTypes}
                onChange={(_, value) => setVisibleEventTypes(value)}
            >
                {userCalendarConfigs.map((config) => {
                    const { icon, label } = getButtonDetails(config.event_type)
                    return (
                        <ToggleButton
                            key={config.event_type}
                            value={config.event_type}
                            aria-label={config.event_type.toString()}
                        >
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {icon}
                                <span
                                    style={{
                                        textTransform: 'none',
                                    }}
                                >
                                    {label}
                                </span>
                            </Container>
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
            <Tooltip title="Add Event Type to view">
                <IconButton
                    style={{
                        color: 'white',
                        borderRadius: '50%',
                        border: `1px solid ${theme.palette.grey[300]}`,
                        padding: '6px',
                        marginLeft: theme.spacing(1),
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setOpenAddCalendarEventPopover(true)
                    }}
                >
                    <AddIcon fontSize="small" color="primary" />
                </IconButton>
            </Tooltip>
            {userCalendarConfigsLength > 0 && (
                <Tooltip title="Edit Event Type Configurations">
                    <IconButton
                        style={{
                            color: 'white',
                            borderRadius: '50%',
                            border: `1px solid ${theme.palette.grey[300]}`,
                            padding: '6px',
                            marginLeft: theme.spacing(1),
                        }}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setOpenEditCalendarEventPopover(true)
                        }}
                    >
                        <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            )}
            <AddCalendarEventPopover
                anchorPosition={{
                    top: anchorEl?.getBoundingClientRect().top ?? 0,
                    left: anchorEl?.getBoundingClientRect().left ?? 0,
                }}
                open={openAddCalendarEventPopover}
                theme={theme}
                userCalendarConfigs={userCalendarConfigs}
                onClose={() => setOpenAddCalendarEventPopover(false)}
                createUserCalendarConfig={createUserCalendarConfig}
            />
            <EditCalendarEventPopover
                anchorPosition={{
                    top: anchorEl?.getBoundingClientRect().top ?? 0,
                    left: anchorEl?.getBoundingClientRect().left ?? 0,
                }}
                open={openEditCalendarEventPopover}
                theme={theme}
                userCalendarConfigs={userCalendarConfigs}
                onClose={() => setOpenEditCalendarEventPopover(false)}
                editUserCalendarConfig={editUserCalendarConfig}
                deleteUserCalendarConfig={deleteUserCalendarConfig}
            />
        </Container>
    )
}

const getButtonDetails = (eventType: CalendarEventType) => {
    switch (eventType) {
        case CalendarEventType.PROJECT:
            return {
                icon: <AssignmentTurnedInIcon />,
                label: 'Projects',
            }
        case CalendarEventType.DEADLINE:
            return {
                icon: <AlarmIcon />,
                label: 'Deadlines',
            }
        case CalendarEventType.SERVICE_ORDER:
            return {
                icon: <FormatPaintIcon />,
                label: 'Service Orders',
            }
        case CalendarEventType.ACTION_ITEM:
            return {
                icon: (
                    <ActionItemIcon
                        style={{
                            height: 25,
                            width: 25,
                        }}
                    />
                ),
                label: 'Action Items',
            }
        case CalendarEventType.SERVICE_REQUEST:
            return {
                icon: <AssignmentIndOutlined />,
                label: 'Service Requests',
            }
        case CalendarEventType.MOVE_OUT:
            return {
                icon: <EmojiPeopleIcon />,
                label: 'Move Outs',
            }
        case CalendarEventType.MOVE_IN:
            return {
                icon: <PersonAddIcon />,
                label: 'Move Ins',
            }
        case CalendarEventType.ON_CALL:
            return {
                icon: <PersonIcon />,
                label: 'On Call',
            }
        case CalendarEventType.DAY_OFF:
            return {
                icon: <EventBusy />,
                label: 'Day Off',
            }
        default:
            return {
                icon: null,
                label: '',
            }
    }
}
