import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Box,
    IconButton,
    Chip,
    makeStyles,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { ExpandMore, DeleteForever, Add } from '@material-ui/icons'

import {
    getServiceImageDetails,
    ModelMap,
    Service,
    ServiceCategory,
} from '../../models'
import { AddServiceToCategoryRequest } from '../../hooks/useServiceCategories'
import { Container, Selector } from '../../components'

const useStyles = makeStyles((theme) => ({
    listContainer: {
        overflowY: 'auto',
        width: '100%',
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    addServiceContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}))

interface Props {
    serviceCategories: ServiceCategory[]
    availableServices: Service[]
    serviceMap: ModelMap<Service>
    deleteServiceCategory: (id: number) => Promise<undefined>
    addServiceToCategory: (
        request: AddServiceToCategoryRequest,
    ) => Promise<ServiceCategory>
    removeServiceFromCategory: (
        request: AddServiceToCategoryRequest,
    ) => Promise<ServiceCategory>
}

export const ServiceCategoryList = (props: Props) => {
    const {
        serviceCategories,
        serviceMap,
        availableServices,
        deleteServiceCategory,
        addServiceToCategory,
        removeServiceFromCategory,
    } = props

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [
        categoryToDelete,
        setCategoryToDelete,
    ] = useState<ServiceCategory | null>(null)
    const [selectedServiceId, setSelectedServiceId] = useState<number>(-1)

    const getService = (serviceId: number) => {
        const service: Service | undefined = serviceMap[serviceId]
        return service
    }

    const classes = useStyles()
    const theme = useTheme()

    console.log(selectedServiceId)

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 150px)',
            }}
        >
            {serviceCategories.map((category) => (
                <Accordion key={category.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel${category.id}-content`}
                        id={`panel${category.id}-header`}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                alignSelf: 'center',
                                minWidth: 100,
                            }}
                        >
                            {category.name}
                        </span>
                        <Container style={{ flex: 1 }}>
                            {category.services.map((serviceId) => {
                                const service = getService(serviceId)
                                if (!service) return

                                const icon = getServiceImageDetails(service.id)
                                    .icon
                                if (icon === null) return

                                return (
                                    <Tooltip
                                        title={service.name}
                                        key={`SERVICE_FILTER_${service.id}`}
                                    >
                                        <img
                                            src={icon}
                                            style={{
                                                maxHeight: 25,
                                                maxWidth: 27,
                                                marginRight: theme.spacing(1),
                                            }}
                                        />
                                    </Tooltip>
                                )
                            })}
                        </Container>
                        <Chip
                            label={`${category.services.length} service${
                                category.services.length !== 1 ? 's' : ''
                            }`}
                            size="small"
                            className={classes.chip}
                            style={{
                                width: '100px',
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                                marginRight: theme.spacing(2),
                                alignSelf: 'center',
                            }}
                        />
                        <IconButton
                            color="secondary"
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                setCategoryToDelete(category)
                                setDeleteDialogOpen(true)
                            }}
                            size="small"
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            <DeleteForever color="secondary" />
                        </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ServiceCategoryDetails
                            category={category}
                            theme={theme}
                            availableServices={availableServices}
                            getService={getService}
                            addServiceToCategory={addServiceToCategory}
                            removeServiceFromCategory={
                                removeServiceFromCategory
                            }
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the category{' '}
                        {categoryToDelete?.name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (categoryToDelete) {
                                deleteServiceCategory(categoryToDelete.id).then(
                                    () => {
                                        toast.success('Category deleted')
                                        setDeleteDialogOpen(false)
                                        setCategoryToDelete(null)
                                    },
                                )
                            }
                        }}
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface ServiceCategoryDetailsProps {
    category: ServiceCategory
    theme: Theme
    availableServices: Service[]
    getService: (serviceId: number) => Service | undefined
    addServiceToCategory: (
        request: AddServiceToCategoryRequest,
    ) => Promise<ServiceCategory>
    removeServiceFromCategory: (
        request: AddServiceToCategoryRequest,
    ) => Promise<ServiceCategory>
}

const ServiceCategoryDetails = (props: ServiceCategoryDetailsProps) => {
    const {
        category,
        theme,
        availableServices,
        getService,
        addServiceToCategory,
        removeServiceFromCategory,
    } = props

    const classes = useStyles()

    const [selectedServiceId, setSelectedServiceId] = useState<number>(-1)

    const handleServiceChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        setSelectedServiceId(event.target.value as number)
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            {category.services.map((serviceId) => {
                const service = getService(serviceId)
                if (!service) return null
                return (
                    <Box
                        key={`SERVICE_${serviceId}_${category.id}`}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{
                            padding: theme.spacing(1),
                            transition: 'background-color 0.3s',
                        }}
                    >
                        <Typography variant="body1">{service.name}</Typography>
                        <IconButton
                            color="secondary"
                            onClick={() =>
                                removeServiceFromCategory({
                                    service_id: serviceId,
                                    category_id: category.id,
                                }).then(() => {
                                    toast.success(
                                        'Service removed from category',
                                    )
                                })
                            }
                            size="small"
                        >
                            <DeleteForever color="secondary" />
                        </IconButton>
                    </Box>
                )
            })}
            <Box className={classes.addServiceContainer}>
                <Selector
                    label="Service"
                    currentValue={selectedServiceId ?? -1}
                    onChange={handleServiceChange}
                    data={availableServices}
                    getDisplayString={(s: Service) => s.name}
                    size="small"
                    customStyle={{
                        formControl: {
                            flex: 1,
                            marginRight: '16px',
                        },
                    }}
                    maxItems={10}
                    searchable
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (selectedServiceId === -1) return
                        addServiceToCategory({
                            service_id: selectedServiceId,
                            category_id: category.id,
                        }).then(() => {
                            toast.success('Service added to category')
                            setSelectedServiceId(-1)
                        })
                    }}
                    disabled={
                        selectedServiceId === -1 ||
                        selectedServiceId === undefined
                    }
                    startIcon={<Add />}
                >
                    Add Service
                </Button>
            </Box>
        </Box>
    )
}
