import {
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tab,
    Tabs,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AvatarCircle, Calendly, Container } from '../../components'
import { axiosInstance } from '../../helpers'
import { useService, useUser } from '../../hooks'
import { ListVendor, PlanStatus, Service } from '../../models'
import { useStyles } from '../../styles'
import { EditAccount } from './EditAccount'
import { VendorPlans } from './VendorPlans'
import { PremiumPage } from './PremiumPage'
import { VendorServices } from './VendorServices'
import { EditVendorDetails } from './EditVendorDetails'
import { PhotoCamera } from '@material-ui/icons'
import { toast } from 'react-toastify'
import { CompanyHourDisplay } from './CompanyHourDisplay'
const ACCOUNT_TAB = 0
const VENDOR_HOURS = 1
const DETAILS_TAB = 2
const PLANS_TAB = 3
const SERVICES_TAB = 4
const PREMIUM_TAB = 5

export const VendorSettings = () => {
    const { workspaceUser } = useUser()

    const [selectedTab, setSelectedTab] = useState<number>(ACCOUNT_TAB)
    const [vendor, setVendor] = useState<ListVendor | null>()

    const [modalOpen, setModalOpen] = useState(false)
    const [calendlyUrl, setCalendlyUrl] = useState('')

    const [selectedServices, setSelectedServices] = useState<Service[]>([])
    const { serviceList, setServiceList, getServiceList } = useService()

    const [profilePic, setProfilePic] = useState<string>('')

    const theme = useTheme()

    const tabStyle: React.CSSProperties = {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    }

    const classes = useStyles()

    useEffect(() => {
        if (workspaceUser?.active_workspace) {
            axiosInstance
                .get(`company/vendor/${workspaceUser.active_workspace.id}`)
                .then((res) => setVendor(res.data))
        }
        getServiceList({})
    }, [])

    useEffect(() => {
        const vServiceIds = vendor?.services.map((service) => service.id)
        const updatedServiceList = serviceList.map((service) => {
            service.checked = vServiceIds?.includes(service.id)
            return service
        })
        setServiceList(updatedServiceList)
        setSelectedServices(
            updatedServiceList.filter((service) => service.checked),
        )
    }, [selectedTab])

    useEffect(() => {
        setProfilePic(vendor?.profile_picture ? vendor.profile_picture : '')
    }, [vendor])

    return (
        <Container
            style={{
                height: '100%',
                flex: 1,
                position: 'relative',
            }}
        >
            <Container
                style={{
                    backgroundColor: theme.palette.darkBlue.main,
                    position: 'absolute',
                    flex: 1,
                    maxHeight: '350px',
                    height: '350px',
                    width: '100%',
                }}
            >
                <span
                    style={{
                        color: 'white',
                        fontSize: '40px',
                        fontWeight: 400,
                        margin: theme.spacing(5, 0, 0, 3),
                    }}
                >
                    Settings
                </span>
            </Container>

            <Container
                direction="row"
                style={{ zIndex: 9, position: 'absolute', width: '100%' }}
            >
                <Container
                    style={{
                        maxHeight: '450px',
                        width: '350px',
                        height: '400px',
                        backgroundColor: 'white',
                        margin: theme.spacing(20, 0, 0, 3),
                        border: `1px solid ${theme.palette.grey[300]}`,
                    }}
                    direction="column"
                >
                    <Container
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {profilePic ? (
                            <img
                                src={profilePic}
                                style={{
                                    borderRadius: '50%',
                                    height: '150px',
                                    width: '150px',
                                    margin: theme.spacing(4, 1, 1, 4),
                                }}
                            />
                        ) : (
                            <AvatarCircle
                                text={vendor?.name}
                                initials={true}
                                styles={{
                                    container: {
                                        margin: theme.spacing(4, 1, 1, 4),
                                        padding: theme.spacing(6),
                                        fontSize: '25px',
                                        border: `1px solid black`,
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                    },
                                }}
                            />
                        )}
                        <input
                            accept="image/png, image/jpeg, image/jpg"
                            id="profile-pic-button-file"
                            type="file"
                            onChange={(event) => {
                                if (event.target.files) {
                                    const file = event.target.files[0]
                                    const body = new FormData()
                                    body.append('profile_picture', file)
                                    axiosInstance
                                        .patch(
                                            `company/vendor/${vendor?.id}/`,
                                            body,
                                        )
                                        .then((res) => {
                                            toast.success(
                                                `Profile Picture updated successfully`,
                                            )
                                            const tempVendor = JSON.parse(
                                                JSON.stringify(vendor),
                                            )
                                            tempVendor.profile_picture =
                                                res.data.vendor.profile_picture
                                            setVendor(tempVendor)
                                            setProfilePic(
                                                res.data.vendor.profile_picture,
                                            )
                                        })
                                        .catch((e) =>
                                            toast.error(e.response.data),
                                        )
                                }
                            }}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="profile-pic-button-file">
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                style={{
                                    color: '#FFF',
                                    background: '#2C80FF',
                                    padding: '4px',
                                    marginTop: theme.spacing(5),
                                }}
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </Container>
                    <Container
                        direction="column"
                        style={{
                            alignItems: 'center',
                            margin: theme.spacing(5, 0, 5, 0),
                            justifyContent: 'center',
                        }}
                        flex={1}
                    >
                        <span
                            style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                // color: theme.palette.darkGreen.main,
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            {vendor?.name}
                        </span>
                        <span
                            style={{
                                fontSize: '15px',
                                fontWeight: 400,
                                color: theme.palette.darkGreen.main,
                            }}
                        >
                            {vendor?.email}
                        </span>
                    </Container>
                    <Container
                        style={{
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            marginBottom: theme.spacing(4),
                        }}
                        flex={1}
                    >
                        <Button
                            variant="outlined"
                            size="large"
                            style={{
                                textTransform: 'none',
                                backgroundColor: 'white',
                                color: theme.palette.darkGreen.main,
                            }}
                            onClick={() => {
                                setModalOpen(true)
                                setCalendlyUrl(
                                    'https://calendly.com/alexandria-44',
                                )
                            }}
                        >
                            Need Help?
                        </Button>
                    </Container>
                </Container>

                <Container
                    style={{
                        maxHeight: 'calc(100vh)',
                        width: '70%',
                        backgroundColor: 'white',
                        margin: theme.spacing(20, 0, 0, 6),
                        border: `1px solid ${theme.palette.grey[300]}`,
                        overflowX: 'auto',
                    }}
                    direction="column"
                >
                    <Container>
                        <Tabs
                            style={{
                                color: 'black',
                                borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                                flex: 1,
                                textTransform: 'none',
                            }}
                            value={selectedTab}
                            onChange={(_, v) => {
                                if (v !== selectedTab) {
                                    setSelectedTab(v)
                                }
                            }}
                            classes={{
                                indicator: classes.indicator,
                            }}
                        >
                            <Tab label="Account" style={{ ...tabStyle }} />
                            <Tab label="Vendor Hours" style={{ ...tabStyle }} />
                            <Tab label="Details" style={{ ...tabStyle }} />
                            <Tab label="Plans" style={{ ...tabStyle }} />
                            <Tab label="Services" style={{ ...tabStyle }} />
                            {vendor?.plan === PlanStatus.PREMIUM && (
                                <Tab label="Premium" style={{ ...tabStyle }} />
                            )}
                        </Tabs>
                    </Container>
                    <Container flex={1}>
                        {selectedTab === ACCOUNT_TAB && vendor && (
                            <EditAccount
                                vendor={vendor}
                                setVendor={setVendor}
                            />
                        )}

                        {selectedTab === DETAILS_TAB && vendor && (
                            <EditVendorDetails
                                vendor={vendor}
                                setVendor={setVendor}
                            />
                        )}

                        {selectedTab === VENDOR_HOURS && vendor && (
                            <Container style={{ margin: theme.spacing(4, 2) }}>
                                <CompanyHourDisplay
                                    company={vendor}
                                    displayHeader
                                    onAfterUpdate={(companyHours) => {
                                        const tempVendor = {
                                            ...vendor,
                                            company_hours: companyHours,
                                        }
                                        setVendor(tempVendor)
                                    }}
                                />
                            </Container>
                        )}

                        {selectedTab === PLANS_TAB && vendor && (
                            <VendorPlans vendor={vendor} />
                        )}

                        {selectedTab === SERVICES_TAB && vendor && (
                            <VendorServices
                                vendor={vendor}
                                setVendor={setVendor}
                                selectedServices={selectedServices}
                                serviceList={serviceList}
                                setSelectedServices={setSelectedServices}
                                setServiceList={setServiceList}
                            />
                        )}

                        {selectedTab === PREMIUM_TAB &&
                            vendor &&
                            vendor?.plan === PlanStatus.PREMIUM && (
                                <PremiumPage
                                    vendor={vendor}
                                    setVendor={setVendor}
                                />
                            )}
                    </Container>
                </Container>
            </Container>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Slide direction="up" in={modalOpen}>
                    <Paper
                        style={{
                            height: 800,
                            width: 800,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <div style={{ height: 800 }}>
                            <Calendly url={calendlyUrl} />
                        </div>
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
