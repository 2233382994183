import React, { useEffect, useState } from 'react'
import { Container } from '../../Container'
import {
    Theme,
    IconButton,
    CircularProgress,
    Tabs,
    Tab,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {
    Address,
    BaseCompany,
    IdentifiableNamedObject,
    SimpleUser,
} from '../../../models'
import { APARTMENT, axiosInstance } from '../../../helpers'
import { toast } from 'react-toastify'
import { WorkspaceUserList } from './WorkspaceUserList'
import { MODAL_HEADER_H } from './constants'
import { WorkspaceAddress } from './WorkspaceAddress'
import { UploadOnboardingSheet } from '../UploadOnboardingSheet'

interface Props {
    theme: Theme
    workspaceDetail: BaseCompany
    handleClose: () => void
    updateWorkspaceAddress: (address: Address) => void
}

export const SelectedWorkspacePanel = (props: Props) => {
    const { theme, workspaceDetail } = props

    const [mode, setMode] = useState<'users' | 'address' | 'onboarding'>(
        'users',
    )

    const [userState, setUserState] = useState<UserState>({
        loading: false,
        userList: [],
    })

    useEffect(() => {
        setUserState({ loading: true, userList: [] })
        axiosInstance
            .get('user/_admin/', {
                params: { company: workspaceDetail.id },
            })
            .then((res) => {
                setUserState({ loading: false, userList: res.data })
            })
            .catch((e) => {
                setUserState({ loading: false, userList: [] })
                toast.error(`${e}`)
            })
    }, [workspaceDetail])

    const resendWelcomeEmail = async (email: string) => {
        const body = {
            email: email,
        }
        axiosInstance
            .post(`user/resend_welcome_email/`, body)
            .then(() => {
                toast.success(`Welcome email resent!`)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
    }

    return (
        <Container
            style={{
                width: 600,
                flexDirection: 'column',
                border: `1px solid ${theme.palette.grey[300]}`,
                marginLeft: theme.spacing(1),
                maxHeight: `calc(100vh - 100px - ${MODAL_HEADER_H}px - ${theme.spacing(
                    1,
                )}px)`,
                minHeight: `calc(100vh - 100px - ${MODAL_HEADER_H}px - ${theme.spacing(
                    1,
                )}px)`,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    padding: 2,
                    alignItems: 'center',
                    height: 60,
                }}
            >
                <IconButton onClick={props.handleClose}>
                    <ArrowForwardIosIcon />
                </IconButton>

                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {workspaceDetail.name}
                </span>
            </Container>

            {/* Mode Tabs */}
            <Container>
                <Tabs value={mode} onChange={(e, v) => setMode(v)}>
                    <Tab label="Users" value="users" />
                    <Tab label="Address" value="address" />
                    {workspaceDetail.company_type === APARTMENT && (
                        <Tab label="Onboarding" value="onboarding" />
                    )}
                </Tabs>
            </Container>

            {/* Body */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'scroll',
                }}
            >
                {mode === 'users' && (
                    <WorkspaceUserList
                        theme={theme}
                        userState={userState}
                        handleArchive={(id) => {
                            axiosInstance
                                .post(`user/${id}/archive/`)
                                .then(() => {
                                    setUserState((prev) => {
                                        return {
                                            ...prev,
                                            userList: prev.userList.filter(
                                                (u) => u.id !== id,
                                            ),
                                        }
                                    })

                                    toast.success(`User Archived`)
                                })
                        }}
                        handleCreate={(email) => {
                            axiosInstance
                                .post(`company/add_user/`, {
                                    email: email,
                                    workspace: workspaceDetail.id,
                                })
                                .then((res) => {
                                    setUserState((prev) => {
                                        return {
                                            ...prev,
                                            userList: res.data.employees,
                                        }
                                    })

                                    toast.success(`User Added`)
                                })
                                .catch(() => {
                                    toast.error(`User not found`)
                                })
                        }}
                        handleResendWelcomeEmail={resendWelcomeEmail}
                    />
                )}

                {mode === 'address' && (
                    <WorkspaceAddress
                        theme={theme}
                        workspace={workspaceDetail}
                        updateWorkspaceAddress={props.updateWorkspaceAddress}
                    />
                )}

                {mode === 'onboarding' && (
                    <UploadOnboardingSheet apartment={workspaceDetail} />
                )}
            </Container>
        </Container>
    )
}

export type UserState = {
    loading: boolean
    userList: SimpleUser[]
}
