import React from 'react'

import {
    Box,
    Chip,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { DeleteForever, DragIndicator } from '@material-ui/icons'

import { ApartmentVendorRule, ListVendor } from '../../models'

interface Props {
    apartmentVendorRule: ApartmentVendorRule
    vendor?: ListVendor
    theme: Theme
    onClickDelete: () => void
    index: number
    onDragStart: () => void
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
    onDragLeave: (e: React.DragEvent<HTMLDivElement>) => void
    onDrop: (e: React.DragEvent<HTMLDivElement>) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    draggable: {
        cursor: 'move',
    },
    rank: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
    },
    name: {
        flex: 1,
    },
    bufferDays: {
        marginLeft: theme.spacing(2),
    },
    dragHandle: {
        marginRight: theme.spacing(1),
    },
    ezNowSpacer: {
        width: '24px', // Width of the DragIndicator icon
        marginRight: theme.spacing(1),
    },
}))

export const VendorServiceRuleRow = (props: Props) => {
    const {
        apartmentVendorRule,
        vendor,
        theme,
        onClickDelete,
        onDragStart,
        onDragOver,
        onDragLeave,
        onDrop,
    } = props
    const classes = useStyles()

    const isEZNow = apartmentVendorRule.vendor === null

    return (
        <Box
            className={`${classes.root} ${!isEZNow ? classes.draggable : ''}`}
            display="flex"
            alignItems="center"
            draggable={!isEZNow}
            onDragStart={!isEZNow ? onDragStart : undefined}
            onDragOver={!isEZNow ? onDragOver : undefined}
            onDragLeave={!isEZNow ? onDragLeave : undefined}
            onDrop={!isEZNow ? onDrop : undefined}
        >
            {!isEZNow ? (
                <DragIndicator className={classes.dragHandle} />
            ) : (
                <div className={classes.ezNowSpacer} />
            )}
            <Typography
                variant="body1"
                className={classes.rank}
                style={{
                    color: isEZNow ? theme.palette.text.primary : undefined,
                }}
            >
                {apartmentVendorRule.rank}.
            </Typography>
            <Typography variant="body1" className={classes.name}>
                {isEZNow ? 'EZNow' : vendor?.name}
            </Typography>
            {!isEZNow && (
                <Chip
                    label={`Buffer Days: ${apartmentVendorRule.buffer_days}`}
                    size="small"
                    className={classes.bufferDays}
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                    }}
                />
            )}
            <Tooltip title="Delete">
                <IconButton
                    onClick={onClickDelete}
                    style={{ marginLeft: theme.spacing(1) }}
                >
                    <DeleteForever color="secondary" />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
