import React, { useEffect, useState } from 'react'

// components
import {
    Order,
    HeadCell,
    RenderRow,
    FilterRow,
    RowData,
    EnhancedTableHead,
} from './'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { useStyles } from '../../styles'
import { Container } from '../Container'

function descendingComparator<T extends RowData>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy].sortValue < a[orderBy].sortValue) {
        return -1
    } else if (b[orderBy].sortValue > a[orderBy].sortValue) {
        return 1
    }
    return 0
}

function getComparator<T extends RowData>(
    order: Order,
    orderBy: keyof T,
): (a: T, b: T) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort<T extends RowData>(
    array: T[],
    comparator: (a: T, b: T) => number,
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    return stabilizedThis.map((el) => el[0])
}

interface Props<T extends RowData> {
    rows: T[]
    orderByDefault: keyof T
    orderDirDefault?: Order
    headCells: HeadCell<T>[]
    render: RenderRow<T>
    filter?: FilterRow<T>
    onGoToLastPage?: () => void
    count?: number
    stickyHeader?: boolean
    rowsPerPage?: number
    backendPagination?: {
        onSortChange: (sort: string[], dir: Order) => void
    }
    // when this value changes, set page 0
    goToTop?: boolean
}

export function SimpleTable<T extends RowData>(props: Props<T>) {
    const classes = useStyles()

    const {
        rows,
        orderByDefault,
        headCells,
        render,
        filter,
        onGoToLastPage,
        count,
        backendPagination,
        goToTop,
        orderDirDefault,
    } = props

    const [order, setOrder] = useState<Order>(orderDirDefault ?? 'asc')
    const [orderBy, setOrderBy] = useState<keyof T>(orderByDefault)
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage ?? 10)
    const [page, setPage] = useState(0)

    const filteredRows = filter ? rows.filter(filter) : rows
    const sortedRows = backendPagination
        ? filteredRows
        : stableSort(filteredRows, getComparator(order, orderBy))

    useEffect(() => {
        setPage(0)
    }, [goToTop])

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader={props.stickyHeader}>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(_, property, backendSort) => {
                        const isAsc: boolean =
                            orderBy === property && order === 'asc'
                        setOrder(isAsc ? 'desc' : 'asc')
                        setOrderBy(property)
                        if (backendPagination && backendSort) {
                            backendPagination.onSortChange(backendSort, order)
                        }
                    }}
                    rowCount={rows.length}
                    headCells={headCells}
                />
                <TableBody>
                    {sortedRows
                        .map((row, index) => render(row, index))
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                        )}
                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count ? count : sortedRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(_: unknown, newPage: number) => {
                    setPage(newPage)
                    if (
                        (newPage + 1) * rowsPerPage >= sortedRows.length &&
                        onGoToLastPage
                    ) {
                        onGoToLastPage()
                    }
                }}
                onChangeRowsPerPage={(
                    event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                    setRowsPerPage(parseInt(event.target.value, 10))
                    setPage(0)
                }}
            />
        </TableContainer>
    )
}
