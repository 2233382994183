import React from 'react'

import { Grid } from '@material-ui/core'

import { BaseProps } from '../../types'
import {
    getServiceImageDetails,
    ListVendor,
    TemplateService,
} from '../../../../models'

import { TemplateVendorRow } from './TemplateVendorRow'
import { VENDOR_BAR_H } from '../../constants'

import { setEndOfDay, setMidnight } from '../../../../helpers'

interface Props extends BaseProps {
    templateService: TemplateService
    vendorList: ListVendor[]
    projectDuration: number
    lastRow?: boolean
}

export const TemplateServiceDetailRow = (props: Props) => {
    const { theme, templateService } = props

    const containerHeight =
        (templateService.template_service_vendors.length + 1) * VENDOR_BAR_H

    const vendorRows = templateService.template_service_vendors.map((tsv) => {
        const startDate = new Date(tsv.start_date)
        const endDate = new Date(tsv.end_date)
        setMidnight(startDate)
        setEndOfDay(endDate)

        return (
            <TemplateVendorRow
                key={`TEMPLATE-VENDOR-${tsv.id}`}
                {...props}
                templateVendor={tsv}
            />
        )
    })

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                boxSizing: 'border-box',
                borderBottom: props.lastRow
                    ? undefined
                    : `1px solid ${theme.palette.grey[400]}`,
                borderRight: `1px solid ${theme.palette.grey[200]}`,
                maxHeight: containerHeight,
            }}
        >
            {/* Top Row: Service Name, Required Capcity & add button */}
            <Grid
                xs={12}
                container
                item
                style={{
                    display: 'flex',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    minHeight: VENDOR_BAR_H,
                    maxHeight: VENDOR_BAR_H,
                }}
            >
                <Grid xs={2}>
                    <img
                        src={
                            getServiceImageDetails(templateService.service.id)
                                .icon
                        }
                        style={{
                            maxHeight: 40,
                            maxWidth: 40,
                        }}
                    />
                </Grid>
                <Grid
                    xs={10}
                    style={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h5,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        {templateService.service.name}
                    </span>
                </Grid>
            </Grid>

            {/* Vendor Rows */}
            {vendorRows}
        </Grid>
    )
}
