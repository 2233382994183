import React from 'react'
import { Routes } from '../config/routes'
import { ApartmentGuard } from '../guards'
import { AuthGuard } from '../guards/AuthGuard'
import { AuthLayout } from '../layouts'
import type { RouteConfig } from '../types'

// Import grouping for containers
import Dashboard from '../../main/containers/dashboard'
import {
    AreaConfig,
    ComingSoon,
    DamageHome,
    InfrastructureConfig,
    InfrastructureManager,
    InspectionDetail,
    InspectionRoot,
    InventoryConfigHome,
    LeaseHome,
    ScheduleHomeRoot,
    StatusGroupConfig,
} from '../../containers'
import { SchedulerRoot } from '../../containers/Scheduler_v2/SchedulerRoot'
import { ArchivedSchedule } from '../../containers/ArchivedSchedule'
import { JobBoardRoot } from '../../containers/JobBoard/WorkspaceJobBoard/JobBoardRoot'
import { ActionItemRoot } from '../../containers/ActionItem/ActionItemRoot'
import { LeaseAuditList } from '../../containers/LeaseAudit/LeaseAuditList'
import { LeaseAuditRoot } from '../../containers/LeaseAudit/LeaseAuditRoot'
import { ChangeOrderConfigList } from '../../containers/ChangeOrderConfig/ChangeOrderConfig'
import { PropertyAutomationRoot } from '../../containers/PropertyAutomationContainer/PropertyAutomationRoot'
import { NVLRoot } from '../../containers/NationalVendorList/NVLRoot'
import { RFPRoot } from '../../containers/RFP/RFPRoot'
import { ServiceCategoriesContainer } from '../../containers/ServiceCategories/ServiceCategoriesContainer'

// Helper function to create guarded route
const createGuardedRoute = (props: any) => (
    <AuthGuard {...props}>
        <ApartmentGuard {...props} />
    </AuthGuard>
)

export const apartmentRoutes: RouteConfig[] = [
    // Dashboard
    {
        path: Routes.apartment.dashboard,
        component: Dashboard,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Schedule Management Routes
    {
        path: Routes.apartment.schedule.archived,
        component: ArchivedSchedule,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.schedule.detail,
        component: SchedulerRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.schedule.root,
        component: ScheduleHomeRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Inspection Routes
    {
        path: Routes.apartment.inspection.detail,
        component: InspectionDetail,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.inspection.root,
        component: InspectionRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Job and Action Management
    {
        path: Routes.apartment.jobBoard,
        component: JobBoardRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.actionItems,
        component: ActionItemRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Lease Management Routes
    {
        path: Routes.apartment.lease.auditDetail,
        component: LeaseAuditRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.lease.audit,
        component: LeaseAuditList,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.lease.root,
        component: LeaseHome,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Configuration Routes
    {
        path: Routes.apartment.config.inventory,
        component: InventoryConfigHome,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.area,
        component: AreaConfig,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.unit,
        component: ComingSoon,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.damage,
        component: DamageHome,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.statusGroups,
        component: StatusGroupConfig,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.changeOrder,
        component: ChangeOrderConfigList,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.serviceCategories,
        component: ServiceCategoriesContainer,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Infrastructure Configuration Routes
    {
        path: Routes.apartment.config.infrastructure.manager,
        component: InfrastructureManager,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.config.infrastructure.config,
        component: InfrastructureConfig,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },

    // Property Automation Routes
    {
        path: Routes.apartment.config.propertyAutomation,
        component: PropertyAutomationRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    // Apartment NVL
    {
        path: Routes.public.nvl.root,
        component: NVLRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
    {
        path: Routes.apartment.rfp.rfpDashboard,
        component: RFPRoot,
        layout: AuthLayout,
        guard: createGuardedRoute,
    },
]
