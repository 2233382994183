import React, { useMemo } from 'react'

import {
    Avatar,
    IconButton,
    makeStyles,
    Paper,
    Popover,
    Theme,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Divider,
} from '@material-ui/core'
import {
    HouseOutlined,
    AddCircleOutline,
    PeopleOutline,
} from '@material-ui/icons'

import {
    Company,
    getServiceImageDetails,
    ListVendor,
    Service,
} from '../../../models'
import { getInitials } from '../../../helpers'
import {
    AddRemoveVendorAptRequest,
    CompanyDetailActionThunk,
    UpdateVendorServicesRequest,
    WorkorderResponse,
} from '../../../store'
import { Container } from '../../../components'
import { usePopover } from '../../../hooks/usePopover'
import { toast } from 'react-toastify'

interface Props {
    vendor: ListVendor
    theme: Theme
    draggedWorkorder: WorkorderResponse | null
    serviceList: Service[]
    apartmentList: Company[]
    organizationView?: boolean
    apartmentVendorMap?: Record<number, number[]>
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
    onDrop: (
        workorderId: string,
        newVendorId: number,
        newDateKey?: string,
        isUser?: boolean,
    ) => void
    addVendorToApartment?: (req: AddRemoveVendorAptRequest) => Promise<void>
}

const useStyles = makeStyles((theme: Theme) => ({
    headerWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
        minWidth: 310,
        maxWidth: 310,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        borderBottom: 'none',
        minHeight: 80,
        maxHeight: 90,
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
    },
    name: {
        fontWeight: theme.typography.fontWeightBold,
    },
    invalidDropTarget: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    services: {
        minHeight: 30,
        maxHeight: 30,
    },
    popover: {
        padding: theme.spacing(2),
    },
    dropZone: {
        border: '2px dashed transparent',
        transition: 'border-color 0.2s ease',
        '&.drag-over': {
            borderColor: theme.palette.primary.main,
        },
    },
    validDropTarget: {
        border: `2px solid ${theme.palette.primary.dark}`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottom: 'none',
    },
}))

export const VendorHeaderCell = (props: Props) => {
    const {
        vendor,
        theme,
        draggedWorkorder,
        serviceList,
        organizationView,
        apartmentVendorMap,
        apartmentList,
        updateVendorServices,
        addVendorToApartment,
    } = props
    const classes = useStyles(theme)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()
    const {
        isOpen: isApartmentOpen,
        anchorEl: apartmentAnchorEl,
        handleOpen: handleApartmentOpen,
        handleClose: handleApartmentClose,
    } = usePopover()

    const isValidDropTarget = useMemo(() => {
        let isValid = true
        if (draggedWorkorder && vendor) {
            isValid = vendor.services.some(
                (service) => service.id === draggedWorkorder.service_id,
            )

            if (organizationView && apartmentVendorMap) {
                isValid =
                    isValid &&
                    apartmentVendorMap[
                        draggedWorkorder?.apartment_id ?? 0
                    ]?.includes(vendor.id)
            }
        }
        return isValid
    }, [organizationView, apartmentVendorMap, draggedWorkorder, vendor])

    const [firstThreeServices, remainingServices] = useMemo(() => {
        let serviceList = vendor.services
        if (draggedWorkorder) {
            serviceList = serviceList.filter(
                (service) => service.id === draggedWorkorder.service_id,
            )
        }
        const first = serviceList.slice(0, 3)
        const rest = serviceList.slice(3)
        return [first, rest]
    }, [vendor.services, draggedWorkorder])

    const handleAddService = (serviceId: number) => {
        const newServices = vendor.services.map((srv) => ({
            id: srv.id,
            offers: true,
        }))

        newServices.push({ id: serviceId, offers: true })

        updateVendorServices({
            params: { id: vendor.id },
            body: {
                services: newServices,
            },
        })

        handleClose()
    }

    const availableServices = useMemo(() => {
        return serviceList.filter(
            (service) => !vendor.services.some((vs) => vs.id === service.id),
        )
    }, [serviceList, vendor.services])

    const availableApartments = useMemo(() => {
        if (!apartmentVendorMap || !vendor) return []
        return apartmentList.filter(
            (apartment) =>
                !apartmentVendorMap[apartment.id]?.includes(vendor.id),
        )
    }, [apartmentList, apartmentVendorMap, vendor])

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault()
        if (isValidDropTarget) {
            e.currentTarget.classList.add('drag-over')
        }
    }

    const handleDragLeave = (e: React.DragEvent) => {
        e.currentTarget.classList.remove('drag-over')
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault()
        e.currentTarget.classList.remove('drag-over')
        if (isValidDropTarget) {
            const workorderId = e.dataTransfer.getData('text/plain')
            // Assuming handleDrop is passed as a prop to VendorHeaderCell
            props.onDrop(workorderId, vendor.id) // Replace 'some-date-key' with the actual date key if needed
        }
    }

    return (
        <Paper
            className={`${classes.headerWrapper} ${
                draggedWorkorder && isValidDropTarget
                    ? classes.validDropTarget
                    : ''
            }`}
            elevation={3}
        >
            <div
                className={`${classes.header} ${classes.dropZone} ${
                    !isValidDropTarget ? classes.invalidDropTarget : ''
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <Avatar
                    src={vendor.profile_picture}
                    alt={vendor.name}
                    className={classes.avatar}
                >
                    {getInitials(vendor.name)}
                </Avatar>
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Container style={{ alignItems: 'center' }}>
                        <Typography
                            variant="subtitle1"
                            className={classes.name}
                            style={{ flex: 1 }}
                        >
                            {vendor.name}
                        </Typography>
                        {vendor.in_house && (
                            <Tooltip title="In House">
                                <HouseOutlined />
                            </Tooltip>
                        )}
                    </Container>

                    <Container style={{ alignItems: 'center' }}>
                        <VendorServices
                            vendor={vendor}
                            theme={theme}
                            firstThreeServices={firstThreeServices}
                            remainingServices={remainingServices}
                        />
                        <Container style={{ flex: 1 }} />
                        <Tooltip title="Add Service">
                            <IconButton onClick={handleOpen} size="small">
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>
                        {organizationView && addVendorToApartment && (
                            <Tooltip title="Add Vendor to a Property">
                                <IconButton
                                    onClick={handleApartmentOpen}
                                    size="small"
                                >
                                    <PeopleOutline />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Container>
                </Container>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                padding: theme.spacing(2),
                            }}
                        >
                            Add Service
                        </Typography>
                        <Divider />
                        <List style={{ padding: theme.spacing(2) }}>
                            {availableServices.map((service) => (
                                <ListItem
                                    key={service.id}
                                    button
                                    onClick={() => handleAddService(service.id)}
                                >
                                    <ListItemText primary={service.name} />
                                </ListItem>
                            ))}
                        </List>
                        {availableServices.length === 0 && (
                            <Typography style={{ padding: theme.spacing(2) }}>
                                No additional services available
                            </Typography>
                        )}
                    </div>
                </Popover>
                <Popover
                    open={isApartmentOpen}
                    anchorEl={apartmentAnchorEl}
                    onClose={handleApartmentClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                padding: theme.spacing(2),
                            }}
                        >
                            Add Vendor to a Property
                        </Typography>
                        <Divider />
                        <List style={{ padding: theme.spacing(2) }}>
                            {availableApartments.map((apartment) => (
                                <ListItem
                                    key={apartment.id}
                                    button
                                    onClick={() => {
                                        addVendorToApartment?.({
                                            body: {
                                                vendor: vendor.id,
                                                apartment_id: apartment.id,
                                            },
                                        })
                                            .then(() => {
                                                toast.success(
                                                    `Vendor added to ${apartment.name}`,
                                                )
                                                handleApartmentClose()
                                            })
                                            .catch((e) => {
                                                toast.error(
                                                    `Failed to add vendor to ${apartment.name}`,
                                                )
                                            })
                                    }}
                                >
                                    <ListItemText primary={apartment.name} />
                                </ListItem>
                            ))}
                        </List>
                        {availableApartments.length === 0 && (
                            <Typography
                                style={{ padding: theme.spacing(0, 2, 2, 2) }}
                            >
                                No additional workspaces available
                            </Typography>
                        )}
                    </div>
                </Popover>
            </div>
        </Paper>
    )
}

interface VendorServicesProps {
    firstThreeServices: Service[]
    remainingServices: Service[]
    theme: Theme
    vendor: ListVendor
}

const VendorServices = (props: VendorServicesProps) => {
    const { firstThreeServices, remainingServices, theme, vendor } = props

    const classes = useStyles()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    return (
        <>
            <Container
                style={{ alignItems: 'center' }}
                className={classes.services}
            >
                {firstThreeServices.map((service) => {
                    return (
                        <Tooltip
                            title={service.name}
                            key={`SERVICE_${service.id}_${vendor.id}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={getServiceImageDetails(service.id).icon}
                                style={{
                                    maxHeight: 25,
                                    maxWidth: 30,
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        </Tooltip>
                    )
                })}
                {remainingServices.length > 0 && (
                    <Container>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOpen(e)
                            }}
                            size="small"
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                +{remainingServices.length}
                            </span>
                        </IconButton>
                    </Container>
                )}
            </Container>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
            >
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    {remainingServices.map((service) => {
                        return (
                            <Container
                                key={`SERVICE_${service.id}_${vendor.id}`}
                                style={{ alignItems: 'center' }}
                            >
                                <Container
                                    style={{
                                        width: 40,
                                    }}
                                >
                                    <img
                                        src={
                                            getServiceImageDetails(service.id)
                                                .icon
                                        }
                                        style={{
                                            maxHeight: 25,
                                            maxWidth: 30,
                                        }}
                                    />
                                </Container>
                                <Container>
                                    <span
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {service.name}
                                    </span>
                                </Container>
                            </Container>
                        )
                    })}
                </Container>
            </Popover>
        </>
    )
}
