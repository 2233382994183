import React from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import EzosLogo from '../../../../assets/ezos-logo-primary-dark.png'
import BackgroundImage from '../../../../assets/login_background.png'
import { Routes } from '../../../../helpers'
import { Container } from '../../../../components'

export const CheckEmailConfirmation = () => {
    const theme = useTheme()

    const history = useHistory()

    return (
        <Container
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    width: '100%',
                    position: 'fixed',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    zIndex: 99999,
                    top: 0,
                }}
            >
                <Container
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 800,
                    }}
                >
                    {/* Logo */}
                    <img
                        src={EzosLogo}
                        style={{
                            padding: theme.spacing(1),
                            height: 61,
                        }}
                    />
                    {/* Menu Items */}
                    <Container
                        style={{
                            justifyContent: 'flex-end',
                            marginRight: theme.spacing(2),
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <MenuItem
                            style={{
                                backgroundColor: 'transparent',
                                justifyContent: 'center',
                            }}
                            onClick={() => {
                                history.push(Routes.nationalVendorListFinal)
                            }}
                            onMouseEnter={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '80%'
                                }
                            }}
                            onMouseLeave={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '0'
                                }
                            }}
                        >
                            National Vendor List
                            <span
                                id="nvlLine"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: 0,
                                    height: '2px',
                                    backgroundColor: theme.palette.primary.dark,
                                    transition: 'width 0.3s ease',
                                }}
                            />
                        </MenuItem>
                        {/* Get Stated Button */}
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: theme.shape.borderRadius,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(2),
                                textTransform: 'none',
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border: `2px solid ${theme.palette.primary.dark}`,
                            }}
                            onClick={() => {
                                history.push(Routes.login)
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            Back to Log-in
                            <ArrowForwardIcon />
                        </Button>
                    </Container>
                </Container>
            </Container>
            <Container
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    maxWidth: 600,
                    borderRadius: 20,
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                }}
            >
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        ...theme.typography.h4,
                        color: theme.palette.grey[800],
                    }}
                >
                    An email has been sent with instructions to reset your
                    password.
                </span>
            </Container>
        </Container>
    )
}
