import { TableCell, TableRow, useTheme } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import React from 'react'
import {
    CellData,
    HeadCell,
    RenderRow,
    RowData,
    SimpleTable,
} from '../../components'
import { InspectionType } from '../../models'
import { useStyles } from '../../styles'

interface Props {
    selectedType?: InspectionType
    inspectionTypeList?: InspectionType[]
    setSelectedType: (type?: InspectionType) => void
    setModalOpen: (open: boolean) => void
    moveOutInspection: number | undefined
}

export const InspectionTypeTable = (props: Props) => {
    const {
        selectedType,
        setSelectedType,
        setModalOpen,
        inspectionTypeList,
        moveOutInspection,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface InspectionTypeRowType extends RowData {
        name: CellData<string>
        numInventory: CellData<number>
        id: CellData<number>
    }

    const headCells: HeadCell<InspectionTypeRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'numInventory',
            label: 'Inventory Items',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'right',
            id: 'numInventory',
            label: 'Move In Inspection',
            style: headCellStyle,
        },
    ]

    const createRow = (insType: InspectionType): InspectionTypeRowType => {
        return {
            name: { value: insType.name, sortValue: insType.name },
            numInventory: {
                value: insType.inventory_configs.length,
                sortValue: insType.inventory_configs.length,
            },
            insType: { value: insType, sortValue: 1 },
            id: { value: insType.id, sortValue: 1 },
        }
    }

    interface InsTypeRowProps {
        row: InspectionTypeRowType
    }

    const InsTypeRow: RenderRow<InsTypeRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`INV_CONF_ROW_${row.insType.value.id}`}
                style={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedType?.id === row.insType.value.id
                            ? theme.palette.grey[300]
                            : undefined,
                }}
                className={classes.hoverGrey300}
                onClick={() => {
                    setSelectedType(row.insType.value)
                    setModalOpen(true)
                }}
            >
                <TableCell style={{ ...cellStyle }}>{row.name.value}</TableCell>
                <TableCell align="center" style={{ ...cellStyle }}>
                    {row.numInventory.value}
                </TableCell>
                <TableCell
                    align="right"
                    style={{ ...cellStyle, paddingRight: theme.spacing(13) }}
                >
                    {moveOutInspection === row.id.value ? <Check /> : ''}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <SimpleTable<InspectionTypeRowType>
            rows={
                inspectionTypeList?.map((insType: InspectionType) =>
                    createRow(insType),
                ) ?? []
            }
            render={(row) => <InsTypeRow row={row} />}
            orderByDefault={'name'}
            headCells={headCells}
        />
    )
}
