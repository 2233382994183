import { Timeline } from '../models'
import { Range } from 'react-date-range'

const getComponents = (date: Date) => {
    const month = date.getMonth() + 1
    const day = date.getDate()

    let safeDay = day.toString()
    let safeMonth = month.toString()
    if (Math.floor(day / 10) === 0) {
        safeDay = `0${day}`
    }

    if (Math.floor(month / 10) === 0) {
        safeMonth = `0${month}`
    }

    return {
        day: safeDay,
        month: safeMonth,
        year: date.getFullYear(),
    }
}

export const toMMDDYYYY = (date: Date | undefined) => {
    if (date) {
        const c = getComponents(date)
        return `${c.month}-${c.day}-${c.year}`
    }
    return ''
}

export const toMMDD = (date: Date | undefined | null) => {
    if (date) {
        const c = getComponents(date)
        return `${c.month}-${c.day}`
    }
    return ''
}

export const toYYYYMMDD = (date: Date | undefined) => {
    if (date) {
        const c = getComponents(date)
        return `${c.year}-${c.month}-${c.day}`
    }
    return ''
}

export const toMMDDYY = (date: Date | undefined) => {
    if (date) {
        const c = getComponents(date)
        const yearShort = c.year.toString().slice(-2) // Get the last two digits of the year
        return `${c.month}-${c.day}-${yearShort}`
    }
    return ''
}

//takes a js date object and converts it to AM/PM time
export const toUiTime = (date: Date | undefined) => {
    if (date) {
        // the date is today, just need the time to display
        let hours = date.getHours()
        const minutes = date.getMinutes()
        const ampm = hours > 12 ? 'PM' : 'AM'

        hours = hours % 12 //get it out of 24 hr time
        hours = hours ? hours : 12 //if it is 0 instead its 12

        const minuteString = minutes < 10 ? '0' + minutes : minutes // minutes should always have 2 digits
        return `${hours}:${minuteString} ${ampm}`
    }
    return ''
}

export type IsTimelineConvertable = Timeline | Range

export const timelinesOverlap = (
    timeline1: IsTimelineConvertable,
    timeline2: IsTimelineConvertable,
) => {
    const safeT1 = isTimeline(timeline1)
        ? timeline1
        : convertRangeToTimeline(timeline1)
    const safeT2 = isTimeline(timeline2)
        ? timeline2
        : convertRangeToTimeline(timeline2)
    return (
        safeT1.startDate <= safeT2.endDate && safeT1.endDate >= safeT2.startDate
    )
}

export const isTimeline = (obj: any): obj is Timeline => {
    return (
        obj.start_date &&
        obj.start_date instanceof Date &&
        obj.end_date &&
        obj.end_date instanceof Date
    )
}

export const getSafeTimeline = (
    convertable: IsTimelineConvertable,
): Timeline => {
    if (isTimeline(convertable)) {
        return convertable
    }

    return convertRangeToTimeline(convertable)
}

export const convertRangeToTimeline = (range: Range): Timeline => {
    const date = new Date()
    // range.startDate and range.endDate can be undefined, if so just use now
    // should never be undefined in actual use
    return {
        startDate: range.startDate ?? date,
        endDate: range.endDate ?? date,
    }
}

export const compareDateToTimeline = (
    date: Date,
    timeline: IsTimelineConvertable,
) => {
    /**
     * Return -1 if date is less than time line, 0 if it intersects, and 1 if it is after
     */
    const safeTl = isTimeline(timeline)
        ? timeline
        : convertRangeToTimeline(timeline)

    if (date < safeTl.startDate) {
        return -1
    }

    if (date > safeTl.endDate) {
        return 1
    }

    return 0
}

export const toMonthYear = (date: Date | undefined) => {
    const month = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    if (date) {
        return `${month[date.getMonth()]} ${date.getFullYear()}`
    }

    return ''
}

export const getDifferenceInDays = (
    endDate: Date,
    startDate: Date,
    normalizeTime?: boolean,
): number => {
    if (normalizeTime) {
        const normalizedStart = startDate
        setMidnight(normalizedStart)
        const normalizedEnd = endDate
        setMidnight(normalizedEnd)

        return getDifferenceInDays(normalizedEnd, normalizedStart, false)
    }

    const DifferenceInTime = endDate.getTime() - startDate.getTime()
    const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24)
    if (DifferenceInDays < 0) {
        return 0
    }
    return Math.floor(DifferenceInDays)
}

export const dateValid = (date?: Date) => {
    if (date === undefined) {
        return false
    }

    return date instanceof Date && !isNaN(date.getTime())
}

export const getPreviousMonthDates = () => {
    const today = new Date()
    const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)

    // Handle the edge case where the previous month has 31 days and today is the 31st of the current month
    if (today.getDate() === 31 && previousMonth.getMonth() === 11) {
        previousMonth.setDate(30)
    }
    const endLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)

    return { startDate: previousMonth, endDate: endLastMonth }
}

export const setMidnight = (date: Date) => {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
}

export const setEndOfDay = (date: Date) => {
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
}

export const MS_PER_DAY = 24 * 60 * 60 * 1000

export const isDSTActive = (): boolean => {
    const today = new Date()
    const jan = new Date(today.getFullYear(), 0, 1)
    const jul = new Date(today.getFullYear(), 6, 1)
    const stdTimezoneOffset = Math.max(
        jan.getTimezoneOffset(),
        jul.getTimezoneOffset(),
    )

    return today.getTimezoneOffset() < stdTimezoneOffset
}

export const utcOffsetString = (
    utcOffsetSeconds: number,
    dstOffsetSeconds: number,
): string => {
    let totalOffsetSeconds = utcOffsetSeconds
    if (isDSTActive()) {
        totalOffsetSeconds += dstOffsetSeconds
    }

    const utcOffsetHours = Math.floor(Math.abs(totalOffsetSeconds) / 3600)
    const utcOffsetMinutes = Math.floor(
        (Math.abs(totalOffsetSeconds) % 3600) / 60,
    )
    const utcOffsetSign = totalOffsetSeconds < 0 ? '-' : '+'
    return `${utcOffsetSign}${utcOffsetHours
        .toString()
        .padStart(2, '0')}:${utcOffsetMinutes.toString().padStart(2, '0')}`
}

export const toLocaleString = (date: Date) => {
    return date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: undefined, // Exclude seconds
    })
}
