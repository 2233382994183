import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { useForm } from 'react-hook-form'
import LoopIcon from '@material-ui/icons/Loop'
import { useUser } from '../../../../hooks'
import { axiosInstance } from '../../../../helpers'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginRight: '5px',
        },
        saveBtn: {
            background: '#008C85',
            color: '#FFF',
            border: '1px solid #008C85',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
        },
        createConversationForm: {
            padding: '10px 0',
            '& .MuiFormControl-root': {
                margin: '5px 0',
            },
            '& .MuiFormLabel-root': {
                fontSize: '0.75rem',
                color: '#000',
                fontWeight: 700,
            },
            '& .MuiInputBase-input': {
                fontSize: '0.813rem',
            },
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
        inputDropdown: {},
    }),
)

interface State {
    companyIds: any
}

export const AddCompany = (props: any) => {
    const classes = useStyles()

    const { workspaceUser, rootUser, actions } = useUser()
    const { updateUser } = actions
    console.log('work space user companies.....', rootUser)

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [values, setValues] = React.useState<State>({
        companyIds: [],
    })

    const onSubmit = (data: any, event: any) => {
        event.preventDefault()
        console.log('company ids....', values.companyIds)
        setIsLoading(true)
        if (values.companyIds.length <= 0) {
            setIsError(true)
            setIsLoading(false)
            return false
        } else {
            props
                .addMember({
                    channelId: props.currentChannel?.id,
                    body: {
                        companies: [...values.companyIds],
                    },
                })
                .then((res: any) => {
                    setIsLoading(false)
                    props.handleClose()
                })
                .catch((err: any) => {
                    setIsLoading(false)
                })
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'all',
    })

    // const handleChange = (prop: keyof State, event: any) => {
    //     setValues({ ...values, [prop]: event.target.value })
    // }

    return (
        <>
            <Container style={{ padding: '0 8px' }}>
                <Typography
                    component="p"
                    style={{
                        fontSize: '0.75rem',
                        color: '#8A8A8A',
                        lineHeight: '1.9em',
                        margin: '15px 0',
                    }}
                >
                    Please enter companies name below.
                </Typography>
                <form
                    noValidate
                    autoComplete="off"
                    className={classes.createConversationForm}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormLabel component="legend">Company Name:</FormLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="company"
                            size="small"
                            options={props.vendorList ?? []}
                            getOptionLabel={(option) =>
                                option ? option.name : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Company"
                                />
                            )}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        color: '#000',
                                    }}
                                >
                                    {children}
                                </Paper>
                            )}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                newValues: any,
                            ) => {
                                if (newValues && newValues.length > 0) {
                                    if (isError) {
                                        setIsError(false)
                                    }
                                    const userIds: any = []
                                    newValues.forEach((element: any) => {
                                        userIds.push(element.id)
                                    })
                                    newValues
                                    setValues({
                                        ...values,
                                        companyIds: [...userIds],
                                    })
                                }
                            }}
                        />
                        <Box>
                            {isError && (
                                <span className="error-message">
                                    This is required field
                                </span>
                            )}
                        </Box>
                    </FormControl>

                    <div style={{ marginTop: '15px', textAlign: 'right' }}>
                        <Button
                            className={classes.closeBtn}
                            onClick={() => props.handleClose()}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#008C85',
                                color: '#FFF',
                                fontSize: '0.875rem',
                                textTransform: 'inherit',
                                fontWeight: 500,
                                borderRadius: '5px',
                                boxShadow: 'none',
                            }}
                            type="submit"
                        >
                            {isLoading ? (
                                <LoopIcon className={classes.rotateIcon} />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </form>
            </Container>
        </>
    )
}
