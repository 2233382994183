import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
    CalendarEventType,
    UserCalendarConfig,
} from '../containers/ScheduleHome/types'
import { axiosInstance } from '../helpers'
import { IdentifiableObject } from '../models'

export const useUserCalendarConfigs = () => {
    const [userCalendarConfigs, setUserCalendarConfigs] = useState<
        UserCalendarConfig[]
    >([])

    useEffect(() => {
        getUserCalendarConfigs()
    }, [])

    const getUserCalendarConfigs = async () => {
        try {
            const res = await axiosInstance.get(
                'ezos-calendar/user_calendar_config/',
            )
            const userCalendarConfigs: UserCalendarConfig[] = res.data
            setUserCalendarConfigs(userCalendarConfigs)
            return userCalendarConfigs
        } catch (e) {
            toast.error('Error getting user calendar configs')
            return Promise.reject(e)
        }
    }

    const createUserCalendarConfig = async (
        data: CreateUserCalendarConfigRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'ezos-calendar/user_calendar_config/',
                data,
            )
            const newUserCalendarConfig: UserCalendarConfig = res.data
            _insertOrUpdateIdentifiable(
                newUserCalendarConfig,
                setUserCalendarConfigs,
            )
            return newUserCalendarConfig
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const editUserCalendarConfig = async (
        data: UpdateUserCalendarConfigRequest,
    ) => {
        try {
            const res = await axiosInstance.patch(
                `ezos-calendar/user_calendar_config/${data.id}/`,
                data,
            )
            const updatedUserCalendarConfig: UserCalendarConfig = res.data
            _insertOrUpdateIdentifiable(
                updatedUserCalendarConfig,
                setUserCalendarConfigs,
            )
            return updatedUserCalendarConfig
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const deleteUserCalendarConfig = async (id: number) => {
        try {
            await axiosInstance.delete(
                `ezos-calendar/user_calendar_config/${id}/`,
            )
            setUserCalendarConfigs(
                userCalendarConfigs.filter((config) => config.id !== id),
            )
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        userCalendarConfigs,
        getUserCalendarConfigs,
        createUserCalendarConfig,
        deleteUserCalendarConfig,
        editUserCalendarConfig,
    }
}

export interface CreateUserCalendarConfigRequest {
    auto_display: boolean
    event_type: CalendarEventType
}

export interface UpdateUserCalendarConfigRequest {
    id: number
    auto_display: boolean
}
