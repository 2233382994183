import React from 'react'

import { BaseProps } from '../../types'
import { TemplateService } from '../../../../models'

import { Container } from '../../../Container'
import { VENDOR_BAR_H, MARGIN, DAY_CELL_W } from '../../constants'
import { TemplateVendorChartBar } from './TemplateVendorChartBar'
import { CalendarGridController } from '../../hooks'
import { GanttBar } from '../GanttBar'

interface Props extends BaseProps {
    templateService: TemplateService
    gridState: CalendarGridController
    prevVendorCount: number
}

export const TemplateServiceChartRow = (props: Props) => {
    const { templateService, prevVendorCount } = props

    const vendorCount = templateService.template_service_vendors.length

    let selfOffset = 0
    if (vendorCount > 0) {
        selfOffset = VENDOR_BAR_H
    }
    const topOffset = prevVendorCount * VENDOR_BAR_H + selfOffset

    const containerHeight = vendorCount * VENDOR_BAR_H

    return (
        <Container
            style={{
                top: topOffset,
                height: containerHeight,
                flexDirection: 'column',
                boxSizing: 'border-box',
                minHeight: VENDOR_BAR_H,
                position: 'relative',
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {templateService.template_service_vendors.map((vnd) => {
                return (
                    <TemplateVendorChartBar
                        key={`VND-BAR-${vnd.id}`}
                        {...props}
                        templateVendor={vnd}
                        templateService={templateService}
                        color={templateService.service.color}
                        margin={MARGIN}
                    />
                )
            })}
        </Container>
    )
}
