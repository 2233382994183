import React, { useEffect, useMemo, useState } from 'react'
import { Container } from '../../Container'
import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Tooltip,
    IconButton,
    FormHelperText,
} from '@material-ui/core'
import { SchedulerFinder } from '../../../containers/Scheduler_v2/SchedulerFinder'
import { TimelinePicker } from '../../TimelinePicker'
import {
    useDateRange,
    usePagination,
    useSelectVendorService,
} from '../../../hooks'
import { IdBoolMap, ModelMap, Unit, WorkorderType } from '../../../models'
import { Selector } from '../../Selector'
import {
    CreateWorkorderFormProps,
    FolderRowProps,
    UnitRowProps,
    AreaRowProps,
    FolderRowData,
} from './types'
import { AreaSelectorCollection } from '../../AreaSelector'
import { Pagination } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { Swiper } from '../../Swiper'

import { workorderContext } from '../../../contexts'
import { CreateWorkOrderRequest } from '../../../store'
import { AddCircleOutline } from '@material-ui/icons'
import { toast } from 'react-toastify'

export const CreateWorkorderForm = (props: CreateWorkorderFormProps) => {
    const {
        root,
        finderSelection,
        areaConfigMap,
        areaStatusTagMap,
        unitWorkorderMap,
        serviceList,
        vendorList,
        initialDate,
        theme,
        scheduleList,
        createEZNowRequest,
        apartmentList,
        getApartmentData,
        setCreateEZNowRequest,
        apartmentDataLoading,
        apartmentVendorMap,
        addVendorToApartment,
    } = props

    const { loadingState } = workorderContext()

    const units = useMemo(() => {
        return Object.keys(finderSelection.selection.unit).reduce<Unit[]>(
            (prev, unitKey) => {
                if (unitKey === 'length') {
                    return prev
                }

                const unitId = Number(unitKey)
                const unit = finderSelection.selection.unit[unitId]

                if (unit === undefined) {
                    return prev
                }

                return prev.concat(unit)
            },
            [],
        )
    }, [finderSelection.selection])

    const {
        page,
        pageData,
        setPage,
        numPages,
        start,
        end,
    } = usePagination<Unit>(units)

    const [areaSelectionState, setAreaSelectionState] = useState<IdBoolMap>({})

    const { dateRange, setEndDate, setStartDate, setRange } = useDateRange('')

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
    } = useSelectVendorService(vendorList)

    const [workOrderTitle, setWorkOrderTitle] = useState('')
    const [doValidation, setDoValidation] = useState(false)
    const [priority, setPriority] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedScheduleId, setSelectedScheduleId] = useState(-1)
    const [selectedApartmentId, setSelectedApartmentId] = useState(-1)

    useEffect(() => {
        const newAreaSelectionState: IdBoolMap = {}

        units.forEach((unit) => {
            unit.areas.forEach((a) => {
                newAreaSelectionState[a.id] = true
            })
        })

        setAreaSelectionState(newAreaSelectionState)
    }, [finderSelection.selection])

    useEffect(() => {
        if (initialDate) {
            setRange({ startDate: initialDate, endDate: initialDate })
        }
    }, [initialDate])

    const folderMap: ModelMap<FolderRowData> = {}

    pageData.forEach((unit) => {
        const folderId = unit.folder.id
        if (folderMap[folderId] === undefined) {
            folderMap[folderId] = {
                id: unit.folder.id,
                folderName: `${unit.folder.path}${unit.folder.name}`,
                unitList: [],
            }
        }
        folderMap[folderId]!.unitList.push(unit)
    })

    const folders = Object.keys(folderMap).reduce<FolderRowData[]>(
        (prev, key) => {
            const folderId = Number(key)

            const folder = folderMap[folderId]
            if (folder === undefined) {
                return prev
            }

            return prev.concat(folder)
        },
        [],
    )

    const [swiperState, setSwiperState] = useState({
        position: ASSIGN_TO_COMPANY,
        companyId: -1,
        userId: -1,
    })

    let workorderLength = 1
    if (units.length > 1) {
        workorderLength = units.length
    }

    const onSetEZNowRequest = () => {
        if (createEZNowRequest && setCreateEZNowRequest) {
            setCreateEZNowRequest(false)
        } else {
            if (setCreateEZNowRequest) {
                setCreateEZNowRequest(true)
            }
            setSwiperState({
                position: ASSIGN_TO_COMPANY,
                companyId: -1,
                userId: -1,
            })
        }
    }

    const vendorIsValidForApt = useMemo(() => {
        const selectedVendorId = swiperState.companyId
        if (selectedVendorId === -1 || selectedApartmentId === -1) {
            return true
        }
        if (apartmentVendorMap) {
            return apartmentVendorMap[selectedApartmentId]?.includes(
                selectedVendorId,
            )
        }
        return true
    }, [apartmentVendorMap, selectedApartmentId, swiperState.companyId])

    const handleAddVendor = () => {
        if (
            addVendorToApartment &&
            swiperState.companyId !== -1 &&
            selectedApartmentId !== -1
        ) {
            addVendorToApartment({
                body: {
                    vendor: swiperState.companyId,
                    apartment_id: selectedApartmentId,
                },
            })
                .then(() => {
                    toast.success('Vendor added to workspace')
                })
                .catch((err) => {
                    toast.error(err.response.data.message)
                })
        }
    }

    const canCompleteForm =
        selectedApartmentId !== -1 || !apartmentList || !getApartmentData

    return (
        <>
            <Container
                style={{
                    flexDirection: 'column',
                    width: 1500,
                    maxWidth: 'calc(100vw - 100px)',
                    height: 1100,
                    maxHeight: 'calc(100vh - 100px)',
                }}
            >
                {/* Primary Content Rows */}
                {apartmentDataLoading ? (
                    <Container
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                        }}
                    >
                        <CircularProgress size={100} />
                    </Container>
                ) : (
                    <Container
                        style={{
                            flex: 1,
                            overflowY: 'scroll',
                        }}
                    >
                        {/* Left Column: Workorder Details + Finder*/}
                        <Container
                            style={{
                                flexDirection: 'column',
                                width: 400,
                                borderRight: `1px solid ${theme.palette.grey[300]}`,
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    margin: theme.spacing(1),
                                }}
                            >
                                Create Workorders
                            </span>

                            {/* Apartment Selector */}
                            {apartmentList && getApartmentData && (
                                <Selector
                                    label="Apartment"
                                    data={apartmentList}
                                    searchable
                                    currentValue={selectedApartmentId}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        if (e.target.value === undefined) {
                                            setSelectedApartmentId(-1)
                                            return
                                        }

                                        setSelectedApartmentId(
                                            e.target.value as number,
                                        )

                                        getApartmentData(
                                            e.target.value as number,
                                        )
                                    }}
                                    getDisplayString={(apartment) =>
                                        apartment.name
                                    }
                                    customStyle={{
                                        formControl: {
                                            margin: theme.spacing(1),
                                        },
                                    }}
                                />
                            )}

                            <div
                                style={{
                                    margin: theme.spacing(1),
                                }}
                            >
                                {/*Workorder Title*/}
                                <TextField
                                    fullWidth
                                    label="Workorder Title (Required)"
                                    variant="outlined"
                                    value={workOrderTitle}
                                    onChange={(e) => {
                                        setWorkOrderTitle(e.target.value)
                                    }}
                                    inputProps={{ maxLength: 30 }}
                                    error={
                                        doValidation && workOrderTitle === ''
                                    }
                                    disabled={!canCompleteForm}
                                />
                            </div>

                            {/* First row vendor + service + date selections */}
                            <div style={{ margin: theme.spacing(1) }}>
                                {/*Date Picker*/}
                                <TimelinePicker
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    onChangeStartDate={setStartDate}
                                    onChangeEndDate={setEndDate}
                                    disabled={!canCompleteForm}
                                />
                            </div>

                            {/*Service Selector*/}
                            <Selector
                                label="Service"
                                data={serviceList}
                                searchable
                                currentValue={selectedServiceId}
                                onChange={(e) => {
                                    selectService(e.target.value as number)
                                }}
                                getDisplayString={(service) => service.name}
                                customStyle={{
                                    formControl: { margin: theme.spacing(1) },
                                }}
                                disabled={!canCompleteForm}
                            />
                            {/*Swiper to assign to vendor or my team member*/}

                            <Container
                                style={{
                                    flexDirection: 'column',
                                    margin: theme.spacing(1),
                                    border: `1px solid ${theme.palette.grey[500]}`,
                                    borderRadius: theme.shape.borderRadius,
                                }}
                            >
                                <Swiper
                                    width={376}
                                    style={{ overflow: 'hidden', margin: 4 }}
                                    position={swiperState.position}
                                    onChangePosition={() => {
                                        setSwiperState({
                                            ...swiperState,
                                            position: !swiperState.position,
                                        })
                                    }}
                                    leftItem={
                                        <FormControl
                                            fullWidth
                                            disabled={
                                                !canCompleteForm ||
                                                createEZNowRequest
                                            }
                                        >
                                            <span
                                                style={{
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightBold,
                                                }}
                                            >
                                                My Vendors
                                            </span>
                                            <Container>
                                                <Container
                                                    style={{
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Select
                                                        value={
                                                            swiperState.companyId
                                                        }
                                                        placeholder="My Vendors"
                                                        label="My Vendors"
                                                        onChange={(e) => {
                                                            setSwiperState({
                                                                ...swiperState,
                                                                companyId: Number(
                                                                    e.target
                                                                        .value,
                                                                ),
                                                            })
                                                        }}
                                                        error={
                                                            !vendorIsValidForApt
                                                        }
                                                    >
                                                        <MenuItem
                                                            key={`NO_VENDOR_SELECTED`}
                                                            value={-1}
                                                        >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        theme
                                                                            .palette
                                                                            .grey[700],
                                                                }}
                                                            >
                                                                Not Assigned (My
                                                                Vendors)
                                                            </span>
                                                        </MenuItem>
                                                        {props.vendorList.map(
                                                            (vnd) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`SELECT_VENDOR_${vnd.id}`}
                                                                        value={
                                                                            vnd.id
                                                                        }
                                                                    >
                                                                        {
                                                                            vnd.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            },
                                                        )}
                                                    </Select>
                                                    {!vendorIsValidForApt && (
                                                        <FormHelperText error>
                                                            Vendor is not valid
                                                            for the selected
                                                            apartment.
                                                        </FormHelperText>
                                                    )}
                                                </Container>
                                                {!vendorIsValidForApt && (
                                                    <Tooltip
                                                        title={`Add vendor to workspace`}
                                                    >
                                                        <IconButton
                                                            onClick={
                                                                handleAddVendor
                                                            }
                                                        >
                                                            <AddCircleOutline color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Container>
                                        </FormControl>
                                    }
                                    rightItem={
                                        <FormControl
                                            fullWidth
                                            disabled={
                                                !canCompleteForm ||
                                                createEZNowRequest
                                            }
                                        >
                                            <span
                                                style={{
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightBold,
                                                }}
                                            >
                                                My Team
                                            </span>
                                            <Select
                                                value={swiperState.userId}
                                                label="My Team"
                                                placeholder="My Team"
                                                onChange={(e) => {
                                                    setSwiperState({
                                                        ...swiperState,
                                                        userId: Number(
                                                            e.target.value,
                                                        ),
                                                    })
                                                }}
                                            >
                                                <MenuItem
                                                    key={`NO_USER_SELECTED`}
                                                    value={-1}
                                                >
                                                    <span
                                                        style={{
                                                            color:
                                                                theme.palette
                                                                    .grey[700],
                                                        }}
                                                    >
                                                        Not Assigned (My Team)
                                                    </span>
                                                </MenuItem>
                                                {props.userList.map((user) => {
                                                    return (
                                                        <MenuItem
                                                            key={`SELECT_USER_${user.id}`}
                                                            value={user.id}
                                                        >
                                                            {user.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    disabled={
                                        !canCompleteForm || createEZNowRequest
                                    }
                                />
                            </Container>
                            {scheduleList && (
                                <Selector
                                    label="Project"
                                    data={scheduleList}
                                    searchable
                                    currentValue={selectedScheduleId}
                                    onChange={(e) => {
                                        setSelectedScheduleId(
                                            e.target.value as number,
                                        )
                                    }}
                                    getDisplayString={(schedule) =>
                                        schedule.name
                                    }
                                    customStyle={{
                                        formControl: {
                                            margin: theme.spacing(1),
                                        },
                                    }}
                                    disabled={!canCompleteForm}
                                />
                            )}

                            {canCompleteForm && (
                                <SchedulerFinder
                                    root={root}
                                    finderSelection={finderSelection}
                                    areaConfigMap={areaConfigMap}
                                    areaStatusTagMap={areaStatusTagMap}
                                    unitWorkorderMap={unitWorkorderMap}
                                    style={{ padding: theme.spacing(1) }}
                                    defaultOpen
                                />
                            )}
                        </Container>

                        {/* Right Column: Selected Units and area selector */}
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                            }}
                        >
                            {/* Header */}
                            <Container
                                style={{
                                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                    padding: theme.spacing(2),
                                    paddingLeft: theme.spacing(3),
                                }}
                            >
                                {canCompleteForm && (
                                    <AreaSelectorCollection
                                        areaConfigMap={areaConfigMap}
                                        areaSelectionMap={areaSelectionState}
                                        setAreaSelectionMap={
                                            setAreaSelectionState
                                        }
                                        units={units}
                                    />
                                )}
                            </Container>

                            {/* Body */}
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    padding: theme.spacing(2),
                                    paddingLeft: theme.spacing(3),
                                    overflowY: 'scroll',
                                    flex: 1,
                                }}
                            >
                                {loadingState.createWorkorder ? (
                                    <Container
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flex: 1,
                                        }}
                                    >
                                        <CircularProgress size={150} />
                                    </Container>
                                ) : pageData.length === 0 ? (
                                    <Container
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flex: 1,
                                        }}
                                    >
                                        <span
                                            style={{
                                                ...theme.typography.h3,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Make a selection to get started
                                        </span>
                                    </Container>
                                ) : (
                                    folders.map((folder) => {
                                        return (
                                            <FolderRow
                                                key={`FOLDER-${folder.id}`}
                                                folder={folder}
                                                areaSelectionState={
                                                    areaSelectionState
                                                }
                                                setAreaSelectionState={
                                                    setAreaSelectionState
                                                }
                                                {...props}
                                            />
                                        )
                                    })
                                )}
                            </Container>
                        </Container>
                    </Container>
                )}

                {/* Footer */}
                <Divider />
                <Container
                    style={{ alignItems: 'center', padding: theme.spacing(1) }}
                >
                    <div style={{ flex: 1 }} />
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_, value) => setPage(value)}
                        disabled={!canCompleteForm}
                    />
                    <span>
                        {start} - {end} of {units.length}
                    </span>
                    <div style={{ flex: 1 }} />

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={priority}
                                onChange={() => setPriority(!priority)}
                                disabled={!canCompleteForm}
                            />
                        }
                        label="Priority"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={createEZNowRequest}
                                onChange={() => onSetEZNowRequest()}
                                disabled={!canCompleteForm}
                            />
                        }
                        label="Create EZNow Request"
                    />

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: loadingState.createWorkorder
                                ? theme.palette.grey[600]
                                : theme.palette.primary.main,
                            color: loadingState.createWorkorder
                                ? '#fff'
                                : theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        disabled={
                            loadingState.createWorkorder || !canCompleteForm
                        }
                        onClick={() => {
                            if (workOrderTitle === '') {
                                setDoValidation(true)
                                return
                            }
                            if (units.length === 0) {
                                setOpenDialog(true)
                                return
                            }
                            const request: CreateWorkOrderRequest = {
                                body: {
                                    title: workOrderTitle,
                                    workorder_type: WorkorderType.ACTION_ITEM,
                                    start_date: dateRange.startDate?.toISOString(),
                                    end_date: dateRange.endDate?.toISOString(),
                                    assign_to_type: swiperState.position
                                        ? 'user'
                                        : 'vendor',
                                    assign_to_id: swiperState.position
                                        ? swiperState.userId
                                        : swiperState.companyId,
                                },
                            }

                            if (selectedScheduleId !== -1 && scheduleList) {
                                request.body.workorder_type =
                                    WorkorderType.SERVICE_ORDER
                                request.body.schedule = selectedScheduleId
                            }

                            if (priority) {
                                request.body.priority = true
                            }

                            if (selectedServiceId !== -1) {
                                request.body.service = selectedServiceId
                            }

                            if (units.length !== 0) {
                                request.body.units = units.map((unit) => {
                                    const serviceAreas: number[] = []
                                    unit.areas.forEach((area) => {
                                        if (
                                            areaSelectionState[area.id] === true
                                        ) {
                                            serviceAreas.push(area.id)
                                        }
                                    })

                                    return {
                                        unit_id: unit.id,
                                        service_areas: serviceAreas,
                                    }
                                })
                            }

                            props.onClickCreate(request)
                        }}
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {`Create (${workorderLength})`}
                        </span>
                    </Button>
                </Container>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Create Workorder</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create a workorder without a
                        selected location?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: loadingState.createWorkorder
                                ? theme.palette.grey[600]
                                : theme.palette.primary.main,
                            color: loadingState.createWorkorder
                                ? '#fff'
                                : theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        disabled={
                            loadingState.createWorkorder || !canCompleteForm
                        }
                        onClick={() => {
                            const request: CreateWorkOrderRequest = {
                                body: {
                                    title: workOrderTitle,
                                    workorder_type: WorkorderType.ACTION_ITEM,
                                    start_date: dateRange.startDate?.toISOString(),
                                    end_date: dateRange.endDate?.toISOString(),
                                    assign_to_type: swiperState.position
                                        ? 'user'
                                        : 'vendor',
                                    assign_to_id: swiperState.position
                                        ? swiperState.userId
                                        : swiperState.companyId,
                                },
                            }

                            if (priority) {
                                request.body.priority = true
                            }

                            if (selectedServiceId !== -1) {
                                request.body.service = selectedServiceId
                            }

                            if (selectedScheduleId !== -1 && scheduleList) {
                                request.body.workorder_type =
                                    WorkorderType.SERVICE_ORDER
                                request.body.schedule = selectedScheduleId
                            }

                            props.onClickCreate(request)

                            setOpenDialog(false)
                        }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const FolderRow = (props: FolderRowProps) => {
    const { folder, theme } = props

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {folder.folderName}
            </span>
            {folder.unitList.map((unit) => {
                return (
                    <UnitRow key={`UNIT-${unit.id}`} unit={unit} {...props} />
                )
            })}
        </Container>
    )
}

const UnitRow = (props: UnitRowProps) => {
    const { unit, theme, unitConfigMap } = props

    const UR_BORDER_COLOR = theme.palette.grey[400]

    const unitConfig = unitConfigMap[unit.unit_config]

    return (
        <Container
            style={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                border: `1px solid ${UR_BORDER_COLOR}`,
                borderRadius: theme.shape.borderRadius,
            }}
        >
            {/* Unit Detail section */}
            <Container
                style={{
                    flexDirection: 'column',
                    borderRight: `1px solid ${UR_BORDER_COLOR}`,
                    padding: theme.spacing(1),
                    minWidth: 125,
                    maxWidth: 125,
                    backgroundColor: theme.palette.grey[200],
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        overflow: 'hidden',
                        maxLines: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {unit.name}
                </span>
                <span
                    style={{
                        ...theme.typography.subtitle2,
                        fontWeight: theme.typography.fontWeightLight,
                        overflow: 'hidden',
                        maxLines: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {unitConfig?.name}
                </span>
            </Container>

            {/* Area Section */}
            <Container
                style={{
                    overflowX: 'scroll',
                }}
            >
                {unit.areas.map((area) => {
                    return (
                        <AreaRow
                            key={`AREA-${area.id}`}
                            area={area}
                            {...props}
                        />
                    )
                })}
            </Container>
        </Container>
    )
}

const AreaRow = (props: AreaRowProps) => {
    const {
        area,
        theme,
        areaConfigMap,
        areaSelectionState,
        setAreaSelectionState,
    } = props

    const areaConfig = areaConfigMap[area.area_config]

    const checked = areaSelectionState[area.id] === true

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                flexDirection: 'column',
                minWidth: 124,
                maxWidth: 124,
                minHeight: 84,
                maxHeight: 84,
                cursor: 'pointer',
            }}
            onClick={() => {
                setAreaSelectionState({
                    ...areaSelectionState,
                    [area.id]:
                        areaSelectionState[area.id] === true ? false : true,
                })
            }}
        >
            <Container style={{ alignItems: 'center' }}>
                {/* Area Name */}
                <span
                    style={{
                        ...theme.typography.subtitle2,
                        fontWeight: theme.typography.fontWeightBold,
                        overflow: 'hidden',
                        maxLines: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {areaConfig?.name} {area.area_label}
                </span>
                <Checkbox checked={checked} />
            </Container>
        </Container>
    )
}

const ASSIGN_TO_USER = false
const ASSIGN_TO_COMPANY = true
