import React from 'react'

import { Theme } from '@material-ui/core'

import { AreaConfig } from '../../../models'
import { Container } from '../../../components'

interface Props {
    areaConfig: AreaConfig
    selected: boolean
    theme: Theme
    onClick: () => void
}

export const AreaConfigButton = (props: Props) => {
    const { areaConfig, selected, theme, onClick } = props

    return (
        <Container
            onClick={onClick}
            style={{
                padding: theme.spacing(1, 2, 1, 2),
                marginRight: theme.spacing(1),
                border: `1px solid ${theme.palette.primary.dark}`,
                borderRadius: '5px',
                backgroundColor: selected
                    ? theme.palette.primary.dark
                    : theme.palette.white.main,
                color: selected
                    ? theme.palette.white.main
                    : theme.palette.primary.dark,
                cursor: 'pointer',
                textAlign: 'center',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
                whiteSpace: 'nowrap',
                width: 'auto',
            }}
        >
            {areaConfig.name}
        </Container>
    )
}
