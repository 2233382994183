import React from 'react'
import { Redirect } from 'react-router-dom'
import { Routes } from '../config/routes'
import { User, WorkSpaceUser } from '../../models'

interface AuthGuardProps {
    children: React.ReactNode
    workspaceUser?: WorkSpaceUser
    rootUser?: User
}

export const AuthGuard: React.FC<AuthGuardProps> = ({
    children,
    workspaceUser,
    rootUser,
}) => {
    const isAuthenticated = () => {
        if (workspaceUser) {
            return workspaceUser && workspaceUser.id
        }

        return rootUser && rootUser.id
    }

    if (!isAuthenticated()) {
        return <Redirect to={Routes.public.login} />
    }

    return <>{children}</>
}
