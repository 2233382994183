import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react'
import {
    BidStatus,
    IdentifiableObject,
    Bid,
    RFP,
    RFPStatus,
    RFPType,
} from '../models'
import { VENDOR, axiosInstance, timelinesOverlap } from '../helpers'
import { toast } from 'react-toastify'
import { useUser } from '../hooks'

export interface IRFPContext {
    rfpList: RFP[] | null
    setRFPList: Dispatch<SetStateAction<RFP[] | null>>
    selectedRFP: RFP | null
    setSelectedRFP: Dispatch<SetStateAction<RFP | null>>
    clearState: () => void
    rfpFilterState: RFPFilterState
    setRFPFilterState: Dispatch<SetStateAction<RFPFilterState>>
    loadingState: RFPLoadingState
    setLoadingState: Dispatch<SetStateAction<RFPLoadingState>>
    bidList: Bid[] | null
    setBidList: Dispatch<SetStateAction<Bid[] | null>>
    selectedBid: Bid | null
    setSelectedBid: Dispatch<SetStateAction<Bid | null>>
    bidFilterState: BidFilterState
    setBidFilterState: Dispatch<SetStateAction<BidFilterState>>
    rfpAndBidCounts: RFPAndBidCounts | null
    setRFPAndBidCounts: Dispatch<SetStateAction<RFPAndBidCounts | null>>
    radius: number
    setRadius: Dispatch<SetStateAction<number>>
}

export const RFPContext = createContext<IRFPContext>(
    (null as unknown) as IRFPContext,
)

export const rfpApi = () => {
    const state = useContext(RFPContext)

    const { workspaceUser } = useUser()

    // RFP
    const getRFPList = async (request?: getRFPListRequest) => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getRFPList: true,
            }
        })

        axiosInstance
            .get(`service-contract/rfp/`, request)
            .then((res) => {
                state.setRFPList(res.data)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
            .finally(() => {
                state.setLoadingState((prev) => {
                    return {
                        ...prev,
                        getRFPList: false,
                    }
                })
            })
    }

    const createRFP = async (
        body: CreateOrUpdateRFPRequest,
        onCreate?: () => void,
    ) => {
        axiosInstance
            .post(`service-contract/rfp/`, body)
            .then((res) => {
                state.setSelectedRFP(res.data)
                _insertOrUpdateIdentifiable(res.data, state.setRFPList)
                if (onCreate) {
                    onCreate()
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const updateRFP = async (body: CreateOrUpdateRFPRequest): Promise<RFP> => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .patch(`service-contract/rfp/${state.selectedRFP?.id}/`, body)
                .then((res) => {
                    state.setSelectedRFP(res.data)
                    _insertOrUpdateIdentifiable(res.data, state.setRFPList)
                    resolve(res.data)
                })
                .catch((err) => {
                    console.log('FOO')
                    toast.error(err.response.data.message)
                })
        })
    }

    const deleteRFP = async (rfp: RFP) => {
        axiosInstance
            .post(`service-contract/rfp/${rfp.id}/delete_rfp_draft/`)
            .then(() => {
                toast.success(`${rfp.name} deleted successfully!`)
                _removeIdentifiable(rfp, state.setRFPList)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const getRFPDetail = async (rfp: RFP) => {
        axiosInstance.get(`service-contract/rfp/${rfp.id}/`).then((res) => {
            state.setSelectedRFP(res.data)
            _insertOrUpdateIdentifiable(res.data, state.setRFPList)
        })
    }

    const uploadRFPDocument = async (rfp: RFP, body: FormData) => {
        axiosInstance
            .post(`service-contract/rfp/${rfp.id}/upload_rfp_document/`, body)
            .then((res) => {
                state.setSelectedRFP(res.data)
                _insertOrUpdateIdentifiable(res.data, state.setRFPList)
            })
    }

    const editRFPDocument = async (rfp: RFP, body: EditRFPDocumentRequest) => {
        axiosInstance
            .post(`service-contract/rfp/${rfp.id}/edit_rfp_document/`, body)
            .then((res) => {
                state.setSelectedRFP(res.data)
                _insertOrUpdateIdentifiable(res.data, state.setRFPList)
            })
    }

    const updateRFPState = (rfp: RFP) => {
        _insertOrUpdateIdentifiable(rfp, state.setRFPList)
    }

    const filteredRFPList = useMemo(() => {
        const filter = state.rfpFilterState
        const rfps = state.rfpList ?? []

        return rfps.filter((rfp) => {
            let serviceValid = false
            if (filter.serviceIds.length !== 0) {
                rfp.rfp_services.forEach((rfpService) => {
                    if (
                        filter.serviceIds.find(
                            (sId) => rfpService.service.id === sId,
                        )
                    ) {
                        serviceValid = true
                    }
                })
            } else {
                serviceValid = true
            }

            let searchTextValid = false
            if (filter.searchText !== '') {
                const name = rfp.name.toLocaleLowerCase()
                const description = rfp.description.toLocaleLowerCase()
                const searchText = filter.searchText.toLocaleLowerCase()

                searchTextValid =
                    name.includes(searchText) ||
                    description.includes(searchText)
            } else {
                searchTextValid = true
            }

            const dateValid =
                !filter.timeline.enabled ||
                (rfp.project_start_date !== null &&
                    rfp.project_end_date !== null &&
                    timelinesOverlap(
                        {
                            startDate: new Date(rfp.project_start_date),
                            endDate: new Date(rfp.project_end_date),
                        },
                        {
                            startDate: filter.timeline.startDate,
                            endDate: filter.timeline.endDate,
                        },
                    ))

            const statusValid =
                filter.statusFilter === null ||
                rfp.status === filter.statusFilter

            return serviceValid && searchTextValid && dateValid && statusValid
        })
    }, [state.rfpList, state.rfpFilterState])

    // Bid
    const getBidList = async (request?: getBidListRequest) => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getBidList: true,
            }
        })

        axiosInstance
            .get(`service-contract/bid/`, request)
            .then((res) => {
                state.setBidList(res.data)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
            .finally(() => {
                state.setLoadingState((prev) => {
                    return {
                        ...prev,
                        getBidList: false,
                    }
                })
            })
    }

    const createBid = async (body: updateOrCreateBidRequest, rfp: RFP) => {
        axiosInstance
            .post(`service-contract/bid/`, body)
            .then((res) => {
                state.setSelectedBid(res.data)
                updateBidState(res.data)
                _removeIdentifiable(rfp, state.setRFPList)
                toast.success(`Bid for ${rfp.name} submitted successfully!`)
                if (workspaceUser?.active_workspace.company_type === VENDOR) {
                    getRFPAndBidCounts(state.radius)
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const updateBid = async (
        id: number,
        body: updateOrCreateBidRequest,
        rfp: RFP,
    ) => {
        axiosInstance
            .patch(`service-contract/bid/${id}/`, body)
            .then((res) => {
                if (body.status === BidStatus.SUBMITTED) {
                    toast.success(`Bid for ${rfp.name} submitted successfully!`)
                } else if (body.status === BidStatus.DECLINED) {
                    toast.success(
                        `Request for ${rfp.name} declined successfully!`,
                    )
                } else {
                    toast.success(`Bid updated successfully`)
                }
                if (workspaceUser?.active_workspace.company_type === VENDOR) {
                    getRFPAndBidCounts()
                }
                updateBidState(res.data)
                state.setSelectedBid(res.data)
                if (rfp.bids) {
                    const bids = rfp.bids.map((bid) => {
                        if (bid.id === res.data.id) {
                            return res.data
                        }
                        return bid
                    })
                    const updatedRfp = { ...rfp, bids: bids }
                    state.setSelectedRFP(updatedRfp)
                    _insertOrUpdateIdentifiable(
                        {
                            ...rfp,
                            bids: bids,
                        },
                        state.setRFPList,
                    )
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const updateBidState = (bid: Bid) => {
        _insertOrUpdateIdentifiable(bid, state.setBidList)
    }

    const filteredBidList = useMemo(() => {
        const filter = state.bidFilterState
        const bids = state.bidList ?? []

        return bids.filter((bid) => {
            let statusValid = true
            if (
                filter.statusFilter === null ||
                filter.statusFilter.length === 0
            ) {
                statusValid = true
            } else {
                statusValid = filter.statusFilter.includes(bid.status)
            }

            const dateValid =
                !filter.timeline.enabled ||
                (bid.rfp.project_start_date !== null &&
                    bid.rfp.project_end_date !== null &&
                    timelinesOverlap(
                        {
                            startDate: new Date(bid.rfp.project_start_date),
                            endDate: new Date(bid.rfp.project_end_date),
                        },
                        {
                            startDate: filter.timeline.startDate,
                            endDate: filter.timeline.endDate,
                        },
                    ))

            const apartmentValid =
                filter.apartmentIds.length === 0 ||
                filter.apartmentIds.find((id) => id === bid.rfp.apartment.id)

            let serviceValid = false
            if (filter.serviceIds.length !== 0) {
                bid.rfp.rfp_services.forEach((rfpService) => {
                    if (
                        filter.serviceIds.find(
                            (sId) => rfpService.service.id === sId,
                        )
                    ) {
                        serviceValid = true
                    }
                })
            } else {
                serviceValid = true
            }

            const unsolicitedValid =
                filter.includeUnsolicited || !bid.is_unsolicited

            return (
                statusValid &&
                dateValid &&
                apartmentValid &&
                serviceValid &&
                unsolicitedValid
            )
        })
    }, [state.bidList, state.bidFilterState])

    // counts
    const getRFPAndBidCounts = async (radius?: number) => {
        let request = {}
        if (radius) {
            request = { radius: radius }
        }
        axiosInstance
            .get(`service-contract/rfp/get_rfp_and_bid_counts/`, request)
            .then((res) => state.setRFPAndBidCounts(res.data))
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    // ********* Private state setting functions *********
    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue.id)
        })
    }

    return {
        // Expose state variables
        rfpList: state.rfpList,
        selectedRFP: state.selectedRFP,
        setSelectedRFP: state.setSelectedRFP,
        bidList: state.bidList,
        selectedBid: state.selectedBid,
        setBidList: state.setBidList,
        setSelectedBid: state.setSelectedBid,
        radius: state.radius,
        setRadius: state.setRadius,

        // RFP
        getRFPList,
        createRFP,
        updateRFP,
        deleteRFP,
        getRFPDetail,
        updateRFPState,
        uploadRFPDocument,
        editRFPDocument,
        filteredRFPList,

        //Filters
        rfpFilterState: state.rfpFilterState,
        setRFPFilterState: state.setRFPFilterState,
        bidFilterState: state.bidFilterState,
        setBidFilterState: state.setBidFilterState,

        // Bid
        updateBid,
        getBidList,
        updateBidState,
        createBid,
        filteredBidList,

        //Helpers
        clearState: state.clearState,
        loadingState: state.loadingState,

        //Counts
        getRFPAndBidCounts,
        rfpAndBidCounts: state.rfpAndBidCounts,
        setRFPAndBidCounts: state.setRFPAndBidCounts,
    }
}

export const useRFPState = (): IRFPContext => {
    const [rfpList, setRFPList] = useState<RFP[] | null>(null)
    const [selectedRFP, setSelectedRFP] = useState<RFP | null>(null)

    const [rfpFilterState, setRFPFilterState] = useState<RFPFilterState>(
        initialRFPFilterState,
    )

    const [bidList, setBidList] = useState<Bid[] | null>(null)
    const [selectedBid, setSelectedBid] = useState<Bid | null>(null)

    const [bidFilterState, setBidFilterState] = useState<BidFilterState>(
        initialBidFilterState,
    )

    const [loadingState, setLoadingState] = useState<RFPLoadingState>({
        getRFPList: false,
        getBidList: false,
    })

    const [
        rfpAndBidCounts,
        setRFPAndBidCounts,
    ] = useState<RFPAndBidCounts | null>(null)

    const [radius, setRadius] = useState(100)

    const clearState = () => {
        setRFPList([])
        setSelectedRFP(null)
        setRFPFilterState(initialRFPFilterState)
        setBidList(null)
        setBidFilterState(initialBidFilterState)
    }

    return {
        rfpList,
        selectedRFP,
        rfpFilterState,
        loadingState,
        bidList,
        selectedBid,
        bidFilterState,
        rfpAndBidCounts,
        radius,
        setRFPList,
        setSelectedRFP,
        clearState,
        setRFPFilterState,
        setLoadingState,
        setBidList,
        setSelectedBid,
        setBidFilterState,
        setRFPAndBidCounts,
        setRadius,
    }
}

export interface CreateOrUpdateRFPRequest {
    name?: string
    type?: RFPType
    services?: { id: number }[]
    project_start_date?: string
    project_end_date?: string
    project_submission_deadline?: string
    budget?: number
    description?: string
    submit?: boolean
    vendors?: { id: number }[]
    service_details?: {
        id: number
        rfp_service: number
        question_description: string
        question_answer: string
        question_config?: number
    }[]
    workorders?: { id: number }[]
    accepted_bid?: number
    status?: RFPStatus
    apartment_questions?: {
        id?: number
        question_description: string
    }[]
}

export interface getRFPListRequest {
    params?: {
        type?: string
        statuses?: string
        bid_statuses?: string
        open_jobs?: boolean
        radius?: number
        all_published_rfps?: boolean
        unsolicited_jobs?: boolean
    }
}

export interface updateOrCreateBidRequest {
    status?: BidStatus
    group_bid_price?: number
    bid_line_items?: createOrUpdateBidLineItemRequest[]
    vendor_answers?: {
        id?: number
        apartment_question: number
        answer_description: string
    }[]
    rfp?: number
}

export interface createOrUpdateBidLineItemRequest {
    id?: number
    rfp_service?: number
    description?: string
    price: number
    apartment_question?: number
}

export interface RFPFilterState {
    statusFilter: RFPStatus | null
    searchText: string
    serviceIds: number[]
    timeline: {
        enabled: boolean
        startDate: Date
        endDate: Date
    }
}

const initialRFPFilterState: RFPFilterState = {
    searchText: '',
    serviceIds: [],
    timeline: {
        enabled: false,
        startDate: new Date(),
        endDate: new Date(),
    },
    statusFilter: null,
}

interface RFPLoadingState {
    getRFPList: boolean
    getBidList: boolean
}

export interface getBidListRequest {
    params?: {
        status?: string
        rfp_type?: string
        rfp?: number
    }
}

export interface BidFilterState {
    statusFilter: BidStatus[] | null
    apartmentIds: number[]
    serviceIds: number[]
    timeline: {
        enabled: boolean
        startDate: Date
        endDate: Date
    }
    includeUnsolicited: boolean
}

const initialBidFilterState: BidFilterState = {
    statusFilter: null,
    serviceIds: [],
    apartmentIds: [],
    timeline: {
        enabled: false,
        startDate: new Date(),
        endDate: new Date(),
    },
    includeUnsolicited: false,
}

export interface EditRFPDocumentRequest {
    document_id: number
    public?: boolean
    remove?: boolean
}

export interface RFPAndBidCounts {
    ez_now_count: number | null
    open_job_count: number | null
    unsolicited_job_count: number | null
    invited_bid_count: number | null
}
