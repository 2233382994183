import { IdentifiableNamedObject } from '../types'

export const PERMISSION_FALSE = 0
export const PERMISSION_TRUE = 1
export const PERMISSION_SUPER_FALSE = -1

export type PERMISSION_OPTIONS =
    | typeof PERMISSION_FALSE
    | typeof PERMISSION_SUPER_FALSE
    | typeof PERMISSION_TRUE

export const EDIT_WORKORDERS = 'edit_workorders'
export const CREATE_WORKORDERS = 'create_workorders'
export const DELETE_WORKORDERS = 'delete_workorders'

export const APPROVE_DENY_CHANGE_ORDER = 'approve_deny_changeorder'

export const USE_MOBILE = 'use_mobile'
export const USE_WEB = 'use_web'

export const EDIT_USER_PERMISSIONS = 'edit_user_permissions'
export const EDIT_USER = 'edit_user'
export const EDIT_PERMISSION_ROLES = 'edit_permission_roles'

export const ASSIGNED_TO_IN_PROGRESS = 'assigned_to_in_progress'

export const IN_PROGRESS_TO_ASSIGNED = 'in_progress_to_assigned'
export const IN_PROGRESS_TO_PAUSE = 'in_progress_to_pause'
export const IN_PROGRESS_TO_COMPLETE = 'in_progress_to_complete'

export const PAUSE_TO_IN_PROGRESS = 'pause_to_in_progress'

export const COMPLETE_TO_APPROVED = 'complete_to_approved'
export const COMPLETE_TO_PRE_APPROVED = 'complete_to_pre_approved'
export const COMPLETE_TO_GO_BACK = 'complete_to_go_back'

export const PRE_APPROVED_TO_GO_BACK = 'pre_approved_to_go_back'
export const PRE_APPROVED_TO_APPROVED = 'pre_approved_to_approved'

export const GO_BACK_TO_IN_PROGRESS = 'go_back_to_in_progress'

export const APPROVED_TO_INVOICED = 'approved_to_invoiced'

export const SEE_ACTION_ITEMS = 'see_action_items'
export const SEE_INSPECTION = 'see_inspections'
export const SEE_VENDOR_FINANCES = 'see_vendor_finances'
export const SEE_INCOME_FINANCES = 'see_income_finances'

export const VIEW_NATIONAL_VENDOR_LIST = 'view_national_vendor_list'
export const VIEW_INVOICE = 'view_invoice'
export const RECEIVE_DAILY_REPORT = 'receive_daily_report'
export const BULK_TRANSITION = 'super_transition'

export const DMG_PENDING_TO_DENIED = 'dmg_pending_to_denied'
export const DMG_PENDING_TO_PREAPPROVED = 'dmg_pending_to_preapproved'

export const DMG_PREAPPROVED_TO_DENIED = 'dmg_preapproved_to_denied'
export const DMG_PREAPPROVED_TO_APPROVED = 'dmg_preapproved_to_approved'

export const DMG_APPROVED_TO_SYNCED = 'dmg_approved_to_synced'

export const EDIT_VENDOR_SUBSCRIPTION_STATUS = 'edit_vendor_subscription_status'

export const INCIDENT_REPORTS = 'incident_reports'
export const VENDOR_INVOICE_PERMISSION = 'vendor_invoice_permission'
export const SMART_SCHEDULER_PERMISSION = 'smart_scheduler_permission'

export const SUPER_TRANSITION_REVERSAL = 'super_transition_reversal'
export const DELETE_DAMAGES_PERMISSION = 'delete_damages'

export const APARTMENT_ONLY_PERMISSIONS = {
    [EDIT_WORKORDERS]: true,
    [CREATE_WORKORDERS]: true,
    [DELETE_WORKORDERS]: true,
    [COMPLETE_TO_APPROVED]: true,
    [COMPLETE_TO_PRE_APPROVED]: true,
    [COMPLETE_TO_GO_BACK]: true,
    [PRE_APPROVED_TO_GO_BACK]: true,
    [PRE_APPROVED_TO_APPROVED]: true,
    [APPROVE_DENY_CHANGE_ORDER]: true,
    [VIEW_NATIONAL_VENDOR_LIST]: true,
    [RECEIVE_DAILY_REPORT]: true,
    [SEE_INCOME_FINANCES]: true,
    [SEE_VENDOR_FINANCES]: true,
    [DMG_PENDING_TO_DENIED]: true,
    [DMG_PENDING_TO_PREAPPROVED]: true,
    [DMG_PREAPPROVED_TO_APPROVED]: true,
    [DMG_PREAPPROVED_TO_DENIED]: true,
    [DMG_APPROVED_TO_SYNCED]: true,
    [INCIDENT_REPORTS]: true,
    [SMART_SCHEDULER_PERMISSION]: true,
    [SUPER_TRANSITION_REVERSAL]: true,
    [DELETE_DAMAGES_PERMISSION]: true,
}

export const VENDOR_ONLY_PERMISSIONS = {
    [APPROVED_TO_INVOICED]: true,
    [EDIT_VENDOR_SUBSCRIPTION_STATUS]: true,
    [VENDOR_INVOICE_PERMISSION]: true,
}

export const ALL_PERMISSIONS = [
    EDIT_WORKORDERS,
    CREATE_WORKORDERS,
    DELETE_WORKORDERS,
    COMPLETE_TO_APPROVED,
    COMPLETE_TO_PRE_APPROVED,
    COMPLETE_TO_GO_BACK,
    PRE_APPROVED_TO_GO_BACK,
    PRE_APPROVED_TO_APPROVED,
    APPROVED_TO_INVOICED,
    USE_MOBILE,
    USE_WEB,
    ASSIGNED_TO_IN_PROGRESS,
    IN_PROGRESS_TO_ASSIGNED,
    IN_PROGRESS_TO_PAUSE,
    IN_PROGRESS_TO_COMPLETE,
    GO_BACK_TO_IN_PROGRESS,
    PAUSE_TO_IN_PROGRESS,
    EDIT_USER_PERMISSIONS,
    EDIT_USER,
    EDIT_PERMISSION_ROLES,
    SEE_ACTION_ITEMS,
    SEE_VENDOR_FINANCES,
    SEE_INSPECTION,
    APPROVE_DENY_CHANGE_ORDER,
    VIEW_NATIONAL_VENDOR_LIST,
    RECEIVE_DAILY_REPORT,
    VIEW_INVOICE,
    SEE_INCOME_FINANCES,
    DMG_PENDING_TO_PREAPPROVED,
    DMG_PENDING_TO_DENIED,
    DMG_PREAPPROVED_TO_DENIED,
    DMG_PREAPPROVED_TO_APPROVED,
    DMG_APPROVED_TO_SYNCED,
    BULK_TRANSITION,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    INCIDENT_REPORTS,
    VENDOR_INVOICE_PERMISSION,
    SMART_SCHEDULER_PERMISSION,
    SUPER_TRANSITION_REVERSAL,
    DELETE_DAMAGES_PERMISSION,
] as const

export type Permissible = {
    [key in typeof ALL_PERMISSIONS[number]]: PERMISSION_OPTIONS
}

export interface PermissionRole extends Permissible, IdentifiableNamedObject {
    organization: number
}

export const defaultPermissible: Permissible = {
    edit_workorders: PERMISSION_FALSE,
    create_workorders: PERMISSION_FALSE,
    delete_workorders: PERMISSION_FALSE,
    use_mobile: PERMISSION_FALSE,
    use_web: PERMISSION_FALSE,
    assigned_to_in_progress: PERMISSION_FALSE,
    in_progress_to_assigned: PERMISSION_FALSE,
    in_progress_to_pause: PERMISSION_FALSE,
    in_progress_to_complete: PERMISSION_FALSE,
    pause_to_in_progress: PERMISSION_FALSE,
    complete_to_approved: PERMISSION_FALSE,
    complete_to_pre_approved: PERMISSION_FALSE,
    complete_to_go_back: PERMISSION_FALSE,
    pre_approved_to_go_back: PERMISSION_FALSE,
    pre_approved_to_approved: PERMISSION_FALSE,
    approved_to_invoiced: PERMISSION_FALSE,
    go_back_to_in_progress: PERMISSION_FALSE,
    edit_user: PERMISSION_FALSE,
    edit_user_permissions: PERMISSION_FALSE,
    edit_permission_roles: PERMISSION_FALSE,
    see_action_items: PERMISSION_FALSE,
    see_vendor_finances: PERMISSION_FALSE,
    see_inspections: PERMISSION_FALSE,
    approve_deny_changeorder: PERMISSION_FALSE,
    view_invoice: PERMISSION_FALSE,
    receive_daily_report: PERMISSION_FALSE,
    view_national_vendor_list: PERMISSION_FALSE,
    see_income_finances: PERMISSION_FALSE,
    dmg_pending_to_denied: PERMISSION_FALSE,
    dmg_pending_to_preapproved: PERMISSION_FALSE,
    dmg_preapproved_to_denied: PERMISSION_FALSE,
    dmg_preapproved_to_approved: PERMISSION_FALSE,
    dmg_approved_to_synced: PERMISSION_FALSE,
    super_transition: PERMISSION_FALSE,
    edit_vendor_subscription_status: PERMISSION_FALSE,
    incident_reports: PERMISSION_FALSE,
    vendor_invoice_permission: PERMISSION_FALSE,
    smart_scheduler_permission: PERMISSION_FALSE,
    super_transition_reversal: PERMISSION_FALSE,
    delete_damages: PERMISSION_FALSE,
}
