import React, { useEffect, useMemo, useState } from 'react'
import { ListVendor } from '../../../../models'
import { Container, Selector } from '../../../../components'
import { useTheme } from '@material-ui/core'
import { CompanyHourDisplay } from '../../../../containers/VendorSettings/CompanyHourDisplay'

interface Props {
    vendorList: ListVendor[]
    setVendorList: (vendorList: ListVendor[]) => void
}

export const InHouseVendorHours = (props: Props) => {
    const { vendorList, setVendorList } = props

    const [selectedVendor, setSelectedVendor] = useState<ListVendor | null>(
        null,
    )

    const inHouseVendors = useMemo(() => {
        return vendorList.filter((v) => v.in_house)
    }, [vendorList])

    useEffect(() => {
        if (inHouseVendors.length > 0) {
            setSelectedVendor(inHouseVendors[0])
        }
    }, [inHouseVendors.length])

    const theme = useTheme()

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                padding: theme.spacing(1),
            }}
        >
            <Selector
                label="Vendor"
                data={inHouseVendors}
                searchable
                currentValue={selectedVendor?.id ?? -1}
                onChange={(e) => {
                    const vendorId = e.target.value as number
                    const vendor = inHouseVendors.find((v) => v.id === vendorId)
                    if (vendor) {
                        setSelectedVendor(vendor)
                    }
                }}
                getDisplayString={(vendor) => vendor.name}
                customStyle={{
                    formControl: { margin: theme.spacing(1, 1, 2, 1) },
                }}
            />

            {selectedVendor && (
                <CompanyHourDisplay
                    company={selectedVendor}
                    onAfterUpdate={(companyHours) => {
                        const updatedVendor = {
                            ...selectedVendor,
                            company_hours: companyHours,
                        }
                        setVendorList(
                            vendorList.map((v) => {
                                if (v.id === selectedVendor.id) {
                                    return updatedVendor
                                }
                                return v
                            }),
                        )
                    }}
                />
            )}
        </Container>
    )
}
