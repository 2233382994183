import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '.'
import { axiosInstance } from '../helpers'
import {
    InspectionStatusType,
    Lease,
    LeaseClusterResponse,
    LeaseStatus,
    LeaseStatusType,
    ModelListMap,
} from '../models'
import {
    getLeaseList,
    GetLeaseListRequest,
    GET_LEASE_LIST_REQUEST,
    RootState,
    setLeaseList,
    bulkUpdateLeaseRequest,
    BulkUpdateLeaseRequest,
    BulkCreateLeaseRequest,
    bulkCreateLease,
    getVacantAreas,
    GetVacantAreaRequest,
    CreateDamageReqeust,
    createDamageRequest,
    GetDamageRequest,
    getDamageListRequest,
    setDamageList,
    GetTenantListRequest,
    getTenantList,
    setTenantList,
    GET_TENANT_LIST_REQUEST,
    updateLeases,
    UpdateLeaseRequest,
} from '../store'
import { toast } from 'react-toastify'

interface Options {
    getLeaseList?: GetLeaseListRequest
    getVacantAreas?: GetVacantAreaRequest
    createAreaLeaseMap?: boolean
    getDamageListForInspecitonId?: number
    cleanUp?: boolean
}

export const _useLease = (options?: Options) => {
    const leaseList = useSelector((state: RootState) => state.lease.leaseList)
    const tenantList = useSelector((state: RootState) => state.lease.tenantList)

    const vacantAreas = useSelector(
        (state: RootState) => state.lease.vacantAreas,
    )
    const leaseListPagination = useSelector(
        (state: RootState) => state.pagination.GET_LEASE_LIST_REQUEST,
    )
    const leaseListLoading = useSelector(
        (state: RootState) => state.lease.isLoading[GET_LEASE_LIST_REQUEST],
    )

    const tenantListLoading = useSelector(
        (state: RootState) => state.lease.isLoading[GET_TENANT_LIST_REQUEST],
    )

    const damageList = useSelector((state: RootState) => state.lease.damageList)

    const [areaLeaseMap, setAreaLeaseMap] = useState<ModelListMap<Lease>>({})

    const [leasesLoaded, setLeasesLoaded] = useState(false)
    const [damagesLoaded, setDamagesLoaded] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (options?.getLeaseList) {
            dispatch(getLeaseList(options.getLeaseList)).then(() =>
                setLeasesLoaded(true),
            )
        }

        if (options?.getDamageListForInspecitonId) {
            dispatch(
                getDamageListRequest({
                    params: {
                        inspection: options.getDamageListForInspecitonId,
                    },
                }),
            ).then(() => setDamagesLoaded(true))
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setLeaseList(true))
                dispatch(setDamageList(undefined))
            }
        }
    }, [options?.getLeaseList, options?.getDamageListForInspecitonId])

    useEffect(() => {
        if (options?.createAreaLeaseMap) {
            const areaLeaseMap: ModelListMap<Lease> = { readyForUse: true }
            leaseList?.forEach((lease) => {
                if (areaLeaseMap[lease.area.id] === undefined) {
                    areaLeaseMap[lease.area.id] = []
                }
                areaLeaseMap[lease.area.id]!.push(lease)
            })

            setAreaLeaseMap(areaLeaseMap)
        }
    }, [leaseList, options?.createAreaLeaseMap])

    const transitionLease = (leaseId: number, toStatus: LeaseStatusType) => {
        return new Promise<Lease>((resolve, reject) => {
            axiosInstance
                .post(`lease/${leaseId}/transition/`, { status: toStatus })
                .then((res) => {
                    dispatch(updateLeases([res.data]))
                    resolve(res.data)
                })
                .catch(reject)
        })
    }

    return {
        leaseList: leaseList,
        areaLeaseMap: areaLeaseMap,
        vacantAreas: vacantAreas,
        leaseListPagination: leaseListPagination,
        leaseListLoading: leaseListLoading,
        damageList: damageList,
        leasesLoaded: leasesLoaded,
        damagesLoaded: damagesLoaded,
        tenantList: tenantList,
        tenantListLoading,
        bulkUpdateLeases: (request: BulkUpdateLeaseRequest) =>
            dispatch(bulkUpdateLeaseRequest(request)),
        bulkCreateLeases: (request: BulkCreateLeaseRequest) =>
            dispatch(bulkCreateLease(request)),
        getVacantAreas: (request: GetVacantAreaRequest) =>
            dispatch(getVacantAreas(request)),
        createDamage: (request: CreateDamageReqeust) =>
            dispatch(createDamageRequest(request)),
        getDamageList: (request: GetDamageRequest) =>
            dispatch(getDamageListRequest(request)),
        getTenantList: (request: GetTenantListRequest) =>
            dispatch(getTenantList(request)),
        clearTenantList: () => dispatch(setTenantList()),
        getLeaseList: (request: GetLeaseListRequest) =>
            dispatch(getLeaseList(request)),
        clearLeaseList: () => dispatch(setLeaseList(true, undefined)),
        transitionLease: transitionLease,
    }
}

export type _LeaseController = ReturnType<typeof _useLease>

export const useLeaseSingleStatusRequest = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<LeaseState>({
        leaseList: [],
        loadedStatusMap: {
            [LeaseStatus.APPLICANT]: false,
            [LeaseStatus.CANCELLED]: false,
            [LeaseStatus.CURRENT]: false,
            [LeaseStatus.NOTICE]: false,
            [LeaseStatus.PAST]: false,
            [LeaseStatus.FUTURE]: false,
        },
    })

    const getLeaseList = async (
        statuses: LeaseStatusType[],
        staged?: boolean,
    ) => {
        const loadedStatusMap: LoadedStatusMap = { ...state.loadedStatusMap }

        let newStatusCount = 0
        let statusStr = ''
        statuses.forEach((status) => {
            // Add the status to the new loaded status map
            loadedStatusMap[status] = true

            // If the status is not already loaded, add the status to the query params string
            if (!state.loadedStatusMap[status]) {
                statusStr += `${status},`
                newStatusCount += 1
            }
        })

        if (newStatusCount > 0) {
            setIsLoading(true)
            // Make the request
            const leaseRes = await axiosInstance.get('lease/', {
                params: {
                    statuses: statusStr,
                    occupiable: true,
                    staged: staged,
                    home: true,
                },
            })
            const newLeases = leaseRes.data

            // Update the lease state
            setIsLoading(false)
            setState({
                leaseList: [...state.leaseList, ...newLeases],
                loadedStatusMap: loadedStatusMap,
            })
        }
    }

    const transitionLease = async (
        leaseId: number,
        toStatus: LeaseStatusType,
    ) => {
        const transitionRes = await axiosInstance.post(
            `lease/${leaseId}/transition/`,
            {
                status: toStatus,
            },
        )

        const lease: Lease = transitionRes.data
        const newLeaseList = state.leaseList.map((l) => {
            if (l.id === lease.id) {
                return lease
            }
            return l
        })

        setState({ ...state, leaseList: newLeaseList })
        return newLeaseList
    }

    const setLeaseList = (newLeaseList: Lease[]) => {
        setState({ ...state, leaseList: newLeaseList })
    }

    return {
        leaseList: state.leaseList,
        getLeaseList,
        transitionLease,
        isLoading,
        setLeaseList,
    }
}

interface LoadedStatusMap {
    [LeaseStatus.APPLICANT]: boolean
    [LeaseStatus.CANCELLED]: boolean
    [LeaseStatus.CURRENT]: boolean
    [LeaseStatus.NOTICE]: boolean
    [LeaseStatus.PAST]: boolean
    [LeaseStatus.FUTURE]: boolean
}

interface LeaseState {
    leaseList: Lease[]
    loadedStatusMap: LoadedStatusMap
}

export const useLease = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [leaseList, setLeaseList] = useState<Lease[]>([])
    const [
        leaseClusterList,
        setLeaseClusterList,
    ] = useState<LeaseClusterResponse | null>(null)

    const getLeaseList = async (request?: GetLeaseListRequest) => {
        setIsLoading(true)
        try {
            const leaseRes = await axiosInstance.get('lease/', request)
            setLeaseList(leaseRes.data)
            setIsLoading(false)
            return leaseRes.data as Lease[]
        } catch (e) {
            toast.error('There was a problem getting your leases')
            setIsLoading(false)
            return Promise.reject()
        }
    }

    const getLeaseClusterList = async (request?: GetLeaseListRequest) => {
        setIsLoading(true)

        return new Promise<LeaseClusterResponse>((resolve, reject) => {
            axiosInstance
                .get('lease/cluster/', request)
                .then((res) => {
                    setLeaseClusterList(res.data)
                    resolve(res.data)
                })
                .catch(reject)
                .finally(() => {
                    setIsLoading(false)
                })
        })
    }

    const transitionLease = async (
        leaseId: number,
        toStatus: LeaseStatusType,
    ) => {
        const transitionRes = await axiosInstance.post(
            `lease/${leaseId}/transition/`,
            {
                status: toStatus,
            },
        )

        const lease: Lease = transitionRes.data

        setLeaseList((oldLeaseList) => {
            return oldLeaseList.map((l) => {
                if (l.id === lease.id) {
                    return lease
                }
                return l
            })
        })

        return lease
    }

    const updateLease = async (body: UpdateLeaseRequest) => {
        try {
            const request = {
                leases: [body],
            }

            const updateRes = await axiosInstance.post(
                `lease/bulk_update/`,
                request,
            )

            const updatedLease: Lease = updateRes.data[0]
            updateLeaseState(updatedLease)

            return updatedLease
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject()
        }
    }

    const getStatusString = (statuses: LeaseStatusType[]) => {
        let statusStr = ''
        statuses.forEach((status) => {
            statusStr += `${status},`
        })

        return statusStr
    }

    const updateLeaseState = (updatedLease: Lease) => {
        setLeaseList((oldLeaseList) => {
            return oldLeaseList.map((l) => {
                if (l.id === updatedLease.id) {
                    return updatedLease
                }
                return l
            })
        })
    }

    const reAlertTenants = async (
        leases: Lease[],
        days: number,
        onSuccess: () => void,
    ) => {
        setIsLoading(true)

        const inspectionIds: number[] = []
        leases.forEach((lease) => {
            if (lease.tenant_move_in_inspection) {
                inspectionIds.push(lease.tenant_move_in_inspection.id)
            }
        })

        axiosInstance
            .post('inspection/area-inspection/bulk_transition/', {
                area_inspections: inspectionIds,
                to_status: 'READY',
                days_until_due: days,
                ready_override: true,
                children_area_inspections: true,
            })
            .then(() => {
                onSuccess()
                toast.success('Success!')
            })
            .catch((e) => {
                toast.error('There was a problem re-alerting tenants')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        isLoading,
        leaseList,
        setLeaseList,
        getLeaseList,
        transitionLease,
        getStatusString,
        updateLeaseState,
        getLeaseClusterList,
        leaseClusterList,
        reAlertTenants,
        updateLease,
    }
}

export type LeaseController = ReturnType<typeof useLease>
