import React, { useState } from 'react'
import { Popover, TextField, IconButton, Tooltip } from '@mui/material'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'

import { EntityClassification } from '../../../models'
import {
    classificationDataTypes,
    getEntityClassificationUiName,
} from '../../../models/DocumentAudit/services'

import {
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    Theme,
} from '@material-ui/core'
import { PopoverState } from '../../../hooks/usePopover'
import { Container } from '../../../components'

import { FilterState, FilterValue, NumberFilterValue } from './types'
import { FilterRow } from './FilterRow'

export const FilterPopover = <
    FilterIdentifier extends EntityClassification
>(props: {
    theme: Theme
    popoverState: PopoverState<FilterIdentifier>
    filterState: FilterState
    setFilterState: (filterState: FilterState) => void
    onClear: () => void
    onApply: () => void
}) => {
    const {
        theme,
        popoverState,
        filterState,
        setFilterState,
        onClear,
        onApply,
    } = props

    const classification = popoverState.context
    if (!classification) return null

    const filters = filterState[classification]?.filters ?? []
    const classificationDataType = classificationDataTypes.get(classification)
    if (!classificationDataType) return null

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({
            ...filterState,
            [classification]: {
                ...filterState[classification],
                filters: [...(filterState[classification]?.filters || [])],
                hasMismatch: e.target.checked,
            },
        } as FilterState)
    }

    const addFilter = () => {
        setFilterState({
            ...filterState,
            [classification]: {
                type: classificationDataType.type,
                filters: [
                    ...(filterState[classification]?.filters || []),
                    {
                        type: classificationDataType.type,
                        value: '',
                        operator: 'eq',
                        logicOperator: 'and',
                    },
                ],
            },
        } as FilterState)
    }

    return (
        <Popover
            open={popoverState.isOpen}
            anchorEl={popoverState.anchorEl}
            onClose={popoverState.handleClose}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                    width: 400,
                }}
            >
                <PopoverTitle theme={theme} classification={classification} />
                <MismatchCheckboxes
                    theme={theme}
                    filterState={filterState}
                    classification={classification}
                    handleCheckboxChange={handleCheckboxChange}
                />
                <FilterRows
                    theme={theme}
                    classification={classification}
                    filters={filters}
                    filterState={filterState}
                    setFilterState={setFilterState}
                />
                <PopoverFooter
                    theme={theme}
                    onApply={onApply}
                    addFilter={addFilter}
                />
            </Container>
        </Popover>
    )
}

const PopoverTitle = ({
    theme,
    classification,
}: {
    theme: Theme
    classification: EntityClassification
}) => (
    <span
        style={{
            ...theme.typography.subtitle1,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
        }}
    >
        Filter {getEntityClassificationUiName(classification)}
    </span>
)

const MismatchCheckboxes = ({
    theme,
    filterState,
    classification,
    handleCheckboxChange,
}: {
    theme: Theme
    filterState: FilterState
    classification: EntityClassification
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => (
    <Container style={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Container style={{ flexDirection: 'column' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={
                            filterState[classification]?.hasMismatch ?? false
                        }
                        onChange={handleCheckboxChange}
                    />
                }
                label="Has Mismatch"
            />
        </Container>
    </Container>
)

const FilterRows = ({
    theme,
    classification,
    filters,
    filterState,
    setFilterState,
}: {
    theme: Theme
    classification: EntityClassification
    filters: FilterValue[]
    filterState: FilterState
    setFilterState: (filterState: FilterState) => void
}) => (
    <>
        {filters.map((filter, index) => (
            <FilterRow
                key={`${classification}-${index}`}
                theme={theme}
                classification={classification}
                filterValue={filter}
                setFilterValue={(filterValue) => {
                    const updatedFilters = (filterState[classification]
                        .filters as FilterValue[]).map((f, i) =>
                        i === index ? filterValue : f,
                    )
                    setFilterState({
                        ...filterState,
                        [classification]: {
                            ...filterState[classification],
                            filters: updatedFilters,
                        },
                    } as FilterState)
                }}
                onRemoveFilter={() => {
                    setFilterState({
                        ...filterState,
                        [classification]: {
                            ...filterState[classification],
                            filters: (filterState[classification]
                                .filters as FilterValue[]).filter(
                                (_, i) => i !== index,
                            ),
                        },
                    } as FilterState)
                }}
                firstRow={index === 0}
            />
        ))}
    </>
)

const PopoverFooter = ({
    theme,
    onApply,
    addFilter,
}: {
    theme: Theme
    onApply: () => void
    addFilter: () => void
}) => (
    <Container
        style={{
            marginTop: theme.spacing(2),
            flexDirection: 'row',
            alignItems: 'center',
        }}
    >
        <Tooltip title="Add Filter">
            <IconButton onClick={addFilter}>
                <AddIcon color="primary" />
            </IconButton>
        </Tooltip>
        <div style={{ flex: 1 }} />
        <span
            style={{
                ...theme.typography.subtitle1,
                fontWeight: theme.typography.fontWeightLight,
                color: theme.palette.text.secondary,
            }}
        >
            Apply
        </span>
        <Tooltip title="Apply">
            <IconButton onClick={onApply}>
                <CheckIcon color="primary" />
            </IconButton>
        </Tooltip>
    </Container>
)
