import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    makeStyles,
    Paper,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core'

import { Selector } from '../../components'
import { Service, ServiceCategory } from '../../models'
import { CreateServiceCategoryRequest } from '../../hooks/useServiceCategories'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
    },
    header: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
    },
    form: {
        flex: 1,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
    },
    footer: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

interface Props {
    serviceList: Service[]
    customStyle?: {
        root?: React.CSSProperties
    }
    createServiceCategory: (
        request: CreateServiceCategoryRequest,
    ) => Promise<ServiceCategory>
    handleClose: () => void
}

export const AddServiceCategoryForm = (props: Props) => {
    const { serviceList, createServiceCategory, handleClose } = props

    const [categoryName, setCategoryName] = useState('')
    const [selectedServices, setSelectedServices] = useState<number[]>([])

    const [doValidate, setDoValidate] = useState(false)

    const theme = useTheme()
    const classes = useStyles()

    const selectorStyle: React.CSSProperties = {
        margin: theme.spacing(1, 0),
        flex: 1,
        width: '100%',
    }

    const handleSubmit = () => {
        const request: CreateServiceCategoryRequest = {
            name: categoryName,
            services: selectedServices,
        }
        createServiceCategory(request).then(() => {
            toast.success('Service category created successfully')
            handleClose()
        })
    }

    const handelValidation = () => {
        if (categoryName.trim() === '') {
            setDoValidate(true)
            return false
        }
        if (selectedServices.length === 0) {
            setDoValidate(true)
            return false
        }
        setDoValidate(false)
        return true
    }

    return (
        <Paper className={classes.root} style={{ ...props.customStyle?.root }}>
            <div className={classes.header}>
                <Typography variant="h5" component="h2">
                    Create Service Category
                </Typography>
            </div>
            <div className={classes.form}>
                <Typography
                    variant="body2"
                    style={{
                        marginBottom: theme.spacing(2),
                        color: theme.palette.primary.dark,
                    }}
                >
                    Enter a name and select your services to group services
                    together.
                    <br />
                    Note: A service can only be part of one category.
                </Typography>
                <div style={{ marginBottom: theme.spacing(3) }}>
                    <TextField
                        label="Category Name"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        error={doValidate && categoryName.trim() === ''}
                        helperText={
                            doValidate && categoryName.trim() === ''
                                ? 'Category name is required'
                                : ''
                        }
                    />
                </div>
                <div
                    style={{
                        marginBottom: theme.spacing(3),
                    }}
                >
                    <Selector
                        data={serviceList}
                        customStyle={{
                            formControl: { ...selectorStyle, width: '100%' },
                        }}
                        label="Services"
                        maxItems={10}
                        onChange={(e) => {
                            const selection: number[] = e.target.value as any
                            setSelectedServices(selection)
                        }}
                        currentValue={selectedServices}
                        getDisplayString={(v) => v.name}
                        searchable
                        multiple
                        error={doValidate && selectedServices.length === 0}
                    />
                </div>
            </div>
            <div className={classes.footer}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                    style={{
                        textTransform: 'none',
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: 75,
                    }}
                    onClick={() => {
                        if (handelValidation()) {
                            handleSubmit()
                        }
                    }}
                >
                    Submit
                </Button>
            </div>
        </Paper>
    )
}
