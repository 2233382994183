import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'

import { Container } from '../Container'
import { DropFiles } from '../DropFiles'
import { BaseCompany } from '../../models'
import { axiosInstance } from '../../helpers'

interface Props {
    apartment: BaseCompany
}

export const UploadOnboardingSheet = (props: Props) => {
    const { apartment } = props

    const theme = useTheme()

    const [fileType, setFileType] = useState<OnboardSheetType | null>(null)
    const [file, setFile] = useState<File | null>(null)
    const [loading, setLoading] = useState(false)

    const handleSelectChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        setFileType(event.target.value as OnboardSheetType)
    }

    const handleFileChange = (files: File[]) => {
        if (files.length > 0) {
            setFile(files[0])
        }
    }

    const checkValid = () => {
        if (fileType === null) {
            return false
        }
        if (file === null) {
            return false
        }
        return true
    }

    const handleUpload = () => {
        if (file && fileType) {
            setLoading(true)
            if (fileType !== 'status_sheet') {
                const data = new FormData()
                data.append(fileType, file)

                axiosInstance
                    .post(`company/apartment/${apartment.id}/onboard/`, data)
                    .then((res) => {
                        if (res.data['errors'].length > 0) {
                            let errorMsg = ''
                            res.data['errors'].forEach((e: any) => {
                                errorMsg += `${e.message}\n`
                            })
                            toast.error(errorMsg)
                            return
                        }

                        toast.success('Uploaded')
                    })
                    .catch((e) => {
                        toast.error('Problem uploading the file')
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                const data = new FormData()
                data.append('sheet', file)
                axiosInstance
                    .post(
                        `company/apartment/${apartment.id}/status_sheet/`,
                        data,
                    )
                    .then((res) => {
                        if (res.data.errors.length > 0) {
                            let errorStr = ''
                            res.data.errors.forEach(
                                (e: any) => (errorStr += `${e}\n`),
                            )
                            toast.error(errorStr, { autoClose: 5000 })
                            return
                        }

                        toast.success('Uploaded')
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    return (
        <Container style={{ flexDirection: 'column', flex: 1 }}>
            <Typography variant="h6">Upload Onboarding Sheet</Typography>
            <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: theme.spacing(2) }}
            >
                <InputLabel id="sheet-type-label">
                    Select a sheet type
                </InputLabel>
                <Select
                    labelId="sheet-type-label"
                    value={fileType}
                    onChange={handleSelectChange}
                    label="Select a sheet type"
                >
                    <MenuItem value="" disabled>
                        <em>Select a sheet type</em>
                    </MenuItem>
                    {Object.keys(sheetNameMap).map((key) => (
                        <MenuItem key={key} value={key}>
                            {sheetNameMap[key as OnboardSheetType]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Container
                style={{
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <DropFiles
                    onDrop={handleFileChange}
                    displayString="Choose CSV File"
                    fileTypes={{
                        'text/csv': [],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                        'application/vnd.ms-excel': [],
                    }}
                />
            </Container>
            <Container style={{ height: 75 }}>
                {file && (
                    <Container
                        style={{
                            margin: theme.spacing(1),
                            border: `1px solid ${theme.palette.grey[400]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(1),
                            alignItems: 'center',
                        }}
                    >
                        {file.name}
                        <IconButton
                            onClick={() => {
                                setFile(null)
                            }}
                        >
                            <DeleteForever color="secondary" />
                        </IconButton>
                    </Container>
                )}
            </Container>
            <Container style={{ flex: 1 }} />
            <Container
                style={{
                    padding: theme.spacing(1),
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 0),
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: '100%',
                    }}
                    onClick={() => {
                        if (!checkValid()) {
                            return
                        }

                        handleUpload()
                    }}
                    disabled={!file || !fileType || loading}
                >
                    {loading ? (
                        <CircularProgress
                            size={25}
                            style={{ color: 'white' }}
                        />
                    ) : (
                        'Upload'
                    )}
                </Button>
            </Container>
        </Container>
    )
}

type OnboardSheetType =
    | 'inventory_sheet'
    | 'area_sheet'
    | 'unit_sheet'
    | 'status_sheet'
    | 'infrastructure_sheet'

const sheetNameMap: { [key in OnboardSheetType]: string } = {
    inventory_sheet: 'Inventory Sheet',
    area_sheet: 'Area Sheet',
    unit_sheet: 'Unit Sheet',
    status_sheet: 'Status Sheet',
    infrastructure_sheet: 'Infrastructure Sheet',
}
