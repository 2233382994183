import React, { useState } from 'react'

import {
    useTheme,
    Modal,
    Paper,
    Slide,
    Grid,
    Tooltip,
    IconButton,
    Tabs,
    Tab,
    CircularProgress,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../../../components'

import {
    BaseWorkorder,
    Schedule,
    Service,
    ServiceCategory,
} from '../../../../models'

import { ServiceTable } from '../../../../components/BudgetModal/Components/ServiceTable'
import { SummaryTable } from '../../../../components/BudgetModal/Components/SummaryTable'
import { BudgetPlot } from '../../../../components/BudgetModal/Components/BudgetPlot'
import { LineItemTable } from '../../../../components/BudgetModal/Components/LineItemTable'

import { SmartScheduleController, useSmartSchedule } from '../../../../hooks'
import { useServiceCategories } from '../../../../hooks/useServiceCategories'
interface Props {
    open: boolean
    onClose: () => void
    workorders: BaseWorkorder[]
    serviceList: Service[]
    // scheduleController: SmartScheduleController
    scheduleDetail: Schedule | undefined
    portfolio?: boolean
}

export const BudgetModal = (props: Props) => {
    const {
        open,
        onClose,
        workorders,
        serviceList,
        scheduleDetail,
        portfolio,
    } = props
    if (!scheduleDetail) {
        return (
            <Modal
                open={open}
                onClose={onClose}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Modal>
        )
    }

    const scheduleController = useSmartSchedule(scheduleDetail?.id, portfolio)
    const serviceCategoryController = useServiceCategories(
        scheduleDetail?.apartment.id,
    )
    const schedule = scheduleController?.schedule

    const theme = useTheme()

    const [serviceTotals, setServiceTotals] = useState<Totals>()
    const [lineItemTotals, setLineItemTotals] = useState<LineItemTotals>()
    const [selectedTab, setSelectedTab] = useState(0) // Add state for selected tab

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setSelectedTab(newValue)
    }

    const HEADER_HEIGHT = 70

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflowY: 'scroll',
                        flexDirection: 'column',
                        height: '95%',
                        width: '90%',
                        padding: theme.spacing(2),
                    }}
                >
                    <Container style={{ flexDirection: 'column' }}>
                        {/* Header */}
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                minHeight: HEADER_HEIGHT,
                            }}
                        >
                            {/* Title */}
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    display: 'flex',
                                }}
                            >
                                {schedule?.name} Budget
                            </span>
                            <div
                                style={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Tooltip title="Close">
                                    <IconButton onClick={props.onClose}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Container>

                        {/* Main Section */}
                        <Grid container>
                            <Grid
                                container
                                xs={10}
                                sm={10}
                                md={8}
                                lg={6}
                                xl={4}
                            >
                                <Grid item xs={12}>
                                    <SummaryTable
                                        theme={theme}
                                        scheduleController={scheduleController}
                                        workorders={workorders}
                                        totals={serviceTotals}
                                        lineItemTotals={lineItemTotals}
                                        scheduleDetail={scheduleDetail}
                                        portfolio
                                    />
                                </Grid>

                                {/* Add a thick black line */}
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            borderBottom: '1px solid black',
                                            margin: '10px 0',
                                        }}
                                    ></div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                    >
                                        <Tab label="Services" />
                                        <Tab label="Line Items" />
                                    </Tabs>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        minHeight: 500, // neccesary to hold the tabs in the right spot
                                    }}
                                >
                                    {selectedTab === 0 && (
                                        <ServiceTable
                                            theme={theme}
                                            scheduleController={
                                                scheduleController
                                            }
                                            serviceList={serviceList}
                                            workorders={workorders}
                                            portfolio={portfolio}
                                            serviceCategoryList={
                                                serviceCategoryController.serviceCategories
                                            }
                                        />
                                    )}
                                    {selectedTab === 1 && (
                                        <LineItemTable
                                            theme={theme}
                                            scheduleController={
                                                scheduleController
                                            }
                                            totals={lineItemTotals}
                                            scheduleDetail={scheduleDetail}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container xs={2} sm={2} md={4} lg={6} xl={8}>
                                <BudgetPlot
                                    theme={theme}
                                    scheduleController={scheduleController}
                                    workorders={workorders}
                                    scheduleDetail={scheduleDetail}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

export interface Totals {
    budget: number
    variance: number
    forecast: number
    invoice: number
}

export interface LineItemTotals {
    cost: number
}
