import { useState } from 'react'
import { toast } from 'react-toastify'

import { ApartmentVendorRule } from '../models'
import { axiosInstance } from '../helpers'

export const useApartmentVendorRule = () => {
    const [
        apartmentVendorRuleDict,
        setApartmentVendorRuleDict,
    ] = useState<ApartmentVendorRuleDict>({})

    const getApartmentVendorRules = async () => {
        try {
            const res = await axiosInstance.get(
                'company/apartment_vendor_rule/',
            )
            const dict: ApartmentVendorRuleDict = res.data
            setApartmentVendorRuleDict(dict)
            return dict
        } catch (e) {
            toast.error('Error getting apartment vendor rules')
            return Promise.reject(e)
        }
    }

    const createOrUpdateAptVendorRule = async (
        request: CreateOrUpdateAptVendorRuleRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'company/apartment_vendor_rule/',
                request,
            )

            const key = request.service_id
            const tempDict: ApartmentVendorRuleDict = {
                ...apartmentVendorRuleDict,
            }
            tempDict[key] = res.data
            setApartmentVendorRuleDict(tempDict)

            return tempDict
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    const deleteAptVendorRule = async (request: DeleteAptVendorRuleRequest) => {
        try {
            const res = await axiosInstance.delete(
                `company/apartment_vendor_rule/${request.rule_id}/`,
            )

            const key = request.service_id
            const tempDict: ApartmentVendorRuleDict = {
                ...apartmentVendorRuleDict,
            }
            tempDict[key] = res.data
            setApartmentVendorRuleDict(tempDict)

            return tempDict
        } catch (e: any) {
            toast.error(e.response.data.message)
            return null
        }
    }

    return {
        apartmentVendorRuleDict,
        setApartmentVendorRuleDict,
        getApartmentVendorRules,
        createOrUpdateAptVendorRule,
        deleteAptVendorRule,
    }
}

export interface ApartmentVendorRuleDict {
    [service_id: number]: ApartmentVendorRule[]
}

export interface CreateOrUpdateAptVendorRuleRequest {
    service_id: number
    rank?: number
    vendor_id?: number
    buffer_days?: number
}

export interface DeleteAptVendorRuleRequest {
    rule_id: number
    service_id: number
}
