import { useState } from 'react'
import { toast } from 'react-toastify'

import { Inspection, InspectionDataResponse } from '../models'
import { axiosInstance } from '../helpers'

export const useInspectionData = () => {
    const [
        inspectionData,
        setInspectionData,
    ] = useState<InspectionDataResponse>({
        prior: {},
        future: {},
        today: {},
    })

    const [inspectionList, setInspectionList] = useState<Inspection[]>([])
    const [
        analyticData,
        setAnalyticData,
    ] = useState<InspectionAnalyticRes | null>(null)

    const getInspectionData = async (request: getInspectionDateRequest) => {
        try {
            const response = await axiosInstance.get(
                'inspection/unit-inspection/get_unit_inspection_data/',
                { params: request.params },
            )
            const inspectionData: InspectionDataResponse = response.data
            setInspectionData(inspectionData)
            return inspectionData
        } catch (e) {
            toast.error('Error getting inspection data')
            return Promise.reject(e)
        }
    }

    const getInspectionListFromUnitInspection = async (
        request: getInspectionListFromUnitInspectionRequest,
    ) => {
        try {
            const response = await axiosInstance.get(
                'inspection/unit-inspection/get_inspection_list/',
                { params: request.params },
            )
            const inspectionList: Inspection[] = response.data['inspections']
            const analyticData: InspectionAnalyticRes =
                response.data['analytics']
            setInspectionList(inspectionList)
            setAnalyticData(analyticData)
            return inspectionList
        } catch (e) {
            toast.error('Error getting inspection list')
            return Promise.reject(e)
        }
    }

    return {
        inspectionData,
        inspectionList,
        analyticData,
        setInspectionData,
        getInspectionData,
        getInspectionListFromUnitInspection,
    }
}

export interface getInspectionDateRequest {
    params: {
        upper_bound_date: string
        lower_bound_date: string
    }
}

export interface getInspectionListFromUnitInspectionRequest {
    params: {
        start_date?: string
        assigned_to?: number
        completed_by?: number
        completed_date?: string
        today?: boolean
    }
}

export interface InspectionAnalyticRes {
    unit_inspection_count: number
    area_inspection_count: number
    inventory_inspection_count: number
    flagged_inventory_inspection_count: number
}
