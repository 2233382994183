import {
    CircularProgress,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { FileCopy } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import PDFMerger from 'pdf-merger-js/browser'
import { Container } from '../../components'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import { Report } from '../../models'

interface Props {
    report: Report
}

export const ReportsDrawerItem = (props: Props) => {
    const { report } = props

    const [reportGenerating, setReportGenerating] = useState(false)

    const theme = useTheme()

    const createdDate = toMMDDYYYY(new Date(report.creation_time))

    const [openToast, setOpenToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const [currentReport, setCurrentReport] = useState(report)

    const handleCopyLink = (url: string) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setToastMessage('Link copied to clipboard!')
                setOpenToast(true)
            })
            .catch(() => {
                setToastMessage('Failed to copy link')
                setOpenToast(true)
            })
    }

    const isTextTruncated = (elementId: string) => {
        const element = document.getElementById(elementId)
        if (element) {
            return element.scrollWidth > element.clientWidth
        }
        return false
    }

    return (
        <Container
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(2),
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '100px',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    marginRight: theme.spacing(2),
                    overflow: 'hidden',
                    gap: theme.spacing(1),
                }}
            >
                <Tooltip
                    title={report.name}
                    placement="top-start"
                    enterDelay={500}
                    enterNextDelay={500}
                >
                    <span
                        id={`report-name-${report.id}`}
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightBold,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: 1.2,
                        }}
                    >
                        {report.name}
                    </span>
                </Tooltip>

                <Tooltip
                    title={report.generated_by?.name || 'Unknown'}
                    placement="top-start"
                    enterDelay={500}
                    enterNextDelay={500}
                >
                    <span
                        id={`report-user-${report.id}`}
                        style={{
                            ...theme.typography.body2,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: 1.2,
                        }}
                    >
                        {report.generated_by?.name === 'deleted'
                            ? 'user deleted'
                            : report.generated_by?.name || 'Unknown'}
                    </span>
                </Tooltip>

                <span
                    style={{
                        ...theme.typography.body2,
                        color: theme.palette.text.secondary,
                        lineHeight: 1.2,
                    }}
                >
                    {createdDate}
                </span>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: theme.spacing(1),
                }}
            >
                <Container>
                    {reportGenerating ? (
                        <CircularProgress
                            size={24}
                            style={{ margin: theme.spacing(1) }}
                        />
                    ) : (
                        <Tooltip title="View Report">
                            <IconButton
                                size="small"
                                disabled={reportGenerating}
                                onClick={async () => {
                                    if (report.report) {
                                        window.open(report.report)
                                        return false
                                    } else {
                                        setReportGenerating(true)
                                        const merger = new PDFMerger()

                                        // using traditional for structure to make awaits function properly
                                        for (const page of report.report_pages) {
                                            for (const file of page.report_files) {
                                                await fetch(file.file)
                                                    .then((res) => {
                                                        return res.blob()
                                                    })
                                                    .then(
                                                        async (arrayBuffer) => {
                                                            await merger.add(
                                                                arrayBuffer,
                                                            )
                                                        },
                                                    )
                                            }
                                        }

                                        const mergedPDF = await merger.saveAsBlob()
                                        const url = URL.createObjectURL(
                                            mergedPDF,
                                        )
                                        window.open(url)

                                        // save full report to database to create a link
                                        try {
                                            const response = await axiosInstance.post(
                                                '/stats/reports/save-report/',
                                                {
                                                    report_id: report.id,
                                                },
                                            )
                                            // Update the local report state with the new data
                                            if (response.data) {
                                                setCurrentReport(response.data)
                                            }
                                        } catch (error) {
                                            console.log(error)
                                            console.error(
                                                'Failed to save report:',
                                                error,
                                            )
                                        }
                                        setReportGenerating(false)
                                        return false
                                    }
                                }}
                            >
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                    )}
                </Container>

                {(currentReport.xlsx_report || currentReport.report) && (
                    <Container>
                        <Tooltip title="Copy URL">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (currentReport.xlsx_report) {
                                        handleCopyLink(
                                            currentReport.xlsx_report,
                                        )
                                    } else if (currentReport.report) {
                                        handleCopyLink(currentReport.report)
                                    }
                                }}
                            >
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>
                    </Container>
                )}
            </Container>
        </Container>
    )
}
