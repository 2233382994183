import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
    Button,
    CircularProgress,
    Popover,
    PopoverPosition,
    useTheme,
    List,
    ListItem,
    ListItemText,
    IconButton,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { ListVendor } from '../../models'
import { Container, Selector } from '../../components'
import { CreateDayOffRequest } from '../../hooks/useDayOff'
import { CalendarDayOff } from '../../models/Calendar'

interface Props {
    selectedDates: Record<string, boolean>
    anchorPosition: PopoverPosition
    open: boolean
    vendorList: ListVendor[]
    onClose: () => void
    addDayOff: (request: CreateDayOffRequest) => Promise<CalendarDayOff[]>
    resetSelectedDates: () => void
    removeDate: (date: string) => void
}

export const AddDayOffPopover = (props: Props) => {
    const {
        selectedDates,
        anchorPosition,
        open,
        vendorList,
        onClose,
        addDayOff,
        resetSelectedDates,
        removeDate,
    } = props

    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState<'Property' | 'Vendor'>('Property')
    const [selectedVendorId, setSelectedVendorId] = useState(-1)

    const selectedDateList = Object.keys(selectedDates)

    const sortedDateList = selectedDateList.sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime()
    })

    const inHouseVendors = vendorList.filter((ven) => ven.in_house)

    const disabled =
        selectedDateList.length === 0 ||
        (mode === 'Vendor' && selectedVendorId === -1)

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 300,
                    maxWidth: 500,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Add Days Off
                </span>
                <RadioGroup
                    row
                    value={mode}
                    onChange={(e) =>
                        setMode(e.target.value as 'Property' | 'Vendor')
                    }
                    style={{ margin: theme.spacing(2, 1, 2, 1) }}
                >
                    <FormControlLabel
                        value="Property"
                        control={<Radio color="primary" />}
                        label="Property"
                    />
                    <FormControlLabel
                        value="Vendor"
                        control={<Radio color="primary" />}
                        label="Vendor"
                    />
                </RadioGroup>
                {mode === 'Vendor' && (
                    <Selector
                        label="Vendor"
                        data={inHouseVendors}
                        searchable
                        currentValue={selectedVendorId ?? -1}
                        onChange={(e) => {
                            setSelectedVendorId(e.target.value as number)
                        }}
                        getDisplayString={(vendor) => vendor.name}
                        customStyle={{
                            formControl: { margin: theme.spacing(2, 1, 2, 1) },
                        }}
                    />
                )}
                <Container
                    style={{
                        padding: theme.spacing(1, 2, 1, 2),
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Selected Dates:
                    </span>
                    <List style={{ maxHeight: 300, overflowY: 'auto' }}>
                        {sortedDateList.map((date) => {
                            return (
                                <ListItem key={date}>
                                    <ListItemText
                                        primary={date}
                                        primaryTypographyProps={{
                                            style: {
                                                color: 'inherit',
                                                fontWeight: 'inherit',
                                            },
                                        }}
                                    />
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeDate(date)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            disabled={disabled}
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: 75,
                            }}
                            onClick={() => {
                                setLoading(true)
                                const dates: string[] = []
                                selectedDateList.forEach((dateString) => {
                                    const date = new Date(dateString)
                                    date.setTime(
                                        date.getTime() +
                                            date.getTimezoneOffset() *
                                                60 *
                                                1000,
                                    )
                                    dates.push(date.toISOString())
                                })
                                const request: CreateDayOffRequest = {
                                    dates: dates,
                                }
                                if (mode === 'Vendor') {
                                    request.vendor_id = selectedVendorId
                                }
                                addDayOff(request)
                                    .then(() => {
                                        toast.success(
                                            'Days Off added successfully',
                                        )
                                        resetSelectedDates()
                                        onClose()
                                    })
                                    .catch((e) => {
                                        toast.error(e.response.data.message)
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                        >
                            Save
                        </Button>
                    )}
                </Container>
            </Container>
        </Popover>
    )
}
