import React, { useState } from 'react'
import { LeaseAuditController } from '../../../hooks/documentAudit/useLeaseAudit'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Theme,
    DialogActions,
} from '@material-ui/core'
import { Container } from '../../../components'
import NumberFormat from 'react-number-format'
import { getTotalPriceExpense, PRICE_PER_PAGE } from '../../../models'
import { currencyFormatter } from '../../../helpers'

interface Props {
    leaseAuditController: LeaseAuditController
    theme: Theme
}
export const WaitingForApprovalPage = ({
    leaseAuditController,
    theme,
}: Props) => {
    const leaseAudit = leaseAuditController.leaseAudit

    const [dialogMode, setDialogMode] = useState<'approve' | 'reject' | null>(
        null,
    )

    if (leaseAudit === null || leaseAudit === undefined) {
        return <CircularProgress />
    }

    const documentCount = leaseAudit.document_count
    const pageCount = leaseAudit.page_count
    const pricePerPage = 0.04

    let pagePerDocLabel = 'N/A'
    if (documentCount > 0) {
        pagePerDocLabel = (pageCount / documentCount).toFixed(2)
    }

    const handleConfirm = (dialogMode: 'approve' | 'reject' | null) => {
        if (dialogMode === null) {
            setDialogMode(null)
            return
        }
        if (dialogMode === 'approve') {
            leaseAuditController.updateLeaseAudit(leaseAudit.id, {
                status: 'APPROVED',
            })
        } else {
            leaseAuditController.updateLeaseAudit(leaseAudit.id, {
                status: 'REJECTED',
            })
        }
        setDialogMode(null)
    }

    return (
        <Container
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <span
                style={{
                    ...theme.typography.h2,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.text.primary,
                }}
            >
                {currencyFormatter.format(pageCount * pricePerPage)}
            </span>
            <NumberFormat
                value={pageCount}
                displayType="text"
                thousandSeparator={true}
                suffix=" Pages"
                style={{
                    ...theme.typography.h4,
                    fontWeight: theme.typography.fontWeightRegular,
                    color: theme.palette.text.secondary,
                    marginTop: theme.spacing(2),
                }}
            />
            <NumberFormat
                value={documentCount}
                displayType="text"
                thousandSeparator={true}
                suffix=" Documents"
                style={{
                    ...theme.typography.h4,
                    fontWeight: theme.typography.fontWeightRegular,
                    color: theme.palette.text.secondary,
                    marginTop: theme.spacing(2),
                }}
            />
            {typeof pagePerDocLabel === 'number' ? (
                <NumberFormat
                    value={pagePerDocLabel}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix=" Pages per Document"
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightRegular,
                        color: theme.palette.text.secondary,
                        marginTop: theme.spacing(2),
                    }}
                />
            ) : (
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightRegular,
                        color: theme.palette.text.secondary,
                        marginTop: theme.spacing(2),
                    }}
                >
                    {pagePerDocLabel} Pages per Document
                </span>
            )}

            <Container
                style={{
                    marginTop: theme.spacing(6),
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ textTransform: 'none', width: BUTTON_WIDTH }}
                    onClick={() => setDialogMode('reject')}
                >
                    Reject
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginLeft: theme.spacing(2),
                        width: BUTTON_WIDTH,
                    }}
                    onClick={() => setDialogMode('approve')}
                >
                    Approve
                </Button>
            </Container>

            <Dialog
                open={dialogMode !== null}
                onClose={() => setDialogMode(null)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Confirm{' '}
                    {dialogMode === 'approve' ? 'Approval' : 'Rejection'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getApprovalText(dialogMode, pageCount)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogMode(null)}
                        style={{ textTransform: 'none' }}
                        color="secondary"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleConfirm(dialogMode)}
                        style={{ textTransform: 'none' }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

const getApprovalText = (
    dialogMode: 'approve' | 'reject' | null,
    pageCount: number,
) => {
    if (dialogMode === null) {
        return ''
    }
    if (dialogMode === 'approve') {
        return `Are you sure you want to approve this lease audit of ${pageCount} pages? You will be invoiced ${currencyFormatter.format(
            pageCount * PRICE_PER_PAGE,
        )}`
    }
    return 'Are you sure you want to reject this lease audit?'
}

const BUTTON_WIDTH = 150
