import React, { useState } from 'react'

import styles from './EditSpan.module.css'
import { Container } from '../Container'

import {
    Divider,
    IconButton,
    Popover,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'
import { NumberInput } from '../NumberInput'

interface Props {
    style?: React.CSSProperties
    showEditIcon?: boolean
    editIconTooltip?: string
    customPopoverContent?: JSX.Element
    dataType?: 'string' | 'number'
    disabled?: boolean
    hideSubmitButtons?: boolean
    onSave?: (newValue: string) => void
    maxLength?: number
    width?: string
}

export const EditSpan: React.FC<React.PropsWithChildren<Props>> = ({
    style,
    showEditIcon,
    editIconTooltip,
    onSave,
    customPopoverContent,
    children,
    disabled = false,
    dataType = 'string',
    hideSubmitButtons = false,
    maxLength,
    width,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [editText, setEditText] = useState('')

    const theme = useTheme()

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
        setEditText('')
    }

    const popOverOpen = Boolean(anchorEl)

    const InputComponent = dataType === 'string' ? TextField : NumberInput

    const popOverContent = customPopoverContent ? (
        customPopoverContent
    ) : (
        <InputComponent
            placeholder={editIconTooltip ?? 'Edit'}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            inputProps={{ maxLength: maxLength }}
        />
    )

    const hoverEffect = disabled ? undefined : styles.Container

    const submitButtons = (
        <Container style={{}}>
            <div style={{ flex: 1 }} />
            <Tooltip title="Save" style={{ marginRight: theme.spacing(1) }}>
                <IconButton
                    onClick={() => {
                        onSave && onSave(editText)
                        closePopover()
                    }}
                >
                    <SaveIcon color="primary" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Cancel">
                <IconButton onClick={closePopover}>
                    <CloseIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <div style={{ flex: 1 }} />
        </Container>
    )

    return (
        <>
            <Container
                className={hoverEffect}
                onClick={(e) => {
                    if (disabled) {
                        return
                    }
                    openPopover(e)
                }}
                style={{ alignItems: 'center', width: width }}
            >
                <span style={{ ...style }}>{children}</span>
                <div style={{ flex: 1 }} />

                {showEditIcon !== false && (
                    <Tooltip
                        className={styles.Icon}
                        title={editIconTooltip ?? 'Edit'}
                    >
                        <EditIcon fontSize="small" />
                    </Tooltip>
                )}
            </Container>
            <Popover
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={popOverOpen}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <Container style={{ margin: theme.spacing(1) }}>
                        {popOverContent}
                    </Container>
                    <Divider />
                    {!hideSubmitButtons && submitButtons}
                </Container>
            </Popover>
        </>
    )
}
