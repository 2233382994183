import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../hooks'
import { ListVendor, Company } from '../models'
import {
    createVendor,
    getApartmentList,
    getVendorList,
    removeVendorFromApt,
    RootState,
    setVendorList,
    updateApartment,
    updateVendor,
    updateVendorServices,
} from '../store'
import {
    CompanyDetailActionThunk,
    CreateVendorRequest,
    GetApartmentListActionThunk,
    GetApartmentListRequest,
    VendorListActionThunk,
    GetVendorListRequest,
    UpdateVendorRequest,
    UpdateVendorServicesRequest,
    VendorAndContractThunk,
    AddRemoveVendorAptRequest,
    GET_VENDOR_LIST_REQUEST,
    UpdateApartmentRequest,
} from '../store/company/types'
import { axiosInstance } from '../helpers'

type UseCompany = {
    apartmentList: Company[]
    vendorList: ListVendor[]
    vendorsLoading: boolean
    apartmentVendorMap: Record<number, number[]>
    getApartmentVendorMap: (organization?: number) => Promise<void>
    getVendorList: (req: GetVendorListRequest) => VendorListActionThunk
    getApartmentList: (
        req: GetApartmentListRequest,
    ) => GetApartmentListActionThunk
    createVendor: (req: CreateVendorRequest) => VendorAndContractThunk
    removeVendor: (req: AddRemoveVendorAptRequest) => VendorListActionThunk
    updateVendor: (req: UpdateVendorRequest) => VendorAndContractThunk
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
    updateApartment: (req: UpdateApartmentRequest) => Promise<Company>
    setVendorList: (vendorList: ListVendor[]) => void
    setApartmentVendorMap: (map: Record<number, number[]>) => void
    addVendorToApartment: (req: AddRemoveVendorAptRequest) => Promise<void>
}

interface Options {
    getVendorList?: boolean
    cleanUp?: boolean
}

export const useCompany = (options?: Options): UseCompany => {
    const dispatch = useAppDispatch()
    const vendorList = useSelector(
        (state: RootState) => state.company.vendorList,
    )
    const apartmentList = useSelector(
        (state: RootState) => state.company.apartmentList,
    )

    const vendorsLoading = useSelector(
        (state: RootState) => state.company.isLoading[GET_VENDOR_LIST_REQUEST],
    )

    const [apartmentVendorMap, setApartmentVendorMap] = useState<
        Record<number, number[]>
    >({})

    useEffect(() => {
        if (options?.getVendorList) {
            dispatch(getVendorList({ params: { my_team: true } }))
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setVendorList())
            }
        }
    }, [])

    const getApartmentVendorMap = async (
        organization?: number,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(`company/vendor/get_apartment_vendor_map/`, {
                    params: { organization: organization },
                })
                .then((res) => {
                    const map: Record<number, number[]> = res.data
                    setApartmentVendorMap(map)
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    const addVendorToApartment = async (
        req: AddRemoveVendorAptRequest,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(`company/apartment/add_vendor/`, req.body)
                .then((res) => {
                    if (req.body.apartment_id) {
                        setApartmentVendorMap({
                            ...apartmentVendorMap,
                            [req.body.apartment_id]: [
                                ...(apartmentVendorMap[req.body.apartment_id] ??
                                    []),
                                req.body.vendor,
                            ],
                        })
                    }
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    return {
        apartmentList,
        vendorList,
        vendorsLoading,
        apartmentVendorMap,
        getApartmentVendorMap,
        addVendorToApartment,
        getApartmentList: (req) => dispatch(getApartmentList(req)),
        getVendorList: (req) => dispatch(getVendorList(req)),
        createVendor: (req) => dispatch(createVendor(req)),
        removeVendor: (req) => dispatch(removeVendorFromApt(req)),
        updateVendor: (req) => dispatch(updateVendor(req)),
        updateVendorServices: (req) => dispatch(updateVendorServices(req)),
        updateApartment: (req) => updateApartment(req),
        setVendorList: (vendorList: ListVendor[]) =>
            dispatch(setVendorList(vendorList)),
        setApartmentVendorMap: (map: Record<number, number[]>) =>
            setApartmentVendorMap(map),
    }
}
