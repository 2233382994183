import React, { useEffect, useState, useMemo } from 'react'
import { LeaseAuditController } from '../../hooks/documentAudit/useLeaseAudit'
import {
    Theme,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    CircularProgress,
    Typography,
    IconButton,
    Button,
    TextField,
    InputAdornment,
    Tooltip,
} from '@material-ui/core'
import { Container } from '../../components'
import { getEntityClassificationUiName } from '../../models/DocumentAudit/services'
import { Pagination } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import { SuggestedPairsPopover } from './SuggestedPairsPopover'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

export const RentRollList = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const rowsPerPage = 50

    const leaseAudit = leaseAuditController.leaseAudit
    const rentRolls = leaseAuditController.rentRolls

    useEffect(() => {
        if (isActive && rentRolls.length === 0) {
            if (leaseAudit) {
                leaseAuditController.fetchRentRolls(leaseAudit.id)
                leaseAuditController.fetchRentRollDocumentOccurances(
                    leaseAudit.id,
                )
            }
        }
    }, [isActive, leaseAudit])

    const { headers, tableData } = useMemo(() => {
        const headers = new Set<string>()
        const tableData: Record<string, any>[] = []

        rentRolls.forEach((rentRoll) => {
            const rowData: Record<string, any> = {}
            rentRoll.documents.forEach((doc) => {
                if (doc.document_type === 'EXCEL') {
                    doc.entity_values.forEach((ev) => {
                        headers.add(ev.classification)
                        rowData['paired'] = rentRoll.has_lease
                        rowData[ev.classification] = ev.value
                    })
                }
            })

            tableData.push(rowData)
        })
        return {
            headers: Array.from(headers),
            tableData,
        }
    }, [rentRolls, leaseAuditController.leaseAudit])

    const filteredTableData = useMemo(() => {
        if (!searchTerm) return tableData
        return tableData.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase()),
            ),
        )
    }, [tableData, searchTerm])

    const paginatedTableData = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage
        return filteredTableData.slice(startIndex, startIndex + rowsPerPage)
    }, [filteredTableData, page])

    const totalPages = Math.ceil(filteredTableData.length / rowsPerPage)

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPage(value)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
        setPage(1)
    }

    const matchedCount = rentRolls.filter((rentRoll) => rentRoll.has_lease)
        .length
    const totalCount = rentRolls.length
    const matchRate = totalCount > 0 ? (matchedCount / totalCount) * 100 : 0
    const matchRateFormatted = matchRate.toFixed(0)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        if (leaseAuditController.leaseAudit) {
            leaseAuditController.fetchPairingSuggestions(
                leaseAuditController.leaseAudit.id,
            )
        }
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleRefresh = () => {
        if (leaseAudit) {
            leaseAuditController.fetchRentRolls(leaseAudit.id)
        }
    }

    if (leaseAudit === undefined || leaseAudit === null) {
        return null
    }

    if (leaseAuditController.loading.fetchRentRolls) {
        return (
            <Container>
                <CircularProgress />
                <Typography style={{ marginLeft: theme.spacing(2) }}>
                    Loading Rent Rolls...
                </Typography>
            </Container>
        )
    }

    return (
        <Container
            style={{
                height: height,
                flex: 1,
                flexDirection: 'column',
            }}
        >
            {/* header */}
            <Container
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: theme.spacing(2),
                }}
            >
                <div style={{ fontWeight: theme.typography.fontWeightBold }}>
                    Pair Rate: {matchedCount} / {totalCount} (
                    {matchRateFormatted}%)
                </div>

                <Container style={{ marginLeft: theme.spacing(2) }}>
                    <Tooltip title="Refresh Rent Roll Data">
                        <IconButton onClick={handleRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Container>
                <Container style={{ marginLeft: theme.spacing(2) }}>
                    <Button
                        variant="contained"
                        onClick={handleOpenPopover}
                        style={{ textTransform: 'none' }}
                    >
                        Suggested Pairs
                    </Button>
                </Container>

                <Button
                    onClick={() => {
                        toast.info('Resetting failed rent rolls...')
                        leaseAuditController.resetFailedTickets(
                            leaseAudit.id,
                            'EXCEL',
                        )
                    }}
                    style={{ marginLeft: theme.spacing(2) }}
                >
                    Reset Failed Rent Rolls
                </Button>
                <Button
                    onClick={() => {
                        leaseAuditController.runTickets(leaseAudit.id, 'EXCEL')
                    }}
                    style={{
                        marginLeft: theme.spacing(2),
                        textTransform: 'none',
                    }}
                    variant="contained"
                    color="primary"
                >
                    Run rent roll pipeline (
                    {leaseAuditController.rentRollDocumentOccurances?.count})
                </Button>
                <Container
                    style={{
                        marginLeft: theme.spacing(2),
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                >
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Container>
            </Container>

            {/* table */}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    position: 'sticky',
                                    top: 0,
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    zIndex: 1,
                                }}
                            >
                                Paired
                            </TableCell>
                            {headers.map((header) => (
                                <TableCell
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        zIndex: 1,
                                    }}
                                    key={header}
                                >
                                    {getEntityClassificationUiName(header)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {leaseAuditController.loading
                        .fetchRentRollDocumentOccurances ? (
                        <CircularProgress />
                    ) : (
                        <TableBody>
                            {paginatedTableData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {row.paired ? 'True' : 'False'}
                                    </TableCell>
                                    {headers.map((header) => (
                                        <TableCell key={`${index}-${header}`}>
                                            {row[header]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            {/* Pagination */}
            <Container
                style={{
                    marginTop: theme.spacing(2),
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Container>
            <SuggestedPairsPopover
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                theme={theme}
                leaseAuditController={leaseAuditController}
                isActive={isActive}
            />
        </Container>
    )
}
