import { Company } from '../Company'
import {
    AreaConfig,
    CustomStatus,
    DamageConfig,
    InspectionType,
    InventoryConfig,
    IssueConfig,
} from '../Config'
import { Folder, Inventory, Unit } from '../Infrastructure'
import { IdentifiableNamedObject, IdentifiableObject } from '../types'
import { BaseUser, User } from '../Users'
import { Schedule } from '../WorkOrder'

import { Area } from '../../models/Infrastructure'
import { Lease } from '../Lease'
import { BaseMessage } from '../Messaging'

export interface Inspection {
    id: number
    name: string
    start_date: string
    end_date: string
    active: boolean
    inspection_type: InspectionType
    schedule?: Schedule | null
    apartment: Company
    ui_complete?: number
    ui_total?: number
}

export interface InspectionWithReportDate extends Inspection {
    last_report_date?: string
}

export interface InspectionDetail extends Inspection {
    area_needs_inspected_count: number
    unit_inspections: BaseUnitInspection[]
}

export interface UnitInspectionTransitionLog {
    id: number
    created_date: string
    user: User
    from_status: boolean
    to_status: boolean
}

export interface AreaInspectionArea extends IdentifiableObject {
    area_label: string
    area_config: IdentifiableNamedObject
    unit: IdentifiableNamedObject
}

export interface AreaInspection extends IdentifiableObject {
    area: AreaInspectionArea
    entered_space: 1 | 0
    unread_count: number
    message_count: number
    handled_count: number
    flagged_count: number
    cost: number
    staus: InspectionStatusType
    lease_move_in_inspection_tenant: Lease
}

export interface UnitInspection extends IdentifiableObject {
    unit: { id: number; name: string; unit_config: number }
    complete_status: 1 | 0
    assigned_by: IdentifiableNamedObject | null
    assigned_to: IdentifiableNamedObject | null
    completed_by: IdentifiableNamedObject | null
    area_inspections: AreaInspection[]
    start_date: string
    end_date: string
    folder: { id: number; path: string; name: string }
    sort_date?: string
}

export interface BaseUnitInspection extends IdentifiableObject {
    unit: number
    complete_status: boolean
    assigned_by: number | null
    assigned_to: number | null
    completed_by: number | null
    start_date: string
    end_date: string
}

export interface InspectionDamage {
    id: number
    unit_inspection: number
    unit: number
    damage_config: DamageConfig
    amount: number
    unread_count: number
}

export interface InventoryInspectionArea extends IdentifiableObject {
    area_config: AreaConfig
    area_number: number
}

export interface InventoryInspection {
    id: number
    inventory_config: number
    status: CustomStatus | null
    channel?: number | null
    message_count?: number
    unread_count?: number
    handled: boolean
    area_ins_id: number
    config_index: number
    area: number
    note: string | null
    status_group_id: number
    issue?: Issue[]
}

export interface Issue extends IdentifiableObject {
    issue_config?: IssueConfig
    message: BaseMessage
    description?: string
}

export interface MoveInspection extends IdentifiableObject {
    status: InspectionStatusType
    area_inspection_type: AreaInspectionType
    inventory_inspection?: InventoryInspection[]
    auto_completed: 1 | 0
}

export const InspectionStatus = {
    LOCKED: 'LOCKED',
    NA: 'NA',
    READY: 'READY',
    COMPLETE: 'COMPLETE',
    ARCHIVED: 'ARCHIVED',
    STAGED: 'STAGED',
} as const

export type InspectionStatusType = typeof InspectionStatus[keyof typeof InspectionStatus]

export const AreaInspectionTypeOption = {
    MOVE_OUT: 'MOVE_OUT',
    MOVE_IN_TENANT: 'MOVE_IN_TENANT',
    MOVE_IN_APARTMENT: 'MOVE_IN_APARTMENT',
    TRADITIONAL: 'TRADITIONAL',
} as const

export type AreaInspectionType = typeof AreaInspectionTypeOption[keyof typeof AreaInspectionTypeOption]

export interface Witness {
    user: BaseUser
    reason_on_the_community: string
}

export interface Claimant extends Witness {
    seeking_compensation: boolean
    object_carrying_description?: string
    first_aid_description?: string
    medical_transport_provider?: string
}

export interface IncidentInspectedAfter {
    inspector: BaseUser
    date: string
}

export interface IncidentPoliceOrFireCalled {
    contact: string
    case_number: string
}

export interface IncidentBodilyInjury {
    description: string
    lighting_conditions: string
    weather_conditions: string
    walkway_ground_conditions: string
}

export interface IncidentDamageToProperty {
    type_of_loss: string
    what_caused_the_loss: string
    estimated_cost: number
}

export interface IncidentReport extends IdentifiableObject {
    reporting_employee: BaseUser
    date: string
    created_date: string
    location_inspected_after?: IncidentInspectedAfter
    police_or_fire_called?: IncidentPoliceOrFireCalled
    bodily_injury?: IncidentBodilyInjury
    damage_to_property?: IncidentDamageToProperty
    description: string
    folder?: Folder
    unit?: Unit
    area?: Area
    claimants: Claimant[]
    witnesses: Witness[]
    apartment: Company
    images: IncidentImage[]
    incident_type: IdentifiableNamedObject
    severity: string
    status: string
    title: string
    on_master_insurance: boolean
    tenant_negligible: boolean
}

export interface IncidentImage extends IdentifiableObject {
    incident_report: number
    file: string
    uploaded: boolean
}

export interface WitnessClaimant {
    name: string
    email: string
    firstAid?: string
    medicalTransportProvider?: string
    seekingComp?: boolean
    objectCarryingOrHolding?: string
    reasonOnCommunity: string
}

export enum IncidentSeverity {
    LOW = 'LOW',
    MID = 'MID',
    HIGH = 'HIGH',
}

export enum IncidentStatus {
    PENDING = 'PENDING',
    REVIEWED = 'REVIEWED',
    SUBMITTED = 'SUBMITTED',
    NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export enum IncidentStatusNumbers {
    PENDING = 0,
    REVIEWED = 1,
    SUBMITTED = 2,
    NOT_SUBMITTED = 3,
}

export enum TenantNegligibleFilter {
    NO_FILTER = -1,
    YES = 1,
    NO = 0,
}

export interface UnitInspectionCountDict {
    [date: string]: {
        [userId: number]: number
    }
}

export interface InspectionDataDict {
    [userId: number]: {
        total: number
        completed: number
    }
}

export interface InspectionDataResponse {
    prior: UnitInspectionCountDict
    future: UnitInspectionCountDict
    today: InspectionDataDict
}
