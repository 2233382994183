import React, { useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    TextField,
    useTheme,
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

import { toast } from 'react-toastify'
import { Container } from '../../components'
import { useAppDispatch, useUser } from '../../hooks'
import {
    PRE_APPROVED_TO_APPROVED,
    PRE_APPROVED_TO_GO_BACK,
    ASSIGNED_TO_IN_PROGRESS,
    COMPLETE_TO_PRE_APPROVED,
    COMPLETE_TO_APPROVED,
    COMPLETE_TO_GO_BACK,
    CREATE_WORKORDERS,
    defaultPermissible,
    DELETE_WORKORDERS,
    EDIT_PERMISSION_ROLES,
    EDIT_USER,
    EDIT_USER_PERMISSIONS,
    EDIT_WORKORDERS,
    IN_PROGRESS_TO_ASSIGNED,
    IN_PROGRESS_TO_COMPLETE,
    IN_PROGRESS_TO_PAUSE,
    PAUSE_TO_IN_PROGRESS,
    Permissible,
    PermissionRole,
    SEE_ACTION_ITEMS,
    SEE_INSPECTION,
    SEE_VENDOR_FINANCES,
    User,
    USE_MOBILE,
    USE_WEB,
    GO_BACK_TO_IN_PROGRESS,
    APPROVE_DENY_CHANGE_ORDER,
    VIEW_INVOICE,
    VIEW_NATIONAL_VENDOR_LIST,
    RECEIVE_DAILY_REPORT,
    SEE_INCOME_FINANCES,
    DMG_PENDING_TO_PREAPPROVED,
    DMG_PENDING_TO_DENIED,
    DMG_PREAPPROVED_TO_APPROVED,
    DMG_PREAPPROVED_TO_DENIED,
    DMG_APPROVED_TO_SYNCED,
    BULK_TRANSITION,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    INCIDENT_REPORTS,
    VENDOR_INVOICE_PERMISSION,
    SMART_SCHEDULER_PERMISSION,
    SUPER_TRANSITION_REVERSAL,
    DELETE_DAMAGES_PERMISSION,
} from '../../models'
import { hasPermission } from '../../models/Users/services'
import {
    createPermissionRole,
    PermissionRoleRequestBody,
    updatePermissionRoleRequest,
} from '../../store'
import { isAccountManager } from '../../helpers'
import { SearchField } from '../../components/SearchField'

interface Props {
    permissionRole?: PermissionRole
    handleClose: () => void
    open: boolean
    organization: number
}

// First, let's create interfaces for our permission sections
interface PermissionSection {
    title: string
    permissions: PermissionItem[]
}

interface PermissionItem {
    field: keyof Permissible
    displayName: string
    showIf?: (user: User) => boolean // Optional condition to show permission
}

const PERMISSION_SECTIONS: PermissionSection[] = [
    {
        title: 'General',
        permissions: [
            { field: USE_WEB, displayName: 'Use Web' },
            { field: USE_MOBILE, displayName: 'Use Mobile' },
            { field: EDIT_USER, displayName: 'Edit User' },
            {
                field: EDIT_PERMISSION_ROLES,
                displayName: 'Edit Permission Roles',
            },
            {
                field: EDIT_USER_PERMISSIONS,
                displayName: 'Edit Users Permission Roles',
            },
            {
                field: SEE_VENDOR_FINANCES,
                displayName: 'See Schedule Finances',
            },
            { field: SEE_INCOME_FINANCES, displayName: 'See Income Finances' },
            { field: SEE_ACTION_ITEMS, displayName: 'See Action Items' },
            { field: SEE_INSPECTION, displayName: 'See Inspections' },
            {
                field: APPROVE_DENY_CHANGE_ORDER,
                displayName: 'Approve / Deny Change Order',
            },
            { field: VIEW_INVOICE, displayName: 'View Invoice' },
            {
                field: VIEW_NATIONAL_VENDOR_LIST,
                displayName: 'View National Vendor List',
            },
            {
                field: RECEIVE_DAILY_REPORT,
                displayName: 'Receive Daily Report',
            },
            { field: INCIDENT_REPORTS, displayName: 'Incident Reports' },
            { field: BULK_TRANSITION, displayName: 'Bulk Transition' },
            {
                field: EDIT_VENDOR_SUBSCRIPTION_STATUS,
                displayName: 'Edit Vendor Subscription Status',
                showIf: (user) =>
                    user?.active_workspace?.company_type === 'VENDOR',
            },
            {
                field: VENDOR_INVOICE_PERMISSION,
                displayName: 'Vendor Invoicing',
                showIf: (user) =>
                    user?.active_workspace?.company_type === 'VENDOR',
            },
            {
                field: SMART_SCHEDULER_PERMISSION,
                displayName: 'Smart Scheduler',
                showIf: (user) =>
                    user?.active_workspace?.company_type === 'APARTMENT',
            },
            {
                field: SUPER_TRANSITION_REVERSAL,
                displayName: 'Bulk Transition Reversal',
                showIf: (user) =>
                    user?.active_workspace?.company_type === 'APARTMENT',
            },
            {
                field: DELETE_DAMAGES_PERMISSION,
                displayName: 'Delete Damages',
                showIf: (user) =>
                    user?.active_workspace?.company_type === 'APARTMENT',
            },
        ],
    },
    {
        title: 'Workorder',
        permissions: [
            { field: CREATE_WORKORDERS, displayName: 'Create Workorders' },
            { field: EDIT_WORKORDERS, displayName: 'Edit Workorders' },
            { field: DELETE_WORKORDERS, displayName: 'Delete Workorders' },
        ],
    },
    {
        title: 'Workorder Transitions',
        permissions: [
            {
                field: ASSIGNED_TO_IN_PROGRESS,
                displayName: 'Assigned - In Progress',
            },
            {
                field: IN_PROGRESS_TO_COMPLETE,
                displayName: 'In Progress - Complete',
            },
            { field: IN_PROGRESS_TO_PAUSE, displayName: 'In Progress - Pause' },
            {
                field: IN_PROGRESS_TO_ASSIGNED,
                displayName: 'In Progress - Assigned',
            },
            { field: PAUSE_TO_IN_PROGRESS, displayName: 'Pause - In Progress' },
            {
                field: GO_BACK_TO_IN_PROGRESS,
                displayName: 'Go Back - In Progress',
            },
            {
                field: PRE_APPROVED_TO_APPROVED,
                displayName: 'Pre-Approved - Approved',
            },
            {
                field: PRE_APPROVED_TO_GO_BACK,
                displayName: 'Pre-Approved - Go Back',
            },
            {
                field: COMPLETE_TO_PRE_APPROVED,
                displayName: 'Complete - Pre-Approved',
            },
            { field: COMPLETE_TO_APPROVED, displayName: 'Complete - Approved' },
            { field: COMPLETE_TO_GO_BACK, displayName: 'Complete - Go Back' },
        ],
    },
    {
        title: 'Damage Transitions',
        permissions: [
            {
                field: DMG_PENDING_TO_PREAPPROVED,
                displayName: 'Pending - Pre-Approved',
            },
            { field: DMG_PENDING_TO_DENIED, displayName: 'Pending - Denied' },
            {
                field: DMG_PREAPPROVED_TO_APPROVED,
                displayName: 'Pre-Approved - Approved',
            },
            {
                field: DMG_PREAPPROVED_TO_DENIED,
                displayName: 'Pre-Approved - Denied',
            },
            { field: DMG_APPROVED_TO_SYNCED, displayName: 'Approved - Synced' },
        ],
    },
]

// Add a helper function to check if a permission matches the search
const matchesSearch = (
    permission: PermissionItem,
    searchText: string,
): boolean => {
    const search = searchText.toLowerCase()
    return permission.displayName.toLowerCase().includes(search)
}

// Create a reusable Section component
const PermissionSection: React.FC<{
    section: PermissionSection
    editedPermissions: Permissible
    setEditedPermissions: (p: Permissible) => void
    disabled: boolean
    workspaceUser?: User
    searchText: string
}> = ({
    section,
    editedPermissions,
    setEditedPermissions,
    disabled,
    workspaceUser,
    searchText,
}) => {
    const theme = useTheme()

    // Filter permissions based on search and conditions
    const visiblePermissions = section.permissions.filter(
        (permission) =>
            (!permission.showIf || permission.showIf(workspaceUser!)) &&
            (!searchText || matchesSearch(permission, searchText)),
    )

    // Don't render the section if no permissions match the search
    if (visiblePermissions.length === 0) {
        return null
    }

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    padding: theme.spacing(2, 2, 1, 2),
                    backgroundColor: theme.palette.background.paper,
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                {section.title}
            </Container>
            <Container
                style={{
                    padding: theme.spacing(0, 2),
                    flexDirection: 'column',
                }}
            >
                {visiblePermissions.map((permission) => (
                    <PermissionCheckBox
                        key={`ROLE_DRAWER_CHECKBOX_${permission.field}`}
                        editedPermissions={editedPermissions}
                        setEditedPermissions={setEditedPermissions}
                        permissibleField={permission.field}
                        permissibleFieldDisplayName={permission.displayName}
                        disabled={disabled}
                    />
                ))}
            </Container>
        </Container>
    )
}

export const PermissionRoleDrawer = (props: Props) => {
    const { permissionRole, handleClose, open, organization } = props

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const { workspaceUser } = useUser()

    const [editedPermissions, setEditedPermissions] = useState<Permissible>(
        defaultPermissible,
    )
    const [name, setName] = useState('')
    const [doValidate, setDoValidate] = useState(false)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        if (permissionRole) {
            setEditedPermissions(permissionRole)
            setName(permissionRole.name)
        } else {
            setEditedPermissions(defaultPermissible)
            setName('')
        }
        setDoValidate(false)
    }, [permissionRole])

    const onSave = () => {
        // validate name
        if (name === '') {
            setDoValidate(true)
            return
        }

        const requestBody: PermissionRoleRequestBody = {
            ...editedPermissions,
            name: name,
            organization: organization,
        }

        if (permissionRole) {
            // update
            dispatch(
                updatePermissionRoleRequest({
                    id: permissionRole.id,
                    body: requestBody,
                }),
            ).then(() => {
                toast.success(`${name} was updated!`)
                handleClose()
                setEditedPermissions(defaultPermissible)
                setName('')
            })
        } else {
            // create
            dispatch(createPermissionRole({ body: requestBody })).then(() => {
                toast.success(`${name} was created!`)
                handleClose()
                setEditedPermissions(defaultPermissible)
                setName('')
            })
        }
    }

    const CAN_NOT_EDIT_PERMISSION_ROLES = !hasPermission(
        workspaceUser,
        EDIT_PERMISSION_ROLES,
    )

    return (
        <Container
            direction="column"
            flex={1}
            style={{
                height: '100%',
                display: 'flex',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    position: 'sticky',
                    top: 0,
                    zIndex: 3,
                    flexDirection: 'column',
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <IconButton onClick={handleClose}>
                        <ChevronLeft />
                    </IconButton>
                    <TextField
                        label="Role"
                        error={doValidate && name === ''}
                        helperText={doValidate && name === '' ? 'Required' : ''}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '100%' }}
                        disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        size="small"
                    />
                </Container>
                <SearchField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                />
            </Container>

            {/* Scrollable content */}
            <Container
                direction="column"
                flex={1}
                style={{
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    flexGrow: 1,
                    position: 'relative',
                }}
            >
                {PERMISSION_SECTIONS.map((section) => (
                    <PermissionSection
                        key={section.title}
                        section={section}
                        editedPermissions={editedPermissions}
                        setEditedPermissions={setEditedPermissions}
                        disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        workspaceUser={workspaceUser}
                        searchText={searchText}
                    />
                ))}
            </Container>

            {/* Footer */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                    borderTop: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3, // Increased to be above section headers
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                        handleClose()
                        setEditedPermissions(defaultPermissible)
                        setName('')
                    }}
                >
                    Close
                </Button>
                {!CAN_NOT_EDIT_PERMISSION_ROLES && (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => onSave()}
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        {permissionRole ? 'Update' : 'Create'}
                    </Button>
                )}
            </Container>
        </Container>
    )
}

interface PermissionCheckBoxProps {
    editedPermissions: Permissible
    setEditedPermissions: (permissible: Permissible) => void
    permissibleField: keyof Permissible
    permissibleFieldDisplayName: string
    disabled: boolean
}

const PermissionCheckBox = (props: PermissionCheckBoxProps) => {
    const {
        editedPermissions,
        setEditedPermissions,
        permissibleField,
        permissibleFieldDisplayName,
        disabled,
    } = props

    const theme = useTheme()

    return (
        <FormControlLabel
            key={`ROLE_DRAWER_CHECKBOX_${permissibleField}`}
            control={
                <Checkbox
                    checked={editedPermissions[permissibleField] === 1}
                    style={{ color: theme.palette.primary.dark }}
                    onClick={() => {
                        const newPermissible: Permissible = {
                            ...editedPermissions,
                            [permissibleField]:
                                editedPermissions[permissibleField] === 0
                                    ? 1
                                    : 0,
                        }
                        setEditedPermissions(newPermissible)
                    }}
                    disabled={disabled}
                />
            }
            label={permissibleFieldDisplayName}
            labelPlacement="end"
            style={{
                fontWeight: 'bold',
            }}
        />
    )
}
