import React, { useEffect, useState } from 'react'
import { Theme, Popover, Tabs, Tab, Button } from '@material-ui/core'
import { PopoverState } from '../../../hooks/usePopover'
import { Container } from '../../../components/Container'
import {
    DocumentOccurance,
    GENERATE_MARKDOWN,
    getJobStage,
    getJobStatus,
} from '../../../models'
import { LeaseAuditController } from '../../../hooks/documentAudit/useLeaseAudit'
export const DocumentDetailPopover = (props: {
    theme: Theme
    popoverState: PopoverState<DocumentOccurance | null>
    leaseAuditController: LeaseAuditController
}) => {
    const { theme, popoverState, leaseAuditController } = props
    const docOccurance = popoverState.context
    const document = docOccurance?.document
    const ticket = document?.active_ticket

    const [tab, setTab] = useState(0)

    useEffect(() => {
        if (document?.id) {
            console.log('fetch history')
            setTab(0)
        }
    }, [document?.id])

    return (
        <Popover
            open={popoverState.isOpen}
            onClose={popoverState.handleClose}
            anchorEl={popoverState.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Active Ticket" />
                    <Tab label="History" />
                </Tabs>

                {tab === 0 && (
                    <Container style={{ flexDirection: 'column' }}>
                        {ticket?.jobs.map((job, index) => {
                            return (
                                <Container
                                    key={job.id}
                                    style={{
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Container
                                        style={{
                                            flexDirection: 'row',
                                            padding: theme.spacing(2),
                                        }}
                                    >
                                        <span
                                            style={{
                                                ...theme.typography.subtitle1,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightMedium,
                                                color:
                                                    theme.palette.text.primary,
                                                width: 180,
                                            }}
                                        >
                                            {index + 1}.{' '}
                                            {getJobStage(job.stage)}
                                        </span>
                                        <span
                                            style={{
                                                ...theme.typography.subtitle1,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                color:
                                                    theme.palette.text.primary,
                                            }}
                                        >
                                            {getJobStatus(job.status)}
                                        </span>
                                    </Container>
                                </Container>
                            )
                        })}

                        <Container
                            style={{
                                padding: theme.spacing(2),
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    if (
                                        docOccurance &&
                                        leaseAuditController.leaseAudit
                                    ) {
                                        leaseAuditController.runTickets(
                                            leaseAuditController.leaseAudit.id,
                                            document?.document_type ?? 'PDF',
                                            [docOccurance.id],
                                        )
                                    }
                                }}
                            >
                                Run Pipeline
                            </Button>
                        </Container>
                    </Container>
                )}

                {tab === 1 && (
                    <Container>
                        <span>History</span>
                    </Container>
                )}
            </Container>
        </Popover>
    )
}
