import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    makeStyles,
    Typography,
    useTheme,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import {
    Container,
    MessageForm,
    Selector,
    TimelinePicker,
    WorkorderRow,
} from '../../../components'
import {
    BulkUpdateWorkOrderRequest,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'
import {
    BaseWorkorder,
    IdBoolMap,
    ListVendor,
    Service,
    WorkorderStatus,
    WorkSpaceUser,
} from '../../../models'
import { useDateRange, useSelectVendorService } from '../../../hooks'

interface Props {
    workorderList: WorkorderResponse[]
    vendorList: ListVendor[]
    serviceList: Service[]
    workspaceUser?: WorkSpaceUser
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    updateWorkorderState: (wo: WorkorderResponse) => void
    bulkUpdateWorkorders: (request: BulkUpdateWorkOrderRequest) => Promise<void>
    openEditModal: (workorder: WorkorderResponse) => void
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
    },
    scrollContainer: {
        flex: 1,
        overflowY: 'auto',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(1),
    },
    accordion: {
        marginBottom: theme.spacing(1),
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: theme.palette.grey[100],
        borderBottom: `1px solid ${theme.palette.divider}`,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    accordionDetails: {
        padding: theme.spacing(2),
        flexDirection: 'column',
    },
    serviceName: {
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1,
    },
    workorderCount: {
        marginLeft: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    expanded: {},
}))

export const UnassignedWorkordersView = (props: Props) => {
    const {
        workorderList,
        workspaceUser,
        vendorList,
        serviceList,
        updateWorkorderState,
        transitionWorkorder,
        bulkUpdateWorkorders,
        openEditModal,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [selectedWorkorderMap, setSelectedWorkorderMap] = useState<IdBoolMap>(
        {},
    )

    const [openMessageChannel, setOpenMessageChannel] = useState(-1)
    const [
        selectedWorkorder,
        setSelectedWorkorder,
    ] = useState<WorkorderResponse | null>(null)

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
        filteredVendorList,
    } = useSelectVendorService(vendorList)

    const { dateRange, setEndDate, setStartDate, setRange } = useDateRange('')

    const workordersByService = useMemo(() => {
        const groupedWorkorders: {
            [serviceId: number]: WorkorderResponse[]
        } = {}
        workorderList.forEach((wo) => {
            if (wo.service_id) {
                if (!groupedWorkorders[wo.service_id]) {
                    groupedWorkorders[wo.service_id] = []
                }
                groupedWorkorders[wo.service_id].push(wo)
            }
        })
        return groupedWorkorders
    }, [workorderList])

    const selectedWorkorderIds = Object.keys(selectedWorkorderMap).reduce<
        number[]
    >((prev, id) => {
        if (id === 'length') {
            return prev
        }
        const numID = Number(id)
        if (selectedWorkorderMap[numID] === true) {
            return prev.concat(numID)
        }
        return prev
    }, [])

    const selectedWorkordersLength = selectedWorkorderIds.length

    const handleWorkorderSelection = (woId: number, serviceId: number) => {
        if (selectedServiceId !== serviceId) {
            selectService(serviceId)
            const emptyMap: IdBoolMap = {}
            setSelectedWorkorderMap({
                ...emptyMap,
                [woId]: true,
            })
        } else {
            setSelectedWorkorderMap((prev) => ({
                ...prev,
                [woId]: !prev[woId],
            }))
        }
    }

    const onClickAssign = () => {
        setLoading(true)

        const request: BulkUpdateWorkOrderRequest = {
            body: {
                workorders: selectedWorkorderIds,
                start_date: dateRange.startDate?.toISOString(),
                end_date: dateRange.endDate?.toISOString(),
                vendor: selectedVendorId,
            },
        }

        bulkUpdateWorkorders(request)
            .then(() => {
                toast.success(
                    `${selectedWorkordersLength} workorders assigned successfully!`,
                )
                setSelectedWorkorderMap({})
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container className={classes.contentContainer}>
            <Container style={{ flexDirection: 'column', flex: 1 }}>
                <Container style={{ paddingLeft: theme.spacing(2) }}>
                    <Typography
                        variant="body2"
                        style={{ color: theme.palette.primary.dark }}
                    >
                        Select your workorders and then choose a vendor to
                        assign
                    </Typography>
                </Container>
                <Container
                    className={classes.scrollContainer}
                    style={{ paddingRight: theme.spacing(1) }}
                >
                    {serviceList.map((service) => {
                        const workorders = workordersByService[service.id] ?? []
                        if (workorders.length === 0) {
                            return
                        }
                        return (
                            <Accordion
                                key={service.id}
                                className={classes.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.accordionSummary}
                                    classes={{ expanded: classes.expanded }}
                                >
                                    <Typography className={classes.serviceName}>
                                        {service.name}
                                    </Typography>
                                    <Typography
                                        style={{ marginLeft: theme.spacing(2) }}
                                        className={classes.workorderCount}
                                    >
                                        ({workorders.length} workorders)
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{ flexDirection: 'column' }}
                                    className={classes.accordionDetails}
                                >
                                    {workorders.map((wo) => (
                                        <Container
                                            key={`UNASSIGNED_WORKORDER_${wo.id}`}
                                        >
                                            <Checkbox
                                                style={{ width: CHECK_BOX_W }}
                                                checked={
                                                    selectedWorkorderMap[
                                                        wo.id
                                                    ] === true
                                                }
                                                onChange={() =>
                                                    handleWorkorderSelection(
                                                        wo.id,
                                                        service.id,
                                                    )
                                                }
                                                disabled={loading}
                                            />
                                            <WorkorderRow
                                                workorder={wo}
                                                width={'100%'}
                                                isSelectedWorkorder={
                                                    wo.id ===
                                                    selectedWorkorder?.id
                                                }
                                                canSeeUnitNotes
                                                openUnitNotes={() => {}}
                                                transitionWorkorder={
                                                    transitionWorkorder
                                                }
                                                onClickWorkorder={() => {}}
                                                updateWorkorderState={
                                                    updateWorkorderState
                                                }
                                                user={workspaceUser}
                                                openMessageDrawer={() => {
                                                    setSelectedWorkorder(wo)
                                                }}
                                                hideDates
                                                openEditModal={openEditModal}
                                            />
                                        </Container>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Container>
            </Container>
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <Selector
                    label="Vendor"
                    data={filteredVendorList}
                    searchable
                    currentValue={selectedVendorId}
                    onChange={(e) => {
                        selectVendor(e.target.value as number)
                    }}
                    getDisplayString={(vendor) => vendor.name}
                    customStyle={{
                        formControl: { margin: theme.spacing(1) },
                    }}
                />

                <div style={{ margin: theme.spacing(1) }}>
                    <TimelinePicker
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onChangeStartDate={setStartDate}
                        onChangeEndDate={setEndDate}
                    />
                </div>

                <Container style={{ flex: 1 }} />

                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        color: loading
                            ? '#fff'
                            : theme.palette.primary.contrastText,
                    }}
                    onClick={onClickAssign}
                    disabled={
                        loading ||
                        selectedVendorId === -1 ||
                        selectedWorkordersLength === 0
                    }
                >
                    {loading ? (
                        <CircularProgress
                            size={25}
                            style={{ color: 'white' }}
                        />
                    ) : (
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {`Assign (${selectedWorkordersLength})`}
                        </span>
                    )}
                </Button>
            </Container>
            {selectedWorkorder && (
                <Container
                    style={{
                        width: 400,
                        borderLeft: `1px solid ${theme.palette.grey[700]}`,
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(1),
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={() => setSelectedWorkorder(null)}>
                            <ChevronRightIcon />
                        </IconButton>

                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            Messages
                        </span>
                    </Container>

                    <MessageForm
                        theme={theme}
                        channelId={selectedWorkorder.channel}
                        secondaryChannelId={selectedWorkorder?.inv_ins_channel}
                        secondaryTitle="From Inventory Inspection"
                        afterCreateMessage={() => {
                            updateWorkorderState({
                                ...selectedWorkorder,
                                message_count:
                                    selectedWorkorder.message_count + 1,
                            })
                        }}
                    />
                </Container>
            )}
        </Container>
    )
}

const CHECK_BOX_W = 42
