import React, { useState, useEffect } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import {
    Container,
    EllipsisSpan,
    Finder,
    Selector,
    SideDrawer,
} from '../../components'

import {
    DrawerState,
    FORM_CLOSED,
    INVENTORY_CONFIG,
    AREA_CONFIG,
    UNIT_CONFIG,
} from './InfrastructureConfig'
import { AreaConfig, ModelMap, Unit, UnitConfig } from '../../models'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    Location,
} from '../../store'
import {
    useDragMouse,
    _useFinderSelection,
    useRootInfrastructure,
} from '../../hooks'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'

interface Props {
    open: DrawerState
    areaConfigList: AreaConfig[]
    unitConfigList: UnitConfig[]
    unitConfigMap: ModelMap<UnitConfig>
    handleDrawerChange: (drawerState?: DrawerState) => void
}

export const CreateInfrastructureDrawer = (props: Props) => {
    const {
        open,
        handleDrawerChange,
        areaConfigList,
        unitConfigList,
        unitConfigMap,
    } = props

    const [requestLoading, setRequestLoading] = useState(false)

    const [multiAreaConfigId, setMultiAreaConfigId] = useState<number[]>([])
    const [areaConfigId, setAreaConfigId] = useState(-1)
    const [unitConfigId, setUnitConfigId] = useState(-1)
    const [areaLabel, setAreaLabel] = useState('')

    const [doValidate, setDoValidate] = useState(false)
    const [ignoreDuplicates, setIgnoreDuplicates] = useState(true)

    const theme = useTheme()

    const { mouseDown } = useDragMouse()

    const { tree } = useRootInfrastructure(true)

    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    useEffect(() => {
        setDoValidate(false)
        setAreaConfigId(-1)
        setUnitConfigId(-1)
        setAreaLabel('')
        finderSelection.setFinderSelection()
    }, [open])

    useEffect(() => {
        finderSelection.setFinderSelection()
    }, [unitConfigId, areaConfigId])

    const excluder = (location: Location): boolean => {
        if (open.formOpen === AREA_CONFIG) {
            // The user is wanting to add areas to unit types
            // exclude all units that do not match the selected unit type
            if (location.type === 'unit') {
                const unit = location.location as Unit
                return unit.unit_config !== unitConfigId
            }
        }
        if (open.formOpen === UNIT_CONFIG) {
            if (location.type === 'unit') {
                const unit = location.location as Unit
                return unit.unit_config !== open.selected?.id
            }
        }

        return false
    }

    let addingLabel = open.selected?.name ?? ''
    let addingToLabel = ''
    if (open.formOpen === INVENTORY_CONFIG) {
        addingToLabel =
            areaConfigList.find((areaConf) => areaConf.id === areaConfigId)
                ?.name ?? 'Area Type Required'
    } else if (open.formOpen === AREA_CONFIG) {
        if (areaLabel === '') {
            addingLabel += ' (Label Required)'
        } else {
            addingLabel += ` (${areaLabel})`
        }

        addingToLabel =
            unitConfigList.find((unitConf) => unitConf.id === unitConfigId)
                ?.name ?? 'Unit Type Required'
    } else if (open.formOpen === UNIT_CONFIG) {
        addingToLabel = open.selected?.name ?? ''

        addingLabel =
            areaConfigList.find((areaConf) => areaConf.id === areaConfigId)
                ?.name ?? 'Area Type Required'
        if (areaLabel === '') {
            addingLabel += ' (Label Required)'
        } else {
            addingLabel += ` (${areaLabel})`
        }
    }

    const isFormValid = () => {
        if (open.formOpen === FORM_CLOSED) return false
        if (finderSelection.selection.unit.length === 0) return false

        if (open.formOpen === INVENTORY_CONFIG) {
            return areaConfigId !== -1
        }

        if (open.formOpen === AREA_CONFIG) {
            return (
                unitConfigId !== -1 &&
                areaLabel.length > 0 &&
                areaLabel.length <= 3
            )
        }

        if (open.formOpen === UNIT_CONFIG) {
            return (
                multiAreaConfigId.length > 0 &&
                areaLabel.length > 0 &&
                areaLabel.length <= 3
            )
        }
    }

    const pressConfirm = () => {
        setDoValidate(true)
        if (isFormValid()) {
            let msg = ''
            let url = ''

            const body: any = {}
            const unitIds: string[] = []
            Object.keys(finderSelection.selection.unit).forEach((key) => {
                if (key === 'length') {
                    return
                }
                unitIds.push(key)
            })
            body.units = unitIds

            if (open.formOpen === INVENTORY_CONFIG) {
                url = 'infrastructure/inventory/'

                body.inventory_config = open.selected!.id
                body.area_config = areaConfigId
                body.ignore_duplicates = ignoreDuplicates

                msg = `Confirm you would like to add 1 ${addingLabel} to every ${addingToLabel}. In the selected ${finderSelection.selection.unit.length} units.`
            } else if (open.formOpen === AREA_CONFIG) {
                url = 'infrastructure/area/'
                body.area_config = [open.selected!.id]
                body.unit_config = unitConfigId
                body.area_label = areaLabel
                msg = `Confirm you would like to add 1 ${addingLabel} to every ${addingToLabel}. In the selected ${finderSelection.selection.unit.length} units.  Any unit that already has ${addingLabel} will be ignored.`
            } else if (open.formOpen === UNIT_CONFIG) {
                url = 'infrastructure/area/'
                body.area_config = multiAreaConfigId
                body.unit_config = open.selected!.id
                body.area_label = areaLabel
                msg = `Confirm you would like to add 1 ${addingLabel} to every ${addingToLabel}. In the selected ${finderSelection.selection.unit.length} units.  Any unit that already has ${addingLabel} will be ignored.`
            }
            if (confirm(msg)) {
                setRequestLoading(true)
                axiosInstance
                    .post(url, body)
                    .then((res) => {
                        handleDrawerChange()
                        toast.success(
                            `Created ${res.data.length} ${addingLabel}`,
                        )
                    })
                    .finally(() => setRequestLoading(false))
            }
        }
    }

    return (
        <SideDrawer
            open={open.formOpen !== FORM_CLOSED}
            handleClose={() => handleDrawerChange()}
            title={`Add ${open.selected?.name}`}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                    flex: 1,
                    flexDirection: 'column',
                }}
                scrollY
            >
                {open.formOpen === INVENTORY_CONFIG && (
                    <>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={ignoreDuplicates}
                                    color="primary"
                                    onChange={(e) =>
                                        setIgnoreDuplicates(e.target.checked)
                                    }
                                />
                            }
                            labelPlacement="end"
                            label="Ignore Duplicates"
                            style={{ marginBottom: theme.spacing(1) }}
                        />
                        <Selector
                            error={doValidate && areaConfigId === -1}
                            data={areaConfigList}
                            customStyle={{
                                formControl: {
                                    margin: 0,
                                    marginTop: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                },
                            }}
                            label="Select Area Type"
                            currentValue={areaConfigId}
                            noSelectionLabel="Select an area type"
                            getDisplayString={(areaConf) => areaConf.name}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setAreaConfigId(event.target.value as number)
                            }}
                        />
                    </>
                )}

                {open.formOpen === AREA_CONFIG && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Selector
                            error={doValidate && unitConfigId === -1}
                            data={unitConfigList}
                            customStyle={{
                                formControl: {
                                    margin: 0,
                                    marginTop: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                },
                            }}
                            label="Select Unit Type"
                            currentValue={unitConfigId}
                            noSelectionLabel="Select a unit type"
                            getDisplayString={(unitConfig) => unitConfig.name}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setUnitConfigId(event.target.value as number)
                            }}
                        />

                        <TextField
                            error={doValidate && areaLabel === ''}
                            variant="outlined"
                            value={areaLabel}
                            inputProps={{ maxLength: 3 }}
                            onChange={(e) => setAreaLabel(e.target.value)}
                            placeholder="Area Label"
                        />
                    </Container>
                )}

                {open.formOpen === UNIT_CONFIG && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Selector
                            error={doValidate && areaConfigId === -1}
                            data={areaConfigList}
                            customStyle={{
                                formControl: {
                                    margin: 0,
                                    marginTop: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                },
                            }}
                            multiple
                            label="Select Area Type"
                            currentValue={multiAreaConfigId}
                            noSelectionLabel="Select an Area type"
                            getDisplayString={(areaConfig) => areaConfig.name}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setMultiAreaConfigId(
                                    event.target.value as number[],
                                )
                            }}
                        />

                        <TextField
                            error={doValidate && areaLabel === ''}
                            variant="outlined"
                            value={areaLabel}
                            inputProps={{ maxLength: 3 }}
                            onChange={(e) => setAreaLabel(e.target.value)}
                            placeholder="Area Label"
                        />
                    </Container>
                )}

                <Container
                    style={{
                        marginBottom: theme.spacing(2),
                        marginTop: theme.spacing(2),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {addingLabel}
                    </span>
                    <ArrowRightAltIcon />
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {addingToLabel}
                    </span>
                </Container>
                <Finder
                    root={tree.root}
                    onClickUnit={(unit) =>
                        finderSelection.setFinderSelection(unit, excluder)
                    }
                    onClickFolder={(folder) =>
                        finderSelection.setFinderSelection(folder, excluder)
                    }
                    locationSelection={finderSelection.selection}
                    getUnitAdornment={(unit) => {
                        return (
                            <Container
                                style={{
                                    flex: 1,
                                    marginLeft: theme.spacing(1),
                                    alignItems: 'center',
                                }}
                            >
                                <EllipsisSpan
                                    style={{
                                        ...theme.typography.caption,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        maxWidth: 60,
                                    }}
                                >
                                    (
                                    {unitConfigMap[unit.unit_config]?.name ??
                                        ''}
                                    )
                                </EllipsisSpan>

                                <div style={{ flex: 1 }} />
                                <Checkbox
                                    disabled={excluder({
                                        type: 'unit',
                                        location: unit,
                                    })}
                                    checked={
                                        finderSelection.selection.unit[
                                            unit.id
                                        ] !== undefined
                                    }
                                    onMouseDown={() => {
                                        finderSelection.setFinderSelection(
                                            {
                                                type: 'unit',
                                                location: unit,
                                            },
                                            excluder,
                                        )
                                    }}
                                    onMouseOver={() => {
                                        if (mouseDown) {
                                            finderSelection.setFinderSelection(
                                                {
                                                    type: 'unit',
                                                    location: unit,
                                                },
                                                excluder,
                                            )
                                        }
                                    }}
                                />
                            </Container>
                        )
                    }}
                    getFolderAdornment={(folder) => {
                        return (
                            <Container
                                style={{
                                    justifyContent: 'flex-end',
                                    flex: 1,
                                }}
                            >
                                <Checkbox
                                    color="primary"
                                    checked={
                                        finderSelection.selection.folder[
                                            folder.id
                                        ] !== undefined
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        finderSelection.setFinderSelection(
                                            {
                                                type: 'folder',
                                                location: folder,
                                            },
                                            excluder,
                                        )
                                    }}
                                />
                            </Container>
                        )
                    }}
                    selectedIcon={<></>}
                />
                <span style={{ height: 20, color: 'red' }}>
                    {doValidate && finderSelection.selection.unit.length === 0
                        ? 'Units Required *'
                        : ''}
                </span>
            </Container>
            <Divider />
            <Container
                style={{
                    margin: theme.spacing(2),
                    marginRight: theme.spacing(1),
                    marginLeft: theme.spacing(1),
                }}
            >
                <div style={{ flex: 1 }} />
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        handleDrawerChange()
                        finderSelection.setFinderSelection()
                    }}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    Cancel
                </Button>
                {requestLoading ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={pressConfirm}
                    >
                        Confirm ({finderSelection.selection.unit.length} Units)
                    </Button>
                )}
            </Container>
        </SideDrawer>
    )
}
