import React, { useState, memo } from 'react'
import {
    getServiceImageDetails,
    ListVendor,
    TemplateService,
} from '../../../../models'
import { toast } from 'react-toastify'
import { SmartScheduleController } from '../../../../hooks'
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Theme,
    Tooltip,
    MenuItem,
} from '@material-ui/core'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DragIndicator from '@material-ui/icons/DragIndicator'
import { SelectorPopover } from '../../../../components/TemplateScheduleGantt/Components/SelectorPopover'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { Container } from '../../../../components/Container'
import { VENDOR_BAR_H } from '../../../../components/TemplateScheduleGantt/constants'
import { usePopover } from '../../../../hooks/usePopover'

import {
    SetupSmartScheduleFormState,
    TemplateServiceFormState,
} from './SetupStep'

import { Draggable } from 'react-beautiful-dnd'

interface Props {
    theme: Theme
    templateService: TemplateServiceFormState
    vendorList: ListVendor[]
    formState: SetupSmartScheduleFormState
    setFormState: React.Dispatch<
        React.SetStateAction<SetupSmartScheduleFormState>
    >
    index: number
}

export const ServiceRow = memo((props: Props) => {
    const {
        theme,
        templateService,
        vendorList,
        formState,
        setFormState,
        index,
    } = props

    const vendorPopoverState = usePopover()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const vendorOptions = vendorList.filter((vnd) => {
        const serviceOffered =
            vnd.services.find((srv) => templateService.serviceId === srv.id) !==
            undefined

        const alreadyIncluded =
            templateService.templateServiceVendors.find(
                (tsv) => tsv.vendorId === vnd.id,
            ) !== undefined

        return serviceOffered && !alreadyIncluded
    })

    const vendorRows = templateService.templateServiceVendors.map((tsv) => {
        return (
            <Container
                key={`TEMPLATE-VENDOR-${tsv.vendorId}`}
                style={{
                    marginLeft: theme.spacing(6),
                    border: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(2),
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(2, 0),
                    width: 500,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightLight,
                        color: theme.palette.text.primary,
                    }}
                >
                    {tsv.vendorName}
                </span>
                <div style={{ flex: 1 }} />
                <Tooltip title="Remove Vendor">
                    <IconButton
                        size="small"
                        onClick={() => {
                            setFormState((prev) => {
                                const newTemplateServices = [
                                    ...prev.templateServices,
                                ]
                                const serviceIndex = newTemplateServices.findIndex(
                                    (ts) =>
                                        ts.serviceId ===
                                        templateService.serviceId,
                                )

                                newTemplateServices[
                                    serviceIndex
                                ].templateServiceVendors = newTemplateServices[
                                    serviceIndex
                                ].templateServiceVendors.filter(
                                    (vendor) =>
                                        vendor.vendorId !== tsv.vendorId,
                                )

                                return {
                                    ...prev,
                                    templateServices: newTemplateServices,
                                }
                            })
                        }}
                    >
                        <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Container>
        )
    })

    return (
        <>
            <Draggable
                draggableId={templateService.serviceId.toString()}
                index={index}
            >
                {(provided, snapshot) => (
                    <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{
                            ...provided.draggableProps.style,
                            display: 'flex',
                            padding: theme.spacing(2),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            background: snapshot.isDragging
                                ? theme.palette.grey[100]
                                : 'white',
                            userSelect: 'none',
                            flexDirection: 'column',
                        }}
                    >
                        <Container>
                            {/* Drag Handle */}
                            <div
                                {...provided.dragHandleProps}
                                style={{
                                    padding: theme.spacing(1),
                                }}
                            >
                                <DragIndicator />
                            </div>

                            {/* Main Content */}
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={
                                        getServiceImageDetails(
                                            templateService.serviceId,
                                        ).icon
                                    }
                                    style={{
                                        maxHeight: 25,
                                        maxWidth: 27,
                                    }}
                                />

                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    {templateService.serviceName}
                                </span>

                                <div style={{ flex: 1 }} />

                                <Tooltip title="Add Vendor">
                                    <IconButton
                                        size="small"
                                        onClick={vendorPopoverState.handleOpen}
                                    >
                                        <PersonAddIcon />
                                    </IconButton>
                                </Tooltip>

                                <IconButton
                                    size="small"
                                    style={{ marginLeft: theme.spacing(1) }}
                                    onClick={openMenu}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        </Container>
                        {vendorRows}
                        <SelectorPopover
                            {...props}
                            popoverState={vendorPopoverState}
                            label="Select Vendor"
                            data={vendorOptions}
                            onSubmit={(vndId) => {
                                setFormState((prev) => {
                                    const newTemplateServices = [
                                        ...prev.templateServices,
                                    ]
                                    const serviceIndex = newTemplateServices.findIndex(
                                        (ts) =>
                                            ts.serviceId ===
                                            templateService.serviceId,
                                    )

                                    if (serviceIndex === -1) {
                                        return prev
                                    }

                                    newTemplateServices[
                                        serviceIndex
                                    ].templateServiceVendors.push({
                                        vendorId: vndId,
                                        vendorName:
                                            vendorOptions.find(
                                                (v) => v.id === vndId,
                                            )?.name ?? '',
                                    })

                                    return {
                                        ...prev,
                                        templateServices: newTemplateServices,
                                    }
                                })
                            }}
                        />
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    setFormState((prev) => {
                                        const newTemplateServices = [
                                            ...prev.templateServices,
                                        ]
                                        const serviceIndex = newTemplateServices.findIndex(
                                            (ts) =>
                                                ts.serviceId ===
                                                templateService.serviceId,
                                        )

                                        newTemplateServices.splice(
                                            serviceIndex,
                                            1,
                                        )
                                        return {
                                            ...prev,
                                            templateServices: newTemplateServices,
                                        }
                                    })
                                    handleClose()
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Remove {templateService.serviceName}
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </Draggable>
            {/* Vendor Rows */}
        </>
    )
})

ServiceRow.displayName = 'ServiceRow'
