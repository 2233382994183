import React, { useMemo, useState } from 'react'
import {
    InputAdornment,
    ListSubheader,
    Menu,
    MenuItem,
    TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { BaseProps } from '../../types'
import { PopoverState } from '../../../../hooks/usePopover'
import { IdentifiableNamedObject } from '../../../../models'

interface Props {
    popoverState: PopoverState<any>
    data: IdentifiableNamedObject[]
    label: string
    onSubmit: (id: number) => void
}

export const SelectorPopover = (props: Props) => {
    const { popoverState } = props

    const [searchText, setSearchText] = useState('')

    const filteredData = useMemo(() => {
        return props.data.filter((item) =>
            item.name
                .toLocaleLowerCase()
                .startsWith(searchText.toLocaleLowerCase()),
        )
    }, [searchText, props.data])

    return (
        <Menu
            open={popoverState.isOpen}
            anchorEl={popoverState.anchorEl}
            onClose={() => {
                popoverState.handleClose()
                setTimeout(() => {
                    setSearchText('')
                }, 300)
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <ListSubheader style={{ backgroundColor: 'white' }}>
                <TextField
                    size="small"
                    autoFocus
                    value={searchText}
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation()
                        }
                    }}
                />
            </ListSubheader>

            {filteredData.map((item) => {
                return (
                    <MenuItem
                        key={`ITEM-${item.id}`}
                        onClick={() => {
                            props.onSubmit(item.id)
                            popoverState.handleClose()
                            setTimeout(() => {
                                setSearchText('')
                            }, 300)
                        }}
                    >
                        <span>{item.name}</span>
                    </MenuItem>
                )
            })}
        </Menu>
    )
}
