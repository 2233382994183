import { Permissible } from '.'

export const getCleanPermissible = (permissible: Permissible) => {
    // takes in a permissible and returns a permissible without any fields that are not on all permissibles
    // for example, if a user is passed, a permissible will be returned where all the Permissible actions have
    // the same values as the user, but it will not have any fields such as id, name, etc

    const cleanPermissible: Permissible = {
        use_web: permissible.use_web,
        use_mobile: permissible.use_mobile,
        create_workorders: permissible.create_workorders,
        edit_workorders: permissible.edit_workorders,
        delete_workorders: permissible.delete_workorders,
        assigned_to_in_progress: permissible.assigned_to_in_progress,
        in_progress_to_assigned: permissible.in_progress_to_assigned,
        in_progress_to_complete: permissible.in_progress_to_complete,
        in_progress_to_pause: permissible.in_progress_to_pause,
        pause_to_in_progress: permissible.pause_to_in_progress,
        complete_to_approved: permissible.complete_to_approved,
        complete_to_pre_approved: permissible.complete_to_pre_approved,
        complete_to_go_back: permissible.complete_to_go_back,
        go_back_to_in_progress: permissible.go_back_to_in_progress,
        pre_approved_to_approved: permissible.pre_approved_to_approved,
        pre_approved_to_go_back: permissible.pre_approved_to_go_back,
        approved_to_invoiced: permissible.approved_to_invoiced,
        edit_user: permissible.edit_user,
        edit_user_permissions: permissible.edit_user_permissions,
        edit_permission_roles: permissible.edit_permission_roles,
        see_action_items: permissible.see_action_items,
        see_vendor_finances: permissible.see_vendor_finances,
        see_income_finances: permissible.see_income_finances,
        see_inspections: permissible.see_inspections,
        approve_deny_changeorder: permissible.approve_deny_changeorder,
        view_invoice: permissible.view_invoice,
        view_national_vendor_list: permissible.view_national_vendor_list,
        receive_daily_report: permissible.receive_daily_report,
        dmg_pending_to_denied: permissible.dmg_pending_to_denied,
        dmg_pending_to_preapproved: permissible.dmg_pending_to_preapproved,
        dmg_preapproved_to_denied: permissible.dmg_preapproved_to_denied,
        dmg_preapproved_to_approved: permissible.dmg_preapproved_to_approved,
        dmg_approved_to_synced: permissible.dmg_approved_to_synced,
        super_transition: permissible.super_transition,
        edit_vendor_subscription_status:
            permissible.edit_vendor_subscription_status,
        incident_reports: permissible.incident_reports,
        vendor_invoice_permission: permissible.vendor_invoice_permission,
        smart_scheduler_permission: permissible.smart_scheduler_permission,
        super_transition_reversal: permissible.super_transition_reversal,
        delete_damages: permissible.delete_damages,
    }

    return cleanPermissible
}
