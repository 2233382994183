import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { axiosInstance } from '../helpers'
import { IdentifiableObject, ServiceCategory } from '../models'

export const useServiceCategories = (apartmentId?: number) => {
    const [serviceCategories, setServiceCategories] = useState<
        ServiceCategory[]
    >([])

    useEffect(() => {
        getServiceCategories(apartmentId)
    }, [])

    const getServiceCategories = async (apartmentId?: number) => {
        try {
            const res = await axiosInstance.get('service/service-category/', {
                params: {
                    apartment_id: apartmentId,
                },
            })
            const serviceCategories: ServiceCategory[] = res.data
            setServiceCategories(serviceCategories)
            return serviceCategories
        } catch (e) {
            toast.error('Error getting service categories')
            return Promise.reject(e)
        }
    }

    const createServiceCategory = async (
        request: CreateServiceCategoryRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'service/service-category/',
                request,
            )
            const newServiceCategory: ServiceCategory = res.data
            _insertOrUpdateIdentifiable(
                newServiceCategory,
                setServiceCategories,
            )
            return newServiceCategory
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject(e)
        }
    }

    const deleteServiceCategory = async (id: number) => {
        try {
            await axiosInstance.delete(`service/service-category/${id}/`)
            setServiceCategories((old) => old.filter((sc) => sc.id !== id))
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject(e)
        }
    }

    const addServiceToCategory = async (
        request: AddServiceToCategoryRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'service/service-category/add_service_to_category/',
                request,
            )
            const updatedServiceCategory: ServiceCategory = res.data
            _insertOrUpdateIdentifiable(
                updatedServiceCategory,
                setServiceCategories,
            )
            return updatedServiceCategory
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject(e)
        }
    }

    const removeServiceFromCategory = async (
        request: AddServiceToCategoryRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                'service/service-category/delete_service_category_membership/',
                request,
            )
            const updatedServiceCategory: ServiceCategory = res.data
            _insertOrUpdateIdentifiable(
                updatedServiceCategory,
                setServiceCategories,
            )
            return updatedServiceCategory
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        serviceCategories,
        setServiceCategories,
        createServiceCategory,
        deleteServiceCategory,
        addServiceToCategory,
        removeServiceFromCategory,
    }
}

export interface CreateServiceCategoryRequest {
    name: string
    services: number[]
}

export interface AddServiceToCategoryRequest {
    service_id: number
    category_id: number
}
