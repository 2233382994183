import React, { useState } from 'react'

import {
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Button,
    makeStyles,
    useTheme,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { MoveOutVendorRule, ListVendor } from '../../models'
import { Container, Selector } from '../../components'
import { CreateOrUpdateMoveOutVendorRuleRequest } from '../../hooks/useMoveOutRules'

const useStyles = makeStyles((theme) => ({
    vendorRule: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 5px 5px rgba(0,0,0,0.1)',
    },
    addButton: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    selectorContainer: {
        flex: 1,
    },
}))

interface Props {
    moveOutStepId: number
    vendorRules: MoveOutVendorRule[]
    vendors: ListVendor[]
    currentServiceId: number
    createOrUpdateVendorRule: (
        request: CreateOrUpdateMoveOutVendorRuleRequest,
    ) => Promise<void>
    onDeleteVendorRule: (vendorRuleId: number) => Promise<void>
}

export const MoveOutVendorRuleList: React.FC<Props> = ({
    moveOutStepId,
    vendorRules,
    vendors,
    currentServiceId,
    createOrUpdateVendorRule,
    onDeleteVendorRule,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    const [selectedVendorId, setSelectedVendorId] = useState<number>(-1)
    const [newRank, setNewRank] = useState<number>(vendorRules.length + 1)

    const handleAddVendorRule = async () => {
        if (selectedVendorId !== -1) {
            const request: CreateOrUpdateMoveOutVendorRuleRequest = {
                move_out_step: moveOutStepId,
                vendor: selectedVendorId,
                rank: newRank,
            }
            await createOrUpdateVendorRule(request)
            setSelectedVendorId(-1)
            setNewRank(newRank + 1)
        }
    }

    const availableVendors = vendors.filter(
        (vendor) =>
            vendor.services.some(
                (service) => service.id === currentServiceId,
            ) && !vendorRules.some((rule) => rule.vendor === vendor.id),
    )

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Typography variant="h6">Vendor Rules</Typography>
            <List>
                {vendorRules.map((vendorRule) => {
                    const vendor = vendors.find(
                        (v) => v.id === vendorRule.vendor,
                    )
                    return (
                        <ListItem
                            key={vendorRule.id}
                            className={classes.vendorRule}
                        >
                            <ListItemText
                                primary={`${vendorRule.rank}. ${
                                    vendor?.name || 'Unknown Vendor'
                                }`}
                            />
                            <IconButton
                                onClick={() =>
                                    onDeleteVendorRule(vendorRule.id)
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    )
                })}
            </List>
            <Container
                className={classes.formContainer}
                style={{ alignItems: 'center' }}
            >
                <div className={classes.selectorContainer}>
                    <Selector
                        label="Vendor"
                        currentValue={selectedVendorId}
                        onChange={(e) =>
                            setSelectedVendorId(e.target.value as number)
                        }
                        data={availableVendors}
                        getDisplayString={(vendor) => vendor.name}
                        customStyle={{
                            formControl: {
                                flex: 1,
                                width: '100%',
                            },
                        }}
                        size="small"
                    />
                </div>
                <TextField
                    label="Rank"
                    type="number"
                    value={newRank}
                    onChange={(e) => setNewRank(parseInt(e.target.value))}
                    size="small"
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddVendorRule}
                    disabled={selectedVendorId === -1}
                    className={classes.addButton}
                >
                    Add Vendor Rule
                </Button>
            </Container>
        </Container>
    )
}
