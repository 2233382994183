import React from 'react'

import { Divider, IconButton, Tooltip, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../../components'
import {
    _usePrunedInfrastructure,
    useAppDispatch,
    useAptConfig,
    _useFinderSelection,
    useRootInfrastructure,
} from '../../../hooks'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    addInspection,
} from '../../../store'
import { AssignInspectionForm } from '../AssignInspectionForm'
import { InspectionAssignModal } from '../InspectionAssignModal'
import { Frequency } from '../../../models'

interface Props {
    openCreateFrequencyForm: boolean
    frequencyList: Frequency[]
    setFrequencyList: (list: Frequency[]) => void
    setOpenCreateFrequencyForm: (value: boolean) => void
    handleClose: () => void
}

export const CreateInspectionFrequencyTab = (props: Props) => {
    const {
        handleClose,
        openCreateFrequencyForm,
        setOpenCreateFrequencyForm,
        frequencyList,
        setFrequencyList,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const { tree } = useRootInfrastructure(true)

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Multi,
    })

    const prunedTree = _usePrunedInfrastructure(
        tree,
        locationFinderSelection.selection,
    )

    const { getAreaConfigMap } = useAptConfig({
        inspectionTypeList: true,
        inventoryConfigList: true,
        areaConfigList: true,
    })

    const areaConfigMap = getAreaConfigMap()

    return (
        <Container flex={1} direction="column">
            <Container
                alignItems="center"
                style={{ marginBottom: theme.spacing(2) }}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        flex: 1,
                    }}
                >
                    Step 3: Create Inspection Frequency
                </Container>
                <Tooltip title="Close">
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon
                            fontSize="inherit"
                            style={{
                                color: theme.palette.darkGreen.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Divider />

            <Container
                style={{
                    maxHeight: '720px',
                    height: 'calc(75vh)',
                    overflowY: 'auto',
                }}
            >
                <AssignInspectionForm
                    prunedTree={prunedTree.root}
                    onClose={handleClose}
                    setAssignModalOpen={() => setOpenCreateFrequencyForm(true)}
                    modalMode
                />

                <InspectionAssignModal
                    makeFilteredRequest={() => {}}
                    open={openCreateFrequencyForm}
                    areaConfigMap={areaConfigMap}
                    onClose={() => {
                        setOpenCreateFrequencyForm(false)
                        handleClose()
                    }}
                    onFrequencyCreate={(freq) => {
                        if (freq.inspection) {
                            dispatch(addInspection(freq.inspection))
                        }
                        setFrequencyList([...frequencyList, freq])
                    }}
                />
            </Container>
        </Container>
    )
}
