import {
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from './Container'
import { Schedule } from '../models'
import { Selector } from './Selector'
import { DropFiles } from './DropFiles'
import { DeleteForever } from '@material-ui/icons'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    scheduleList: Schedule[]
    onClose: () => void
}

export const UploadWorkorderCSVModal = (props: Props) => {
    const { open, scheduleList, onClose } = props

    const theme = useTheme()

    const [email, setEmail] = useState('')
    const [file, setFile] = useState<File | null>(null)
    const [selectedScheduleId, setSelectedScheduleId] = useState<number>(-1)
    const [doValidate, setDoValidate] = useState(false)

    const handleFileChange = (files: File[]) => {
        if (files.length > 0) {
            setFile(files[0])
        }
    }

    const checkValid = () => {
        if (selectedScheduleId === -1) {
            return false
        }
        if (file === null) {
            return false
        }
        return true
    }

    const handleUpload = () => {
        const formData = new FormData()
        formData.append('schedule_id', selectedScheduleId.toString())

        if (file) {
            formData.append('sheet', file)
        }

        if (email !== '') {
            formData.append('email', email)
        }

        axiosInstance
            .post('workorder/upload_workorder_sheet/', formData)
            .then(() => {
                toast.success('Workorder sheet uploaded successfully')
                onClose()
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{}}>
                    <Container
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            width: MODAL_W,
                            height: MODAL_H,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                borderBottom: `1px solid ${theme.palette.grey[800]}`,
                                height: HEADER_H,
                                padding: theme.spacing(0, 1),
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                }}
                            >
                                Upload Workorder CSV Sheet
                            </span>
                        </Container>
                        <Container style={{ padding: theme.spacing(1) }}>
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    color: theme.palette.grey[600],
                                }}
                            >
                                You will receive an email once the sheet has
                                been completed
                            </span>
                        </Container>
                        <TextField
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                                marginBottom: theme.spacing(2),
                                padding: theme.spacing(2, 1, 0, 1),
                            }}
                            variant="outlined"
                        />
                        <Selector
                            label="Project"
                            data={scheduleList}
                            searchable
                            currentValue={selectedScheduleId}
                            onChange={(e) => {
                                setSelectedScheduleId(e.target.value as number)
                            }}
                            getDisplayString={(schedule) => schedule.name}
                            customStyle={{
                                formControl: { margin: theme.spacing(1) },
                            }}
                            error={doValidate && selectedScheduleId === -1}
                        />
                        <Container style={{ padding: theme.spacing(1) }}>
                            <DropFiles
                                onDrop={handleFileChange}
                                displayString="Choose CSV File"
                                fileTypes={{
                                    'text/csv': [],
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                                    'application/vnd.ms-excel': [],
                                }}
                            />
                        </Container>
                        <Container style={{ height: 75 }}>
                            {file && (
                                <Container
                                    style={{
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(1),
                                        alignItems: 'center',
                                    }}
                                >
                                    {file.name}
                                    <IconButton
                                        onClick={() => {
                                            setFile(null)
                                        }}
                                    >
                                        <DeleteForever color="secondary" />
                                    </IconButton>
                                </Container>
                            )}
                        </Container>
                        <Container style={{ flex: 1 }} />
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                borderTop: `1px solid ${theme.palette.grey[400]}`,
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(1, 0, 1, 2),
                                    backgroundColor:
                                        theme.palette.secondary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    width: 75,
                                }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <div style={{ flex: 1 }} />

                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(1, 0, 1, 2),
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    width: 75,
                                }}
                                onClick={() => {
                                    setDoValidate(true)

                                    if (!checkValid()) {
                                        return
                                    }

                                    handleUpload()
                                }}
                            >
                                Upload
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const MODAL_H = window.innerHeight * 0.8
const MODAL_W = MODAL_H * 0.8

const HEADER_H = 50
