import React from 'react'
import { LeaseAuditController } from '../../../hooks/documentAudit/useLeaseAudit'
import { CircularProgress, Theme } from '@material-ui/core'
import { Container } from '../../../components'

interface Props {
    leaseAuditController: LeaseAuditController
    theme: Theme
    primaryText: string
    secondaryText: string
}

export const StatusPage = ({
    leaseAuditController,
    theme,
    primaryText,
    secondaryText,
}: Props) => {
    const leaseAudit = leaseAuditController.leaseAudit

    if (leaseAudit === null || leaseAudit === undefined) {
        return <CircularProgress />
    }

    return (
        <Container
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.text.primary,
                }}
            >
                {primaryText}
            </span>
            <span
                style={{
                    ...theme.typography.body1,
                    color: theme.palette.text.secondary,
                }}
            >
                {secondaryText}
            </span>
        </Container>
    )
}
