import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'

import { Modal, Paper, Slide, useTheme } from '@material-ui/core'

import { Container } from '../../../components'
import { ConfigureInspectionType } from './ConfigureInspectionType'
import {
    AreaConfig,
    Frequency,
    IdBoolMap,
    InspectionType,
    InventoryConfig,
    Schedule,
    StatusGroup,
} from '../../../models'
import { useAppDispatch } from '../../../hooks'
import {
    CreateInspectionTypeRequest,
    EditInspectionTypeRequest,
    GetScheduleListActionThunk,
    GetScheduleListRequest,
    createInspectionType,
    editInspectionTypeReq,
    getStatusGroupList,
    getStatusList,
} from '../../../store'
import { CreateInspectionTab } from './CreateInspectionTab'
import { CreateInspectionFrequencyTab } from './CreateInspectionFrequencyTab'
import { PreviewInspectionType } from './PreviewInspectionType'

interface Props {
    open: boolean
    inspectionTypeList: InspectionType[]
    scheduleList: Schedule[]
    frequencyList: Frequency[]
    inventoryConfigList: InventoryConfig[]
    areaConfigList: AreaConfig[]
    statusGroupList: StatusGroup[]
    inspectionType?: InspectionType
    setFrequencyList: (list: Frequency[]) => void
    getScheduleList: (req: GetScheduleListRequest) => GetScheduleListActionThunk
    handleClose: () => void
    setMoveOutInspection?: (val: number) => void
}

export const InspectionConfigurationModal = (props: Props) => {
    const {
        open,
        inspectionTypeList,
        scheduleList,
        frequencyList,
        inspectionType,
        inventoryConfigList,
        areaConfigList,
        statusGroupList,
        handleClose,
        getScheduleList,
        setFrequencyList,
        setMoveOutInspection,
    } = props

    const [tab, setTab] = useState(CONGIFURE_INSPECTION_TYPE_STEP)
    const [inspectionTypeName, setInspectionTypeName] = useState('')
    const [invItemsMap, setInvItemsMap] = useState<IdBoolMap>({})

    const [openCreateFrequencyForm, setOpenCreateFrequencyForm] = useState(
        false,
    )

    const [nextStep, setNextStep] = useState(NONE)

    const theme = useTheme()

    const dispatch = useAppDispatch()

    useEffect(() => {
        setOpenCreateFrequencyForm(false)
        dispatch(getStatusGroupList({}))
        dispatch(getStatusList({}))
    }, [])

    useEffect(() => {
        setTab(CONGIFURE_INSPECTION_TYPE_STEP)
        setOpenCreateFrequencyForm(false)
        if (!open) setInspectionTypeName('')

        const newCheckedMap: IdBoolMap =
            inventoryConfigList?.reduce<IdBoolMap>((prev, iC, idx) => {
                return { ...prev, [iC.id]: false }
            }, {}) ?? {}
        if (inspectionType) {
            inspectionType.inventory_configs.forEach((iC) => {
                newCheckedMap[iC.id] = true
            })
        }
        setInvItemsMap(newCheckedMap)
        setNextStep(NONE)
    }, [open])

    const saveInspectionType = () => {
        const invItems: number[] = []

        inventoryConfigList?.map((invConfig) => {
            if (invItemsMap[invConfig.id]) {
                invItems.push(invConfig.id)
            }
        })

        if (inspectionType) {
            const req: EditInspectionTypeRequest = {
                id: inspectionType.id,
                body: {
                    name: inspectionTypeName,
                    inv_configs: invItems,
                },
            }

            dispatch(editInspectionTypeReq(req)).then(() => {
                toast.success(`${inspectionTypeName} updated.`)
                if (nextStep === INSPECTION) {
                    setTab(CREATE_INSPECTION_TAB)
                } else if (nextStep === FREQUENCY) {
                    setTab(CREATE_FREQUENCY_TAB)
                } else {
                    handleClose()
                }
            })
        } else {
            const req: CreateInspectionTypeRequest = {
                body: {
                    name: inspectionTypeName,
                    inv_configs: invItems,
                },
            }

            dispatch(createInspectionType(req)).then(() => {
                toast.success(`${inspectionTypeName} created.`)
                if (nextStep === INSPECTION) {
                    setTab(CREATE_INSPECTION_TAB)
                } else if (nextStep === FREQUENCY) {
                    setTab(CREATE_FREQUENCY_TAB)
                } else {
                    handleClose()
                }
            })
        }
    }

    const SubHeaderCellStyle: React.CSSProperties = {
        fontWeight: 500,
        fontSize: '15px',
        marginBottom: theme.spacing(2),
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: openCreateFrequencyForm
                            ? '95%'
                            : 'calc(100vw - 300px)',
                        height: openCreateFrequencyForm
                            ? '85%'
                            : 'calc(100vh - 100px)',
                        maxHeight: openCreateFrequencyForm
                            ? '95%'
                            : 'calc(100vh - 100px)',
                        maxWidth: openCreateFrequencyForm
                            ? '95%'
                            : 'calc(100vw - 300px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(1, 0, 4, 0),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        {/* Step 1 */}
                        {tab === CONGIFURE_INSPECTION_TYPE_STEP && (
                            <ConfigureInspectionType
                                inspectionTypeName={inspectionTypeName}
                                invItemsMap={invItemsMap}
                                SubHeaderStyle={SubHeaderCellStyle}
                                inspectionType={inspectionType}
                                nextStep={nextStep}
                                setNextStep={setNextStep}
                                setInspectionTypeName={setInspectionTypeName}
                                handleClose={handleClose}
                                setTab={setTab}
                                setInvItemsMap={setInvItemsMap}
                                setMoveOutInspection={setMoveOutInspection}
                            />
                        )}

                        {/* Step 2 */}
                        {tab === PREVIEW_INSPECTION_TYPE_STEP && (
                            <Slide
                                direction="left"
                                in={tab === PREVIEW_INSPECTION_TYPE_STEP}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <PreviewInspectionType
                                        invItemsMap={invItemsMap}
                                        inspectionType={inspectionType}
                                        inventoryConfigList={
                                            inventoryConfigList ?? []
                                        }
                                        areaConfigList={areaConfigList}
                                        statusGroupList={statusGroupList}
                                        setInvItemsMap={setInvItemsMap}
                                        setTab={setTab}
                                        handleClose={handleClose}
                                        saveInspectionType={saveInspectionType}
                                    />
                                </Paper>
                            </Slide>
                        )}

                        {tab === CREATE_INSPECTION_TAB && (
                            <Slide
                                direction="left"
                                in={tab === CREATE_INSPECTION_TAB}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <CreateInspectionTab
                                        open={true}
                                        scheduleList={scheduleList}
                                        inspectionTypeList={inspectionTypeList}
                                        getScheduleList={getScheduleList}
                                        handleClose={handleClose}
                                    />
                                </Paper>
                            </Slide>
                        )}

                        {tab === CREATE_FREQUENCY_TAB && (
                            <Slide
                                direction="left"
                                in={tab === CREATE_FREQUENCY_TAB}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <CreateInspectionFrequencyTab
                                        openCreateFrequencyForm={
                                            openCreateFrequencyForm
                                        }
                                        setOpenCreateFrequencyForm={
                                            setOpenCreateFrequencyForm
                                        }
                                        handleClose={handleClose}
                                        frequencyList={frequencyList}
                                        setFrequencyList={setFrequencyList}
                                    />
                                </Paper>
                            </Slide>
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

export const CONGIFURE_INSPECTION_TYPE_STEP = 0
export const PREVIEW_INSPECTION_TYPE_STEP = 1
export const CREATE_INSPECTION_TAB = 2
export const CREATE_FREQUENCY_TAB = 3

export const INSPECTION = 1
export const FREQUENCY = 2
export const NONE = 3
