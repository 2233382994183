import React from 'react'

import { Avatar, makeStyles, Paper, Theme, Typography } from '@material-ui/core'

import { User } from '../../../models'
import { getInitials } from '../../../helpers'
import { Container } from '../../../components'

interface Props {
    user: User
    theme: Theme
}

const useStyles = makeStyles((theme: Theme) => ({
    headerWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
        minWidth: 310,
        maxWidth: 310,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        borderBottom: 'none',
        minHeight: 80,
        maxHeight: 90,
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
    },
    name: {
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export const UserHeaderCell = (props: Props) => {
    const { user, theme } = props

    const classes = useStyles(theme)

    return (
        <div className={classes.headerWrapper}>
            <Paper className={classes.header} elevation={3}>
                <Avatar
                    src={user?.profile_picture ?? undefined}
                    alt={user.name}
                    className={classes.avatar}
                >
                    {getInitials(user.name)}
                </Avatar>
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Typography
                        variant="subtitle1"
                        className={classes.name}
                        style={{ flex: 1 }}
                    >
                        {user.name}
                    </Typography>
                </Container>
            </Paper>
        </div>
    )
}
