import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import { useLocation } from 'react-router-dom'
import TopNavBar from './top-bar'
import SideBar from './sidebar'
import { AdminPanel } from '../../../components/AdminPanel/AdminPanel'
import { useUser } from '../../../hooks'
import { isAccountManager } from '../../../helpers/stringHelpers'

interface AuthLayoutProps {
    children: React.ReactNode
}
const DRAWER_WIDTH = 136

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    const location = useLocation()

    const { rootUser } = useUser()

    const isRegistrationPage =
        location.pathname === '/vendor-register' ||
        location.pathname === '/skillcat-register'

    const isFullWidthPage =
        location.pathname === '/ez-chat-final' || isRegistrationPage

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                width: '100%',
            },
            drawer: {
                width: DRAWER_WIDTH,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                backgroundColor: '#2f2e41',
                color: '#737378',
                zIndex: 9999,
            },
            drawerOpen: {
                width: DRAWER_WIDTH,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: `${DRAWER_WIDTH} !important`,
                [theme.breakpoints.up('sm')]: {
                    width: `${DRAWER_WIDTH} !important`,
                },
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                minHeight: '56px !important',
                ...theme.mixins.toolbar,
            },
            content: {
                flexGrow: 1,
                width: `calc(100vw - ${DRAWER_WIDTH}px)`,
            },
        }),
    )
    const classes = useStyles()

    const [adminDrawerOpen, setAdminDrawerOpen] = useState(false)

    return (
        <div className={classes.root}>
            {!isRegistrationPage && (
                <>
                    <TopNavBar open drawerWidth={DRAWER_WIDTH} />
                    <SideBar open drawerWidth={DRAWER_WIDTH} />
                </>
            )}

            <main
                className={classes.content}
                style={{
                    padding: isFullWidthPage ? 0 : '24px',
                }}
            >
                <div
                    className={classes.toolbar}
                    style={{
                        minHeight: isRegistrationPage ? 0 : '56px',
                    }}
                />
                {children}
            </main>

            {isAccountManager(rootUser) && (
                <div>
                    <div
                        style={{
                            height: 32,
                            width: 32,
                            position: 'fixed',
                            bottom: 2,
                            right: 2,
                            backgroundColor: '#2F2E41',
                            borderRadius: 32,
                            zIndex: 1000000000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => setAdminDrawerOpen((open) => !open)}
                    >
                        <MenuOpenIcon htmlColor="#fff" />
                    </div>

                    <AdminPanel
                        open={adminDrawerOpen}
                        onClose={() => setAdminDrawerOpen(false)}
                    />
                </div>
            )}
        </div>
    )
}
