import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useAppDispatch } from '../../../hooks'
import { IdentifiableNamedObject, User } from '../../../models'
import { setOrganization } from '../../../store'

type Organization = IdentifiableNamedObject & {
    propertyCount: number
}

interface OrganizationSelectionProps {
    user: User
    selectedOrganization: number | null
}

const OrganizationSelection: React.FC<OrganizationSelectionProps> = ({
    user,
    selectedOrganization,
}) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            workspaceDropdown: {
                width: 252,
                color: '#FFF',
                borderRight: '2px solid #FFF',
                height: '56px',
                '& .MuiFormControl-root, .MuiInputBase-root': {
                    height: '100%',
                    color: '#FFF',
                },
                '& .MuiInputBase-root': {
                    paddingLeft: '12px',
                },
                '& .MuiButtonBase-root': {
                    color: '#FFF',
                },
                '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                },
            },
        }),
    )
    const classes = useStyles()
    const dispatch = useAppDispatch()

    const organizationList: Organization[] = []
    user.workspaces.forEach((workspace) => {
        const organizationId = workspace.active_workspace.organization
        const organizationName = workspace.active_workspace.organization_name

        const organization = organizationList.find(
            (org) => org.id === organizationId,
        )

        if (organization) {
            organization.propertyCount += 1
        } else {
            organizationList.push({
                id: organizationId,
                name: organizationName,
                propertyCount: 1,
            })
        }
    })

    return (
        <>
            <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={organizationList}
                getOptionLabel={(option) => option.name}
                className={classes.workspaceDropdown}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ color: '#FFF' }}
                        placeholder="Search"
                    />
                )}
                value={organizationList.find(
                    (org) => org.id === selectedOrganization,
                )}
                onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
                    dispatch(setOrganization(newValue.id))
                }}
                style={{ zIndex: Number.MAX_SAFE_INTEGER }}
            />
        </>
    )
}

export default OrganizationSelection
