import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
    Button,
    useTheme,
    CircularProgress,
    Switch,
    FormControlLabel,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import {
    Container,
    SideDrawer,
    SideDrawerContainer,
    WorkorderAutomationForm,
    WorkorderAutomationRow,
} from '../../components'

import {
    AreaConfig,
    Folder,
    ListVendor,
    ModelMap,
    Service,
    User,
    WorkorderAutomationType,
    WorkorderFrequencyAutomation,
} from '../../models'

import { usePagination } from '../../hooks'
import { workorderContext } from '../../contexts'

import {
    DRAWER_W,
    VH,
    TOP_TOOL_BAR_H,
    AI_LIST_HEADER_H,
    AI_LIST_FOOTER_H,
    AI_HOME_HEADER,
    JANK_SIZE,
} from './contants'
import { AiDrawerId, AiFormState, closedAiFormState } from './types'
import { UpdateApartmentRequest } from '../../store'

interface Props {
    frequencyList: WorkorderFrequencyAutomation[]
    serviceList: Service[]
    userList: User[]
    vendorList: ListVendor[]
    root: Folder
    areaConfigMap: ModelMap<AreaConfig>
    propertyAutomationMode?: boolean
    workspaceUser?: User
    onUpdateApartment?: (req: UpdateApartmentRequest) => void
}

export const FrequencyList = (props: Props) => {
    const {
        frequencyList,
        serviceList,
        userList,
        vendorList,
        root,
        propertyAutomationMode,
        workspaceUser,
        onUpdateApartment,
    } = props

    const {
        loadingState,
        createFrequency,
        updateFrequency,
        toggleFrequencyActive,
    } = workorderContext()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        frequencyList,
    )

    const [formState, setFormState] = useState<AiFormState>(closedAiFormState)

    const [runFrequencies, setRunFrequencies] = useState(
        workspaceUser?.active_workspace?.run_action_item_frequencies ?? false,
    )

    const theme = useTheme()

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        flex: 1,
    }

    const openCreateForm = () => {
        setFormState({
            ...closedAiFormState,
            createFormOpen: true,
        })
    }

    const selectedFrequency = pageData[formState.detailFormOpen.index]

    const freqDetailDrawerOpen =
        selectedFrequency?.id === formState.detailFormOpen.id &&
        formState.detailFormOpen.drawerId === AiDrawerId.frequncy

    const drawerContainerOpen = formState.createFormOpen || freqDetailDrawerOpen

    let headerHeight = AI_LIST_HEADER_H
    if (propertyAutomationMode) {
        headerHeight = '125px'
    }

    const activeWorkspace = workspaceUser?.active_workspace

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {/* Header */}

                <Container
                    style={{
                        flexDirection: 'column',
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: headerHeight,
                        maxHeight: headerHeight,
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    <Container
                        style={{
                            height: 60,
                            alignItems: 'center',
                        }}
                    >
                        <span>Action Item Frequencies</span>
                        {activeWorkspace && onUpdateApartment && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={runFrequencies}
                                        onChange={() => {
                                            setRunFrequencies(!runFrequencies)
                                            onUpdateApartment({
                                                params: {
                                                    id: activeWorkspace?.id,
                                                },
                                                body: {
                                                    run_action_item_frequencies: !runFrequencies,
                                                },
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Automate Action Item Frequencies"
                                style={{ marginLeft: theme.spacing(1) }}
                            />
                        )}
                        <div style={{ flex: 1 }} />
                        <Button
                            variant="contained"
                            style={{
                                marginLeft: theme.spacing(2),
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={openCreateForm}
                        >
                            + Create Frequency
                        </Button>
                    </Container>

                    <div style={{ flex: 1 }} />
                    <Container>
                        <Container style={{ ...headerStyle }}>Title</Container>
                        <Container style={{ ...headerStyle }}>
                            Assigned To
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Service
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Priority
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Location
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Initial Date
                        </Container>

                        <Container style={{ ...headerStyle }}>
                            Frequency
                        </Container>

                        <Container style={{ ...headerStyle }}>Active</Container>
                    </Container>
                </Container>

                {/* Body */}

                <Container
                    direction="column"
                    scrollY
                    flex={1}
                    style={{
                        minHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${headerHeight} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                        maxHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${headerHeight} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                    }}
                >
                    {loadingState.getFrequencyList && (
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={100} />
                        </Container>
                    )}

                    {pageData.map((frequency, idx) => {
                        return (
                            <WorkorderAutomationRow
                                key={`FREQUENCY-${frequency.id}`}
                                theme={theme}
                                automationConfig={frequency}
                                isSelected={
                                    selectedFrequency?.id === frequency.id
                                }
                                onClick={() =>
                                    setFormState({
                                        ...closedAiFormState,
                                        detailFormOpen: {
                                            index: idx,
                                            id: frequency.id,
                                            drawerId: AiDrawerId.frequncy,
                                        },
                                    })
                                }
                                toggleFrequencyActive={toggleFrequencyActive}
                            />
                        )
                    })}
                </Container>

                {/* Footer */}
                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_FOOTER_H,
                        maxHeight: AI_LIST_FOOTER_H,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                    />
                    <span>
                        {start} - {end} of {frequencyList.length}
                    </span>
                </Container>
            </Container>

            <SideDrawer
                open={formState.createFormOpen}
                handleClose={() => setFormState(closedAiFormState)}
                width={DRAWER_W}
            >
                <WorkorderAutomationForm
                    inventoryConfigList={[]}
                    customStatusList={[]}
                    automationType={WorkorderAutomationType.FREQUENCY}
                    theme={theme}
                    handleCancel={() => console.log()}
                    handleSave={(request) =>
                        createFrequency(request)
                            .catch((e) => {
                                toast.error(`Problem creating frequency${e}`)
                            })
                            .finally(() => {
                                setFormState(closedAiFormState)
                            })
                    }
                    services={serviceList}
                    vendors={vendorList}
                    users={userList}
                    root={root}
                    areaConfigMap={props.areaConfigMap}
                />
            </SideDrawer>

            <SideDrawer
                open={freqDetailDrawerOpen}
                handleClose={() => setFormState(closedAiFormState)}
                width={DRAWER_W}
            >
                <WorkorderAutomationForm
                    inventoryConfigList={[]}
                    customStatusList={[]}
                    automationType={WorkorderAutomationType.FREQUENCY}
                    theme={theme}
                    handleCancel={() => setFormState(closedAiFormState)}
                    handleSave={(request) => {
                        updateFrequency(request, selectedFrequency?.id ?? -1)
                            .catch((e) => {
                                toast.error(`Problem updating frequency${e}`)
                            })
                            .finally(() => {
                                setFormState(closedAiFormState)
                            })
                    }}
                    services={serviceList}
                    vendors={vendorList}
                    users={userList}
                    root={root}
                    areaConfigMap={props.areaConfigMap}
                    automation={selectedFrequency}
                />
            </SideDrawer>
        </SideDrawerContainer>
    )
}
