import React, { useEffect, useState } from 'react'
import {
    ApartmentQuestionType,
    BidLineItem,
    BidStatus,
    Bid,
    RFP,
    RFPService,
    VendorAnswer,
    convertListToMap,
} from '../../models'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Theme,
    useTheme,
} from '@material-ui/core'
import { Container, NumberInput, Selector } from '../../components'
import { DeleteForever } from '@material-ui/icons'
import {
    CreateOrUpdateRFPRequest,
    createOrUpdateBidLineItemRequest,
    updateOrCreateBidRequest,
} from '../../contexts'
import { RFP_DETAIL_TAB } from './VendorViewRFPDetail'
import { RFPStatusBadge } from './RFPStatusBadge'
import { toast } from 'react-toastify'

interface Props {
    bid?: Bid | null
    rfp: RFP
    apartmentView?: boolean
    handleClose: () => void
    setTab: (tab: number) => void
    updateBid?: (
        bid: number,
        request: updateOrCreateBidRequest,
        rfp: RFP,
    ) => void
    createBid?: (request: updateOrCreateBidRequest, rfp: RFP) => void
    updateRFP?: (request: CreateOrUpdateRFPRequest) => Promise<RFP>
}

export const BidForm = (props: Props) => {
    const {
        bid,
        rfp,
        updateBid,
        handleClose,
        setTab,
        createBid,
        apartmentView,
        updateRFP,
    } = props

    const theme = useTheme()

    const [vendorAnswerList, setVendorAnswerList] = useState<VendorAnswer[]>(
        bid?.vendor_answers ?? [],
    )

    const [customBidLineItems, setCustomBidLineItems] = useState<BidLineItem[]>(
        bid
            ? bid.bid_line_items.filter(
                  (lineItem) => lineItem.apartment_question === null,
              )
            : [],
    )
    const [
        apartmentQuestionBidLineItems,
        setApartmentQuestionBidLineItems,
    ] = useState<BidLineItem[]>(
        bid
            ? bid.bid_line_items.filter(
                  (lineItem) => lineItem.apartment_question,
              )
            : [],
    )

    const [totalQuestionPrice, setTotalQuestionPrice] = useState<number>(0)
    const [totalExtrasPrice, setTotalExtrasPrice] = useState<number>(0)

    const [doValidate, setDoValidate] = useState(false)
    const [openDeclineBidDialog, setOpenDeclineBidDialog] = useState(false)
    const [openAcceptBidDialog, setOpenAcceptBidDialog] = useState(false)

    useEffect(() => {
        let tempTotalQuestionPrice = 0
        let tempTotalExtrasPrice = 0
        customBidLineItems.forEach(
            (bidLineItem) =>
                (tempTotalExtrasPrice =
                    tempTotalExtrasPrice + Number(bidLineItem.price)),
        )
        apartmentQuestionBidLineItems.forEach(
            (bidLineItem) =>
                (tempTotalQuestionPrice =
                    tempTotalQuestionPrice + Number(bidLineItem.price)),
        )
        setTotalQuestionPrice(tempTotalQuestionPrice)
        setTotalExtrasPrice(tempTotalExtrasPrice)
    }, [customBidLineItems, apartmentQuestionBidLineItems])

    useEffect(() => {
        if (rfp) {
            const tempVendorAnswerList: VendorAnswer[] = vendorAnswerList
            const tempApartmentQuestionBidLineItems: BidLineItem[] = apartmentQuestionBidLineItems
            rfp.apartment_questions.forEach((apartmentQuestion) => {
                if (apartmentQuestion.type === ApartmentQuestionType.GENERAL) {
                    const vendorAnswer = bid?.vendor_answers.find(
                        (answer) =>
                            answer.apartment_question === apartmentQuestion.id,
                    )
                    if (!vendorAnswer) {
                        tempVendorAnswerList.push({
                            id: -1,
                            answer_description: '',
                            apartment_question: apartmentQuestion.id,
                        })
                    }
                } else if (
                    apartmentQuestion.type === ApartmentQuestionType.FINANCIAL
                ) {
                    const bidLineItem = apartmentQuestionBidLineItems.find(
                        (lineItem) =>
                            lineItem.apartment_question ===
                            apartmentQuestion.id,
                    )
                    if (!bidLineItem) {
                        tempApartmentQuestionBidLineItems.push({
                            id: -1,
                            apartment_question: apartmentQuestion.id,
                            price: 0,
                            rfp_service: null,
                            description: '',
                        })
                    }
                }
            })
            setApartmentQuestionBidLineItems(tempApartmentQuestionBidLineItems)
            setDoValidate(false)
            setVendorAnswerList(tempVendorAnswerList)
            setCustomBidLineItems(
                bid
                    ? bid.bid_line_items.filter(
                          (lineItem) => lineItem.apartment_question === null,
                      )
                    : [],
            )
        } else {
            setApartmentQuestionBidLineItems([])
            setDoValidate(false)
            setVendorAnswerList([])
            setCustomBidLineItems([])
        }
    }, [bid, rfp])

    const checkValidation = () => {
        let allAnswersProvided = true
        vendorAnswerList.map((vendorAnswer) => {
            if (vendorAnswer.answer_description === '') {
                allAnswersProvided = false
            }
        })

        let allBidLineItemsFilled = true
        customBidLineItems.map((bidLineItem) => {
            if (bidLineItem.description === '') {
                allBidLineItemsFilled = false
            }
        })

        setDoValidate(!allAnswersProvided || !allBidLineItemsFilled)
        return allAnswersProvided && allBidLineItemsFilled
    }

    const createBidRequestBody = (submit?: boolean) => {
        const body: updateOrCreateBidRequest = {}
        if (submit) {
            body.status = BidStatus.SUBMITTED
        }
        const allBidLineItems = customBidLineItems.concat(
            apartmentQuestionBidLineItems,
        )
        if (allBidLineItems.length > 0) {
            const lineItems: createOrUpdateBidLineItemRequest[] = []
            allBidLineItems.forEach((bidLineItem) => {
                const lineItem: createOrUpdateBidLineItemRequest = {
                    id: bidLineItem.id,
                    price: Number(bidLineItem.price),
                    description: bidLineItem.description,
                }
                if (bidLineItem.rfp_service) {
                    lineItem.rfp_service = bidLineItem.rfp_service
                }
                if (bidLineItem.apartment_question) {
                    lineItem.apartment_question = bidLineItem.apartment_question
                }
                lineItems.push(lineItem)
            })
            body.bid_line_items = lineItems
        }
        if (vendorAnswerList.length > 0) {
            body.vendor_answers = vendorAnswerList
        }
        return body
    }

    let viewMode = false
    if ((bid && bid.status !== BidStatus.INVITATION) || apartmentView) {
        viewMode = true
    }

    const showBidButton =
        (!bid || bid.status === BidStatus.INVITATION) && !viewMode

    const apartmentQuestionMap = convertListToMap(rfp.apartment_questions)

    return (
        <Container direction="column" flex={1}>
            <Container
                direction="column"
                style={{
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto',
                    width: '100%',
                }}
                flex={1}
            >
                <Container style={{ margin: theme.spacing(1, 0, 1, 0) }}>
                    {apartmentView ? (
                        <Container
                            style={{
                                ...theme.typography.h5,
                                fontWeight: 600,
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            Bid Details - {bid?.vendor.name}
                        </Container>
                    ) : (
                        <Container
                            style={{
                                ...theme.typography.h5,
                                fontWeight: 600,
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            Bid Details
                        </Container>
                    )}
                    <Container flex={1} />
                    {bid && (
                        <RFPStatusBadge
                            theme={theme}
                            status={bid.status}
                            style={{
                                container: {
                                    maxWidth: '100px',
                                },
                            }}
                        />
                    )}
                </Container>
                <Container direction="column">
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 600,
                        }}
                    >
                        General Questions:
                    </Container>
                    <Container direction="column">
                        {vendorAnswerList.map((vendorAnswer, idx) => {
                            return (
                                <Container
                                    key={`VENDOR_ANSWER_APARTMENT_GENERAL_QUESTION_${idx}`}
                                    style={{
                                        padding: theme.spacing(1, 1, 0, 0),
                                        width: '100%',
                                    }}
                                    direction="column"
                                >
                                    <Container
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight: 500,
                                        }}
                                    >
                                        {
                                            apartmentQuestionMap[
                                                vendorAnswer.apartment_question ??
                                                    -1
                                            ]?.question_description
                                        }
                                    </Container>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={vendorAnswer.answer_description}
                                        onChange={(e) => {
                                            const tempVendorAnswerList = vendorAnswerList.map(
                                                (va, index) => {
                                                    if (index === idx) {
                                                        return {
                                                            id: vendorAnswer.id,
                                                            apartment_question:
                                                                vendorAnswer.apartment_question,
                                                            answer_description:
                                                                e.target.value,
                                                        }
                                                    }
                                                    return va
                                                },
                                            )
                                            setVendorAnswerList(
                                                tempVendorAnswerList,
                                            )
                                        }}
                                        error={
                                            doValidate &&
                                            vendorAnswer.answer_description ===
                                                ''
                                        }
                                        helperText={
                                            doValidate &&
                                            vendorAnswer.answer_description ===
                                                '' &&
                                            'Required'
                                        }
                                        disabled={viewMode}
                                    />
                                </Container>
                            )
                        })}
                    </Container>
                </Container>
                <Container
                    direction="column"
                    style={{ marginTop: theme.spacing(2) }}
                >
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 600,
                        }}
                    >
                        Financial Questions:
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: 600,
                        }}
                    >
                        Total - ${totalQuestionPrice}
                    </Container>
                    <Container direction="column">
                        {apartmentQuestionBidLineItems.map(
                            (bidLineItem, idx) => {
                                return (
                                    <Container
                                        key={`VENDOR_ANSWER_APARTMENT_FINANCIAL_QUESTION_${idx}`}
                                        style={{
                                            padding: theme.spacing(1, 1, 0, 0),
                                            width: '100%',
                                        }}
                                        direction="column"
                                    >
                                        <Container
                                            style={{
                                                ...theme.typography.subtitle1,
                                                fontWeight: 500,
                                            }}
                                        >
                                            {
                                                apartmentQuestionMap[
                                                    bidLineItem.apartment_question ??
                                                        -1
                                                ]?.question_description
                                            }
                                        </Container>
                                        <NumberInput
                                            prefix={'$'}
                                            variant="outlined"
                                            value={bidLineItem.price}
                                            onChange={(e) => {
                                                const tempApartmentQuestionBidLineItems = apartmentQuestionBidLineItems.map(
                                                    (lineItem, index) => {
                                                        if (index === idx) {
                                                            return {
                                                                ...lineItem,
                                                                price: Number(
                                                                    e.target
                                                                        .value,
                                                                ),
                                                            }
                                                        }
                                                        return lineItem
                                                    },
                                                )
                                                setApartmentQuestionBidLineItems(
                                                    tempApartmentQuestionBidLineItems,
                                                )
                                            }}
                                            size="small"
                                            disabled={viewMode}
                                        />
                                    </Container>
                                )
                            },
                        )}
                    </Container>
                </Container>
                <Container direction="column">
                    <Container
                        justifyContent="center"
                        style={{ margin: theme.spacing(2, 0, 1, 0) }}
                        direction="column"
                    >
                        <Container>
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: 600,
                                }}
                            >
                                Bid Line Items:
                            </Container>
                            {!viewMode && (
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: 'white',
                                        color: theme.palette.primary.dark,
                                        borderColor: theme.palette.primary.dark,
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                        width: '180px',
                                        marginLeft: theme.spacing(2),
                                    }}
                                    onClick={() => {
                                        setCustomBidLineItems([
                                            ...customBidLineItems,
                                            {
                                                id: -1,
                                                rfp_service: null,
                                                description: '',
                                                price: 0,
                                                apartment_question: null,
                                            },
                                        ])
                                    }}
                                >
                                    + Add Bid Line Item
                                </Button>
                            )}
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: 600,
                            }}
                        >
                            Total - ${totalExtrasPrice}
                        </Container>
                    </Container>
                    {!apartmentView && (
                        <Container
                            style={{
                                ...theme.typography.body2,
                                fontWeight: 400,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Add bid line items for more pricing information!
                        </Container>
                    )}
                    {apartmentView && customBidLineItems.length === 0 && (
                        <Container
                            style={{
                                ...theme.typography.body2,
                                fontWeight: 400,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            No bid line items were added!
                        </Container>
                    )}
                    <Container direction="column">
                        {customBidLineItems.map((bidLineItem, idx) => {
                            return (
                                <BidLineItemRow
                                    key={`BID_LINE_ITEM_INDEX_${idx}`}
                                    bidLineItem={bidLineItem}
                                    idx={idx}
                                    bidLineItems={customBidLineItems}
                                    theme={theme}
                                    viewMode={viewMode}
                                    doValidate={doValidate}
                                    rfpServices={rfp.rfp_services}
                                    setBidLineItems={setCustomBidLineItems}
                                />
                            )
                        })}
                    </Container>
                </Container>
            </Container>
            <Container alignItems="center">
                <Container flex={1} />
                {apartmentView ? (
                    <Container>
                        {bid?.status === BidStatus.SUBMITTED ||
                        bid?.status === BidStatus.REVIEWED ? (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2),
                                    backgroundColor: 'red',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setOpenDeclineBidDialog(true)
                                }}
                            >
                                Deny Bid
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2),
                                    backgroundColor: 'red',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        )}
                        {(bid?.status === BidStatus.SUBMITTED ||
                            bid?.status === BidStatus.REVIEWED) && (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2, 0, 2, 0),
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setOpenAcceptBidDialog(true)
                                }}
                            >
                                Accept Bid
                            </Button>
                        )}
                    </Container>
                ) : (
                    <Container>
                        {bid?.status === BidStatus.INVITATION && (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: 'white',
                                    color: theme.palette.primary.dark,
                                    borderColor: theme.palette.primary.dark,
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    margin: theme.spacing(2, 0, 2, 0),
                                }}
                                onClick={() => {
                                    if (bid && updateBid) {
                                        const body = createBidRequestBody()
                                        updateBid(bid.id, body, bid.rfp)
                                    }
                                }}
                            >
                                Save Progress
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2),
                                backgroundColor: 'red',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setTab(RFP_DETAIL_TAB)
                            }}
                        >
                            Cancel
                        </Button>
                        {showBidButton && (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2, 0, 2, 0),
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (checkValidation()) {
                                        const submit = true
                                        const body = createBidRequestBody(
                                            submit,
                                        )
                                        if (bid && updateBid) {
                                            updateBid(bid.id, body, bid.rfp)
                                        } else if (createBid) {
                                            body.rfp = rfp.id
                                            createBid(body, rfp)
                                        }
                                        handleClose()
                                    }
                                }}
                            >
                                Submit
                            </Button>
                        )}
                    </Container>
                )}
            </Container>
            <Dialog
                open={openDeclineBidDialog}
                onClose={() => setOpenDeclineBidDialog(false)}
            >
                <DialogTitle>Decline Bid</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to decline this bid from{' '}
                        {bid?.vendor.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDeclineBidDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            if (bid && updateBid) {
                                const body: updateOrCreateBidRequest = {
                                    status: BidStatus.DECLINED,
                                }
                                updateBid(bid.id, body, rfp)
                                handleClose()
                            }
                        }}
                    >
                        Decline
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAcceptBidDialog}
                onClose={() => setOpenAcceptBidDialog(false)}
            >
                <DialogTitle>Accept Bid</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to accept this bid from{' '}
                        {bid?.vendor.name}. All other bids will be declined.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenAcceptBidDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            if (updateRFP && bid) {
                                const body: CreateOrUpdateRFPRequest = {
                                    accepted_bid: bid.id,
                                }
                                updateRFP(body).then((updateRFP) => {
                                    toast.success(`Bid accepted successfully!`)
                                })
                                handleClose()
                            }
                        }}
                    >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface BidLineItemRowProps {
    bidLineItem: BidLineItem
    idx: number
    bidLineItems: BidLineItem[]
    theme: Theme
    viewMode: boolean
    doValidate: boolean
    rfpServices: RFPService[]
    setBidLineItems: (bidLineItems: BidLineItem[]) => void
}

const BidLineItemRow = (props: BidLineItemRowProps) => {
    const {
        bidLineItem,
        idx,
        bidLineItems,
        theme,
        viewMode,
        doValidate,
        rfpServices,
        setBidLineItems,
    } = props

    const selectedRFPServiceId = bidLineItem.rfp_service

    return (
        <Container
            style={{
                padding: theme.spacing(1, 1, 1, 0),
            }}
        >
            <TextField
                variant="outlined"
                size="small"
                label="Description"
                value={bidLineItem.description}
                onChange={(e) => {
                    const tempBidLineItemList = bidLineItems.map(
                        (lineItem, index) => {
                            if (index === idx) {
                                return {
                                    ...lineItem,
                                    description: e.target.value,
                                }
                            }
                            return lineItem
                        },
                    )
                    setBidLineItems(tempBidLineItemList)
                }}
                style={{
                    width: '80%',
                    marginRight: theme.spacing(1),
                }}
                error={doValidate && bidLineItem.description === ''}
                helperText={
                    doValidate && bidLineItem.description === '' && 'Required'
                }
                disabled={viewMode}
            />
            <NumberInput
                prefix={'$'}
                label="Price"
                variant="outlined"
                value={bidLineItem.price}
                onChange={(e) => {
                    const tempBidLineItemList = bidLineItems.map(
                        (lineItem, index) => {
                            if (index === idx) {
                                return {
                                    ...lineItem,
                                    price: Number(e.target.value),
                                }
                            }
                            return lineItem
                        },
                    )
                    setBidLineItems(tempBidLineItemList)
                }}
                size="small"
                disabled={viewMode}
            />
            <Selector
                label={'Service'}
                currentValue={selectedRFPServiceId ?? -1}
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    const tempBidLineItemList = bidLineItems.map(
                        (lineItem, index) => {
                            if (index === idx) {
                                return {
                                    ...lineItem,
                                    rfp_service: Number(event.target.value),
                                }
                            }
                            return lineItem
                        },
                    )
                    setBidLineItems(tempBidLineItemList)
                }}
                data={rfpServices}
                getDisplayString={(s: RFPService) => s.service.name}
                size="small"
                customStyle={{
                    formControl: {
                        marginLeft: theme.spacing(1),
                        width: '250px',
                    },
                }}
                disabled={viewMode}
            />
            {!viewMode && (
                <IconButton
                    onClick={() => {
                        setBidLineItems(
                            bidLineItems.reduce<BidLineItem[]>(
                                (prev, bidLineItem, index) => {
                                    if (index === idx) {
                                        return prev
                                    }
                                    return prev.concat(bidLineItem)
                                },
                                [],
                            ),
                        )
                    }}
                    style={{
                        margin: theme.spacing(0, 0, 2, 1),
                    }}
                    size="small"
                >
                    <DeleteForever color="secondary" />
                </IconButton>
            )}
        </Container>
    )
}
