import React, { Dispatch, SetStateAction, useState } from 'react'

import { makeStyles, Tab, Tabs, useTheme } from '@material-ui/core'

import {
    BaseWorkorder,
    ListVendor,
    Service,
    ServiceRequest,
    WorkorderStatus,
    WorkSpaceUser,
} from '../../../models'
import { Container } from '../../../components'
import {
    CreateServiceRequestWorkorderRequest,
    ServiceRequestFilterState,
} from '../../../hooks/useServiceRequest'
import {
    BulkUpdateWorkOrderRequest,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'
import { PendingServiceRequestView } from '../PendingWork/PendingServiceRequestView'
import { UnassignedWorkordersView } from '../PendingWork/UnassignedWorkordersView'

interface Props {
    serviceRequestList: ServiceRequest[]
    vendorList: ListVendor[]
    serviceList: Service[]
    serviceRequestFilterState: ServiceRequestFilterState
    workorderList: WorkorderResponse[]
    workspaceUser?: WorkSpaceUser
    createServiceRequestWorkorder: (
        request: CreateServiceRequestWorkorderRequest,
    ) => Promise<WorkorderResponse | null>
    updateWorkorderState: (workorder: WorkorderResponse) => void
    setServiceRequestFilterState: Dispatch<
        SetStateAction<ServiceRequestFilterState>
    >
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    bulkUpdateWorkorders: (request: BulkUpdateWorkOrderRequest) => Promise<void>
    openEditModal: (workorder: WorkorderResponse) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)',
        overflow: 'hidden',
        width: 'calc(100vw - 200px)',
        marginBottom: theme.spacing(1),
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column',
    },
    indicator: {
        backgroundColor: theme.palette.primary.dark,
    },
    tabStyle: {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    },
}))

export const PendingWorkView = (props: Props) => {
    const {
        serviceRequestList,
        serviceList,
        vendorList,
        serviceRequestFilterState,
        workorderList,
        workspaceUser,
        transitionWorkorder,
        createServiceRequestWorkorder,
        updateWorkorderState,
        setServiceRequestFilterState,
        bulkUpdateWorkorders,
        openEditModal,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const [selectedTab, setSelectedTab] = useState(
        workspaceUser?.active_workspace.allow_service_request
            ? PENDING_SERVICE_REQUEST
            : UNASSIGNED_WORKORDERS,
    )

    return (
        <Container className={classes.root}>
            <div className={classes.header}>
                <Tabs
                    style={{
                        color: 'black',
                        borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                        flex: 1,
                        textTransform: 'none',
                    }}
                    value={selectedTab}
                    onChange={(_, v) => {
                        if (v !== selectedTab) {
                            setSelectedTab(v)
                        }
                    }}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    {workspaceUser?.active_workspace.allow_service_request && (
                        <Tab
                            label={`Pending Service Requests (${serviceRequestList.length})`}
                            className={classes.tabStyle}
                        />
                    )}
                    <Tab
                        label={`Unassigned Workorders (${workorderList.length})`}
                        className={classes.tabStyle}
                    />
                </Tabs>
            </div>

            {selectedTab === PENDING_SERVICE_REQUEST && (
                <PendingServiceRequestView
                    serviceRequestList={serviceRequestList}
                    vendorList={vendorList}
                    serviceList={serviceList}
                    serviceRequestFilterState={serviceRequestFilterState}
                    updateWorkorderState={updateWorkorderState}
                    createServiceRequestWorkorder={
                        createServiceRequestWorkorder
                    }
                    setServiceRequestFilterState={setServiceRequestFilterState}
                />
            )}

            {selectedTab === UNASSIGNED_WORKORDERS && (
                <UnassignedWorkordersView
                    workorderList={workorderList}
                    vendorList={vendorList}
                    serviceList={serviceList}
                    workspaceUser={workspaceUser}
                    transitionWorkorder={transitionWorkorder}
                    updateWorkorderState={updateWorkorderState}
                    bulkUpdateWorkorders={bulkUpdateWorkorders}
                    openEditModal={openEditModal}
                />
            )}
        </Container>
    )
}

const PENDING_SERVICE_REQUEST = 0
const UNASSIGNED_WORKORDERS = 1
