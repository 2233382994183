import React, { useState } from 'react'
import { Button, FormControl, TextField } from '@material-ui/core'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import BackgroundImage from '../../../../assets/login_background.png'
import { Routes, axiosInstance, passwordValidator } from '../../../../helpers'
import { Container, PasswordRequirements } from '../../../../components'
import { toast } from 'react-toastify'

const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContainer: {
            height: '100vh',
            width: '100vw',
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        formContainer: {
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            borderRadius: 20,
            width: '500px',
        },
        passwordField: {
            margin: theme.spacing(1),
            width: '100%',
        },
        submitButton: {
            width: '100%',
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
            textTransform: 'inherit',
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: theme.shape.borderRadius,
            boxShadow: 'none',
            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
            border: `2px solid ${theme.palette.primary.dark}`,
            marginTop: theme.spacing(2),
            '&:disabled': {
                backgroundColor: 'grey',
            },
            '&:hover': {
                backgroundColor: 'white',
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
            },
        },
    }),
)

export const ResetPasswordForm = () => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const query = useQuery()

    const serverCode = query.get('code') || ''
    const serverEmail = query.get('email')

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const passwordValidResult = passwordValidator(newPassword, confirmPassword)

    const handleSubmit = async () => {
        try {
            await axiosInstance.post('user/forgot_password/', {
                set_code: false,
                code: serverCode,
                email: serverEmail,
                new_password: newPassword,
            })
            history.push(Routes.login)
            toast.success('Password Updated', {
                autoClose: 5000,
            })
        } catch (error) {
            toast.error(
                'Unable to change your password. Please make sure the code you entered is correct.',
            )
        }
    }

    const isSubmitDisabled =
        serverCode === '' || !passwordValidResult.passwordValid

    return (
        <Container className={classes.rootContainer}>
            <Container
                className={classes.formContainer}
                style={{ padding: theme.spacing(2) }}
            >
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                    <Container style={{ flexDirection: 'column' }}>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                ...theme.typography.h5,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Enter a new password
                        </span>
                        <FormControl fullWidth>
                            <TextField
                                id="new-pass"
                                placeholder="New Password"
                                variant="outlined"
                                type="password"
                                size="small"
                                autoComplete="new-password"
                                className={classes.passwordField}
                                onChange={(e) => setNewPassword(e.target.value)}
                                label="New Password"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                id="new-pass-confirm"
                                placeholder="Confirm Password"
                                variant="outlined"
                                type="password"
                                size="small"
                                autoComplete="new-password"
                                className={classes.passwordField}
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                label="Confirm Password"
                            />
                        </FormControl>

                        <PasswordRequirements
                            passwordRequirementResult={passwordValidResult}
                            containerStyle={{
                                marginLeft: theme.spacing(2),
                                justifyContent: 'center',
                            }}
                        />

                        <Button
                            variant="contained"
                            className={classes.submitButton}
                            disabled={isSubmitDisabled}
                            onClick={handleSubmit}
                        >
                            Reset Password
                        </Button>
                    </Container>
                </form>
            </Container>
        </Container>
    )
}
