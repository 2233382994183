import React from 'react'

import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    Theme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from '@material-ui/lab'

import { Container } from '../../components'
import {
    ChangeOrderController,
    changeOrderStatusOptions,
} from '../../hooks/useChangeOrder'
import {
    ChangeOrderConfig,
    ChangeOrderStatus,
    WorkSpaceUser,
    convertListToMap,
} from '../../models'
import { ChangeOrderRow } from '../../components/ChangeOrder/ChangeOrderRow'
import { usePagination } from '../../hooks'
import { useMultiSelectStyles } from '../../styles'

interface Props {
    open: boolean
    theme: Theme
    changeOrderConfigList: ChangeOrderConfig[]
    changeOrderController: ChangeOrderController
    workspaceUser?: WorkSpaceUser
    onClose: () => void
}

export const ChangeOrderListModal = (props: Props) => {
    const {
        open,
        theme,
        changeOrderConfigList,
        workspaceUser,
        changeOrderController,
        onClose,
    } = props

    const classes = useMultiSelectStyles()

    const changeOrderConfigMap = convertListToMap(changeOrderConfigList)

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        changeOrderController.filteredChangeOrders ?? [],
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{}}>
                    <Container
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            width: MODAL_W,
                            height: MODAL_H,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                borderBottom: `1px solid ${theme.palette.grey[800]}`,
                                height: HEADER_H,
                                padding: theme.spacing(0, 1),
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                }}
                            >
                                Change Orders
                            </span>
                        </Container>
                        <Container
                            style={{
                                padding: theme.spacing(1),
                            }}
                        >
                            <TextField
                                variant="outlined"
                                label="Unit Search"
                                value={
                                    changeOrderController.changeOrderFilterState
                                        .unitSearch
                                }
                                onChange={(e) => {
                                    changeOrderController.setChangeOrderFilterState(
                                        {
                                            ...changeOrderController.changeOrderFilterState,
                                            unitSearch: e.target.value,
                                        },
                                    )
                                }}
                                size="small"
                                InputProps={{
                                    endAdornment: <SearchIcon />,
                                }}
                            />

                            <FormControl
                                className={classes.formControl}
                                variant="outlined"
                                style={{ marginLeft: theme.spacing(2) }}
                            >
                                <InputLabel id="STATUS_FILTER_LABEL">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="STATUS_FILTER_LABEL"
                                    id="status_filter_label"
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: unknown
                                        }>,
                                    ) => {
                                        changeOrderController.setChangeOrderFilterState(
                                            {
                                                ...changeOrderController.changeOrderFilterState,
                                                status: event.target
                                                    .value as ChangeOrderStatus,
                                            },
                                        )
                                    }}
                                    value={
                                        changeOrderController
                                            .changeOrderFilterState.status
                                    }
                                    label="Status"
                                    style={{
                                        width: '200px',
                                        height: '40px',
                                    }}
                                >
                                    {changeOrderStatusOptions.map((option) => {
                                        return (
                                            <MenuItem
                                                key={`CO_STATUS_FILTER_${option}`}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Container>
                        {changeOrderController.isLoading ? (
                            <Container
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress size={100} />
                            </Container>
                        ) : (
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    overflowY: 'scroll',
                                }}
                            >
                                {pageData.map((changeOrder) => {
                                    const changeOrderConfig =
                                        changeOrderConfigMap[
                                            changeOrder.change_order_config
                                        ]

                                    return (
                                        <ChangeOrderRow
                                            key={`CHANGE_ORDER_ROW_${changeOrder.id}`}
                                            changeOrder={changeOrder}
                                            theme={theme}
                                            changeOrderConfig={
                                                changeOrderConfig
                                            }
                                            approveDenyChangeOrder={
                                                changeOrderController.approveDenyChangeOrder
                                            }
                                            workspaceUser={workspaceUser}
                                            deleteChangeOrder={
                                                changeOrderController.deleteChangeOrder
                                            }
                                        />
                                    )
                                })}
                            </Container>
                        )}
                        <Container
                            style={{
                                borderTop: `1px solid ${theme.palette.grey[400]}`,
                                padding: theme.spacing(1),
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Pagination
                                count={numPages}
                                page={page}
                                onChange={(_: unknown, newPage: number) => {
                                    setPage(newPage)
                                }}
                            />
                            <span>
                                {start} - {end} of{' '}
                                {changeOrderController.filteredChangeOrders
                                    .length ?? 0}
                            </span>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

// const MODAL_H = 'calc(100vh - 200px)'
// const MODAL_W = 'calc(100vw - 300px)'

const MODAL_H = window.innerHeight * 0.8
const MODAL_W = window.innerWidth * 0.8

const HEADER_H = 50
