import { Action } from 'redux'
import { AreaStatusConfig, IdBoolMap, WorkorderStatus } from '../../models'

// Actions
export const SET_INFRASTRUCTURE_FILTER = 'SET_INFRASTRUCTURE_FILTER'

interface SetInfrastructureFilterAction extends Action {
    type: typeof SET_INFRASTRUCTURE_FILTER
    newFilter?: InfrastructureFilterOptions
}

export type InfrastructureFilterActionTypes = SetInfrastructureFilterAction

// Reducer types
export enum AssignmentFilter {
    All,
    Assigned,
    NotAssigned,
}

export enum InspectionCompletionFilter {
    Complete,
    Assigned,
    All,
}

export enum InspectionHandledFilter {
    All,
    Handled,
    NotHandled,
}

export enum CalendarFilterMode {
    Gaps,
    Overlap,
}

export enum ChangeOrderFilterMode {
    All,
    HasAny,
    HasPending,
    HasApproved,
    HasDenied,
}

export enum PartialFilterMode {
    All,
    Partial,
    Vacant,
    Renewed,
}

export enum KeysFilterMode {
    All,
    AllKeysIn,
    PartialKeysIn,
    AllKeysOut,
}

export interface AreaStatusFilter {
    [id: number]: {
        inFilter: boolean
        areaStatusConfig: AreaStatusConfig
    }
    filterLength: number
}

export interface ReadyUnitFilter {
    enabled: boolean
    value: WorkorderStatus
}

export interface InfrastructureFilterOptions {
    serviceFilter: IdBoolMap
    vendorFilter: IdBoolMap
    unitConfigFilter: IdBoolMap
    invConfigFilter: IdBoolMap
    customStatusFilter: IdBoolMap
    priorityFilter: boolean
    vacantFilter: boolean
    unreadFilter: boolean
    hasMessages: boolean
    hasDamages: boolean
    isGhost: boolean
    hasGoBack: boolean
    behindWorkorders: boolean
    hasFlaggedItem: boolean
    hasCostDriver: boolean
    manualServiceAreas: boolean
    assignmentFilter: AssignmentFilter
    inspectionCompletionFilter: InspectionCompletionFilter
    statusFilter: { [key in WorkorderStatus]: boolean }
    areaStatusFilter: AreaStatusFilter
    dateFilter: {
        enable: boolean
        filterMode: CalendarFilterMode
        filterLeases: boolean
        filterServices: boolean
        range: {
            startDate: Date
            endDate: Date
            key: string
        }
    }
    unitSearch: string
    handled: InspectionHandledFilter
    changeOrder: ChangeOrderFilterMode
    readyUnitFilter: ReadyUnitFilter
    partialUnitFilter: PartialFilterMode
    displayVacantAreas: boolean
    keysFilter: KeysFilterMode
    serviceCategoryFilter: number | null
}

export interface InfrastructureFilterState {
    filter: InfrastructureFilterOptions
}
