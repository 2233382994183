import React, { useState } from 'react'
import {
    IconButton,
    Select,
    MenuItem,
    TextField,
    Theme,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { EntityClassification } from '../../../models/'
import { classificationDataTypes } from '../../../models/DocumentAudit/services'
import {
    DateFilterValue,
    FilterValue,
    NumberFilterValue,
    StringFilterValue,
} from './types'
import { Container } from '../../../components/Container'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { dateValid } from '../../../helpers'

export const FilterRow = (props: {
    theme: Theme
    classification: EntityClassification
    onRemoveFilter: () => void
    filterValue: FilterValue
    setFilterValue: (filterValue: FilterValue) => void
    firstRow: boolean
}) => {
    const {
        theme,
        classification,
        onRemoveFilter,
        filterValue,
        setFilterValue,
        firstRow,
    } = props

    const classificationDataType = classificationDataTypes.get(classification)
    if (!classificationDataType) return null

    let filterRowContent: React.ReactNode = null

    if (classificationDataType.type === 'string') {
        filterRowContent = (
            <NewStringFilterRow
                theme={theme}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
            />
        )
    }

    if (classificationDataType.type === 'number') {
        filterRowContent = (
            <NewNumberFilterRow
                theme={theme}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
            />
        )
    }

    if (classificationDataType.type === 'date') {
        filterRowContent = (
            <NewDateFilterRow
                theme={theme}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
            />
        )
    }

    return (
        <Container
            style={{
                marginBottom: theme.spacing(1),
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingBottom: theme.spacing(2),
                gap: theme.spacing(1),
                flexDirection: 'column',
            }}
        >
            {!firstRow && (
                <Container
                    style={{
                        flexDirection: 'row',
                        gap: 'inherit',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.text.secondary,
                            cursor: 'pointer',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(1),
                        }}
                        onClick={() => {
                            setFilterValue({
                                ...filterValue,
                                logicOperator:
                                    filterValue.logicOperator === 'and'
                                        ? 'or'
                                        : 'and',
                            })
                        }}
                    >
                        {filterValue.logicOperator}
                    </span>
                </Container>
            )}

            <Container
                style={{
                    flexDirection: 'row',
                    gap: 'inherit',
                    flex: 1,
                }}
            >
                {filterRowContent}
                <IconButton onClick={onRemoveFilter}>
                    <ClearIcon color="secondary" />
                </IconButton>
            </Container>
        </Container>
    )
}

const NewNumberFilterRow = (props: {
    theme: Theme
    filterValue: FilterValue
    setFilterValue: (filterValue: FilterValue) => void
}) => {
    const { theme, filterValue, setFilterValue } = props

    return (
        <Container
            style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: 'inherit',
                flex: 1,
            }}
        >
            <Select
                value={filterValue.operator}
                onChange={(e) => {
                    setFilterValue({
                        ...filterValue,
                        operator: e.target.value as
                            | 'eq'
                            | 'neq'
                            | 'lt'
                            | 'lte'
                            | 'gt'
                            | 'gte',
                    } as NumberFilterValue)
                }}
            >
                <MenuItem value="eq">=</MenuItem>
                <MenuItem value="neq">≠</MenuItem>
                <MenuItem value="lt">&lt;</MenuItem>
                <MenuItem value="lte">&le;</MenuItem>
                <MenuItem value="gt">&gt;</MenuItem>
                <MenuItem value="gte">&ge;</MenuItem>
            </Select>

            <TextField
                label="Value"
                value={filterValue.value}
                onChange={(e) =>
                    setFilterValue({
                        ...filterValue,
                        value: Number(e.target.value),
                    } as NumberFilterValue)
                }
                type="number"
                variant="standard"
                fullWidth
            />
        </Container>
    )
}

const NewStringFilterRow = (props: {
    theme: Theme
    filterValue: FilterValue
    setFilterValue: (filterValue: FilterValue) => void
}) => {
    const { theme, filterValue, setFilterValue } = props

    return (
        <Container
            style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: 'inherit',
                flex: 1,
            }}
        >
            <Select
                value={filterValue.operator}
                onChange={(e) => {
                    setFilterValue({
                        ...filterValue,
                        operator: e.target.value as
                            | 'eq'
                            | 'neq'
                            | 'contains'
                            | 'starts_with'
                            | 'ends_with',
                    } as StringFilterValue)
                }}
            >
                <MenuItem value="eq">=</MenuItem>
                <MenuItem value="neq">≠</MenuItem>
                <MenuItem value="contains">Contains</MenuItem>
                <MenuItem value="starts_with">Starts With</MenuItem>
                <MenuItem value="ends_with">Ends With</MenuItem>
            </Select>

            <TextField
                label="Value"
                value={filterValue.value}
                onChange={(e) =>
                    setFilterValue({
                        ...filterValue,
                        value: e.target.value,
                    } as StringFilterValue)
                }
                type="text"
                variant="standard"
                fullWidth
            />
        </Container>
    )
}

const NewDateFilterRow = (props: {
    theme: Theme
    filterValue: FilterValue
    setFilterValue: (filterValue: FilterValue) => void
}) => {
    const { theme, filterValue, setFilterValue } = props

    const date = filterValue.value as Date | null

    const [uiDate, setUiDate] = useState<Date | null>(
        date instanceof Date ? date : null,
    )

    return (
        <Container
            style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: 'inherit',
                flex: 1,
            }}
        >
            <Select
                value={filterValue.operator}
                onChange={(e) => {
                    setFilterValue({
                        ...filterValue,
                        operator: e.target.value as
                            | 'eq'
                            | 'neq'
                            | 'lt'
                            | 'lte'
                            | 'gt'
                            | 'gte',
                    } as DateFilterValue)
                }}
            >
                <MenuItem value="eq">=</MenuItem>
                <MenuItem value="neq">≠</MenuItem>
                <MenuItem value="lt">&lt;</MenuItem>
                <MenuItem value="lte">&le;</MenuItem>
                <MenuItem value="gt">&gt;</MenuItem>
                <MenuItem value="gte">&ge;</MenuItem>
            </Select>

            <KeyboardDatePicker
                label="Value"
                format="MM/dd/yyyy"
                value={uiDate}
                onChange={(date) => {
                    setUiDate(date)

                    if (dateValid(date ?? undefined)) {
                        setFilterValue({
                            ...filterValue,
                            value: date,
                        } as DateFilterValue)
                    }
                }}
            />
        </Container>
    )
}
