import { useRef } from 'react'
import { DAY_CELL_W } from '../constants'
import { MS_PER_DAY, getDifferenceInDays } from '../../../helpers'

// Constants
const NUM_DAY_PADDING = 8
const NUM_DAY_START_PADDING = 4

// Types
interface GridPositionInfo {
    numDisplayDays: number
    numProjectDays: number
    numStartPaddingDays: number
}

interface DateInfo {
    projectStart: Date
    projectEnd: Date
    displayStart: Date
}

/**
 * Hook to manage calendar grid calculations and positioning
 * @param startDate Project start date
 * @param endDate Project end date
 */
export const useCalendarGrid = (startDate: Date, endDate: Date) => {
    const gridRef = useRef<HTMLDivElement>(null)

    // Convert dates to UTC to avoid DST issues
    const getUTCDate = (date: Date) => {
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                0,
                0,
                0,
                0,
            ),
        )
    }

    // Grid calculations
    const getGridColumnCount = () => {
        if (!gridRef.current) return 0
        const gridWidth = gridRef.current.getBoundingClientRect().width
        return Math.ceil(gridWidth / DAY_CELL_W)
    }

    const getProjectDurationDays = () => {
        const utcStart = getUTCDate(startDate)
        const utcEnd = getUTCDate(endDate)
        return getDifferenceInDays(utcEnd, utcStart, true)
    }

    // Initialize grid dimensions
    const gridColumnCount = getGridColumnCount()
    const utcStartDate = getUTCDate(startDate)
    const startPointMs =
        utcStartDate.getTime() - MS_PER_DAY * NUM_DAY_START_PADDING
    const gridStart = new Date(startPointMs)
    const numProjectDays = getProjectDurationDays()
    const prePaddingDisplayDays = Math.max(numProjectDays, gridColumnCount)
    const numDisplayDays = prePaddingDisplayDays + NUM_DAY_PADDING

    // Position calculations
    const getDateGridPosition = (end: Date) => {
        const utcEnd = getUTCDate(end)
        const utcGridStart = getUTCDate(gridStart)
        return getDifferenceInDays(utcEnd, utcGridStart, true)
    }

    const convertGridPositionToDate = (gridPos: number) => {
        const utcGridStart = getUTCDate(gridStart)
        const startMs = utcGridStart.getTime()
        const offsetMs = gridPos * MS_PER_DAY
        const utcDate = new Date(startMs + offsetMs)

        // Convert back to local time zone
        return new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate(),
            0,
            0,
            0,
            0,
        )
    }

    const getClickedGridPosition = (clickX: number) => {
        if (!gridRef.current) return 0

        const gridEl = gridRef.current.getBoundingClientRect()
        const clickXCoord = clickX - gridEl.x + gridRef.current.scrollLeft
        return Math.floor(clickXCoord / DAY_CELL_W)
    }

    return {
        gridRef,
        getDateGridPosition,
        getClickedGridPosition,
        convertGridPositionToDate,
        gridPositionInfo: {
            numDisplayDays,
            numProjectDays,
            numStartPaddingDays: NUM_DAY_START_PADDING,
        } as GridPositionInfo,
        dateInfo: {
            projectStart: startDate,
            projectEnd: endDate,
            displayStart: gridStart,
        } as DateInfo,
    }
}

export type CalendarGridController = ReturnType<typeof useCalendarGrid>
