import { Routes } from '../config/routes'

import { VendorGuard } from '../guards'
import type { RouteConfig } from '../types'
import { AuthLayout } from '../layouts'
import { EZWorkRoot } from '../../containers/EZWork/EZWorkRoot'
import { VendorHelp } from '../../containers/Help'
import { VendorSettings } from '../../containers/VendorSettings'
import { VendorAnalytics } from '../../containers/Analytics'

export const vendorRoutes: RouteConfig[] = [
    {
        path: Routes.vendor.ezwork,
        component: EZWorkRoot,
        layout: AuthLayout,
        guard: VendorGuard,
    },
    {
        path: Routes.vendor.settings,
        component: VendorSettings,
        layout: AuthLayout,
        guard: VendorGuard,
    },
    {
        path: Routes.vendor.help,
        component: VendorHelp,
        layout: AuthLayout,
        guard: VendorGuard,
    },
    {
        path: Routes.vendor.analytics,
        component: VendorAnalytics,
        layout: AuthLayout,
        guard: VendorGuard,
    },
]
