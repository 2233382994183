import React from 'react'
import { Grid } from '@material-ui/core'
import { BaseProps } from '../../types'
import { HEADER_H } from '../../constants'
import { Container } from '../../../Container'

interface Props extends BaseProps {
    unitCount: number
    serviceAreaCount: number
}

export const ServiceDetailHeader = (props: Props) => {
    const { theme, unitCount, serviceAreaCount } = props

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                borderRight: `1px solid ${theme.palette.grey[200]}`,
                minHeight: HEADER_H,
                maxHeight: HEADER_H,
                boxSizing: 'border-box',
                padding: theme.spacing(1),
            }}
        >
            <Grid item xs={6}>
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightMedium,
                            color: theme.palette.text.secondary,
                        }}
                    >
                        units
                    </span>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        {unitCount}
                    </span>
                </Container>
            </Grid>

            <Grid item xs={6}>
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightMedium,
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Service areas
                    </span>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        {serviceAreaCount}
                    </span>
                </Container>
            </Grid>
        </Grid>
    )
}
