import {
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import {
    Container,
    Finder,
    LocationForm,
    Selector,
    SideDrawer,
} from '../../components'
import { FinderSelection } from '../../hooks'
import {
    DamageStatus,
    Folder,
    Inspection,
    getDamageStatusName,
} from '../../models'
import { useMultiSelectStyles } from '../../styles'
import {
    DamageFilter,
    HasIssuesFilter,
    defaultDamageFilter,
} from './DamageList'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'

interface Props {
    tree: Folder
    selection: FinderSelection
    open: boolean
    damageFilterState: DamageFilter
    inspectionList: Inspection[]
    onApplyFilter: (
        modifiedFilter: DamageFilter,
        finderSelection?: FinderSelection,
    ) => void
    setDamageFilterState: (newValue: DamageFilter) => void
    onClose: () => void
}

export const DamageFilterDrawer: React.FC<Props> = ({
    open,
    onClose,
    tree,
    selection,
    damageFilterState,
    setDamageFilterState,
    onApplyFilter,
    inspectionList,
}) => {
    const theme = useTheme()
    const classes = useMultiSelectStyles()

    const selectorStyle: React.CSSProperties = {
        margin: theme.spacing(1, 0),
        flex: 1,
    }

    return (
        <SideDrawer open={open} handleClose={onClose} width={330}>
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <Container
                    style={{
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Tenant RCR Issues
                    </span>
                    <ToggleButtonGroup
                        value={damageFilterState.hasIssues}
                        onChange={(_, value) =>
                            setDamageFilterState({
                                ...damageFilterState,
                                hasIssues: value,
                                modified: true,
                            })
                        }
                        aria-label="Tenant Ins Issues"
                        exclusive
                        style={{
                            margin: theme.spacing(1, 0, 1, 0),
                        }}
                    >
                        <ToggleButton
                            value={HasIssuesFilter.NO_FILTER}
                            aria-label="No Filter"
                        >
                            No Filter
                        </ToggleButton>
                        <ToggleButton
                            value={HasIssuesFilter.ISSUES}
                            aria-label="Issues"
                        >
                            Issues
                        </ToggleButton>
                        <ToggleButton
                            value={HasIssuesFilter.NO_ISSUES}
                            aria-label="No Issues"
                        >
                            No Issues
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl style={{ flex: 1 }}>
                        <InputLabel id="vendor-chip-label">Status</InputLabel>
                        <Select
                            value={damageFilterState.status}
                            renderValue={(value) => {
                                const statusList = value as DamageStatus[]
                                return statusList.map((status) => {
                                    return (
                                        <Chip
                                            key={status}
                                            label={getDamageStatusName(status)}
                                            className={classes.chip}
                                        />
                                    )
                                })
                            }}
                            multiple
                            onChange={(e) => {
                                setDamageFilterState({
                                    ...damageFilterState,
                                    status: e.target.value as DamageStatus[],
                                    modified: true,
                                })
                            }}
                        >
                            <MenuItem value={DamageStatus.PENDING}>
                                <span>Pending</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.PRE_APPROVED}>
                                <span>Preapproved</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.APPROVED}>
                                <span>Approved</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.SYNC_PENDING}>
                                <span>Sync Pending</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.ENTRATA_SYNCED}>
                                <span>Synced</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.DENIED}>
                                <span>Denied</span>
                            </MenuItem>
                            <MenuItem value={DamageStatus.SYNC_FAILED}>
                                <span>Failed</span>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <Selector
                        data={inspectionList}
                        customStyle={{ formControl: selectorStyle }}
                        label="Inspection"
                        onChange={(e) => {
                            setDamageFilterState({
                                ...damageFilterState,
                                inspection: e.target.value as number,
                                modified: true,
                            })
                        }}
                        currentValue={damageFilterState.inspection}
                        getDisplayString={(ins) => ins.name}
                        searchable
                        variant="standard"
                    />

                    {/*  */}

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            Created Before
                        </span>
                        <KeyboardDatePicker
                            value={damageFilterState.created_before}
                            format="MM/dd/yyyy"
                            onChange={(date) => {
                                if (date) {
                                    setDamageFilterState({
                                        ...damageFilterState,
                                        created_before: date,
                                        modified: true,
                                    })
                                }
                            }}
                        />
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            Created After
                        </span>
                        <KeyboardDatePicker
                            value={damageFilterState.created_after}
                            format="MM/dd/yyyy"
                            onChange={(date) => {
                                if (date) {
                                    setDamageFilterState({
                                        ...damageFilterState,
                                        created_after: date,
                                        modified: true,
                                    })
                                }
                            }}
                        />
                    </Container>
                    <Container
                        style={{
                            padding: theme.spacing(0, 1, 0, 1),
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <FormLabel
                            component="legend"
                            style={{
                                ...theme.typography.body1,
                                color: 'black',
                                marginRight: theme.spacing(2),
                            }}
                        >
                            Cost
                        </FormLabel>

                        <FormControl
                            style={{
                                marginRight: theme.spacing(2),
                                minWidth: 50,
                            }}
                        >
                            <Select
                                labelId="operator-label"
                                value={damageFilterState.costFilter.operator}
                                onChange={(e) =>
                                    setDamageFilterState({
                                        ...damageFilterState,
                                        costFilter: {
                                            ...damageFilterState.costFilter,
                                            operator: e.target.value as
                                                | 'gt'
                                                | 'lt'
                                                | 'eq'
                                                | 'gte'
                                                | 'lte',
                                        },
                                        modified: true,
                                    })
                                }
                            >
                                <MenuItem value={'gt'}>&gt;</MenuItem>
                                <MenuItem value={'gte'}>&ge;</MenuItem>
                                <MenuItem value={'lt'}>&lt;</MenuItem>
                                <MenuItem value={'lte'}>&le;</MenuItem>
                                <MenuItem value={'eq'}>=</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            type="number"
                            value={damageFilterState.costFilter.cost || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? parseFloat(e.target.value)
                                    : null
                                setDamageFilterState({
                                    ...damageFilterState,
                                    costFilter: {
                                        ...damageFilterState.costFilter,
                                        cost: value,
                                    },
                                    modified: true,
                                })
                            }}
                            variant="standard"
                            style={{ width: '70%', padding: theme.spacing(1) }}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Container>
                    <Container>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        damageFilterState.created_date_sort
                                    }
                                    color="primary"
                                    onChange={() => {
                                        setDamageFilterState({
                                            ...damageFilterState,
                                            created_date_sort: !damageFilterState.created_date_sort,
                                            modified: true,
                                        })
                                    }}
                                />
                            }
                            labelPlacement="start"
                            label="Sort By Creation Date"
                            style={{ marginLeft: theme.spacing(1) }}
                        />
                    </Container>
                    <Container>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={damageFilterState.hasAttachment}
                                    color="primary"
                                    onChange={() => {
                                        setDamageFilterState({
                                            ...damageFilterState,
                                            hasAttachment: !damageFilterState.hasAttachment,
                                            modified: true,
                                        })
                                    }}
                                />
                            }
                            labelPlacement="start"
                            label="Has Image"
                            style={{ marginLeft: theme.spacing(1) }}
                        />
                    </Container>
                </Container>

                <Container style={{ padding: theme.spacing(1), flex: 1 }}>
                    <Finder
                        root={tree}
                        locationSelection={selection.selection}
                        onClickFolder={(folder) =>
                            selection.setFinderSelection(folder)
                        }
                        onClickUnit={(unit) =>
                            selection.setFinderSelection(unit)
                        }
                        selectedIcon={<></>}
                        getUnitAdornment={(unit) => {
                            return (
                                <Container style={{ flex: 1 }}>
                                    <div style={{ flex: 1 }} />
                                    <Checkbox
                                        checked={
                                            selection.selection.unit[
                                                unit.id
                                            ] !== undefined
                                        }
                                        onClick={() => {
                                            selection.setFinderSelection({
                                                type: 'unit',
                                                location: unit,
                                            })
                                            setDamageFilterState({
                                                ...damageFilterState,
                                                modified: true,
                                            })
                                        }}
                                    />
                                </Container>
                            )
                        }}
                        getFolderAdornment={(folder) => {
                            return (
                                <Container style={{ flex: 1 }}>
                                    <div style={{ flex: 1 }} />
                                    <Checkbox
                                        color={'primary'}
                                        checked={
                                            selection.selection.folder[
                                                folder.id
                                            ] !== undefined
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            selection.setFinderSelection({
                                                type: 'folder',
                                                location: folder,
                                            })
                                            setDamageFilterState({
                                                ...damageFilterState,
                                                modified: true,
                                            })
                                        }}
                                    />
                                </Container>
                            )
                        }}
                    />
                </Container>

                {/* footer */}
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        style={{ marginRight: theme.spacing(2) }}
                        variant="outlined"
                        onClick={() => onApplyFilter(defaultDamageFilter)}
                    >
                        Clear All
                    </Button>

                    <div style={{ flex: 1 }} />
                    <Button
                        disabled={!damageFilterState.modified}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            onApplyFilter(damageFilterState, selection)
                        }}
                    >
                        Apply
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
