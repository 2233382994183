import {
    IdentifiableObject,
    LeaseCluster,
    OnCallDay,
    Schedule,
} from '../../models'
import { CalendarDayOff } from '../../models/Calendar'

export enum CalendarEventType {
    SERVICE_ORDER = 'SERVICE_ORDER',
    ACTION_ITEM = 'ACTION_ITEM',
    DEADLINE = 'DEADLINE',
    PROJECT = 'PROJECT',
    MOVE_OUT = 'MOVE_OUT',
    MOVE_IN = 'MOVE_IN',
    SERVICE_REQUEST = 'SERVICE_REQUEST',
    ON_CALL = 'ON_CALL',
    DAY_OFF = 'DAY_OFF',
}
export type FormState = {
    meta: {
        xPos: number
        yPos: number
        date: Date
    }
    popovers: CalendarPopoverState
    modals: CalendarModalState
}

export type CalendarModalState = {
    deadlineModal: {
        open: boolean
        deadlineId: number
    }
    addDeadlineModal: boolean
    addActionItemModal: boolean
    clusterDetailModal: ClusterDetailFormState | null
    ezNowCreationModal: boolean
    leaseModal: LeaseModalFormState | null
}

export type LeaseModalFormState = {
    type: CalendarEventType.MOVE_IN | CalendarEventType.MOVE_OUT
    leaseCluster: LeaseCluster | null
}

export type ClusterDetailFormState = {
    selectedClusterIndex: number
}

export type CalendarPopoverState = {
    projectPopover: ProjectPopoverFormState
    addEventPopover: boolean
    addOnCallPopover: boolean
    onCallDetailPopover: OnCallPopoverFormState
    addDayOffPopover: boolean
    dayOffDetailPopover: DayOffPopoverFormState
}

export type ProjectPopoverFormState = {
    schedule: Schedule | null
    open: boolean
}

export type OnCallPopoverFormState = {
    onCallDay: OnCallDay | null
    open: boolean
    htmlElement: HTMLElement | null
}

export type DayOffPopoverFormState = {
    dayOff: CalendarDayOff | null
    open: boolean
    htmlElement: HTMLElement | null
}

export const defaultFormState: FormState = {
    meta: {
        xPos: 0,
        yPos: 0,
        date: new Date(),
    },
    popovers: {
        projectPopover: {
            schedule: null,
            open: false,
        },
        addEventPopover: false,
        addOnCallPopover: false,
        addDayOffPopover: false,
        onCallDetailPopover: {
            onCallDay: null,
            open: false,
            htmlElement: null,
        },
        dayOffDetailPopover: {
            dayOff: null,
            open: false,
            htmlElement: null,
        },
    },
    modals: {
        deadlineModal: {
            open: false,
            deadlineId: -1,
        },
        addDeadlineModal: false,
        addActionItemModal: false,
        clusterDetailModal: null,
        ezNowCreationModal: false,
        leaseModal: null,
    },
}

export interface UserCalendarConfig extends IdentifiableObject {
    user: number
    auto_display: boolean
    event_type: CalendarEventType
    created_date: string
    modified_date: string
}

export const eventTypeOptions = [
    { be: 'PROJECT', d: 'Projects' },
    { be: 'DEADLINE', d: 'Deadlines' },
    { be: 'SERVICE_ORDER', d: 'Service Orders' },
    { be: 'ACTION_ITEM', d: 'Action Items' },
    { be: 'SERVICE_REQUEST', d: 'Service Requests' },
    { be: 'MOVE_IN', d: 'Move Ins' },
    { be: 'MOVE_OUT', d: 'Move Outs' },
    { be: 'ON_CALL', d: 'On Call' },
    { be: 'DAY_OFF', d: 'Day Off' },
] as const
