import React from 'react'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { OnCallDay, User } from '../../../../../models'

interface Props extends BaseProps {
    onCallDay: OnCallDay
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const EventTypeOnCall = (props: Props) => {
    const { theme, onCallDay, onClick } = props

    const startTime = new Date(onCallDay.start_time).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    })
    const endTime = new Date(onCallDay.end_time).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    })

    return (
        <Container
            style={{ alignItems: 'center', width: '100%' }}
            onClick={onClick}
        >
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightMedium,
                }}
            >
                {onCallDay.user.name}
            </span>
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightRegular,
                    marginLeft: theme.spacing(1),
                }}
            >
                {startTime} - {endTime}
            </span>
        </Container>
    )
}
