import React from 'react'

import { Button, useTheme } from '@material-ui/core'

import { Bid, RFP } from '../../models'
import { WorkorderResponse } from '../../store'
import { Container, WorkorderRow } from '../../components'
import { useUser } from '../../hooks'
import { workorderContext } from '../../contexts'
import { useMessage } from '../../hooks/useMessage'

interface Props {
    workorders: WorkorderResponse[]
    selectedBid: Bid
    handleAcceptEZNowBid: () => void
}

export const EZNowConfirmationPage = (props: Props) => {
    const { workorders, selectedBid, handleAcceptEZNowBid } = props

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const { transitionWorkorder, updateWorkorderState } = workorderContext()

    const { createMessage } = useMessage()

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <Container
                direction="column"
                style={{ marginRight: theme.spacing(5) }}
            >
                <Container
                    style={{
                        ...theme.typography.h6,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Assign To: {selectedBid.vendor.name}
                </Container>
                <Container style={{ ...theme.typography.subtitle1 }}>
                    Price: ${selectedBid.group_bid_price}
                </Container>
            </Container>
            <Container
                style={{
                    flex: 1,
                    overflowX: 'hidden',
                    marginLeft: theme.spacing(1),
                    maxHeight: 'calc(100vh - 475px)',
                    minHeight: 'calc(100vh - 475px)',
                    overflowY: 'scroll',
                    flexDirection: 'column',
                }}
            >
                {workorders.map((wo, idx) => {
                    return (
                        <WorkorderRow
                            key={`CONFIRM_WORKORDER_${wo.id}`}
                            workorder={wo}
                            isSelectedWorkorder={false}
                            canSeeUnitNotes
                            openUnitNotes={() => {}}
                            transitionWorkorder={transitionWorkorder}
                            onClickWorkorder={() => {}}
                            createMessage={createMessage}
                            updateWorkorderState={updateWorkorderState}
                            user={workspaceUser}
                            openMessageDrawer={() => {}}
                            hideMessageButtons
                            width={`calc(100vw - 400px)`}
                        />
                    )
                })}
            </Container>
            <Container style={{ width: '100%' }}>
                <Button
                    variant="outlined"
                    size="small"
                    style={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        height: '40px',
                        margin: theme.spacing(2, 0, 2, 0),
                        width: '100%',
                    }}
                    onClick={() => handleAcceptEZNowBid()}
                >
                    Assign Work
                </Button>
            </Container>
        </Container>
    )
}
