import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react'
import {
    axiosInstance,
    enumKeys,
    timelinesOverlap,
    toMMDDYYYY,
} from '../../helpers'
import {
    BaseWorkorder,
    Frequency,
    FrequencyType,
    IdentifiableObject,
    ListVendor,
    PeriodOption,
    Service,
    UnitConfig,
    _Workorder,
    WorkorderAutomationConfig,
    WorkorderAutomationType,
    WorkorderFrequencyAutomation,
    WorkorderStatus,
    WorkorderType,
    createWorkorderFromResponse,
    getUiStatus,
    isWorkorderSafe,
    Workorder,
    RFPType,
    WorkorderCluster,
    ClusterGroup,
    Schedule,
    ModelMap,
    convertMapToList,
} from '../../models'

import {
    BulkDeleteWorkOrderRequest,
    BulkUpdateWorkOrderRequest,
    CreateWorkOrderRequest,
    DeleteWorkorderParams,
    FinderLocationSelection,
    FinderSelectionMode,
    LocationSelection,
    RootState,
    TransitionWorkorderParams,
    UpdateWorkOrderRequest,
    WorkorderResponse,
} from '../../store'
import { FilterChipData } from '../../components'
import { FinderSelection, _useFinderSelection } from '../../hooks'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

export interface IWorkorderContext {
    workorderResponse: WorkorderResponse[] | null
    setWorkorderResponse: Dispatch<SetStateAction<WorkorderResponse[] | null>>
    frequencyList: WorkorderFrequencyAutomation[] | null
    setFrequencyList: Dispatch<
        SetStateAction<WorkorderFrequencyAutomation[] | null>
    >
    workorderFilterState: WorkorderFilterState
    setWorkorderFilterState: Dispatch<SetStateAction<WorkorderFilterState>>
    workorderClusters: WorkorderCluster[] | null
    setWorkorderClusters: Dispatch<SetStateAction<WorkorderCluster[] | null>>
    loadingState: LoadingState
    setLoadingState: Dispatch<SetStateAction<LoadingState>>
    finderFilterSelection: FinderSelection
    finderCreateSelection: FinderSelection
    triggerList: WorkorderAutomationConfig[] | null
    setTriggerList: Dispatch<SetStateAction<WorkorderAutomationConfig[] | null>>
    workorderSelectionMap: ModelMap<WorkorderResponse>
    setWorkorderSelectionMap: Dispatch<
        SetStateAction<ModelMap<WorkorderResponse>>
    >
}

export const WorkorderContext = createContext<IWorkorderContext>(
    (null as unknown) as IWorkorderContext,
)

export const workorderContext = () => {
    // ********* State *********
    const state = useContext(WorkorderContext)

    const vendorList = useSelector(
        (state: RootState) => state.company.vendorList,
    )

    const userList = useSelector((state: RootState) => state.user.userList)

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const unitTypeList = useSelector(
        (state: RootState) => state.aptConfig.unitConfigList,
    )

    // ********* Workorder *********
    const getWorkorderList = async (
        request: GetWorkOrderListRequest,
        priorityFirst?: boolean,
    ) => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getWorkorderList: true,
            }
        })

        try {
            const res = await axiosInstance.get('workorder/', request)
            const workorderRes: WorkorderResponse[] = res.data
            if (priorityFirst) {
                const priorityWorkorders: WorkorderResponse[] = []
                const notPriorityWorkorders: WorkorderResponse[] = []

                // Split the two workorders into arrays of priority / not priority
                workorderRes.forEach((wo) => {
                    if (wo.priority) {
                        priorityWorkorders.push(wo)
                    } else {
                        notPriorityWorkorders.push(wo)
                    }
                })

                // Comparison function for sorting by date
                const sortComparison = (
                    a: WorkorderResponse,
                    b: WorkorderResponse,
                ) => {
                    if (a.start_date && b.start_date) {
                        const aStart = new Date(a.start_date)
                        const bStart = new Date(b.start_date)
                        return aStart.valueOf() - bStart.valueOf()
                    }

                    return 0
                }

                priorityWorkorders.sort(sortComparison)
                notPriorityWorkorders.sort(sortComparison)

                state.setWorkorderResponse(
                    priorityWorkorders.concat(notPriorityWorkorders),
                )
            } else {
                state.setWorkorderResponse(workorderRes)
            }
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getWorkorderList: false,
                }
            })
            return workorderRes
        } catch (e) {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getWorkorderList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const createWorkorders = async (
        request: CreateWorkOrderRequest,
        createEZNowRequest?: boolean,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    createWorkorder: true,
                }
            })

            axiosInstance
                .post('workorder/', request.body)
                .then((res) => {
                    const workorderRes: WorkorderResponse[] =
                        res.data.workorders
                    const oldVal = state.workorderResponse ?? []

                    state.setWorkorderResponse([...oldVal, ...workorderRes])

                    if (createEZNowRequest) {
                        state.setWorkorderSelectionMap(
                            convertMapToList(workorderRes),
                        )
                    }

                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            createWorkorder: false,
                        }
                    })
                })
        })
    }

    const transitionWorkorder = async (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const url = `workorder/${workorder.id}/transition/`

            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    transitionWorkorder: true,
                }
            })

            axiosInstance
                .post(url, { status: status }, { params: params })
                .then((res) => {
                    const updatedWorkorder = res.data
                    updateWorkorderState(updatedWorkorder)
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            transitionWorkorder: false,
                        }
                    })
                })
        })
    }

    const superTransitionWorkorders = async (
        workorders: TransitionStatusDict[],
        allJobsites?: boolean,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            let url = `workorder/super_transition/`
            if (allJobsites) {
                url = url + '?all_jobsites=true'
            }

            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    transitionWorkorder: true,
                }
            })

            axiosInstance
                .post(url, { workorders: workorders })
                .then((res) => {
                    const updatedWorkorders: WorkorderResponse[] = res.data
                    updatedWorkorders.forEach((wo) => updateWorkorderState(wo))
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            transitionWorkorder: false,
                        }
                    })
                })
        })
    }

    const updateWorkorder = async (
        request: UpdateWorkOrderRequest,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateWorkorder: true,
                }
            })

            axiosInstance
                .patch(`workorder/${request.workorderId}/`, request.body, {
                    params: request.params,
                })
                .then((res) => {
                    const updatedWorkorder = res.data
                    updateWorkorderState(updatedWorkorder)
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateWorkorder: false,
                        }
                    })
                })
        })
    }

    const deleteWorkorder = async (
        workorder: WorkorderResponse,
        params?: DeleteWorkorderParams,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateWorkorder: true,
                }
            })

            axiosInstance
                .delete(`workorder/${workorder.id}/`, {
                    params: params,
                })
                .then((res) => {
                    _removeIdentifiable(workorder, state.setWorkorderResponse)
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateWorkorder: false,
                        }
                    })
                })
        })
    }

    const bulkUpdateWorkorders = async (
        request: BulkUpdateWorkOrderRequest,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateWorkorder: true,
                }
            })

            axiosInstance
                .post(`workorder/bulk_update/`, request.body, {
                    params: request.params,
                })
                .then((res) => {
                    const updatedWorkorders: WorkorderResponse[] = res.data
                    updatedWorkorders.forEach((wo) => updateWorkorderState(wo))
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateWorkorder: false,
                        }
                    })
                })
        })
    }

    const bulkDeleteWorkorders = async (
        request: BulkDeleteWorkOrderRequest,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateWorkorder: true,
                }
            })

            axiosInstance
                .post(`workorder/bulk_delete/`, request.body, {
                    params: request.params,
                })
                .then(() => {
                    state.setWorkorderResponse(
                        state.workorderResponse?.filter(
                            (wo) => !request.body.workorders.includes(wo.id),
                        ) ?? [],
                    )
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateWorkorder: false,
                        }
                    })
                })
        })
    }

    const updateWorkorderState = (workorder: WorkorderResponse) => {
        _insertOrUpdateIdentifiable(workorder, state.setWorkorderResponse)
    }

    const updateWoWithNextAvailableDate = async (
        workorder: WorkorderResponse,
        vendorId: number,
        apartmentId?: number,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateWorkorder: true,
                }
            })

            let params = {}
            if (apartmentId) {
                params = {
                    apartment_id: apartmentId,
                }
            }

            axiosInstance
                .post(
                    `workorder/${workorder.id}/get_next_available_date/`,
                    {
                        vendor_id: vendorId,
                    },
                    { params: params },
                )
                .then((res) => {
                    const updatedWorkorder = res.data
                    updateWorkorderState(updatedWorkorder)
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateWorkorder: false,
                        }
                    })
                })
        })
    }

    // ********* Workorder Clusters *********
    const getWorkorderClusters = async (request: GetWorkOrderListRequest) => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getWorkorderClusters: true,
            }
        })

        try {
            const res = await axiosInstance.get('workorder/cluster/', request)
            const clusterRes: WorkorderCluster[] = res.data
            state.setWorkorderClusters(clusterRes)
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getWorkorderClusters: false,
                }
            })
            return clusterRes
        } catch (e) {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getWorkorderClusters: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const getClusterGroups = (clusters: WorkorderCluster[]) => {
        const clusterGroups: ClusterGroup[] = []

        if (clusters.length > 0) {
            const woCluster = clusters[0]

            let cg: ClusterGroup = {
                start_date: woCluster.start_date,
                end_date: woCluster.end_date,
                clusters: [woCluster],
                workorder_type: woCluster.workorder_type,
                service: woCluster.service,
                index: 0,
            }

            for (let i = 1; i < clusters.length; i++) {
                const curCluster = clusters[i]

                if (
                    cg.start_date === curCluster.start_date &&
                    cg.end_date === curCluster.end_date
                ) {
                    cg.clusters.push(curCluster)
                } else {
                    clusterGroups.push(cg)

                    cg = {
                        start_date: curCluster.start_date,
                        end_date: curCluster.end_date,
                        workorder_type: curCluster.workorder_type,
                        service: curCluster.service,
                        clusters: [curCluster],
                        index: clusterGroups.length,
                    }
                }
            }

            clusterGroups.push(cg)
        }

        return clusterGroups
    }

    // ********* Frequency *********
    const getFrequencyList = async () => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getFrequencyList: true,
            }
        })

        try {
            const res = await axiosInstance.get('workorder/automation/', {
                params: { automation_type: WorkorderAutomationType.FREQUENCY },
            })
            const frequencyRes: WorkorderFrequencyAutomation[] = res.data
            state.setFrequencyList(frequencyRes)
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getFrequencyList: false,
                }
            })
            return frequencyRes
        } catch (e) {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getFrequencyList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const createFrequency = async (
        request: CreateWorkorderAutomationRequest,
    ) => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    createFrequency: true,
                }
            })

            axiosInstance
                .post('workorder/automation/', request)
                .then((res) => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            createFrequency: false,
                        }
                    })
                    _insertOrUpdateIdentifiable(
                        res.data,
                        state.setFrequencyList,
                    )
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            createFrequency: false,
                        }
                    })
                })
        })
    }

    const updateFrequency = async (
        request: CreateWorkorderAutomationRequest,
        id: number,
    ) => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateFrequency: true,
                }
            })

            axiosInstance
                .put(`workorder/automation/${id}/`, request)
                .then((res) => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateFrequency: false,
                        }
                    })
                    _insertOrUpdateIdentifiable(
                        res.data,
                        state.setFrequencyList,
                    )
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateFrequency: false,
                        }
                    })
                })
        })
    }

    const toggleFrequencyActive = async (id: number) => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateFrequency: true,
                }
            })

            axiosInstance
                .post(`workorder/automation/${id}/toggle_active/`)
                .then((res) => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateFrequency: false,
                        }
                    })
                    _insertOrUpdateIdentifiable(
                        res.data,
                        state.setFrequencyList,
                    )
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateFrequency: false,
                        }
                    })
                })
        })
    }

    // ********* Triggers *********
    const getInspectionTriggers = async () => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                getTriggerList: true,
            }
        })

        try {
            const res = await axiosInstance.get('workorder/automation/', {
                params: {
                    automation_type: WorkorderAutomationType.TRIGGER,
                },
            })

            const inspectionTriggers: WorkorderAutomationConfig[] = res.data
            state.setTriggerList(inspectionTriggers)
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getTriggerList: false,
                }
            })

            return inspectionTriggers
        } catch (e) {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    getTriggerList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const createInspectionTrigger = async (
        request: CreateWorkorderAutomationRequest,
    ) => {
        state.setLoadingState((prev) => {
            return {
                ...prev,
                createTrigger: true,
            }
        })

        try {
            const res = await axiosInstance.post(
                'workorder/automation/',
                request,
            )
            const trigger: WorkorderAutomationConfig = res.data
            _insertOrUpdateIdentifiable(trigger, state.setTriggerList)
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    createTrigger: false,
                }
            })
            return trigger
        } catch (e) {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    createTrigger: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const updateTrigger = async (
        request: CreateWorkorderAutomationRequest,
        id: number,
    ) => {
        return new Promise((resolve, reject) => {
            state.setLoadingState((prev) => {
                return {
                    ...prev,
                    updateTrigger: true,
                }
            })

            axiosInstance
                .put(`workorder/automation/${id}/`, request)
                .then((res) => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateTrigger: false,
                        }
                    })
                    _insertOrUpdateIdentifiable(res.data, state.setTriggerList)
                    resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    state.setLoadingState((prev) => {
                        return {
                            ...prev,
                            updateTrigger: false,
                        }
                    })
                })
        })
    }

    // ********* Helpers *********
    const clearState = () => {
        state.setWorkorderResponse(null)
        state.setWorkorderFilterState(initialWorkorderFilterState)
    }

    // ********* Memoized public data *********
    const filteredWorkorderList = useMemo(() => {
        const filter = state.workorderFilterState
        const workorders = state.workorderResponse ?? []

        let statusSelectionEmpty = true
        // If any statuses are selected set status valid to false
        Object.keys(filter.statusFilter).forEach((key) => {
            const k = key as WorkorderStatus
            statusSelectionEmpty =
                statusSelectionEmpty && !filter.statusFilter[k]
        })

        const finderSelectionEmpty =
            state.finderFilterSelection.selection.unit.length === 0

        return workorders.filter((wo) => {
            const statusValid =
                filter.statusFilter[wo.status] || statusSelectionEmpty

            const scheduleValid =
                filter.schedule === -1 || wo.schedule_id === filter.schedule

            const hasTimeline = wo.start_date !== null && wo.end_date !== null

            const isGhost = wo.vendor_id === null || !hasTimeline

            let woTypeValid = true
            if (filter.workorderType === WORKORDER_TYPE_ALL) {
                woTypeValid = true
            } else if (
                filter.workorderType === WORKORDER_TYPE_SERVICE_ORDER &&
                wo.type === WorkorderType.SERVICE_ORDER
            ) {
                woTypeValid = true
            } else if (
                filter.workorderType === WORKORDER_TYPE_ACTION_ITEM &&
                wo.type === WorkorderType.ACTION_ITEM
            ) {
                woTypeValid = true
            } else if (
                filter.workorderType === WORKORDER_TYPE_SERVICE_REQUEST &&
                wo.type === WorkorderType.SERVICE_REQUEST
            ) {
                woTypeValid = true
            } else {
                woTypeValid = false
            }

            const priorityValid = !filter.priority || wo.priority
            const unreadValid = !filter.hasUnread || (wo.unread_count ?? 0) > 0
            const msgValid = !filter.hasMessage || (wo.message_count ?? 0) > 0
            const ghostValid = !filter.isGhost || isGhost

            const unitValid =
                finderSelectionEmpty ||
                state.finderFilterSelection.selection.unit[wo.unit_id ?? -1] !==
                    undefined

            const dateValid =
                !filter.timeline.enabled ||
                (hasTimeline &&
                    timelinesOverlap(
                        {
                            startDate: new Date(wo.start_date),
                            endDate: new Date(wo.end_date),
                        },
                        {
                            startDate: filter.timeline.startDate,
                            endDate: filter.timeline.endDate,
                        },
                    ))

            const vendorValid =
                filter.vendorIds.length === 0 ||
                filter.vendorIds.find((vId) => wo.vendor_id === vId)

            const userValid =
                filter.userIds.length === 0 ||
                filter.userIds.find((uId) => wo.user_id === uId)

            const serviceValid =
                filter.serviceIds.length === 0 ||
                filter.serviceIds.find((sId) => wo.service_id === sId)

            const unitTypeValid =
                filter.unitTypeIds.length === 0 ||
                filter.unitTypeIds.find((utId) => wo.unit_config_id === utId)

            const ezNowValid =
                !filter.canBeEZNow ||
                (wo.price_group_id === null &&
                    wo.vendor_id === null &&
                    wo.status === WorkorderStatus.ASSIGNED)

            const unitSearchValid =
                filter.unitSearch === '' ||
                wo.unit_name
                    ?.toLocaleLowerCase()
                    .includes(filter.unitSearch.toLocaleLowerCase())

            const apartmentValid =
                filter.apartmentId === -1 ||
                wo.apartment_id === filter.apartmentId

            const isEZNowWorkorder =
                !filter.isEZNow ||
                (wo.active_rfp && wo.rfp_type === RFPType.EZNOW)

            return (
                statusValid &&
                priorityValid &&
                unreadValid &&
                msgValid &&
                ghostValid &&
                dateValid &&
                vendorValid &&
                unitTypeValid &&
                serviceValid &&
                unitValid &&
                userValid &&
                ezNowValid &&
                woTypeValid &&
                unitSearchValid &&
                apartmentValid &&
                isEZNowWorkorder &&
                scheduleValid
            )
        })
    }, [
        state.workorderResponse,
        state.workorderFilterState,
        state.finderFilterSelection,
    ])

    const getChipData = (options?: {
        onClickHandler?: (options: {
            filterState: WorkorderFilterState
        }) => void
        scheduleList?: Schedule[]
    }) => {
        const chipData: FilterChipData[] = []

        const finderSelection = state.finderFilterSelection
        const filter = state.workorderFilterState

        if (filter.schedule > -1) {
            let scheduleLabel = 'Schedule'
            if (options?.scheduleList) {
                const schedule = options.scheduleList.find(
                    (sch) => sch.id === filter.schedule,
                )
                scheduleLabel = schedule?.name ?? 'Unknown Schedule'
            }

            chipData.push({
                title: scheduleLabel,
                id: 'schedule-Chip',
                onClick: () => {
                    setFilter({ ...filter, schedule: -1 })
                },
            })
        }

        const setFilter = (filterState: WorkorderFilterState) => {
            options?.onClickHandler &&
                options.onClickHandler({ filterState: filterState })
            state.setWorkorderFilterState(filterState)
        }

        if (finderSelection.selection.unit.length > 0) {
            chipData.push({
                title: `${finderSelection.selection.unit.length} Units`,
                id: 'Unit-Chip',
                onClick: () => {
                    finderSelection.setFinderSelection()
                },
            })
        }

        if (filter.priority) {
            chipData.push({
                title: 'Priority',
                id: 'Priority-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        priority: false,
                    })
                },
            })
        }

        if (filter.hasUnread) {
            chipData.push({
                title: 'Unread Messages',
                id: 'UR-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        hasUnread: false,
                    })
                },
            })
        }

        if (filter.hasMessage) {
            chipData.push({
                title: 'Has Message',
                id: 'MSG-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        hasMessage: false,
                    })
                },
            })
        }

        if (filter.hasEditedServiceArea) {
            chipData.push({
                title: 'Edited Service Area',
                id: 'ESA-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        hasEditedServiceArea: false,
                    })
                },
            })
        }

        if (filter.isGhost) {
            chipData.push({
                title: 'Ghost',
                id: 'Ghost-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        isGhost: false,
                    })
                },
            })
        }

        if (filter.isBehind) {
            chipData.push({
                title: 'Behind',
                id: 'Behind-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        isBehind: false,
                    })
                },
            })
        }

        if (filter.hasChangeOrder) {
            chipData.push({
                title: 'Change Order',
                id: 'CO-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        hasChangeOrder: false,
                    })
                },
            })
        }

        if (filter.timeline.enabled) {
            const startLabel = toMMDDYYYY(filter.timeline.startDate)
            const endLabel = toMMDDYYYY(filter.timeline.endDate)
            chipData.push({
                title: `${startLabel} - ${endLabel}`,
                id: 'TL-Chip',
                onClick: () => {
                    setFilter({
                        ...filter,
                        timeline: {
                            ...filter.timeline,
                            enabled: false,
                        },
                    })
                },
            })
        }

        if (filter.vendorIds.length > 0) {
            filter.vendorIds.forEach((vId) => {
                const vendor = vendorList.find((v) => v.id === vId)
                if (vendor) {
                    chipData.push({
                        title: `${vendor.name}`,
                        id: `VND-${vendor.id}-Chip`,
                        onClick: () => {
                            const newVndIds = filter.vendorIds.filter(
                                (_vId) => _vId !== vId,
                            )
                            setFilter({
                                ...filter,
                                vendorIds: newVndIds,
                            })
                        },
                    })
                }
            })
        }

        if (filter.userIds.length > 0) {
            filter.userIds.forEach((uId) => {
                const user = userList.find((usr) => usr.id === uId)
                if (user) {
                    chipData.push({
                        title: `${user.name}`,
                        id: `USR-${user.id}-Chip`,
                        onClick: () => {
                            const newUsrs = filter.userIds.filter(
                                (_usrId) => _usrId !== uId,
                            )
                            setFilter({
                                ...filter,
                                userIds: newUsrs,
                            })
                        },
                    })
                }
            })
        }

        if (filter.serviceIds.length > 0) {
            filter.serviceIds.forEach((sId) => {
                const service = serviceList.find((s) => s.id === sId)
                if (service) {
                    chipData.push({
                        title: `${service.name}`,
                        id: `SRV-${service.id}-Chip`,
                        onClick: () => {
                            const newSrvIds = filter.serviceIds.filter(
                                (_sId) => _sId !== sId,
                            )
                            setFilter({
                                ...filter,
                                serviceIds: newSrvIds,
                            })
                        },
                    })
                }
            })
        }

        if (filter.unitTypeIds.length > 0) {
            filter.unitTypeIds.forEach((utId) => {
                const unitType = unitTypeList.find((ut) => ut.id === utId)
                if (unitType) {
                    chipData.push({
                        title: `${unitType.name}`,
                        id: `UT-${unitType.id}-Chip`,
                        onClick: () => {
                            const newUtIds = filter.unitTypeIds.filter(
                                (_utId) => _utId !== utId,
                            )
                            setFilter({
                                ...filter,
                                unitTypeIds: newUtIds,
                            })
                        },
                    })
                }
            })
        }

        const allStatuses = enumKeys(WorkorderStatus)

        allStatuses.forEach((key) => {
            if (filter.statusFilter[key]) {
                const title = getUiStatus(key as any)
                chipData.push({
                    title: `${title}`,
                    id: `${key}-Chip`,
                    onClick: () => {
                        setFilter({
                            ...filter,
                            statusFilter: {
                                ...filter.statusFilter,
                                [key]: false,
                            },
                        })
                    },
                })
            }
        })

        if (filter.workorderType !== WORKORDER_TYPE_ALL) {
            let label = 'Service Order'
            if (filter.workorderType === WORKORDER_TYPE_ACTION_ITEM) {
                label = 'Action Item'
            } else if (
                filter.workorderType === WORKORDER_TYPE_SERVICE_REQUEST
            ) {
                label = 'Service Request'
            }

            chipData.push({
                title: label,
                id: `Workorder-Type-Chip`,
                onClick: () => {
                    setFilter({
                        ...filter,
                        workorderType: WORKORDER_TYPE_ALL,
                    })
                },
            })
        }

        return chipData
    }

    // ********* Private state setting functions *********
    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue.id)
        })
    }

    return {
        // Workorders
        workorderList: state.workorderResponse,
        getWorkorderList: getWorkorderList,
        createWorkorders,
        filteredWorkorderList,
        updateWorkorderState,
        updateWorkorder,
        transitionWorkorder,
        superTransitionWorkorders,
        deleteWorkorder,
        bulkUpdateWorkorders,
        bulkDeleteWorkorders,
        updateWoWithNextAvailableDate,

        // Clusters
        workorderClusters: state.workorderClusters,
        getWorkorderClusters,
        getClusterGroups,

        // Frequencies
        frequencyList: state.frequencyList,
        getFrequencyList,
        createFrequency,
        updateFrequency,
        toggleFrequencyActive,

        // Triggers
        getInspectionTriggers,
        createInspectionTrigger,
        updateTrigger,
        triggerList: state.triggerList,

        // Filters
        workorderFilterState: state.workorderFilterState,
        setWorkorderFilterState: state.setWorkorderFilterState,
        getChipData,

        // Helpers
        loadingState: state.loadingState,
        clearState: clearState,
        finderFilterSelection: state.finderFilterSelection,
        finderCreateSelection: state.finderCreateSelection,

        // EZNow
        workorderSelectionMap: state.workorderSelectionMap,
        setWorkorderSelectionMap: state.setWorkorderSelectionMap,
    }
}

export const useWorkorderState = (): IWorkorderContext => {
    const [loadingState, setLoadingState] = useState<LoadingState>({
        // Workorder
        getWorkorderList: false,
        createWorkorder: false,
        updateWorkorder: false,
        transitionWorkorder: false,
        // Clusters
        getWorkorderClusters: false,
        // Frequencies
        getFrequencyList: false,
        createFrequency: false,
        updateFrequency: false,
        // Triggers
        getTriggerList: false,
        createTrigger: false,
        updateTrigger: false,
    })

    const [workorderList, setWorkorderList] = useState<
        WorkorderResponse[] | null
    >(null)

    const [workorderClusters, setWorkorderClusters] = useState<
        WorkorderCluster[] | null
    >(null)

    const [frequencyList, setFrequencyList] = useState<
        WorkorderFrequencyAutomation[] | null
    >(null)

    const [triggerList, setTriggerList] = useState<
        WorkorderAutomationConfig[] | null
    >(null)

    const finderFilterSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const finderCreateSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const [
        workorderFilterState,
        setWorkorderFilterState,
    ] = useState<WorkorderFilterState>(initialWorkorderFilterState)

    const [workorderSelectionMap, setWorkorderSelectionMap] = useState<
        ModelMap<WorkorderResponse>
    >({})

    return {
        workorderResponse: workorderList,
        setWorkorderResponse: setWorkorderList,
        loadingState,
        setLoadingState,
        workorderFilterState,
        setWorkorderFilterState,
        finderFilterSelection,
        finderCreateSelection,
        frequencyList,
        setFrequencyList,
        triggerList,
        setTriggerList,
        workorderClusters,
        setWorkorderClusters,
        workorderSelectionMap: workorderSelectionMap,
        setWorkorderSelectionMap: setWorkorderSelectionMap,
    }
}

export interface GetWorkOrderListRequest {
    params?: {
        portfolio?: boolean
        schedule?: number | string
        unit_name?: string
        service?: number
        vendor?: number
        apartment?: number
        folder?: number
        priority?: boolean
        is_ghost?: boolean
        is_behind?: boolean
        has_message?: boolean
        has_unread?: boolean
        has_change_order?: boolean
        upper_bound_date?: string
        lower_bound_date?: string
        workorder_type?: WorkorderType
        wo_status?: string
        // comma seperated id lists
        units?: string
        status_list?: string
        service_list?: string
        vendor_list?: string
        unit_type_list?: string
        user_list?: string
        id_list?: string
        all_jobsites?: boolean
        schedule_active?: boolean
        unassigned?: boolean
        behind_date_range_union?: boolean
        organization?: number
        apartment_id?: number
    }
}

interface LoadingState {
    // Workorders
    getWorkorderList: boolean
    createWorkorder: boolean
    updateWorkorder: boolean
    transitionWorkorder: boolean
    // Clusters
    getWorkorderClusters: boolean
    // Frequencies
    getFrequencyList: boolean
    createFrequency: boolean
    updateFrequency: boolean
    // Triggers
    getTriggerList: boolean
    createTrigger: boolean
    updateTrigger: boolean
}

export const WORKORDER_TYPE_ALL = 1
export const WORKORDER_TYPE_SERVICE_ORDER = 2
export const WORKORDER_TYPE_ACTION_ITEM = 3
export const WORKORDER_TYPE_SERVICE_REQUEST = 4

export interface WorkorderFilterState {
    timeline: {
        enabled: boolean
        startDate: Date
        endDate: Date
    }
    vendorIds: number[]
    userIds: number[]
    serviceIds: number[]
    unitTypeIds: number[]
    statusFilter: {
        [key in WorkorderStatus]: boolean
    }
    priority: boolean
    hasUnread: boolean
    hasMessage: boolean
    hasEditedServiceArea: boolean
    isGhost: boolean
    isBehind: boolean
    hasChangeOrder: boolean
    canBeEZNow: boolean
    workorderTypeFilter: WorkorderType | null
    unitSearch: string
    apartmentId: number
    isEZNow: boolean
    schedule: number
    workorderType:
        | typeof WORKORDER_TYPE_ALL
        | typeof WORKORDER_TYPE_SERVICE_ORDER
        | typeof WORKORDER_TYPE_ACTION_ITEM
        | typeof WORKORDER_TYPE_SERVICE_REQUEST
    scheduleActive: boolean
}

const initialWorkorderFilterState: WorkorderFilterState = {
    timeline: {
        enabled: false,
        startDate: new Date(),
        endDate: new Date(),
    },
    vendorIds: [],
    userIds: [],
    serviceIds: [],
    unitTypeIds: [],
    statusFilter: {
        ASSIGNED: false,
        IN_PROGRESS: false,
        PAUSE: false,
        COMPLETE: false,
        GO_BACK: false,
        APPROVED: false,
        PRE_APPROVED: false,
        INVOICED: false,
    },
    priority: false,
    hasUnread: false,
    hasMessage: false,
    hasEditedServiceArea: false,
    isGhost: false,
    isBehind: false,
    hasChangeOrder: false,
    canBeEZNow: false,
    workorderTypeFilter: null,
    unitSearch: '',
    apartmentId: -1,
    isEZNow: false,
    schedule: -1,
    workorderType: WORKORDER_TYPE_ALL,
    scheduleActive: false,
}

export interface CreateWorkorderAutomationRequest {
    start_date: string
    title: string
    period: PeriodOption | null
    service_id?: number
    assign_to_type: 'user' | 'vendor'
    assign_to_id: number
    days_to_complete: number
    priority: boolean
    unit_id?: number
    area_ids?: number[]
    inspection_subscription: {
        status_id: number
        inventroy_config_id: number
    } | null
    active?: boolean
}

export interface TransitionStatusDict {
    id: number
    status: string
    revert: boolean
}
