import React from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

type SearchFieldProps = TextFieldProps & {
    iconColor?: string
}

export const SearchField = ({ iconColor, ...props }: SearchFieldProps) => {
    return (
        <TextField
            {...props}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                ...props.InputProps,
                style: {
                    color: 'black',
                    ...props.InputProps?.style,
                },
            }}
            InputLabelProps={{
                ...props.InputLabelProps,
            }}
        />
    )
}
