import React, { PropsWithChildren } from 'react'

import { Checkbox, TableCell, TableRow, Theme } from '@material-ui/core'

import { Lease, getLeaseStatusString } from '../../../../models'
import { Container } from '../../../../components'
import { toMMDDYYYY } from '../../../../helpers'

interface Props {
    theme: Theme
    lease: Lease
    isChecked?: boolean
    disabled?: boolean
    onCheck?: (newValue: boolean) => void
}

export const LeaseRow = (props: PropsWithChildren<Props>) => {
    const { theme, lease, isChecked, disabled, onCheck } = props

    const startDate = new Date(lease.start_date)
    const endDate = new Date(lease.end_date)

    let statusLabel: string = lease.tenant_move_in_inspection?.status || ''
    if (statusLabel === 'COMPLETE') {
        if (lease.tenant_move_in_inspection?.auto_completed) {
            statusLabel = 'Auto Completed'
        }
    }

    return (
        <TableRow>
            {isChecked !== undefined && (
                <TableCell>
                    <Checkbox
                        checked={isChecked}
                        onChange={(_, checked) => {
                            if (!disabled) {
                                onCheck && onCheck(checked)
                            }
                        }}
                        disabled={disabled}
                    />
                </TableCell>
            )}

            <TableCell>
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {lease.user.name}
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[700],
                        }}
                    >
                        {lease.user.email}
                    </span>
                </Container>
            </TableCell>
            <TableCell>
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {lease.location?.unit_name}{' '}
                        {lease.location?.area_config_name}{' '}
                        {lease.location?.area_label}
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[700],
                        }}
                    >
                        {lease.location?.folder_path}{' '}
                        {lease.location?.folder_name}
                    </span>
                </Container>
            </TableCell>
            <TableCell>{toMMDDYYYY(startDate)}</TableCell>
            <TableCell>{toMMDDYYYY(endDate)}</TableCell>
            <TableCell>{getLeaseStatusString(lease.status)}</TableCell>
            <TableCell>{statusLabel}</TableCell>

            {props.children}
        </TableRow>
    )
}
