import { AxiosResponse } from 'axios'
import {
    AreaConfig,
    DamageConfig,
    IdCost,
    InventoryConfig,
    CustomStatus,
    StatusGroup,
    UnitConfig,
    InspectionType,
    AreaStatusConfig,
    ChangeOrderConfig,
    AreaType,
} from '../../models'
import { Action } from 'redux'
import { ReqIAC } from '../../containers/InfrastructureConfig/InvAreaConfigDrawer'

// Request
export interface GetAreaConfigListRequest {
    shows_on_schedule?: boolean
    minimal?: boolean
}

export interface GetUnitConfigListRequest {}

export interface GetDamageConfigListRequest {
    params?: {
        archived?: boolean
    }
}

export interface GetInventoryConfigListRequest {
    params?: {
        inspection_type?: number
        inspection?: number
    }
}

export interface CreateDamageConfigRequest {
    body: {
        description: string
        cost: number
        integration_id?: string | null
        charge_item_description?: string | null
    }
}

export interface UpdateDamageConfigRequest {
    id: number
    body: {
        description?: string
        cost?: number
        integration_id?: string | null
        charge_item_description?: string | null
    }
}

export interface ArchiveDamageConfigsRequest {
    damage_configs: number[]
    unarchive?: boolean
}

export interface GetStatusGroupListRequest {}

export interface RequestCustomStatus {
    //id is here if they want to use an exisitng status
    //our front end will not support this so id will always be -1
    id: number
    name: string
    color: string
}

export interface CreateStatusGroupRequest {
    name: string
    default_status?: RequestCustomStatus
    statuses?: RequestCustomStatus[]
}

export interface CreateInventoryConfigRequest {
    name: string
    sku?: string
    description?: string
    status_group?: number
    iacs?: ReqIAC[]
}

export interface GetStatusListRequest {}

export interface UpdateStatusGroupRequest {
    params: {
        id: number
    }
    body: {
        name?: string
        status_list?: RequestCustomStatus[]
        default_status?: RequestCustomStatus
        remove_default_status?: boolean
    }
}

export interface UpdateInventoryConfigRequest {
    id: number
    req: FormData
}

export interface InvConfigAreaRequest {
    id: number
    amount?: number
}

export interface CreateAreaConfigRequest {
    body: {
        name: string
        area_type?: AreaType
        inventory_configs?: InvConfigAreaRequest[]
        shows_on_schedule?: boolean
        inventory_groups?: number[]
    }
}

export interface UpdateAreaConfigRequest extends CreateAreaConfigRequest {
    params: {
        id: number
    }
}

export interface UpdateUnitConfigRequest {
    id: number
    body: {
        name?: string
        bedCount?: number
        bathCount?: number
        shows_on_schedule?: boolean
    }
}

export interface SetInventoryStatusCostRequest {
    params: {
        id: number
    }
    body: {
        status_cost_list: IdCost[]
    }
}

export interface CreateInspectionTypeRequest {
    body: {
        name: string
        inv_configs?: number[]
    }
}

export interface EditInspectionTypeRequest {
    id: number
    body: {
        name?: string
        inv_configs?: number[]
    }
}

export interface GetChangeOrderConfigRequest {
    params?: {
        jobsite?: number
    }
}

export interface CreateChangeOrderConfigRequestBody {
    name: string
    description: string
    addon: boolean
    suggested_add_price?: number
    upstream_price?: number
}
export interface CreateChangeOrderConfigRequest {
    body: CreateChangeOrderConfigRequestBody
}
export interface EditChangeOrderConfigRequest {
    id: number
    body: {
        name?: string
        description?: string
        addon?: boolean
        suggested_add_price?: number
        upstream_price?: number
    }
}

// Responses

// actions

export const GET_AREA_CONFIG_LIST_REQUEST = 'GET_AREA_CONFIG_LIST_REQUEST'
export const GET_AREA_CONFIG_LIST_RESPONSE = 'GET_AREA_CONFIG_LIST_RESPONSE'
export const GET_UNIT_CONFIG_LIST_REQUEST = 'GET_UNIT_CONFIG_LIST_REQUEST'
export const GET_UNIT_CONFIG_LIST_RESPONSE = 'GET_UNIT_CONFIG_LIST_RESPONSE'
export const GET_DAMAGE_CONFIG_LIST_REQUEST = 'GET_DAMAGE_CONFIG_LIST_REQUEST'
export const CREATE_DAMAGE_CONFIG_REQUEST = 'CREATE_DAMAGE_CONFIG_REQUEST'
export const CREATE_INVENTORY_CONFIG_REQUEST = 'CREATE_INVENTORY_CONFIG_REQUEST'
export const GET_STATUS_GROUP_LIST_REQUEST = 'GET_STATUS_GROUP_LIST_REQUEST'
export const CREATE_STATUS_GROUP_REQUEST = 'CREATE_STATUS_GROUP_REQUEST'
export const GET_STATUS_LIST_REQUEST = 'GET_STATUS_LIST_REQUEST'
export const UPDATE_INVENTORY_CONFIG_REQUEST = 'UPDATE_INVENTORY_CONFIG_REQUEST'
export const UPDATE_STATUS_GROUP_REQUEST = 'UPDATE_STATUS_GROUP_REQUEST'
export const CREATE_AREA_CONFIG_REQUEST = 'CREATE_AREA_CONFIG_REQUEST'
export const UPDATE_AREA_CONFIG_REQUEST = 'UPDATE_AREA_CONFIG_REQUEST'
export const UPDATE_UNIT_CONFIG_REQUEST = 'UPDATE_UNIT_CONFIG_REQUEST'
export const SET_INVENTORY_STATUS_COST_REQUEST =
    'SET_INVENTORY_STATUS_COST_REQUEST'
export const GET_AREA_STATUS_CONFIG_REQUEST = 'GET_AREA_STATUS_CONFIG_REQUEST'
export const CREATE_CHANGE_ORDER_CONFIG_REQUEST =
    'CREATE_CHANGE_ORDER_CONFIG_REQUEST'
export const GET_CHANGE_ORDER_CONFIG_LIST_REQUEST =
    'GET_CHANGE_ORDER_CONFIG_LIST_REQUEST'
export const EDIT_CHANGE_ORDER_CONFIG_REQUEST =
    'EDIT_CHANGE_ORDER_CONFIG_REQUEST'

export const SET_DAMAGE_CONFIG_LIST = 'SET_DAMAGE_CONFIG_LIST'
export const ADD_DAMAGE_CONFIG = 'ADD_DAMAGE_CONFIG'
export const UPDATE_DAMAGE_CONFIG_REQUET = 'UPDATE_DAMAGE_CONFIG_REQUET'
export const UPDATE_DAMAGE_CONFIG = 'UPDATE_DAMAGE_CONFIG'
export const GET_INVENTORY_CONFIG_LIST = 'GET_INVENTORY_CONFIG_LIST'
export const SET_INVENTORY_CONFIG_LIST = 'SET_INVENTORY_CONFIG_LIST'
export const ADD_INVENTORY_CONFIG = 'ADD_INVENTORY_CONFIG'
export const SET_STATUS_GROUP_LIST = 'SET_STATUS_GROUP_LIST'
export const ADD_STATUS_GROUP = 'ADD_STATUS_GROUP'
export const SET_STATUS_LIST = 'SET_STATUS_LIST'
export const UPDATE_INVENTORY_CONFIG = 'UPDATE_INVENTORY_CONFIG'
export const UPDATE_STATUS_GROUP = 'UPDATE_STATUS_GROUP'
export const ADD_AREA_CONFIG = 'ADD_AREA_CONFIG'
export const UPDATE_AREA_CONFIG = 'UPDATE_AREA_CONFIG'
export const UPDATE_UNIT_CONFIG = 'UPDATE_UNIT_CONFIG'
export const SET_AREA_STATUS_CONFIG_LIST = 'SET_AREA_STATUS_CONFIG_LIST'
export const ADD_CHANGE_ORDER_CONFIG = 'ADD_CHANGE_ORDER_CONFIG'
export const UPDATE_CHANGE_ORDER_CONFIG = 'UPDATE_CHANGE_ORDER_CONFIG'
export const SET_CHANGE_ORDER_CONFIG_LIST = 'SET_CHANGE_ORDER_CONFIG_LIST'

export const GET_INSPECTION_TYPE_LIST = 'GET_INSPECTION_TYPE_LIST'
export const SET_INSPECTION_TYPE_LIST = 'SET_INSPECTION_TYPE_LIST'
export const ADD_INSPECTION_TYPE = 'ADD_INSPECTION_TYPE'
export const CREATE_INSPECTION_TYPE = 'CREATE_INSPECTION_TYPE'
export const EDIT_INSPECTION_TYPE = 'EDIT_INSPECTION_TYPE'

export const SET_APT_CONFIG_LOADING = 'SET_APT_CONFIG_LOADING'

export type AptConfigRequest =
    | typeof GET_AREA_CONFIG_LIST_REQUEST
    | typeof GET_UNIT_CONFIG_LIST_REQUEST
    | typeof GET_DAMAGE_CONFIG_LIST_REQUEST
    | typeof CREATE_DAMAGE_CONFIG_REQUEST
    | typeof UPDATE_DAMAGE_CONFIG_REQUET
    | typeof GET_INVENTORY_CONFIG_LIST
    | typeof CREATE_INVENTORY_CONFIG_REQUEST
    | typeof GET_STATUS_GROUP_LIST_REQUEST
    | typeof CREATE_STATUS_GROUP_REQUEST
    | typeof GET_STATUS_LIST_REQUEST
    | typeof UPDATE_INVENTORY_CONFIG_REQUEST
    | typeof UPDATE_STATUS_GROUP_REQUEST
    | typeof CREATE_AREA_CONFIG_REQUEST
    | typeof UPDATE_AREA_CONFIG_REQUEST
    | typeof SET_INVENTORY_STATUS_COST_REQUEST
    | typeof GET_INSPECTION_TYPE_LIST
    | typeof CREATE_INSPECTION_TYPE
    | typeof EDIT_INSPECTION_TYPE
    | typeof GET_AREA_STATUS_CONFIG_REQUEST
    | typeof GET_CHANGE_ORDER_CONFIG_LIST_REQUEST
    | typeof CREATE_CHANGE_ORDER_CONFIG_REQUEST
    | typeof EDIT_CHANGE_ORDER_CONFIG_REQUEST
    | typeof UPDATE_UNIT_CONFIG_REQUEST

interface UpdateInventoryConfigAction extends Action {
    type: typeof UPDATE_INVENTORY_CONFIG
    inventoryConfig: InventoryConfig
}

interface AddStatusGroupAction extends Action {
    type: typeof ADD_STATUS_GROUP
    statusGroup: StatusGroup
}

interface SetInventoryConfigListAction extends Action {
    type: typeof SET_INVENTORY_CONFIG_LIST
    inventoryConfigList?: InventoryConfig[]
}

interface GetAreaConfigRequestAction extends Action {
    type: typeof GET_AREA_CONFIG_LIST_REQUEST
}

interface GetAreaConfigResponseAction extends Action {
    type: typeof GET_AREA_CONFIG_LIST_RESPONSE
    payload: AreaConfig[]
}

interface GetUnitConfigListRequestAction extends Action {
    type: typeof GET_UNIT_CONFIG_LIST_REQUEST
}

interface GetUnitConfigListResponseAction extends Action {
    type: typeof GET_UNIT_CONFIG_LIST_RESPONSE
    payload: UnitConfig[]
}

interface SetAptConfigLoadingAction extends Action {
    type: typeof SET_APT_CONFIG_LOADING
    request: AptConfigRequest
    isLoading: boolean
}

interface SetDamageConfigList extends Action {
    type: typeof SET_DAMAGE_CONFIG_LIST
    damageConfigList?: DamageConfig[]
}

interface AddDamageConfigAction extends Action {
    type: typeof ADD_DAMAGE_CONFIG
    damageConfig: DamageConfig
}

interface UpdateDamageConfigAction extends Action {
    type: typeof UPDATE_DAMAGE_CONFIG
    id: number
    damageConfig?: DamageConfig
}

interface AddInventoryConfigAction extends Action {
    type: typeof ADD_INVENTORY_CONFIG
    invConfig: InventoryConfig
}

interface SetStatusGroupListAction extends Action {
    type: typeof SET_STATUS_GROUP_LIST
    statusGroupList?: StatusGroup[]
}

interface SetStatusListAction extends Action {
    type: typeof SET_STATUS_LIST
    statusList?: CustomStatus[]
}

interface UpdateStatusGroupAction extends Action {
    type: typeof UPDATE_STATUS_GROUP
    statusGroup: StatusGroup
}

interface AddAreaConfigAction extends Action {
    type: typeof ADD_AREA_CONFIG
    areaConfig: AreaConfig
}

interface UpdateAreaConfigAction extends Action {
    type: typeof UPDATE_AREA_CONFIG
    areaConfig: AreaConfig
}

interface UpdateUnitConfigAction extends Action {
    type: typeof UPDATE_UNIT_CONFIG
    unitConfig: UnitConfig
}

interface SetInspectionTypeList extends Action {
    type: typeof SET_INSPECTION_TYPE_LIST
    inspectionTypeList?: InspectionType[]
}

interface AddInspectionType extends Action {
    type: typeof ADD_INSPECTION_TYPE
    inspectionType: InspectionType
}

interface EditInspectionType extends Action {
    type: typeof EDIT_INSPECTION_TYPE
    inspectionType: InspectionType
}

interface SetAreaStatusConfigList extends Action {
    type: typeof SET_AREA_STATUS_CONFIG_LIST
    areaStatusConfigList?: AreaStatusConfig[]
}

interface SetChangeOrderConfigList extends Action {
    type: typeof SET_CHANGE_ORDER_CONFIG_LIST
    changeOrderConfigList?: ChangeOrderConfig[]
}
interface AddChangeOrderConfig extends Action {
    type: typeof ADD_CHANGE_ORDER_CONFIG
    changeOrderConfig: ChangeOrderConfig
}
interface UpdateChangeOrderConfig extends Action {
    type: typeof UPDATE_CHANGE_ORDER_CONFIG
    changeOrderConfig: ChangeOrderConfig
}

type GetAreaConfigActionTypes =
    | GetAreaConfigRequestAction
    | GetAreaConfigResponseAction

type GetUnitConfigActionTypes =
    | GetUnitConfigListRequestAction
    | GetUnitConfigListResponseAction

export type AptConfigActionTypes =
    | GetAreaConfigActionTypes
    | GetUnitConfigActionTypes
    | SetAptConfigLoadingAction
    | SetDamageConfigList
    | AddDamageConfigAction
    | UpdateDamageConfigAction
    | SetInventoryConfigListAction
    | AddInventoryConfigAction
    | SetStatusGroupListAction
    | AddStatusGroupAction
    | SetStatusListAction
    | UpdateInventoryConfigAction
    | UpdateStatusGroupAction
    | AddAreaConfigAction
    | UpdateAreaConfigAction
    | SetInspectionTypeList
    | AddInspectionType
    | EditInspectionType
    | SetAreaStatusConfigList
    | SetChangeOrderConfigList
    | AddChangeOrderConfig
    | UpdateChangeOrderConfig
    | UpdateUnitConfigAction

// thunks
export type GetAreaConfigListActionThunk = Promise<AxiosResponse<AreaConfig[]>>
export type AreaConfigActionThunk = Promise<AxiosResponse<AreaConfig>>
export type GetUnitConfigListActionThunk = Promise<AxiosResponse<UnitConfig[]>>
export type UnitConfigThunk = Promise<AxiosResponse<UnitConfig>>
export type DamageConfigListThunk = Promise<AxiosResponse<DamageConfig[]>>
export type DamageConfigThunk = Promise<AxiosResponse<DamageConfig>>
export type InventoryConfigListThunk = Promise<AxiosResponse<InventoryConfig[]>>
export type InventoryConfigThunk = Promise<AxiosResponse<InventoryConfig>>
export type StatusGroupListThunk = Promise<AxiosResponse<StatusGroup[]>>
export type StatusGroupThunk = Promise<AxiosResponse<StatusGroup>>
export type StatusListThunk = Promise<AxiosResponse<CustomStatus[]>>
export type InspectionTypeThunk = Promise<AxiosResponse<InspectionType>>
export type AreaStatusConfigListThunk = Promise<
    AxiosResponse<AreaStatusConfig[]>
>
export type ChangeOrderConfigThunk = Promise<AxiosResponse<ChangeOrderConfig>>
export type ChangeOrderConfigListThunk = Promise<
    AxiosResponse<ChangeOrderConfig[]>
>

// reducers
export interface AptConfigState {
    isLoading: {
        [GET_AREA_CONFIG_LIST_REQUEST]: boolean
        [GET_UNIT_CONFIG_LIST_REQUEST]: boolean
        [GET_DAMAGE_CONFIG_LIST_REQUEST]: boolean
        [ADD_DAMAGE_CONFIG]: boolean
        [UPDATE_DAMAGE_CONFIG_REQUET]: boolean
        [GET_INVENTORY_CONFIG_LIST]: boolean
        [CREATE_INVENTORY_CONFIG_REQUEST]: boolean
        [GET_STATUS_GROUP_LIST_REQUEST]: boolean
        [CREATE_STATUS_GROUP_REQUEST]: boolean
        [GET_STATUS_LIST_REQUEST]: boolean
        [UPDATE_INVENTORY_CONFIG_REQUEST]: boolean
        [UPDATE_STATUS_GROUP_REQUEST]: boolean
        [CREATE_AREA_CONFIG_REQUEST]: boolean
        [UPDATE_AREA_CONFIG_REQUEST]: boolean
        [SET_INVENTORY_STATUS_COST_REQUEST]: boolean
        [GET_INSPECTION_TYPE_LIST]: boolean
        [CREATE_INSPECTION_TYPE]: boolean
        [EDIT_INSPECTION_TYPE]: boolean
        [GET_AREA_STATUS_CONFIG_REQUEST]: boolean
        [GET_CHANGE_ORDER_CONFIG_LIST_REQUEST]: boolean
        [EDIT_CHANGE_ORDER_CONFIG_REQUEST]: boolean
        [CREATE_CHANGE_ORDER_CONFIG_REQUEST]: boolean
        [UPDATE_UNIT_CONFIG_REQUEST]: boolean
    }
    areaConfigList: AreaConfig[]
    unitConfigList: UnitConfig[]
    damageConfigList?: DamageConfig[]
    inventoryConfigList?: InventoryConfig[]
    statusGroupList?: StatusGroup[]
    statusList?: CustomStatus[]
    inspectionTypeList?: InspectionType[]
    areaStatusConfigList?: AreaStatusConfig[]
    changeOrderConfigList?: ChangeOrderConfig[]
}
