import React from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Popover,
    PopoverPosition,
    Theme,
} from '@material-ui/core'

import { Container } from '../../../../components'
import { UpdateUserCalendarConfigRequest } from '../../../../hooks/useUserCalendarConfigs'
import { UserCalendarConfig } from '../../types'
import { DeleteForever } from '@material-ui/icons'
import { getCalendarEventDisplayName } from '../../../../models/Calendar/services'

interface Props {
    anchorPosition: PopoverPosition
    open: boolean
    theme: Theme
    userCalendarConfigs: UserCalendarConfig[]
    onClose: () => void
    editUserCalendarConfig: (
        data: UpdateUserCalendarConfigRequest,
    ) => Promise<UserCalendarConfig>
    deleteUserCalendarConfig: (id: number) => Promise<void>
}

export const EditCalendarEventPopover = (props: Props) => {
    const {
        open,
        anchorPosition,
        theme,
        userCalendarConfigs,
        onClose,
        editUserCalendarConfig,
        deleteUserCalendarConfig,
    } = props

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    width: 400,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Edit Event Type Configurations
                </span>
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    {userCalendarConfigs.map((config) => (
                        <Container
                            key={config.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                }}
                            >
                                {getCalendarEventDisplayName(config.event_type)}
                            </span>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={config.auto_display}
                                        onChange={() => {
                                            editUserCalendarConfig({
                                                id: config.id,
                                                auto_display: !config.auto_display,
                                            }).catch((e) =>
                                                toast.error(
                                                    e.response.data.message,
                                                ),
                                            )
                                        }}
                                    />
                                }
                                label="Display on load"
                            />
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                color="secondary"
                                onClick={() =>
                                    deleteUserCalendarConfig(config.id)
                                        .then(() =>
                                            toast.success(
                                                'Event type configuration deleted',
                                            ),
                                        )
                                        .catch((e) =>
                                            toast.error(
                                                e.response.data.message,
                                            ),
                                        )
                                }
                            >
                                <DeleteForever />
                            </IconButton>
                        </Container>
                    ))}
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Container>
            </Container>
        </Popover>
    )
}
