import { IdentifiableNamedObject } from '../types'

export interface SectionType {
    id: number
    name: string
    created_date: string
}

export interface ClassifiedSection {
    id: number
    section_index: number
    classification: number
    start_page: number
    end_page: number
    created_date: string
    section_type: SectionType
}

export interface Page {
    id: number
    page: number
}

export interface LogCategory {
    id: number
    name: string
}

export interface PipelineLog {
    id: number
    created_date: string
    log_text: string
    log_level: 'INFO' | 'ERROR' | 'WARNING' | 'DEBUG'
    log_category: LogCategory
}
export type EntityClassification =
    | 'resident'
    | 'guarantor'
    | 'approved_date'
    | 'balance'
    | 'bldg_unit'
    | 'completed_date'
    | 'employee_discount'
    | 'guarantor_waiver_fee'
    | 'guarantor_email'
    | 'late_charges'
    | 'lease_end'
    | 'lease_start'
    | 'lease_status'
    | 'lease_term'
    | 'lease_term_name'
    | 'liability_insurance'
    | 'market_rent'
    | 'parking_fee'
    | 'pet_rent'
    | 'reletting_charges'
    | 'rent_concessions'
    | 'short_term_fee'
    | 'square_footage'
    | 'unit_status'
    | 'unit_type'
    | 'utility_trash'
    | 'total_rent'
    | 'monthly_rent'
    | 'installments'
    | 'security_deposit'
    | 'renters_insurance_required'
    | 'personal_liability_required'
    | 'renters_insurance_provider'
    | 'required_policy_amount'
    | 'monthly_cost_of_insurance'
    | 'lease_contract_date'
    | 'animals_approved'
    | 'animal_deposit'
    | 'additional_fees'
    | 'pet_1_type'
    | 'pet_1_breed'
    | 'pet_1_weight'
    | 'pet_1_age'
    | 'pet_1_house_broken'
    | 'pet_2_type'
    | 'pet_2_breed'
    | 'pet_2_weight'
    | 'pet_2_age'
    | 'pet_2_house_broken'
    | 'pet_3_type'
    | 'pet_3_breed'
    | 'pet_3_weight'
    | 'pet_3_age'
    | 'pet_3_house_broken'
    | 'utility_electricity'
    | 'amenity_fee'

export type RentRollAccountingMode =
    | 'RENT_ROLL_SCHEDULED'
    | 'RENT_ROLL_ACTUAL'
    | 'RENT_ROLL_POSTED'
    | 'RENT_ROLL'
    | 'LEASE'

export interface EntityValue {
    id: number
    value: string | null
    value_type: RentRollAccountingMode
    created_date: string
    classification: EntityClassification
    document: AuditDocument
    classified_section: ClassifiedSection | null
    page_number: number | null
}

export interface ResidentDirectoryRentRoll {
    id: number
    customer: Customer
    lease_start: string
    lease_end: string
    bldg_unit: string
    documents: AuditDocumentDetail[]
    has_lease: boolean
}

export type LeaseAuditStatus =
    | 'REQUESTED'
    | 'SETUP'
    | 'WAITING_FOR_APPROVAL'
    | 'RUNNING_PIPELINE'
    | 'COMPLETED'
    | 'APPROVED'
    | 'REJECTED'

export interface LeaseAudit {
    id: number
    name: string
    status: LeaseAuditStatus
    apartment: IdentifiableNamedObject
    created_date: string
    created_by: IdentifiableNamedObject
    criteria_group: number
    page_count: number
    document_count: number
}

export type DocumentType = 'PDF' | 'EXCEL' | 'UNKNOWN'

export interface AuditDocument {
    id: number
    created_date: string
    status: string
    apartment: number

    file: string
    file_sha_256: string
    file_name: string
    document_type: DocumentType
    document_batch: number
}

export const PENDING = 1
export const IN_PROGRESS = 2
export const COMPLETED = 3
export const FAILED = 4

export const INITIAL = 1
export const CREATE_PAGES = 2
export const GENERATE_MARKDOWN = 3
export const CLASSIFY_PAGES = 4
export const EXTRACT_DATA = 5
export const GROUP_BY_TERM = 6

export type PipelineJobStatus =
    | typeof PENDING
    | typeof IN_PROGRESS
    | typeof COMPLETED
    | typeof FAILED
export type PipelineJobStage =
    | typeof INITIAL
    | typeof CREATE_PAGES
    | typeof GENERATE_MARKDOWN
    | typeof CLASSIFY_PAGES
    | typeof EXTRACT_DATA
    | typeof GROUP_BY_TERM

export interface PipelineJob {
    id: number
    status: PipelineJobStatus
    stage: PipelineJobStage
}

export interface PipelinePageTicket {
    id: number
    page: number
    status: PipelineJobStatus
    jobs: PipelineJob[]
}

export interface PipelineDocumentTicket {
    id: number
    page: number
    status: PipelineJobStatus
    jobs: PipelineJob[]
    subscribed_pages: PipelinePageTicket[]
}

export interface AuditDocumentDetail extends AuditDocument {
    entity_values: EntityValue[]
    active_ticket: PipelineDocumentTicket | null
}

export interface DocumentOccurance {
    id: number
    document: AuditDocumentDetail
    document_batch: number
    lease_audit: number
    presigned_url?: string
}

export interface DocumentBatch {
    id: number
    created_date: string
    created_by: IdentifiableNamedObject
    document_occurances: DocumentOccurance[]
}

export interface DocumentPage {
    id: number
    page_number: number
    document: number
    page: number
}

export interface Customer {
    id: number
    name: string
    created_date: string
    email: string | null
    phone: string | null
}

export interface ResidentTermDirectory {
    id: number
    created_date: string
    customer: Customer
    lease_start: string
    lease_end: string
    paired_suggestion: boolean // True if paired using suggested pairs
}

export interface ResidentTermDirectoryBaseDocument {
    id: number
    created_date: string
    customer: Customer
    lease_start: string
    lease_end: string
    documents: AuditDocument[]
}

export interface ResidentTermDirectoryDetail extends ResidentTermDirectory {
    documents: AuditDocumentDetail[]
}

export type DocumentFilter = 'paired' | 'unpaired' | 'all'

export type MatchType = 'NOT_REQUIRED' | 'ANY_REQUIRED' | 'SIGNED_REQUIRED'

export type MatchCriteria = {
    id: number
    name: string
    match_type: MatchType
    criteria_group: number
    entity_classification: EntityClassification
}

export type CriteriaGroup = {
    id: number
    name: string
    created_date: string
    modified_date: string
    match_criteria: MatchCriteria[]
}

export type UnpairedResidentTermDirectoryResponse = {
    unpaired_rent_rolls: ResidentTermDirectoryBaseDocument[]
    unpaired_leases: ResidentTermDirectoryBaseDocument[]
}

export const PRICE_PER_PAGE = 0.04

export type PairingSuggestionResults = {
    rent_roll: ResidentTermDirectory
    leases: ResidentTermDirectory[]
}
export interface PairingSuggestion {
    lease_start: PairingSuggestionResults[]
    spelling: PairingSuggestionResults[]
    additional_term: PairingSuggestionResults[]
}

export type SuggestedPair = {
    keep_id: number
    remove_id: number
}

export type PageWithMarkdown = Page & {
    markdown_text: Markdown | null
}

export type Markdown = {
    id: number
    markdown_text: string
}
