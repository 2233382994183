import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    CircularProgress,
    useTheme,
    TextField,
    Modal,
    Slide,
    Paper,
    Button,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import { Container, SideDrawerContainer } from '../../components'
import { usePagination } from '../../hooks'
import { GET_INVENTORY_CONFIG_LIST, RootState } from '../../store'
import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { InventoryConfigRow } from './InventoryConfigRow'
import {
    AreaConfig,
    DamageConfig,
    InventoryConfig,
    StatusGroup,
} from '../../models'
import { InvConfigStatusCostDrawer } from '../InventoryConfig'
import { InvAreaConfigDrawer } from './InvAreaConfigDrawer'
import { IssueOptionsDrawer } from './IssueOptionsDrawer'
import {
    DrawerState,
    FORM_CLOSED,
    INVENTORY_CONFIG,
    ISSUE_CONFIG,
} from './InfrastructureConfig'
import { NewInvItemForm } from '../Inspection/InspectionConfiguration/NewInvItemForm'

const closedDrawer: DrawerState = { formOpen: FORM_CLOSED }

interface Props {
    inventoryConfigList: InventoryConfig[]
    areaConfigList: AreaConfig[]
    statusGroupList: StatusGroup[]
    damageConfigList: DamageConfig[]
}

export const InventoryConfigPage = (props: Props) => {
    const {
        inventoryConfigList,
        areaConfigList,
        statusGroupList,
        damageConfigList,
    } = props

    const theme = useTheme()

    const styles = useInfrastructureConfigStyles()

    const inventoryConfigLoading = useSelector(
        (state: RootState) =>
            state.aptConfig.isLoading[GET_INVENTORY_CONFIG_LIST],
    )

    const [searchValue, setSearchValue] = useState('')

    const [modalOpen, setModalOpen] = useState(false)
    const [openCostDrawer, setOpenCostDrawer] = useState<
        InventoryConfig | undefined
    >(undefined)

    const [drawerOpen, setDrawerOpen] = useState(closedDrawer)

    const [formPage, setFormPage] = useState(1)

    const handeInfrastructureDrawerChange = (drawerState: DrawerState) => {
        // 1. close the cost drawer and main drawer
        setOpenCostDrawer(undefined)
        setDrawerOpen(closedDrawer)
        // 2. close any open modals
        setModalOpen(false)
    }

    const handleDrawerChange = (drawer?: DrawerState) => {
        // 1. close the cost drawer
        setOpenCostDrawer(undefined)

        // 2. close any open modals
        setModalOpen(false)

        // set the drawer
        if (drawer === undefined) {
            setDrawerOpen(closedDrawer)
        } else {
            setDrawerOpen(drawer)
        }
    }

    const handleCostDrawerChange = (
        selectedInvConfig: InventoryConfig | undefined,
    ) => {
        // 1. close primary drawer
        setDrawerOpen(closedDrawer)

        // 2. close any open modals
        setModalOpen(false)

        // set the inventory cost drawer
        setOpenCostDrawer(selectedInvConfig)
    }

    const filteredInventoryConfigList = useMemo(() => {
        return inventoryConfigList?.filter(
            (invConf) =>
                invConf.name
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()) ||
                invConf.sku
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()),
        )
    }, [inventoryConfigList, searchValue])

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        filteredInventoryConfigList ?? [],
    )

    return (
        <SideDrawerContainer
            open={
                drawerOpen.selected !== undefined ||
                openCostDrawer !== undefined
            }
        >
            <Container style={{ flex: 1, height: 'calc(100vh - 104px)' }}>
                <Container
                    style={{
                        ...styles.columnStyle,
                        marginRight: theme.spacing(1),
                    }}
                >
                    <Container style={{ alignItems: 'center', height: 50 }}>
                        <TextField
                            value={searchValue}
                            placeholder="Search"
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ width: 300, marginLeft: theme.spacing(1) }}
                        />

                        <div style={{ flex: 1 }} />

                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(3, 0, 3, 2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => setModalOpen(true)}
                        >
                            {`+ Add New Item`}
                        </Button>
                    </Container>

                    {inventoryConfigLoading && (
                        <CircularProgress
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'center',
                            }}
                            size={100}
                        />
                    )}
                    <Container
                        style={{
                            ...styles.columnStyle,
                            maxHeight: 'calc(100vh - 250px)',
                            minHeight: 'calc(100vh - 250px)',
                            overflowY: 'auto',
                        }}
                    >
                        {pageData.map((invConf, idx) => {
                            return (
                                <InventoryConfigRow
                                    key={`INVENTORY_CONFIG_ROW_${invConf.id}`}
                                    inventoryConfig={invConf}
                                    selected={
                                        (drawerOpen.formOpen ===
                                            INVENTORY_CONFIG &&
                                            drawerOpen.selected?.id ===
                                                invConf.id) ||
                                        openCostDrawer?.id === invConf.id
                                    }
                                    statusGroupList={statusGroupList}
                                    onClickCost={handleCostDrawerChange}
                                    onClickIssue={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen === ISSUE_CONFIG
                                        ) {
                                            handleDrawerChange(closedDrawer)
                                        } else {
                                            handleDrawerChange({
                                                formOpen: ISSUE_CONFIG,
                                                selected: invConf,
                                            })
                                        }
                                    }}
                                    onClickTree={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen ===
                                                INVENTORY_CONFIG
                                        ) {
                                            handleDrawerChange(closedDrawer)
                                        } else {
                                            handleDrawerChange({
                                                formOpen: INVENTORY_CONFIG,
                                                selected: invConf,
                                            })
                                        }
                                    }}
                                    onClickInfrastructure={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen ===
                                                INVENTORY_CONFIG
                                        ) {
                                            handeInfrastructureDrawerChange(
                                                closedDrawer,
                                            )
                                        } else {
                                            handeInfrastructureDrawerChange({
                                                formOpen: INVENTORY_CONFIG,
                                                selected: invConf,
                                            })
                                        }
                                    }}
                                />
                            )
                        })}
                    </Container>
                    <Container
                        style={{
                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                            padding: theme.spacing(1),
                            minHeight: 50,
                            maxHeight: 50,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            count={numPages}
                            page={page}
                            onChange={(_: unknown, newPage: number) => {
                                setPage(newPage)
                            }}
                        />
                        <span>
                            {start} - {end} of{' '}
                            {filteredInventoryConfigList.length}
                        </span>
                    </Container>
                </Container>

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={modalOpen}>
                        <Paper
                            elevation={0}
                            style={{
                                flex: 1,
                                padding: theme.spacing(2),
                                display: 'flex',
                                overflow: 'auto',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                outline: 'none',
                                borderRadius: '20px',
                                width: 'calc(100vw - 400px)',
                                height: 'calc(100vh - 100px)',
                                maxHeight: 'calc(100vh - 100px)',
                                maxWidth: '1200px',
                            }}
                        >
                            <NewInvItemForm
                                subHeaderStyle={styles.subHeaderStyle}
                                invItemsMap={{}}
                                formPage={formPage}
                                setInvItemsMap={() => {}}
                                setFormPage={setFormPage}
                                largerView={true}
                                handleClose={() => setModalOpen(false)}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            </Container>

            <InvAreaConfigDrawer
                handleDrawerChange={handleDrawerChange}
                areaConfigList={areaConfigList}
                inventoryConfigList={inventoryConfigList ?? []}
                open={drawerOpen}
            />

            <InvConfigStatusCostDrawer
                open={openCostDrawer !== undefined}
                onClose={() => handleCostDrawerChange(undefined)}
                invConf={openCostDrawer}
            />

            <IssueOptionsDrawer
                open={drawerOpen}
                handleDrawerChange={handleDrawerChange}
                damageConfigList={damageConfigList ?? []}
            />
        </SideDrawerContainer>
    )
}
