import React from 'react'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { CalendarDayOff } from '../../../../../models/Calendar'

interface Props extends BaseProps {
    dayOff: CalendarDayOff
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const EventTypeDayOff = (props: Props) => {
    const { theme, dayOff, onClick } = props

    return (
        <Container
            style={{ alignItems: 'center', width: '100%' }}
            onClick={onClick}
        >
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightMedium,
                }}
            >
                {dayOff.company_name}
            </span>
        </Container>
    )
}
