import React from 'react'

import { IconButton, Tooltip, useTheme } from '@material-ui/core'
import ApplyIcon from '@material-ui/icons/Check'

import { Company, ListVendor } from '../../../models'
import { Container, Selector } from '../../../components'
import { useMultiSelectStyles } from '../../../styles'

interface Props {
    vendorList: ListVendor[]
    propertyList: Company[]
    selectedVendors: number[]
    selectedProperty: number
    refreshWorkorders: () => void
    setSelectedVendors: (selected: number[]) => void
    setSelectedProperty: (selected: number) => void
}

const VendorAndPropertySelect: React.FC<Props> = ({
    vendorList,
    propertyList,
    selectedVendors,
    selectedProperty,
    refreshWorkorders,
    setSelectedVendors,
    setSelectedProperty,
}) => {
    const theme = useTheme()

    const classes = useMultiSelectStyles()

    const renderVendorValue = (selected: unknown) => {
        const count = (selected as number[]).length

        return (
            <div
                className={classes.chips}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {`${count} vendor(s) selected`}
            </div>
        )
    }

    const allPropertiesOption = { id: -1, name: 'All Properties' }

    return (
        <Container style={{ alignItems: 'center' }}>
            <Selector
                customStyle={{
                    formControl: {
                        minWidth: 275,
                    },
                }}
                data={vendorList}
                label="Vendors"
                onChange={(e) => {
                    const selection: number[] = e.target.value as any
                    setSelectedVendors(selection)
                }}
                currentValue={selectedVendors}
                getDisplayString={(v) => {
                    return v.name
                }}
                customRenderValue={renderVendorValue}
                size="small"
                maxItems={20}
                multiple
                searchable
            />

            <Selector
                customStyle={{
                    formControl: {
                        minWidth: 275,
                        marginLeft: theme.spacing(2),
                    },
                }}
                data={[allPropertiesOption, ...propertyList]}
                label="Property"
                onChange={(e) => {
                    const selection: number = e.target.value as any
                    setSelectedProperty(selection)
                }}
                currentValue={selectedProperty ?? -1}
                getDisplayString={(v) => {
                    return v.name
                }}
                size="small"
                maxItems={20}
                searchable
            />

            <Tooltip title="Apply">
                <IconButton
                    color="primary"
                    onClick={refreshWorkorders}
                    style={{
                        marginLeft: theme.spacing(2),
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: 50,
                    }}
                    size="small"
                >
                    <ApplyIcon />
                </IconButton>
            </Tooltip>
        </Container>
    )
}

export default VendorAndPropertySelect
