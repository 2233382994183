import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Popover,
    PopoverPosition,
    Select,
    Theme,
} from '@material-ui/core'

import { Container } from '../../../../components'
import { CreateUserCalendarConfigRequest } from '../../../../hooks/useUserCalendarConfigs'
import {
    CalendarEventType,
    eventTypeOptions,
    UserCalendarConfig,
} from '../../types'
import { useMultiSelectStyles } from '../../../../styles'

interface Props {
    anchorPosition: PopoverPosition
    open: boolean
    theme: Theme
    userCalendarConfigs: UserCalendarConfig[]
    onClose: () => void
    createUserCalendarConfig: (
        data: CreateUserCalendarConfigRequest,
    ) => Promise<UserCalendarConfig>
}

export const AddCalendarEventPopover = (props: Props) => {
    const {
        open,
        anchorPosition,
        theme,
        userCalendarConfigs,
        onClose,
        createUserCalendarConfig,
    } = props

    const [
        selectedEventType,
        setSelectedEventType,
    ] = useState<CalendarEventType | null>(null)

    const [autoDisplay, setAutoDisplay] = useState<boolean>(true)

    const classes = useMultiSelectStyles()
    const availableEventTypes = eventTypeOptions.filter(
        (option) =>
            !userCalendarConfigs.some(
                (config) => config.event_type === option.be,
            ),
    )

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 300,
                    maxWidth: 300,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Add Event Type
                </span>
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    <FormControl
                        className={classes.formControl}
                        variant="outlined"
                    >
                        <InputLabel id="EVENT_TYPE_LABEL">
                            Event Type
                        </InputLabel>
                        <Select
                            labelId="EVENT_TYPE_LABEL"
                            id="event_type_label"
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setSelectedEventType(
                                    event.target.value as CalendarEventType,
                                )
                            }}
                            value={selectedEventType}
                            label="Event Type"
                            style={{
                                width: '270px',
                            }}
                        >
                            {availableEventTypes.map((option) => {
                                return (
                                    <MenuItem
                                        key={`EVENT_TYPE_${option.be}`}
                                        value={option.be}
                                    >
                                        {option.d}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={autoDisplay}
                                onChange={() => setAutoDisplay(!autoDisplay)}
                            />
                        }
                        label="Display on load"
                        style={{ marginTop: theme.spacing(2) }}
                    />
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={() => {
                            if (selectedEventType) {
                                const request: CreateUserCalendarConfigRequest = {
                                    event_type: selectedEventType,
                                    auto_display: autoDisplay,
                                }
                                createUserCalendarConfig(request)
                                    .then(() => {
                                        toast.success(
                                            'Event type added successfully',
                                        )
                                        onClose()
                                    })
                                    .catch((e) => {
                                        toast.error(e.response.data.message)
                                    })
                            }
                        }}
                        disabled={!selectedEventType}
                    >
                        Save
                    </Button>
                </Container>
            </Container>
        </Popover>
    )
}
