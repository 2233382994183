import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
    Button,
    CircularProgress,
    Popover,
    PopoverPosition,
    Tooltip,
    useTheme,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { OnCallDay, User } from '../../models'
import { Container, Selector } from '../../components'
import { CreateOnCallDayRequest } from '../../hooks/useOnCallSchedule'

interface Props {
    users: User[]
    selectedDates: Record<string, boolean>
    onCallDays: OnCallDay[]
    anchorPosition: PopoverPosition
    open: boolean
    onClose: () => void
    createOnCallDays: (request: CreateOnCallDayRequest) => Promise<void>
    resetSelectedDates: () => void
    removeDate: (date: string) => void
}

export const AddOnCallDaysPopover = (props: Props) => {
    const {
        users,
        selectedDates,
        onCallDays,
        anchorPosition,
        open,
        onClose,
        createOnCallDays,
        resetSelectedDates,
        removeDate,
    } = props

    const theme = useTheme()

    const [selectedUserId, setSelectedUserId] = useState<number>(-1)
    const [loading, setLoading] = useState(false)

    const selectedDateList = Object.keys(selectedDates)

    const conflictingDates = selectedDateList.filter((date) =>
        onCallDays.some((onCallDay) => onCallDay.on_call_date === date),
    )

    const sortedDateList = selectedDateList.sort((a, b) => {
        const isAConflicting = conflictingDates.includes(a)
        const isBConflicting = conflictingDates.includes(b)
        if (isAConflicting && !isBConflicting) return -1
        if (!isAConflicting && isBConflicting) return 1
        return new Date(a).getTime() - new Date(b).getTime()
    })

    const disableSave =
        conflictingDates.length > 0 ||
        selectedDateList.length === 0 ||
        selectedUserId === -1

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 300,
                    maxWidth: 500,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Add On Call Days
                </span>
                <Selector
                    label="User"
                    data={users}
                    searchable
                    currentValue={selectedUserId ?? -1}
                    onChange={(e) => {
                        setSelectedUserId(e.target.value as number)
                    }}
                    getDisplayString={(user) => user.name}
                    customStyle={{
                        formControl: { margin: theme.spacing(2, 1, 2, 1) },
                    }}
                />
                <Container
                    style={{
                        padding: theme.spacing(1, 2, 1, 2),
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Selected Dates:
                    </span>
                    <List style={{ maxHeight: 300, overflowY: 'auto' }}>
                        {sortedDateList.map((date) => {
                            const isConflicting = conflictingDates.includes(
                                date,
                            )
                            return (
                                <ListItem key={date}>
                                    <Tooltip
                                        title={
                                            isConflicting
                                                ? 'Someone has already been assigned this date. Remove to continue'
                                                : ''
                                        }
                                    >
                                        <ListItemText
                                            primary={date}
                                            primaryTypographyProps={{
                                                style: {
                                                    color: isConflicting
                                                        ? theme.palette.error
                                                              .main
                                                        : 'inherit',
                                                    fontWeight: isConflicting
                                                        ? theme.typography
                                                              .fontWeightBold
                                                        : 'inherit',
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeDate(date)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: 75,
                            }}
                            onClick={() => {
                                setLoading(true)
                                const request: CreateOnCallDayRequest = {
                                    user: selectedUserId,
                                    dates: selectedDateList,
                                }
                                createOnCallDays(request)
                                    .then(() => {
                                        toast.success(
                                            'On call days added successfully',
                                        )
                                        resetSelectedDates()
                                        onClose()
                                    })
                                    .catch((e) => {
                                        toast.error(e.response.data.message)
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                            disabled={disableSave}
                        >
                            Save
                        </Button>
                    )}
                </Container>
            </Container>
        </Popover>
    )
}
