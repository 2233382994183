import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Theme } from '@material-ui/core'

import { ServiceRequest } from '../../../models'
import { toMMDDYYYY } from '../../../helpers'
import { Attachment, Container } from '../../../components'

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        margin: theme.spacing(2),
        minHeight: 180,
        maxHeight: 180,
        overflow: 'auto',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
        '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        },
        cursor: 'pointer',
    },
    selected: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[300],
    },
    content: {
        flex: 1,
    },
    location: {
        marginBottom: theme.spacing(1),
    },
    tenant: {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1),
    },
}))

interface Props {
    serviceRequest: ServiceRequest
    theme: Theme
    isSelected: boolean
    onClick: () => void
}

export const ServiceRequestCard = (props: Props) => {
    const { serviceRequest, theme, isSelected, onClick } = props

    const classes = useStyles()

    return (
        <Card
            className={`${classes.root} ${isSelected ? classes.selected : ''}`}
            onClick={onClick}
        >
            <Container>
                <Container style={{ flex: 1 }}>
                    <CardContent className={classes.content}>
                        <span className={classes.tenant}>
                            {serviceRequest.tenant}
                        </span>
                        <Container
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                {serviceRequest.path}
                                {serviceRequest.folder_name}
                            </Typography>

                            <Typography
                                variant="h6"
                                style={{
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {serviceRequest.unit_name ?? ''}
                            </Typography>
                        </Container>
                        <Typography variant="subtitle2">
                            {toMMDDYYYY(new Date(serviceRequest.created_date))}
                        </Typography>
                        <Typography variant="body1" component="p">
                            {serviceRequest.description}
                        </Typography>
                    </CardContent>
                </Container>
                {serviceRequest.image && (
                    <Container style={{ padding: theme.spacing(1, 2, 1, 2) }}>
                        <Attachment
                            attachment={serviceRequest.image}
                            uploaded
                            theme={theme}
                            size={150}
                        />
                    </Container>
                )}
            </Container>
        </Card>
    )
}
