import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    PopoverPosition,
    useTheme,
} from '@material-ui/core'

import { CalendarDayOff } from '../../models/Calendar'
import { Container } from '../../components'
import { toMMDDYYYY } from '../../helpers'

interface Props {
    dayOff: CalendarDayOff
    anchorPosition: PopoverPosition
    open: boolean
    onClose: () => void
    deleteDayOff: (id: number) => Promise<void>
}

export const DayOffDetailPopover = (props: Props) => {
    const { dayOff, anchorPosition, open, onClose, deleteDayOff } = props

    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        deleteDayOff(dayOff.id)
            .then(() => {
                toast.success('Day Off deleted successfully!')
                onClose()
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ boxShadow: theme.shadows[5] }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 300,
                    maxWidth: 500,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(1, 2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Day Off
                </span>
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        {dayOff.company_name}
                    </span>
                    <span style={{ ...theme.typography.subtitle2 }}>
                        {toMMDDYYYY(new Date(dayOff.date))}
                    </span>
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={() => setOpenDialog(true)}
                    >
                        Delete
                    </Button>
                </Container>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Delete Day Off
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Day Off?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleDelete}
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Popover>
    )
}
