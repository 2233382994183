import { useState, useRef, useCallback } from 'react'
import { CalendarGridController } from './useCalendarGrid'
import { setEndOfDay, setMidnight } from '../../../helpers'

type CursorStartPoint = 'start_date' | 'end_date'

export interface CursorState {
    startPoint: CursorStartPoint
    hoverMidnightMs: number
    startMidnightMs: number
}

export const useDragDateSelector = (
    gridState: CalendarGridController,
    onSubmit?: (startPoint: CursorStartPoint, selectedDate: Date) => void,
) => {
    const cursorState = useRef<CursorState | null>(null)
    const [
        dateChangeRenderState,
        setDateChangeRenderState,
    ] = useState<CursorState | null>(null)

    const handleMouseMove = useCallback(
        (moveEv: MouseEvent) => {
            if (!cursorState.current) return

            const gridPos = gridState.getClickedGridPosition(moveEv.pageX)
            const cursorDate = gridState.convertGridPositionToDate(gridPos)
            setMidnight(cursorDate)

            const cursorMidnightMs = cursorDate.getTime()
            const newCursorState = {
                ...cursorState.current,
                hoverMidnightMs: cursorMidnightMs,
            }

            if (cursorState.current.hoverMidnightMs !== cursorMidnightMs) {
                setDateChangeRenderState(newCursorState)
            }

            cursorState.current = newCursorState
        },
        [gridState],
    )

    const handleMouseUp = useCallback(
        (ev: MouseEvent) => {
            if (cursorState.current) {
                const updatedDate = new Date(
                    cursorState.current.hoverMidnightMs,
                )

                if (cursorState.current.startPoint === 'end_date') {
                    setEndOfDay(updatedDate)
                }

                onSubmit?.(cursorState.current.startPoint, updatedDate)

                // Clean up listeners
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }

            cursorState.current = null
            setDateChangeRenderState(null)
        },
        [handleMouseMove, onSubmit],
    )

    const handleMouseDown = useCallback(
        (
            startMidnightMs: number,
            hoverMidnightMs: number,
            startPoint: CursorStartPoint,
        ) => {
            cursorState.current = {
                startMidnightMs,
                hoverMidnightMs,
                startPoint,
            }

            // Add listeners when drag starts
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)
        },
        [handleMouseMove, handleMouseUp],
    )

    return {
        dateChangeRenderState,
        handleMouseDown,
    }
}
