import React, { useCallback, useState } from 'react'

import {
    CircularProgress,
    FormControlLabel,
    Modal,
    Paper,
    Slide,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'

import { Container } from '../../components'
import {
    InspectionType,
    ListVendor,
    MoveOutRule,
    Service,
    User,
} from '../../models'
import { MoveOutRuleList } from '../MoveOutRule/MoveOutRuleList'
import {
    CreateMoveOutRuleRequest,
    CreateOrUpdateMoveOutStepRequest,
    CreateOrUpdateMoveOutVendorRuleRequest,
} from '../../hooks/useMoveOutRules'
import { MoveOutRuleDetail } from '../MoveOutRule/MoveOutRuleDetail'
import { UpdateApartmentRequest } from '../../store'

interface Props {
    moveOutRuleList: MoveOutRule[]
    serviceList: Service[]
    inspectionTypeList: InspectionType[]
    vendorList: ListVendor[]
    selectedMoveOutRule: MoveOutRule | null
    loading: boolean
    workspaceUser?: User
    setSelectedMoveOutRule: React.Dispatch<
        React.SetStateAction<MoveOutRule | null>
    >
    createMoveOutRule: (
        request: CreateMoveOutRuleRequest,
    ) => Promise<MoveOutRule | null>
    deleteMoveOutRule: (moveOutRule: MoveOutRule) => Promise<void>
    createOrUpdateMoveOutStep: (
        request: CreateOrUpdateMoveOutStepRequest,
    ) => Promise<MoveOutRule | null>
    deleteMoveOutStep: (id: number) => Promise<MoveOutRule | null>
    createOrUpdateVendorRule: (
        request: CreateOrUpdateMoveOutVendorRuleRequest,
    ) => Promise<void>
    onDeleteVendorRule: (vendorRuleId: number) => Promise<void>
    setDefaultMoveOutRule: (
        moveOutRule: MoveOutRule,
    ) => Promise<MoveOutRule[] | null>
    onUpdateApartment: (req: UpdateApartmentRequest) => void
}

export const MoveOutRuleTab = (props: Props) => {
    const {
        moveOutRuleList,
        serviceList,
        inspectionTypeList,
        vendorList,
        selectedMoveOutRule,
        loading,
        workspaceUser,
        createMoveOutRule,
        deleteMoveOutRule,
        setSelectedMoveOutRule,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        createOrUpdateVendorRule,
        onDeleteVendorRule,
        setDefaultMoveOutRule,
        onUpdateApartment,
    } = props

    const theme = useTheme()
    const activeWorkspace = workspaceUser?.active_workspace

    const [automateMoveOutRule, setAutomateMoveOutRule] = useState(
        workspaceUser?.active_workspace?.run_auto_move_out_events ?? false,
    )

    const [daysBeforeMoveOutSchedule, setDaysBeforeMoveOutSchedule] = useState(
        workspaceUser?.active_workspace?.days_before_move_out_schedule ?? 0,
    )

    const handleAutomateSwitchChange = useCallback(() => {
        const newValue = !automateMoveOutRule
        setAutomateMoveOutRule(newValue)
        if (activeWorkspace) {
            onUpdateApartment({
                params: { id: activeWorkspace.id },
                body: { run_auto_move_out_events: newValue },
            })
        }
    }, [automateMoveOutRule, activeWorkspace, onUpdateApartment])

    const handleDaysBeforeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value ? parseInt(e.target.value) : 0
            setDaysBeforeMoveOutSchedule(newValue)
            if (activeWorkspace) {
                onUpdateApartment({
                    params: { id: activeWorkspace.id },
                    body: { days_before_move_out_schedule: newValue },
                })
            }
        },
        [activeWorkspace, onUpdateApartment],
    )

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                maxHeight: 'calc(100vh - 200px)',
                minHeight: 'calc(100vh - 200px)',
                display: 'flex',
            }}
        >
            <Container
                style={{ padding: theme.spacing(1), alignItems: 'center' }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Move Out Rules
                </span>
                {activeWorkspace && (
                    <Container style={{ alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={automateMoveOutRule}
                                    onChange={handleAutomateSwitchChange}
                                    color="primary"
                                />
                            }
                            label="Automate Move Out Rule"
                            style={{ marginLeft: theme.spacing(1) }}
                        />
                        <TextField
                            size="small"
                            disabled={!automateMoveOutRule}
                            variant="outlined"
                            label="Days Before Scheduling Move Out Rule"
                            type="number"
                            value={daysBeforeMoveOutSchedule ?? ''}
                            onChange={handleDaysBeforeChange}
                            style={{
                                marginLeft: theme.spacing(1),
                                width: '300px',
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Container>
                )}
            </Container>

            {loading ? (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: 'calc(100vh - 300px)',
                        minHeight: 'calc(100vh - 300px)',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <MoveOutRuleList
                    moveOutRuleList={moveOutRuleList}
                    theme={theme}
                    createMoveOutRule={createMoveOutRule}
                    deleteMoveOutRule={deleteMoveOutRule}
                    onRuleClick={setSelectedMoveOutRule}
                    setDefaultMoveOutRule={setDefaultMoveOutRule}
                />
            )}

            {selectedMoveOutRule && (
                <Modal
                    open={!!selectedMoveOutRule}
                    onClose={() => setSelectedMoveOutRule(null)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={!!selectedMoveOutRule}>
                        <Paper
                            style={{
                                height: 'calc(100vh - 200px)',
                                width: '80%',
                                maxWidth: '800px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <MoveOutRuleDetail
                                selectedMoveOutRule={selectedMoveOutRule}
                                theme={theme}
                                serviceList={serviceList}
                                inspectionTypeList={inspectionTypeList}
                                vendorList={vendorList}
                                onBack={() => setSelectedMoveOutRule(null)}
                                setSelectedMoveOutRule={setSelectedMoveOutRule}
                                createOrUpdateMoveOutStep={
                                    createOrUpdateMoveOutStep
                                }
                                deleteMoveOutStep={deleteMoveOutStep}
                                createOrUpdateVendorRule={
                                    createOrUpdateVendorRule
                                }
                                onDeleteVendorRule={onDeleteVendorRule}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            )}
        </Container>
    )
}
