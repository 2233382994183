import { CalendarEventType } from '../../containers/ScheduleHome/types'

export const getCalendarEventDisplayName = (eventType: CalendarEventType) => {
    switch (eventType) {
        case CalendarEventType.PROJECT:
            return 'Projects'
        case CalendarEventType.DEADLINE:
            return 'Deadlines'
        case CalendarEventType.SERVICE_ORDER:
            return 'Service Orders'
        case CalendarEventType.ACTION_ITEM:
            return 'Action Items'
        case CalendarEventType.SERVICE_REQUEST:
            return 'Service Requests'
        case CalendarEventType.MOVE_IN:
            return 'Move Ins'
        case CalendarEventType.MOVE_OUT:
            return 'Move Outs'
        case CalendarEventType.ON_CALL:
            return 'On Call'
        case CalendarEventType.DAY_OFF:
            return 'Day Off'
        default:
            return ''
    }
}
