import {
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import {
    BaseWorkorder,
    createWorkorderFromResponse,
    ModelMap,
    ServiceContractMap,
    SUPER_TRANSITION_REVERSAL,
    WorkorderStatus,
} from '../../../models'
import { WorkorderRow } from './WorkorderRow'
import CloseIcon from '@material-ui/icons/Close'
import { axiosInstance, isAccountManager } from '../../../helpers'
import { useAppDispatch, useUser } from '../../../hooks'
import { WorkorderResponse, updateBulkWorkorders } from '../../../store'
import { toast } from 'react-toastify'
import { hasPermission } from '../../../models/Users/services'

interface Props {
    workorders: (BaseWorkorder | WorkorderResponse)[]
    isOpen: boolean
    upStreamContractMap?: ServiceContractMap
    isVendor?: boolean
    handleBulkTransitionClose: () => void
    setSelectedWorkorders: (
        selectedWorkorders: ModelMap<BaseWorkorder | WorkorderResponse>,
    ) => void
    handleBulkEditClose: () => void
    handleDataFormClose?: () => void
    superTransitionWorkorders?: (
        workorders: TransitionStatusDict[],
        allJobsites?: boolean | undefined,
    ) => Promise<void>
}

export interface TransitionsDict {
    [status: string]: string[]
}

interface TransitionStatusDict {
    id: number
    status: string
    revert: boolean
}

type TransitionCountsDict = {
    [key in WorkorderStatus]: {
        validCount: number
        foundCount: number
    }
}

const transitions: TransitionsDict = {
    ASSIGNED: [
        WorkorderStatus.IN_PROGRESS,
        WorkorderStatus.COMPLETE,
        WorkorderStatus.GO_BACK,
        WorkorderStatus.PRE_APPROVED,
        WorkorderStatus.APPROVED,
    ],
    IN_PROGRESS: [
        WorkorderStatus.ASSIGNED,
        WorkorderStatus.COMPLETE,
        WorkorderStatus.GO_BACK,
        WorkorderStatus.PRE_APPROVED,
        WorkorderStatus.APPROVED,
        WorkorderStatus.PAUSE,
    ],
    PAUSE: [
        WorkorderStatus.IN_PROGRESS,
        WorkorderStatus.COMPLETE,
        WorkorderStatus.GO_BACK,
        WorkorderStatus.PRE_APPROVED,
        WorkorderStatus.APPROVED,
    ],
    COMPLETE: [WorkorderStatus.PRE_APPROVED, WorkorderStatus.APPROVED],
    GO_BACK: [
        WorkorderStatus.COMPLETE,
        WorkorderStatus.PRE_APPROVED,
        WorkorderStatus.APPROVED,
    ],
    PRE_APPROVED: [WorkorderStatus.APPROVED],
    APPROVED: [],
    INVOICED: [],
}

export const BulkTransitionEditor = (props: Props) => {
    const {
        workorders,
        isOpen,
        handleBulkTransitionClose: handleOpen,
        upStreamContractMap,
        setSelectedWorkorders,
        handleBulkEditClose,
        handleDataFormClose,
        isVendor,
        superTransitionWorkorders,
    } = props

    interface WorkorderTransitionToDict {
        workorder: BaseWorkorder
        toStatus: string | undefined
    }

    const dispatch = useAppDispatch()
    const { workspaceUser } = useUser()

    const [workorderTransitionList, setWorkorderTransitionList] = useState<
        WorkorderTransitionToDict[]
    >([])
    const [handlingTransitions, setHandlingTransitions] = useState(false)

    useEffect(() => {
        setWorkorderTransitionList(
            workorders.map((wo) => {
                return { workorder: wo, toStatus: undefined }
            }),
        )
    }, [workorders])

    if (hasPermission(workspaceUser, SUPER_TRANSITION_REVERSAL)) {
        transitions[WorkorderStatus.PRE_APPROVED].push(WorkorderStatus.COMPLETE)
        transitions[WorkorderStatus.PRE_APPROVED].push(WorkorderStatus.GO_BACK)
        transitions[WorkorderStatus.PRE_APPROVED].push(
            WorkorderStatus.IN_PROGRESS,
        )
        transitions[WorkorderStatus.APPROVED].push(WorkorderStatus.COMPLETE)
        transitions[WorkorderStatus.APPROVED].push(WorkorderStatus.PRE_APPROVED)
        transitions[WorkorderStatus.APPROVED].push(WorkorderStatus.IN_PROGRESS)
        transitions[WorkorderStatus.APPROVED].push(WorkorderStatus.GO_BACK)
        transitions[WorkorderStatus.COMPLETE].push(WorkorderStatus.IN_PROGRESS)
        transitions[WorkorderStatus.COMPLETE].push(WorkorderStatus.GO_BACK)
    }

    const totalCounts = workorderTransitionList.reduce<TransitionCountsDict>(
        (prev, currentWorkorder) => {
            const isInProgressValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.IN_PROGRESS)
            const isCompleteValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.COMPLETE)
            const isGoBackValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.GO_BACK)
            const isPreApprovedValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.PRE_APPROVED)
            const isApprovedValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.APPROVED)
            const isPauseValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.PAUSE)
            const isAssignedValid = transitions[
                currentWorkorder.workorder.status
            ].includes(WorkorderStatus.ASSIGNED)

            const isToInProgress =
                currentWorkorder.toStatus === WorkorderStatus.IN_PROGRESS
            const isToComplete =
                currentWorkorder.toStatus === WorkorderStatus.COMPLETE
            const isToGoBack =
                currentWorkorder.toStatus === WorkorderStatus.GO_BACK
            const isToPreApproved =
                currentWorkorder.toStatus === WorkorderStatus.PRE_APPROVED
            const isToApproved =
                currentWorkorder.toStatus === WorkorderStatus.APPROVED
            const isToPause =
                currentWorkorder.toStatus === WorkorderStatus.PAUSE
            const isToAssigned =
                currentWorkorder.toStatus === WorkorderStatus.ASSIGNED

            prev[WorkorderStatus.IN_PROGRESS].validCount += Number(
                isInProgressValid,
            )
            prev[WorkorderStatus.IN_PROGRESS].foundCount += Number(
                isToInProgress,
            )

            prev[WorkorderStatus.COMPLETE].validCount += Number(isCompleteValid)
            prev[WorkorderStatus.COMPLETE].foundCount += Number(isToComplete)

            prev[WorkorderStatus.GO_BACK].validCount += Number(isGoBackValid)
            prev[WorkorderStatus.GO_BACK].foundCount += Number(isToGoBack)

            prev[WorkorderStatus.PRE_APPROVED].validCount += Number(
                isPreApprovedValid,
            )
            prev[WorkorderStatus.PRE_APPROVED].foundCount += Number(
                isToPreApproved,
            )

            prev[WorkorderStatus.APPROVED].validCount += Number(isApprovedValid)
            prev[WorkorderStatus.APPROVED].foundCount += Number(isToApproved)

            prev[WorkorderStatus.PAUSE].validCount += Number(isPauseValid)
            prev[WorkorderStatus.PAUSE].foundCount += Number(isToPause)

            prev[WorkorderStatus.ASSIGNED].validCount += Number(isAssignedValid)
            prev[WorkorderStatus.ASSIGNED].foundCount += Number(isToAssigned)

            return { ...prev }
        },
        {
            [WorkorderStatus.IN_PROGRESS]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.COMPLETE]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.GO_BACK]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.PRE_APPROVED]: {
                validCount: 0,
                foundCount: 0,
            },

            [WorkorderStatus.APPROVED]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.PAUSE]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.ASSIGNED]: {
                validCount: 0,
                foundCount: 0,
            },
            [WorkorderStatus.INVOICED]: {
                validCount: 0,
                foundCount: 0,
            },
        },
    )

    const isChecked = (status: WorkorderStatus) => {
        let validCount = totalCounts[status].validCount
        if (isAccountManager(workspaceUser)) {
            validCount = workorderTransitionList.length
        }

        return validCount === totalCounts[status].foundCount && validCount != 0
    }

    const isIndeterminate = (status: WorkorderStatus) => {
        let validCount = totalCounts[status].validCount
        if (isAccountManager(workspaceUser)) {
            validCount = workorderTransitionList.length
        }

        return (
            (totalCounts[status].foundCount > 0 &&
                totalCounts[status].foundCount !== validCount) ||
            validCount === 0
        )
    }

    const isDisabled = (status: WorkorderStatus) => {
        if (isAccountManager(workspaceUser)) {
            return false
        }

        return totalCounts[status].validCount === 0
    }

    const handleOnChange = (status: WorkorderStatus) => {
        const today = new Date()

        if (
            totalCounts[status].foundCount > 0 &&
            totalCounts[status].foundCount !== totalCounts[status].validCount
        ) {
            setWorkorderTransitionList(
                workorderTransitionList.map((wo) => {
                    const disabled =
                        isVendor &&
                        new Date(wo.workorder.start_date ?? '') > today
                    if (wo.toStatus === status && !disabled) {
                        return {
                            workorder: wo.workorder,
                            toStatus: undefined,
                        }
                    } else {
                        return {
                            workorder: wo.workorder,
                            toStatus: wo.toStatus,
                        }
                    }
                }),
            )
        } else if (totalCounts[status].foundCount === 0) {
            setWorkorderTransitionList(
                workorderTransitionList.map((wo) => {
                    const disabled =
                        isVendor &&
                        new Date(wo.workorder.start_date ?? '') > today
                    if (
                        isAccountManager(workspaceUser) ||
                        (transitions[wo.workorder.status].includes(status) &&
                            !disabled)
                    ) {
                        return {
                            workorder: wo.workorder,
                            toStatus: status,
                        }
                    } else {
                        return {
                            workorder: wo.workorder,
                            toStatus: wo.toStatus,
                        }
                    }
                }),
            )
        } else if (
            totalCounts[status].foundCount === totalCounts[status].validCount
        ) {
            setWorkorderTransitionList(
                workorderTransitionList.map((wo) => {
                    const disabled =
                        isVendor &&
                        new Date(wo.workorder.start_date ?? '') > today
                    if (wo.toStatus === status && !disabled) {
                        return {
                            workorder: wo.workorder,
                            toStatus: undefined,
                        }
                    } else {
                        return {
                            workorder: wo.workorder,
                            toStatus: wo.toStatus,
                        }
                    }
                }),
            )
        }
    }

    const theme = useTheme()

    const handleTransition = (transitionsArray: TransitionStatusDict[]) => {
        axiosInstance
            .post('workorder/super_transition/', {
                workorders: transitionsArray,
            })
            .then((res) => {
                dispatch(
                    updateBulkWorkorders(
                        res.data.map(createWorkorderFromResponse),
                    ),
                )
                toast.success('Bulk Transition Complete')
                setHandlingTransitions(false)
                handleOpen()
                setSelectedWorkorders({})
                if (handleDataFormClose) {
                    handleDataFormClose()
                }
                handleBulkEditClose()
            })
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleOpen}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={isOpen}>
                <Paper
                    style={{
                        width: '90%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        height: '90%',
                    }}
                >
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                                marginTop: theme.spacing(1),
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            Bulk Transition Editor
                        </span>
                        <div style={{ flex: 1 }} />

                        <Tooltip title="Cancel">
                            <IconButton onClick={handleOpen}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Container
                        style={{
                            alignItems: 'center',
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    >
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                flex: 1,
                                justifyContent: 'center',
                                padding: theme.spacing(2),
                            }}
                        >
                            Workorder
                        </Container>
                        {isVendor && (
                            <Container
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                    justifyContent: 'center',
                                    padding: theme.spacing(2),
                                    alignItems: 'center',
                                }}
                            >
                                Assigned
                                <Checkbox
                                    checked={isChecked(
                                        WorkorderStatus.ASSIGNED,
                                    )}
                                    indeterminate={isIndeterminate(
                                        WorkorderStatus.ASSIGNED,
                                    )}
                                    disabled={isDisabled(
                                        WorkorderStatus.ASSIGNED,
                                    )}
                                    onChange={() =>
                                        handleOnChange(WorkorderStatus.ASSIGNED)
                                    }
                                />
                                {totalCounts[WorkorderStatus.ASSIGNED]
                                    .foundCount > 0 && (
                                    <Container>
                                        (
                                        {
                                            totalCounts[
                                                WorkorderStatus.ASSIGNED
                                            ].foundCount
                                        }
                                        )
                                    </Container>
                                )}
                            </Container>
                        )}
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                flex: 1,
                                justifyContent: 'center',
                                padding: theme.spacing(2),
                                alignItems: 'center',
                            }}
                        >
                            In Progress
                            <Checkbox
                                checked={isChecked(WorkorderStatus.IN_PROGRESS)}
                                indeterminate={isIndeterminate(
                                    WorkorderStatus.IN_PROGRESS,
                                )}
                                disabled={isDisabled(
                                    WorkorderStatus.IN_PROGRESS,
                                )}
                                onChange={() =>
                                    handleOnChange(WorkorderStatus.IN_PROGRESS)
                                }
                            />
                            {totalCounts[WorkorderStatus.IN_PROGRESS]
                                .foundCount > 0 && (
                                <Container>
                                    (
                                    {
                                        totalCounts[WorkorderStatus.IN_PROGRESS]
                                            .foundCount
                                    }
                                    )
                                </Container>
                            )}
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                flex: 1,
                                justifyContent: 'center',
                                padding: theme.spacing(2),
                                alignItems: 'center',
                            }}
                        >
                            Complete
                            <Checkbox
                                checked={isChecked(WorkorderStatus.COMPLETE)}
                                indeterminate={isIndeterminate(
                                    WorkorderStatus.COMPLETE,
                                )}
                                disabled={isDisabled(WorkorderStatus.COMPLETE)}
                                onChange={() =>
                                    handleOnChange(WorkorderStatus.COMPLETE)
                                }
                            />
                            {totalCounts[WorkorderStatus.COMPLETE].foundCount >
                                0 && (
                                <Container>
                                    (
                                    {
                                        totalCounts[WorkorderStatus.COMPLETE]
                                            .foundCount
                                    }
                                    )
                                </Container>
                            )}
                        </Container>
                        {!isVendor && (
                            <Container
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                    justifyContent: 'center',
                                    padding: theme.spacing(2),
                                    alignItems: 'center',
                                }}
                            >
                                Go Back
                                <Checkbox
                                    checked={isChecked(WorkorderStatus.GO_BACK)}
                                    indeterminate={isIndeterminate(
                                        WorkorderStatus.GO_BACK,
                                    )}
                                    disabled={isDisabled(
                                        WorkorderStatus.GO_BACK,
                                    )}
                                    onChange={() =>
                                        handleOnChange(WorkorderStatus.GO_BACK)
                                    }
                                />
                                {totalCounts[WorkorderStatus.GO_BACK]
                                    .foundCount > 0 && (
                                    <Container>
                                        (
                                        {
                                            totalCounts[WorkorderStatus.GO_BACK]
                                                .foundCount
                                        }
                                        )
                                    </Container>
                                )}
                            </Container>
                        )}
                        {isVendor && (
                            <Container
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                    justifyContent: 'center',
                                    padding: theme.spacing(2),
                                    alignItems: 'center',
                                }}
                            >
                                Pause
                                <Checkbox
                                    checked={isChecked(WorkorderStatus.PAUSE)}
                                    indeterminate={isIndeterminate(
                                        WorkorderStatus.PAUSE,
                                    )}
                                    disabled={isDisabled(WorkorderStatus.PAUSE)}
                                    onChange={() =>
                                        handleOnChange(WorkorderStatus.PAUSE)
                                    }
                                />
                                {totalCounts[WorkorderStatus.PAUSE].foundCount >
                                    0 && (
                                    <Container>
                                        (
                                        {
                                            totalCounts[WorkorderStatus.PAUSE]
                                                .foundCount
                                        }
                                        )
                                    </Container>
                                )}
                            </Container>
                        )}
                        {!isVendor && (
                            <Container
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                    justifyContent: 'center',
                                    padding: theme.spacing(2),
                                    alignItems: 'center',
                                }}
                            >
                                Pre Approved
                                <Checkbox
                                    checked={isChecked(
                                        WorkorderStatus.PRE_APPROVED,
                                    )}
                                    indeterminate={isIndeterminate(
                                        WorkorderStatus.PRE_APPROVED,
                                    )}
                                    disabled={isDisabled(
                                        WorkorderStatus.PRE_APPROVED,
                                    )}
                                    onChange={() =>
                                        handleOnChange(
                                            WorkorderStatus.PRE_APPROVED,
                                        )
                                    }
                                />
                                {totalCounts[WorkorderStatus.PRE_APPROVED]
                                    .foundCount > 0 && (
                                    <Container>
                                        (
                                        {
                                            totalCounts[
                                                WorkorderStatus.PRE_APPROVED
                                            ].foundCount
                                        }
                                        )
                                    </Container>
                                )}
                            </Container>
                        )}
                        {!isVendor && (
                            <Container
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                    justifyContent: 'center',
                                    padding: theme.spacing(2),
                                    alignItems: 'center',
                                }}
                            >
                                Approved
                                <Checkbox
                                    checked={isChecked(
                                        WorkorderStatus.APPROVED,
                                    )}
                                    indeterminate={isIndeterminate(
                                        WorkorderStatus.APPROVED,
                                    )}
                                    disabled={isDisabled(
                                        WorkorderStatus.APPROVED,
                                    )}
                                    onChange={() =>
                                        handleOnChange(WorkorderStatus.APPROVED)
                                    }
                                />
                                {totalCounts[WorkorderStatus.APPROVED]
                                    .foundCount > 0 && (
                                    <Container>
                                        (
                                        {
                                            totalCounts[
                                                WorkorderStatus.APPROVED
                                            ].foundCount
                                        }
                                        )
                                    </Container>
                                )}
                            </Container>
                        )}
                    </Container>
                    <Container style={{ flexDirection: 'column' }} scrollY>
                        {workorderTransitionList.map((woDict) => {
                            return (
                                <WorkorderRow
                                    key={woDict.workorder.id}
                                    workorder={woDict.workorder}
                                    transitions={transitions}
                                    toStatus={woDict.toStatus}
                                    onStatusChange={(
                                        status: string | undefined,
                                    ) => {
                                        const newWorkorderList = workorderTransitionList.map(
                                            (innerWoDict) => {
                                                if (
                                                    innerWoDict.workorder.id ===
                                                    woDict.workorder.id
                                                ) {
                                                    return {
                                                        workorder:
                                                            woDict.workorder,
                                                        toStatus: status,
                                                    }
                                                }
                                                return innerWoDict
                                            },
                                        )
                                        setWorkorderTransitionList(
                                            newWorkorderList,
                                        )
                                    }}
                                    upStreamContractMap={upStreamContractMap}
                                    isVendor={isVendor}
                                />
                            )
                        })}
                    </Container>
                    <Container
                        style={{
                            flex: 1,
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    ></Container>

                    <Container
                        style={{
                            padding: theme.spacing(2),
                            justifyContent: 'flex-end',
                        }}
                    >
                        {handlingTransitions && (
                            <CircularProgress
                                size={20}
                                style={{ margin: theme.spacing(1) }}
                            />
                        )}
                        {!handlingTransitions && (
                            <Button
                                color="primary"
                                variant="outlined"
                                style={{ marginRight: theme.spacing(2) }}
                                onClick={() => {
                                    setHandlingTransitions(true)
                                    const transitionsArray: TransitionStatusDict[] = []
                                    workorderTransitionList.map((wo) => {
                                        if (wo.toStatus !== undefined) {
                                            transitionsArray.push({
                                                id: wo.workorder.id,
                                                status: wo.toStatus,
                                                revert: false,
                                            })
                                        }
                                    })
                                    if (transitionsArray.length !== 0) {
                                        if (superTransitionWorkorders) {
                                            superTransitionWorkorders(
                                                transitionsArray,
                                                true,
                                            )
                                                .then(() => {
                                                    toast.success(
                                                        `Bulk transition complete`,
                                                    )
                                                    handleOpen()
                                                    setSelectedWorkorders({})
                                                    if (handleDataFormClose) {
                                                        handleDataFormClose()
                                                    }
                                                    handleBulkEditClose()
                                                })
                                                .catch((e) => {
                                                    toast.error(
                                                        e.response.data.message,
                                                    )
                                                })
                                                .finally(() => {
                                                    setHandlingTransitions(
                                                        false,
                                                    )
                                                })
                                        } else {
                                            handleTransition(transitionsArray)
                                        }
                                    }
                                }}
                            >
                                Transition
                            </Button>
                        )}
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={handleOpen}
                        >
                            Cancel
                        </Button>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
