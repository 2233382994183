import React from 'react'

import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    makeStyles,
    Button,
    Chip,
    Divider,
    useTheme,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { ApartmentVendorRuleDict } from '../../hooks/useApartmentVendorRule'
import { Service } from '../../models'
import { Container } from '../../components'

interface Props {
    apartmentVendorRuleDict: ApartmentVendorRuleDict
    serviceList: Service[]
    onSelectService: (serviceId: number) => void
    onAddNewRule: () => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        flex: 1,
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
    listContainer: {
        overflowY: 'auto',
    },
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    addButton: {
        margin: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
        flex: 1,
    },
    emptyState: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
}))

export const ServiceRulesList = (props: Props) => {
    const {
        apartmentVendorRuleDict,
        serviceList,
        onAddNewRule,
        onSelectService,
    } = props
    const classes = useStyles()

    const servicesWithRules = serviceList.filter(
        (service) => apartmentVendorRuleDict[service.id]?.length > 0,
    )

    const theme = useTheme()

    return (
        <Paper className={classes.root}>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                    width: '100%',
                }}
            >
                <Typography variant="h6" className={classes.header}>
                    Services with Rules
                </Typography>
                <div className={classes.listContainer}>
                    {servicesWithRules.length > 0 ? (
                        <List component="nav" aria-label="services with rules">
                            {servicesWithRules.map((service) => (
                                <React.Fragment key={service.id}>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            onSelectService(service.id)
                                        }
                                        className={classes.listItem}
                                    >
                                        <ListItemText primary={service.name} />
                                        <Chip
                                            label={`${
                                                apartmentVendorRuleDict[
                                                    service.id
                                                ].length
                                            } rule${
                                                apartmentVendorRuleDict[
                                                    service.id
                                                ].length !== 1
                                                    ? 's'
                                                    : ''
                                            }`}
                                            size="small"
                                            className={classes.chip}
                                            style={{
                                                width: '75px',
                                                backgroundColor:
                                                    theme.palette.primary.dark,
                                                color:
                                                    theme.palette.primary
                                                        .contrastText,
                                            }}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <div className={classes.emptyState}>
                            <Typography variant="body1" color="textSecondary">
                                No services with rules yet.
                            </Typography>
                        </div>
                    )}
                </div>
                <Container style={{ flex: 1 }} />
                <Container>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onAddNewRule}
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                    >
                        Add New Service Rule
                    </Button>
                </Container>
            </Container>
        </Paper>
    )
}
