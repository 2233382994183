import React, { useState } from 'react'

import {
    Modal,
    Paper,
    Typography,
    Theme,
    IconButton,
    makeStyles,
    Slide,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../components'
import { InspectionType, ListVendor, MoveOutRule, Service } from '../../models'
import {
    CreateMoveOutRuleRequest,
    CreateOrUpdateMoveOutStepRequest,
    CreateOrUpdateMoveOutVendorRuleRequest,
} from '../../hooks/useMoveOutRules'
import { MoveOutRuleList } from './MoveOutRuleList'
import { MoveOutRuleDetail } from './MoveOutRuleDetail'

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    },
}))

interface Props {
    open: boolean
    theme: Theme
    moveOutRuleList: MoveOutRule[]
    selectedMoveOutRule: MoveOutRule | null
    serviceList: Service[]
    inspectionTypeList: InspectionType[]
    vendorList: ListVendor[]
    onClose: () => void
    createMoveOutRule: (
        request: CreateMoveOutRuleRequest,
    ) => Promise<MoveOutRule | null>
    setSelectedMoveOutRule: React.Dispatch<
        React.SetStateAction<MoveOutRule | null>
    >
    deleteMoveOutRule: (moveOutRule: MoveOutRule) => Promise<void>
    createOrUpdateMoveOutStep: (
        request: CreateOrUpdateMoveOutStepRequest,
    ) => Promise<MoveOutRule | null>
    deleteMoveOutStep: (id: number) => Promise<MoveOutRule | null>
    createOrUpdateVendorRule: (
        request: CreateOrUpdateMoveOutVendorRuleRequest,
    ) => Promise<void>
    onDeleteVendorRule: (vendorRuleId: number) => Promise<void>
}

export const MoveOutRuleModal = (props: Props) => {
    const {
        open,
        theme,
        moveOutRuleList,
        selectedMoveOutRule,
        serviceList,
        inspectionTypeList,
        vendorList,
        setSelectedMoveOutRule,
        onClose,
        createMoveOutRule,
        deleteMoveOutRule,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        createOrUpdateVendorRule,
        onDeleteVendorRule,
    } = props

    const classes = useStyles()

    const handleRuleClick = (rule: MoveOutRule) => {
        setSelectedMoveOutRule(rule)
    }

    const handleBackToList = () => {
        setSelectedMoveOutRule(null)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: 'calc(100vh - 200px)',
                        width: '80%',
                        maxWidth: '800px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        className={classes.header}
                        style={{ padding: theme.spacing(1) }}
                    >
                        <Typography variant="h6">Move Out Rules</Typography>
                        <IconButton
                            onClick={onClose}
                            size="small"
                            style={{ marginLeft: 'auto' }}
                        >
                            <CloseIcon htmlColor="white" />
                        </IconButton>
                    </Container>
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            overflow: 'auto',
                        }}
                    >
                        {selectedMoveOutRule ? (
                            <MoveOutRuleDetail
                                selectedMoveOutRule={selectedMoveOutRule}
                                onBack={handleBackToList}
                                theme={theme}
                                setSelectedMoveOutRule={setSelectedMoveOutRule}
                                serviceList={serviceList}
                                inspectionTypeList={inspectionTypeList}
                                createOrUpdateMoveOutStep={
                                    createOrUpdateMoveOutStep
                                }
                                deleteMoveOutStep={deleteMoveOutStep}
                                vendorList={vendorList}
                                createOrUpdateVendorRule={
                                    createOrUpdateVendorRule
                                }
                                onDeleteVendorRule={onDeleteVendorRule}
                            />
                        ) : (
                            <MoveOutRuleList
                                moveOutRuleList={moveOutRuleList}
                                theme={theme}
                                createMoveOutRule={createMoveOutRule}
                                onRuleClick={handleRuleClick}
                                deleteMoveOutRule={deleteMoveOutRule}
                            />
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
