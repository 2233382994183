import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { Area, Damage, Lease, Tenant } from '../../models'

export interface GetLeaseListRequest {
    url?: string
    params?: {
        overlap_start?: string
        overlap_end?: string
        lte_start_date?: string
        gte_start_date?: string
        lte_end_date?: string
        gte_end_date?: string
        area?: number
        // a comma seperated list of unit ids
        units?: string
        // a comma seperated string of lease ids
        leases?: string
        history?: boolean
        page?: number
        page_size?: number
        // a comma seperated string of the sort order for the be
        sort_order?: string
        occupiable?: boolean
        // comma seperated LeaseStatus string
        statuses?: string
        is_child?: boolean
        staged?: boolean
        home?: boolean
        exclude_children?: boolean
        move_in_date?: string
        move_out_date?: string
    }
}

export interface BulkUpdateLeaseRequest {
    body: {
        leases: {
            lease: number
            start_date?: string
            end_date?: string
            rent?: number
        }[]
    }
}

export interface UpdateLeaseRequest {
    lease: number
    start_date?: string
    end_date?: string
}

export interface BulkCreateLeaseRequest {
    body: {
        leases: {
            start_date: string
            end_date: string
            area: number
            rent?: number
            tenant: {
                email: string
                name?: string
                phone?: string
            }
        }[]
    }
}

export interface GetVacantAreaRequest {
    params: {
        occupiable?: boolean
        vacant_start?: string
        vacant_end?: string
    }
}

export interface GetNextAndPrevLeaseRequest {
    id: number
}

export interface CreateDamageReqeust {
    body: {
        amount: number
        damage_config?: number
        area: number
        inspection: number
        tenants?: number[]
        // description and cost required when config is not provided
        description?: string
        cost?: number
    }
}

export interface GetDamageRequest {
    params?: {
        inspection?: number
    }
}

export interface GetTenantListRequest {
    params?: {
        lease_ids?: string
        previous_tenants_for_area?: number
        area?: number
        include_other_unit_areas?: boolean
    }
}

export const GET_LEASE_LIST_REQUEST = 'GET_LEASE_LIST_REQUEST'
export const BULK_UPDATE_LEASE_REQUEST = 'BULK_UPDATE_LEASE_REQUEST'
export const BULK_CREATE_LEASE_REQUEST = 'BULK_CREATE_LEASE_REQUEST'
export const GET_VACANT_AREA_REQUEST = 'GET_VACANT_AREA_REQUEST'
export const GET_NEXT_AND_PREV_LEASE_REQUEST = 'GET_NEXT_AND_PREV_LEASE_REQUEST'
export const CREATE_DAMAGE_REQUEST = 'CREATE_DAMAGE_REQUEST'
export const GET_DAMAGE_LIST_REQUEST = 'GET_DAMAGE_LIST_REQUEST'
export const GET_TENANT_LIST_REQUEST = 'GET_TENANT_LIST_REQUEST'
export const DELETE_DAMAGE_REQUEST = 'DELETE_DAMAGE_REQUEST'

export const SET_VACANT_AREA_LIST = 'SET_VACANT_AREA_LIST'
export const BULK_UPDATE_LEASE = 'BULK_UPDATE_LEASE'
export const ADD_LEASES = 'ADD_LEASES'
export const SET_LEASE_LIST = 'SET_LEASE_LIST'
export const SET_LEASE_LOADING = 'SET_LEASE_LOADING'
export const SET_NEXT_AND_PREV_LEASE = 'SET_NEXT_AND_PREV_LEASE'
export const SET_DAMAGE_LIST = 'SET_DAMAGE_LIST'
export const ADD_DAMAGE = 'ADD_DAMAGE'
export const UPDATE_DAMAGE = 'UPDATE_DAMAGE'
export const SET_TENANT_LIST = 'SET_TENANT_LIST'
export const REMOVE_DAMAGE = 'REMOVE_DAMAGE'

export type LeaseRequest =
    | typeof GET_LEASE_LIST_REQUEST
    | typeof BULK_UPDATE_LEASE_REQUEST
    | typeof BULK_CREATE_LEASE_REQUEST
    | typeof GET_VACANT_AREA_REQUEST
    | typeof GET_NEXT_AND_PREV_LEASE_REQUEST
    | typeof CREATE_DAMAGE_REQUEST
    | typeof GET_DAMAGE_LIST_REQUEST
    | typeof GET_TENANT_LIST_REQUEST
    | typeof DELETE_DAMAGE_REQUEST

interface SetLeaseListAction extends Action {
    type: typeof SET_LEASE_LIST
    leases?: Lease[]
    initial: boolean
}

interface SetLeaseLoadingAction extends Action {
    type: typeof SET_LEASE_LOADING
    request: LeaseRequest
    loading: boolean
}

interface BulkUpdateLeaseAction extends Action {
    type: typeof BULK_UPDATE_LEASE
    leases: Lease[]
}

interface AddLeasesAction extends Action {
    type: typeof ADD_LEASES
    leases: Lease[]
}

interface SetVacantAreaAction extends Action {
    type: typeof SET_VACANT_AREA_LIST
    areas?: Area[]
}

interface SetNextPrevLease extends Action {
    type: typeof SET_NEXT_AND_PREV_LEASE
    next?: Lease
    prev?: Lease
}

interface SetDamageListAction extends Action {
    type: typeof SET_DAMAGE_LIST
    damageList?: Damage[]
}

interface AddDamageAction extends Action {
    type: typeof ADD_DAMAGE
    damage: Damage
}

interface SetTenantList extends Action {
    type: typeof SET_TENANT_LIST
    tenantList?: Tenant[]
}

interface UpdateDamage extends Action {
    type: typeof UPDATE_DAMAGE
    damage: Damage
}

interface RemoveDamage extends Action {
    type: typeof REMOVE_DAMAGE
    damageId: number
}

export type LeaseActionTypes =
    | SetLeaseListAction
    | SetLeaseLoadingAction
    | BulkUpdateLeaseAction
    | AddLeasesAction
    | SetVacantAreaAction
    | SetNextPrevLease
    | SetDamageListAction
    | AddDamageAction
    | SetTenantList
    | UpdateDamage
    | RemoveDamage

// Thunks
export type LeaseListResponseThunk = Promise<AxiosResponse<Lease[]>>
export type AreaListThunk = Promise<AxiosResponse<Area[]>>
export type NextAndPrevLeaseThunk = Promise<
    AxiosResponse<{ next_lease: Lease; prev_lease: Lease }>
>
export type DamageThunk = Promise<AxiosResponse<Damage>>
export type DamageListThunk = Promise<AxiosResponse<Damage[]>>
export type TenantListThunk = Promise<AxiosResponse<Tenant[]>>

// Reducers
export interface LeaseState {
    leaseList?: Lease[]
    leaseIDList?: number[]
    vacantAreas?: Area[]
    prevLease?: Lease
    nextLease?: Lease
    damageList?: Damage[]
    tenantList?: Tenant[]
    isLoading: {
        [GET_LEASE_LIST_REQUEST]: boolean
        [BULK_UPDATE_LEASE_REQUEST]: boolean
        [BULK_CREATE_LEASE_REQUEST]: boolean
        [GET_VACANT_AREA_REQUEST]: boolean
        [GET_NEXT_AND_PREV_LEASE_REQUEST]: boolean
        [CREATE_DAMAGE_REQUEST]: boolean
        [GET_DAMAGE_LIST_REQUEST]: boolean
        [GET_TENANT_LIST_REQUEST]: boolean
        [DELETE_DAMAGE_REQUEST]: boolean
    }
}
