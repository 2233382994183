import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from '@material-ui/core'

import { Container } from '../../../components'
import { IdBoolMap } from '../../../models'
import { RootState } from '../../../store'

interface SelectedItemsProps {
    invItemsMap: IdBoolMap
}

const SelectedItems = React.memo(({ invItemsMap }: SelectedItemsProps) => {
    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )
    const theme = useTheme()

    const selectedItems = useMemo(() => {
        return (
            inventoryConfigList
                ?.filter((inventoryConfig) => invItemsMap[inventoryConfig.id])
                .map((inventoryConfig) => (
                    <Container
                        key={inventoryConfig.id}
                        style={{ padding: theme.spacing(0.5) }}
                    >
                        {inventoryConfig.name} (
                        {inventoryConfig.area_config_count})
                    </Container>
                )) ?? []
        )
    }, [inventoryConfigList, invItemsMap, theme])

    return (
        <Container
            style={{
                minHeight: '100px',
                overflowY: 'auto',
                border: `1px solid ${theme.palette.lightGrey.dark}`,
                borderRadius: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            }}
        >
            {selectedItems.length !== 0 ? (
                <Container
                    flexWrap="wrap"
                    style={{ margin: theme.spacing(1, 0, 1, 0) }}
                >
                    {selectedItems}
                </Container>
            ) : (
                <Container>Select an item</Container>
            )}
        </Container>
    )
})

export default SelectedItems
