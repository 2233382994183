import React, { useEffect, useState, useCallback } from 'react'
import { Container, LoadingButton, LocationForm } from '../../../../components'
import { ListVendor, TemplateService } from '../../../../models'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Button, Theme, Typography } from '@material-ui/core'
import { SmartScheduleController, useFinderSelection } from '../../../../hooks'
import { Folder, Service } from '../../../../models'
import { TemplateServiceDetailRow } from '../../../../components/TemplateScheduleGantt/Components'
import { usePopover } from '../../../../hooks/usePopover'
import { SelectorPopover } from '../../../../components/TemplateScheduleGantt/Components/SelectorPopover'
import { ServiceRow } from './ServiceRow'

import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DroppableProvided,
    DraggableProvided,
} from 'react-beautiful-dnd'

import { memo } from 'react'
import {
    FOOTER_HEIGHT,
    HEADER_HEIGHT,
    MODAL_PADDING,
} from './SmartScheduleModal'
import { toast } from 'react-toastify'

interface SetupStepProps {
    theme: Theme
    onSubmit: (
        unitIds: number[],
        formState: SetupSmartScheduleFormState,
    ) => void
    scheduleController: SmartScheduleController
    infrastructure: Folder
    vendorList: ListVendor[]
    serviceList: Service[]
}

// Memoize the ServiceRow component to prevent unnecessary re-renders
const MemoizedServiceRow = memo(ServiceRow)

export const SetupStep: React.FC<SetupStepProps> = (props: SetupStepProps) => {
    const [error, setError] = useState<string>('')

    const { theme, scheduleController, infrastructure, onSubmit } = props

    const {
        finderSelection,
        selectLocation,
        setSelection,
    } = useFinderSelection()

    const [formState, setFormState] = useState<SetupSmartScheduleFormState>({
        workStartDate: new Date(),
        workEndDate: new Date(),
        templateServices: [],
    })

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return

        setFormState((prev) => {
            const services = [...prev.templateServices]
            const [reorderedItem] = services.splice(result.source.index, 1)
            services.splice(result.destination!.index, 0, reorderedItem)

            return {
                ...prev,
                templateServices: services,
            }
        })
    }, []) // Empty dependency array since we're using the setter form of setState

    const popoverState = usePopover()

    const schedule = scheduleController.schedule

    const loadPage = () => {
        if (schedule === null || schedule === undefined) return

        const selectedUnits = schedule.template_units
            .filter((tu) => tu.is_included)
            .map((filteredUnit) => filteredUnit.unit)

        let startDate = new Date()
        let endDate = new Date()
        if (schedule.work_start_date) {
            startDate = new Date(schedule.work_start_date)
        } else if (schedule.start_date) {
            startDate = new Date(schedule.start_date)
        }

        if (schedule.work_end_date) {
            endDate = new Date(schedule.work_end_date)
        } else if (schedule.end_date) {
            endDate = new Date(schedule.end_date)
        }

        const templateServices = schedule.template_services.map((ts) => {
            return {
                serviceId: ts.service.id,
                serviceName: ts.service.name,
                templateServiceVendors: ts.template_service_vendors.map(
                    (tsv) => {
                        return {
                            vendorId: tsv.vendor.id,
                            vendorName: tsv.vendor.name,
                        }
                    },
                ),
            }
        })

        setFormState({
            workStartDate: startDate,
            workEndDate: endDate,
            templateServices: templateServices,
        })

        setSelection(selectedUnits)
    }

    const handleSubmit = () => {
        const unitIds = Object.keys(finderSelection.unit).reduce<number[]>(
            (acc, idStr) => {
                const id = Number(idStr)
                if (finderSelection.unit[id]) {
                    acc.push(id)
                }
                return acc
            },
            [],
        )

        onSubmit(unitIds, formState)
    }

    useEffect(() => {
        loadPage()
    }, [])

    const labelStyle = {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                maxHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px)`,
                minHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px)`,
            }}
        >
            {/* Body */}
            <Container
                style={{
                    flex: 1,
                    maxHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
                    minHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
                }}
            >
                {/* Infrastructure Column */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        borderRight: `1px solid ${theme.palette.divider}`,
                        overflowY: 'scroll',
                    }}
                >
                    <Typography variant="h6" style={labelStyle}>
                        Select Units
                    </Typography>

                    <LocationForm
                        root={infrastructure}
                        locationSelection={finderSelection}
                        setLocationSelection={selectLocation}
                        useCheckbox
                        style={{ padding: theme.spacing(2) }}
                    />
                </Container>

                {/* Right Column */}

                <Container
                    style={{
                        flex: 3,
                        flexDirection: 'column',
                        overflowY: 'scroll',
                    }}
                >
                    {/* Date Selection */}
                    <Container style={{ flexDirection: 'column' }}>
                        <Typography variant="h6" style={labelStyle}>
                            Project timeline
                        </Typography>

                        <Container style={{ padding: theme.spacing(2) }}>
                            <KeyboardDatePicker
                                label="Work start"
                                format="MM/dd/yyyy"
                                value={formState.workStartDate}
                                onChange={(date) => {
                                    setFormState({
                                        ...formState,
                                        workStartDate: date ?? new Date(),
                                    })
                                }}
                                style={{ marginRight: theme.spacing(4) }}
                            />

                            <KeyboardDatePicker
                                label="Work end"
                                format="MM/dd/yyyy"
                                value={formState.workEndDate}
                                onChange={(date) => {
                                    setFormState({
                                        ...formState,
                                        workEndDate: date ?? new Date(),
                                    })
                                }}
                            />
                        </Container>
                    </Container>

                    {/* Service Selection */}
                    <Container style={{ flexDirection: 'column' }}>
                        <Typography variant="h6" style={labelStyle}>
                            Select Services
                        </Typography>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="services">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            minHeight: '100px',
                                            height: '100%',
                                            width: '100%',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {formState.templateServices.map(
                                            (service, index) => (
                                                <MemoizedServiceRow
                                                    key={service.serviceId}
                                                    index={index}
                                                    templateService={service}
                                                    theme={theme}
                                                    vendorList={
                                                        props.vendorList
                                                    }
                                                    formState={formState}
                                                    setFormState={setFormState}
                                                />
                                            ),
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                                height: 40,
                                textTransform: 'none',
                            }}
                            onClick={popoverState.handleOpen}
                        >
                            + Service
                        </Button>
                    </Container>
                </Container>
            </Container>

            {/* Footer */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    borderTop: `1px solid ${theme.palette.divider}`,
                    maxHeight: FOOTER_HEIGHT,
                    minHeight: FOOTER_HEIGHT,
                }}
            >
                <div style={{ flex: 1 }} />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ textTransform: 'none' }}
                    loading={scheduleController.loadingState.scheduleLoading}
                >
                    Submit
                </LoadingButton>
            </Container>

            <SelectorPopover
                {...props}
                popoverState={popoverState}
                data={props.serviceList}
                label="Select Service"
                onSubmit={(selectedId) => {
                    setFormState((prev) => {
                        const newTemplateServices = [...prev.templateServices]
                        const serviceIndex = newTemplateServices.findIndex(
                            (ts) => ts.serviceId === selectedId,
                        )

                        if (serviceIndex === -1) {
                            newTemplateServices.push({
                                serviceId: selectedId,
                                serviceName:
                                    props.serviceList.find(
                                        (s) => s.id === selectedId,
                                    )?.name ?? '',
                                templateServiceVendors: [],
                            })
                        }

                        return {
                            ...prev,
                            templateServices: newTemplateServices,
                        }
                    })
                }}
            />
        </Container>
    )
}

export type TemplateServiceFormState = {
    serviceId: number
    serviceName: string
    templateServiceVendors: {
        vendorId: number
        vendorName: string
    }[]
}

export type SetupSmartScheduleFormState = {
    workStartDate: Date
    workEndDate: Date
    templateServices: TemplateServiceFormState[]
}
