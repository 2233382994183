import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Switch,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { Container, SideDrawerContainer } from '../../components'
import { useSchedule, useUser } from '../../hooks'
import { Inspection, InspectionType, Schedule } from '../../models'
import { InventoryInspectionListAxiosReq, RootState } from '../../store'
import { axiosInstance } from '../../helpers'
import { InspectionFormDrawer } from './InspectionForm'
import { Mail } from '@material-ui/icons'
import { InspectionListTable } from './InspectionListTable'
import { CreateInspectionModal } from './CreateInspectionModal'
import { SearchField } from '../../components/SearchField'

interface Props {
    scheduleList: Schedule[]
    inspectionTypeList: InspectionType[]
    setOpenInspectionConfiguration: (
        value: React.SetStateAction<boolean>,
    ) => void
}

export const InspectionPage = (props: Props) => {
    const {
        scheduleList,
        inspectionTypeList,
        setOpenInspectionConfiguration,
    } = props

    const inspectionList = useSelector(
        (state: RootState) => state.inspection.inspectionList,
    )

    const { getScheduleList } = useSchedule()

    const [searchField, setSearchField] = useState('')

    const theme = useTheme()

    const currentYear = new Date().getFullYear()

    const [createInspectionModalOpen, setCreateInspectionModalOpen] = useState(
        false,
    )

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedInspection, setSelectedInspection] = useState<
        Inspection | undefined
    >()

    const [reportYear, setReportYear] = useState(currentYear)

    const [archivedFilter, setArchivedFilter] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const yearOptions = [currentYear]
    let yearOption = currentYear
    // 2021 because it was the first year of ezturn inspections
    while (yearOption > 2021) {
        yearOption--
        yearOptions.push(yearOption)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
        setSelectedInspection(undefined)
    }

    const { userList } = useUser()

    const archivedCount =
        inspectionList?.filter((ins) => !ins.active).length ?? 0

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 140px)',
                }}
            >
                {/* Header */}
                <Container style={{ alignItems: 'center' }}>
                    <Container style={{ flexDirection: 'column' }}>
                        <Container alignItems="center">
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Inspection
                            </span>

                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openPopover(e)
                                }}
                            >
                                <InfoIcon fontSize="small" color="action" />
                            </IconButton>
                        </Container>

                        <Popover
                            open={popOverOpen}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                        >
                            <Container
                                direction="column"
                                style={{
                                    padding: theme.spacing(2),
                                }}
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '16px',
                                    }}
                                >
                                    Steps to create an Inspection
                                </Container>
                                <Container>
                                    Step 1: Create or choose an Inspection Type
                                </Container>
                                <Container>
                                    Step 2: Create an Inspection
                                </Container>
                                <Container>
                                    Step 3: Assign an Inspection
                                </Container>
                            </Container>
                        </Popover>

                        <FormControlLabel
                            control={
                                <Switch
                                    value={archivedFilter}
                                    color="primary"
                                    onChange={() =>
                                        setArchivedFilter(!archivedFilter)
                                    }
                                />
                            }
                            labelPlacement="start"
                            label={`Show Archived (${archivedCount})`}
                            style={{ margin: 0 }}
                        />
                    </Container>

                    <div style={{ flex: 1 }} />
                    <FormControl
                        variant="outlined"
                        style={{ margin: theme.spacing(2) }}
                    >
                        <InputLabel id={`report-year-label`}>
                            Report Year
                        </InputLabel>
                        <Select
                            labelId={`report-year-label`}
                            id={`report-year-select`}
                            value={reportYear}
                            onChange={(e) => {
                                setReportYear(e.target.value as number)
                            }}
                            label="Report Year"
                            style={{ paddingLeft: theme.spacing(1) }}
                        >
                            {yearOptions.map((year) => (
                                <MenuItem
                                    key={`YEAR_SELECT__${year}`}
                                    value={year}
                                >
                                    <span>{year}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title="Email Year Report">
                        <IconButton
                            onClick={() => {
                                const startOfYear = new Date(reportYear, 0)
                                const endOfYear = new Date(
                                    reportYear,
                                    11,
                                    31,
                                    23,
                                    59,
                                    59,
                                    999,
                                )

                                const req: InventoryInspectionListAxiosReq = {
                                    params: {
                                        lower_bound_date: startOfYear.toISOString(),
                                        upper_bound_date: endOfYear.toISOString(),
                                    },
                                }
                                axiosInstance.get(
                                    'inspection/inventory-inspection/create_report/',
                                    req,
                                )
                                toast.success(
                                    'You will receive an email with your report shortly!',
                                )
                            }}
                        >
                            <Mail fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setCreateInspectionModalOpen(true)
                        }}
                    >
                        + Add Inspection
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenInspectionConfiguration(true)}
                    >
                        + Configure Inspection
                    </Button>
                    <SearchField
                        variant="outlined"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                        }}
                        value={searchField}
                        onChange={(e) => {
                            setSearchField(e.target.value)
                        }}
                        placeholder="Inspection Name"
                    />
                </Container>
                {/* End Header */}

                <InspectionListTable
                    inspectionList={inspectionList?.filter(
                        (inspection) =>
                            inspection.active !== archivedFilter &&
                            (searchField.trim() === '' ||
                                inspection.name
                                    .toLowerCase()
                                    .includes(searchField.toLowerCase())),
                    )}
                    selectedInspection={selectedInspection}
                    setSelectedInspection={setSelectedInspection}
                    setDrawerOpen={setDrawerOpen}
                    active={archivedFilter === false}
                />
            </Container>

            <InspectionFormDrawer
                open={drawerOpen}
                handleClose={() => {
                    handleDrawerClose()
                }}
                inspectionTypeList={inspectionTypeList ?? []}
                scheduleList={scheduleList ?? []}
                getScheduleList={getScheduleList}
                selectedInspection={selectedInspection}
            />

            <CreateInspectionModal
                open={createInspectionModalOpen}
                scheduleList={scheduleList}
                inspectionTypeList={inspectionTypeList ?? []}
                userList={userList}
                onClose={() => setCreateInspectionModalOpen(false)}
            />
        </SideDrawerContainer>
    )
}
