import React, { useEffect, useMemo, useState } from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import { ModelMap } from '../../models'
import { axiosInstance } from '../../helpers'
import { ToggleButtonGroup } from '@material-ui/lab'
import { AnalyticsTable } from './AnalyticsTable'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { toast } from 'react-toastify'
import SearchIcon from '@material-ui/icons/Search'
import { AnalyticsModal } from './AnalyticsModal'
import { Check } from '@material-ui/icons'

export interface AnalyticsResponseData {
    id: number
    name: string
    is_vendor: boolean
    type: string
    workorder_count?: number
    inspection_count?: number
    prior_week_workorder_count?: number
    prior_week_inspection_count?: number
    profile_picture?: string
}

export interface VendorWorkTime {
    id: number
    work_time: number
}

const ALL = 0
const MY_VENDORS = 1
const MY_TEAM = 2

const THIS_WEEK = 0
const LAST_WEEK = 1
const CUSTOM = 2

interface Props {
    portfolio_analytics_view?: boolean
    height?: string
}

export const AnalyticsHome = (props: Props) => {
    const { portfolio_analytics_view, height } = props

    const [teamFilter, setTeamFilter] = useState(ALL)
    const [dateFilter, setDateFilter] = useState(THIS_WEEK)
    const [startDate, setStartDate] = useState<MaterialUiPickersDate>(
        moment().day(1).toDate(),
    )
    const [endDate, setEndDate] = useState<MaterialUiPickersDate>(new Date())

    const [analyticsData, setAnalyticsData] = useState<AnalyticsResponseData[]>(
        [],
    )
    const [vendorWorkTimeMap, setVendorWorkTimeMap] = useState<
        ModelMap<VendorWorkTime>
    >({})

    const [searchText, setSearchText] = useState('')

    const [loading, setLoading] = useState(false)

    const [
        selectedAnalyticData,
        setSelectedAnalyticData,
    ] = useState<AnalyticsResponseData | null>(null)
    const [openAnalyticModal, setOpenAnalyticModal] = useState(false)

    const theme = useTheme()

    const ButtonGroupStyle: React.CSSProperties = {
        textTransform: 'none',
        fontSize: 15,
        fontWeight: 600,
    }

    const GetAnalyticData = (
        startDate: Date | MaterialUiPickersDate,
        endDate: Date | MaterialUiPickersDate,
    ) => {
        setLoading(true)
        startDate?.setHours(0, 0, 0)
        endDate?.setHours(0, 0, 0)
        const priorWeekStartDate = new Date()
        const priorWeekEndDate = new Date()
        if (startDate) {
            priorWeekStartDate.setDate(startDate.getDate() - 7)
        }
        if (endDate) {
            priorWeekEndDate.setDate(endDate.getDate() - 7)
        }

        let AnalyticsPromise = axiosInstance.get(
            `stats/vendor-and-user-analytics/`,
            {
                params: {
                    lte_date: endDate,
                    gte_date: startDate,
                    prior_week_lte_date: priorWeekEndDate,
                    prior_week_gte_date: priorWeekStartDate,
                },
            },
        )

        if (portfolio_analytics_view) {
            AnalyticsPromise = axiosInstance.get(
                `stats/vendor-portfolio-analytics/`,
                {
                    params: {
                        lte_date: endDate,
                        gte_date: startDate,
                        prior_week_lte_date: priorWeekEndDate,
                        prior_week_gte_date: priorWeekStartDate,
                    },
                },
            )
        }

        const WorkHoursPromise = axiosInstance.get(`stats/vendor-work-hours/`, {
            params: {
                lte_date: endDate,
                gte_date: startDate,
                all_properties: portfolio_analytics_view,
            },
        })

        Promise.all([AnalyticsPromise, WorkHoursPromise])
            .then((values) => {
                setAnalyticsData(values[0].data)
                const tempVendorWorkTimeMap: ModelMap<VendorWorkTime> = {}
                values[1].data.results.map((vendorWorkTime: VendorWorkTime) => {
                    tempVendorWorkTimeMap[vendorWorkTime.id] = vendorWorkTime
                })
                setVendorWorkTimeMap(tempVendorWorkTimeMap)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (dateFilter === THIS_WEEK) {
            setStartDate(moment().day(1).toDate())
            setEndDate(new Date())
        } else if (dateFilter === LAST_WEEK) {
            setStartDate(moment().day(-6).toDate())
            setEndDate(moment().day(0).toDate())
        }
    }, [dateFilter])

    useEffect(() => {
        GetAnalyticData(startDate, endDate)
    }, [])

    const filteredAnalyticsData = useMemo(() => {
        return analyticsData
            .filter((data) => {
                let typeValid = true
                if (teamFilter === MY_VENDORS) {
                    typeValid = data.is_vendor
                } else if (teamFilter === MY_TEAM) {
                    typeValid = !data.is_vendor
                }
                return (
                    typeValid &&
                    data.name
                        .toLocaleLowerCase()
                        .includes(searchText.toLocaleLowerCase())
                )
            })
            .sort(sortAnalyticDataCount)
    }, [analyticsData, searchText, teamFilter])

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                padding: theme.spacing(0),
                paddingBottom: theme.spacing(2),
                height: 'calc(100vh - 104px)',
            }}
        >
            {!portfolio_analytics_view && (
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Property Analytics
                    </span>
                </Container>
            )}
            <Container
                alignItems="center"
                style={{
                    flexWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: theme.spacing(1),
                }}
            >
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    style={{
                        marginRight: theme.spacing(4),
                        marginTop: theme.spacing(1),
                        minWidth: '200px',
                    }}
                />
                <ToggleButtonGroup
                    value={dateFilter}
                    aria-label="Date Filter"
                    exclusive
                    style={{
                        border: `1px solid ${theme.palette.primary.dark}`,
                        borderRadius: '5px',
                        minWidth: '200px',
                        marginRight: theme.spacing(2),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        value={THIS_WEEK}
                        aria-label="This Week"
                        style={{
                            ...ButtonGroupStyle,
                            backgroundColor:
                                dateFilter === THIS_WEEK
                                    ? theme.palette.primary.dark
                                    : '',
                            color: dateFilter === THIS_WEEK ? 'white' : '',
                        }}
                        onClick={() => {
                            setDateFilter(THIS_WEEK)
                            const tempStartDate = moment().day(1).toDate()
                            const tempEndDate = new Date()
                            setStartDate(tempStartDate)
                            setEndDate(tempEndDate)
                            GetAnalyticData(tempStartDate, tempEndDate)
                        }}
                    >
                        This Week
                    </Button>
                    <Button
                        value={LAST_WEEK}
                        aria-label="Last Week"
                        style={{
                            ...ButtonGroupStyle,
                            borderRight: `1px solid ${theme.palette.primary.dark}`,
                            borderLeft: `1px solid ${theme.palette.primary.dark}`,
                            backgroundColor:
                                dateFilter === LAST_WEEK
                                    ? theme.palette.primary.dark
                                    : '',
                            color: dateFilter === LAST_WEEK ? 'white' : '',
                        }}
                        onClick={() => {
                            setDateFilter(LAST_WEEK)
                            const tempStartDate = moment().day(-6).toDate()
                            const tempEndDate = moment().day(0).toDate()
                            setStartDate(tempStartDate)
                            setEndDate(tempEndDate)
                            GetAnalyticData(tempStartDate, tempEndDate)
                        }}
                    >
                        Last Week
                    </Button>
                    <Button
                        value={CUSTOM}
                        aria-label="Custom"
                        style={{
                            ...ButtonGroupStyle,
                            backgroundColor:
                                dateFilter === CUSTOM
                                    ? theme.palette.primary.dark
                                    : '',
                            color: dateFilter === CUSTOM ? 'white' : '',
                        }}
                        onClick={() => setDateFilter(CUSTOM)}
                    >
                        Custom
                    </Button>
                </ToggleButtonGroup>
                <KeyboardDatePicker
                    clearable
                    disabled={dateFilter !== CUSTOM}
                    value={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        if (endDate === null || (date && endDate < date)) {
                            //if the end date is before the start date, set the end date to the same as the start date
                            setEndDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    style={{
                        margin: theme.spacing(1, 2, 0, 2),
                        width: 180,
                        minWidth: '180px',
                    }}
                    label="Start Date"
                    size="small"
                />
                <KeyboardDatePicker
                    disabled={dateFilter !== CUSTOM}
                    error={endDate === null}
                    value={endDate}
                    onChange={(date) => {
                        if (date === null) {
                            return setEndDate(null)
                        }
                        if (startDate && date >= startDate) {
                            // if date is LATER than the start date
                            return setEndDate(date)
                        }
                        return (
                            startDate &&
                            toast.error(
                                'End Date must be the day of or after the Start Date',
                            )
                        )
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="End Date"
                    style={{
                        margin: theme.spacing(1, 2, 0, 2),
                        width: 180,
                        minWidth: '180px',
                    }}
                    size="small"
                />
                {dateFilter === CUSTOM && (
                    <Tooltip title="Apply">
                        <IconButton
                            component="span"
                            onClick={() => GetAnalyticData(startDate, endDate)}
                            style={{ padding: '6px' }}
                        >
                            <Check />
                        </IconButton>
                    </Tooltip>
                )}
                <Container flex={1} />
                {!portfolio_analytics_view && (
                    <ToggleButtonGroup
                        value={teamFilter}
                        aria-label="Team Filter"
                        exclusive
                        style={{
                            border: `1px solid ${theme.palette.primary.dark}`,
                            borderRadius: '5px',
                            minWidth: '200px',
                            marginTop: theme.spacing(1),
                        }}
                    >
                        <Button
                            value={ALL}
                            aria-label="All"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    teamFilter === ALL
                                        ? theme.palette.primary.dark
                                        : '',
                                color: teamFilter === ALL ? 'white' : '',
                            }}
                            onClick={() => setTeamFilter(ALL)}
                        >
                            All
                        </Button>
                        <Button
                            value={MY_VENDORS}
                            aria-label="My Vendors"
                            style={{
                                ...ButtonGroupStyle,
                                borderRight: `1px solid ${theme.palette.primary.dark}`,
                                borderLeft: `1px solid ${theme.palette.primary.dark}`,
                                backgroundColor:
                                    teamFilter === MY_VENDORS
                                        ? theme.palette.primary.dark
                                        : '',
                                color: teamFilter === MY_VENDORS ? 'white' : '',
                            }}
                            onClick={() => setTeamFilter(MY_VENDORS)}
                        >
                            My Vendors
                        </Button>
                        <Button
                            value={MY_TEAM}
                            aria-label="My Team"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    teamFilter === MY_TEAM
                                        ? theme.palette.primary.dark
                                        : '',
                                color: teamFilter === MY_TEAM ? 'white' : '',
                            }}
                            onClick={() => setTeamFilter(MY_TEAM)}
                        >
                            My Team
                        </Button>
                    </ToggleButtonGroup>
                )}
            </Container>
            {loading ? (
                <Container flex={1} alignItems="center" justifyContent="center">
                    <CircularProgress
                        size={100}
                        style={{ marginTop: theme.spacing(10) }}
                    />
                </Container>
            ) : (
                <AnalyticsTable
                    analyticsData={filteredAnalyticsData}
                    showLastWeekArrow={dateFilter === THIS_WEEK}
                    vendorWorkTimeMap={vendorWorkTimeMap}
                    setSelectedAnalyticData={setSelectedAnalyticData}
                    setOpenAnalyticModal={setOpenAnalyticModal}
                    height={height}
                />
            )}
            {selectedAnalyticData && (
                <AnalyticsModal
                    open={openAnalyticModal}
                    handleClose={() => setOpenAnalyticModal(false)}
                    data={selectedAnalyticData}
                    startDate={startDate}
                    endDate={endDate}
                    all_properties={portfolio_analytics_view}
                />
            )}
        </Container>
    )
}

export const sortAnalyticDataCount = (
    analyticA: AnalyticsResponseData,
    analyticB: AnalyticsResponseData,
) => {
    let analyticACount = 0
    if (analyticA.workorder_count) {
        analyticACount = analyticACount + analyticA.workorder_count
    }
    if (analyticA.inspection_count) {
        analyticACount = analyticACount + analyticA.inspection_count
    }

    let analyticBCount = 0
    if (analyticB.workorder_count) {
        analyticBCount = analyticBCount + analyticB.workorder_count
    }
    if (analyticB.inspection_count) {
        analyticBCount = analyticBCount + analyticB.inspection_count
    }

    if (analyticACount < analyticBCount) {
        return 1
    } else if (analyticACount > analyticBCount) {
        return -1
    }
    return 0
}
