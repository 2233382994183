import React from 'react'
import {
    Checkbox,
    Modal,
    Paper,
    Slide,
    TableCell,
    TableRow,
    TextField,
    useTheme,
} from '@material-ui/core'
import {
    CellData,
    Container,
    EditSpan,
    HeadCell,
    RenderRow,
    RowData,
    SimpleTable,
    StatusBadge,
} from '../../components'
import { CustomStatus } from '../../models'
import { useSelector } from 'react-redux'
import { RootState, setStatusList } from '../../store'
import { useStyles } from '../../styles'
import { axiosInstance } from '../../helpers'
import { useAppDispatch } from '../../hooks'
import { parse } from 'date-fns'

interface Props {
    open: boolean
    onClose: () => void
}

export const StatusModal = (props: Props) => {
    const { open, onClose } = props

    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const statusList = useSelector(
        (state: RootState) => state.aptConfig.statusList,
    )

    const [statusSearch, setStatusSearch] = React.useState<string>('')

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface StatusRowType extends RowData {
        name: CellData<JSX.Element>
        flag: CellData<JSX.Element>
        defaultScore: CellData<JSX.Element>
        status: CellData<CustomStatus>
    }

    const headCells: HeadCell<StatusRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'flag',
            label: 'Causes Flag',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'detaultScore',
            label: 'Default Score',
            style: headCellStyle,
        },
    ]

    const createRow = (status: CustomStatus): StatusRowType => {
        const textStyle: React.CSSProperties = {
            textAlign: 'center',
            fontWeight: 'bold',
        }
        const contianerSize: React.CSSProperties = { width: 140, height: 52 }
        const statusBadge = (
            <StatusBadge
                text={status.name}
                customStyle={{
                    container: {
                        backgroundColor: status.color,
                        ...contianerSize,
                    },
                    text: textStyle,
                }}
            />
        )

        const updateStatus = (body: any) => {
            axiosInstance
                .patch(`apt_config/custom_status/${status.id}/`, body)
                .then(() => {
                    // update status list
                    const newStatusList = statusList?.map((s) => {
                        if (s.id === status.id) {
                            return {
                                ...s,
                                causes_flag: !status.causes_flag,
                            }
                        }
                        return s
                    })
                    dispatch(setStatusList(newStatusList))
                })
        }

        const flagCheck = (
            <Checkbox
                checked={status.causes_flag}
                style={{
                    color: theme.palette.secondary.main,
                }}
                onChange={() => {
                    updateStatus({ causes_flag: !status.causes_flag })
                }}
            />
        )
        const defaultScore = (
            <EditSpan
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                }}
                maxLength={30}
                dataType="number"
                editIconTooltip="Edit Default Score"
                onSave={(name) => {
                    updateStatus({ default_score: parseInt(name) })
                }}
            >
                {status.default_score}
            </EditSpan>
        )

        return {
            name: { value: statusBadge, sortValue: status.name },
            flag: {
                value: flagCheck,
                sortValue: status.causes_flag ? 1 : 0,
            },
            defaultScore: {
                value: defaultScore,
                sortValue: status.default_score ?? 0,
            },
            status: {
                value: status,
                sortValue: 1,
            },
        }
    }

    interface StatusRowProps {
        row: StatusRowType
    }

    const filteredStatusList = statusList?.filter((s) =>
        s.name.toLowerCase().includes(statusSearch.toLowerCase()),
    )

    const StatusRow: RenderRow<StatusRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`INV_CONF_ROW_${row.status.value.id}`}
                style={{
                    cursor: 'pointer',
                }}
                className={classes.hoverGrey300}
            >
                <TableCell style={cellStyle}>{row.name.value}</TableCell>
                <TableCell style={cellStyle}>{row.flag.value}</TableCell>
                <TableCell style={cellStyle}>
                    {row.defaultScore.value}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: 600,
                            width: 1000,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            margin: theme.spacing(2),
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                height: 50,
                                padding: theme.spacing(0, 1),
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h4,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                }}
                            >
                                Status Configuration
                            </span>
                            <div style={{ flexGrow: 1 }} />
                            <TextField
                                placeholder="Search"
                                value={statusSearch}
                                onChange={(e) =>
                                    setStatusSearch(e.target.value)
                                }
                                style={{ width: 200 }}
                            />
                        </Container>

                        <SimpleTable<StatusRowType>
                            rows={
                                filteredStatusList?.map((s) => createRow(s)) ??
                                []
                            }
                            render={(row) => <StatusRow row={row} />}
                            orderByDefault={'name'}
                            headCells={headCells}
                        />
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
