import React, { useEffect, useState } from 'react'
import { Container } from '../../components'

import {
    Theme,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Drawer,
    Typography,
    TableContainer,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { DocumentBatch } from '../../models'
import { DropFiles } from '../../components/DropFiles'
import { LeaseAuditController } from '../../hooks/documentAudit/useLeaseAudit'

interface Props {
    theme: Theme
    height: number
    documentBatches: DocumentBatch[]
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

export const HistoryPage = (props: Props) => {
    const {
        theme,
        height,
        documentBatches,
        leaseAuditController,
        isActive,
    } = props
    const [selectedBatchIndex, setSelectedBatchIndex] = useState<number>(-1)

    const leaseAudit = leaseAuditController.leaseAudit

    const selectedBatch: undefined | DocumentBatch =
        documentBatches[selectedBatchIndex]

    const selectedDate = new Date(selectedBatch?.created_date)

    useEffect(() => {
        if (isActive && leaseAudit) {
            leaseAuditController.fetchDocumentBatches(leaseAudit.id)
        }
    }, [isActive, leaseAudit])

    return (
        <Container
            style={{
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            {/* header */}
            <Container></Container>

            {/* table */}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Uploaded Contracts</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentBatches.map((batch, index) => {
                            const createdDate = new Date(batch.created_date)
                            return (
                                <TableRow key={batch.id}>
                                    <TableCell>
                                        {createdDate.toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {batch.created_by?.name}
                                    </TableCell>
                                    <TableCell>
                                        {batch.document_occurances.length}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                setSelectedBatchIndex(index)
                                            }
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* drawer */}
            {/* TODO: change drawer to display the status counts of the docs instead */}
            <Drawer
                open={selectedBatchIndex !== -1}
                anchor="right"
                onClose={() => setSelectedBatchIndex(-1)}
            >
                <Container style={{ flexDirection: 'column', width: 300 }}>
                    <Container
                        style={{
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            padding: theme.spacing(1),
                        }}
                    >
                        <Container style={{ flexDirection: 'column' }}>
                            <span
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {selectedDate.toLocaleDateString()}
                            </span>
                            <span
                                style={{
                                    ...theme.typography.caption,
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {selectedBatch?.created_by.name}
                            </span>
                        </Container>
                        <div style={{ flex: 1 }} />
                        <IconButton onClick={() => setSelectedBatchIndex(-1)}>
                            <CloseIcon />
                        </IconButton>
                    </Container>
                    {selectedBatch?.document_occurances.map(
                        (documentOccurance) => {
                            return (
                                <Container
                                    key={documentOccurance.id}
                                    style={{
                                        padding: theme.spacing(1),
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Container
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <span>{documentOccurance.id}</span>
                                        <span>
                                            {
                                                documentOccurance.document
                                                    .file_name
                                            }
                                        </span>
                                    </Container>

                                    <IconButton
                                        onClick={() => {
                                            window.open(
                                                documentOccurance.document.file,
                                                '_blank',
                                            )
                                        }}
                                    >
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Container>
                            )
                        },
                    )}
                </Container>
            </Drawer>

            {/* footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    marginTop: theme.spacing(2),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <DropFiles
                        onDrop={(files) => {
                            if (leaseAuditController.leaseAudit) {
                                leaseAuditController.uploadLeaseContractBatch(
                                    files,
                                    leaseAuditController.leaseAudit.id,
                                )
                            }
                        }}
                        displayString="Drop leases here to upload"
                    />
                </Container>
            </Container>
        </Container>
    )
}
