import React, { useState } from 'react'

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
    Typography,
    makeStyles,
    Button,
    useTheme,
    Radio,
    FormControlLabel,
    RadioGroup,
    TextField,
    CircularProgress,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
    convertListToMap,
    InspectionType,
    ListVendor,
    MoveOutRule,
    Service,
} from '../../models'
import {
    footerH,
    headerH,
    modalH,
} from '../ScheduleHome/ViewComponents/LeaseModal/constants'
import { Container } from '../../components'
import { ScheduleMoveOutRequest } from '../../hooks/useMoveOutRules'
import { SelectionController } from '../../hooks/useSelectionMap'
import { LeaseController, useDateRange } from '../../hooks'
import { toast } from 'react-toastify'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'

interface Props {
    moveOutRuleList: MoveOutRule[]
    vendorList: ListVendor[]
    serviceList: Service[]
    inspectionTypeList: InspectionType[]
    leaseController: LeaseController
    selectionController: SelectionController
    selectedDate: Date
    onClickBack: () => void
    scheduleMoveOut: (request: ScheduleMoveOutRequest) => Promise<void>
    onReloadData: () => void
    handleClose: () => void
}

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    step: {
        paddingLeft: theme.spacing(2),
    },
    vendorRule: {
        paddingLeft: theme.spacing(4),
    },
    listItem: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            transform: 'translateY(-2px)',
        },
    },
    stepItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.3s ease',
    },
    stepContent: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    stepNumber: {
        marginRight: theme.spacing(2),
        minWidth: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    stepInfo: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
    stepName: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        textAlign: 'left',
    },
    stepType: {
        color: theme.palette.text.secondary,
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0.5),
    },
    stepTypeIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem',
    },
    expandButton: {
        marginLeft: 'auto',
    },
    vendorRuleList: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
    },
    confirmButton: {
        marginTop: theme.spacing(2),
    },
    stepContainer: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    vendorRulesCount: {
        marginLeft: 'auto',
        fontSize: '0.9rem',
        color: theme.palette.text.secondary,
    },
    ruleItem: {
        '&:hover $stepItem': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            transform: 'translateY(-2px)',
        },
    },
    selectedRule: {
        backgroundColor: theme.palette.action.selected,
    },
    list: {
        overflow: 'auto',
        maxHeight: modalH - headerH - headerH - 130 - theme.spacing(2),
        minHeight: modalH - headerH - headerH - 130 - theme.spacing(2),
    },
}))

export const ChooseMoveOutRule = (props: Props) => {
    const {
        moveOutRuleList,
        serviceList,
        vendorList,
        inspectionTypeList,
        selectionController,
        leaseController,
        selectedDate,
        onClickBack,
        scheduleMoveOut,
        onReloadData,
        handleClose,
    } = props

    const classes = useStyles()
    const theme = useTheme()

    const [openRuleIds, setOpenRuleIds] = useState<number[]>([])
    const [selectedRuleId, setSelectedRuleId] = useState<number | null>(null)
    const [openStepIds, setOpenStepIds] = useState<number[]>([])
    const [startDate, setStartDate] = useState<Date | null>(
        selectedDate ? selectedDate : new Date(),
    )

    const [name, setName] = useState('')
    const [scheduleId, setScheduleId] = useState('')
    const [doValidate, setDoValidate] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const vendorMap = convertListToMap(vendorList)
    const serviceMap = convertListToMap(serviceList)
    const inspectionTypeMap = convertListToMap(inspectionTypeList)

    const handleToggleRule = (ruleId: number) => {
        setOpenRuleIds((prevOpenRuleIds) =>
            prevOpenRuleIds.includes(ruleId)
                ? prevOpenRuleIds.filter((id) => id !== ruleId)
                : [...prevOpenRuleIds, ruleId],
        )
    }

    const handleToggleStep = (stepId: number) => {
        setOpenStepIds((prevOpenStepIds) =>
            prevOpenStepIds.includes(stepId)
                ? prevOpenStepIds.filter((id) => id !== stepId)
                : [...prevOpenStepIds, stepId],
        )
    }

    const handleSelectRule = (ruleId: number) => {
        setSelectedRuleId(ruleId)
    }

    const getServiceName = (serviceId: number) => {
        const service = serviceMap[serviceId]
        return service ? service.name : `Unknown Service (${serviceId})`
    }

    const getInspectionTypeName = (inspectionTypeId: number) => {
        const inspectionType = inspectionTypeMap[inspectionTypeId]
        return inspectionType
            ? inspectionType.name
            : `Unknown Inspection Type (${inspectionTypeId})`
    }

    const getVendorName = (vendorId: number) => {
        const vendor = vendorMap[vendorId]
        return vendor ? vendor.name : `Unknown Vendor (${vendorId})`
    }

    const isValid = () => {
        const nameValid = name !== ''
        const scheduleIdValid = scheduleId !== ''
        const leasesValid = selectedLeases.length !== 0
        const ruleValid = selectedRuleId !== null

        return nameValid && scheduleIdValid && leasesValid && ruleValid
    }

    const selectedLeases = selectionController.getSelectedItems(
        leaseController.leaseList,
    )

    const disabled =
        selectedRuleId === null ||
        selectedLeases.length === 0 ||
        name === '' ||
        scheduleId === '' ||
        startDate === null ||
        isLoading

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Typography variant="h6">Choose Move Out Rule</Typography>
            <RadioGroup
                value={selectedRuleId}
                onChange={(event) =>
                    handleSelectRule(Number(event.target.value))
                }
            >
                <List className={classes.list}>
                    {moveOutRuleList.map((rule) => (
                        <Container
                            key={rule.id}
                            className={`${classes.listItem} ${
                                selectedRuleId === rule.id
                                    ? classes.selectedRule
                                    : ''
                            }`}
                            style={{ flexDirection: 'column' }}
                        >
                            <ListItem style={{ width: '100%' }}>
                                <FormControlLabel
                                    value={rule.id}
                                    control={<Radio />}
                                    label=""
                                    style={{ marginRight: theme.spacing(2) }}
                                />
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.stepName}
                                        >
                                            {rule.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            className={classes.stepType}
                                        >
                                            {`${rule.steps.length} step${
                                                rule.steps.length !== 1
                                                    ? 's'
                                                    : ''
                                            }`}
                                            {rule.allow_weekends
                                                ? ' (Allows Weekends)'
                                                : ''}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            handleToggleRule(rule.id)
                                        }
                                    >
                                        {openRuleIds.includes(rule.id) ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <StepRow
                                rule={rule}
                                openRuleIds={openRuleIds}
                                openStepIds={openStepIds}
                                getServiceName={getServiceName}
                                getInspectionTypeName={getInspectionTypeName}
                                getVendorName={getVendorName}
                                handleToggleStep={handleToggleStep}
                            />
                        </Container>
                    ))}
                </List>
            </RadioGroup>
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                }}
            >
                <Container>
                    <TextField
                        error={doValidate && name === ''}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Project Name"
                        style={{ flex: 1, marginRight: theme.spacing(2) }}
                    />

                    <TextField
                        error={doValidate && scheduleId === ''}
                        variant="outlined"
                        value={scheduleId}
                        onChange={(e) => setScheduleId(e.target.value)}
                        label="Project Id"
                        inputProps={{ maxLength: 8 }}
                        style={{ flex: 1 }}
                    />
                </Container>
                <Container>
                    <KeyboardDatePicker
                        error={startDate === null}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{ flex: 1, marginTop: theme.spacing(1) }}
                        label="Project Start Date"
                    />
                </Container>
            </Container>
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: footerH,
                    maxHeight: footerH,
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    color="secondary"
                    onClick={onClickBack}
                >
                    Cancel
                </Button>
                <Container style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{ textTransform: 'none', width: 80 }}
                    color="primary"
                    disabled={disabled}
                    onClick={() => {
                        setDoValidate(true)
                        if (!isValid()) {
                            return
                        }

                        if (selectedRuleId === null) {
                            return
                        }

                        setIsLoading(true)

                        const leaseIds = selectedLeases.map((lease) => lease.id)

                        const request: ScheduleMoveOutRequest = {
                            move_out_rule: selectedRuleId,
                            lease_ids: leaseIds,
                            schedule_name: name,
                            schedule_id: scheduleId,
                            start_date: selectedDate.toISOString() ?? '',
                        }

                        scheduleMoveOut(request)
                            .then(() => {
                                toast.success(
                                    'Move Out Events scheduled successfully!',
                                )
                                onReloadData()
                                handleClose()
                            })
                            .catch((e) => {
                                toast.error(e.response.data.message)
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size={25} />
                    ) : (
                        <span>Confirm</span>
                    )}
                </Button>
            </Container>
        </Container>
    )
}

interface StepRowProps {
    rule: MoveOutRule
    openRuleIds: number[]
    openStepIds: number[]
    getServiceName: (serviceId: number) => string
    getInspectionTypeName: (inspectionTypeId: number) => string
    getVendorName: (vendorId: number) => string
    handleToggleStep: (stepId: number) => void
}

const StepRow = (props: StepRowProps) => {
    const {
        rule,
        openRuleIds,
        openStepIds,
        getServiceName,
        getInspectionTypeName,
        getVendorName,
        handleToggleStep,
    } = props

    const classes = useStyles()
    const theme = useTheme()

    return (
        <Collapse
            in={openRuleIds.includes(rule.id)}
            timeout="auto"
            unmountOnExit
        >
            <List component="div" disablePadding>
                {rule.steps.map((step, index) => (
                    <div key={step.id} className={classes.stepItem}>
                        <div className={classes.stepContent}>
                            <Typography className={classes.stepNumber}>
                                {step.rank}
                            </Typography>
                            <div className={classes.stepInfo}>
                                <Typography className={classes.stepName}>
                                    {step.service
                                        ? getServiceName(step.service)
                                        : getInspectionTypeName(
                                              step.inspection_type ?? -1,
                                          )}
                                </Typography>
                                <Typography className={classes.stepType}>
                                    {step.service
                                        ? 'Service'
                                        : 'Inspection Type'}
                                </Typography>
                            </div>
                            {step.service && (
                                <>
                                    <Typography
                                        className={classes.vendorRulesCount}
                                    >
                                        {`${
                                            step.vendor_rules.length
                                        } vendor rule${
                                            step.vendor_rules.length !== 1
                                                ? 's'
                                                : ''
                                        }`}
                                    </Typography>
                                    <IconButton
                                        className={classes.expandButton}
                                        onClick={() =>
                                            handleToggleStep(step.id)
                                        }
                                    >
                                        {openStepIds.includes(step.id) ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </IconButton>
                                </>
                            )}
                        </div>
                        {step.service && (
                            <Collapse
                                in={openStepIds.includes(step.id)}
                                timeout="auto"
                                unmountOnExit
                            >
                                <div className={classes.vendorRuleList}>
                                    <List component="div" disablePadding>
                                        {step.vendor_rules.map(
                                            (vendorRule, idx) => (
                                                <ListItem
                                                    key={vendorRule.id}
                                                    className={
                                                        classes.vendorRule
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={`${
                                                            idx + 1
                                                        }. ${getVendorName(
                                                            vendorRule.vendor,
                                                        )}`}
                                                    />
                                                </ListItem>
                                            ),
                                        )}
                                    </List>
                                </div>
                            </Collapse>
                        )}
                    </div>
                ))}
            </List>
        </Collapse>
    )
}
