import { RefObject, useRef, useState } from 'react'

export type OpenPopoverHandler<T> = (
    event: React.MouseEvent<HTMLElement>,
    context?: T,
) => void

export type PopoverState<T> = {
    isOpen: boolean
    anchorEl: HTMLElement | null
    handleOpen: OpenPopoverHandler<T>
    handleClose: () => void
    fileSelectorRef: RefObject<HTMLInputElement>
    context: T | null
}

export const usePopover = <T>(): PopoverState<T> => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const [context, setContext] = useState<T | null>(null)

    const fileSelectorRef = useRef<HTMLInputElement>(null)

    const handleOpen: OpenPopoverHandler<T> = (event, context) => {
        setAnchorEl(event.currentTarget)

        if (context) {
            setContext(context)
        } else {
            setContext(null)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
        setContext(null)
    }

    const isOpen = Boolean(anchorEl)

    return {
        isOpen,
        anchorEl,
        handleOpen,
        handleClose,
        fileSelectorRef,
        context,
    }
}
