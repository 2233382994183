import { Dispatch, SetStateAction, useState } from 'react'
import { IdentifiableObject, OnCallDay } from '../models'
import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers'

export const useOnCallSchedule = () => {
    const [onCallDays, setOnCallDays] = useState<OnCallDay[]>([])

    const getOnCallDays = async () => {
        try {
            const res = await axiosInstance.get('company/on_call_day/')
            const newOnCallDays: OnCallDay[] = res.data
            setOnCallDays(newOnCallDays)
            return onCallDays
        } catch (e) {
            toast.error('Error getting on call days')
            return Promise.reject(e)
        }
    }

    const createOnCallDays = async (request: CreateOnCallDayRequest) => {
        try {
            const res = await axiosInstance.post(
                'company/on_call_day/',
                request,
            )
            const newOnCallDays: OnCallDay[] = res.data
            newOnCallDays.forEach((onCallDay) => {
                _insertOrUpdateIdentifiable(onCallDay, setOnCallDays)
            })
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const deleteOnCallDay = async (id: number) => {
        try {
            await axiosInstance.delete(`company/on_call_day/${id}/`)
            setOnCallDays((prev) => prev.filter((day) => day.id !== id))
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        onCallDays,
        getOnCallDays,
        createOnCallDays,
        deleteOnCallDay,
    }
}

export interface CreateOnCallDayRequest {
    user: number
    dates: string[]
}
