import React from 'react'
import { CircularProgress, Theme } from '@material-ui/core'
import { Container } from '../Container'

export interface UploadBatch {
    file: File
    presignedUrl: string
}

interface Props {
    theme: Theme
    uploadBatch: UploadBatch[]
    currentFileIndex: number
}

export const UploadProgress = (props: Props) => {
    const { theme, uploadBatch, currentFileIndex } = props

    if (uploadBatch.length === 0) {
        return null
    }

    if (currentFileIndex >= uploadBatch.length) {
        return null
    }

    const file = uploadBatch[currentFileIndex]?.file

    return (
        <Container
            style={{
                height: 150,
                width: 600,
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                zIndex: theme.zIndex.drawer + 1,
                border: `2px solid ${theme.palette.divider}`,
                padding: theme.spacing(2),
                boxShadow: theme.shadows[2],
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flex: 1,
                }}
            >
                <Container
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Uploading Leases
                    </span>
                </Container>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    {currentFileIndex} / {uploadBatch.length}
                </span>
                <span
                    style={{
                        ...theme.typography.subtitle2,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: theme.typography.fontWeightMedium,
                        color: theme.palette.text.secondary,
                    }}
                >
                    {file?.name}
                </span>
            </Container>

            <CircularProgress />
        </Container>
    )
}
