import {
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tab,
    Tabs,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Container } from '../../components'
import { AnalyticsResponseData } from './AnalyticsHome'
import CloseIcon from '@material-ui/icons/Close'
import { displayTotalWork } from './AnalyticsRow'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { axiosInstance } from '../../helpers'
import { UnitInspection, WorkorderType, _Workorder } from '../../models'
import { useStyles } from '../../styles'
import { AnalyticsWorkorderTable } from './AnalyticsWorkorderTable'
import { AnalyticsInspectionTable } from './AnalyticsInspectionTable'

interface Props {
    open: boolean
    data: AnalyticsResponseData
    startDate: MaterialUiPickersDate
    endDate: MaterialUiPickersDate
    handleClose: () => void
    all_properties?: boolean
}

const SERVICE_ORDER_TAB = 0
const ACTION_ITEM_TAB = 1
const SERVICE_REQUEST_TAB = 2
const INSPECTION_TAB = 3

export const AnalyticsModal = (props: Props) => {
    const {
        open,
        handleClose,
        data,
        startDate,
        endDate,
        all_properties,
    } = props

    const [workorders, setWorkorders] = useState<_Workorder[]>([])
    const [unitInspections, setUnitInspections] = useState<UnitInspection[]>([])

    const [tab, setTab] = useState(SERVICE_ORDER_TAB)

    const [loading, setLoading] = useState(true)

    const theme = useTheme()

    const classes = useStyles()

    const InfoStyle: React.CSSProperties = {
        fontSize: 16,
        fontWeight: 500,
        marginRight: theme.spacing(2),
        color: theme.palette.darkGreen.main,
    }

    const tabStyle: React.CSSProperties = {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    }

    useEffect(() => {
        if (open) {
            startDate?.setHours(0, 0, 0)
            endDate?.setHours(0, 0, 0)
            axiosInstance
                .get(`stats/vendor-and-user-analytics-details/`, {
                    params: {
                        lte_date: endDate,
                        gte_date: startDate,
                        id: data.id,
                        is_vendor: data.is_vendor,
                        all_properties: all_properties,
                    },
                })
                .then((res) => {
                    setWorkorders(res.data.workorders)
                    if (res.data.inspections) {
                        setUnitInspections(res.data.inspections)
                    } else {
                        setUnitInspections([])
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setWorkorders([])
            setUnitInspections([])
            setLoading(true)
        }
        setTab(SERVICE_ORDER_TAB)
    }, [open])

    const serviceOrders = useMemo(() => {
        return workorders.filter(
            (wo) => wo.type === WorkorderType.SERVICE_ORDER,
        )
    }, [workorders])

    const actionItems = useMemo(() => {
        return workorders.filter((wo) => wo.type === WorkorderType.ACTION_ITEM)
    }, [workorders])

    const serviceRequests = useMemo(() => {
        return workorders.filter(
            (wo) => wo.type === WorkorderType.SERVICE_REQUEST,
        )
    }, [workorders])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '1200px',
                        height: '800px',
                        maxHeight: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        {/* Header */}
                        <Container
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 600,
                                    fontSize: '25px',
                                    flex: 1,
                                }}
                            >
                                Analytics Details
                            </span>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        {/* <Divider /> */}
                        <Container
                            style={{
                                alignItems: 'center',
                                margin: theme.spacing(2, 0, 1, 0),
                            }}
                        >
                            <Container style={{ ...InfoStyle }}>
                                Name: {data.name}
                            </Container>
                            <Container style={{ ...InfoStyle }}>
                                Type: {data.type}
                            </Container>
                            <Container style={{ ...InfoStyle }}>
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    Total Work:
                                </Container>
                                {displayTotalWork(data, false)}
                            </Container>
                            <Container style={{ ...InfoStyle }}>
                                {startDate?.toDateString()} -{' '}
                                {endDate?.toDateString()}
                            </Container>
                        </Container>
                        {loading ? (
                            <Container
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress size={100} />
                            </Container>
                        ) : (
                            <Container direction="column">
                                <Container>
                                    <Tabs
                                        style={{
                                            color: 'black',
                                            borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                                            flex: 1,
                                            textTransform: 'none',
                                        }}
                                        value={tab}
                                        onChange={(_, v) => {
                                            if (v !== tab) {
                                                setTab(v)
                                            }
                                        }}
                                        classes={{
                                            indicator: classes.indicator,
                                        }}
                                    >
                                        <Tab
                                            label={`Service Orders (${serviceOrders.length})`}
                                            style={{ ...tabStyle }}
                                        />
                                        <Tab
                                            label={`Action Items (${actionItems.length})`}
                                            style={{ ...tabStyle }}
                                        />
                                        <Tab
                                            label={`Service Requests (${serviceRequests.length})`}
                                            style={{ ...tabStyle }}
                                        />
                                        {!data.is_vendor && (
                                            <Tab
                                                label={`Inspections (${unitInspections.length})`}
                                                style={{ ...tabStyle }}
                                            />
                                        )}
                                    </Tabs>
                                </Container>
                                {tab === SERVICE_ORDER_TAB && (
                                    <AnalyticsWorkorderTable
                                        workorders={serviceOrders}
                                        showProperty={all_properties}
                                    />
                                )}
                                {tab === ACTION_ITEM_TAB && (
                                    <AnalyticsWorkorderTable
                                        workorders={actionItems}
                                        showProperty={all_properties}
                                    />
                                )}
                                {tab === SERVICE_REQUEST_TAB && (
                                    <AnalyticsWorkorderTable
                                        workorders={serviceRequests}
                                        showProperty={all_properties}
                                    />
                                )}
                                {tab === INSPECTION_TAB && (
                                    <AnalyticsInspectionTable
                                        unitInspections={unitInspections}
                                    />
                                )}
                            </Container>
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
