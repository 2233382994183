import { AxiosError } from 'axios'
import { AppThunk, LOGIN_REQUEST } from '../'

import { axiosInstance, LocalStorage } from '../../helpers'
import { User, WorkSpaceUser } from '../../models'
import { setNetworkError } from '../error'
import { ErrorResponse } from '../types'

import {
    LoginRequest,
    SET_ROOT_USER,
    UserActionTypes,
    GET_USER_LIST_REQUEST,
    LoginActionThunk,
    BaseUserListThunk,
    GetUserListRequest,
    CreateUserRequest,
    UserDetailActionThunk,
    CREATE_USER_REQUEST,
    CREATE_USER_RESPONSE,
    UpdateUserRequest,
    UPDATE_USER_REQUEST,
    UserRequests,
    SET_USER_LOADING,
    GetRootUserRequest,
    GET_ROOT_USER_REQUEST,
    SET_WORKSPACE_USER,
    SET_USER_LIST,
    UPDATE_USER_LIST,
    REMOVE_USER_FROM_LIST,
    ARCHIVE_USER_REQUEST,
    ArchiveUserRequest,
    UpdateUserRequestFormData,
    SET_ORGANIZATION,
} from './types'

export const login = (req: LoginRequest): AppThunk<LoginActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setUserLoading(LOGIN_REQUEST, true))

        const url = 'user/login/'

        try {
            // success
            const response = await axiosInstance.post(url, req)
            const rootUser = response.data
            dispatch(setRootUser(rootUser))
            dispatch(setWorkSpaceUser(rootUser.workspaces[0]))
            dispatch(setUserLoading(LOGIN_REQUEST, false))
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, false))
            dispatch(setUserLoading(LOGIN_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getUserList = (
    req: GetUserListRequest,
): AppThunk<BaseUserListThunk> => {
    return async (dispatch) => {
        //start the request
        dispatch(setUserLoading(GET_USER_LIST_REQUEST, true))

        const url = 'user/'

        try {
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setUserLoading(GET_USER_LIST_REQUEST, false))
            dispatch(setUserList(response.data))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>

            dispatch(setUserLoading(GET_USER_LIST_REQUEST, false))
            dispatch(setNetworkError(error, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createUser = (
    req: CreateUserRequest,
): AppThunk<UserDetailActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_USER_REQUEST,
        })

        const url = 'user/'

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: CREATE_USER_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateUser = (
    req: UpdateUserRequest | UpdateUserRequestFormData,
): AppThunk<UserDetailActionThunk> => {
    return async (dispatch) => {
        dispatch(setUserLoading(UPDATE_USER_REQUEST, true))
        const url = `user/${req.params.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            if (req.isCurrentUser) {
                dispatch(setWorkSpaceUser(response.data))
            }
            dispatch(updateUserList(response.data))

            dispatch(setUserLoading(UPDATE_USER_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getRootUser = (
    req: GetRootUserRequest,
): AppThunk<UserDetailActionThunk> => {
    return async (dispatch) => {
        dispatch(setUserLoading(GET_ROOT_USER_REQUEST, true))

        const url = `user/get_root_user/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setRootUser(response.data))
            dispatch(setUserLoading(GET_ROOT_USER_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setUserLoading(GET_ROOT_USER_REQUEST, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const archiveUser = (
    req: ArchiveUserRequest,
): AppThunk<UserDetailActionThunk> => {
    return async (dispatch) => {
        dispatch(setUserLoading(ARCHIVE_USER_REQUEST, true))

        const url = `user/${req.user.id}/archive/`

        try {
            const response = await axiosInstance.post(url)

            dispatch(removeUserFromList(req.user))
            dispatch(setUserLoading(ARCHIVE_USER_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setUserLoading(ARCHIVE_USER_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setUserLoading = (
    request: UserRequests,
    loading: boolean,
): UserActionTypes => {
    return {
        type: SET_USER_LOADING,
        request: request,
        isLoading: loading,
    }
}

export const setWorkSpaceUser = (user?: WorkSpaceUser): UserActionTypes => {
    if (user && user.token) {
        localStorage.setItem(LocalStorage.EZTURN4_TOKEN, user.token)
        axiosInstance.defaults.headers.common[
            'Authorization'
        ] = `token ${user.token}`
    }

    return {
        type: SET_WORKSPACE_USER,
        user: user,
    }
}

export const setRootUser = (user?: User): UserActionTypes => {
    if (user && user.token && user.workspaces.length === 0) {
        localStorage.setItem(LocalStorage.EZTURN4_TOKEN, user.token)
        axiosInstance.defaults.headers.common[
            'Authorization'
        ] = `token ${user.token}`
    }

    return {
        type: SET_ROOT_USER,
        user: user,
    }
}

export const setUserList = (userList: User[]): UserActionTypes => {
    return {
        type: SET_USER_LIST,
        userList: userList,
    }
}

export const updateUserList = (user: User): UserActionTypes => {
    return {
        type: UPDATE_USER_LIST,
        user: user,
    }
}

export const removeUserFromList = (user: User): UserActionTypes => {
    return {
        type: REMOVE_USER_FROM_LIST,
        user: user,
    }
}

export const setOrganization = (organization: number): UserActionTypes => {
    return {
        type: SET_ORGANIZATION,
        organization: organization,
    }
}
