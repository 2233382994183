import React, { useState } from 'react'
import { Report } from '../../models'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'
import {
    CircularProgress,
    IconButton,
    TableCell,
    TableRow,
    useTheme,
    Snackbar,
    Tooltip,
} from '@material-ui/core'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import { useStyles } from '../../styles'
import { FileCopy } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import PDFMerger from 'pdf-merger-js/browser'

interface Props {
    reports: Report[]
}

export const ReportTable = (props: Props) => {
    const { reports } = props

    const [reportGenerating, setReportGenerating] = useState(false)
    const [generatingReportId, setGeneratingReportId] = useState<
        number | null
    >()
    const [openToast, setOpenToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const theme = useTheme()
    const classes = useStyles()

    interface ReportRow extends RowData {
        report_name: CellData<string>
        created_by: CellData<string>
        created_date: CellData<string>
        report_string: CellData<string>
        report: CellData<Report>
    }

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<ReportRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'report_name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'created_by',
            label: 'Created By',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'created_date',
            label: 'Created Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'report',
            label: 'Report',
            style: headCellStyle,
        },
    ]

    const generateExcelReport = async (xlsx_report: string) => {
        // Create an HTML page that embeds the Excel file
        const html = `
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <title>Excel Viewer</title>
                        <style>
                            body, html {
                                margin: 0;
                                padding: 0;
                                height: 100%;
                                overflow: hidden;
                            }
                            iframe {
                                width: 100%;
                                height: calc(100% - 50px);
                                border: none;
                            }
                            .header {
                                height: 50px;
                                background: #f5f5f5;
                                display: flex;
                                align-items: center;
                                padding: 0 20px;
                                box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                            }
                            .download-btn {
                                padding: 8px 16px;
                                background: #1976d2;
                                color: white;
                                border: none;
                                border-radius: 4px;
                                cursor: pointer;
                                font-family: Arial, sans-serif;
                            }
                            .download-btn:hover {
                                background: #1565c0;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="header">
                            <button class="download-btn" onclick="window.location.href='${xlsx_report}'">
                                Download File
                            </button>
                        </div>
                        <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            xlsx_report,
                        )}" />
                    </body>
                    </html>
                `

        const htmlBlob = new Blob([html], { type: 'text/html' })
        const htmlUrl = URL.createObjectURL(htmlBlob)
        window.open(htmlUrl)
    }

    const generatePDFReport = async (report: Report) => {
        setReportGenerating(true)
        setGeneratingReportId(report.id)

        const merger = new PDFMerger()

        // using traditional for structure to make awaits function properly
        for (const page of report.report_pages) {
            for (const file of page.report_files) {
                await fetch(file.file)
                    .then((res) => {
                        return res.blob()
                    })
                    .then(async (arrayBuffer) => {
                        await merger.add(arrayBuffer)
                    })
            }
        }

        const mergedPDF = await merger.saveAsBlob()
        const url = URL.createObjectURL(mergedPDF)
        window.open(url)

        // save full report to database to create a link
        try {
            await axiosInstance.post('/stats/reports/save-report/', {
                report_id: report.id,
            })
        } catch (error) {
            console.log(error)
            console.error('Failed to save report:', error)
        }

        setReportGenerating(false)
        setGeneratingReportId(null)
    }

    const createReportRow = (report: Report): ReportRow => {
        return {
            report_name: { value: report.name, sortValue: report.name },
            created_date: {
                value: report.creation_time,
                sortValue: report.creation_time,
            },
            created_by: {
                value: report.generated_by.name,
                sortValue: report.generated_by.name,
            },
            report_string: { value: report.xlsx_report ?? '', sortValue: -1 },
            report: { value: report, sortValue: -1 },
        }
    }

    const handleCopyLink = (url: string) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setToastMessage('Link copied to clipboard!')
                setOpenToast(true)
            })
            .catch(() => {
                setToastMessage('Failed to copy link')
                setOpenToast(true)
            })
    }

    return (
        <>
            <SimpleTable<ReportRow>
                rows={reports.map((report) => createReportRow(report)) ?? []}
                render={(row) => {
                    const createdDate = row.created_date.value
                        ? toMMDDYYYY(new Date(row.created_date.value))
                        : ''

                    const rowStyle: React.CSSProperties = {
                        cursor: 'pointer',
                    }
                    return (
                        <TableRow
                            key={`REPORT_ROW_${row.report.value.id}`}
                            className={classes.hoverGrey200}
                            style={rowStyle}
                        >
                            <TableCell>{row.report_name.value}</TableCell>
                            <TableCell>{row.created_by.value}</TableCell>
                            <TableCell>{createdDate}</TableCell>
                            <TableCell>
                                <Container
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Container>
                                        {reportGenerating &&
                                        row.report.value.id ===
                                            generatingReportId ? (
                                            <CircularProgress
                                                size={30}
                                                style={{
                                                    margin: theme.spacing(2),
                                                }}
                                            />
                                        ) : (
                                            <Tooltip title="View Report">
                                                <IconButton
                                                    disabled={reportGenerating}
                                                    onClick={async () => {
                                                        if (
                                                            row.report.value
                                                                .xlsx_report
                                                        ) {
                                                            generateExcelReport(
                                                                row.report.value
                                                                    .xlsx_report,
                                                            )
                                                        } else {
                                                            generatePDFReport(
                                                                row.report
                                                                    .value,
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <FileCopy />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Container>
                                    {(row.report.value.xlsx_report ||
                                        row.report.value.report) && (
                                        <Container>
                                            <Tooltip title="Copy URL">
                                                <IconButton
                                                    onClick={() => {
                                                        if (
                                                            row.report.value
                                                                .xlsx_report
                                                        ) {
                                                            handleCopyLink(
                                                                row.report.value
                                                                    .xlsx_report,
                                                            )
                                                        } else if (
                                                            row.report.value
                                                                .report
                                                        ) {
                                                            handleCopyLink(
                                                                row.report.value
                                                                    .report,
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Container>
                                    )}
                                </Container>
                            </TableCell>
                        </TableRow>
                    )
                }}
                orderByDefault="created_date"
                orderDirDefault="desc"
                stickyHeader
                headCells={headCells}
            />
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={() => setOpenToast(false)}
                message={toastMessage}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </>
    )
}
