import { Badge, IconButton, Tooltip, useTheme } from '@material-ui/core'
import React from 'react'
import {
    Attachment,
    Container,
    WorkorderStatusBadge,
    WorkorderTransitionButtons,
} from '../..'
import {
    AddAPhoto,
    ChatBubbleOutline,
    ReportProblem,
    ExitToApp,
    AssignmentIndOutlined,
    Edit,
} from '@material-ui/icons'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'
import {
    BaseWorkorder,
    User,
    WorkorderStatus,
    WorkorderType,
    getServiceImageDetails,
} from '../../../models'
import {
    MessageDetailActionThunk,
    TransitionWorkorderParams,
    WorkorderResponse,
} from '../../../store'

import { ReactComponent as UnitNotesIcon } from '../../../assets/icons/unitnotes.svg'
import { ReactComponent as ActionItemIcon } from '../../../assets/icons/action-item.svg'
import { mix_hexes, toMMDDYY } from '../../../helpers'
import { toast } from 'react-toastify'
import { useStyles } from '../../../styles'
import { WorkorderAreaCard } from './WorkorderAreaCard'
import { WorkorderDateCard } from './WorkorderDateCard'
import { WorkorderInfoPopover } from '../WorkorderInfoPopover'

interface Props {
    workorder: WorkorderResponse
    isSelectedWorkorder: boolean
    user?: User
    style?: React.CSSProperties
    hideMessageButtons?: boolean
    canSeeUnitNotes: boolean
    selectionMode?: boolean
    width?: number | string
    disabled?: boolean
    hideDates?: boolean
    openMessageDrawer: () => void
    openUnitNotes: () => void
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
        params?: TransitionWorkorderParams,
    ) => Promise<void>
    onClickWorkorder: (wo: WorkorderResponse) => void
    updateWorkorderState: (wo: WorkorderResponse) => void
    createMessage?: (req: FormData) => MessageDetailActionThunk
    navigateToSchedule?: (scheduleId: number) => void
    getRFPDetail?: () => void
    openEditModal?: (workorder: WorkorderResponse) => void
}

export const WorkorderRow = (props: Props) => {
    const {
        workorder,
        user,
        isSelectedWorkorder,
        hideMessageButtons,
        selectionMode,
        width,
        disabled,
        hideDates,
        openMessageDrawer,
        openUnitNotes,
        transitionWorkorder,
        onClickWorkorder,
        updateWorkorderState,
        createMessage,
        navigateToSchedule,
        getRFPDetail,
        openEditModal,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    let attachment = null
    if (workorder.attachment_list[0]) {
        attachment = workorder.attachment_list[0]
    }

    const priorityIcon =
        (workorder as any).priority === 1 ? (
            <Tooltip title="Priority">
                <ReportProblem color="secondary" fontSize="small" />
            </Tooltip>
        ) : null

    let titleLabel = ''
    if (
        workorder.schedule_name !== null &&
        workorder.schedule_name !== undefined &&
        workorder.title !== ''
    ) {
        titleLabel = `${workorder.schedule_name} / ${workorder.title}`
    } else {
        titleLabel = workorder.schedule_name ?? workorder.title
    }

    let navigationIcon: JSX.Element | null = null
    if (workorder.schedule_id !== null && navigateToSchedule) {
        navigationIcon = (
            <Tooltip title={`Go to ${workorder.schedule_name}`}>
                <IconButton
                    style={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                        marginRight: theme.spacing(1),
                    }}
                    onClick={() => {
                        navigateToSchedule(workorder.schedule_id!)
                    }}
                >
                    <ExitToApp fontSize="small" />
                </IconButton>
            </Tooltip>
        )
    }

    let backgroundColor = ''
    if (isSelectedWorkorder) {
        backgroundColor = theme.palette.grey[200]
    }
    if (isSelectedWorkorder && selectionMode) {
        backgroundColor = mix_hexes(theme.palette.secondary.main, '#ffffff')
    }

    let workorderIcon = <FormatPaintIcon />
    let workorderTooltip = 'Service Order'
    if (workorder.type === WorkorderType.ACTION_ITEM) {
        workorderIcon = (
            <ActionItemIcon
                style={{
                    height: 25,
                    width: 25,
                }}
            />
        )
        workorderTooltip = 'Action Item'
    } else if (workorder.type === WorkorderType.SERVICE_REQUEST) {
        workorderIcon = <AssignmentIndOutlined />
        workorderTooltip = 'Service Request'
    }

    let editButton: JSX.Element | null = null
    if (!workorder.price_locked && openEditModal) {
        editButton = (
            <Tooltip title="Edit">
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        openEditModal(workorder)
                    }}
                >
                    <Edit />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[300]}`,
                width: width ? width : '600px',
                marginBottom: theme.spacing(2),
                cursor: 'pointer',
                backgroundColor: backgroundColor,
                borderRadius: theme.shape.borderRadius,
                ...props.style,
            }}
            direction="column"
            onClick={() => onClickWorkorder(workorder)}
            className={classes.hoverGrey100}
        >
            <Container
                style={{
                    height: '160px',
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                }}
            >
                {/* Left Column title, service, vendor & location */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    {/* Title */}
                    <Container style={{ alignItems: 'center' }}>
                        {navigationIcon}
                        <span style={{ ...theme.typography.subtitle1 }}>
                            {titleLabel}
                        </span>
                    </Container>

                    <Container>
                        {/* Location */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                maxWidth: 150,
                                minWidth: 150,
                                overflow: 'hidden',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {workorder.path}
                                {workorder.folder_name}
                            </span>

                            <span
                                style={{
                                    ...theme.typography.h6,
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {workorder.unit_name ?? ''}
                            </span>

                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {workorder.unit_config_name ?? ''}
                            </span>
                        </Container>

                        {/* Service & vendor */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                overflow: 'hidden',
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            <Container style={{ overflow: 'hidden' }}>
                                <img
                                    src={
                                        getServiceImageDetails(
                                            workorder.service_id ?? -1,
                                        ).icon
                                    }
                                    style={{
                                        maxHeight: 25,
                                        maxWidth: 27,
                                        marginRight: theme.spacing(1),
                                    }}
                                />
                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight: 600,
                                    }}
                                >
                                    {workorder.service_name ?? 'No Service'}
                                </span>
                            </Container>

                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: 600,
                                }}
                            >
                                {workorder.vendor_name ??
                                    workorder.user_name ??
                                    'No Vendor'}
                            </span>
                        </Container>
                    </Container>
                </Container>

                {/* Middle column service areas & date */}
                <Container flex={1} direction="column">
                    <WorkorderAreaCard workorder={workorder} theme={theme} />
                    {!hideDates ? (
                        <WorkorderDateCard
                            workorder={workorder}
                            theme={theme}
                        />
                    ) : (
                        <Container
                            style={{
                                flex: 1,
                                ...theme.typography.body2,
                                borderRadius: theme.shape.borderRadius,
                                border: `1px solid ${theme.palette.grey[300]}`,
                                padding: theme.spacing(1),
                                boxSizing: 'border-box',
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            <Container style={{ ...theme.typography.caption }}>
                                Created Date:
                            </Container>
                            <Container
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {toMMDDYY(new Date(workorder.created_date))}
                            </Container>
                        </Container>
                    )}
                </Container>

                {/* Right Column image */}
                <Container flex={1} alignItems="center" justifyContent="center">
                    {attachment ? (
                        <Attachment
                            attachment={attachment.file}
                            uploaded={attachment.uploaded}
                            theme={theme}
                            size={130}
                        />
                    ) : (
                        <Container>
                            <label htmlFor={`icon-button-file_${workorder.id}`}>
                                <Container
                                    style={{
                                        border: `1px solid ${theme.palette.grey[900]}`,
                                        width: 100,
                                        height: 100,
                                        borderRadius: theme.shape.borderRadius,
                                        marginLeft: theme.spacing(2),
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <AddAPhoto fontSize="large" />
                                </Container>
                            </label>
                            <input
                                accept="image/png, image/jpeg, image/jpg"
                                id={`icon-button-file_${workorder.id}`}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={async (e) => {
                                    if (createMessage) {
                                        e.stopPropagation()
                                        e.preventDefault()

                                        if (
                                            e.target.files &&
                                            e.target.files[0]
                                        ) {
                                            const message = new FormData()
                                            message.append(
                                                'channel',
                                                String(workorder.channel),
                                            )
                                            message.append(
                                                'attachments_len',
                                                String(e.target.files.length),
                                            )
                                            for (
                                                let i = 0;
                                                i <= e.target.files.length;
                                                i++
                                            ) {
                                                const attachmentNum = `attachment${i}`
                                                message.append(
                                                    attachmentNum,
                                                    e.target.files[i],
                                                )
                                            }

                                            createMessage(message).then(
                                                (res) => {
                                                    updateWorkorderState({
                                                        ...workorder,
                                                        attachment_list:
                                                            res.data
                                                                .attachments,
                                                    })
                                                },
                                            )
                                        }
                                    }
                                }}
                            />
                        </Container>
                    )}
                </Container>
            </Container>
            <Container style={{ height: '50px' }}>
                <Container
                    flex={1}
                    style={{
                        padding: theme.spacing(1),
                        boxSizing: 'border-box',
                        alignItems: 'center',
                    }}
                >
                    <Container
                        flex={1}
                        style={{
                            height: '35px',
                            alignSelf: 'center',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        <WorkorderStatusBadge
                            workorder={workorder}
                            theme={theme}
                        />
                    </Container>
                    <Container flex={1}>
                        {!hideMessageButtons && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openMessageDrawer()
                                }}
                            >
                                <Badge
                                    badgeContent={workorder.message_count}
                                    color={
                                        (workorder.unread_count ?? 0) > 0
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                >
                                    <ChatBubbleOutline />
                                </Badge>
                            </IconButton>
                        )}
                    </Container>
                    <Container flex={1}>
                        {!hideMessageButtons && props.canSeeUnitNotes && (
                            <Tooltip title="Unit Notes">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        openUnitNotes()
                                    }}
                                    size="small"
                                >
                                    <Badge
                                        badgeContent={
                                            workorder.unit_notes_msg_count
                                        }
                                        color={
                                            (workorder.unread_unit_notes ?? 0) >
                                            0
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                    >
                                        <UnitNotesIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Container>
                    <Container style={{ flex: 1 }}>
                        <Tooltip title={workorderTooltip}>
                            {workorderIcon}
                        </Tooltip>
                    </Container>
                    <Container style={{ flex: 1 }}>{editButton}</Container>
                    <Container style={{ flex: 1 }}>
                        {workorder.info && (
                            <WorkorderInfoPopover
                                workorder={workorder}
                                theme={theme}
                            />
                        )}
                    </Container>

                    <Container style={{ flex: 1 }}>{priorityIcon}</Container>
                </Container>
                <Container flex={1}>
                    <WorkorderTransitionButtons
                        workorder={workorder}
                        user={user}
                        handleTransition={(e, workorder, status) => {
                            e.stopPropagation()
                            transitionWorkorder(workorder, status, {
                                all_jobsites: true,
                            }).then(() => {
                                toast.success('Success')
                                if (getRFPDetail) {
                                    getRFPDetail()
                                }
                            })
                        }}
                        disabled={disabled}
                    />
                </Container>
            </Container>
        </Container>
    )
}
