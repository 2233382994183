import React, { useState } from 'react'
import { ListVendor, Service } from '../../../../models'
import { LoadingButton, Container } from '../../../../components'
import { Dialog } from '@material-ui/core'
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    Theme,
} from '@material-ui/core'
import { SmartScheduleController } from '../../../../hooks'
import { TemplateScheduleGantt } from '../../../../components/TemplateScheduleGantt'
import {
    MODAL_PADDING,
    HEADER_HEIGHT,
    FOOTER_HEIGHT,
} from './SmartScheduleModal'

interface Props {
    theme: Theme
    scheduleController: SmartScheduleController
    serviceList: Service[]
    vendorList: ListVendor[]
    reloadScheduleWorkorders: () => void
}

export const ConfirmStep = (props: Props) => {
    const { theme, scheduleController, reloadScheduleWorkorders } = props

    const schedule = scheduleController.schedule

    const [dialogOpen, setDialogOpen] = useState(false)

    const createProjectPlan = () => {
        const schedule = scheduleController.schedule
        if (schedule) {
            scheduleController.createProjectPlan(schedule.id).then(() => {
                setDialogOpen(false)
                reloadScheduleWorkorders()
            })
        }
    }

    return (
        <Container
            style={{
                flex: 1,
                maxHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px)`,
                minHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px)`,
                flexDirection: 'column',
            }}
        >
            <Grid
                container
                style={{
                    flex: 1,
                    overflowY: 'scroll',
                    minHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
                    maxHeight: `calc(100vh - ${MODAL_PADDING}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
                    padding: theme.spacing(2),
                }}
            >
                <Grid xs={12}>
                    <TemplateScheduleGantt
                        {...props}
                        theme={theme}
                        scheduleController={scheduleController}
                    />
                </Grid>

                {/* Footer */}
            </Grid>

            {/* Footer */}
            <Container
                style={{
                    minHeight: FOOTER_HEIGHT,
                    maxHeight: FOOTER_HEIGHT,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    padding: theme.spacing(2),
                }}
            >
                <div style={{ flex: 1 }} />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    style={{
                        textTransform: 'none',
                        width: 180,
                    }}
                    loading={scheduleController.loadingState.suggestionsLoading}
                    onClick={() => {
                        setDialogOpen(true)
                    }}
                >
                    Create project plan
                </LoadingButton>
            </Container>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Warning!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This project already has a plan created. If you create a
                        new plan, all workorders in the{' '}
                        <span
                            style={{
                                fontStyle: 'italic',
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Assigned
                        </span>{' '}
                        status will be removed.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        loading={
                            scheduleController.loadingState.suggestionsLoading
                        }
                        onClick={() => {
                            createProjectPlan()
                        }}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
