import React from 'react'
import {
    CircularProgress,
    IconButton,
    Popover,
    PopoverOrigin,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import SyncIcon from '@material-ui/icons/Sync'

import { Container } from '../Container'

interface Props {
    open: boolean
    onClose: () => void
    anchorEl: HTMLElement | null
    anchorOrigin?: PopoverOrigin
    reportItems?: ItemProps[]
    setupItems?: ItemProps[]
    reportLoading?: boolean
    setupLoading?: boolean
    width?: string | number
}

interface ItemLink {
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    title: string
}

export interface ItemProps {
    title: string
    downloadLink?: ItemLink
    uploadLink?: ItemLink
    syncLink?: ItemLink
    lineAbove?: boolean
}

export const ScheduleReportPopOver = (props: Props) => {
    const {
        open,
        onClose,
        anchorEl,
        anchorOrigin,
        reportItems,
        setupItems,
        reportLoading,
        setupLoading,
        width,
    } = props

    const theme = useTheme()

    const Item = (props: ItemProps) => {
        const ICON_WIDTH = 45
        return (
            <Container
                style={{
                    alignItems: 'center',
                    borderTop: props.lineAbove
                        ? `1px solid ${theme.palette.grey[400]}`
                        : undefined,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        marginLeft: theme.spacing(2),
                    }}
                >
                    {props.title}
                </span>
                <div style={{ flex: 1 }} />

                <div style={{ width: ICON_WIDTH }}>
                    {props.syncLink && (
                        <Tooltip title={props.syncLink.title}>
                            <IconButton onClick={props.syncLink.onClick}>
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>

                <div style={{ width: ICON_WIDTH }}>
                    {props.uploadLink && (
                        <Tooltip title={props.uploadLink.title}>
                            <IconButton onClick={props.uploadLink.onClick}>
                                <PublishIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>

                <div style={{ width: ICON_WIDTH }}>
                    {props.downloadLink && (
                        <Tooltip title={props.downloadLink.title}>
                            <IconButton onClick={props.downloadLink.onClick}>
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Container>
        )
    }

    return (
        <Popover
            id="SCHEDULE-REPORT-POPOVER"
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
        >
            <Container
                style={{
                    width: width ?? 300,
                    backgroundColor: theme.palette.grey[100],
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                }}
            >
                {reportItems && (
                    <>
                        <Container>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Reports
                            </span>

                            <div style={{ flex: 1 }} />

                            {reportLoading && <CircularProgress size={20} />}
                        </Container>

                        {reportItems.map((item, idx) => (
                            <Item key={`REPORT_ITEM_${idx}`} {...item} />
                        ))}
                    </>
                )}

                {setupItems && (
                    <>
                        <Container>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Setup
                            </span>

                            <div style={{ flex: 1 }} />

                            {setupLoading && <CircularProgress size={20} />}
                        </Container>

                        {setupItems.map((item, idx) => (
                            <Item key={`SETUP_ITEM_${idx}`} {...item} />
                        ))}
                    </>
                )}

                {/* TODO: Turnco upload sheet pending further discussion */}
            </Container>
        </Popover>
    )
}
