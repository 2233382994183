import React, { useState } from 'react'
import {
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'

import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, SideDrawer } from '../../components'
import { useAppDispatch } from '../../hooks'

import {
    createStatusGroup,
    CreateStatusGroupRequest,
    RequestCustomStatus,
    RootState,
    setStatusList,
    updateStatusGroupReq,
    UpdateStatusGroupRequest,
} from '../../store'
import { useStyles } from '../../styles'
import { StatusField } from './StatusField'
import { StatusGroup } from '../../models'
import { useEffect } from 'react'

interface Props {
    open: boolean
    handleClose: () => void
    statusGroup?: StatusGroup
}

export const StatusGroupSideDrawer = (props: Props) => {
    const { open, handleClose, statusGroup } = props

    return (
        <SideDrawer
            open={open}
            handleClose={() => handleClose()}
            title={
                statusGroup === undefined
                    ? 'New Status Group'
                    : 'Edit Status Group'
            }
        >
            <StatusGroupForm
                handleClose={() => handleClose()}
                statusGroup={statusGroup}
            />
        </SideDrawer>
    )
}

interface StatusGroupFormProps {
    handleClose: () => void
    modalMode?: boolean
    statusGroup?: StatusGroup
    setSelectedStatusGroup?: (statusGroup: StatusGroup) => void
    customTopColorPlacement?: number
    customLeftColorPlacement?: number
    height?: string
}

export const StatusGroupForm = (props: StatusGroupFormProps) => {
    const {
        handleClose,
        statusGroup,
        modalMode,
        setSelectedStatusGroup,
        customLeftColorPlacement,
        customTopColorPlacement,
        height,
    } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const statusList = useSelector(
        (state: RootState) => state.aptConfig.statusList,
    )

    // An interface for the statuses the user will use/create in the drawer
    // these are also the same format the be wants them in for ease of sending the req

    const baseStatus: RequestCustomStatus = {
        id: -1,
        name: '',
        color: theme.palette.primary.main,
    }

    const CREATE_STATUS_ID = -2
    const NO_DEFAULT_STATUS_ID = -3

    const createStatus: RequestCustomStatus = {
        id: CREATE_STATUS_ID,
        name: 'Create New Status',
        color: theme.palette.primary.main,
    }

    const noDefault: RequestCustomStatus = {
        id: NO_DEFAULT_STATUS_ID,
        name: 'No Default',
        color: theme.palette.primary.main,
    }

    const [name, setName] = useState('')
    const [validate, setValidate] = useState(false)
    const [defaultStatus, setDefaultStatus] = useState<RequestCustomStatus>(
        baseStatus,
    )
    const [otherStatuses, setOtherStatuses] = useState<RequestCustomStatus[]>([
        baseStatus,
    ])
    const [selectedStatuses, setSelectedStatuses] = useState<number[]>([])

    const [showCustomDefault, setShowCustomDefault] = useState(false)
    const [customDefaultStatus, setCustomDefaultStatus] = useState(baseStatus)

    useEffect(() => {
        if (statusGroup) {
            setName(statusGroup.name)
            setDefaultStatus(statusGroup.default_status ?? noDefault)
            setOtherStatuses([])
            const statusIds: number[] = []
            statusGroup.custom_statuses?.forEach((s) => {
                if (s.id !== statusGroup.default_status?.id) {
                    statusIds.push(s.id)
                }
            })

            setSelectedStatuses(statusIds)
        } else {
            setName('')
            setDefaultStatus(baseStatus)
            setOtherStatuses([])
            setSelectedStatuses([])
        }
    }, [statusGroup])

    const handleDrawerClose = () => {
        handleClose()
        setName('')
        setValidate(false)
        setOtherStatuses([baseStatus])
        setDefaultStatus(baseStatus)
        setSelectedStatuses([])
        setCustomDefaultStatus(baseStatus)
    }

    const saveButtonPress = () => {
        // see if we are in edit mode or create mode
        if (statusGroup) {
            // we're in edit mode
            if (name === '') {
                setValidate(true)
            } else {
                const requestStatuses: RequestCustomStatus[] = []
                selectedStatuses.forEach((id) => {
                    const selected = statusList?.find((s) => s.id === id)
                    if (selected) {
                        requestStatuses.push(selected)
                    }
                })
                otherStatuses.forEach((s) => {
                    if (s.name !== '') {
                        requestStatuses.push(s)
                    }
                })
                const req: UpdateStatusGroupRequest = {
                    params: { id: statusGroup.id },
                    body: { name: name, status_list: requestStatuses },
                }
                if (defaultStatus.id !== NO_DEFAULT_STATUS_ID) {
                    req.body.default_status = defaultStatus
                    req.body.status_list = [...requestStatuses, defaultStatus]
                } else {
                    req.body.remove_default_status = true
                }
                dispatch(updateStatusGroupReq(req)).then((res) => {
                    const newStatuses = [...(statusList ?? [])]
                    res.data.custom_statuses?.forEach((status) => {
                        const selected = statusList?.find(
                            (s) => s.id === status.id,
                        )
                        if (!selected) {
                            newStatuses.push(status)
                        }
                    })
                    dispatch(setStatusList(newStatuses))

                    // alert the user it was succesful, and close the drawer
                    toast.success(`${name} succesfully updated!`)
                    handleDrawerClose()
                })
            }
        } else {
            // create mode
            // make sure that the default status and the status group have a name
            // if they do, make the request to create a new status group
            // make sure default status is the corrent one between custom and existing
            const defStatus =
                defaultStatus.id === CREATE_STATUS_ID
                    ? customDefaultStatus
                    : defaultStatus
            if (name === '' || defStatus.name === '') {
                setValidate(true)
            } else {
                //generate the request body
                const req: CreateStatusGroupRequest = {
                    name: name,

                    statuses: [],
                }
                if (defStatus.id !== NO_DEFAULT_STATUS_ID) {
                    req.default_status = defStatus
                }

                // add the other statuses
                // if any of the other statuses name is '', assume that they are not to be sent to the server
                otherStatuses.forEach((s) => {
                    if (s.name !== '') {
                        req.statuses?.push(s)
                    }
                })
                // add other existing statuses
                selectedStatuses.forEach((id) => {
                    const selected = statusList?.find((s) => s.id === id)
                    if (selected) {
                        req.statuses?.push(selected)
                    }
                })

                // send the request
                dispatch(createStatusGroup(req)).then((res) => {
                    const newStatuses = [...(statusList ?? [])]
                    res.data.custom_statuses?.forEach((status) => {
                        const selected = statusList?.find(
                            (s) => s.id === status.id,
                        )
                        if (!selected) {
                            newStatuses.push(status)
                        }
                    })
                    dispatch(setStatusList(newStatuses))
                    if (setSelectedStatusGroup) setSelectedStatusGroup(res.data)

                    // alert the user it was succesful, and close the drawer
                    toast.success(`${name} succesfully created!`)
                    handleDrawerClose()
                })
            }
        }
    }

    const defaultStatusOptions: JSX.Element[] = []
    defaultStatusOptions.push(
        <MenuItem
            value={NO_DEFAULT_STATUS_ID}
            key={'no-status-default-selector'}
        >
            No Default
        </MenuItem>,
    )
    defaultStatusOptions.push(
        <MenuItem
            value={CREATE_STATUS_ID}
            key={'create-new-status-default-selector'}
        >
            Create New Status
        </MenuItem>,
    )
    statusList?.forEach((s) => {
        defaultStatusOptions.push(
            <MenuItem value={s.id} key={`status-default-selector-${s.id}`}>
                {s.name}
            </MenuItem>,
        )
    })

    const SubHeaderCellStyle: React.CSSProperties = {
        fontWeight: 500,
        fontSize: '15px',
        marginBottom: theme.spacing(2),
    }

    return (
        <Container
            direction="column"
            flex={1}
            scrollY
            style={{ margin: !modalMode ? theme.spacing(0, 2, 0, 2) : '' }}
        >
            {modalMode && (
                <Container style={{ ...SubHeaderCellStyle, fontSize: '20px' }}>
                    Create New Status Group
                </Container>
            )}

            <Container
                style={{
                    flexDirection: 'column',
                    maxHeight: height,
                    minHeight: height,
                    overflow: 'auto',
                }}
            >
                {/* Name */}

                <Container style={{ ...SubHeaderCellStyle }}>Name:</Container>

                <TextField
                    variant="outlined"
                    error={validate && name === ''}
                    value={name}
                    onChange={(e) => {
                        const name = e.target.value.slice(0, 30)
                        setName(name)
                    }}
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                    size="small"
                    helperText={validate && name === '' && 'Required'}
                />
                {/* default status */}

                <Container direction="column">
                    <Container style={{ ...SubHeaderCellStyle }}>
                        Default Status
                    </Container>
                    <FormControl
                        variant="outlined"
                        style={{ marginBottom: theme.spacing(2) }}
                        size="small"
                    >
                        <Select
                            labelId={`assign-default-status-label`}
                            id={`assign-default-status-select`}
                            value={defaultStatus.id}
                            onChange={(e) => {
                                const id = e.target.value as number
                                if (id === -2) {
                                    setDefaultStatus(createStatus)
                                    setShowCustomDefault(true)
                                } else if (id === NO_DEFAULT_STATUS_ID) {
                                    setDefaultStatus(noDefault)
                                    setShowCustomDefault(false)
                                } else {
                                    const status = statusList?.find(
                                        (s) => s.id === id,
                                    )
                                    setDefaultStatus(status ?? baseStatus)
                                    setShowCustomDefault(false)
                                }
                            }}
                        >
                            {defaultStatusOptions}
                        </Select>
                    </FormControl>
                    {showCustomDefault && (
                        <StatusField
                            error={validate && defaultStatus.name === ''}
                            status={customDefaultStatus}
                            updateStatus={(s) => setCustomDefaultStatus(s)}
                            label={'New Default Status'}
                            customLeftPlacement={customLeftColorPlacement}
                            customTopPlacement={customTopColorPlacement}
                        />
                    )}
                </Container>
                {/* other statuses */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                    }}
                    scrollY
                >
                    {/* other statuses header */}
                    <Container style={{ marginTop: theme.spacing(5) }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                            }}
                        >
                            Statuses
                        </span>
                        <Container style={{ flex: 1 }} />
                        <Tooltip
                            title={
                                statusGroup === undefined ? 'New Status' : ''
                            }
                        >
                            <Add
                                onClick={() => {
                                    setOtherStatuses([
                                        ...otherStatuses,
                                        baseStatus,
                                    ])
                                }}
                                color="primary"
                                className={
                                    statusGroup === undefined
                                        ? classes.highlightCircleOnHover
                                        : undefined
                                }
                            />
                        </Tooltip>
                    </Container>
                    {/* existing statuses */}
                    <FormControl style={{ margin: theme.spacing(0, 0, 2, 0) }}>
                        <InputLabel id="multiple-status-selector">
                            Existing Statuses
                        </InputLabel>
                        <Select
                            multiple
                            labelId="multiple-status-selector"
                            id=""
                            value={selectedStatuses}
                            onChange={(
                                event: React.ChangeEvent<{ value: unknown }>,
                            ) => {
                                setSelectedStatuses(
                                    event.target.value as number[],
                                )
                            }}
                            MenuProps={{
                                getContentAnchorEl: () => {
                                    return (null as unknown) as Element
                                },
                            }}
                            renderValue={(selected) => (
                                <Container style={{ flexWrap: 'wrap' }}>
                                    {(selected as number[]).map((id) => {
                                        const status = statusList?.find(
                                            (status) => status.id === id,
                                        )

                                        return (
                                            <Chip
                                                style={{ margin: 2 }}
                                                key={`ADD_EXISTING_STATUS_TO_STATUS_GROUP_${status?.id}`}
                                                label={
                                                    status
                                                        ? status.name
                                                        : 'unknown'
                                                }
                                            />
                                        )
                                    })}
                                </Container>
                            )}
                        >
                            {statusList?.map((s) => {
                                if (s.id !== defaultStatus.id) {
                                    let inSelectedStatuses = false
                                    selectedStatuses.forEach((status) => {
                                        if (status === s.id)
                                            inSelectedStatuses = true
                                    })
                                    return (
                                        <MenuItem
                                            key={`ADD_TO_STATUS_GROUP_${s.id}`}
                                            value={s.id}
                                            style={{
                                                backgroundColor: inSelectedStatuses
                                                    ? theme.palette.lightGrey
                                                          .dark
                                                    : '',
                                            }}
                                        >
                                            {s.name}
                                        </MenuItem>
                                    )
                                }
                                return null
                            })}
                        </Select>
                    </FormControl>

                    {/* New status fields */}

                    {otherStatuses.map((s, index) => (
                        <Container
                            key={`${s.id}_${index}_OTHER_STATUS_CONFIG_CONTIANER`}
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <StatusField
                                error={validate && s.name === ''}
                                status={s}
                                updateStatus={(updatedStatus) => {
                                    //update this status in the array
                                    const newOtherStatuses: RequestCustomStatus[] = []
                                    otherStatuses.forEach((status, sIndex) => {
                                        if (index !== sIndex) {
                                            newOtherStatuses.push(status)
                                        } else {
                                            newOtherStatuses.push(updatedStatus)
                                        }
                                    })
                                    setOtherStatuses(newOtherStatuses)
                                }}
                                customLeftPlacement={customLeftColorPlacement}
                                customTopPlacement={customTopColorPlacement}
                            />
                            <Remove
                                color="secondary"
                                className={classes.highlightCircleOnHover}
                                onClick={() => {
                                    //remove this status from other statuses
                                    const newOtherStatuses: RequestCustomStatus[] = []
                                    otherStatuses.forEach((status, sIndex) => {
                                        if (index !== sIndex) {
                                            newOtherStatuses.push(status)
                                        }
                                    })
                                    setOtherStatuses(newOtherStatuses)
                                }}
                                style={{ marginLeft: theme.spacing(1) }}
                            />
                        </Container>
                    ))}
                </Container>
            </Container>
            {/* save and cancel buttons */}
            {modalMode ? (
                <Container
                    justifyContent={'flex-end'}
                    style={{
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        style={{
                            textTransform: 'none',
                            margin: theme.spacing(1, 0, 1, 2),
                        }}
                        onClick={handleClose}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(1, 0, 1, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={saveButtonPress}
                    >
                        Create
                    </Button>
                </Container>
            ) : (
                <Container
                    justifyContent={'flex-end'}
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleClose}
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={saveButtonPress}
                    >
                        Save
                    </Button>
                </Container>
            )}
        </Container>
    )
}
