import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
    Tooltip,
    Typography,
    ListItem,
    List,
    Divider,
    ListItemIcon,
    Badge,
} from '@material-ui/core'

import { NavLink } from 'react-router-dom'

import { ReactComponent as ClipboardTickSBIcon } from '../../../../assets/icons/clipboard-tick.svg'

import { ReactComponent as HomeSBIcon } from '../../../../assets/icons/grid.svg'
import { ReactComponent as CalendarSBIcon } from '../../../../assets/icons/group-39654.svg'
import { ReactComponent as HelpCircleSBIcon } from '../../../../assets/icons/help-circle.svg'
import { ReactComponent as ListSBIcon } from '../../../../assets/icons/action-item.svg'
import { ReactComponent as SettingsSBIcon } from '../../../../assets/icons/settings.svg'
import { ReactComponent as MessageSBIcon } from '../../../../assets/icons/vector-1.svg'
import { ReactComponent as UsersSBIcon } from '../../../../assets/icons/ion_people-outline.svg'

import { ReactComponent as StarSBIcon } from '../../../../assets/icons/star.svg'
import { ReactComponent as DollarSignSBIcon } from '../../../../assets/icons/dollar-sign.svg'
import { ReactComponent as ToolSBIcon } from '../../../../assets/icons/tool.svg'
import { ReactComponent as FileSBIcon } from '../../../../assets/icons/file.svg'
import { ReactComponent as Monitor } from '../../../../assets/icons/monitor.svg'

import CompareIcon from '@material-ui/icons/Compare'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'

import { Routes } from '../../../../main/config/routes'
import { APARTMENT, axiosInstance, VENDOR } from '../../../../helpers'
import { useAppDispatch, useUser } from '../../../../hooks'
import { APPLICATION_VERSION } from '../../../../helpers'
import { useServiceContract } from '../../../../hooks/useServiceContract'
import {
    Bid,
    PlanStatus,
    VENDOR_INVOICE_PERMISSION,
    VIEW_INVOICE,
} from '../../../../models'
import { hasPermission } from '../../../../models/Users/services'
import { ReceiptOutlined, TableChartOutlined } from '@material-ui/icons'
import { VendorItems } from '../../../components/SideBarItems/VendorItems'
import { ApartmentItems } from '../../../components/SideBarItems/ApartmentItems'

const SideBarNavigationItems = () => {
    const { workspaceUser } = useUser()
    if (!workspaceUser) return null

    if (workspaceUser.active_workspace.company_type === VENDOR) {
        return <VendorItems />
    }

    return <ApartmentItems />
}

// const SideBarNavigationItems = () => {
//     const { workspaceUser } = useUser()

//     const useStyles = makeStyles((theme: Theme) =>
//         createStyles({
//             highlightMenu: {
//                 borderLeft: '6px solid #008c85 !important',
//                 '& path': {
//                     stroke: '#FFF !important',
//                 },
//             },
//             navMenu: {
//                 borderLeft: '6px solid transparent',
//             },
//             sideBarItem: {
//                 maxHeight: 'calc(100vh - 114px) !important',
//                 overflowY: 'auto',
//                 '& .MuiListItem-root': {
//                     paddingTop: '16px',
//                     paddingBottom: '16px',
//                 },
//                 '& path': {
//                     stroke:
//                         workspaceUser?.active_workspace.company_type ===
//                         'VENDOR'
//                             ? theme.palette.darkBlue.main
//                             : theme.palette.darkGreen.main,
//                 },
//             },
//             footerText: {
//                 fontSize: '1.25rem',
//                 lineHeight: '24px',
//                 fontWeight: 600,
//                 color: '#FFF',
//                 margin: 'auto',
//                 padding: '15px 0',
//                 textAlign: 'center',
//             },
//             toolTipMenuIcon: {
//                 background: '#FFF',
//                 color: '#000',
//                 fontSize: '0.813rem',
//                 fontWeight: 400,
//                 maxWidth: '104px',
//                 textAlign: 'center',
//             },
//         }),
//     )
//     const classes = useStyles()

//     const navItems = [
//         {
//             link: Routes.ezwork,
//             icon: <AssignmentOutlinedIcon fontSize="large" />,
//             companyType: VENDOR,
//             name: 'EZWork',
//         },
//         {
//             link: Routes.dashboard,
//             icon: <HomeSBIcon />,
//             companyType: APARTMENT,
//             name: 'Dashboard',
//         },
//         {
//             link: Routes.scheduler,
//             icon: <CalendarSBIcon />,
//             companyType: APARTMENT,
//             name: 'Scheduler',
//         },
//         {
//             link: Routes.inspect,
//             icon: <ClipboardTickSBIcon />,
//             companyType: APARTMENT,
//             name: 'Inspection',
//         },
//         {
//             link: Routes.jobBoard,
//             icon: <TableChartOutlined fontSize="large" />,
//             companyType: APARTMENT,
//             name: 'Job Board',
//         },
//         {
//             link: Routes.actionItems,
//             icon: <ListSBIcon style={{ height: 32 }} />,
//             companyType: APARTMENT,

//             name: 'Action Items',
//         },
//         {
//             link: Routes.nvl,
//             icon: <StarSBIcon />,
//             companyType: APARTMENT,

//             name: 'National Vendors List',
//         },
//         {
//             link: Routes.workForce,
//             icon: <UsersSBIcon />,
//             companyType: APARTMENT,

//             name: 'Workforce',
//         },
//         {
//             link: Routes.workForce,
//             icon: <UsersSBIcon />,
//             companyType: VENDOR,

//             name: 'Workforce',
//         },
//         {
//             link: Routes.leases,
//             icon: <FileSBIcon />,
//             companyType: APARTMENT,

//             name: 'Leases',
//         },
//         {
//             link: Routes.leaseAudit,
//             icon: <CompareIcon fontSize="large" />,
//             companyType: APARTMENT,

//             name: 'Lease Audit',
//         },
//         {
//             link: Routes.chat,
//             icon: <MessageSBIcon />,
//             companyType: APARTMENT,

//             name: 'Chat',
//         },
//         {
//             link: Routes.damageConf,
//             icon: <ToolSBIcon />,
//             companyType: APARTMENT,

//             name: 'Damages',
//         },
//         {
//             link: Routes.invoiceHome,
//             icon: <DollarSignSBIcon />,
//             companyType: APARTMENT,

//             name: 'Finances',
//         },
//         {
//             link: Routes.ezpay,
//             icon: <AccountBalanceIcon />,
//             companyType: APARTMENT,
//             name: 'EZ-Pay',
//         },
//     ]

//     const navItems2 = [
//         // TODO: Pasztor/Feature/BuildingInspectorPerformance
//         // Pick a new icon
//         {
//             link: Routes.portfolioAnalytics,
//             icon: <TrendingUpIcon fontSize="large" />,
//             name: 'Portfolio Analytics',
//             companyType: APARTMENT,
//         },
//         {
//             link: Routes.nationalPropertyList,
//             icon: <Monitor />,
//             name: 'National Property List',
//             companyType: VENDOR,
//         },
//         {
//             link: Routes.settings,
//             icon: <SettingsSBIcon />,
//             name: 'Settings',
//             companyType: APARTMENT,
//         },
//         {
//             link: Routes.vendorSettings,
//             icon: <SettingsSBIcon />,
//             name: 'Settings',
//             companyType: VENDOR,
//         },
//         {
//             link: Routes.helpNew,
//             icon: <HelpCircleSBIcon />,
//             name: 'Help',
//             companyType: APARTMENT,
//         },
//         {
//             link: Routes.vendorHelp,
//             icon: <HelpCircleSBIcon />,
//             name: 'Help',
//             companyType: VENDOR,
//         },
//     ]

//     if (hasPermission(workspaceUser, VENDOR_INVOICE_PERMISSION)) {
//         navItems.push({
//             link: Routes.ezpay,
//             icon: <AccountBalanceIcon />,
//             companyType: VENDOR,
//             name: 'EZ-Pay',
//         })
//     }

//     if (hasPermission(workspaceUser, VIEW_INVOICE)) {
//         navItems.push({
//             link: Routes.invoiceHome,
//             icon: <ReceiptOutlined fontSize="large" />,
//             companyType: VENDOR,
//             name: 'Invoice',
//         })
//     }

//     const filterNavItems = (navItem: {
//         link: string
//         icon: JSX.Element
//         companyType: string
//         name: string
//     }) => {
//         const plan = workspaceUser?.active_workspace.stripe_subscription?.plan

//         if (
//             navItem.link === Routes.nvl &&
//             workspaceUser?.active_workspace.company_type === APARTMENT
//         ) {
//             return true
//         }

//         if (
//             navItem.companyType === APARTMENT &&
//             !workspaceUser?.active_workspace.has_infrastructure
//         ) {
//             return false
//         }

//         if (
//             navItem.link === Routes.nationalPropertyList &&
//             (!plan || plan !== PlanStatus.PREMIUM)
//         ) {
//             return false
//         }

//         return (
//             navItem.companyType === workspaceUser?.active_workspace.company_type
//         )
//     }

//     return (
//         <>
//             <List className={classes.sideBarItem}>
//                 {navItems.filter(filterNavItems).map((item, index) => (
//                     <ListItem
//                         component={NavLink}
//                         to={item.link}
//                         button
//                         key={`nav-${index}`}
//                         className={classes.navMenu}
//                         activeClassName={classes.highlightMenu}
//                     >
//                         <Tooltip
//                             title={item.name}
//                             placement="top"
//                             classes={{ tooltip: classes.toolTipMenuIcon }}
//                         >
//                             <ListItemIcon
//                                 style={{
//                                     color: '#8a8a8a',
//                                     justifyContent: 'center',
//                                     width: '100%',
//                                 }}
//                             >
//                                 {item.icon}
//                             </ListItemIcon>
//                         </Tooltip>
//                     </ListItem>
//                 ))}
//                 <Divider
//                     style={{
//                         width: '80%',
//                         background: '#8A8A8A',
//                         margin: 'auto',
//                     }}
//                 />
//                 {navItems2.filter(filterNavItems).map((item, index) => (
//                     <ListItem
//                         component={NavLink}
//                         to={item.link}
//                         button
//                         key={`nav2-${index}`}
//                         className={classes.navMenu}
//                         activeClassName={classes.highlightMenu}
//                     >
//                         <Tooltip
//                             title={item.name}
//                             placement="top"
//                             classes={{ tooltip: classes.toolTipMenuIcon }}
//                         >
//                             <ListItemIcon
//                                 style={{
//                                     color: '#8a8a8a',
//                                     justifyContent: 'center',
//                                     width: '100%',
//                                 }}
//                             >
//                                 {item.icon}
//                             </ListItemIcon>
//                         </Tooltip>
//                     </ListItem>
//                 ))}
//                 <Typography className={classes.footerText}>
//                     {APPLICATION_VERSION}
//                 </Typography>
//             </List>
//         </>
//     )
// }

export default SideBarNavigationItems
