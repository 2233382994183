import React, { useEffect, useState } from 'react'
import {
    Button,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { Container } from '../../components'
import { Company, CompanyHours, ListVendor } from '../../models'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    dayRow: {
        marginBottom: theme.spacing(2),
    },
    timeField: {
        width: 120,
    },
    dayName: {
        fontWeight: 'bold',
    },
    switchLabel: {
        marginRight: theme.spacing(2),
    },
    saveButton: {
        marginTop: theme.spacing(3),
    },
}))

interface Props {
    company: ListVendor | Company
    displayHeader?: boolean
    onAfterUpdate: (companyHours: CompanyHours) => void
}

const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
] as const
type Day = typeof days[number]

export const CompanyHourDisplay = (props: Props) => {
    const { company, displayHeader, onAfterUpdate } = props
    const theme = useTheme()
    const classes = useStyles()

    const [companyHours, setCompanyHours] = useState<CompanyHours>(defaultHours)
    const [doValidate, setDoValidate] = useState(false)

    useEffect(() => {
        if (company.company_hours) {
            setCompanyHours(company.company_hours)
        } else {
            setCompanyHours(defaultHours)
        }
    }, [company.company_hours])

    const validateCompanyHours = () => {
        let valid = true
        days.forEach((day) => {
            const start = companyHours[`${day}_start` as keyof CompanyHours]
            const end = companyHours[`${day}_end` as keyof CompanyHours]
            const closed = companyHours[`${day}_closed` as keyof CompanyHours]

            if (!closed) {
                if (start && end && start > end) {
                    valid = false
                    setDoValidate(true)
                }
                if (start === null || end === null) {
                    valid = false
                    setDoValidate(true)
                }
            }
        })
        return valid
    }

    const handleHoursChange = (
        day: Day,
        field: 'start' | 'end' | 'closed',
        value: string | boolean,
    ) => {
        setCompanyHours((prev) => ({
            ...prev,
            [`${day}_${field}`]: field === 'closed' ? Boolean(value) : value,
        }))
    }

    const handleSave = () => {
        const requestBody = {
            company_id: company.id,
            company_hours: companyHours,
        }

        axiosInstance
            .post(`company/update-company-hours/`, requestBody)
            .then((res) => {
                toast.success(`Company Hours updated!`)
                const companyHours: CompanyHours = res.data
                onAfterUpdate(companyHours)
            })
    }

    return (
        <Container flex={1} direction="column">
            {displayHeader && (
                <Typography variant="h4" gutterBottom>
                    Vendor Hours
                </Typography>
            )}
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    {days.map((day) => {
                        const capitalizedDay =
                            day.charAt(0).toUpperCase() + day.slice(1)
                        const closed =
                            companyHours[`${day}_closed` as keyof CompanyHours]
                        const start =
                            companyHours[`${day}_start` as keyof CompanyHours]
                        const end =
                            companyHours[`${day}_end` as keyof CompanyHours]

                        let hasStartTimeError = false
                        let hasEndTimeError = false
                        let startTimeError = ''
                        let endTimeError = ''

                        if (doValidate && !closed) {
                            if (start === null) {
                                hasStartTimeError = true
                                startTimeError = 'Start time required'
                            }
                            if (end === null) {
                                hasEndTimeError = true
                                endTimeError = 'End time required'
                            }
                            if (start && end && start > end) {
                                hasStartTimeError = true
                                startTimeError =
                                    'Start time cannot be after the end time'
                            }
                        }

                        return (
                            <Grid
                                item
                                xs={12}
                                key={day}
                                className={classes.dayRow}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={2}>
                                        <Typography className={classes.dayName}>
                                            {capitalizedDay}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!closed}
                                                    onChange={(e) =>
                                                        handleHoursChange(
                                                            day,
                                                            'closed',
                                                            !e.target.checked,
                                                        )
                                                    }
                                                    color="primary"
                                                />
                                            }
                                            label={closed ? 'Closed' : 'Open'}
                                            className={classes.switchLabel}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            label="Start Time"
                                            type="time"
                                            value={start || ''}
                                            onChange={(e) =>
                                                handleHoursChange(
                                                    day,
                                                    'start',
                                                    e.target.value,
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }}
                                            disabled={Boolean(closed)}
                                            className={classes.timeField}
                                            error={hasStartTimeError}
                                            helperText={
                                                hasStartTimeError &&
                                                startTimeError
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            label="End Time"
                                            type="time"
                                            value={end || ''}
                                            onChange={(e) =>
                                                handleHoursChange(
                                                    day,
                                                    'end',
                                                    e.target.value,
                                                )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }}
                                            disabled={Boolean(closed)}
                                            className={classes.timeField}
                                            error={hasEndTimeError}
                                            helperText={
                                                hasEndTimeError && endTimeError
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if (validateCompanyHours()) {
                        handleSave()
                    }
                }}
                className={classes.saveButton}
            >
                Save Changes
            </Button>
        </Container>
    )
}

const defaultHours: CompanyHours = {
    id: 0,
    monday_start: null,
    monday_end: null,
    monday_closed: false,
    tuesday_start: null,
    tuesday_end: null,
    tuesday_closed: false,
    wednesday_start: null,
    wednesday_end: null,
    wednesday_closed: false,
    thursday_start: null,
    thursday_end: null,
    thursday_closed: false,
    friday_start: null,
    friday_end: null,
    friday_closed: false,
    saturday_start: null,
    saturday_end: null,
    saturday_closed: true,
    sunday_start: null,
    sunday_end: null,
    sunday_closed: true,
}
