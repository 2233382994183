import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../helpers'
import { Company, ListVendor } from '../../models'
import { setNetworkError } from '../error'
import { AppThunk, ErrorResponse } from '../types'
import {
    CompanyDetailActionThunk,
    CreateVendorRequest,
    CREATE_VENDOR_REQUEST,
    CREATE_VENDOR_RESPONSE,
    GetApartmentListActionThunk,
    GetApartmentListRequest,
    VendorListActionThunk,
    GetVendorListRequest,
    GET_APARTMENT_LIST_REQUEST,
    GET_APARTMENT_LIST_RESPONSE,
    GET_VENDOR_LIST_REQUEST,
    GET_VENDOR_LIST_RESPONSE,
    UpdateVendorRequest,
    UPDATE_VENDOR_REQUEST,
    UPDATE_VENDOR_RESPONSE,
    UpdateVendorServicesRequest,
    UPDATE_VENDOR_SERVICE_REQUEST,
    UPDATE_VENDOR_SERVICE_RESPONSE,
    AddRemoveVendorAptRequest,
    CompanyActionTypes,
    SET_VENDOR_LIST,
    SET_COMPANY_LOADING,
    CompanyRequests,
    ADD_VENDOR_TO_APT_REQUEST,
    CompanyDetailAxiosReq,
    GET_COMPANY_DETAIL_REQUEST,
    SET_COMPANY_DETAIL,
    SET_PORTFOLIO_VENDOR_LIST,
    REMOVE_VENDOR_APT_REQUEST,
    VendorAndContractThunk,
    UpdateApartmentRequest,
} from './types'

export const getApartmentList = (
    req: GetApartmentListRequest,
): AppThunk<GetApartmentListActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: GET_APARTMENT_LIST_REQUEST,
        })

        const url = 'company/apartment/'

        try {
            const response = await axiosInstance.get(url, req)

            dispatch({
                type: GET_APARTMENT_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getVendorList = (
    req: GetVendorListRequest,
): AppThunk<VendorListActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: GET_VENDOR_LIST_REQUEST,
        })

        const url = 'company/vendor/'

        try {
            const response = await axiosInstance.get(url, req)

            if (req.params.portfolio_apartment) {
                dispatch(setCompanyLoading(GET_VENDOR_LIST_REQUEST, false))
                dispatch(setPortfolioVendorList(response.data))
            } else {
                dispatch({
                    type: GET_VENDOR_LIST_RESPONSE,
                    payload: response.data,
                })
            }

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setCompanyLoading(GET_VENDOR_LIST_REQUEST, false))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createVendor = (
    req: CreateVendorRequest,
): AppThunk<VendorAndContractThunk> => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_VENDOR_REQUEST,
        })

        const url = 'company/vendor/'

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: CREATE_VENDOR_RESPONSE,
                payload: response.data.vendor,
            })

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            if (error.response?.data.key === 'DUPLICATE_EMAIL') {
                const message =
                    'There is currently a vendor with that email, check the National Vendor List for that vendor'
                toast.error(message)
            } else {
                dispatch(setNetworkError(error, true))
            }

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateVendor = (
    req: UpdateVendorRequest,
): AppThunk<VendorAndContractThunk> => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_VENDOR_REQUEST,
        })

        const url = `company/vendor/${req.params.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch({
                type: UPDATE_VENDOR_RESPONSE,
                payload: response.data.vendor,
            })

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateApartment = async (req: UpdateApartmentRequest) => {
    try {
        const url = `company/apartment/${req.params.id}/`
        const response = await axiosInstance.patch(url, req.body)
        const apartment: Company = response.data
        return apartment
    } catch (e) {
        return Promise.reject(e)
    }
}

export const updateVendorServices = (
    req: UpdateVendorServicesRequest,
): AppThunk<CompanyDetailActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_VENDOR_SERVICE_REQUEST,
        })

        const url = `company/vendor/${req.params.id}/update_services/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch({
                type: UPDATE_VENDOR_SERVICE_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const addVendorToApt = (
    req: AddRemoveVendorAptRequest,
): AppThunk<VendorListActionThunk> => {
    return async (dispatch) => {
        dispatch(setCompanyLoading(ADD_VENDOR_TO_APT_REQUEST, true))

        const url = `company/apartment/add_vendor/`

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch(setVendorList(response.data))
            dispatch(setCompanyLoading(ADD_VENDOR_TO_APT_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setCompanyLoading(ADD_VENDOR_TO_APT_REQUEST, false))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const removeVendorFromApt = (
    req: AddRemoveVendorAptRequest,
): AppThunk<VendorListActionThunk> => {
    return async (dispatch, getPrevState) => {
        const prevState = getPrevState()

        dispatch(setCompanyLoading(REMOVE_VENDOR_APT_REQUEST, true))

        const url = `company/apartment/remove_vendor/`

        try {
            const response = await axiosInstance.post(url, req.body)

            const newVendorList = prevState.company.vendorList.filter(
                (vnd) => vnd.id !== req.body.vendor,
            )

            dispatch(setVendorList(newVendorList))
            dispatch(setCompanyLoading(REMOVE_VENDOR_APT_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setCompanyLoading(REMOVE_VENDOR_APT_REQUEST, false))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getCompanyDetail = (
    req: CompanyDetailAxiosReq,
): AppThunk<CompanyDetailActionThunk> => {
    return async (dispatch) => {
        dispatch(setCompanyLoading(GET_COMPANY_DETAIL_REQUEST, true))

        const url = `company/${req.company}/`

        try {
            const response = await axiosInstance.get(url, req)
            dispatch(setCompanyDetail(response.data))
            dispatch(setCompanyLoading(GET_COMPANY_DETAIL_REQUEST, false))
            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setCompanyLoading(GET_COMPANY_DETAIL_REQUEST, false))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setVendorList = (
    vendorList?: ListVendor[],
): CompanyActionTypes => {
    return {
        type: SET_VENDOR_LIST,
        vendorList: vendorList,
    }
}

export const setPortfolioVendorList = (
    vendorList?: ListVendor[],
): CompanyActionTypes => {
    return {
        type: SET_PORTFOLIO_VENDOR_LIST,
        vendorList: vendorList,
    }
}

export const setCompanyDetail = (company?: Company): CompanyActionTypes => {
    return {
        type: SET_COMPANY_DETAIL,
        company: company,
    }
}

export const setCompanyLoading = (
    action: CompanyRequests,
    isLoading: boolean,
) => {
    return {
        type: SET_COMPANY_LOADING,
        action: action,
        isLoading: isLoading,
    }
}
