import React, { useState } from 'react'

import { Button, useTheme } from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { useAppDispatch } from '../../hooks'
import { AreaConfig, Frequency, ModelMap } from '../../models'
import { addInspection } from '../../store'
import { InspectionFrequencyTable } from './InspectionFrequencyTable'
import { InspectionFrequencyDrawer } from './InspectionFrequencyDrawer'
import { InspectionAssignModal } from './InspectionAssignModal'

interface Props {
    frequencyList: Frequency[]
    areaConfigMap: ModelMap<AreaConfig>
    selectedFrequency: Frequency | null
    setSelectedFrequency: (frequency: Frequency | null) => void
    toggleFrequencyActive: (id: number) => Promise<Frequency>
    setOpenInspectionConfiguration: (open: boolean) => void
    setFrequencyList: (frequencyList: Frequency[]) => void
}

export const InspectionFrequencyPage = (props: Props) => {
    const {
        frequencyList,
        areaConfigMap,
        selectedFrequency,
        setSelectedFrequency,
        toggleFrequencyActive,
        setOpenInspectionConfiguration,
        setFrequencyList,
    } = props

    const dispatch = useAppDispatch()

    const theme = useTheme()

    const [drawerOpen, setDrawerOpen] = useState(false)

    const [createFrequencyModalOpen, setCreateFrequencyModalOpen] = useState(
        false,
    )

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 140px)',
                }}
            >
                {/* Header */}
                <Container style={{ alignItems: 'center' }}>
                    <Container style={{ flexDirection: 'column' }}>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Inspection Frequencies
                        </span>
                    </Container>

                    <div style={{ flex: 1 }} />

                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setDrawerOpen(true)
                        }}
                    >
                        + Add Inspection Frequency
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenInspectionConfiguration(true)}
                    >
                        + Configure Inspection
                    </Button>
                </Container>
                {/* End Header */}

                <InspectionFrequencyTable
                    frequencyList={frequencyList}
                    setFrequencyModalOpenViewOnly={(freq) => {
                        setCreateFrequencyModalOpen(true)
                        setSelectedFrequency(freq)
                    }}
                    toggleFrequencyActive={toggleFrequencyActive}
                />
            </Container>

            <InspectionFrequencyDrawer
                open={drawerOpen}
                handleClose={() => handleDrawerClose()}
                setCreateModalOpen={() => setCreateFrequencyModalOpen(true)}
            />

            <InspectionAssignModal
                makeFilteredRequest={() => {}}
                open={createFrequencyModalOpen}
                areaConfigMap={areaConfigMap}
                onClose={() => {
                    setCreateFrequencyModalOpen(false)
                    setSelectedFrequency(null)
                }}
                onFrequencyCreate={(freq) => {
                    if (freq.inspection) {
                        dispatch(addInspection(freq.inspection))
                    }
                    setFrequencyList([...frequencyList, freq])
                }}
                onFrequencyDelete={(freq) => {
                    const newList = frequencyList.reduce<Frequency[]>(
                        (prev, frequency) => {
                            if (frequency.id === freq.id) {
                                return prev
                            }
                            return prev.concat(frequency)
                        },
                        [],
                    )
                    setFrequencyList(newList)
                }}
                viewOnlyMode={selectedFrequency ?? undefined}
            />
        </SideDrawerContainer>
    )
}
