import React from 'react'
import { LeaseAuditController } from '../../hooks/documentAudit'
import { Theme, Paper, Slide, Modal } from '@material-ui/core'
import { Container } from '../../components'

interface Props {
    open: boolean
    onClose: () => void
    theme: Theme
    residentDirectoryId: number
    leaseAuditController: LeaseAuditController
}

export const ResidentDirectoryModal = (props: Props) => {
    const { open, onClose, theme, residentDirectoryId } = props

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            width: 600,
                            height: 300,
                            padding: theme.spacing(2),
                            flexDirection: 'column',
                        }}
                    >
                        <span>Resident Directory {residentDirectoryId}</span>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
