import React from 'react'

import { WorkorderContext, useWorkorderState } from '../../contexts'

import { PropertyAutomationContainer } from './PropertyAutomationContainer'

export const PropertyAutomationRoot = () => {
    const workorderState = useWorkorderState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <PropertyAutomationContainer />
        </WorkorderContext.Provider>
    )
}
