import { ReactComponent as ActionItemIcon } from '../../assets/icons/action-item.svg'

import {
    CircularProgress,
    Divider,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import BarChartIcon from '@material-ui/icons/BarChart'
import TuneIcon from '@material-ui/icons/Tune'
import AddIcon from '@material-ui/icons/Add'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
    Container,
    DamageDrawer,
    InfrastructureFilterChips,
    Finder,
    FolderComponent,
    MessageDrawer,
    ScheduleReportPopOver,
    SideDrawer,
    SideDrawerContainer,
} from '../../components'
import {
    useAppDispatch,
    useAptConfig,
    useAreaStatusTag,
    useBlanket,
    _useFinderSelection,
    useInspection,
    _useLease,
    useMessageDrawer,
    useOnScreen,
    _usePrunedInfrastructure,
    useRootInfrastructure,
    useUser,
    useService,
    useCompany,
    useSchedule,
} from '../../hooks'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    getInspectionAnalytics,
    getInventoryInspectionList,
    GetLeaseListRequest,
    getUnitInspectionList,
    GET_UNIT_INSPECTION_LIST_REQUEST,
    InspectionCompletionFilter,
    InspectionHandledFilter,
    RootState,
    setInventoryInspectionList,
    UnitInspecitonListAxiosReq,
    UnitInspectionParams,
    getStatusGroupList,
    getStatusList,
    setMessageList,
    setUnits,
} from '../../store'
import { FileCopy } from '@material-ui/icons'
import { InfrastructureHoc } from '../../hoc'
import {
    ActionItem,
    BaseUnitInspection,
    Damage,
    IdentifiableObject,
    InventoryConfig,
    InventoryInspection,
    ModelListMap,
    traverse,
    Unit,
    UnitInspection,
} from '../../models'
import { AssignInspectionForm } from './AssignInspectionForm'
import { InspectionUnit } from './InspectionUnit'
import { UnitInspectionDetailForm } from './UnitInspectionDetailForm'
import { InspectionAssignModal } from './InspectionAssignModal'
import { FilterForm } from './FilterForm'
import { useInspectionFilter } from '../../hooks/useFilter'
import { useFilterApplied } from '../../components/Infrastructure/UseFilterApplied'
import { axiosInstance, toLocaleString } from '../../helpers'
import { toast } from 'react-toastify'
import { InspectionAnalyticsHeadline } from './InspectionAnalyticsHeadline'
import { AnalyticsBlanket } from './AnalyticsBlanket'
import {
    InvInsAnalyticsBlanket,
    InventoryInspectionAnalytic,
} from './InventoryInspectionAnalyticsBlanket'
import { ActionItemDashboard } from './ActionItemDashboard'

export interface InvInsAnalyticsBlanketData {
    inventoryConfig?: InventoryConfig
    status: string
    statusId?: string
    statusCount: number
    sumPrice: number
}

export const InspectionDetail = () => {
    const { id } = useParams<{ id?: string }>()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    // filter state

    // drawer/ui state
    const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [assignOpen, setAssignOpen] = useState(false)
    const [assignModalOpen, setAssignModalOpen] = useState(false)
    const [messageDrawerOpen, setMessageDrawerOpen] = useState(false)
    const [detailUnitInspection, setDetailUnitInspection] = useState<
        UnitInspection | undefined
    >(undefined)
    const [
        invInsAnalyitcsBlanketOpen,
        setInvInsAnalyitcsBlanketOpen,
    ] = useState(false)

    const [actionItemDashboardOpen, setActionItemDashboardOpen] = useState(
        false,
    )

    const [
        invInsAnalyticsBlanketData,
        setInvInsAnalyticsBlanketData,
    ] = useState<InvInsAnalyticsBlanketData>({
        status: '',
        statusCount: 0,
        sumPrice: 0,
    })

    const [
        currentInvInsAnalytic,
        setCurrentInvInsAnalytic,
    ] = useState<InventoryInspectionAnalytic>()
    const [
        inventoryInspectionAnalyticList,
        setInventoryInspectionAnalyticList,
    ] = useState<InventoryInspectionAnalytic[]>([])
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

    const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const [activeChannel, setActiveChannel] = useState<ActiveChannel>({
        type: null,
        modelId: null,
    })

    const popoverOpen = Boolean(anchorEl)

    const contentRef = useRef<HTMLDivElement>(null)
    const atBottomRef = useRef<HTMLDivElement>(null)
    const analyticRect = useBlanket(analyticsModalOpen, contentRef)
    const actionItemRect = useBlanket(actionItemDashboardOpen, contentRef)

    const {
        getUnitConfigMap,
        getInventoryConfigMap,
        getAreaConfigMap,
        unitConfigList,
        inventoryConfigList,
        customStatusList,
    } = useAptConfig({
        unitConfigList: true,
        inventoryConfigList: true,
        areaConfigList: true,
        customStatusList: true,
    })
    const unitConfigMap = getUnitConfigMap()
    const inventoryConfigMap = getInventoryConfigMap()
    const areaConfigMap = getAreaConfigMap()
    const { setChannel } = useMessageDrawer()

    const {
        inspectionDetail,
        unitInspectionMap,
        unitCompletionMap,
        fetchInspectionScore,
        inspectionScore,
        unitInspectionList,
    } = useInspection(Number(id) ?? -1, {
        getDetail: true,
    })
    const {
        filter,
        setUnitSearch,
        isInventoryInspectionInFilter,
    } = useInspectionFilter({ cleanUp: true })

    const nextLink = useSelector(
        (state: RootState) =>
            state.pagination.GET_UNIT_INSPECTION_LIST_REQUEST?.next,
    )

    const unitInsListLoading = useSelector(
        (state: RootState) =>
            state.inspection.isLoading[GET_UNIT_INSPECTION_LIST_REQUEST],
    )

    const [unitDamageMap, setUnitDamageMap] = useState<ModelListMap<Damage>>({})
    const [damageUnit, setDamageUnit] = useState<Unit | undefined>()

    const [actionItemList, setActionItemList] = useState<ActionItem[] | null>(
        null,
    )

    const [invInspectionList, setInvInspectionList] = useState<
        InventoryInspection[] | null
    >(null)

    const [messageDrawerTitle, setMessageDrawerTitle] = useState('')
    const [selectedUnit, setSelectedUnit] = useState<null | Unit>(null)

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Multi,
    })
    const assignmentFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Multi,
    })
    const { tree, unitCount, unitList } = useRootInfrastructure(
        inspectionDetail !== undefined,
    )
    const prunedTree = _usePrunedInfrastructure(
        tree,
        locationFinderSelection.selection,
    )

    const { areaStatusTagMap } = useAreaStatusTag({
        getListForSchedule: inspectionDetail?.schedule?.id ?? -1,
        cleanUp: true,
    })

    const { userList, actions } = useUser()
    const { getUserList } = actions

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })

    const { scheduleList, getScheduleList } = useSchedule()

    const loadSchedules = async () => {
        getScheduleList({})
    }

    useEffect(() => {
        dispatch(
            getUnitInspectionList({
                inspection_id: Number(id),
                params: { limit: 100, offset: 0 },
            }),
        )
        dispatch(
            getInspectionAnalytics({
                inspection_id: Number(id),
            }),
        )
        dispatch(getStatusGroupList({}))
        dispatch(getStatusList({}))
        axiosInstance
            .get('action-item/', {
                params: {
                    inspection: id,
                },
            })
            .then((res) => {
                setActionItemList(res.data)
            })
            .catch((e) => {
                toast.error(`Error loading action items ${e}`)
            })

        axiosInstance
            .get('inspection/inventory-inspection/', {
                params: {
                    inspection: id,
                    has_actionitem: true,
                },
            })
            .then((res) => {
                setInvInspectionList(res.data)
            })

        getUserList({ params: { my_team: true } })

        loadSchedules()
        fetchInspectionScore(Number(id))
    }, [])

    const atBottom = useOnScreen(atBottomRef)

    useEffect(() => {
        if (atBottom && nextLink) {
            dispatch(
                getUnitInspectionList({
                    url: nextLink,
                    inspection_id: Number(id),
                }),
            )
        }
    }, [atBottom])

    const [leaseParams, setLeaseParams] = useState<
        GetLeaseListRequest | undefined
    >(undefined)

    useEffect(() => {
        if (inspectionDetail) {
            setLeaseParams({
                params: {
                    overlap_start: inspectionDetail.start_date,
                    overlap_end: inspectionDetail.end_date,
                },
            })
        }
    }, [inspectionDetail])

    useEffect(() => {
        if (detailUnitInspection) {
            dispatch(
                getInventoryInspectionList({
                    params: {
                        inspection: Number(id),
                        units: `${detailUnitInspection.unit.id}`,
                    },
                }),
            )
        } else {
            dispatch(setInventoryInspectionList(undefined))
        }
    }, [detailUnitInspection])

    const { areaLeaseMap, createDamage, damageList } = _useLease({
        getLeaseList: leaseParams,
        cleanUp: true,
        getDamageListForInspecitonId: Number(id) ?? -1,
        createAreaLeaseMap: true,
    })

    useEffect(() => {
        if (damageList) {
            let curDamageIdx = 0
            const mutableDamageMap: ModelListMap<Damage> = {}
            traverse(tree.root, (folder) => {
                folder.units.forEach((unit) => {
                    while (
                        damageList[curDamageIdx] &&
                        damageList[curDamageIdx].unit === unit.id
                    ) {
                        if (mutableDamageMap[unit.id] === undefined) {
                            mutableDamageMap[unit.id] = []
                        }
                        mutableDamageMap[unit.id]!.push(
                            damageList[curDamageIdx],
                        )
                        curDamageIdx += 1
                    }
                })
            })
            setUnitDamageMap(mutableDamageMap)
        }
    }, [damageList])

    const getUnitAdornment = (unit: Unit) => {
        const unitInspection: BaseUnitInspection | undefined =
            unitCompletionMap[unit.id]

        if (unitInspection) {
            return (
                <CheckCircleIcon
                    fontSize="small"
                    color={
                        unitInspection.complete_status ? 'primary' : 'secondary'
                    }
                />
            )
        }

        return null
    }

    const { filterApplied, setAllApplied } = useFilterApplied(
        filter,
        locationFinderSelection.selection,
    )

    const getFilteredParams = () => {
        // build filters for request

        const params: UnitInspectionParams = {}

        if (filter.hasDamages) {
            params.has_damage = true
        }

        if (filter.unitSearch !== '') {
            params.unit_name = filter.unitSearch
        }
        if (
            filter.inspectionCompletionFilter !== InspectionCompletionFilter.All
        ) {
            params.complete =
                filter.inspectionCompletionFilter ===
                InspectionCompletionFilter.Complete
        }
        if (Object.keys(filter.unitConfigFilter).length > 0) {
            params.unit_configs = Object.keys(filter.unitConfigFilter)
                .reduce((prev, id) => {
                    return prev.concat(`${id},`)
                }, '')
                .slice(0, -1)
        }

        if (Object.keys(filter.invConfigFilter).length > 0) {
            params.inventory_configs = Object.keys(filter.invConfigFilter)
                .reduce((prev, id) => {
                    return prev.concat(`${id},`)
                }, '')
                .slice(0, -1)
        }
        if (Object.keys(filter.customStatusFilter).length > 0) {
            params.statuses = Object.keys(filter.customStatusFilter)
                .reduce((prev, id) => {
                    return prev.concat(`${id},`)
                }, '')
                .slice(0, -1)
        }

        if (filter.hasFlaggedItem) {
            params.has_flag = true
        }
        if (filter.handled !== InspectionHandledFilter.All) {
            params.handled = filter.handled === InspectionHandledFilter.Handled
        }
        if (filter.hasCostDriver) {
            params.cost_driver = true
        }
        if (filter.hasMessages) {
            params.has_message = true
        }
        if (filter.unreadFilter) {
            params.has_unread = true
        }

        if (locationFinderSelection.selection.unit.length > 0) {
            let units = ''
            Object.keys(locationFinderSelection.selection.unit).forEach(
                (key) => {
                    if (
                        key !== 'length' &&
                        locationFinderSelection.selection.unit[Number(key)] !==
                            undefined
                    ) {
                        units = units.concat(`${key},`)
                    }
                },
            )
            units = units.slice(0, -1)
            params.units = units
        }
        if (locationFinderSelection.selection.folder.length > 0) {
            let folders = ''
            Object.keys(locationFinderSelection.selection.folder).forEach(
                (key) => {
                    if (
                        key !== 'length' &&
                        locationFinderSelection.selection.folder[
                            Number(key)
                        ] !== undefined
                    ) {
                        folders = folders.concat(`${key},`)
                    }
                },
            )
            folders = folders.slice(0, -1)
            params.folders = folders
        }

        if (inspectionDetail) {
            params.inspection = inspectionDetail.id
        }

        return params
    }

    const makeInventoryInspectionAnalyticsRequest = () => {
        const params: UnitInspectionParams = getFilteredParams()
        if (inspectionDetail) {
            params.inspection = inspectionDetail.id
        }
        if (invInsAnalyticsBlanketData.inventoryConfig) {
            params.inventory_configs = invInsAnalyticsBlanketData.inventoryConfig.id.toString()
        }
        if (invInsAnalyticsBlanketData.statusId) {
            params.statuses = invInsAnalyticsBlanketData.statusId
        }
        axiosInstance
            .get('inspection/inventory-inspection/get_analytics_list/', {
                params: params,
            })
            .then((res) => {
                setInventoryInspectionAnalyticList(res.data)
            })
    }

    const makeFilteredRequest = () => {
        // build filters for request

        const params: UnitInspectionParams = getFilteredParams()

        const req: UnitInspecitonListAxiosReq = {
            inspection_id: Number(id),
            params: params,
        }
        dispatch(getUnitInspectionList(req)).then(() => setAllApplied())
        dispatch(getInspectionAnalytics(req))
        makeInventoryInspectionAnalyticsRequest()
    }

    const updateListState = <T extends IdentifiableObject>(
        stateSetter: React.Dispatch<React.SetStateAction<T[] | null>>,
        updatedModel: T,
    ) => {
        stateSetter((prevList) => {
            const newList = [...(prevList ?? [])]
            return newList.map((existingModel) => {
                if (existingModel.id === updatedModel.id) {
                    return updatedModel
                }
                return existingModel
            })
        })
    }

    const onAddMessage = <T extends IdentifiableObject>(
        modelList: T[],
        modelId: number,
        updateHandler: StateSetter<T>,
    ) => {
        const model = modelList.find((m) => m.id === modelId)
        if (model) {
            const newModel = { ...model } as any
            newModel.message_count = newModel.message_count ?? 0
            newModel.message_count += 1
            updateListState(updateHandler, newModel)
        }
    }

    const latestCompletedDateTime: Date | null = useMemo(() => {
        let latestDate: Date | null = null

        unitInspectionList?.forEach((unitInspection: UnitInspection) => {
            // Check if the inspection is complete and has a valid sort_date
            if (
                unitInspection.complete_status === 1 &&
                unitInspection.sort_date
            ) {
                const completedDate = new Date(unitInspection.sort_date)
                // Update latestDate if completedDate is more recent
                if (!latestDate || completedDate > latestDate) {
                    latestDate = completedDate
                }
            }
        })

        return latestDate
    }, [unitInspectionList])

    return (
        <SideDrawerContainer open={true}>
            {/* Main Content */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 104px)',
                }}
            >
                {/* Header */}

                <Container
                    style={{
                        padding: theme.spacing(1),
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}
                >
                    {/* Inspection Title and controls */}
                    <Container style={{ width: '100%', alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                                marginRight: theme.spacing(1),
                            }}
                        >
                            {inspectionDetail?.name ?? ''}
                        </span>

                        <span>
                            {inspectionScore.total} / {inspectionScore.max}
                        </span>
                        <div style={{ flex: 1 }} />

                        <TextField
                            variant="outlined"
                            label="Unit Search"
                            value={filter.unitSearch}
                            onChange={(e) => {
                                setUnitSearch(e.target.value)
                            }}
                            size="small"
                            InputProps={{
                                endAdornment: <SearchIcon />,
                            }}
                        />

                        <div style={{ marginLeft: theme.spacing(1) }}>
                            <IconButton
                                onClick={() => {
                                    setActionItemDashboardOpen((open) => !open)
                                }}
                            >
                                <ActionItemIcon
                                    style={{ height: 35, width: 35 }}
                                />
                            </IconButton>
                        </div>

                        <Container onClick={handlePopoverClick}>
                            <IconButton onClick={() => {}}>
                                <FileCopy fontSize="large" />
                            </IconButton>
                        </Container>

                        <ScheduleReportPopOver
                            open={popoverOpen}
                            onClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            reportItems={[
                                {
                                    title: 'Email Excel Report',
                                    downloadLink: {
                                        title: 'Email',
                                        onClick: () => {
                                            axiosInstance.get(
                                                'inspection/inventory-inspection/create_report/',
                                                { params: getFilteredParams() },
                                            )
                                            toast.success(
                                                'You will receive an email with your report shortly!',
                                            )
                                        },
                                    },
                                },
                                {
                                    title:
                                        'Filtered Full Property PDF (with messages)',
                                    downloadLink: {
                                        title: 'Full PDF',
                                        onClick: () => {
                                            axiosInstance
                                                .get(
                                                    `inspection/${inspectionDetail?.id}/get_report/`,
                                                    {
                                                        params: getFilteredParams(),
                                                    },
                                                )
                                                .then(() => {
                                                    toast.success(
                                                        'Your report is generating. This report may take a few hours, you will recieve an email when it is complete',
                                                    )
                                                })
                                        },
                                    },
                                },
                            ]}
                        />
                        <Tooltip title="Inspection Analytics">
                            <IconButton
                                onClick={() => {
                                    if (analyticsModalOpen) {
                                        setInvInsAnalyitcsBlanketOpen(false)
                                    }
                                    setAnalyticsModalOpen(!analyticsModalOpen)
                                }}
                                color={
                                    analyticsModalOpen ? 'secondary' : 'default'
                                }
                            >
                                <BarChartIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Filter">
                            <IconButton
                                onClick={() => {
                                    setFilterOpen(!filterOpen)
                                }}
                                color={filterOpen ? 'primary' : 'default'}
                            >
                                <TuneIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Assign">
                            <IconButton
                                onClick={() => {
                                    setAssignOpen(!assignOpen)
                                    setFilterOpen(false)
                                }}
                                color={assignOpen ? 'primary' : 'default'}
                            >
                                <AddIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    {/* Analytics */}
                    <InspectionAnalyticsHeadline totalUnitCount={unitCount} />
                </Container>

                {latestCompletedDateTime && (
                    <Container style={{ padding: theme.spacing(1) }}>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Latest Completed Unit:{' '}
                            {toLocaleString(latestCompletedDateTime as Date)}
                        </span>
                    </Container>
                )}

                <InfrastructureFilterChips
                    filter={filter}
                    unitConfigList={unitConfigList}
                    locationSelection={locationFinderSelection.selection}
                    setLocationSelection={
                        locationFinderSelection.setFinderSelection
                    }
                    invConfigList={inventoryConfigList}
                    customStatusList={customStatusList}
                    filterApplied={filterApplied}
                    makeFilteredRequest={makeFilteredRequest}
                />
                {/* End Header */}

                <Divider />

                {/* Infrastructure */}
                <div
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'auto',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    ref={contentRef}
                >
                    <InfrastructureHoc
                        infrastructure={tree.root}
                        renderFolder={(folder, renderedUnits) => {
                            return (
                                <FolderComponent
                                    key={`FOLDER_COMPONENT_${folder.id}`}
                                    name={folder.name}
                                    path={folder.path}
                                    unitLength={folder.units.length}
                                >
                                    {renderedUnits}
                                </FolderComponent>
                            )
                        }}
                        renderUnit={(unit) => {
                            return (
                                <InspectionUnit
                                    unit={unit}
                                    areaStatusMap={areaStatusTagMap}
                                    unitConfig={unitConfigMap[unit.unit_config]}
                                    unitInspection={unitInspectionMap[unit.id]}
                                    onClickDetail={() => {
                                        if (
                                            detailUnitInspection?.id ===
                                            unitInspectionMap[unit.id]?.id
                                        ) {
                                            setDetailUnitInspection(undefined)
                                        } else {
                                            setDetailUnitInspection(
                                                unitInspectionMap[unit.id],
                                            )
                                        }
                                    }}
                                    onClickAssign={() => {
                                        // Clear the previous selections
                                        assignmentFinderSelection.setFinderSelection()
                                        // Set the selection to match this unit
                                        assignmentFinderSelection.setFinderSelection(
                                            {
                                                type: 'unit',
                                                location: unit,
                                            },
                                        )
                                        setAssignModalOpen(true)
                                    }}
                                    selected={
                                        unitInspectionMap[unit.id] !==
                                            undefined &&
                                        unitInspectionMap[unit.id]?.id ===
                                            detailUnitInspection?.id
                                    }
                                    key={`INSPECTION_UNIT_${unit.id}`}
                                    damageCount={
                                        unitDamageMap[unit.id]?.length ?? 0
                                    }
                                    clickDamage={(unit) => {
                                        if (
                                            damageUnit &&
                                            damageUnit.id === unit.id
                                        ) {
                                            setDamageUnit(undefined)
                                        } else {
                                            let unitLeaseStr = ''

                                            unit.areas.forEach((area) => {
                                                const areaLeases =
                                                    areaLeaseMap[area.id]
                                                areaLeases?.forEach((lease) => {
                                                    unitLeaseStr += `${lease.id},`
                                                })
                                            })

                                            if (unitLeaseStr.length > 0) {
                                                unitLeaseStr = unitLeaseStr.substring(
                                                    0,
                                                    unitLeaseStr.length - 1,
                                                )
                                            }
                                            setDamageUnit(unit)
                                        }
                                    }}
                                    onClickUnitNotes={() => {
                                        if (unit.channel_id) {
                                            setDetailUnitInspection(undefined)
                                            setChannel(unit.channel_id)
                                            setMessageDrawerOpen(true)
                                            setMessageDrawerTitle(
                                                `Unit Notes ${unit.name}`,
                                            )
                                            setSelectedUnit(unit)
                                            if (unit.unread_unit_notes !== 0) {
                                                const newUnitList = unitList?.map(
                                                    (innerUnit) => {
                                                        if (
                                                            unit.id ===
                                                            innerUnit.id
                                                        ) {
                                                            return {
                                                                ...innerUnit,
                                                                unread_unit_notes: 0,
                                                            }
                                                        }
                                                        return innerUnit
                                                    },
                                                )
                                                dispatch(
                                                    setUnits(newUnitList ?? []),
                                                )
                                            }
                                        }
                                    }}
                                />
                            )
                        }}
                        isFolderVisible={(folder, renderedUnitCount) =>
                            renderedUnitCount > 0
                        }
                        isUnitVisible={(unit) => {
                            return unit.id in unitInspectionMap
                        }}
                    />

                    <div
                        ref={atBottomRef}
                        style={{
                            padding: theme.spacing(3),
                            justifyContent: 'center',
                            display: 'flex',
                            flex: 1,
                        }}
                    >
                        {unitInsListLoading && <CircularProgress size={50} />}
                    </div>
                </div>
                {/* End Infrastructure */}
            </Container>
            {/* End Main Content */}

            {/* Location Drawer */}
            <SideDrawer
                open={true}
                handleClose={() => {}}
                alwaysOpen
                title="Select Location"
            >
                <Container
                    flex={1}
                    direction="column"
                    style={{
                        padding: theme.spacing(2),
                        overflow: 'hidden',
                    }}
                >
                    <Finder
                        root={tree.root}
                        locationSelection={locationFinderSelection.selection}
                        onClickFolder={
                            locationFinderSelection.setFinderSelection
                        }
                        onClickUnit={locationFinderSelection.setFinderSelection}
                        getUnitAdornment={getUnitAdornment}
                    />
                </Container>
            </SideDrawer>

            {/* Assign Drawer */}
            <SideDrawer
                open={assignOpen}
                handleClose={() => setAssignOpen(false)}
                title="Assign"
            >
                <AssignInspectionForm
                    prunedTree={prunedTree.root}
                    onClose={() => setAssignOpen(false)}
                    setAssignModalOpen={() => setAssignModalOpen(true)}
                    getUnitAdornment={getUnitAdornment}
                />
            </SideDrawer>

            {/* Filter Drawer */}
            <FilterForm
                open={filterOpen}
                unitConfigList={unitConfigList}
                inventoryConfigList={inventoryConfigList ?? []}
                customStatusList={customStatusList}
                locationSelection={locationFinderSelection.selection}
                handleClose={() => setFilterOpen(false)}
                makeFilteredRequest={makeFilteredRequest}
            />

            <DamageDrawer
                unit={damageUnit}
                openMessages={(damage: Damage) => {
                    setChannel(damage.channel)
                    setMessageDrawerOpen(true)
                }}
                handleClose={() => setDamageUnit(undefined)}
                areaConfigMap={areaConfigMap}
                createDamage={createDamage}
                unitDamages={unitDamageMap[damageUnit?.id ?? -1]}
                inspection={inspectionDetail?.id}
            />

            <MessageDrawer
                title={messageDrawerTitle}
                open={messageDrawerOpen}
                onClose={() => {
                    setActiveChannel({ modelId: null, type: null })
                    setMessageDrawerOpen(false)
                    setCurrentInvInsAnalytic(undefined)
                    setMessageDrawerTitle('')
                    setSelectedUnit(null)
                }}
                onCreateMessage={() => {
                    if (currentInvInsAnalytic) {
                        setCurrentInvInsAnalytic({
                            ...currentInvInsAnalytic,
                            unread_count: 0,
                            channel_message_count:
                                currentInvInsAnalytic.channel_message_count + 1,
                        })
                    }

                    if (
                        activeChannel.modelId !== null &&
                        activeChannel.type !== null
                    ) {
                        if (activeChannel.type === 'action-item') {
                            onAddMessage(
                                actionItemList ?? [],
                                activeChannel.modelId,
                                setActionItemList,
                            )
                        }

                        if (activeChannel.type === 'inventory-inspection') {
                            onAddMessage(
                                invInspectionList ?? [],
                                activeChannel.modelId,
                                setInvInspectionList,
                            )
                        }
                    }

                    if (selectedUnit) {
                        const newUnitList = unitList?.map((unit) => {
                            if (unit.id === selectedUnit.id) {
                                return {
                                    ...unit,
                                    unit_notes_msg_count:
                                        unit.unit_notes_msg_count + 1,
                                }
                            }
                            return unit
                        })
                        dispatch(setUnits(newUnitList ?? []))
                    }
                }}
            />

            <InspectionAssignModal
                makeFilteredRequest={makeFilteredRequest}
                open={assignModalOpen}
                areaConfigMap={areaConfigMap}
                onClose={() => setAssignModalOpen(false)}
                inspectionDetail={inspectionDetail}
                unitCompletionMap={unitCompletionMap}
                areaStatusMap={areaStatusTagMap}
            />

            <UnitInspectionDetailForm
                unitConfigMap={unitConfigMap}
                inventoryConfigMap={inventoryConfigMap}
                unitInspection={detailUnitInspection}
                areaStatusMap={areaStatusTagMap}
                isInventoryInspectionInFilter={isInventoryInspectionInFilter}
                onClose={() => setDetailUnitInspection(undefined)}
                setUnitInspection={setDetailUnitInspection}
            />

            <AnalyticsBlanket
                open={analyticsModalOpen}
                rect={analyticRect}
                setInvConfAnalyticsBlanketData={setInvInsAnalyticsBlanketData}
                setInvConfAnalyticsBlanketOpen={setInvInsAnalyitcsBlanketOpen}
            />

            <InvInsAnalyticsBlanket
                setOpen={setInvInsAnalyitcsBlanketOpen}
                open={invInsAnalyitcsBlanketOpen}
                currentInvInsAnalytic={currentInvInsAnalytic}
                setCurrentInvInsAnalytic={setCurrentInvInsAnalytic}
                setInventoryInspectionAnalyticList={
                    setInventoryInspectionAnalyticList
                }
                inventoryInspectionAnalyticList={
                    inventoryInspectionAnalyticList
                }
                makeInventoryInspectionAnalyticRequest={
                    makeInventoryInspectionAnalyticsRequest
                }
                blanketData={invInsAnalyticsBlanketData}
                getFilteredParams={getFilteredParams}
                inspection={inspectionDetail}
                rect={analyticRect}
                openMessages={(channelId: number) => {
                    setChannel(channelId)
                    setMessageDrawerOpen(true)
                }}
                userList={userList}
                serviceList={serviceList}
                vendorList={vendorList}
                scheduleList={scheduleList.filter(
                    (schedule) => schedule.active,
                )}
            />

            <ActionItemDashboard
                open={actionItemDashboardOpen}
                rect={actionItemRect}
                onClose={() => setActionItemDashboardOpen(false)}
                actionItemList={actionItemList}
                invInspectionList={invInspectionList}
                inspectionId={Number(id)}
                onUpdateActionItem={(actionItem) =>
                    updateListState(setActionItemList, actionItem)
                }
                openMessageDrawer={(channel, activeChannel) => {
                    setActiveChannel(activeChannel)
                    dispatch(setMessageList([]))
                    setChannel(channel)
                    setMessageDrawerOpen(true)
                }}
            />
        </SideDrawerContainer>
    )
}

export type ActiveChannelModel = 'action-item' | 'inventory-inspection' | null

export type ActiveChannel = {
    type: ActiveChannelModel
    modelId: number | null
}

type StateSetter<T extends IdentifiableObject> = React.Dispatch<
    React.SetStateAction<T[] | null>
>
