import React from 'react'

import {
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../components'
import { ApartmentEZNowDetail } from './ApartmentEZNowDetail'
import { RFP } from '../../models'

interface Props {
    selectedRFP: RFP
    openEZNowModal: boolean
    setOpenEZNowModal: (open: boolean) => void
}

export const ApartmentEZNowDetailModal = (props: Props) => {
    const { selectedRFP, openEZNowModal, setOpenEZNowModal } = props

    const theme = useTheme()

    return (
        <Modal
            open={openEZNowModal}
            onClose={() => setOpenEZNowModal(false)}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={openEZNowModal}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        height: 'calc(100vh - 200px)',
                        width: 'calc(100vw - 200px)',
                        maxHeight: '700px',
                        maxWidth: '1100px',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h5,
                                flex: 1,
                            }}
                        >
                            EZNow
                        </span>
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    setOpenEZNowModal(false)
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Divider />
                    <ApartmentEZNowDetail ezNowRequest={selectedRFP} />
                </Paper>
            </Slide>
        </Modal>
    )
}
