import React, { createContext, useContext, useState } from 'react'
import {
    IncidentReportFilterType,
    IncidentReportFilters,
} from './IncidentReportFilters'
import { IncidentReportTable } from './IncidentReportTable'
import { axiosInstance } from '../../helpers'
import {
    Claimant,
    IdentifiableNamedObject,
    IncidentReport,
    Witness,
    WitnessClaimant,
} from '../../models'
import { toast } from 'react-toastify'
import { create } from 'domain'
import { SideDrawerContainer } from '../../components'

interface Props {
    onIncidentSelect: () => void
    portfolio?: boolean
    incidentReportFilters: IncidentReportFilterType
    setFilters: (filters: IncidentReportFilterType) => void
}

export interface IncidentReportStateType {
    incidentReports: IncidentReport[]
    setIncidentReports: (incidnetReports: IncidentReport[]) => void
    selectedIncidentReport?: IncidentReport
    setSelectedIncidentReport: (
        incidnetReport: IncidentReport | undefined,
    ) => void
    newWitness?: WitnessClaimant
    setNewWitness: (witness?: WitnessClaimant) => void
    newClaimant?: WitnessClaimant
    setNewClaimant: (claimant?: WitnessClaimant) => void
    incidentTypes: IdentifiableNamedObject[]
    setIncidentTypes: (incidnetTypes: IdentifiableNamedObject[]) => void
    organization?: IdentifiableNamedObject
    setOrganization: (organization?: IdentifiableNamedObject) => void
    getIncidentReports: () => void
    getIncidentTypes: () => void
    getOrganization: () => void
    updateIncidentReport: (incidentReport: IncidentReport) => void
    handleAddWitness: (witness: Witness, incidnetReport: IncidentReport) => void
    handleAddClaimant: (
        claimant: Claimant,
        incidnetReport: IncidentReport,
    ) => void
    addWitness: (
        witness: WitnessClaimant,
        incidentReport: IncidentReport,
    ) => void
    addClaimant: (
        witness: WitnessClaimant,
        incidentReport: IncidentReport,
    ) => void
    getPDF: (toEmail: string) => void
    transitionStatus: (
        incidentReport: IncidentReport,
        notSubmitted?: boolean,
    ) => void
    addPictures: (incidentReport: IncidentReport, files: File[]) => void
    addIncidentReport: (args: any, images?: File[]) => void
    filtersOpen: boolean
    setFiltersOpen: (open: boolean) => void
}

export const IncidentReportContext = createContext<IncidentReportStateType>({
    incidentReports: [],
    setIncidentReports: (incidentReports) => {},
    selectedIncidentReport: undefined,
    setSelectedIncidentReport: (incidentReport) => {},
    newWitness: undefined,
    setNewWitness: (witness) => {},
    newClaimant: undefined,
    setNewClaimant: (claimant) => {},
    incidentTypes: [],
    setIncidentTypes: (incidnetTypes) => {},
    organization: undefined,
    setOrganization: (organization) => {},
    getIncidentReports: () => {},
    getOrganization: () => {},
    getIncidentTypes: () => {},
    getPDF: () => {},
    addClaimant: () => {},
    addIncidentReport: () => {},
    addPictures: () => {},
    addWitness: () => {},
    handleAddClaimant: () => {},
    handleAddWitness: () => {},
    transitionStatus: () => {},
    updateIncidentReport: () => {},
    filtersOpen: false,
    setFiltersOpen: () => {},
})

export const IncidentReportRoot = (props: Props) => {
    const [incidentReports, setIncidentReports] = useState<IncidentReport[]>([])

    const [filtersOpen, setFiltersOpen] = useState(false)

    const [
        selectedIncidentReport,
        setSelectedIncidentReport,
    ] = useState<IncidentReport>()

    const [newWitness, setNewWitness] = useState<WitnessClaimant | undefined>()
    const [newClaimant, setNewClaimant] = useState<
        WitnessClaimant | undefined
    >()

    const [incidentTypes, setIncidentTypes] = useState<
        IdentifiableNamedObject[]
    >([])
    const [organization, setOrganization] = useState<IdentifiableNamedObject>()

    const getIncidentTypes = () => {
        axiosInstance.get('inspection/incident-type/').then((res) => {
            state.setIncidentTypes(res.data)
        })
    }

    const getOrganization = () => {
        axiosInstance.get('company/org-detail/').then((res) => {
            state.setOrganization(res.data)
        })
    }

    const getIncidentReports = () => {
        // network request
        axiosInstance.get('inspection/incident-report/').then((res) => {
            state.setIncidentReports(res.data)
        })
    }

    const updateIncidentReport = (incidentReport: IncidentReport) => {
        let found = false
        const newIncidentReportList = incidentReports.map((innerReport) => {
            if (innerReport.id === incidentReport.id) {
                found = true
                return incidentReport
            }
            return innerReport
        })
        if (!found) {
            newIncidentReportList.push(incidentReport)
        }
        setIncidentReports(newIncidentReportList)
        if (incidentReport.id === selectedIncidentReport?.id) {
            setSelectedIncidentReport(incidentReport)
        }
    }

    const handleAddWitness = (
        witness: Witness,
        incidnetReport: IncidentReport,
    ) => {
        state.setNewWitness(undefined)

        updateIncidentReport({
            ...incidnetReport,
            witnesses: [...(incidnetReport.witnesses ?? []), witness],
        })
    }
    const handleAddClaimant = (
        claimant: Claimant,
        incidnetReport: IncidentReport,
    ) => {
        state.setNewClaimant(undefined)

        updateIncidentReport({
            ...incidnetReport,
            claimants: [...(incidnetReport.claimants ?? []), claimant],
        })
    }

    const addWitness = (
        witness: WitnessClaimant,
        incidentReport: IncidentReport,
    ) => {
        axiosInstance
            .post(
                `inspection/incident-report/${incidentReport.id}/add_witness/`,
                {
                    email: witness.email,
                    name: witness.name,
                    reason_on_the_community: witness.reasonOnCommunity,
                },
            )
            .then((res) => {
                handleAddWitness(res.data, incidentReport)
            })
    }
    const addClaimant = (
        claimant: WitnessClaimant,
        incidentReport: IncidentReport,
    ) => {
        axiosInstance
            .post(
                `inspection/incident-report/${incidentReport.id}/add_claimant/`,
                {
                    email: claimant.email,
                    name: claimant.name,
                    reason_on_the_community: claimant.reasonOnCommunity,
                    seeking_compensation: claimant.seekingComp,
                    first_aid_description: claimant.firstAid,
                    medical_transport_provider:
                        claimant.medicalTransportProvider,
                    object_carrying_description:
                        claimant.objectCarryingOrHolding,
                },
            )
            .then((res) => {
                handleAddClaimant(res.data, incidentReport)
            })
    }

    const getPDF = (toEmail: string) => {
        axiosInstance
            .post(
                `inspection/incident-report/${selectedIncidentReport?.id}/pdf/`,
                { email: toEmail },
            )
            .then(() => {
                toast.success('Check your email for the pdf')
            })
    }

    const transitionStatus = (
        incidentReport: IncidentReport,
        notSubmitted?: boolean,
    ) => {
        axiosInstance
            .post(
                `inspection/incident-report/${incidentReport.id}/transition_status/`,
                { not_submitted: notSubmitted },
            )
            .then((res) => {
                updateIncidentReport(res.data)
            })
    }

    const addPictures = (incidentReport: IncidentReport, files: File[]) => {
        const formData = new FormData()
        files.forEach((file, idx) => {
            formData.append(`attachment${idx}`, file)
        })
        axiosInstance
            .post(
                `inspection/incident-report/${incidentReport.id}/add_pictures/`,
                formData,
            )
            .then((res) => {
                updateIncidentReport(res.data)
            })
    }

    const addIncidentReport = (args: any, images?: File[]) => {
        const addReportPromise = axiosInstance.post(
            'inspection/incident-report/',
            args,
        )

        Promise.all([addReportPromise]).then((res) => {
            const incidentReport = res[0].data

            setIncidentReports([...incidentReports, incidentReport])
            if (images) {
                addPictures(incidentReport, images)
            }
        })
    }

    const state: IncidentReportStateType = {
        incidentReports,
        setIncidentReports,
        selectedIncidentReport,
        setSelectedIncidentReport,
        newWitness,
        setNewWitness,
        newClaimant,
        setNewClaimant,
        incidentTypes,
        setIncidentTypes,
        organization,
        setOrganization,
        getIncidentReports,
        getIncidentTypes,
        getOrganization,
        updateIncidentReport,
        handleAddWitness,
        handleAddClaimant,
        addWitness,
        addClaimant,
        getPDF,
        transitionStatus,
        addPictures,
        addIncidentReport,
        filtersOpen,
        setFiltersOpen,
    }

    return (
        <IncidentReportContext.Provider value={state}>
            <SideDrawerContainer open={filtersOpen}>
                <IncidentReportTable
                    onIncidentSelect={props.onIncidentSelect}
                    portfolio={props.portfolio}
                    incidentReportFilters={props.incidentReportFilters}
                    setFilters={props.setFilters}
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                />
                <IncidentReportFilters
                    filters={props.incidentReportFilters}
                    setFilters={props.setFilters}
                    open={filtersOpen}
                    handleClose={() => setFiltersOpen(false)}
                />
            </SideDrawerContainer>
        </IncidentReportContext.Provider>
    )
}
