import React, { useEffect, useState } from 'react'
import {
    CircularProgress,
    FormControlLabel,
    Switch,
    useTheme,
    Divider,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { DateFilter, StatusFilter, VendorWorkorder } from '.'
import { Container, MessageDrawer, SideDrawerContainer } from '../../components'
import {
    useAppDispatch,
    useMessageDrawer,
    useUser,
    _useWorkorder,
} from '../../hooks'
import {
    BaseWorkorder,
    _Workorder,
    getDateRange,
    isWorkorderSafe,
} from '../../models'
import {
    getJobsiteLocationList,
    getJobsiteServiceList,
    getJobsiteWorkorderList,
    getMessageList,
    getOrCreateWorkorderChannel,
    getWorkOrderList,
    resetMessageState,
    RootState,
    setJobsiteLocation,
    setJobsiteService,
    setPriorityFilter,
} from '../../store'
import { Redirect, useLocation } from 'react-router-dom'
import { getNumFromURLSegment } from '../../helpers'

let goHome = false

export const VendorScheduler = () => {
    const jobsite = useSelector((state: RootState) => state.jobsite)
    const theme = useTheme()
    const { workspaceUser, actions } = useUser()
    const { getUserList } = actions
    const userList = useSelector((state: RootState) => state.user.userList)
    const dispatch = useAppDispatch()
    const {
        incrementWorkorderMessageCount,
        workorderList,
        incrementUnitNotesMessageCount,
        resetUnitNotesUnreadCount,
    } = _useWorkorder()

    const [loading, setLoading] = useState(true)
    const [messageWo, setMessageWo] = useState<BaseWorkorder | null>(null)
    const [onlyUnread, setOnlyUnread] = useState(false)

    const [openUnitNotes, setOpenUnitNotes] = useState(false)
    const [selectedWorkorder, setSelectedWorkorder] = useState<
        _Workorder | undefined
    >()

    const { setChannel } = useMessageDrawer()

    const url = useLocation()
    useEffect(() => {
        dispatch(resetMessageState())
        getUserList({ params: { my_team: true } })
        if (jobsite.service === undefined) {
            //we are here from a refresh
            const urlSegment = url.pathname.substring(11) // <serviceID>/location/<locationID>
            const serviceIdAndLocSegment = getNumFromURLSegment(urlSegment)
            const dateRange = getDateRange(jobsite.dateFilter)
            let found = false
            dispatch(getJobsiteServiceList({ params: { ...dateRange } }))
                .then((res) => {
                    //get the current service so that we can get the location list and set the service
                    res.data.forEach((service) => {
                        if (service.id === serviceIdAndLocSegment.number) {
                            dispatch(setJobsiteService(service))
                            dispatch(
                                getJobsiteLocationList({
                                    params: {
                                        ...dateRange,
                                        service: service.id,
                                        wo_status:
                                            jobsite.workorderStatusFilter,
                                        priority: jobsite.priorityFilter,
                                    },
                                }),
                            )
                                .then((res) => {
                                    //filter through so we can set the current location

                                    //using the getNumFromURLSegment again because
                                    //even though the number is just at the end, the user can append garbage to the end
                                    // of the url that the router will just ignore, but will still be in the url

                                    const locSegment = serviceIdAndLocSegment.urlSegment.substring(
                                        10,
                                    )
                                    const locID = getNumFromURLSegment(
                                        locSegment,
                                    ).number

                                    res.data.forEach((location) => {
                                        if (location.id === locID) {
                                            found = true
                                            setLoading(false)
                                            dispatch(
                                                setJobsiteLocation(location),
                                            )
                                        }
                                    })
                                    goHome = !found
                                })
                                .catch(() => {
                                    // catch for get location list
                                    goHome = true
                                })
                        }
                    })
                })
                .catch(() => {
                    //catch for get service list
                    goHome = true
                })
        } else {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        const dateRange = getDateRange(jobsite.dateFilter)
        if (jobsite.location && jobsite.service) {
            dispatch(
                getWorkOrderList({
                    params: {
                        service: jobsite.service?.id,
                        folder: jobsite.location?.id,
                        wo_status: jobsite.workorderStatusFilter,
                        priority: jobsite.priorityFilter,
                        upper_bound_date: dateRange.lte_date,
                        lower_bound_date: dateRange.gte_date,
                    },
                }),
            )
        }
    }, [
        jobsite.dateFilter,
        jobsite.workorderStatusFilter,
        jobsite.service,
        jobsite.priorityFilter,
        jobsite.location,
    ])

    if (loading) {
        return (
            <Container flex={1} alignItems="center" justifyContent="center">
                <CircularProgress color="primary" size="15rem" />
            </Container>
        )
    }

    return (
        <SideDrawerContainer open={messageWo !== null}>
            <Container direction="column" flex={1}>
                {goHome ? <Redirect to="/scheduler" /> : null}

                {/* Header */}
                <Container
                    direction="row"
                    style={{ padding: theme.spacing(1, 0, 0, 1) }}
                >
                    {/* left side */}
                    <Container direction="column" flex={1}>
                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(0.5),
                            }}
                        >
                            {jobsite.service?.name}
                        </span>
                        <Container direction="row">
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {jobsite.location?.path}
                                {jobsite.location?.name}
                            </span>
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    marginLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >{`${workorderList.length} Workorders`}</span>
                        </Container>
                    </Container>

                    {/* Right Side */}
                    <Container
                        style={{ margin: theme.spacing(0, 2, 1, 0) }}
                        alignItems="flex-end"
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Jobsite:{' '}
                        </span>
                        <span>{workspaceUser?.jobsite?.name}</span>
                    </Container>
                </Container>
                {/* filters row */}
                <Container style={{ marginTop: theme.spacing(1) }}>
                    <Container flex={1}>
                        <DateFilter
                            style={{
                                formControl: {
                                    width: 100,
                                    marginLeft: theme.spacing(3),
                                },
                            }}
                        />
                        <StatusFilter
                            style={{
                                formControl: {
                                    width: 120,
                                    marginLeft: theme.spacing(3),
                                },
                            }}
                        />
                        <FormControlLabel
                            labelPlacement="top"
                            label="Priority"
                            style={{
                                marginLeft: theme.spacing(2),
                            }}
                            control={
                                <Switch
                                    checked={jobsite.priorityFilter}
                                    color="primary"
                                    onClick={() =>
                                        dispatch(
                                            setPriorityFilter(
                                                !jobsite.priorityFilter,
                                            ),
                                        )
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            labelPlacement="top"
                            label="Unread"
                            style={{
                                marginLeft: theme.spacing(2),
                            }}
                            control={
                                <Switch
                                    checked={onlyUnread}
                                    color="primary"
                                    onClick={() => setOnlyUnread(!onlyUnread)}
                                />
                            }
                        />
                    </Container>
                </Container>
                <Divider />
                {/* Workorders */}
                <Container
                    scrollY
                    direction="column"
                    style={{ backgroundColor: theme.palette.grey[100] }}
                >
                    {workorderList?.map((wo) => {
                        // if only unread is true, only show it if it has an unread greater than 0 OR it is the messageWo
                        if (onlyUnread && !wo.unread_count) {
                            return null
                        }

                        if (isWorkorderSafe(wo)) {
                            if (messageWo && !isWorkorderSafe(messageWo)) {
                                return null
                            }

                            return (
                                <Container
                                    key={`VENDOR_SCHEDULER_SCREEN_${wo.id}`}
                                >
                                    <VendorWorkorder
                                        wo={wo}
                                        theme={theme}
                                        userList={userList}
                                        user={workspaceUser}
                                        dispatch={dispatch}
                                        dateFilter={jobsite.dateFilter}
                                        handleOpenDrawer={(
                                            wo: BaseWorkorder,
                                        ) => {
                                            dispatch(
                                                getOrCreateWorkorderChannel(
                                                    wo.id,
                                                ),
                                            ).then((res) => {
                                                dispatch(
                                                    getMessageList({
                                                        channelId: res.data.id,
                                                    }),
                                                )
                                            })
                                            setMessageWo(wo)
                                            setOpenUnitNotes(false)
                                        }}
                                        currentMessage={messageWo}
                                        clickUnitNotes={(wo) => {
                                            setSelectedWorkorder(wo)
                                            setOpenUnitNotes(true)
                                            setMessageWo(null)
                                            if (wo.unit_channel_id)
                                                setChannel(wo.unit_channel_id)
                                            if (wo.unread_unit_notes !== 0) {
                                                workorderList.forEach(
                                                    (workorder) => {
                                                        if (
                                                            wo.unit_id ===
                                                            workorder.unit_id
                                                        ) {
                                                            resetUnitNotesUnreadCount(
                                                                workorder,
                                                            )
                                                        }
                                                    },
                                                )
                                            }
                                        }}
                                    />
                                </Container>
                            )
                        }
                        return null
                    })}
                </Container>

                <MessageDrawer
                    title={`${messageWo?.unit_name ?? 'Workorder'} Messages`}
                    open={messageWo !== null}
                    onClose={() => setMessageWo(null)}
                    onCreateMessage={() => {
                        if (messageWo) {
                            incrementWorkorderMessageCount(messageWo)
                        }
                    }}
                />

                {selectedWorkorder && (
                    <MessageDrawer
                        open={openUnitNotes}
                        onClose={() => {
                            setOpenUnitNotes(false)
                        }}
                        onCreateMessage={() => {
                            if (selectedWorkorder) {
                                workorderList.forEach((workorder) => {
                                    if (
                                        selectedWorkorder.unit_id ===
                                        workorder.unit_id
                                    ) {
                                        incrementUnitNotesMessageCount(
                                            workorder,
                                        )
                                    }
                                })
                            }
                        }}
                        title={`Unit Notes ${selectedWorkorder.unit_name}`}
                    />
                )}
            </Container>
        </SideDrawerContainer>
    )
}
