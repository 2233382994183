import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import BackgroundImage from '../../../../assets/login_background.png'
import EzosLogo from '../../../../assets/ezos-logo-primary-dark.png'
import { useHistory } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { useUser } from '../../../../hooks'
import LoopIcon from '@material-ui/icons/Loop'
import { Container } from '../../../../components'
import { Routes } from '../../../../navigation/config/routes'

interface State {
    password: string
    showPassword: boolean
    email: string
}

export const Login = () => {
    const theme = useTheme()

    const { workspaceUser } = useUser()

    const [values, setValues] = React.useState<State>({
        password: '',
        showPassword: false,
        email: '',
    })
    const [isTouched, setIsTouched] = useState(false)
    const [loginError, setLoginError] = useState<null | string>(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (
        prop: keyof State,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.value !== '' && !isTouched && loginError) {
            setIsTouched(true)
        }
        setValues({ ...values, [prop]: event.target.value })
    }

    if (isTouched && loginError) {
        setLoginError(null)
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const history = useHistory()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'all',
    })
    const { actions } = useUser()
    const { login } = actions

    const onSubmit = (data: any, event: any) => {
        setIsTouched(false)
        event.preventDefault()
        setIsLoading(true)
        login(data)
            .then(() => {
                setLoginError(null)
                setIsLoading(false)
                if (
                    workspaceUser?.active_workspace.company_type === 'APARTMENT'
                ) {
                    history.push(Routes.apartment.dashboard)
                } else {
                    history.push(Routes.vendor.ezwork)
                }
            })
            .catch((e) => {
                let errorMsg = 'Unable to login'
                try {
                    errorMsg = e.response.data.non_field_errors[0]
                } catch {
                    console.log('')
                }

                setLoginError(errorMsg)
                setIsLoading(false)
            })
    }

    return (
        <Container
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    height: 600,
                    maxWidth: 600,
                    borderRadius: '40px',
                    flexDirection: 'column',
                }}
            >
                <Container
                    style={{
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin: theme.spacing(2),
                    }}
                >
                    <img
                        src={EzosLogo}
                        style={{
                            padding: theme.spacing(1),
                            height: 61,
                        }}
                    />
                    <span
                        style={{
                            ...theme.typography.h4,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[700],
                        }}
                    >
                        EZOS Login
                    </span>
                </Container>
                <Container
                    style={{
                        flexDirection: 'column',
                        margin: theme.spacing(2),
                    }}
                >
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <TextField
                                id="email"
                                placeholder="Email"
                                variant="outlined"
                                type="email"
                                {...register('email', {
                                    required: true,
                                    pattern: /\S+@\S+\.\S+/,
                                    onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleChange('email', event),
                                })}
                                error={
                                    errors?.email?.type === 'required' ||
                                    errors?.email?.type === 'pattern' ||
                                    loginError !== null
                                }
                            />
                            <Box>
                                {errors.email &&
                                    errors.email.type === 'required' && (
                                        <span className="error-message">
                                            This is required field
                                        </span>
                                    )}
                                {errors.email &&
                                    errors.email.type === 'pattern' && (
                                        <span className="error-message">
                                            Enter a valid email
                                        </span>
                                    )}
                            </Box>
                        </FormControl>

                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <OutlinedInput
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={values.password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {values.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                {...register('password', {
                                    required: true,
                                    onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleChange('password', event),
                                })}
                                error={
                                    errors?.password?.type === 'required' ||
                                    loginError !== null
                                }
                            />
                            <Box>
                                {errors.password &&
                                    errors.password.type === 'required' && (
                                        <span className="error-message">
                                            This is required field
                                        </span>
                                    )}
                            </Box>
                            {loginError ? (
                                <span className="error-message">
                                    {loginError}
                                </span>
                            ) : null}
                        </FormControl>

                        <Button
                            variant="contained"
                            style={{
                                background: theme.palette.primary.dark,
                                color: '#FFF',
                                textTransform: 'inherit',
                                fontWeight: 500,
                                borderRadius: theme.shape.borderRadius,
                                boxShadow: 'none',
                                width: '100%',
                                marginTop: theme.spacing(1),
                                height: '49px',
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border: `2px solid ${theme.palette.primary.dark}`,
                            }}
                            type="submit"
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            {isLoading ? (
                                <LoopIcon
                                    style={{
                                        animation: 'spin 01s linear infinite',
                                    }}
                                />
                            ) : (
                                'Sign in'
                            )}
                        </Button>
                    </form>

                    <Button
                        variant="contained"
                        style={{
                            background: theme.palette.primary.dark,
                            color: '#FFF',
                            textTransform: 'inherit',
                            fontWeight: 500,
                            borderRadius: theme.shape.borderRadius,
                            boxShadow: 'none',
                            width: '100%',
                            height: '49px',
                            marginTop: theme.spacing(2),
                            transition:
                                'background-color 0.3s, color 0.3s, border-color 0.3s',
                            border: `2px solid ${theme.palette.primary.dark}`,
                        }}
                        onClick={() => {
                            history.push(Routes.public.requestPasswordReset)
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = 'white'
                            e.currentTarget.style.color =
                                theme.palette.primary.dark
                            e.currentTarget.style.borderColor =
                                theme.palette.primary.dark
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme.palette.primary.dark
                            e.currentTarget.style.color = 'white'
                        }}
                    >
                        Forgot Password?
                    </Button>
                </Container>
            </Container>
            <Container>
                <Button
                    style={{
                        color: 'white',
                        textTransform: 'none',
                        textShadow: '0 0 5px rgba(0, 0, 0, 1.0)',
                        fontWeight: theme.typography.fontWeightBold,
                        position: 'relative',
                        backgroundColor: 'transparent',
                        ...theme.typography.h6,
                    }}
                    onClick={() => {
                        history.push(Routes.public.register)
                    }}
                    onMouseEnter={() => {
                        const line = document.querySelector<HTMLSpanElement>(
                            '#line',
                        )
                        if (line) {
                            line.style.width = '90%'
                        }
                    }}
                    onMouseLeave={() => {
                        const line = document.querySelector<HTMLSpanElement>(
                            '#line',
                        )
                        if (line) {
                            line.style.width = '0'
                        }
                    }}
                >
                    Don&apos;t have an account?
                    <span
                        id="line"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: 0,
                            height: '2px',
                            backgroundColor: 'white',
                            transition: 'width 0.3s ease',
                        }}
                    />
                </Button>
            </Container>
        </Container>
    )
}
