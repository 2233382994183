import { Dispatch, SetStateAction, useState } from 'react'
import { Frequency, IdentifiableObject } from '../models'
import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers'

export const useInspectionFrequency = () => {
    const [frequencyList, setFrequencyList] = useState<Frequency[]>([])

    const [
        selectedFrequency,
        setSelectedFrequency,
    ] = useState<Frequency | null>(null)

    const getFrequencyList = async () => {
        try {
            const response = await axiosInstance.get('frequency/', {
                params: {
                    frequency_type: 'IN',
                },
            })
            const frequencyList: Frequency[] = response.data
            setFrequencyList(frequencyList)
            return frequencyList
        } catch (e) {
            toast.error('Error getting inspection frequency list')
            return Promise.reject(e)
        }
    }

    const toggleFrequencyActive = async (id: number) => {
        try {
            const response = await axiosInstance.post(
                `frequency/${id}/toggle_active/`,
            )
            const frequency: Frequency = response.data
            _insertOrUpdateIdentifiable(frequency, setFrequencyList)
            return frequency
        } catch (e) {
            toast.error('Error toggling frequency active')
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        frequencyList,
        setFrequencyList,
        getFrequencyList,
        selectedFrequency,
        setSelectedFrequency,
        toggleFrequencyActive,
    }
}
