import { Routes } from '../config/routes'

import type { RouteConfig } from '../types'
import { OrganizationGuard } from '../guards'

import { OrganizationHome } from '../../containers'
import { OrganizationLayout } from '../layouts'
import { OrganizationJobBoardRoot } from '../../containers/JobBoard/OrganizationJobBoard/OrganizationJobBoardRoot'

export const organizationRoutes: RouteConfig[] = [
    {
        path: Routes.organization.root,
        component: OrganizationHome,
        layout: OrganizationLayout,
        guard: OrganizationGuard,
    },
    {
        path: Routes.organization.jobBoard,
        component: OrganizationJobBoardRoot,
        layout: OrganizationLayout,
        guard: OrganizationGuard,
    },
]
