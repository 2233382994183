import React, { useEffect, useState } from 'react'

import {
    useTheme,
    Modal,
    Slide,
    Paper,
    IconButton,
    Tooltip,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Stepper,
    StepLabel,
    Step,
    Tab,
    Tabs,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Container, LoadingButton, LocationForm } from '../../../../components'
import { TemplateScheduleGantt } from '../../../../components/TemplateScheduleGantt'
import { SetupStep } from './SetupStep'
import { Folder, ListVendor, Schedule, Service } from '../../../../models'

import { SmartScheduleController, useFinderSelection } from '../../../../hooks'
import { ConfirmStep } from './ConfirmStep'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    serviceList: Service[]
    vendorList: ListVendor[]
    infrastructure: Folder
    scheduleController: SmartScheduleController
    reloadScheduleWorkorders: () => void
    onClose: () => void
}

export const SmartScheduleModal = (props: Props) => {
    const {
        open,
        onClose,
        infrastructure,
        scheduleController,
        reloadScheduleWorkorders,
    } = props

    const schedule = scheduleController.schedule

    const [dialogOpen, setDialogOpen] = useState(false)

    const theme = useTheme()

    const [tab, setTab] = useState(schedule?.setup_complete ? 1 : 0)

    const loadPage = () => {
        if (schedule === null || schedule === undefined) return

        setTab(schedule.setup_complete ? 1 : 0)
    }

    useEffect(() => {
        if (open) {
            loadPage()
        }
    }, [open])

    if (schedule === undefined || schedule === null) return null

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        maxHeight: `calc(100vh - ${MODAL_PADDING}px)`,
                        minHeight: `calc(100vh - ${MODAL_PADDING}px)`,
                        maxWidth: `calc(100vw - ${MODAL_PADDING}px)`,
                        minWidth: `calc(100vw - ${MODAL_PADDING}px)`,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            height: '100%',
                        }}
                        direction="column"
                    >
                        {/* Header */}
                        <Container
                            style={{
                                height: 100,
                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                alignItems: 'center',

                                padding: theme.spacing(1),
                            }}
                        >
                            <Tabs value={tab}>
                                <Tab label="Setup" onClick={() => setTab(0)} />
                                <Tab
                                    label="Review"
                                    onClick={() => setTab(1)}
                                    disabled={!schedule.setup_complete}
                                />
                            </Tabs>

                            <div style={{ flex: 1 }} />

                            {/* {tab === 1 && (
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        textTransform: 'none',
                                        width: 180,
                                    }}
                                    loading={
                                        scheduleController.loadingState
                                            .suggestionsLoading
                                    }
                                    onClick={() => {
                                        setDialogOpen(true)
                                    }}
                                >
                                    Create a project plan
                                </LoadingButton>
                            )} */}

                            <div>
                                <Tooltip title="Close">
                                    <IconButton onClick={props.onClose}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Container>

                        {/* Body */}

                        {tab === 0 && (
                            <SetupStep
                                theme={theme}
                                scheduleController={scheduleController}
                                infrastructure={infrastructure}
                                vendorList={props.vendorList}
                                serviceList={props.serviceList}
                                onSubmit={(unitIds, formState) => {
                                    return scheduleController
                                        .setupSchedule(
                                            unitIds,
                                            formState.workStartDate,
                                            formState.workEndDate,
                                            formState.templateServices.map(
                                                (service) => ({
                                                    service_id:
                                                        service.serviceId,
                                                    template_service_vendors: service.templateServiceVendors.map(
                                                        (vendor) => ({
                                                            vendor_id:
                                                                vendor.vendorId,
                                                        }),
                                                    ),
                                                }),
                                            ),
                                        )
                                        .then(() => {
                                            toast.success('Setup complete.')
                                            setTab(1)
                                        })
                                        .catch((e) => {
                                            toast.error(
                                                'There was an error setting up the schedule.',
                                            )
                                        })
                                }}
                            />
                        )}

                        {tab === 1 && (
                            <ConfirmStep
                                theme={theme}
                                scheduleController={scheduleController}
                                serviceList={props.serviceList}
                                vendorList={props.vendorList}
                                reloadScheduleWorkorders={
                                    reloadScheduleWorkorders
                                }
                            />
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

export const MODAL_PADDING = 100
export const HEADER_HEIGHT = 100
export const FOOTER_HEIGHT = 100
