import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'

import { Button, useTheme } from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { useCompany, useService, useUser } from '../../hooks'
import {
    ActionItemInspectionStatusTrigger,
    CustomStatus,
    InventoryConfig,
} from '../../models'
import { axiosInstance } from '../../helpers'
import { ActionItemTriggerTable } from './ActionItemTriggerTable'
import { ActionItemTriggerDrawer } from './ActionItemTriggerDrawer'

interface Props {
    inventoryConfigList: InventoryConfig[] | undefined
    customStatusList: CustomStatus[]
}

export const ActionItemTriggerPage = (props: Props) => {
    const { inventoryConfigList, customStatusList } = props

    const [triggerList, setTriggerList] = useState<
        ActionItemInspectionStatusTrigger[] | null
    >(null)

    const theme = useTheme()

    const [drawerOpen, setDrawerOpen] = useState(false)

    const [selectedTriggerId, setSelectedTriggerId] = useState(-1)

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    const { userList, actions } = useUser()
    const { getUserList } = actions

    const { vendorList, getVendorList } = useCompany()
    const { serviceList, getServiceList } = useService()

    useEffect(() => {
        axiosInstance
            .get('apt_config/action-item-inspection-status-trigger/')
            .then((res) => {
                setTriggerList(res.data)
            })
        getUserList({ params: { my_team: true } })
        getVendorList({ params: { my_team: true } })
        getServiceList({})
    }, [])

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 140px)',
                }}
            >
                {/* Header */}
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Action Item Triggers
                    </span>

                    <div style={{ flex: 1 }} />

                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setDrawerOpen(true)
                        }}
                    >
                        + Add Trigger
                    </Button>
                </Container>
                {/* End Header */}

                <ActionItemTriggerTable
                    triggerList={triggerList}
                    selectedTriggerId={selectedTriggerId}
                    onSelectTrigger={(id) => {
                        setSelectedTriggerId(id)
                        setDrawerOpen(true)
                    }}
                />
            </Container>
            <ActionItemTriggerDrawer
                trigger={triggerList?.find((t) => t.id === selectedTriggerId)}
                open={drawerOpen}
                handleClose={() => {
                    setSelectedTriggerId(-1)
                    handleDrawerClose()
                }}
                userList={userList}
                vendorList={vendorList}
                serviceList={serviceList}
                inventoryConfigList={inventoryConfigList ?? []}
                customStatusList={customStatusList}
                handleDelete={(trigger) => {
                    axiosInstance
                        .delete(
                            `apt_config/action-item-inspection-status-trigger/${trigger.id}/`,
                        )
                        .then(() => {
                            setTriggerList((oldList) => {
                                if (oldList === null) {
                                    return oldList
                                }

                                return oldList.filter(
                                    (t) => t.id !== trigger.id,
                                )
                            })
                            toast.success(
                                `${trigger.title} Deleted`,
                                // { position: 'bottom-center' },
                            )
                        })
                        .catch(() => {
                            toast.error(
                                'There was a problem deleting the trigger',
                                // { position: 'bottom-center' },
                            )
                        })
                }}
                handleSave={(trigger) => {
                    let newTriggerList = [...(triggerList ?? [])]
                    if (selectedTriggerId !== -1) {
                        // Handle Update
                        newTriggerList = newTriggerList.map(
                            (existingTrigger) => {
                                if (existingTrigger.id === trigger.id) {
                                    return trigger
                                }
                                return existingTrigger
                            },
                        )
                    } else {
                        // Handle save new trigger
                        newTriggerList.push(trigger)
                    }
                    setTriggerList(newTriggerList)
                }}
            />
        </SideDrawerContainer>
    )
}
