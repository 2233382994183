import React from 'react'
import { Grid } from '@material-ui/core'

import { BaseProps } from '../../types'
import { TemplateServiceVendor } from '../../../../models'
import { VENDOR_BAR_H } from '../../constants'

interface Props extends BaseProps {
    templateVendor: TemplateServiceVendor
}

export const TemplateVendorRow = (props: Props) => {
    const { theme, templateVendor } = props

    return (
        <Grid
            xs={12}
            container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(1),
                height: VENDOR_BAR_H,
                boxSizing: 'border-box',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
            }}
        >
            <Grid
                item
                xs={12}
                style={{
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightLight,
                        color: theme.palette.text.primary,
                    }}
                >
                    {templateVendor.vendor.name}
                </span>
            </Grid>
        </Grid>
    )
}
