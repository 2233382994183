import { Dispatch, SetStateAction, useState } from 'react'

import { toast } from 'react-toastify'

import { axiosInstance } from '../helpers'
import { CalendarDayOff } from '../models/Calendar'
import { IdentifiableObject } from '../models'

export const useDayOff = () => {
    const [dayOffList, setDayOffList] = useState<CalendarDayOff[]>([])

    const getDayOffList = async () => {
        try {
            const res = await axiosInstance.get('ezos-calendar/day_off/')
            const daysOff: CalendarDayOff[] = res.data
            setDayOffList(daysOff)
            return daysOff
        } catch (e) {
            toast.error('Error getting on days off')
            return Promise.reject(e)
        }
    }

    const addDayOff = async (request: CreateDayOffRequest) => {
        try {
            const res = await axiosInstance.post(
                'ezos-calendar/day_off/',
                request,
            )
            const newDaysOff: CalendarDayOff[] = res.data
            newDaysOff.forEach((dayOff) =>
                _insertOrUpdateIdentifiable(dayOff, setDayOffList),
            )
            return newDaysOff
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const deleteDayOff = async (id: number) => {
        try {
            await axiosInstance.delete(`ezos-calendar/day_off/${id}/`)
            setDayOffList(dayOffList.filter((dayOff) => dayOff.id !== id))
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        dayOffList,
        getDayOffList,
        addDayOff,
        deleteDayOff,
    }
}

export interface CreateDayOffRequest {
    dates: string[]
    vendor_id?: number
}
