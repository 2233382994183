import { useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers'

export const useEntrataWorkOrderPickList = () => {
    const [
        pickList,
        setPicklist,
    ] = useState<EntrataWorkOrderPicklistResponse | null>(null)

    const loadPicklist = async () => {
        try {
            const res = await axiosInstance.get(
                'action-item/get_entrata_picklist/',
            )
            setPicklist(res.data.response)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        loadPicklist()
    }, [])

    const getPlProperty = () => {
        const propertyDict = pickList?.result?.Property ?? {}
        const propertyIds = Object.keys(propertyDict)
        return propertyDict[Number(propertyIds[0])]
    }

    const getPlLocations = () => {
        const property = getPlProperty()
        return property?.Locations
    }

    const getPlMaintenanceCategories = () => {
        const property = getPlProperty()
        return property?.Categories?.Category
    }

    const getPlMaintenanceProblems = () => {
        const property = getPlProperty()
        return property?.Problems?.Problem
    }

    const getPlMaintenancePriorities = () => {
        const property = getPlProperty()
        return property?.Priorities?.Priority
    }

    return {
        pickList,
        loadPicklist,
        getPlProperty,
        getPlLocations,
        getPlMaintenanceCategories,
        getPlMaintenanceProblems,
        getPlMaintenancePriorities,
    }
}

interface LocationAttributes {
    Id: string | number
    Name: string
    Type: string
    IsResidentPortalEnabled: string | number
}

interface UnitTypeAttributes {
    Id: string
    Name: string
}

interface Location {
    '@attributes': LocationAttributes
    unitTypes?: {
        unitType: UnitTypeAttributes[]
    }
}

interface PriorityAttributes {
    Id: string
    Name: string
    Code: string
}

interface Priority {
    '@attributes': PriorityAttributes
}

interface StatusTypeAttributes {
    Id: string
    Name: string
    Code: string
}

interface StatusType {
    '@attributes': StatusTypeAttributes
}

interface CategoryAttributes {
    Id: string
    Name: string
}

interface Category {
    '@attributes': CategoryAttributes
}

interface SubMaintenanceProblemAttributes {
    Id: string
}

interface SubMaintenanceProblem {
    '@attributes': SubMaintenanceProblemAttributes
}

interface ProblemAttributes {
    Id: string
    Name: string
    CategoryId?: string
    IsResidentPortalEnabled: string
}

interface Problem {
    locations: {
        location: LocationAttributes[]
    }
    '@attributes': ProblemAttributes
    subMaintenanceProblems?: {
        subMaintenanceProblem: SubMaintenanceProblem[]
    }
}

interface InspectionStatusAttributes {
    Id: string
    Name: string
}

interface InspectionStatus {
    '@attributes': InspectionStatusAttributes
}

interface WorkOrderTypeAttributes {
    Id: string
    Name: string
}

interface WorkOrderType {
    '@attributes': WorkOrderTypeAttributes
}

interface CompanyEmployeeAttributes {
    Id: string
    Name: string
}

interface CompanyEmployee {
    '@attributes': CompanyEmployeeAttributes
}

interface PropertyAttributes {
    Id: string
}

interface PropertyDict {
    [id: number]: Property
}

interface Property {
    Locations: {
        Location: Location[]
    }
    Priorities: {
        Priority: Priority[]
    }
    StatusTypes: {
        StatusType: StatusType[]
    }
    Categories: {
        Category: Category[]
    }
    Problems: {
        Problem: Problem[]
    }
    '@attributes': PropertyAttributes
}

interface InspectionStatuses {
    InspectionStatus: InspectionStatus[]
}

interface WorkOrderTypes {
    WorkOrderType: WorkOrderType[]
}

interface CompanyEmployees {
    CompanyEmployee: CompanyEmployee[]
}

interface Result {
    Property: PropertyDict
    InspectionStatuses: InspectionStatuses
    WorkOrderTypes: WorkOrderTypes
    CompanyEmployees: CompanyEmployees
}

interface EntrataWorkOrderPicklistResponse {
    requestId: string
    code: string
    result: Result
}
