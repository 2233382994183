import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    CircularProgress,
    List,
    ListItemText,
    makeStyles,
    Modal,
    Paper,
    Slide,
    Typography,
    useTheme,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
    ApartmentVendorRuleDict,
    CreateOrUpdateAptVendorRuleRequest,
    DeleteAptVendorRuleRequest,
} from '../../hooks/useApartmentVendorRule'
import { convertListToMap, IdBoolMap, ListVendor, Service } from '../../models'
import { AddVendorServiceRule } from '../VendorServiceRules/AddVendorServiceRule'
import { VendorServiceRuleRow } from '../VendorServiceRules/VendorServiceRuleRow'
import { VendorServiceRule } from '../VendorServiceRules/VendorServiceRules'
import { Container } from '../../components'

interface Props {
    apartmentVendorRuleDict: ApartmentVendorRuleDict
    serviceList: Service[]
    vendorList: ListVendor[]
    loading: boolean
    createOrUpdateAptVendorRule: (
        request: CreateOrUpdateAptVendorRuleRequest,
    ) => Promise<ApartmentVendorRuleDict | null>
    deleteAptVendorRule: (
        request: DeleteAptVendorRuleRequest,
    ) => Promise<ApartmentVendorRuleDict | null>
    setApartmentVendorRuleDict: Dispatch<
        SetStateAction<ApartmentVendorRuleDict>
    >
}

const useStyles = makeStyles((theme) => ({
    listContainer: {
        overflowY: 'auto',
        width: '100%',
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    emptyState: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    rulesList: {
        flex: 1,
        overflowY: 'auto',
    },
    addRuleContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
    },
    dragOver: {
        backgroundColor: theme.palette.action.hover,
    },
}))

export const VendorServiceRuleTab = (props: Props) => {
    const {
        apartmentVendorRuleDict,
        serviceList,
        vendorList,
        loading,
        createOrUpdateAptVendorRule,
        deleteAptVendorRule,
        setApartmentVendorRuleDict,
    } = props

    const classes = useStyles()
    const theme = useTheme()

    const [isAddingNewRule, setIsAddingNewRule] = useState(false)
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null)

    const vendorMap = convertListToMap(vendorList)
    const servicesWithRules = serviceList.filter(
        (service) => apartmentVendorRuleDict[service.id]?.length > 0,
    )

    const handleDragStart = (index: number) => setDraggedIndex(index)

    const handleDragOver = (
        e: React.DragEvent<HTMLDivElement>,
        index: number,
    ) => {
        e.preventDefault()
        e.currentTarget.classList.add(classes.dragOver)
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.classList.remove(classes.dragOver)
    }

    const handleDrop = useCallback(
        (
            e: React.DragEvent<HTMLDivElement>,
            dropIndex: number,
            serviceId: number,
        ) => {
            e.preventDefault()
            e.currentTarget.classList.remove(classes.dragOver)

            if (
                draggedIndex === null ||
                draggedIndex === dropIndex ||
                serviceId === null
            )
                return

            const newRules = [...(apartmentVendorRuleDict[serviceId] || [])]
            const newRuleStore = [...newRules]
            const [draggedItem] = newRules.splice(draggedIndex, 1)

            const ezNowIndex = newRules.findIndex(
                (rule) => rule.vendor === null,
            )
            let newDropIndex = dropIndex
            if (ezNowIndex !== -1 && dropIndex >= ezNowIndex) {
                newDropIndex = Math.max(0, ezNowIndex - 1)
            }

            newRules.splice(dropIndex, 0, draggedItem)

            const updatedRules = newRules.map((rule, index) => ({
                ...rule,
                rank: index + 1,
            }))

            const updatedDict = {
                ...apartmentVendorRuleDict,
                [serviceId]: updatedRules,
            }

            setApartmentVendorRuleDict(updatedDict)
            setDraggedIndex(null)

            const request: CreateOrUpdateAptVendorRuleRequest = {
                service_id: serviceId,
                vendor_id: draggedItem.vendor,
                rank: dropIndex + 1,
            }

            createOrUpdateAptVendorRule(request).then((res) => {
                if (res === null) {
                    const updatedDict = {
                        ...apartmentVendorRuleDict,
                        [serviceId]: newRuleStore,
                    }
                    setApartmentVendorRuleDict(updatedDict)
                }
            })
        },
        [draggedIndex, apartmentVendorRuleDict],
    )

    const renderServiceRules = (service: Service) => {
        const selectedServiceRules = apartmentVendorRuleDict[service.id] ?? []
        const selectedVendorMap: IdBoolMap = {}
        selectedServiceRules.forEach((rule) => {
            if (rule.vendor) {
                selectedVendorMap[rule.vendor] = true
            }
        })
        const validVendors = vendorList.filter((vendor) => {
            return (
                vendor.services.find((srv) => srv.id === service.id) &&
                !selectedVendorMap[vendor.id]
            )
        })

        return (
            <Accordion key={service.id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${service.id}-content`}
                    id={`panel${service.id}-header`}
                >
                    <ListItemText primary={service.name} />
                    <Chip
                        label={`${
                            apartmentVendorRuleDict[service.id].length
                        } rule${
                            apartmentVendorRuleDict[service.id].length !== 1
                                ? 's'
                                : ''
                        }`}
                        size="small"
                        className={classes.chip}
                        style={{
                            width: '75px',
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText,
                        }}
                    />
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div className={classes.rulesList}>
                        {apartmentVendorRuleDict[service.id]?.map(
                            (apartmentVendorRule, index) => (
                                <VendorServiceRuleRow
                                    key={`APARTMENT_VENDOR_RULE_${service.id}_${apartmentVendorRule.id}`}
                                    apartmentVendorRule={apartmentVendorRule}
                                    vendor={
                                        vendorMap[
                                            apartmentVendorRule.vendor ?? -1
                                        ]
                                    }
                                    onClickDelete={() => {
                                        const request: DeleteAptVendorRuleRequest = {
                                            service_id: service.id,
                                            rule_id: apartmentVendorRule.id,
                                        }
                                        deleteAptVendorRule(request)
                                    }}
                                    theme={theme}
                                    index={index}
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={(e) => handleDragOver(e, index)}
                                    onDragLeave={handleDragLeave}
                                    onDrop={(e) =>
                                        handleDrop(e, index, service.id)
                                    }
                                />
                            ),
                        )}
                    </div>
                    <div className={classes.addRuleContainer}>
                        <AddVendorServiceRule
                            vendorList={validVendors}
                            theme={theme}
                            selectedServiceId={service.id}
                            existingRules={
                                apartmentVendorRuleDict[service.id] || []
                            }
                            createOrUpdateAptVendorRule={
                                createOrUpdateAptVendorRule
                            }
                            singleRow={true}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Container style={{ padding: theme.spacing(1) }}>
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Vendor Service Rules
                </span>
                <Container style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setIsAddingNewRule(true)}
                >
                    + Add Vendor Service Rule
                </Button>
            </Container>
            {loading ? (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: 'calc(100vh - 300px)',
                        minHeight: 'calc(100vh - 300px)',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Container className={classes.listContainer}>
                    {servicesWithRules.length > 0 ? (
                        <List
                            component="nav"
                            aria-label="services with rules"
                            style={{ width: '100%' }}
                        >
                            {servicesWithRules.map(renderServiceRules)}
                        </List>
                    ) : (
                        <Container className={classes.emptyState}>
                            <Typography variant="body1" color="textSecondary">
                                No services with rules yet.
                            </Typography>
                        </Container>
                    )}
                </Container>
            )}
            <Modal
                open={isAddingNewRule}
                onClose={() => setIsAddingNewRule(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={isAddingNewRule}>
                    <Paper>
                        <VendorServiceRule
                            serviceList={serviceList}
                            vendorList={vendorList}
                            apartmentVendorRuleDict={apartmentVendorRuleDict}
                            createOrUpdateAptVendorRule={
                                createOrUpdateAptVendorRule
                            }
                            deleteAptVendorRule={deleteAptVendorRule}
                            setApartmentVendorRuleDict={
                                setApartmentVendorRuleDict
                            }
                            onlyCreate={true}
                        />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
