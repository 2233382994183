import React from 'react'
import { WorkSpaceUser } from '../../models'
import { Routes } from '../config/routes'
import { Redirect } from 'react-router-dom'

interface VendorGuardProps {
    children: React.ReactNode
    workspaceUser?: WorkSpaceUser
}

export const VendorGuard = ({ children, workspaceUser }: VendorGuardProps) => {
    if (!workspaceUser) {
        return <Redirect to={Routes.error.permissionDenied} />
    }

    if (workspaceUser.active_workspace.company_type !== 'VENDOR') {
        return <Redirect to={Routes.error.permissionDenied} />
    }

    return <div>{children}</div>
}
