import React, { useMemo, useState } from 'react'

import { Button, Modal, Paper, Slide, useTheme } from '@material-ui/core'

import { Container } from '../../components'
import { AddServiceCategoryForm } from './AddServiceCategoryForm'
import { useService } from '../../hooks'
import { useServiceCategories } from '../../hooks/useServiceCategories'
import { convertListToMap } from '../../models'
import { ServiceCategoryList } from './ServiceCategoryList'

export const ServiceCategoriesContainer = () => {
    const [
        openAddServiceCategoryForm,
        setOpenAddServiceCategoryForm,
    ] = useState(false)

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const {
        serviceCategories,
        createServiceCategory,
        addServiceToCategory,
        removeServiceFromCategory,
        deleteServiceCategory,
    } = useServiceCategories()

    const availableServices = useMemo(() => {
        const serviceIdsInCategories = serviceCategories.flatMap(
            (category) => category.services,
        )
        return serviceList.filter(
            (service) => !serviceIdsInCategories.includes(service.id),
        )
    }, [serviceList, serviceCategories])

    const theme = useTheme()

    const serviceMap = convertListToMap(serviceList)

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Container style={{ marginBottom: theme.spacing(2) }}>
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginBottom: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    Service Categories
                </span>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpenAddServiceCategoryForm(true)}
                >
                    + Add Service Category
                </Button>
            </Container>
            <ServiceCategoryList
                serviceCategories={serviceCategories}
                serviceMap={serviceMap}
                availableServices={availableServices}
                deleteServiceCategory={deleteServiceCategory}
                addServiceToCategory={addServiceToCategory}
                removeServiceFromCategory={removeServiceFromCategory}
            />
            <Modal
                open={openAddServiceCategoryForm}
                onClose={() => setOpenAddServiceCategoryForm(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={openAddServiceCategoryForm}>
                    <Paper>
                        <AddServiceCategoryForm
                            serviceList={availableServices}
                            customStyle={{
                                root: {
                                    maxHeight: 'calc(100vh - 200px)',
                                    minHeight: '450px',
                                    overflow: 'hidden',
                                    width: '700px',
                                },
                            }}
                            handleClose={() =>
                                setOpenAddServiceCategoryForm(false)
                            }
                            createServiceCategory={createServiceCategory}
                        />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
