import React, { Dispatch, SetStateAction, useState } from 'react'

import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@material-ui/core'

import { ListVendor, Service, ServiceRequest } from '../../../models'
import { Container } from '../../../components'
import { ServiceRequestCard } from './ServiceRequestCard'
import { ServiceRequestWorkorderForm } from '../PendingWork/ServiceRequestWorkorderForm'
import {
    CreateServiceRequestWorkorderRequest,
    ServiceRequestFilterState,
    ServiceRequestSort,
} from '../../../hooks/useServiceRequest'
import { WorkorderResponse } from '../../../store'
import { SearchField } from '../../../components/SearchField'

interface Props {
    serviceRequestList: ServiceRequest[]
    vendorList: ListVendor[]
    serviceList: Service[]
    serviceRequestFilterState: ServiceRequestFilterState
    createServiceRequestWorkorder: (
        request: CreateServiceRequestWorkorderRequest,
    ) => Promise<WorkorderResponse | null>
    updateWorkorderState: (workorder: WorkorderResponse) => void
    setServiceRequestFilterState: Dispatch<
        SetStateAction<ServiceRequestFilterState>
    >
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
    },
    scrollContainer: {
        flex: 1,
        overflowY: 'auto',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    filterContainer: {
        margin: theme.spacing(1, 1, 1, 2),
        display: 'flex',
        alignItems: 'center',
    },
    searchField: {
        maxWidth: 300,
        marginRight: theme.spacing(2),
    },
    sortSelect: {
        minWidth: 300,
    },
}))

export const PendingServiceRequestView = (props: Props) => {
    const {
        serviceRequestList,
        serviceList,
        vendorList,
        serviceRequestFilterState,
        createServiceRequestWorkorder,
        updateWorkorderState,
        setServiceRequestFilterState,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const [
        selectedServiceRequest,
        setSelectedServiceRequest,
    ] = useState<ServiceRequest | null>(serviceRequestList[0] ?? null)

    const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setServiceRequestFilterState({
            ...serviceRequestFilterState,
            sortValue: event.target.value as ServiceRequestSort,
        })
    }

    return (
        <Container className={classes.contentContainer}>
            <Container style={{ flexDirection: 'column', flex: 1 }}>
                <Container style={{ paddingLeft: theme.spacing(2) }}>
                    <Typography
                        variant="body2"
                        style={{ color: theme.palette.primary.dark }}
                    >
                        Select a tenant service request and create a workorder
                    </Typography>
                </Container>
                <Container className={classes.filterContainer}>
                    <SearchField
                        value={serviceRequestFilterState.searchText}
                        className={classes.searchField}
                        variant="outlined"
                        onChange={(e) => {
                            setServiceRequestFilterState({
                                ...serviceRequestFilterState,
                                searchText: e.target.value,
                            })
                        }}
                        size="small"
                        placeholder="Search"
                    />
                    <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.sortSelect}
                    >
                        <InputLabel id="sort-select-label">Sort By</InputLabel>
                        <Select
                            labelId="sort-select-label"
                            id="sort-select"
                            value={serviceRequestFilterState.sortValue}
                            onChange={handleSortChange}
                            label="Sort By"
                        >
                            <MenuItem value={ServiceRequestSort.submittedDesc}>
                                Newest First
                            </MenuItem>
                            <MenuItem value={ServiceRequestSort.submittedAsc}>
                                Oldest First
                            </MenuItem>
                            <MenuItem value={ServiceRequestSort.tenantAsc}>
                                Tenant A-Z
                            </MenuItem>
                            <MenuItem value={ServiceRequestSort.tenantDesc}>
                                Tenant Z-A
                            </MenuItem>
                            <MenuItem value={ServiceRequestSort.unitAsc}>
                                Unit A-Z
                            </MenuItem>
                            <MenuItem value={ServiceRequestSort.unitDesc}>
                                Unit Z-A
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Container>
                {/* Service Request List */}
                <Container
                    className={classes.scrollContainer}
                    style={{ paddingRight: theme.spacing(1) }}
                >
                    {serviceRequestList.map((serviceRequest) => (
                        <ServiceRequestCard
                            key={`PENDING_SERVICE_REQUEST_${serviceRequest.id}`}
                            serviceRequest={serviceRequest}
                            theme={theme}
                            isSelected={
                                serviceRequest.id === selectedServiceRequest?.id
                            }
                            onClick={() =>
                                setSelectedServiceRequest(serviceRequest)
                            }
                        />
                    ))}
                </Container>
            </Container>
            {/* Create Workorder */}
            <Container style={{ flex: 1 }}>
                <ServiceRequestWorkorderForm
                    theme={theme}
                    vendorList={vendorList}
                    serviceList={serviceList}
                    selectedServiceRequest={selectedServiceRequest}
                    createServiceRequestWorkorder={
                        createServiceRequestWorkorder
                    }
                    updateWorkorderState={updateWorkorderState}
                    setSelectedServiceRequest={setSelectedServiceRequest}
                />
            </Container>
        </Container>
    )
}
