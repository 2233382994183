import React, { useCallback, useState } from 'react'

import {
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    Theme,
    useTheme,
} from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { InspectionFrequencyTable } from '../Inspection/InspectionFrequencyTable'
import { AreaConfig, Company, Frequency, ModelMap, User } from '../../models'
import { InspectionAssignModal } from '../Inspection/InspectionAssignModal'
import { InspectionFrequencyDrawer } from '../Inspection/InspectionFrequencyDrawer'
import {
    _useFinderSelection,
    _usePrunedInfrastructure,
    useAppDispatch,
} from '../../hooks'
import { addInspection, UpdateApartmentRequest } from '../../store'

interface Props {
    areaConfigMap: ModelMap<AreaConfig>
    frequencyList: Frequency[]
    selectedFrequency: Frequency | null
    loading: boolean
    workspaceUser?: User
    setSelectedFrequency: (freq: Frequency | null) => void
    setFrequencyList: (freq: Frequency[]) => void
    toggleFrequencyActive: (id: number) => Promise<Frequency>
    onUpdateApartment: (req: UpdateApartmentRequest) => void
}

export const InspectionFrequencyTab = (props: Props) => {
    const {
        areaConfigMap,
        frequencyList,
        selectedFrequency,
        loading,
        workspaceUser,
        setSelectedFrequency,
        setFrequencyList,
        toggleFrequencyActive,
        onUpdateApartment,
    } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [createFrequencyModalOpen, setCreateFrequencyModalOpen] = useState(
        false,
    )
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [runInspectionFrequencies, setRunInspectionFrequencies] = useState(
        workspaceUser?.active_workspace?.run_inspection_frequencies ?? false,
    )

    const activeWorkspace = workspaceUser?.active_workspace

    const handleToggleFrequency = useCallback(() => {
        setRunInspectionFrequencies(!runInspectionFrequencies)
        if (activeWorkspace) {
            onUpdateApartment({
                params: { id: activeWorkspace.id },
                body: { run_inspection_frequencies: !runInspectionFrequencies },
            })
        }
    }, [runInspectionFrequencies, onUpdateApartment, activeWorkspace])

    const handleAddFrequency = useCallback(() => setDrawerOpen(true), [])

    const handleFrequencyCreate = useCallback(
        (freq: Frequency) => {
            if (freq.inspection) {
                dispatch(addInspection(freq.inspection))
            }
            setFrequencyList([...frequencyList, freq])
        },
        [dispatch, frequencyList, setFrequencyList],
    )

    const handleFrequencyDelete = useCallback(
        (freq: Frequency) => {
            const newList = frequencyList.filter(
                (frequency) => frequency.id !== freq.id,
            )
            setFrequencyList(newList)
        },
        [frequencyList, setFrequencyList],
    )

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <Header
                    theme={theme}
                    activeWorkspace={activeWorkspace}
                    runInspectionFrequencies={runInspectionFrequencies}
                    onToggleFrequency={handleToggleFrequency}
                    onAddFrequency={handleAddFrequency}
                />
                <Content
                    loading={loading}
                    frequencyList={frequencyList}
                    setCreateFrequencyModalOpen={setCreateFrequencyModalOpen}
                    setSelectedFrequency={setSelectedFrequency}
                    toggleFrequencyActive={toggleFrequencyActive}
                />
            </Container>
            <InspectionFrequencyDrawer
                open={drawerOpen}
                handleClose={() => setDrawerOpen(false)}
                setCreateModalOpen={() => setCreateFrequencyModalOpen(true)}
            />
            <InspectionAssignModal
                makeFilteredRequest={() => {}}
                open={createFrequencyModalOpen}
                areaConfigMap={areaConfigMap}
                onClose={() => {
                    setCreateFrequencyModalOpen(false)
                    setSelectedFrequency(null)
                    setDrawerOpen(false)
                }}
                onFrequencyCreate={handleFrequencyCreate}
                onFrequencyDelete={handleFrequencyDelete}
                viewOnlyMode={selectedFrequency ?? undefined}
            />
        </SideDrawerContainer>
    )
}

const Header = ({
    theme,
    activeWorkspace,
    runInspectionFrequencies,
    onToggleFrequency,
    onAddFrequency,
}: {
    theme: Theme
    activeWorkspace?: Company | null
    runInspectionFrequencies: boolean
    onToggleFrequency: () => void
    onAddFrequency: () => void
}) => (
    <Container style={{ padding: theme.spacing(1) }}>
        <span
            style={{
                ...theme.typography.h6,
                fontWeight: theme.typography.fontWeightBold,
            }}
        >
            Inspection Frequencies
        </span>
        {activeWorkspace && (
            <FormControlLabel
                control={
                    <Switch
                        checked={runInspectionFrequencies}
                        onChange={onToggleFrequency}
                        color="primary"
                    />
                }
                label="Automate Inspection Frequencies"
                style={{ marginLeft: theme.spacing(1) }}
            />
        )}
        <Container style={{ flex: 1 }} />
        <Button
            onClick={onAddFrequency}
            variant="contained"
            style={{
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
                textTransform: 'none',
                cursor: 'pointer',
            }}
        >
            + Add Frequency
        </Button>
    </Container>
)

const Content = ({
    loading,
    frequencyList,
    setCreateFrequencyModalOpen,
    setSelectedFrequency,
    toggleFrequencyActive,
}: {
    loading: boolean
    frequencyList: any[]
    setCreateFrequencyModalOpen: (open: boolean) => void
    setSelectedFrequency: (frequency: any | null) => void
    toggleFrequencyActive: (id: number) => Promise<Frequency>
}) => (
    <Container
        style={{
            flex: 1,
            maxHeight: 'calc(100vh - 220px)',
            minHeight: 'calc(100vh - 220px)',
        }}
    >
        {loading ? (
            <CircularProgress
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                }}
                size={100}
            />
        ) : (
            <InspectionFrequencyTable
                frequencyList={frequencyList}
                setFrequencyModalOpenViewOnly={(freq) => {
                    setCreateFrequencyModalOpen(true)
                    setSelectedFrequency(freq)
                }}
                toggleFrequencyActive={toggleFrequencyActive}
            />
        )}
    </Container>
)
