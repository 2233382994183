import React, { useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core'
import {
    DeleteForever,
    DragIndicator,
    Edit,
    ViewList,
} from '@material-ui/icons'
import { RemoveCircleOutline } from '@material-ui/icons'
import { ToggleButton } from '@material-ui/lab'

import { Container } from '../../../components'
import { BulkDeleteWorkOrderRequest, WorkorderResponse } from '../../../store'
import { convertMapToList, ModelMap } from '../../../models'

interface Props {
    isMultiselectMode: boolean
    selectedWorkorders: ModelMap<WorkorderResponse>
    organization?: number | null
    setIsMultiselectMode: (isMultiselectMode: boolean) => void
    setSelectedWorkorders: (
        selectedWorkorders: ModelMap<WorkorderResponse>,
    ) => void
    bulkDeleteWorkorders: (request: BulkDeleteWorkOrderRequest) => Promise<void>
    openBulkEditModal: () => void
}

export const JobBoardSelectionMode = (props: Props) => {
    const {
        isMultiselectMode,
        selectedWorkorders,
        organization,
        setIsMultiselectMode,
        setSelectedWorkorders,
        bulkDeleteWorkorders,
        openBulkEditModal,
    } = props

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const theme = useTheme()

    const handleDeselectAll = () => {
        setSelectedWorkorders({})
    }

    const selectedWorkordersCount = Object.keys(selectedWorkorders).length

    return (
        <Container
            style={{
                alignItems: 'center',
            }}
        >
            <Tooltip title={isMultiselectMode ? 'Multi-Select' : 'Drag & Drop'}>
                <ToggleButton
                    value="check"
                    selected={isMultiselectMode}
                    onChange={() => setIsMultiselectMode(!isMultiselectMode)}
                    style={{}}
                    size="small"
                >
                    {isMultiselectMode ? <ViewList /> : <DragIndicator />}
                </ToggleButton>
            </Tooltip>
            {isMultiselectMode && (
                <Container style={{ alignItems: 'center' }}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginRight: theme.spacing(2),
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        {Object.keys(selectedWorkorders).length} selected
                    </Typography>
                    <Tooltip title="Deselect All">
                        <IconButton
                            color="default"
                            onClick={handleDeselectAll}
                            size="small"
                        >
                            <RemoveCircleOutline />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton
                            color="primary"
                            onClick={openBulkEditModal}
                            disabled={selectedWorkordersCount === 0}
                            size="small"
                            style={{ marginLeft: theme.spacing(1) }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            color="secondary"
                            onClick={() => setOpenDeleteDialog(true)}
                            disabled={selectedWorkordersCount === 0}
                            style={{ marginLeft: theme.spacing(1) }}
                            size="small"
                        >
                            <DeleteForever />
                        </IconButton>
                    </Tooltip>
                </Container>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Workorders
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete{' '}
                        {selectedWorkordersCount} Workorders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const workorders = convertMapToList(
                                selectedWorkorders,
                            )
                            const request: BulkDeleteWorkOrderRequest = {
                                body: {
                                    workorders: workorders.map((wo) => wo.id),
                                },
                            }
                            if (organization) {
                                request.params = {
                                    organization: organization,
                                }
                            }
                            bulkDeleteWorkorders(request).then(() => {
                                toast.success('Workorders deleted successfully')
                                setOpenDeleteDialog(false)
                                handleDeselectAll()
                            })
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
