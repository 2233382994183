import React, { useEffect, useState } from 'react'

import {
    Button,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    useTheme,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { WorkorderResponse } from '../../store'
import { Container, NumberInput, WorkorderRow } from '../../components'
import { RFP, RFPType, Service } from '../../models'
import { CHOOSE_VENDOR_STEP, RecommendedVendor } from './CreateEZNowModal'
import {
    CreateOrUpdateRFPRequest,
    rfpApi,
    workorderContext,
} from '../../contexts'
import { toast } from 'react-toastify'
import { DisplayService } from '../../components/DisplayService'
import { useUser } from '../../hooks'
import { useMessage } from '../../hooks/useMessage'

const TODAY = 'TODAY'
const TOMORROW = 'TOMORROW'
const ANYTIME = 'ANYTIME'
const CHOOSE_DATE = 'CHOOSE_DATE'

interface Props {
    workorders: WorkorderResponse[]
    recommendedVendors: RecommendedVendor[]
    selectedRFP: RFP | null
    onCreate: () => void
    setActiveStep: (step: number) => void
}

export const EZNowCreationForm = (props: Props) => {
    const {
        workorders,
        recommendedVendors,
        onCreate,
        selectedRFP,
        setActiveStep,
    } = props

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [preferredTime, setPreferredTime] = useState(TODAY)
    const [projectStartDate, setProjectStartDate] = useState<Date | null>(
        new Date(),
    )
    const [price, setPrice] = useState<number | undefined>()
    const [addPrice, setAddPrice] = useState(false)
    const [doValidate, setDoValidate] = useState(false)

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const { createRFP } = rfpApi()

    const { transitionWorkorder, updateWorkorderState } = workorderContext()

    const { createMessage } = useMessage()

    const serviceList: Service[] = []
    workorders.forEach((workorder) => {
        if (
            workorder.service_id &&
            !serviceList.find((service) => service.id === workorder.service_id)
        ) {
            const service: Service = {
                id: workorder.service_id,
                name: workorder?.service_name ?? '',
                color: workorder.service_color ?? '',
                order: 1,
            }
            serviceList.push(service)
        }
    })

    const createEZNowRFPRequest = () => {
        const services: {
            id: number
        }[] = []
        workorders.forEach((workorder) => {
            if (workorder.service_id) {
                services.push({ id: workorder.service_id })
            }
        })

        const vendors: {
            id: number
        }[] = []
        recommendedVendors.forEach((vendor) => {
            vendors.push({ id: vendor.id })
        })

        const workorderIdList: {
            id: number
        }[] = []
        workorders.forEach((workorder) => {
            workorderIdList.push({ id: workorder.id })
        })

        const requestBody: CreateOrUpdateRFPRequest = {
            name: name,
            type: RFPType.EZNOW,
            services: services,
            description: description,
            vendors: vendors,
            workorders: workorders,
        }

        if (preferredTime !== ANYTIME) {
            requestBody.project_start_date = projectStartDate?.toISOString()
        }

        if (addPrice) {
            requestBody.budget = price
        }

        return requestBody
    }

    useEffect(() => {
        if (selectedRFP) {
            setName(selectedRFP.name)
            setDescription(selectedRFP.description)
            if (!selectedRFP.project_start_date) {
                setPreferredTime(ANYTIME)
            } else {
                const today = new Date()
                const tomorrow = new Date()
                tomorrow.setDate(today.getDate() + 1)
                const projectDate = new Date(selectedRFP.project_start_date)
                if (today.toDateString() === projectDate.toDateString()) {
                    setPreferredTime(TODAY)
                } else if (
                    tomorrow.toDateString() === projectDate.toDateString()
                ) {
                    setPreferredTime(TOMORROW)
                } else {
                    setPreferredTime(CHOOSE_DATE)
                }
                setProjectStartDate(projectDate)
            }
            if (selectedRFP?.budget) {
                setPrice(selectedRFP?.budget)
                setAddPrice(true)
            }
        }
    }, [selectedRFP])

    const disabled = selectedRFP ? true : false

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                overflow: 'auto',
                maxHeight: 'calc(100vh - 375px)',
                minHeight: 'calc(100vh - 375px)',
                flex: 1,
            }}
        >
            <Container
                flex={1}
                direction="column"
                style={{ marginRight: theme.spacing(2) }}
            >
                <Container flexWrap="wrap">
                    {serviceList.map((service) => {
                        return (
                            <DisplayService
                                service={service}
                                border
                                key={`SERVICE_${service.id}`}
                            />
                        )
                    })}
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        justifyContent: 'space-between',
                    }}
                    direction="column"
                >
                    <span style={{ fontWeight: 600, fontSize: '16px' }}>
                        RFP Name:
                    </span>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        style={{ width: '100%' }}
                        error={doValidate && name === ''}
                        helperText={doValidate && name === '' ? 'Required' : ''}
                        disabled={disabled}
                    />
                </Container>
                <Container
                    style={{
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                    direction="column"
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 600,
                        }}
                    >
                        Description of work (Optional):
                    </span>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        multiline
                        disabled={disabled}
                    />
                </Container>
                <Container direction="column">
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                                marginRight: theme.spacing(2),
                            }}
                        >
                            Set Price:
                        </span>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Yes"
                            checked={addPrice}
                            onClick={() => {
                                if (!disabled) {
                                    setAddPrice(true)
                                }
                            }}
                            disabled={disabled}
                        />
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="No"
                            checked={!addPrice}
                            onClick={() => {
                                setAddPrice(false)
                            }}
                            disabled={disabled}
                        />
                    </Container>
                    {addPrice && (
                        <NumberInput
                            prefix={'$'}
                            variant="outlined"
                            value={price}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    setPrice(undefined)
                                } else {
                                    setPrice(Number(e.target.value))
                                }
                            }}
                            size="small"
                            disabled={disabled}
                        />
                    )}
                </Container>
                <Container
                    direction="column"
                    style={{
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                >
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 600,
                        }}
                    >
                        Preferred Time:
                    </Container>
                    <RadioGroup value={preferredTime} row>
                        <FormControlLabel
                            value="today"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Today"
                            checked={preferredTime === TODAY}
                            onClick={() => {
                                if (!disabled) {
                                    setPreferredTime(TODAY)
                                    setProjectStartDate(new Date())
                                }
                            }}
                            disabled={disabled}
                        />
                        <FormControlLabel
                            value="tomorrow"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Tomorrow"
                            checked={preferredTime === TOMORROW}
                            onClick={() => {
                                if (!disabled) {
                                    setPreferredTime(TOMORROW)
                                    setProjectStartDate(
                                        new Date(
                                            new Date().getTime() +
                                                24 * 60 * 60 * 1000,
                                        ),
                                    )
                                }
                            }}
                            disabled={disabled}
                        />
                        <FormControlLabel
                            value="anytime"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Anytime"
                            checked={preferredTime === ANYTIME}
                            onClick={() => {
                                if (!disabled) {
                                    setPreferredTime(ANYTIME)
                                }
                            }}
                            disabled={disabled}
                        />
                        <FormControlLabel
                            value="chooseDate"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Choose Date"
                            checked={preferredTime === CHOOSE_DATE}
                            onClick={() => {
                                if (!disabled) {
                                    setPreferredTime(CHOOSE_DATE)
                                }
                            }}
                            disabled={disabled}
                        />
                    </RadioGroup>
                    {preferredTime === CHOOSE_DATE && (
                        <KeyboardDatePicker
                            value={projectStartDate}
                            onChange={(date) => setProjectStartDate(date)}
                            format="MM/dd/yyyy"
                            inputVariant="outlined"
                            style={{
                                marginBottom: theme.spacing(2),
                                width: '100%',
                            }}
                            size="small"
                            disabled={disabled}
                        />
                    )}
                </Container>
            </Container>

            <Divider orientation="vertical" />

            <Container flex={1} direction="column">
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowX: 'hidden',
                        marginLeft: theme.spacing(1),
                        maxHeight: 'calc(100vh - 450px)',
                        minHeight: 'calc(100vh - 450px)',
                        overflowY: 'scroll',
                    }}
                >
                    {workorders.map((wo, idx) => {
                        return (
                            <WorkorderRow
                                key={`EDIT_WORKORDER_${wo.id}`}
                                workorder={wo}
                                isSelectedWorkorder={false}
                                canSeeUnitNotes
                                openUnitNotes={() => {}}
                                transitionWorkorder={transitionWorkorder}
                                onClickWorkorder={() => {}}
                                createMessage={createMessage}
                                updateWorkorderState={updateWorkorderState}
                                user={workspaceUser}
                                openMessageDrawer={() => {}}
                                hideMessageButtons
                            />
                        )
                    })}
                </Container>

                <Divider />

                <Container style={{ margin: theme.spacing(2, 0, 0, 2) }}>
                    <span style={{ ...theme.typography.h5 }}>
                        {recommendedVendors.length} Vendors Found
                    </span>
                    <Container style={{ flex: 1 }} />
                    {disabled ? (
                        <Button
                            variant="outlined"
                            size="small"
                            style={{
                                textTransform: 'none',
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                            }}
                            onClick={() => {
                                setActiveStep(CHOOSE_VENDOR_STEP)
                            }}
                        >
                            Choose Vendor
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="small"
                            style={{
                                textTransform: 'none',
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                            }}
                            onClick={() => {
                                if (name === '') {
                                    setDoValidate(true)
                                    return
                                }
                                const body = createEZNowRFPRequest()
                                createRFP(body, onCreate).then(() => {
                                    toast.success(
                                        `${body.name} created successfully, vendor invitations sent!`,
                                    )
                                })
                            }}
                        >
                            Send
                        </Button>
                    )}
                </Container>
            </Container>
        </Container>
    )
}
