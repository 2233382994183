import React, { useState } from 'react'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    PopoverPosition,
    useTheme,
} from '@material-ui/core'

import { OnCallDay } from '../../models'
import { Container } from '../../components'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

interface Props {
    onCallDay: OnCallDay
    anchorPosition: PopoverPosition
    open: boolean
    onClose: () => void
    deleteOnCallDay: (id: number) => Promise<void>
}

export const OnCallDayDetailPopover = (props: Props) => {
    const { onCallDay, anchorPosition, open, onClose, deleteOnCallDay } = props

    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        deleteOnCallDay(onCallDay.id)
            .then(() => {
                toast.success('On Call Deleted successfully!')
                onClose()
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const canDelete = new Date() < new Date(onCallDay.on_call_date)

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ boxShadow: theme.shadows[5] }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 300,
                    maxWidth: 500,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(1, 2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    On Call Details
                </span>
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        {onCallDay.user.name}
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            marginBottom: theme.spacing(0.5),
                        }}
                    >
                        {onCallDay.user.phone}
                    </span>
                    <span style={{ ...theme.typography.body2 }}>
                        {onCallDay.user.email}
                    </span>
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(2),
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: theme.shadows[1],
                        margin: theme.spacing(2),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {format(new Date(onCallDay.start_time), 'Pp')} -{' '}
                        {format(new Date(onCallDay.end_time), 'Pp')}
                    </span>
                </Container>
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.error.main,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    {canDelete && (
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.error.main,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: 75,
                            }}
                            onClick={() => setOpenDialog(true)}
                        >
                            Delete
                        </Button>
                    )}
                </Container>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Delete On Call Assignment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this On Call Assignment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleDelete}
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Popover>
    )
}
