import React, { useEffect, useState } from 'react'

// Hooks
import { useHistory } from 'react-router'
import { useSchedule, useUser } from '../../hooks'
import { useStyles } from '../../styles'

// Helpers
import { isAccountManager, resolveRoute, Routes } from '../../helpers'

// Models
import { Schedule } from '../../models'
import { ScheduleRow } from './types'

// Components
import {
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { ScheduleRowComponent } from './ScheduleRow'
import { Container, HeadCell, SimpleTable } from '../../components'
import { Publish } from '@material-ui/icons'
import { UploadWorkorderCSVModal } from '../../components/UploadWorkorderCSVModal'

export const ScheduleListModal = () => {
    const { scheduleList } = useSchedule()
    const theme = useTheme()
    const classes = useStyles()
    const history = useHistory()
    const { workspaceUser } = useUser()

    const [showArchive, setShowArchive] = useState(false)
    const [titleSearchValue, setTitleSearchValue] = useState('')

    const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState(false)

    // Table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
        width: '1px',
    }

    const barLabelStyle: React.CSSProperties = {
        ...theme.typography.body2,
        height: 20,
        fontWeight: theme.typography.fontWeightLight,
    }

    const barStyle: React.CSSProperties = {
        marginTop: 5,
        marginBottom: 5,
    }

    const headCells: HeadCell<ScheduleRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'nameAndId',
            label: 'Schedule',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'budget',
            label: 'Budget',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'startDate',
            label: 'Start',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'endDate',
            label: 'End',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'assignments',
            label: 'Assignments',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'progress',
            label: 'Progress',
            style: headCellStyle,
        },
    ]

    const createScheduleRow = (schedule: Schedule): ScheduleRow => {
        const endDateSort = schedule.end_date ? schedule.end_date : -1

        const nameAndIdComponent = (
            <Container direction="column">
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {schedule.name}
                </span>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    {schedule.schedule_id}
                </span>
            </Container>
        )

        return {
            nameAndId: { value: nameAndIdComponent, sortValue: schedule.name },
            budget: {
                value: schedule.baseline_budget,
                sortValue: schedule.baseline_budget,
            },
            startDate: {
                value: schedule.start_date,
                sortValue: schedule.start_date,
            },
            endDate: { value: schedule.end_date, sortValue: endDateSort },
            assignments: {
                value: schedule.assigned_count,
                sortValue: schedule.assigned_count,
            },
            schedule: { value: schedule, sortValue: -1 },
        }
    }
    // End Table Setup

    const schedulesToRender = scheduleList.filter((sch) => {
        const archiveValid =
            (showArchive && !sch.active) || (!showArchive && sch.active)

        let titleValid = true

        if (titleSearchValue !== '') {
            titleValid = sch.name
                .toLowerCase()
                .startsWith(titleSearchValue.toLowerCase())
        }

        return archiveValid && titleValid
    })

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                flexDirection: 'column',
                height: 'calc(100vh - 104px)',
                width: 'calc(100vw - 184px)',
            }}
            flex={1}
            // style={{ flexDirection: 'column'}}
        >
            <Container
                alignItems="center"
                style={{ marginBottom: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(0, 2, 0, 0),
                    }}
                >
                    Schedules
                </span>

                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                        margin: theme.spacing(0, 2, 0, 0),
                    }}
                >{`${schedulesToRender.length} Schedules`}</span>

                <div style={{ backgroundColor: 'red', flex: 1 }} />

                {isAccountManager(workspaceUser) && (
                    <Tooltip title="Upload Workorder CSV sheet">
                        <IconButton
                            onClick={() =>
                                setUploadCSVModalOpen(!uploadCSVModalOpen)
                            }
                        >
                            <Publish />
                        </IconButton>
                    </Tooltip>
                )}

                <FormControlLabel
                    control={
                        <Switch
                            value={showArchive}
                            color="primary"
                            onChange={() => setShowArchive(!showArchive)}
                        />
                    }
                    labelPlacement="start"
                    label="Archived"
                    style={{ marginRight: theme.spacing(2) }}
                />

                <TextField
                    value={titleSearchValue}
                    onChange={(e) => setTitleSearchValue(e.target.value)}
                    label="Search"
                />
            </Container>
            <SimpleTable<ScheduleRow>
                rows={schedulesToRender.map((sch) => createScheduleRow(sch))}
                render={(row, index) =>
                    ScheduleRowComponent(
                        {
                            row: row,
                            classes: classes,
                            theme: theme,
                            barLabelStyle: barLabelStyle,
                            cellStyle: cellStyle,
                            barStyle: barStyle,
                            workspaceUser: workspaceUser,
                            onClickRow: () => {
                                let route =
                                    Routes.apartmentScheduleArchivedDetail
                                if (row.schedule.value.active) {
                                    route = Routes.apartmentScheduleDetail
                                }
                                history.push(
                                    resolveRoute(
                                        route,
                                        ':id',
                                        row.schedule.value.id,
                                    ),
                                )
                            },
                        },
                        index,
                    )
                }
                orderByDefault="startDate"
                headCells={headCells}
            />

            <UploadWorkorderCSVModal
                open={uploadCSVModalOpen}
                scheduleList={scheduleList.filter((sch) => sch.active)}
                onClose={() => setUploadCSVModalOpen(false)}
            />
        </Container>
    )
}
