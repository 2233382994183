import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { axiosInstance, LocalStorage } from '../helpers'
import { useAppDispatch, useChannelList, useCompany } from '../hooks'
import { User, WorkSpaceUser } from '../models'
import {
    login,
    setRootUser,
    getRootUser,
    setWorkSpaceUser,
    getInfrastructure,
    updateUser,
    createUser,
    LoginRequest,
    GetUserListRequest,
    CreateUserRequest,
    UpdateUserRequest,
    UpdateUserRequestFormData,
    BaseUserListThunk,
    UserDetailActionThunk,
    LoginActionThunk,
    RootState,
    getUserList,
} from '../store'

interface UseUserOptions {
    shouldAuthenticate?: boolean
    shouldSetup?: boolean
}

interface UseUserReturn {
    rootUser?: User
    workspaceUser?: WorkSpaceUser
    userList: User[]
    isLoading: boolean
    isError: boolean
    organization: number | null
    actions: {
        login: (req: LoginRequest) => LoginActionThunk
        logout: () => void
        loginWithToken: (token: string) => Promise<void>
        saveUserToState: (user: User) => void
        getUserList: (req: GetUserListRequest) => BaseUserListThunk
        createUser: (req: CreateUserRequest) => UserDetailActionThunk
        updateUser: (
            req: UpdateUserRequest | UpdateUserRequestFormData,
        ) => UserDetailActionThunk
    }
}

export const useUser = (options?: UseUserOptions): UseUserReturn => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    // Selectors
    const rootUser = useSelector((state: RootState) => state.user.rootUser)
    const workspaceUser = useSelector(
        (state: RootState) => state.user.workspaceUser,
    )
    const organization = useSelector(
        (state: RootState) => state.user.organization,
    )
    const userList = useSelector((state: RootState) => state.user.userList)

    // Setup options
    const shouldAuthenticate = options?.shouldAuthenticate
    const shouldSetup =
        options?.shouldSetup &&
        !new URLSearchParams(location.search).get('setup')

    // Hooks
    const { getChannelList, getChannelDetail } = useChannelList()
    const { getApartmentList } = useCompany()

    // Authentication handlers
    const handleAuthentication = async (token: string) => {
        try {
            setIsLoading(true)
            axiosInstance.defaults.headers.common[
                'Authorization'
            ] = `token ${token}`
            await dispatch(getRootUser({}))
            setIsError(false)
        } catch (error) {
            setIsError(true)
            handleLogout()
        } finally {
            setIsLoading(false)
        }
    }

    const handleLogout = () => {
        dispatch(setRootUser(undefined))
        localStorage.removeItem(LocalStorage.EZTURN4_TOKEN)
        location.reload()
    }

    const handleLoginWithToken = async (token: string) => {
        try {
            const response = await axiosInstance.get('user/get_root_user/', {
                headers: { Authorization: `token ${token}` },
            })
            const rootUser = response.data
            dispatch(setRootUser(rootUser))
            dispatch(setWorkSpaceUser(rootUser.workspaces[0]))
        } catch (error) {
            handleLogout()
        }
    }

    // Setup handlers
    const setupWorkspace = async (workspaceUser: WorkSpaceUser) => {
        const { company_type, id } = workspaceUser.active_workspace

        if (company_type === 'APARTMENT') {
            await dispatch(getInfrastructure({ apartmentId: id }))
        } else if (company_type === 'VENDOR') {
            await getApartmentList({ params: {} })
        }

        const channelResponse = await getChannelList({
            params: { in_ezchat: true },
        })

        if (channelResponse.data.length > 0) {
            await getChannelDetail({ channelId: channelResponse.data[0].id })
        }
    }

    // Effects
    useEffect(() => {
        if (shouldAuthenticate) {
            const token = localStorage.getItem(LocalStorage.EZTURN4_TOKEN)
            token ? handleAuthentication(token) : setIsLoading(false)
        }
    }, [shouldAuthenticate])

    useEffect(() => {
        if (shouldAuthenticate && rootUser?.workspaces.length) {
            const token = localStorage.getItem(LocalStorage.EZTURN4_TOKEN)
            const ws_user = token
                ? rootUser.workspaces.find((u) => u.token === token)
                : rootUser.workspaces[0]

            dispatch(setWorkSpaceUser(ws_user))
        }
    }, [rootUser, shouldAuthenticate])

    useEffect(() => {
        if (workspaceUser && shouldSetup) {
            setupWorkspace(workspaceUser)
        }
    }, [workspaceUser, shouldSetup])

    return {
        rootUser,
        workspaceUser,
        userList,
        isLoading,
        isError,
        organization,
        actions: {
            login: (req) => dispatch(login(req)),
            logout: handleLogout,
            loginWithToken: handleLoginWithToken,
            saveUserToState: (user) => dispatch(setRootUser(user)),
            getUserList: (req) => dispatch(getUserList(req)),
            createUser: (req) => dispatch(createUser(req)),
            updateUser: (req) => dispatch(updateUser(req)),
        },
    }
}
