import React, { useEffect, useState } from 'react'
import { LeaseAuditController } from '../../hooks/documentAudit'
import {
    Theme,
    Popover,
    CircularProgress,
    Card,
    CardContent,
    Typography,
    makeStyles,
} from '@material-ui/core'
import { Container } from '../../components'
import { PairingSuggestionResults } from '../../models/DocumentAudit/types'

interface Props {
    anchorEl: HTMLElement | null
    onClose: () => void
    theme: Theme
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary,
        },
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}))

export const SuggestedPairsPopover = (props: Props) => {
    const { anchorEl, onClose, theme, leaseAuditController, isActive } = props

    const pairingSuggestions = leaseAuditController.pairingSuggestions

    const handleSuggestionClick = (suggestions: PairingSuggestionResults[]) => {
        if (leaseAuditController.leaseAudit) {
            leaseAuditController.applyPairingSuggestions(suggestions)
        }
        onClose()
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ padding: theme.spacing(2), minWidth: 300 }}>
                {leaseAuditController.loading.fetchPairingSuggestions ? (
                    <Container>
                        <CircularProgress />
                        <Typography style={{ marginLeft: theme.spacing(2) }}>
                            Loading Suggestions...
                        </Typography>
                    </Container>
                ) : (
                    pairingSuggestions && (
                        <>
                            <SuggestionCard
                                title="Lease Start"
                                value={pairingSuggestions.lease_start.length}
                                onClick={() =>
                                    handleSuggestionClick(
                                        pairingSuggestions.lease_start,
                                    )
                                }
                                theme={theme}
                                leaseAuditController={leaseAuditController}
                            />
                            <SuggestionCard
                                title="Spelling"
                                value={pairingSuggestions.spelling.length}
                                onClick={() =>
                                    handleSuggestionClick(
                                        pairingSuggestions.spelling,
                                    )
                                }
                                theme={theme}
                                leaseAuditController={leaseAuditController}
                            />
                            <SuggestionCard
                                title="Additional Term"
                                value={
                                    pairingSuggestions.additional_term.length
                                }
                                onClick={() =>
                                    handleSuggestionClick(
                                        pairingSuggestions.additional_term,
                                    )
                                }
                                theme={theme}
                                leaseAuditController={leaseAuditController}
                            />
                        </>
                    )
                )}
            </div>
        </Popover>
    )
}

interface SuggestionCardProps {
    title: string
    value: number
    onClick: () => void
    theme: Theme
    leaseAuditController: LeaseAuditController
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({
    title,
    value,
    onClick,
    theme,
    leaseAuditController,
}) => {
    const classes = useStyles()

    return (
        <Card className={classes.card} onClick={onClick}>
            <CardContent className={classes.cardContent}>
                <Typography variant="body1">{title}</Typography>
                {leaseAuditController.loading.applyPairingSuggestions ? (
                    <CircularProgress />
                ) : (
                    <Typography
                        variant="h6"
                        style={{ fontWeight: theme.typography.fontWeightBold }}
                    >
                        {value}
                    </Typography>
                )}
            </CardContent>
        </Card>
    )
}
