import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Container, Selector } from '../../../components'
import { LeaseAuditController } from '../../../hooks/documentAudit'
import { MenuItem, Select, Tab, Tabs, Theme } from '@material-ui/core'
import { AnalysisPage } from '../AnalysisPage'
import { AuditPage } from '../AuditPage/AuditPage'
import { RentRollList } from '../RentRollList'
import { LeasePdfList } from '../LeasePdfList'
import { HistoryPage } from '../HistoryPage'
import { LeaseAuditStatus } from '../../../models/DocumentAudit'

interface Props {
    leaseAuditController: LeaseAuditController
    theme: Theme
}

export const AdminPage = ({ leaseAuditController, theme }: Props) => {
    const [tab, setTab] = useState<number>(0)

    const [status, setStatus] = useState<LeaseAuditStatus>('REQUESTED')

    const leaseAudit = leaseAuditController.leaseAudit

    useEffect(() => {
        if (leaseAudit) {
            setStatus(leaseAudit.status)
        }
    }, [leaseAudit])

    const tabs = [
        <Tab key={0} label="Audit" />,
        <Tab key={1} label="Analysis" />,
        <Tab key={2} label="Lease" />,
        <Tab key={3} label="Rent Roll" />,
        <Tab key={4} label="History" />,
    ]

    const carouselItems = [
        <AuditPage
            key={0}
            theme={theme}
            isActive={tab === 0}
            height={bodyH}
            leaseAuditController={leaseAuditController}
        />,
        <AnalysisPage
            key={1}
            theme={theme}
            height={bodyH}
            leaseAuditController={leaseAuditController}
            isActive={tab === 1}
        />,
        <LeasePdfList
            key={2}
            isActive={tab === 2}
            theme={theme}
            height={bodyH}
            leaseAuditController={leaseAuditController}
        />,
        <RentRollList
            key={3}
            theme={theme}
            height={bodyH}
            isActive={tab === 3}
            leaseAuditController={leaseAuditController}
        />,
        <HistoryPage
            key={4}
            isActive={tab === 4}
            theme={theme}
            height={bodyH}
            documentBatches={leaseAuditController.documentBatches}
            leaseAuditController={leaseAuditController}
        />,
    ]

    return (
        <Container
            style={{
                flex: 1,
                height: 'calc(100vh - 104px)',
                flexDirection: 'column',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: headerH,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    {tabs}
                </Tabs>

                <div style={{ flex: 1 }} />
                <Select
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value as LeaseAuditStatus)

                        if (leaseAuditController.leaseAudit) {
                            leaseAuditController.updateLeaseAudit(
                                leaseAuditController.leaseAudit.id,
                                {
                                    status: e.target.value as LeaseAuditStatus,
                                },
                            )
                        }
                    }}
                >
                    <MenuItem value={'REQUESTED'}>Requested</MenuItem>
                    <MenuItem value={'WAITING_FOR_APPROVAL'}>
                        Waiting for Approval
                    </MenuItem>
                    <MenuItem value={'RUNNING_PIPELINE'}>
                        Running Pipeline
                    </MenuItem>
                    <MenuItem value={'REJECTED'}>Rejected</MenuItem>
                    <MenuItem value={'APPROVED'}>Approved</MenuItem>
                    <MenuItem value={'COMPLETED'}>Completed</MenuItem>
                </Select>

                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                        marginLeft: theme.spacing(2),
                    }}
                >
                    {leaseAuditController.leaseAudit?.name}
                </span>
            </Container>

            {/* Body */}
            <Carousel
                swipeable={false}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={tab}
            >
                {carouselItems}
            </Carousel>

            {/* Footer */}
        </Container>
    )
}

const containerH = window.innerHeight - 104

const headerH = 60
const bodyH = containerH - headerH
