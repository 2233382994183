import React from 'react'

import { Button, useTheme } from '@material-ui/core'

import { Container } from '../../components'
import { InspectionType } from '../../models'
import { InspectionTypeTable } from './InspectionTypeTable'

interface Props {
    moveOutInspection: number | undefined
    inspectionTypeList: InspectionType[] | undefined
    selectedInspectionType: InspectionType | undefined
    setSelectedInspectionType: (
        inspectionType: InspectionType | undefined,
    ) => void
    setOpenInspectionConfiguration: (open: boolean) => void
}

export const InspectionTypePage = (props: Props) => {
    const {
        moveOutInspection,
        inspectionTypeList,
        selectedInspectionType,
        setSelectedInspectionType,
        setOpenInspectionConfiguration,
    } = props

    const theme = useTheme()

    return (
        <Container>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 140px)',
                }}
            >
                {/* Header */}
                <Container style={{ alignItems: 'center' }}>
                    <Container
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Inspection Types
                        </span>

                        <Container
                            style={{
                                marginTop: theme.spacing(1),
                            }}
                        >
                            Inspection types are templates of the items you want
                            to inspect for an inspection.
                        </Container>
                    </Container>

                    <div style={{ flex: 1 }} />

                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenInspectionConfiguration(true)}
                    >
                        + Configure Inspection Type
                    </Button>
                </Container>
                {/* End Header */}

                <InspectionTypeTable
                    inspectionTypeList={inspectionTypeList}
                    selectedType={selectedInspectionType}
                    setSelectedType={setSelectedInspectionType}
                    setModalOpen={setOpenInspectionConfiguration}
                    moveOutInspection={moveOutInspection}
                />
            </Container>
        </Container>
    )
}
