import React from 'react'

import { Popover, Theme } from '@material-ui/core'

import { FormState } from '../../types'
import { Container } from '../../../../components'
import { useStyles } from '../../../../styles'

interface Props {
    formState: FormState
    theme: Theme
    isMultiSelect: boolean
    onClose: () => void
    onClickProjectType: () => void
    onClickDeadlineType: () => void
    onClickActionItemType: () => void
    onClickOnCallType: () => void
    onClickDayOffType: () => void
}

export const AddEventPopover = (props: Props) => {
    const {
        formState,
        onClose,
        theme,
        isMultiSelect,
        onClickDeadlineType,
        onClickProjectType,
        onClickActionItemType,
        onClickOnCallType,
        onClickDayOffType,
    } = props

    const popoverState = formState.popovers

    const classes = useStyles()

    const buttonStyle: React.CSSProperties = {
        ...theme.typography.body1,
        padding: theme.spacing(2),
        cursor: 'pointer',
    }

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={{
                top: formState.meta.yPos,
                left: formState.meta.xPos,
            }}
            open={popoverState.addEventPopover}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        padding: theme.spacing(2),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    Choose an event type
                </span>
                {isMultiSelect ? (
                    <>
                        <span
                            className={classes.hoverGrey200}
                            style={buttonStyle}
                            onClick={onClickOnCallType}
                        >
                            On Call
                        </span>
                        <span
                            className={classes.hoverGrey200}
                            style={buttonStyle}
                            onClick={onClickDayOffType}
                        >
                            Day Off
                        </span>
                    </>
                ) : (
                    <>
                        <span
                            className={classes.hoverGrey200}
                            style={buttonStyle}
                            onClick={onClickProjectType}
                        >
                            Project
                        </span>
                        <span
                            className={classes.hoverGrey200}
                            style={buttonStyle}
                            onClick={onClickDeadlineType}
                        >
                            Deadline
                        </span>
                        <span
                            className={classes.hoverGrey200}
                            style={buttonStyle}
                            onClick={onClickActionItemType}
                        >
                            Action Item
                        </span>
                    </>
                )}
            </Container>
        </Popover>
    )
}
