import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
    CircularProgress,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Step,
    StepButton,
    Stepper,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../components'
import { EZNowCreationForm } from './EZNowCreationForm'
import { WorkorderResponse } from '../../store'
import {
    AreaType,
    IdBoolMap,
    Bid,
    ModelMap,
    RFP,
    RFPStatus,
    convertMapToList,
} from '../../models'
import { axiosInstance } from '../../helpers'
import { EZNowVendorList } from './EZNowVendorList'
import {
    CreateOrUpdateRFPRequest,
    rfpApi,
    workorderContext,
} from '../../contexts'
import { EZNowConfirmationPage } from './EZNowConfirmationPage'
import moment from 'moment'

export interface RecommendedVendor {
    id: number
    performance_rating: number | null
    price_rating: number | null
}

interface ServiceAndAreas {
    service_id: number
    area_types: AreaType[]
}

export const CREATION_STEP = 0
export const CHOOSE_VENDOR_STEP = 1
export const CONFIRM_STEP = 2

export type CreateEZNowRequestStep =
    | typeof CREATION_STEP
    | typeof CHOOSE_VENDOR_STEP
    | typeof CONFIRM_STEP

interface Props {
    selectedWorkordersMap: ModelMap<WorkorderResponse>
    open: boolean
    handleClose: () => void
    setSelectedWorkordersMap: (map: ModelMap<WorkorderResponse>) => void
}

export const CreateEZNowModal = (props: Props) => {
    const {
        selectedWorkordersMap,
        open,
        handleClose,
        setSelectedWorkordersMap,
    } = props

    const [selectedBid, setSelectedBid] = useState<Bid | null>(null)

    const [recommendedVendors, setRecommendedVendors] = useState<
        RecommendedVendor[]
    >([])
    const [loading, setLoading] = useState(false)

    const [activeStep, setActiveStep] = useState(CREATION_STEP)
    const steps = ['Create EZNow Request', 'Choose Vendor', `Submit`]
    const selectStep = (step: CreateEZNowRequestStep) => {
        // Use this method to go backwards to a previous step
        setActiveStep(step)
    }

    const [completed, setCompleted] = useState<IdBoolMap>({})

    const [requireSelection, setRequireSelection] = useState(false)

    const theme = useTheme()

    const { updateWorkorderState } = workorderContext()

    const { selectedRFP, getRFPDetail, setSelectedRFP, updateRFP } = rfpApi()

    const workorders = open ? convertMapToList(selectedWorkordersMap) : []

    const rightNow = new Date()

    useEffect(() => {
        setLoading(true)
        const serviceAndAreaList: ServiceAndAreas[] = []
        workorders.forEach((workorder) => {
            if (workorder.service_id) {
                const tempServiceAndAreas: ServiceAndAreas = {
                    service_id: workorder.service_id,
                    area_types: [],
                }
                workorder.service_area_list.forEach((area) => {
                    tempServiceAndAreas.area_types.push(area.area_type)
                })
                serviceAndAreaList.push(tempServiceAndAreas)
            }
        })
        if (serviceAndAreaList.length > 0) {
            const body = {
                workorders: serviceAndAreaList,
            }
            axiosInstance
                .post(`company/vendor_recommend/`, body)
                .then((res) => {
                    setRecommendedVendors(res.data)
                })
                .finally(() => setLoading(false))
        }
        setActiveStep(CREATION_STEP)

        if (!open) {
            setSelectedWorkordersMap({})
            setSelectedRFP(null)
            setSelectedBid(null)
            setCompleted({})
        }
        if (selectedRFP) {
            setActiveStep(CHOOSE_VENDOR_STEP)
            const tempCompleted = completed
            tempCompleted[CREATION_STEP] = true
            setCompleted(tempCompleted)
        }
        setRequireSelection(false)
    }, [open])

    useEffect(() => {
        if (selectedRFP) {
            const endDate = moment(new Date(selectedRFP.created_date))
                .add(30, 'm')
                .toDate()
            if (endDate > rightNow) {
                setRequireSelection(false)
                const interval = setInterval(() => {
                    if (selectedRFP) {
                        getRFPDetail(selectedRFP)
                    }
                }, 10000)
                return () => clearInterval(interval)
            } else {
                setRequireSelection(true)
            }
        }
    }, [selectedRFP])

    const handleAcceptEZNowBid = () => {
        const body: CreateOrUpdateRFPRequest = {
            accepted_bid: selectedBid?.id,
        }
        updateRFP(body).then((updatedRFP) => {
            toast.success(`Work assigned to ${selectedBid?.vendor.name}`)
            setSelectedRFP(null)
            setSelectedWorkordersMap({})
            handleClose()
            updatedRFP.workorder_group?.workorders.forEach((workorder) =>
                updateWorkorderState(workorder),
            )
        })
    }

    const closeEZNowRequest = () => {
        const workorderList = selectedRFP?.workorder_group?.workorders ?? []
        const body: CreateOrUpdateRFPRequest = {
            status: RFPStatus.FAILED,
        }
        updateRFP(body).then(() => {
            toast.success(`EZNow Request has been closed`)
            workorderList.forEach((wo) => {
                updateWorkorderState({
                    ...wo,
                    price_group_id: null,
                })
            })
            setSelectedRFP(null)
            setSelectedWorkordersMap({})
            handleClose()
        })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        height: 'calc(100vh - 200px)',
                        width: 'calc(100vw - 200px)',
                        maxHeight: 'calc(100vh - 200px)',
                        maxWidth: 'calc(100vw - 200px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h5,
                                flex: 1,
                            }}
                        >
                            EZNow
                        </span>
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Divider />
                    <Container
                        style={{
                            flex: 1,
                            overflow: 'auto',
                            maxHeight: 'calc(100vh - 325px)',
                            minHeight: 'calc(100vh - 325px)',
                        }}
                    >
                        {activeStep === CREATION_STEP && (
                            <Container
                                style={{
                                    padding: theme.spacing(3, 0, 4, 2),
                                    flex: 1,
                                }}
                                direction="column"
                            >
                                {loading ? (
                                    <Container
                                        alignItems="center"
                                        justifyContent="center"
                                        flex={1}
                                        style={{ height: '100%' }}
                                    >
                                        <CircularProgress size={100} />
                                    </Container>
                                ) : (
                                    <EZNowCreationForm
                                        workorders={workorders}
                                        recommendedVendors={recommendedVendors}
                                        onCreate={() => {
                                            setActiveStep(CHOOSE_VENDOR_STEP)
                                            const tempCompleted = completed
                                            tempCompleted[CREATION_STEP] = true
                                            setCompleted(tempCompleted)
                                        }}
                                        selectedRFP={selectedRFP}
                                        setActiveStep={setActiveStep}
                                    />
                                )}
                            </Container>
                        )}
                        {activeStep === CHOOSE_VENDOR_STEP && selectedRFP && (
                            <EZNowVendorList
                                recommendedVendors={recommendedVendors}
                                rfp={selectedRFP}
                                selectedBid={selectedBid}
                                requireSelection={requireSelection}
                                onSelectBid={(bid: Bid) => {
                                    setSelectedBid(bid)
                                    setActiveStep(CONFIRM_STEP)
                                    const tempCompleted = completed
                                    tempCompleted[CHOOSE_VENDOR_STEP] = true
                                    setCompleted(tempCompleted)
                                }}
                                closeEZNowRequest={closeEZNowRequest}
                            />
                        )}
                        {activeStep === CONFIRM_STEP &&
                            selectedRFP &&
                            selectedBid && (
                                <EZNowConfirmationPage
                                    selectedBid={selectedBid}
                                    workorders={workorders}
                                    handleAcceptEZNowBid={handleAcceptEZNowBid}
                                />
                            )}
                    </Container>
                    <Container
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            height: '50px',
                        }}
                    >
                        <Stepper
                            nonLinear
                            activeStep={activeStep}
                            style={{ width: '70%' }}
                        >
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton
                                        color="inherit"
                                        onClick={() => {
                                            selectStep(
                                                index as CreateEZNowRequestStep,
                                            )
                                        }}
                                        disabled={
                                            !selectedRFP ||
                                            !completed[
                                                index - 1 >= 0 ? index - 1 : 0
                                            ]
                                        }
                                    >
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
