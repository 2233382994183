import { useState } from 'react'
import { axiosInstance } from '../../helpers'
import { CriteriaGroup, EntityClassification, MatchType } from '../../models'
import { toast } from 'react-toastify'

export const useCriteriaGroup = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroup[]>([])

    const fetchCriteriaGroups = async () => {
        setIsLoading(true)

        const response = await axiosInstance
            .get('/document-audit/criteria-group/')
            .catch((error) => {
                console.log(error)
                return error
            })

        setIsLoading(false)

        if (response instanceof Error) {
            toast.error('Error fetching criteria groups')
            return null
        }

        const criteriaGroups: CriteriaGroup[] = response.data

        console.log('criteriaGroups', criteriaGroups)

        setCriteriaGroups(criteriaGroups)

        return criteriaGroups
    }

    const createCriteriaGroup = async (
        name: string,
        criteria: {
            entity_classification: EntityClassification
            match_type: MatchType
        }[],
    ) => {
        setIsLoading(true)

        const response = await axiosInstance
            .post('/document-audit/criteria-group/', {
                name,
                criteria,
            })
            .catch((error) => {
                console.log(error)
                return error
            })

        setIsLoading(false)

        if (response instanceof Error) {
            toast.error('Error creating criteria group')
            return null
        }

        const criteriaGroup: CriteriaGroup = response.data

        setCriteriaGroups([...criteriaGroups, criteriaGroup])

        return criteriaGroup
    }

    const updateCriteriaGroup = async (
        id: number,
        name: string,
        criteria: {
            entity_classification: EntityClassification
            match_type: MatchType
        }[],
    ) => {
        setIsLoading(true)

        const response = await axiosInstance
            .patch(`/document-audit/criteria-group/${id}/`, {
                name,
                criteria,
            })
            .catch((error) => {
                toast.error('Error updating criteria group')
                return error
            })

        setIsLoading(false)

        if (response instanceof Error) {
            toast.error('Error updating criteria group')
            return null
        }

        const updatedCriteriaGroup: CriteriaGroup = response.data

        setCriteriaGroups(
            criteriaGroups.map((criteriaGroup) =>
                criteriaGroup.id === id ? updatedCriteriaGroup : criteriaGroup,
            ),
        )

        return updatedCriteriaGroup
    }

    return {
        isLoading,
        fetchCriteriaGroups,
        criteriaGroups,
        createCriteriaGroup,
        updateCriteriaGroup,
    }
}

export type CriteriaGroupController = ReturnType<typeof useCriteriaGroup>
