import { TableCell, TableRow, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { CellData, HeadCell, RowData, SimpleTable } from '../../components'
import { toMMDDYYYY } from '../../helpers'
import {
    Inspection,
    InspectionType,
    InspectionWithReportDate,
} from '../../models'
import { useStyles } from '../../styles'

interface Props {
    inspectionList: InspectionWithReportDate[]
    onClick: (inspection: InspectionWithReportDate) => void
}

export const InspectionReportTable = (props: Props) => {
    const { inspectionList, onClick } = props
    const theme = useTheme()
    const classes = useStyles()

    interface InspectionRow extends RowData {
        name: CellData<string>
        inspection_type: CellData<InspectionType>
        start_date: CellData<string>
        end_date: CellData<string>
        progress: CellData<string>
        inspection: CellData<InspectionWithReportDate>
    }

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<InspectionRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },

        {
            disablePadding: false,
            align: 'left',
            id: 'inspection_type',
            label: 'Inspection Type',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'start_date',
            label: 'Start Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'end_date',
            label: 'End Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'progress',
            label: 'Progress',
            style: headCellStyle,
        },
    ]

    const createInspectionRow = (
        inspection: InspectionWithReportDate,
    ): InspectionRow => {
        const uiTotal = inspection.ui_total ?? 0
        const uiComplete = inspection.ui_complete ?? 0

        const safeDenominator = uiTotal > 0 ? uiTotal : 1

        const progressPercent = (uiComplete / safeDenominator) * 100
        const progressStr = `${uiComplete} / ${uiTotal} (${progressPercent.toFixed(
            1,
        )}%)`

        return {
            name: { value: inspection.name, sortValue: inspection.name },
            inspection_type: {
                value: inspection.inspection_type,
                sortValue: inspection.inspection_type.name,
            },
            start_date: {
                value: inspection.start_date,
                sortValue: inspection.start_date,
            },
            end_date: {
                value: inspection.end_date,
                sortValue: inspection.end_date,
            },
            progress: {
                value: progressStr,
                sortValue: progressStr,
            },

            inspection: {
                value: inspection,
                sortValue: -1,
            },
        }
    }

    return (
        <SimpleTable<InspectionRow>
            rows={inspectionList?.map((ins) => createInspectionRow(ins)) ?? []}
            render={(row) => {
                const startDateStr = row.start_date.value
                    ? toMMDDYYYY(new Date(row.start_date.value))
                    : ''
                const endDateStr = row.end_date.value
                    ? toMMDDYYYY(new Date(row.end_date.value))
                    : ''

                const rowStyle: React.CSSProperties = {
                    cursor: 'pointer',
                }
                return (
                    <TableRow
                        key={`INSPECTION_ROW_${row.inspection.value.id}`}
                        className={classes.hoverGrey200}
                        style={rowStyle}
                        onClick={() => {
                            onClick(row.inspection.value)
                        }}
                    >
                        <TableCell>{row.name.value}</TableCell>
                        <TableCell>{row.inspection_type.value.name}</TableCell>
                        <TableCell>{startDateStr}</TableCell>
                        <TableCell>{endDateStr}</TableCell>
                        <TableCell>{row.progress.value}</TableCell>
                    </TableRow>
                )
            }}
            orderByDefault="start_date"
            orderDirDefault="desc"
            headCells={headCells}
        />
    )
}
