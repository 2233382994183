import React from 'react'
import { AvatarCircle, Container } from '../../components'
import { Grid, useTheme } from '@material-ui/core'
import { AnalyticsResponseData, VendorWorkTime } from './AnalyticsHome'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { ModelMap } from '../../models'
import { useStyles } from '../../styles'

interface Props {
    data: AnalyticsResponseData
    showLastWeekArrow: boolean
    vendorWorkTimeMap: ModelMap<VendorWorkTime>
    setSelectedAnalyticData: (data: AnalyticsResponseData) => void
    setOpenAnalyticModal: (open: boolean) => void
}

export const AnalyticsRow = (props: Props) => {
    const {
        data,
        showLastWeekArrow,
        vendorWorkTimeMap,
        setSelectedAnalyticData,
        setOpenAnalyticModal,
    } = props

    const theme = useTheme()

    const classes = useStyles()

    const CellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    return (
        <Container
            className={classes.hoverGrey200}
            style={{
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                padding: theme.spacing(2, 0, 2, 2),
                cursor: 'pointer',
                alignItems: 'center',
                height: '100px',
                width: '100%',
            }}
            onClick={() => {
                setSelectedAnalyticData(data)
                setOpenAnalyticModal(true)
            }}
        >
            <Container style={{ ...CellStyle }}>
                {data.profile_picture ? (
                    <AvatarCircle image={data.profile_picture} />
                ) : (
                    <AvatarCircle
                        text={data.name}
                        initials={true}
                        styles={{
                            container: {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                    />
                )}
            </Container>
            <Container style={{ ...CellStyle }}>{data.name}</Container>
            <Container style={{ ...CellStyle }}>{data.type}</Container>
            <Container style={{ ...CellStyle }}>
                {displayTotalWork(data, showLastWeekArrow)}
            </Container>
            <Container style={{ ...CellStyle }}>
                {displayWorkTime(data, vendorWorkTimeMap)}
            </Container>
        </Container>
    )
}

export const displayTotalWork = (
    data: AnalyticsResponseData,
    showLastWeekArrow: boolean,
) => {
    let totalCount = 0
    if (data.workorder_count) {
        totalCount = totalCount + data.workorder_count
    }
    if (data.inspection_count) {
        totalCount = totalCount + data.inspection_count
    }

    let priorWeekCount = 0
    if (data.prior_week_workorder_count) {
        priorWeekCount = priorWeekCount + data.prior_week_workorder_count
    }
    if (data.prior_week_inspection_count) {
        priorWeekCount = priorWeekCount + data.prior_week_inspection_count
    }

    return (
        <Container alignItems="center">
            {totalCount}
            {totalCount > priorWeekCount && showLastWeekArrow && (
                <ArrowUpward
                    fontSize="small"
                    style={{
                        color: 'green',
                    }}
                />
            )}
            {totalCount < priorWeekCount && showLastWeekArrow && (
                <ArrowDownward
                    fontSize="small"
                    style={{
                        color: 'red',
                    }}
                />
            )}
        </Container>
    )
}

const displayWorkTime = (
    data: AnalyticsResponseData,
    vendorWorkMap: ModelMap<VendorWorkTime>,
) => {
    if (data.is_vendor) {
        let sec = vendorWorkMap[data.id]?.work_time
            ? vendorWorkMap[data.id]?.work_time
            : 0
        if (sec) {
            const hours = Math.floor(sec / 3600) // get the number of hours
            sec = sec % 3600 // mod the number of seconds in an hour to remove the hours
            const minutes = Math.floor(sec / 60) //get the number of minuts in the time
            sec = sec % 60 // mod the number of seconds in a minute to remove the minute

            const hourString =
                hours > 9 ? hours.toFixed(0) : `0${hours.toFixed(0)}`
            const minuteString =
                minutes > 9 ? minutes.toFixed(0) : `0${minutes.toFixed(0)}`
            const secString = sec > 9 ? sec.toFixed(0) : `0${sec.toFixed(0)}`

            return `${hourString}:${minuteString}:${secString}`
        }
    }
    return ''
}
