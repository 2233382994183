import React, { useEffect, useMemo, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Popover,
    PopoverPosition,
    Select,
    Switch,
    Tab,
    Tabs,
    useTheme,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Container, SideDrawer } from '../../../../components'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'

import {
    AreaStatusFilter,
    AssignmentFilter,
    ChangeOrderFilterMode,
    InfrastructureFilterOptions,
    KeysFilterMode,
    PartialFilterMode,
} from '../../../../store'
import { ScheduleDateFilter } from './ScheduleDateFilter'
import {
    Area,
    AreaConfig,
    AreaStatusConfig,
    AreaStatusTag,
    BaseWorkorder,
    Folder,
    IdBoolMap,
    ListVendor,
    ModelListMap,
    ModelMap,
    Service,
    ServiceCategory,
    UnitConfig,
    WorkorderStatus,
} from '../../../../models'
import { useMultiSelectStyles } from '../../styles'

import { axiosInstance, enumKeys } from '../../../../helpers'
import { usePopover } from '../../../../hooks/usePopover'
import { FinderSelection, useAppDispatch, useUser } from '../../../../hooks'
import { SchedulerFinder } from '../../SchedulerFinder'
import { AddServiceCategoryForm } from '../../../ServiceCategories/AddServiceCategoryForm'
import { CreateServiceCategoryRequest } from '../../../../hooks/useServiceCategories'

interface Props {
    open: boolean
    filter: InfrastructureFilterOptions
    vendorList: ListVendor[]
    serviceList: Service[]
    unitConfigList: UnitConfig[]
    areaStatusConfigList: AreaStatusConfig[]
    workorderList: BaseWorkorder[]
    onClose: () => void
    setInfrastructureFilter: (
        key: keyof InfrastructureFilterOptions,
        value: unknown,
    ) => void
    root: Folder
    locationFinderSelection: FinderSelection
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    areaConfigMap: ModelMap<AreaConfig>
    areaStatusTagMap: ModelMap<AreaStatusTag>
    vacantAreas: Area[]
    setVacantAreas: (areas: Area[]) => void
    serviceCategoryList: ServiceCategory[]
    createServiceCategory: (
        request: CreateServiceCategoryRequest,
    ) => Promise<ServiceCategory>
}

export const ScheduleFilterDrawer = (props: Props) => {
    const {
        open,
        onClose,
        filter,
        setInfrastructureFilter,
        vendorList,
        serviceList,
        unitConfigList,
        areaStatusConfigList,
        root,
        locationFinderSelection,
        unitWorkorderMap,
        areaConfigMap,
        areaStatusTagMap,
        vacantAreas,
        setVacantAreas,
        serviceCategoryList,
        createServiceCategory,
    } = props

    const classes = useMultiSelectStyles()

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const bedStatusKeyPopover = usePopover()

    const [woStatusExpanded, setWoStatusExpanded] = useState(false)
    const [bedStatusExpanded, setBedStatusExpanded] = useState(false)

    const [serviceCategoryFormState, setServiceCategoryFormState] = useState<{
        openSelect: boolean
        openCreate: boolean
        anchorPosition: PopoverPosition | undefined
    }>({
        openSelect: false,
        openCreate: false,
        anchorPosition: undefined,
    })

    const [tab, setTab] = useState(FILTER_TAB)

    const sectionTitleStyle: React.CSSProperties = {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
    }

    const lightText: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightLight,
    }

    // Status
    let numSelected = 0
    const allStatuses = enumKeys(WorkorderStatus)
    allStatuses.forEach((key) => {
        numSelected += filter.statusFilter[key] ? 1 : 0
    })

    let statusCountLabel = `Filter (${numSelected})`
    if (numSelected === 0 || numSelected === allStatuses.length) {
        statusCountLabel = 'Showing All'
    }

    const changeAreaStatusFilter = (areaStatusConfig: AreaStatusConfig) => {
        const prevValue =
            filter.areaStatusFilter[areaStatusConfig.id]?.inFilter === true
        const filterLengthDiff = prevValue ? -1 : 1

        setInfrastructureFilter('areaStatusFilter', {
            ...filter.areaStatusFilter,
            [areaStatusConfig.id]: {
                inFilter: !prevValue,
                areaStatusConfig: areaStatusConfig,
            },
            filterLength:
                filter.areaStatusFilter.filterLength + filterLengthDiff,
        })
    }

    let areaStatusLabel = `Filter (${filter.areaStatusFilter.filterLength})`
    if (
        filter.areaStatusFilter.filterLength === 0 ||
        filter.areaStatusFilter.filterLength === areaStatusConfigList.length
    ) {
        areaStatusLabel = 'Showing All'
    }

    const unreadCount = props.workorderList.reduce((count, wo) => {
        return (count += wo.unread_count ?? 0)
    }, 0)

    const DRAWER_WIDTH = 330

    useEffect(() => {
        setInfrastructureFilter('displayVacantAreas', false)
    }, [workspaceUser?.active_workspace.id])

    const availableServices = useMemo(() => {
        const serviceIdsInCategories = serviceCategoryList.flatMap(
            (category) => category.services,
        )
        return serviceList.filter(
            (service) => !serviceIdsInCategories.includes(service.id),
        )
    }, [serviceList, serviceCategoryList])

    return (
        <SideDrawer
            open={open}
            handleClose={onClose}
            width={DRAWER_WIDTH}
            title="Filters"
        >
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflowX: 'hidden',
                }}
                scrollY
            >
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Filters" value={FILTER_TAB} />
                    <Tab label="Assets" value={ASSET_TAB} />
                </Tabs>

                {tab === FILTER_TAB && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Container>
                            <Container
                                style={{
                                    padding: theme.spacing(1),
                                }}
                            >
                                <ToggleButtonGroup
                                    value={filter.assignmentFilter}
                                    onChange={(_, value) =>
                                        setInfrastructureFilter(
                                            'assignmentFilter',
                                            value,
                                        )
                                    }
                                    aria-label="Unit Filter"
                                    exclusive
                                >
                                    <ToggleButton
                                        value={AssignmentFilter.All}
                                        aria-label="All"
                                    >
                                        All
                                    </ToggleButton>
                                    <ToggleButton
                                        value={AssignmentFilter.Assigned}
                                        aria-label="Assigned"
                                    >
                                        Assigned
                                    </ToggleButton>
                                    <ToggleButton
                                        value={AssignmentFilter.NotAssigned}
                                        aria-label="unassigned"
                                    >
                                        Unassigned
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Container>
                        </Container>
                        <ScheduleDateFilter
                            filter={filter}
                            setInfrastructureFilter={setInfrastructureFilter}
                        />

                        <Container
                            style={{
                                alignItems: 'center',
                                flex: 1,
                                padding: theme.spacing(1, 1, 1, 2),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Vacant Areas Filter
                            </span>
                            <div style={{ flex: 1 }} />
                            <FormControlLabel
                                labelPlacement="start"
                                label="Enable"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={filter.displayVacantAreas}
                                        color="primary"
                                        onClick={() => {
                                            if (vacantAreas.length === 0) {
                                                axiosInstance
                                                    .get(
                                                        'infrastructure/area/',
                                                        {
                                                            params: {
                                                                occupiable: true,
                                                                vacant_start: new Date().toISOString(),
                                                                vacant_end: new Date().toISOString(),
                                                            },
                                                        },
                                                    )
                                                    .then((res: any) => {
                                                        if (res && res.data) {
                                                            setVacantAreas(
                                                                res.data,
                                                            )
                                                        }
                                                    })
                                                    .finally(() => {
                                                        setInfrastructureFilter(
                                                            'displayVacantAreas',
                                                            !filter.displayVacantAreas,
                                                        )
                                                    })
                                            } else {
                                                setInfrastructureFilter(
                                                    'displayVacantAreas',
                                                    !filter.displayVacantAreas,
                                                )
                                            }
                                        }}
                                    />
                                }
                            />
                        </Container>

                        <Accordion>
                            <AccordionSummary>
                                <Container
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={() =>
                                            setBedStatusExpanded(
                                                !bedStatusExpanded,
                                            )
                                        }
                                        size="small"
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    >
                                        {bedStatusExpanded ? (
                                            <KeyboardArrowDown />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </IconButton>

                                    {/* Title + toggle */}
                                    <Container
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span style={sectionTitleStyle}>
                                            Bed Status Filter
                                        </span>

                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                bedStatusKeyPopover.handleOpen(
                                                    e,
                                                )
                                            }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Container>

                                    <div style={{ flex: 1 }} />

                                    <Container
                                        direction="column"
                                        alignItems="flex-end"
                                    >
                                        <span style={lightText}>
                                            {areaStatusLabel}
                                        </span>
                                    </Container>
                                </Container>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup style={{ flex: 1 }}>
                                    {areaStatusConfigList
                                        .slice(
                                            0,
                                            areaStatusConfigList.length / 2 + 1,
                                        )
                                        .map((areaStatusConfig) => {
                                            return (
                                                <FormControlLabel
                                                    key={`BED_STATUS_CHECKBOX_${areaStatusConfig.id}`}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                filter
                                                                    .areaStatusFilter[
                                                                    areaStatusConfig
                                                                        .id
                                                                ]?.inFilter ===
                                                                true
                                                            }
                                                            onChange={() => {
                                                                changeAreaStatusFilter(
                                                                    areaStatusConfig,
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        areaStatusConfig.name
                                                    }
                                                />
                                            )
                                        })}
                                </FormGroup>

                                <FormGroup
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    {areaStatusConfigList
                                        .slice(
                                            1 + areaStatusConfigList.length / 2,
                                        )
                                        .map((areaStatusConfig) => {
                                            return (
                                                <FormControlLabel
                                                    key={`BED_STATUS_CHECKBOX_${areaStatusConfig.id}`}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                filter
                                                                    .areaStatusFilter[
                                                                    areaStatusConfig
                                                                        .id
                                                                ]?.inFilter ===
                                                                true
                                                            }
                                                            onChange={() =>
                                                                changeAreaStatusFilter(
                                                                    areaStatusConfig,
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        areaStatusConfig.name
                                                    }
                                                />
                                            )
                                        })}
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => {
                                            const newAreaStausFilter: AreaStatusFilter = {
                                                filterLength: 0,
                                            }
                                            areaStatusConfigList.forEach(
                                                (asc) => {
                                                    newAreaStausFilter[
                                                        asc.id
                                                    ] = {
                                                        inFilter: false,
                                                        areaStatusConfig: asc,
                                                    }
                                                },
                                            )
                                            setInfrastructureFilter(
                                                'areaStatusFilter',
                                                newAreaStausFilter,
                                            )
                                        }}
                                    >
                                        Clear All
                                    </Button>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={woStatusExpanded}>
                            <AccordionSummary
                                onClick={() =>
                                    setWoStatusExpanded(!woStatusExpanded)
                                }
                            >
                                <Container
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={() =>
                                            setWoStatusExpanded(
                                                !woStatusExpanded,
                                            )
                                        }
                                        size="small"
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    >
                                        {woStatusExpanded ? (
                                            <KeyboardArrowDown />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </IconButton>

                                    {/* Title + toggle */}
                                    <Container direction="column">
                                        <span style={sectionTitleStyle}>
                                            Workorder Status Filter
                                        </span>
                                    </Container>

                                    <div style={{ flex: 1 }} />

                                    <Container
                                        direction="column"
                                        alignItems="flex-end"
                                    >
                                        <span style={lightText}>
                                            {statusCountLabel}
                                        </span>
                                    </Container>
                                </Container>
                            </AccordionSummary>

                            <AccordionDetails
                                style={{ padding: theme.spacing(0, 2) }}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.ASSIGNED
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            ASSIGNED: !filter
                                                                .statusFilter
                                                                .ASSIGNED,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Assigned"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter
                                                        .IN_PROGRESS
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            IN_PROGRESS: !filter
                                                                .statusFilter
                                                                .IN_PROGRESS,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="In Progress"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.PAUSE
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            PAUSE: !filter
                                                                .statusFilter
                                                                .PAUSE,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Paused"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.COMPLETE
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            COMPLETE: !filter
                                                                .statusFilter
                                                                .COMPLETE,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Complete"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.GO_BACK
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            GO_BACK: !filter
                                                                .statusFilter
                                                                .GO_BACK,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Go Back"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter
                                                        .PRE_APPROVED
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            PRE_APPROVED: !filter
                                                                .statusFilter
                                                                .PRE_APPROVED,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Preapproved"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.APPROVED
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            APPROVED: !filter
                                                                .statusFilter
                                                                .APPROVED,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Approved"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    filter.statusFilter.INVOICED
                                                }
                                                onChange={() =>
                                                    setInfrastructureFilter(
                                                        'statusFilter',
                                                        {
                                                            ...filter.statusFilter,
                                                            INVOICED: !filter
                                                                .statusFilter
                                                                .INVOICED,
                                                        },
                                                    )
                                                }
                                            />
                                        }
                                        label="Invoiced"
                                    />
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        style={{
                                            marginBottom: theme.spacing(1),
                                        }}
                                        onClick={() => {
                                            const newStatuses: any = {}
                                            allStatuses.forEach((key) => {
                                                newStatuses[key] = false
                                            })
                                            setInfrastructureFilter(
                                                'statusFilter',
                                                newStatuses,
                                            )
                                        }}
                                    >
                                        Clear All
                                    </Button>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>

                        <Card
                            style={{
                                padding: theme.spacing(2),
                                borderRadius: 0,
                                flexShrink: 0,
                            }}
                        >
                            <FormGroup className={classes.formControl}>
                                <span style={sectionTitleStyle}>
                                    Unit Ready Filter
                                </span>
                                <Container
                                    style={{ marginTop: theme.spacing(1) }}
                                >
                                    <Checkbox
                                        checked={filter.readyUnitFilter.enabled}
                                        onChange={() =>
                                            setInfrastructureFilter(
                                                'readyUnitFilter',
                                                {
                                                    ...filter.readyUnitFilter,
                                                    enabled: !filter
                                                        .readyUnitFilter
                                                        .enabled,
                                                },
                                            )
                                        }
                                    />
                                    <FormControl
                                        variant={'outlined'}
                                        style={{ flex: 1 }}
                                    >
                                        <Select
                                            value={filter.readyUnitFilter.value}
                                            disabled={
                                                !filter.readyUnitFilter.enabled
                                            }
                                            onChange={(
                                                event: React.ChangeEvent<{
                                                    value: unknown
                                                }>,
                                            ) => {
                                                setInfrastructureFilter(
                                                    'readyUnitFilter',
                                                    {
                                                        ...filter.readyUnitFilter,
                                                        value:
                                                            event.target.value,
                                                    },
                                                )
                                            }}
                                        >
                                            <MenuItem
                                                value={WorkorderStatus.COMPLETE}
                                            >
                                                <span>Complete</span>
                                            </MenuItem>
                                            <MenuItem
                                                value={WorkorderStatus.APPROVED}
                                            >
                                                <span>Approved</span>
                                            </MenuItem>
                                            <MenuItem
                                                value={WorkorderStatus.INVOICED}
                                            >
                                                <span>Invoiced</span>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Container>
                            </FormGroup>

                            <FormGroup className={classes.formControl}>
                                <span style={sectionTitleStyle}>
                                    Partial Service Filter
                                </span>
                                <FormControl
                                    variant={'outlined'}
                                    style={{ flex: 1 }}
                                >
                                    <Select
                                        value={filter.partialUnitFilter}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            setInfrastructureFilter(
                                                'partialUnitFilter',
                                                event.target.value,
                                            )
                                        }}
                                    >
                                        <MenuItem value={PartialFilterMode.All}>
                                            <span>All</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={PartialFilterMode.Partial}
                                        >
                                            <span>Partial Service</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={PartialFilterMode.Vacant}
                                        >
                                            <span>Full Service</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={PartialFilterMode.Renewed}
                                        >
                                            <span>No Service</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>

                            <FormGroup className={classes.formControl}>
                                <span style={sectionTitleStyle}>
                                    Service Category Filter
                                </span>
                                <FormControl
                                    variant={'outlined'}
                                    style={{ flex: 1 }}
                                >
                                    <Select
                                        value={filter.serviceCategoryFilter}
                                        onChange={(event) => {
                                            const selectedValue =
                                                event.target.value
                                            if (
                                                selectedValue !==
                                                'addServiceCategory'
                                            ) {
                                                setInfrastructureFilter(
                                                    'serviceCategoryFilter',
                                                    selectedValue,
                                                )
                                                setServiceCategoryFormState({
                                                    ...serviceCategoryFormState,
                                                    openSelect: false,
                                                })
                                            }
                                        }}
                                        open={
                                            serviceCategoryFormState.openSelect
                                        }
                                        onClose={() => {
                                            setServiceCategoryFormState({
                                                ...serviceCategoryFormState,
                                                openSelect: false,
                                            })
                                        }}
                                        onOpen={() => {
                                            setServiceCategoryFormState({
                                                ...serviceCategoryFormState,
                                                openSelect: true,
                                            })
                                        }}
                                    >
                                        {serviceCategoryList.map((sc) => (
                                            <MenuItem
                                                key={`SERVICE_CATEGORY_MENU_ITEM_${sc.id}`}
                                                value={sc.id}
                                            >
                                                <span>{sc.name}</span>
                                            </MenuItem>
                                        ))}
                                        <MenuItem
                                            value="addServiceCategory"
                                            style={{
                                                justifyContent: 'center',
                                                borderTop: `1px solid ${theme.palette.divider}`,
                                            }}
                                            onClick={(e) => {
                                                setServiceCategoryFormState({
                                                    ...serviceCategoryFormState,
                                                    openCreate: true,
                                                    anchorPosition: {
                                                        top: e.clientY,
                                                        left: e.clientX,
                                                    },
                                                })
                                            }}
                                        >
                                            + Add Service Category
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>

                            <Popover
                                open={serviceCategoryFormState.openCreate}
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    serviceCategoryFormState.anchorPosition
                                }
                                onClose={() => {
                                    setServiceCategoryFormState({
                                        ...serviceCategoryFormState,
                                        openCreate: false,
                                    })
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <AddServiceCategoryForm
                                    createServiceCategory={
                                        createServiceCategory
                                    }
                                    serviceList={availableServices}
                                    handleClose={() =>
                                        setServiceCategoryFormState({
                                            ...serviceCategoryFormState,
                                            openCreate: false,
                                        })
                                    }
                                />
                            </Popover>

                            {/* <FormGroup className={classes.formControl}>
                                <span style={sectionTitleStyle}>
                                    Keys Filter
                                </span>
                                <FormControl
                                    variant={'outlined'}
                                    style={{ flex: 1 }}
                                >
                                    <Select
                                        value={filter.keysFilter}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            setInfrastructureFilter(
                                                'keysFilter',
                                                event.target.value,
                                            )
                                        }}
                                    >
                                        <MenuItem value={KeysFilterMode.All}>
                                            <span>All</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={KeysFilterMode.PartialKeysIn}
                                        >
                                            <span>Partial Keys In</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={KeysFilterMode.AllKeysIn}
                                        >
                                            <span>All Keys In</span>
                                        </MenuItem>

                                        <MenuItem
                                            value={KeysFilterMode.AllKeysOut}
                                        >
                                            <span>All Keys Out</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup> */}
                        </Card>

                        <Card
                            style={{
                                padding: theme.spacing(2),
                                borderRadius: 0,
                                flexShrink: 0,
                            }}
                        >
                            <FormGroup>
                                {/* Vendor Filter*/}

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="vendor-chip-label">
                                        Vendors
                                    </InputLabel>
                                    <Select
                                        labelId="vendor-chip-label"
                                        id="mutiple-chip-vendor"
                                        multiple
                                        value={Object.keys(
                                            filter.vendorFilter,
                                        ).map(Number)}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            const selectedVendors = event.target
                                                .value as number[]

                                            const newVndFilter = selectedVendors.reduce<IdBoolMap>(
                                                (prev, vndId) => {
                                                    return {
                                                        ...prev,
                                                        [vndId]: true,
                                                    }
                                                },
                                                {},
                                            )

                                            setInfrastructureFilter(
                                                'vendorFilter',
                                                newVndFilter,
                                            )
                                        }}
                                        MenuProps={{
                                            getContentAnchorEl: () => {
                                                return (null as unknown) as Element
                                            },
                                        }}
                                        input={
                                            <Input id="select-multiple-chip-vendor" />
                                        }
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {(selected as number[]).map(
                                                    (value) => {
                                                        const vnd = vendorList.find(
                                                            (s) =>
                                                                s.id === value,
                                                        )
                                                        return (
                                                            <Chip
                                                                key={`VENDOR_CHIP_${
                                                                    vnd
                                                                        ? vnd.id
                                                                        : -1
                                                                }`}
                                                                label={
                                                                    vnd
                                                                        ? vnd.name
                                                                        : 'unknown'
                                                                }
                                                                className={
                                                                    classes.chip
                                                                }
                                                            />
                                                        )
                                                    },
                                                )}
                                            </div>
                                        )}
                                    >
                                        {vendorList
                                            .filter(
                                                (vendor) =>
                                                    vendor.services.length !==
                                                    0,
                                            )
                                            .map((vnd) => {
                                                return (
                                                    <MenuItem
                                                        key={`SERVICE_MENU_ITEM_${vnd.id}`}
                                                        value={vnd.id}
                                                    >
                                                        {vnd.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>

                                {/* Service Filter */}
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="service-chip-label">
                                        Services
                                    </InputLabel>
                                    <Select
                                        labelId="service-chip-label"
                                        id="mutiple-chip-service"
                                        multiple
                                        value={Object.keys(
                                            filter.serviceFilter,
                                        ).map(Number)}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            const selectedServices = event
                                                .target.value as number[]

                                            const newSrvFilter = selectedServices.reduce<IdBoolMap>(
                                                (prev, srvId) => {
                                                    return {
                                                        ...prev,
                                                        [srvId]: true,
                                                    }
                                                },
                                                {},
                                            )

                                            setInfrastructureFilter(
                                                'serviceFilter',
                                                newSrvFilter,
                                            )
                                        }}
                                        input={
                                            <Input id="select-multiple-chip-service" />
                                        }
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {(selected as number[]).map(
                                                    (value) => {
                                                        const srv = serviceList.find(
                                                            (s) =>
                                                                s.id === value,
                                                        )
                                                        return (
                                                            <Chip
                                                                key={`SERVICE_CHIP_${
                                                                    srv
                                                                        ? srv.id
                                                                        : -1
                                                                }`}
                                                                label={
                                                                    srv
                                                                        ? srv.name
                                                                        : 'unknown'
                                                                }
                                                                className={
                                                                    classes.chip
                                                                }
                                                            />
                                                        )
                                                    },
                                                )}
                                            </div>
                                        )}
                                    >
                                        {serviceList.map((srv) => {
                                            return (
                                                <MenuItem
                                                    key={`SERVICE_MENU_ITEM_${srv.id}`}
                                                    value={srv.id}
                                                >
                                                    {srv.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                {/* Unit Config Filter  */}
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="unit-conf-chip-label">
                                        Unit Types
                                    </InputLabel>
                                    <Select
                                        labelId="unit-conf-chip-label"
                                        id="mutiple-chip-unit-conf"
                                        multiple
                                        value={Object.keys(
                                            filter.unitConfigFilter,
                                        ).map(Number)}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            const selectedUnitConfigs = event
                                                .target.value as number[]

                                            const newUCFilter = selectedUnitConfigs.reduce<IdBoolMap>(
                                                (prev, ucId) => {
                                                    return {
                                                        ...prev,
                                                        [ucId]: true,
                                                    }
                                                },
                                                {},
                                            )

                                            setInfrastructureFilter(
                                                'unitConfigFilter',
                                                newUCFilter,
                                            )
                                        }}
                                        input={
                                            <Input id="select-multiple-chip-unit-conf" />
                                        }
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {(selected as number[]).map(
                                                    (value) => {
                                                        const unitConfig = unitConfigList.find(
                                                            (uc) =>
                                                                uc.id === value,
                                                        )
                                                        return (
                                                            <Chip
                                                                key={`UNIT_CONFIG_CHIP_${
                                                                    unitConfig
                                                                        ? unitConfig.id
                                                                        : -1
                                                                }`}
                                                                label={
                                                                    unitConfig
                                                                        ? unitConfig.name
                                                                        : 'unknown'
                                                                }
                                                                className={
                                                                    classes.chip
                                                                }
                                                            />
                                                        )
                                                    },
                                                )}
                                            </div>
                                        )}
                                    >
                                        {unitConfigList
                                            .filter(
                                                (uc) => uc.shows_on_schedule,
                                            )
                                            .map((uc) => {
                                                return (
                                                    <MenuItem
                                                        key={`UNIT_CONFIG_MENU_ITEM_${uc.id}`}
                                                        value={uc.id}
                                                    >
                                                        {uc.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Card>

                        <Card
                            style={{ padding: theme.spacing(2), flexShrink: 0 }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Priority"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.priorityFilter}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'priorityFilter',
                                                    !filter.priorityFilter,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label={`Unread Messages (${unreadCount})`}
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.unreadFilter}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'unreadFilter',
                                                    !filter.unreadFilter,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Has Messages"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.hasMessages}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'hasMessages',
                                                    !filter.hasMessages,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Has Edited Service Areas"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.manualServiceAreas}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'manualServiceAreas',
                                                    !filter.manualServiceAreas,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Has a Go Back"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.hasGoBack}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'hasGoBack',
                                                    !filter.hasGoBack,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Ghost"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.isGhost}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'isGhost',
                                                    !filter.isGhost,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Behind"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={filter.behindWorkorders}
                                            color="primary"
                                            onClick={() => {
                                                setInfrastructureFilter(
                                                    'behindWorkorders',
                                                    !filter.behindWorkorders,
                                                )
                                            }}
                                        />
                                    }
                                />

                                <FormControlLabel
                                    labelPlacement="end"
                                    label="Change Order"
                                    style={{ marginRight: theme.spacing(2) }}
                                    control={
                                        <Switch
                                            checked={
                                                filter.changeOrder !==
                                                ChangeOrderFilterMode.All
                                            }
                                            color="primary"
                                            onClick={() => {
                                                const value =
                                                    filter.changeOrder ===
                                                    ChangeOrderFilterMode.All
                                                        ? ChangeOrderFilterMode.HasAny
                                                        : ChangeOrderFilterMode.All
                                                setInfrastructureFilter(
                                                    'changeOrder',
                                                    value,
                                                )
                                            }}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Card>
                    </Container>
                )}

                {tab === ASSET_TAB && (
                    <SchedulerFinder
                        root={root}
                        finderSelection={locationFinderSelection}
                        areaConfigMap={areaConfigMap}
                        areaStatusTagMap={areaStatusTagMap}
                        unitWorkorderMap={unitWorkorderMap}
                    />
                )}
            </Container>
            <Popover
                open={bedStatusKeyPopover.isOpen}
                anchorEl={bedStatusKeyPopover.anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClose={bedStatusKeyPopover.handleClose}
            >
                <Container style={{ padding: 12 }}>
                    <Container
                        style={{ flexDirection: 'column', marginRight: 8 }}
                    >
                        <span>V - Vacant</span>
                        <span>R - Renewal</span>
                        <span>TI - Transfer In</span>
                        <span>TO - Transfer Out</span>
                    </Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <span>DNT - Do Not Turn</span>
                        <span>O - Occupied</span>
                        <span>EM - Early Move In</span>
                    </Container>
                </Container>
            </Popover>
        </SideDrawer>
    )
}

const FILTER_TAB = 0
const ASSET_TAB = 1
