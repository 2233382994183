import React from 'react'
import { Modal, Slide, Paper, useTheme } from '@material-ui/core'
import { AnalyticsHome } from './AnalyticsHome'

interface Props {
    open: boolean
    handleClose: () => void
}

export const PropertyAnalyticsModal = (props: Props) => {
    const { open, handleClose } = props

    const theme = useTheme()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        padding: theme.spacing(2),
                        width: 'calc(100vw - 300px)',
                        height: 'calc(100vh - 90px)',
                    }}
                >
                    {open && <AnalyticsHome height={'calc(100vh - 350px)'} />}
                </Paper>
            </Slide>
        </Modal>
    )
}
