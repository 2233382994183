import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import IconButton from '@material-ui/core/IconButton'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useDrawerStyles } from './styles'
import { Container } from '../Container'

interface SideDrawerProps {
    open: boolean
    width?: number
    title?: string | JSX.Element
    header?: JSX.Element
    headerRight?: JSX.Element
    alwaysOpen?: boolean
    handleClose: () => void
}

export const SideDrawer: React.FC<SideDrawerProps> = (props) => {
    const classes = useDrawerStyles(props.width)
    const theme = useTheme()

    const displayCloseIcon =
        props.alwaysOpen === undefined || props.alwaysOpen === false

    const closeIcon = displayCloseIcon ? (
        <IconButton onClick={props.handleClose}>
            {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
            )}
        </IconButton>
    ) : null

    let titleComponent: JSX.Element | undefined
    if (typeof props.title === 'string') {
        titleComponent = (
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightMedium,
                    marginLeft: !displayCloseIcon ? theme.spacing(2) : 0,
                }}
            >
                {props.title}
            </span>
        )
    } else {
        titleComponent = props.title
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {props.header || (
                <Container className={classes.drawerHeader}>
                    {closeIcon}
                    {titleComponent}
                    <div style={{ flex: 1 }} />
                    {props.headerRight}
                </Container>
            )}
            {props.children}
        </Drawer>
    )
}
