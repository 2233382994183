import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { toast } from 'react-toastify'
import { eachDayOfInterval, format } from 'date-fns'

import {
    CircularProgress,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import {
    Container,
    CreateWorkorderForm,
    FilterChips,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
    WorkorderEditForm,
    WorkorderFilterForm,
} from '../../../components'
import {
    _useFinderSelection,
    useAptConfig,
    useCompany,
    useRootInfrastructure,
    useSchedule,
    useService,
    useUser,
} from '../../../hooks'
import { rfpApi, workorderContext } from '../../../contexts'
import {
    CreateWorkOrderRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    WorkorderResponse,
} from '../../../store'
import { JobBoardHeader } from './JobBoardHeader'

import {
    ListVendor,
    RFPStatus,
    RFPType,
    activeStatuses,
    User,
    ModelMap,
} from '../../../models'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { VendorServiceRule } from '../../VendorServiceRules/VendorServiceRules'
import { useApartmentVendorRule } from '../../../hooks/useApartmentVendorRule'
import { ApartmentEZNowList } from '../../EZNow/ApartmentEZNowList'
import { useInspectionData } from '../../../hooks/useInspectionData'
import { JobBoard } from '../JobBoardComponents/JobBoard'
import { PropertyAnalyticsModal } from '../../Analytics/PropertyAnalyticsModal'
import { BulkEditWorkorderForm } from '../../../components/WorkorderLib/BulkEditWorkorderForm'
import { JobBoardSelectionMode } from '../JobBoardComponents/JobBoardSelectionMode'
import { PendingWorkView } from '../PendingWork/PendingWorkView'
import {
    ALL,
    closedJbFormState,
    IN_HOUSE,
    JbDrawerId,
    JbFormState,
    THIS_WEEK,
} from '../types'

export const JobBoardHome = () => {
    const { getVendorList, vendorList } = useCompany()

    const { workspaceUser, userList, actions } = useUser()
    const { getUserList } = actions

    const {
        workorderList,
        filteredWorkorderList,
        workorderFilterState,
        loadingState,
        getWorkorderList,
        transitionWorkorder,
        updateWorkorderState,
        setWorkorderFilterState,
        getChipData,
        updateWorkorder,
        createWorkorders,
        bulkUpdateWorkorders,
        bulkDeleteWorkorders,
    } = workorderContext()

    const { tree } = useRootInfrastructure(true)

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const { scheduleList, getScheduleList } = useSchedule()

    const {
        serviceRequestList,
        filteredServiceRequestList,
        serviceRequestFilterState,
        setServiceRequestFilterState,
        getServiceRequestList,
        createServiceRequestWorkorder,
    } = useServiceRequest()

    const {
        apartmentVendorRuleDict,
        setApartmentVendorRuleDict,
        getApartmentVendorRules,
        createOrUpdateAptVendorRule,
        deleteAptVendorRule,
    } = useApartmentVendorRule()

    const {
        inspectionData,
        inspectionList,
        analyticData,
        getInspectionListFromUnitInspection,
        getInspectionData,
    } = useInspectionData()

    const { selectedRFP, rfpList, setSelectedRFP, getRFPList } = rfpApi()

    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(true)
    const [dateFilter, setDateFilter] = useState(THIS_WEEK)
    const [startDate, setStartDate] = useState<MaterialUiPickersDate>(
        moment().day(1).toDate(),
    )
    const [endDate, setEndDate] = useState<MaterialUiPickersDate>(
        moment().day(7).toDate(),
    )

    const [formState, setFormState] = useState<JbFormState>(closedJbFormState)

    const [vendorFilter, setVendorFilter] = useState(ALL)

    const [isMultiselectMode, setIsMultiselectMode] = useState(false)
    const [selectedWorkorders, setSelectedWorkorders] = useState<
        ModelMap<WorkorderResponse>
    >({})

    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        getAreaConfigMap,
        areaConfigList,
        getUnitConfigMap,
        unitConfigList,
        areaStatusConfigList,
    } = useAptConfig({
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    const dateRange = useMemo(
        () =>
            eachDayOfInterval({
                start: new Date(startDate?.toISOString() ?? ''),
                end: new Date(endDate?.toISOString() ?? ''),
            }),
        [startDate, endDate],
    )

    const refreshData = (
        startDate: Date | MaterialUiPickersDate,
        endDate: Date | MaterialUiPickersDate,
    ) => {
        setIsLoading(true)
        startDate?.setHours(0, 0, 0)
        endDate?.setHours(23, 59, 59)

        const workorderPromise = getWorkorderList({
            params: {
                upper_bound_date: endDate?.toISOString(),
                lower_bound_date: startDate?.toISOString(),
                unassigned: true,
                behind_date_range_union: true,
            },
        })

        const inspectionDataPromise = getInspectionData({
            params: {
                upper_bound_date: endDate?.toISOString() ?? '',
                lower_bound_date: startDate?.toISOString() ?? '',
            },
        })

        Promise.allSettled([
            workorderPromise,
            inspectionDataPromise,
        ]).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        const vendorPromise = getVendorList({
            params: { my_team: true },
        })

        const workorderPromise = getWorkorderList(
            {
                params: {
                    upper_bound_date: endDate
                        ? new Date(
                              endDate.getFullYear(),
                              endDate.getMonth(),
                              endDate.getDate(),
                              23,
                              59,
                              59,
                              999,
                          ).toISOString()
                        : undefined,
                    lower_bound_date: startDate
                        ? new Date(
                              startDate.getFullYear(),
                              startDate.getMonth(),
                              startDate.getDate(),
                              0,
                              0,
                              0,
                              0,
                          ).toISOString()
                        : undefined,
                    unassigned: true,
                    behind_date_range_union: true,
                },
            },
            true,
        )

        const schedulePromise = getScheduleList({ params: { active: true } })

        const userPromise = getUserList({ params: { my_team: true } })

        const serviceRequestPromise = getServiceRequestList({
            params: { no_workorder: true },
        })

        const vendorRulePromise = getApartmentVendorRules()

        const rfpPromise = getRFPList({
            params: {
                type: RFPType.EZNOW,
            },
        })

        const inspectionDataPromise = getInspectionData({
            params: {
                upper_bound_date: endDate?.toISOString() ?? '',
                lower_bound_date: startDate?.toISOString() ?? '',
            },
        })

        Promise.allSettled([
            vendorPromise,
            workorderPromise,
            schedulePromise,
            userPromise,
            serviceRequestPromise,
            vendorRulePromise,
            rfpPromise,
            inspectionDataPromise,
        ]).finally(() => setIsLoading(false))
    }, [])

    const areaConfigMap = useMemo(() => {
        return getAreaConfigMap()
    }, [areaConfigList])

    const unitConfigMap = useMemo(() => {
        return getUnitConfigMap()
    }, [unitConfigList])

    const sortVendors = (
        vendors: ListVendor[],
        filteredWorkorderList: WorkorderResponse[],
        currentDate: string,
    ) => {
        const today = new Date()
        today.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison

        return vendors.sort((vendorA, vendorB) => {
            const countWorkorders = (vendor: ListVendor) => {
                return filteredWorkorderList.filter((wo) => {
                    const woDate = new Date(wo.start_date)
                    woDate.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison
                    const isCurrentDate = wo.start_date?.startsWith(currentDate)
                    const isOldActive =
                        woDate < today && activeStatuses.includes(wo.status)
                    return (
                        wo.vendor_id === vendor.id &&
                        (isCurrentDate || isOldActive)
                    )
                }).length
            }

            const aWorkorders = countWorkorders(vendorA)
            const bWorkorders = countWorkorders(vendorB)

            // Sort by number of workorders (descending)
            if (bWorkorders !== aWorkorders) {
                return bWorkorders - aWorkorders
            }

            // If workorder count is the same, sort by in-house status
            if (vendorA.in_house !== vendorB.in_house) {
                return vendorA.in_house ? -1 : 1 // In-house vendors come first
            }

            // If both criteria are the same, sort alphabetically by name
            return vendorA.name.localeCompare(vendorB.name)
        })
    }

    const sortUsers = (
        users: User[],
        filteredWorkorderList: WorkorderResponse[],
        currentDate: string,
    ) => {
        return users.sort((userA, userB) => {
            const aWorkorders = filteredWorkorderList.filter(
                (wo) =>
                    wo.user_id === userA.id &&
                    wo.start_date &&
                    wo.start_date.startsWith(currentDate),
            ).length
            const bWorkorders = filteredWorkorderList.filter(
                (wo) =>
                    wo.user_id === userB.id &&
                    wo.start_date &&
                    wo.start_date.startsWith(currentDate),
            ).length

            // Sort by number of workorders (descending)
            if (bWorkorders !== aWorkorders) {
                return bWorkorders - aWorkorders
            }

            // If both criteria are the same, sort alphabetically by name
            return userA.name.localeCompare(userB.name)
        })
    }

    const filteredVendorList = useMemo(() => {
        return vendorList.filter((vendor) => {
            if (vendorFilter === ALL) {
                return vendor
            } else if (vendorFilter === IN_HOUSE) {
                return vendor.in_house
            } else {
                return !vendor.in_house
            }
        })
    }, [vendorFilter, vendorList])

    const sortedVendors = useMemo(() => {
        let currentDate = format(new Date(), 'yyyy-MM-dd')
        if (dateFilter !== THIS_WEEK) {
            currentDate = format(
                new Date(startDate?.toISOString() ?? ''),
                'yyyy-MM-dd',
            )
        }
        return sortVendors(
            filteredVendorList,
            filteredWorkorderList,
            currentDate,
        )
    }, [filteredVendorList, dateFilter, startDate, isLoading])

    const sortedUsers = useMemo(() => {
        let currentDate = format(new Date(), 'yyyy-MM-dd')
        if (dateFilter !== THIS_WEEK) {
            currentDate = format(
                new Date(startDate?.toISOString() ?? ''),
                'yyyy-MM-dd',
            )
        }
        return sortUsers(userList, filteredWorkorderList, currentDate)
    }, [userList, dateFilter, startDate, isLoading])

    const unassignedWorkorders = useMemo(() => {
        return (
            workorderList?.filter((wo) => {
                return (
                    wo.vendor_id === null &&
                    wo.user_id === null &&
                    wo.price_group_id === null
                )
            }) ?? []
        )
    }, [workorderList])

    const workorderMap = useMemo(() => {
        const map = new Map<string, WorkorderResponse[]>()
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const todayString = format(today, 'yyyy-MM-dd')
        filteredWorkorderList?.forEach((wo) => {
            const woStartDate = new Date(wo.start_date)
            if (wo.vendor_id || wo.user_id) {
                let dateKey = format(woStartDate, 'yyyy-MM-dd')

                if (activeStatuses.includes(wo.status) && woStartDate < today) {
                    dateKey = todayString
                }

                if (!map.has(dateKey)) {
                    map.set(dateKey, [])
                }

                map.get(dateKey)!.push(wo)
            }
        })
        return map
    }, [filteredWorkorderList])

    const openDetailDrawer = (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => {
        setFormState({
            ...closedJbFormState,
            detailFormOpen: {
                workorder: workorder,
                drawerId: drawerId,
            },
        })
    }

    const openFilterDrawer = () => {
        setFormState({
            ...closedJbFormState,
            filterFormOpen: true,
        })
    }

    const handleCreateWorkorders = (request: CreateWorkOrderRequest) => {
        createWorkorders(request)
            .then(() => {
                setFormState({
                    ...formState,
                    createWorkorderForm: false,
                })
            })
            .catch((e) => {
                let errorMessage = <span>Error</span>
                if (e.response?.data?.message !== undefined) {
                    errorMessage = (
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Error
                            </span>
                            <span>- {e.response?.data?.message}</span>
                        </Container>
                    )
                }
                toast.error(errorMessage, {
                    autoClose: 3000,
                })
            })
    }

    const drawerContainerOpen =
        (formState.detailFormOpen.workorder !== null &&
            formState.detailFormOpen.drawerId !== null &&
            formState.detailFormOpen.drawerId !== JbDrawerId.edit) ||
        formState.filterFormOpen

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    height: 'calc(100vh - 100px)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    flex: 1,
                }}
            >
                <JobBoardHeader
                    dateFilter={dateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    vendorFilter={vendorFilter}
                    formState={formState}
                    failedServiceRequests={serviceRequestList.length}
                    ezNowCount={
                        rfpList?.filter(
                            (rfp) => rfp.status === RFPStatus.SELECT_VENDOR,
                        ).length ?? 0
                    }
                    workspaceUser={workspaceUser}
                    unassignedWoCount={unassignedWorkorders.length}
                    setDateFilter={setDateFilter}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setVendorFilter={setVendorFilter}
                    refreshData={refreshData}
                    openFilterDrawer={openFilterDrawer}
                    setFormState={setFormState}
                />

                <JobBoardSelectionMode
                    isMultiselectMode={isMultiselectMode}
                    selectedWorkorders={selectedWorkorders}
                    setIsMultiselectMode={setIsMultiselectMode}
                    setSelectedWorkorders={setSelectedWorkorders}
                    bulkDeleteWorkorders={bulkDeleteWorkorders}
                    openBulkEditModal={() => {
                        setFormState({
                            ...formState,
                            bulkEditModal: true,
                        })
                    }}
                />

                <FilterChips theme={theme} chips={getChipData()} />

                {isLoading ? (
                    <Container
                        style={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={100} />
                    </Container>
                ) : (
                    <JobBoard
                        filteredWorkorderList={filteredWorkorderList}
                        workorderList={workorderList ?? []}
                        userList={userList}
                        sortedUsers={sortedUsers}
                        vendorList={vendorList}
                        workorderMap={workorderMap}
                        vendorFilter={vendorFilter}
                        sortedVendors={sortedVendors}
                        serviceList={serviceList}
                        dateRange={dateRange}
                        scheduleList={scheduleList}
                        inspectionData={inspectionData}
                        inspectionList={inspectionList}
                        analyticData={analyticData}
                        isMultiselectMode={isMultiselectMode}
                        selectedWorkorders={selectedWorkorders}
                        setSelectedWorkorders={setSelectedWorkorders}
                        getInspectionListFromUnitInspection={
                            getInspectionListFromUnitInspection
                        }
                        openDetailDrawer={openDetailDrawer}
                    />
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.message && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.message
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={'Workorder Messages'}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder?.channel ??
                                -1
                            }
                            secondaryChannelId={
                                formState.detailFormOpen.workorder
                                    ?.inv_ins_channel
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        message_count:
                                            (formState.detailFormOpen.workorder
                                                .message_count ?? 0) + 1,
                                    })
                                }
                            }}
                            secondaryTitle="From Inventory Inspection"
                        />
                    </SideDrawer>
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.unitNotes && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.unitNotes
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={`${formState.detailFormOpen.workorder?.unit_name} Unit Notes`}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder
                                    ?.unit_channel_id ?? -1
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        unit_notes_msg_count:
                                            (formState.detailFormOpen.workorder
                                                .unit_notes_msg_count ?? 0) + 1,
                                    })
                                }
                            }}
                        />
                    </SideDrawer>
                )}

                <SideDrawer
                    open={formState.filterFormOpen}
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedJbFormState)}
                >
                    <WorkorderFilterForm
                        theme={theme}
                        root={tree.root}
                        finderSelection={finderSelection}
                        serviceList={serviceList}
                        unitConfigList={unitConfigList}
                        workorderFilterState={workorderFilterState}
                        vendorList={vendorList}
                        userList={userList}
                        disabledSections={['timeline', 'vendors', 'users']}
                        setWorkorderFilterState={setWorkorderFilterState}
                    />
                </SideDrawer>

                <Modal
                    open={formState.createWorkorderForm}
                    onClose={() => {
                        setFormState(closedJbFormState)
                        finderSelection.setFinderSelection()
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.createWorkorderForm}>
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                vendorList={vendorList}
                                serviceList={serviceList}
                                theme={theme}
                                root={tree.root}
                                finderSelection={finderSelection}
                                areaConfigMap={areaConfigMap}
                                areaStatusConfigs={areaStatusConfigList ?? []}
                                unitConfigMap={unitConfigMap}
                                areaStatusTagMap={{}}
                                unitWorkorderMap={{}}
                                userList={userList}
                                scheduleList={scheduleList}
                                onClickCreate={handleCreateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                {formState.detailFormOpen.workorder && (
                    <Modal
                        open={
                            formState.detailFormOpen.drawerId ===
                            JbDrawerId.edit
                        }
                        onClose={() =>
                            setFormState({
                                ...formState,
                                detailFormOpen:
                                    closedJbFormState.detailFormOpen,
                            })
                        }
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Slide
                            direction="up"
                            in={
                                formState.detailFormOpen.drawerId ===
                                JbDrawerId.edit
                            }
                        >
                            <Paper style={{}}>
                                <WorkorderEditForm
                                    theme={theme}
                                    serviceList={serviceList}
                                    root={tree.root}
                                    userList={userList}
                                    vendorList={vendorList}
                                    workorder={
                                        formState.detailFormOpen.workorder
                                    }
                                    handleClose={() =>
                                        setFormState({
                                            ...formState,
                                            detailFormOpen:
                                                closedJbFormState.detailFormOpen,
                                        })
                                    }
                                    areaConfigMap={areaConfigMap}
                                    handleSubmit={(request) => {
                                        return updateWorkorder(request)
                                    }}
                                    loading={loadingState.updateWorkorder}
                                />
                            </Paper>
                        </Slide>
                    </Modal>
                )}

                <Modal
                    open={formState.serviceRequestModal}
                    onClose={() => {
                        setFormState(closedJbFormState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.serviceRequestModal}>
                        <Paper>
                            <PendingWorkView
                                serviceRequestList={filteredServiceRequestList}
                                vendorList={vendorList}
                                serviceList={serviceList}
                                serviceRequestFilterState={
                                    serviceRequestFilterState
                                }
                                workorderList={unassignedWorkorders}
                                workspaceUser={workspaceUser}
                                updateWorkorderState={updateWorkorderState}
                                createServiceRequestWorkorder={
                                    createServiceRequestWorkorder
                                }
                                setServiceRequestFilterState={
                                    setServiceRequestFilterState
                                }
                                transitionWorkorder={transitionWorkorder}
                                bulkUpdateWorkorders={bulkUpdateWorkorders}
                                openEditModal={(
                                    workorder: WorkorderResponse,
                                ) => {
                                    setFormState({
                                        ...formState,
                                        detailFormOpen: {
                                            workorder,
                                            drawerId: JbDrawerId.edit,
                                        },
                                    })
                                }}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <Modal
                    open={formState.vendorRuleModal}
                    onClose={() => {
                        setFormState(closedJbFormState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.vendorRuleModal}>
                        <Paper>
                            <VendorServiceRule
                                serviceList={serviceList}
                                vendorList={vendorList}
                                apartmentVendorRuleDict={
                                    apartmentVendorRuleDict
                                }
                                createOrUpdateAptVendorRule={
                                    createOrUpdateAptVendorRule
                                }
                                deleteAptVendorRule={deleteAptVendorRule}
                                setApartmentVendorRuleDict={
                                    setApartmentVendorRuleDict
                                }
                            />
                        </Paper>
                    </Slide>
                </Modal>

                {formState.ezNowModal && (
                    <Modal
                        open={formState.ezNowModal}
                        onClose={() => {
                            setFormState(closedJbFormState)
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Slide direction="up" in={formState.ezNowModal}>
                            <Paper
                                style={{
                                    height: 'calc(100vh - 100px)',
                                    width: 'calc(100vw - 200px)',
                                }}
                            >
                                <ApartmentEZNowList
                                    ezNowRequestList={rfpList}
                                    selectedRFP={selectedRFP}
                                    modalMode
                                    setSelectedRFP={setSelectedRFP}
                                />
                            </Paper>
                        </Slide>
                    </Modal>
                )}

                <PropertyAnalyticsModal
                    open={formState.propertyAnalyticsModal}
                    handleClose={() => {
                        setFormState(closedJbFormState)
                    }}
                />

                <Modal
                    open={formState.bulkEditModal}
                    onClose={() => {
                        setFormState(closedJbFormState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.bulkEditModal}>
                        <Paper>
                            <Container
                                style={{
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        padding: theme.spacing(2),
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Bulk Edit Workorders
                                </span>
                            </Container>
                            <BulkEditWorkorderForm
                                open={formState.bulkEditModal}
                                selectedWorkorders={selectedWorkorders}
                                vendorList={vendorList}
                                serviceList={serviceList}
                                loading={loadingState.updateWorkorder}
                                handleClose={() => {
                                    setFormState(closedJbFormState)
                                }}
                                bulkUpdateWorkorders={bulkUpdateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            </Container>
        </SideDrawerContainer>
    )
}

const DRAWER_W = 420
