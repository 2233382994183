import { AxiosResponse } from 'axios'
import { Action } from 'redux'

import {
    ListVendor,
    Company,
    ServiceContract,
    RegisterStep,
} from '../../models'
import { ServiceContractForUpdate } from '../serviceContract'

// Requests

export interface GetApartmentListRequest {
    params: {
        all_apartments?: boolean
        organization?: number
    }
}

export interface GetVendorListRequest {
    params: {
        my_team?: boolean
        portfolio_apartment?: number
        organization?: number
    }
}

export interface AddRemoveVendorAptRequest {
    body: {
        vendor: number
        apartment_id?: number
    }
}

export interface CompanyDetailAxiosReq {
    company: number
    params?: {}
}

export interface CreateVendorRequest {
    body: {
        name: string
        address: string
        city: string
        state: string
        zip_code: string
        email: string
        phone?: string
        cell_phone?: string
        in_house?: boolean
        service_contracts: ServiceContractForUpdate[]
        services: {
            id: number
            offers: boolean
        }[]
        is_skillcat: boolean
        register_step?: RegisterStep
    }
}

export interface UpdateVendorRequest {
    params: {
        id: number
    }
    body: {
        name?: string
        address?: string
        city?: string
        state?: string
        zip_code?: string
        phone?: string
        in_house?: boolean
        unit_notes?: boolean
        service_contracts?: ServiceContractForUpdate[]
        services?: {
            id: number
            offers: boolean
        }[]
        is_skillcat?: boolean
        register_step?: RegisterStep
    }
}

export interface UpdateApartmentRequest {
    params: {
        id: number
    }
    body: {
        move_out_inspection_config?: number
        url?: string
        run_inspection_frequencies?: boolean
        run_action_item_frequencies?: boolean
        run_auto_move_out_events?: boolean
        allow_service_requests?: boolean
        days_before_move_out_schedule?: number
    }
}

export interface UpdateVendorServicesRequest {
    params: {
        id: number
    }
    body: {
        services: {
            id: number
            offers: boolean
        }[]
    }
}

// responses

export interface VendorAndContractResponse {
    vendor: ListVendor
    service_contracts: ServiceContract[]
}

// actions

export const GET_APARTMENT_LIST_REQUEST = 'GET_APARTMENT_LIST_REQUEST'
export const GET_APARTMENT_LIST_RESPONSE = 'GET_APARTMENT_LIST_RESPONSE'
export const GET_VENDOR_LIST_REQUEST = 'GET_VENDOR_LIST_REQUEST'
export const GET_VENDOR_LIST_RESPONSE = 'GET_VENDOR_LIST_RESPONSE'
export const GET_COMPANY_DETAIL_REQUEST = 'GET_COMPANY_DETAIL_REQUEST'
export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST'
export const CREATE_VENDOR_RESPONSE = 'CREATE_VENDOR_RESPONSE'
export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST'
export const UPDATE_VENDOR_RESPONSE = 'UPDATE_VENDOR_RESPONSE'
export const UPDATE_VENDOR_SERVICE_REQUEST = 'UPDATE_VENDOR_SERVICE_REQUEST'
export const UPDATE_VENDOR_SERVICE_RESPONSE = 'UPDATE_VENDOR_SERVICE_RESPONSE'
export const ADD_VENDOR_TO_APT_REQUEST = 'ADD_VENDOR_TO_APT_REQUEST'
export const REMOVE_VENDOR_APT_REQUEST = 'REMOVE_VENDOR_APT_REQUEST'
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING'
export const SET_VENDOR_LIST = 'SET_VENDOR_LIST'
export const SET_COMPANY_DETAIL = 'SET_COMPANY_DETAIL'
export const SET_PORTFOLIO_VENDOR_LIST = 'SET_PORTFOLIO_VENDOR_LIST'

export type CompanyRequests =
    | typeof GET_APARTMENT_LIST_REQUEST
    | typeof GET_VENDOR_LIST_REQUEST
    | typeof CREATE_VENDOR_REQUEST
    | typeof UPDATE_VENDOR_REQUEST
    | typeof UPDATE_VENDOR_SERVICE_REQUEST
    | typeof ADD_VENDOR_TO_APT_REQUEST
    | typeof REMOVE_VENDOR_APT_REQUEST
    | typeof GET_COMPANY_DETAIL_REQUEST

interface GetApartmentListRequestAction extends Action {
    type: typeof GET_APARTMENT_LIST_REQUEST
}

interface GetApartmentListResponseAction extends Action {
    type: typeof GET_APARTMENT_LIST_RESPONSE
    payload: Company[]
}

interface GetVendorListRequestAction extends Action {
    type: typeof GET_VENDOR_LIST_REQUEST
}

interface GetVendorListResponseAction extends Action {
    type: typeof GET_VENDOR_LIST_RESPONSE
    payload: ListVendor[]
}

interface CreateVendorRequestAction extends Action {
    type: typeof CREATE_VENDOR_REQUEST
}

interface CreateVendorResponseAction extends Action {
    type: typeof CREATE_VENDOR_RESPONSE
    payload: ListVendor
}

interface UpdateVendorRequestAction extends Action {
    type: typeof UPDATE_VENDOR_REQUEST
}

interface UpdateVendorResponseAction extends Action {
    type: typeof UPDATE_VENDOR_RESPONSE
    payload: ListVendor
}

interface UpdateVendorServicesRequestAction extends Action {
    type: typeof UPDATE_VENDOR_SERVICE_REQUEST
}

interface UpdateVendorServicesResponseAction extends Action {
    type: typeof UPDATE_VENDOR_SERVICE_RESPONSE
    payload: ListVendor
}

interface SetCompanyLoadingAction extends Action {
    type: typeof SET_COMPANY_LOADING
    action: CompanyRequests
    isLoading: boolean
}

interface SetVendorListAction extends Action {
    type: typeof SET_VENDOR_LIST
    vendorList?: ListVendor[]
}

interface SetCompanyDetailAction extends Action {
    type: typeof SET_COMPANY_DETAIL
    company?: Company
}

interface SetPortflioVendorListAction extends Action {
    type: typeof SET_PORTFOLIO_VENDOR_LIST
    vendorList?: ListVendor[]
}

type CreateVendorActionTypes =
    | CreateVendorResponseAction
    | CreateVendorRequestAction

type GetVendorListActionTypes =
    | GetVendorListResponseAction
    | GetVendorListRequestAction

type GetApartmentListActionTypes =
    | GetApartmentListRequestAction
    | GetApartmentListResponseAction

type UpdateVendorActionTypes =
    | UpdateVendorRequestAction
    | UpdateVendorResponseAction

type UpdateVendorServicesActionTypes =
    | UpdateVendorServicesRequestAction
    | UpdateVendorServicesResponseAction

export type CompanyActionTypes =
    | GetApartmentListActionTypes
    | GetVendorListActionTypes
    | CreateVendorActionTypes
    | UpdateVendorActionTypes
    | UpdateVendorServicesActionTypes
    | SetCompanyLoadingAction
    | SetVendorListAction
    | SetCompanyDetailAction
    | SetPortflioVendorListAction

// thunks

export type GetApartmentListActionThunk = Promise<AxiosResponse<Company[]>>

export type VendorListActionThunk = Promise<AxiosResponse<ListVendor[]>>

export type CompanyDetailActionThunk = Promise<AxiosResponse<Company>>

export type VendorAndContractThunk = Promise<
    AxiosResponse<VendorAndContractResponse>
>

// reducers
export interface CompanyState {
    apartmentList: Company[]
    vendorList: ListVendor[]
    portfolioVendorList?: ListVendor[]
    companyDetail?: Company
    isLoading: {
        [GET_APARTMENT_LIST_REQUEST]: boolean
        [GET_VENDOR_LIST_REQUEST]: boolean
        [CREATE_VENDOR_REQUEST]: boolean
        [UPDATE_VENDOR_REQUEST]: boolean
        [UPDATE_VENDOR_SERVICE_REQUEST]: boolean
        [ADD_VENDOR_TO_APT_REQUEST]: boolean
        [REMOVE_VENDOR_APT_REQUEST]: boolean
        [GET_COMPANY_DETAIL_REQUEST]: boolean
    }
}
