import { Chip, useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../../components'
import { FinderSelection } from '../../hooks'
import { Inspection, getDamageStatusName } from '../../models'
import {
    DamageFilter,
    defaultDamageFilter,
    HasIssuesFilter,
} from './DamageList'

interface Props {
    damageFilter: DamageFilter
    finderSelection: FinderSelection
    inspectionList: Inspection[]
    setDamageFilter: (newValue: DamageFilter) => void
}

export const DamageFilterChips: React.FC<Props> = ({
    damageFilter,
    finderSelection,
    setDamageFilter,
    inspectionList,
}) => {
    const theme = useTheme()

    const chipStyle: React.CSSProperties = {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }

    const operatorSymbols: Record<string, string> = {
        gt: '>',
        lt: '<',
        eq: '=',
        gte: '≥',
        lte: '≤',
    }

    let issuesChip: JSX.Element | undefined = undefined

    if (damageFilter.hasIssues !== HasIssuesFilter.NO_FILTER) {
        issuesChip = (
            <Chip
                clickable
                style={chipStyle}
                label={
                    damageFilter.hasIssues === HasIssuesFilter.ISSUES
                        ? 'Has Issues'
                        : 'No Issues'
                }
                onClick={() => {
                    setDamageFilter({
                        ...damageFilter,
                        hasIssues: HasIssuesFilter.NO_FILTER,
                        modified: true,
                    })
                }}
            />
        )
    }

    let locationChips: JSX.Element | JSX.Element[] = (
        <Chip
            clickable
            style={chipStyle}
            label={`(${finderSelection.selection.unit.length}) Units`}
            onClick={() => {
                setDamageFilter({ ...damageFilter, modified: true })
                finderSelection.setFinderSelection(undefined)
            }}
        />
    )
    if (finderSelection.selection.unit.length < 5) {
        locationChips = Object.keys(finderSelection.selection.unit).reduce<
            JSX.Element[]
        >((prev, strKey) => {
            if (strKey === 'length') {
                return prev
            }

            const numKey = parseInt(strKey)
            if (isNaN(numKey)) {
                return prev
            }

            if (finderSelection.selection.unit[numKey] === undefined) {
                return prev
            }

            return prev.concat(
                <Chip
                    key={`DAMAGE_FILTER_UNIT_${strKey}`}
                    clickable
                    style={chipStyle}
                    label={finderSelection.selection.unit[numKey].name}
                    onClick={() => {
                        setDamageFilter({ ...damageFilter, modified: true })
                        finderSelection.setFinderSelection({
                            type: 'unit',
                            location: finderSelection.selection.unit[numKey],
                        })
                    }}
                />,
            )
        }, [])
    }

    let inspectionChip: JSX.Element | undefined = undefined
    const inspection = inspectionList.find(
        (ins) => ins.id === damageFilter.inspection,
    )

    if (damageFilter.inspection !== -1 && inspection) {
        inspectionChip = (
            <Chip
                clickable
                style={chipStyle}
                label={inspection.name}
                onClick={() => {
                    setDamageFilter({
                        ...damageFilter,
                        inspection: -1,
                        modified: true,
                    })
                }}
            />
        )
    }

    let creationDateChip: JSX.Element | undefined = undefined
    if (damageFilter.created_date_sort) {
        creationDateChip = (
            <Chip
                clickable
                style={chipStyle}
                label={'Creation Date Sort'}
                onClick={() => {
                    setDamageFilter({
                        ...damageFilter,
                        created_date_sort: false,
                        modified: true,
                    })
                }}
            />
        )
    }

    let hasAttachmentChip: JSX.Element | undefined = undefined
    if (damageFilter.hasAttachment) {
        hasAttachmentChip = (
            <Chip
                clickable
                style={chipStyle}
                label="Has Image"
                onClick={() => {
                    setDamageFilter({
                        ...damageFilter,
                        hasAttachment: false,
                        modified: true,
                    })
                }}
            />
        )
    }

    let costChip: JSX.Element | undefined = undefined
    if (damageFilter.costFilter.cost !== null) {
        const operatorSymbol =
            operatorSymbols[damageFilter.costFilter.operator] || ''
        costChip = (
            <Chip
                label={`Cost ${operatorSymbol} $${damageFilter.costFilter.cost}`}
                clickable
                style={chipStyle}
                onClick={() => {
                    setDamageFilter({
                        ...damageFilter,
                        costFilter: { ...defaultDamageFilter.costFilter },
                        modified: true,
                    })
                }}
            />
        )
    }

    return (
        <Container>
            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(2),
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightMedium,
                        color: theme.palette.text.secondary,
                    }}
                >
                    Showing
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    {damageFilter.created_after.toLocaleDateString()} -{' '}
                    {damageFilter.created_before.toLocaleDateString()}
                </span>
            </Container>

            {damageFilter.status.map((damageStatus) => (
                <Chip
                    key={`DAMAGE_FILTER_${damageStatus}`}
                    clickable
                    style={chipStyle}
                    label={getDamageStatusName(damageStatus)}
                    onClick={() => {
                        setDamageFilter({
                            ...damageFilter,
                            status: damageFilter.status.filter(
                                (s) => s !== damageStatus,
                            ),
                            modified: true,
                        })
                    }}
                />
            ))}
            {locationChips}
            {issuesChip}
            {inspectionChip}
            {creationDateChip}
            {hasAttachmentChip}
            {costChip}
        </Container>
    )
}
