import React, { useEffect, useMemo, useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    Divider,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import InfoIcon from '@material-ui/icons/Info'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import {
    convertListToMap,
    convertMapToList,
    ListVendor,
    ModelMap,
    Service,
} from '../../models'
import { BulkUpdateWorkOrderRequest, WorkorderResponse } from '../../store'
import { useSelectVendorService } from '../../hooks'
import { Container } from '../Container'
import { Selector } from '../Selector'
import { DetailedToolTip } from '../DetailedToolTip'
import { NumberInput } from '../NumberInput'
import { toMMDDYYYY } from '../../helpers'

interface Props {
    open: boolean
    selectedWorkorders: ModelMap<WorkorderResponse>
    vendorList: ListVendor[]
    serviceList: Service[]
    loading: boolean
    organization?: number | null
    apartmentVendorMap?: Record<number, number[]>
    handleClose: () => void
    bulkUpdateWorkorders: (request: BulkUpdateWorkOrderRequest) => Promise<void>
}

export const BulkEditWorkorderForm = (props: Props) => {
    const {
        open,
        selectedWorkorders,
        vendorList,
        serviceList,
        loading,
        organization,
        apartmentVendorMap,
        handleClose,
        bulkUpdateWorkorders,
    } = props

    const theme = useTheme()

    const [openDialog, setOpenDialog] = useState(false)

    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [priority, setPriority] = useState<ToggleSelection>(NO_CHANGE)

    const [addPrice, setAddPrice] = useState<number | undefined>()
    const [customPrice, setCustomPrice] = useState<number | undefined>()

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
    } = useSelectVendorService(vendorList)

    const workorders = open ? convertMapToList(selectedWorkorders) : []

    const serviceMap = convertListToMap(serviceList)
    const vendorMap = convertListToMap(vendorList)

    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
        setPriority(NO_CHANGE)
        setAddPrice(undefined)
        setCustomPrice(undefined)
        selectVendor(-1)
        selectService(-1)
    }, [open])

    // let servicesMatch = true
    let allUnlocked = true
    const sId = workorders.length > 0 ? workorders[0]?.service_id : -1
    // Get the first services id
    for (let i = 0; i < workorders.length; i++) {
        if (workorders[i]?.service_id !== sId) {
            // We found a service that does not match the first one
            // The user cannot update the vendor
            // servicesMatch = false
        }
        if (workorders[i]?.price_locked) {
            allUnlocked = false
        }
    }

    const {
        singleVendorOffersAllWoServices,
        singleVendorOffersSingleService,
        allWoVendorsOfferService,
        vendorBelongsToAllApts,
    } = useMemo(() => {
        let singleVendorOffersAllWoServices = true
        let singleVendorOffersSingleService = true
        let allWoVendorsOfferService = true
        let vendorBelongsToAllApts = true
        // Changing vendor and not service
        if (selectedServiceId === -1 && selectedVendorId !== -1) {
            const vendor = vendorMap[selectedVendorId]
            workorders.forEach((wo) => {
                const serviceValid =
                    vendor?.services.find((s) => s.id === wo.service_id) !==
                    undefined
                if (!serviceValid) {
                    singleVendorOffersAllWoServices = false
                }
            })
        }

        // Changing service and not vendor
        if (selectedServiceId !== -1 && selectedVendorId === -1) {
            workorders.forEach((wo) => {
                if (wo.vendor_id) {
                    const vendor = vendorMap[wo.vendor_id]
                    const serviceValid =
                        vendor?.services.find(
                            (s) => s.id === selectedServiceId,
                        ) !== undefined
                    if (!serviceValid) {
                        allWoVendorsOfferService = false
                    }
                }
            })
        }

        // Changing both service and vendor
        if (selectedServiceId !== -1 && selectedVendorId !== -1) {
            const vendor = vendorMap[selectedVendorId]
            singleVendorOffersSingleService =
                vendor?.services.find((s) => s.id === selectedServiceId) !==
                undefined
        }

        // Check if vendor belongs to all apartments
        if (apartmentVendorMap && selectedVendorId !== -1) {
            workorders.forEach((wo) => {
                if (
                    !apartmentVendorMap[wo.apartment_id]?.includes(
                        selectedVendorId,
                    )
                ) {
                    vendorBelongsToAllApts = false
                }
            })
        }

        return {
            singleVendorOffersAllWoServices,
            singleVendorOffersSingleService,
            allWoVendorsOfferService,
            vendorBelongsToAllApts,
        }
    }, [selectedServiceId, selectedVendorId, workorders, open])

    const validServices = useMemo(() => {
        if (selectedVendorId !== -1) {
            return vendorMap[selectedVendorId]?.services ?? []
        }
        return serviceList
    }, [selectedServiceId, selectedVendorId, serviceList])

    const canUpdateVendor = allUnlocked

    useEffect(() => {
        if (!canUpdateVendor) {
            selectVendor(-1)
        }
    }, [canUpdateVendor])

    useEffect(() => {
        if (!allUnlocked) {
            setStartDate(null)
        }
    }, [allUnlocked])

    const createBulkUpdateBody = () => {
        const request: BulkUpdateWorkOrderRequest = {
            body: {
                workorders: workorders.map((wo) => wo.id),
                priority:
                    priority !== NO_CHANGE ? Boolean(priority) : undefined,
                start_date:
                    startDate !== null ? startDate.toISOString() : undefined,
                end_date: endDate !== null ? endDate.toISOString() : undefined,
                add_price: addPrice,
                custom_price: customPrice,
                vendor: selectedVendorId !== -1 ? selectedVendorId : undefined,
                service:
                    selectedServiceId !== -1 ? selectedServiceId : undefined,
            },
        }
        if (organization) {
            request.params = {
                organization: organization,
            }
        }
        return request
    }

    const constructBulkEditMessage = () => {
        const messages = []
        messages.push(`You are bulk editing ${workorders.length} workorders`)

        if (addPrice !== undefined) {
            messages.push(`-Add Price: $${addPrice}`)
        }
        if (customPrice !== undefined) {
            messages.push(`-Custom Price: $${customPrice}`)
        }
        if (startDate !== null) {
            messages.push(`-Start Date: ${toMMDDYYYY(startDate)}`)
        }
        if (endDate !== null) {
            messages.push(`-End Date: ${toMMDDYYYY(endDate)}`)
        }
        if (priority !== NO_CHANGE) {
            messages.push(
                `-Priority: ${priority === NEGATIVE_CHANGE ? 'None' : 'All'}`,
            )
        }
        if (selectedVendorId !== -1) {
            messages.push(
                `-Vendor: ${
                    vendorList.find((v) => v.id === selectedVendorId)?.name
                }`,
            )
        }
        if (selectedServiceId !== -1) {
            messages.push(
                `-Service: ${serviceMap[selectedServiceId]?.name ?? ''}`,
            )
        }
        return messages
    }

    const readyToSave =
        (addPrice !== undefined ||
            customPrice !== undefined ||
            startDate !== null ||
            endDate !== null ||
            priority !== NO_CHANGE ||
            selectedVendorId !== -1 ||
            selectedServiceId !== -1) &&
        singleVendorOffersSingleService &&
        allWoVendorsOfferService &&
        singleVendorOffersAllWoServices &&
        vendorBelongsToAllApts

    const vendorSelectorMessages = [
        {
            message: '1. All work orders are Assigned',
            warning: !allUnlocked,
        },
        {
            message:
                '2. Selected Vendor offers service (If a vendor is selected)',
            warning: !singleVendorOffersSingleService,
        },
        {
            message:
                '3. All the assigned vendors for all selected workorders offer this service (If no vendor is selected)',
            warning: !allWoVendorsOfferService,
        },
    ]

    if (apartmentVendorMap) {
        vendorSelectorMessages.push({
            message:
                '4. Vendor belongs to all the workorders apartments (If a vendor is selected)',
            warning: !vendorBelongsToAllApts,
        })
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                overflowY: 'hidden',
            }}
        >
            <Container
                style={{
                    flex: 1,
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    maxHeight: 'calc(100vh - 200px)',
                    height: '1200px',
                    maxWidth: '1000px',
                    width: 'calc(100vw - 200px)',
                }}
                scrollY
            >
                <Container style={{ alignItems: 'center' }}>
                    <Selector
                        disabled={!canUpdateVendor}
                        getDisplayString={(service) => service.name}
                        label="Service"
                        data={validServices}
                        currentValue={selectedServiceId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            selectService(event.target.value as number)
                        }}
                        customStyle={{
                            formControl: {
                                margin: theme.spacing(2, 0),
                                flex: 1,
                            },
                        }}
                    />
                    <DetailedToolTip
                        headline="Can only be edited when:"
                        customStyle={{
                            container: { marginLeft: theme.spacing(2) },
                        }}
                        messages={[
                            {
                                message: '1. All work orders are Assigned',
                                warning: !allUnlocked,
                            },
                            {
                                message:
                                    '2. Selected Vendor offers service (If a vendor is selected)',
                                warning: !singleVendorOffersSingleService,
                            },
                            {
                                message:
                                    '3. All the assigned vendors for all selected workorders offer this service (If no vendor is selected)',
                                warning: !allWoVendorsOfferService,
                            },
                        ]}
                    >
                        <InfoIcon
                            fontSize="large"
                            color={
                                canUpdateVendor &&
                                singleVendorOffersSingleService &&
                                allWoVendorsOfferService
                                    ? 'action'
                                    : 'error'
                            }
                        />
                    </DetailedToolTip>
                </Container>
                <Container style={{ alignItems: 'center' }}>
                    <Selector
                        disabled={!canUpdateVendor}
                        getDisplayString={(vendor) => vendor.name}
                        label="Vendor"
                        data={vendorList.filter((v) => {
                            return (
                                v.services.find((s) => s.id === sId) !==
                                undefined
                            )
                        })}
                        currentValue={selectedVendorId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            selectVendor(event.target.value as number)
                        }}
                        customStyle={{
                            formControl: {
                                margin: theme.spacing(2, 0),
                                flex: 1,
                            },
                        }}
                    />
                    <DetailedToolTip
                        headline="Can only be edited when:"
                        customStyle={{
                            container: { marginLeft: theme.spacing(2) },
                        }}
                        messages={vendorSelectorMessages}
                    >
                        <InfoIcon
                            fontSize="large"
                            color={
                                canUpdateVendor &&
                                singleVendorOffersSingleService &&
                                singleVendorOffersAllWoServices &&
                                vendorBelongsToAllApts
                                    ? 'action'
                                    : 'error'
                            }
                        />
                    </DetailedToolTip>
                </Container>

                <Container style={{ alignItems: 'center' }}>
                    <KeyboardDatePicker
                        disabled={!allUnlocked}
                        value={startDate}
                        onChange={(date) => {
                            setStartDate(date)
                        }}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        label="Start Date"
                        style={{ margin: theme.spacing(2, 0) }}
                        fullWidth
                        clearable
                    />

                    <DetailedToolTip
                        headline="Can only be edited when:"
                        customStyle={{
                            container: { marginLeft: theme.spacing(2) },
                        }}
                        messages={[
                            {
                                message: '1. All work orders are Assigned',
                                warning: !allUnlocked,
                            },
                        ]}
                    >
                        <InfoIcon
                            fontSize="large"
                            color={allUnlocked ? 'action' : 'error'}
                        />
                    </DetailedToolTip>
                </Container>

                <KeyboardDatePicker
                    value={endDate}
                    onChange={(date) => {
                        setEndDate(date)
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="End Date"
                    style={{ margin: theme.spacing(2, 0) }}
                    fullWidth
                    clearable
                />

                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label="Add Price"
                    value={addPrice ?? ''}
                    style={{ margin: theme.spacing(2, 0) }}
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setAddPrice(undefined)
                        } else {
                            setAddPrice(Number(e.target.value))
                        }
                    }}
                />

                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label="Custom Price"
                    value={customPrice ?? ''}
                    style={{ margin: theme.spacing(2, 0) }}
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setCustomPrice(undefined)
                        } else {
                            setCustomPrice(Number(e.target.value))
                        }
                    }}
                />

                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(1, 0),
                    }}
                >
                    Edit Priority
                </span>
                <ToggleButtonGroup
                    value={priority}
                    onChange={(_, priority) => {
                        setPriority(priority)
                    }}
                    aria-label="Priority Select"
                    exclusive
                >
                    <ToggleButton
                        value={NEGATIVE_CHANGE}
                        aria-label="not-priority"
                    >
                        None
                    </ToggleButton>
                    <ToggleButton value={NO_CHANGE} aria-label="no-change">
                        No Changes
                    </ToggleButton>
                    <ToggleButton value={POSITIVE_CHANGE} aria-label="priority">
                        All
                    </ToggleButton>
                </ToggleButtonGroup>
            </Container>

            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        minWidth: 100,
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1),
                        minWidth: 100,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    disabled={!readyToSave}
                    onClick={() => {
                        setOpenDialog(true)
                    }}
                >
                    Update ({workorders.length} Workorders)
                </Button>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Bulk Update Workorders
                </DialogTitle>
                <DialogContent>
                    {constructBulkEditMessage().map((text, index) => (
                        <DialogContentText key={index}>
                            {text}
                        </DialogContentText>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const request = createBulkUpdateBody()

                            bulkUpdateWorkorders(request).then(() => {
                                toast.success(
                                    `${workorders.length} Workorders updated!`,
                                )
                                props.handleClose()
                            })
                        }}
                        disabled={loading}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

const NEGATIVE_CHANGE = 0
const POSITIVE_CHANGE = 1
const NO_CHANGE = 2

type ToggleSelection =
    | typeof NEGATIVE_CHANGE
    | typeof NO_CHANGE
    | typeof POSITIVE_CHANGE
