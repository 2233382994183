import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Container } from '../../components'
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    TableContainer,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    DialogContentText,
    CircularProgress,
    useTheme,
    Modal,
    Paper,
    Slide,
    InputAdornment,
} from '@material-ui/core'
import HttpIcon from '@material-ui/icons/Http'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { useLeaseAudit } from '../../hooks/documentAudit'
import { useHistory } from 'react-router-dom'
import { SearchField } from '../../components/SearchField'
import { getLeaseAuditStatus } from '../../models/DocumentAudit/services'
import NumberFormat from 'react-number-format'

export const LeaseAuditList = () => {
    const { leaseAudits, createLeaseAudit, loading } = useLeaseAudit()

    const history = useHistory()
    const [searchValue, setSearchValue] = useState('')
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [rentRollLink, setRentRollLink] = useState('')
    const [leaseLink, setLeaseLink] = useState('')
    const [leaseLinkError, setLeaseLinkError] = useState('')
    const [rentRollLinkError, setRentRollLinkError] = useState('')

    const theme = useTheme()

    const validateUrl = (url: string) => {
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
        return urlPattern.test(url)
    }

    const handleLeaseLinkChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        linkType: 'lease' | 'rentRoll',
    ) => {
        const value = e.target.value

        let setterMethod: (value: string) => void
        let errorSetterMethod: (value: string) => void

        if (linkType === 'lease') {
            setterMethod = setLeaseLink
            errorSetterMethod = setLeaseLinkError
        } else {
            setterMethod = setRentRollLink
            errorSetterMethod = setRentRollLinkError
        }

        setterMethod(value)
        if (value && !validateUrl(value)) {
            errorSetterMethod('Please enter a valid URL')
        } else {
            errorSetterMethod('')
        }
    }

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container style={{ padding: theme.spacing(1) }}>
                <SearchField
                    placeholder="Search Lease Audits"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />

                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                        setName('')
                        setOpen(true)
                    }}
                    color="primary"
                    disabled={loading.fetchLeaseAudit}
                >
                    Request Lease Audit
                </Button>
            </Container>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Document Count</TableCell>
                            <TableCell>Page Count</TableCell>
                            <TableCell>Pages / Document</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaseAudits
                            .filter((audit) =>
                                audit?.name
                                    .toLowerCase()
                                    .includes(searchValue.toLocaleLowerCase()),
                            )
                            .map((audit) => {
                                if (audit === null) {
                                    return null
                                }

                                let pagePerDocLabel = 'N/A'
                                if (audit.document_count > 0) {
                                    pagePerDocLabel = (
                                        audit.page_count / audit.document_count
                                    ).toFixed(2)
                                }

                                const createdDate = new Date(audit.created_date)

                                return (
                                    <TableRow key={audit.id}>
                                        <TableCell>{audit.name}</TableCell>
                                        <TableCell>
                                            {getLeaseAuditStatus(audit.status)}
                                        </TableCell>
                                        <TableCell>
                                            {audit.created_by?.name}
                                        </TableCell>
                                        <TableCell>
                                            {createdDate.toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={audit.document_count}
                                                displayType="text"
                                                thousandSeparator={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={audit.page_count}
                                                displayType="text"
                                                thousandSeparator={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {typeof pagePerDocLabel ===
                                            'number' ? (
                                                <NumberFormat
                                                    value={pagePerDocLabel}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            ) : (
                                                pagePerDocLabel
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() =>
                                                        history.push(
                                                            `/lease-audit/${audit.id}`,
                                                        )
                                                    }
                                                >
                                                    <ArrowRightAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            {(loading.fetchLeaseAudit || loading.createLeaseAudit) && (
                <CircularProgress />
            )}

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={open}>
                    <Paper>
                        <Container
                            style={{
                                flexDirection: 'column',
                                padding: theme.spacing(2),
                                backgroundColor: theme.palette.background.paper,
                                width: 800,
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h4,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.text.primary,
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                Request Lease Audit
                            </span>
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Lease Audit Name"
                                style={{
                                    marginBottom: theme.spacing(2),
                                    ...theme.typography.body1,
                                }}
                                required
                                error={name === ''}
                                helperText={
                                    name === '' ? 'Name is required' : ''
                                }
                            />
                            <TextField
                                value={rentRollLink}
                                onChange={(e) =>
                                    handleLeaseLinkChange(e, 'rentRoll')
                                }
                                placeholder="Link To Rent Roll"
                                error={!!rentRollLinkError}
                                helperText={rentRollLinkError}
                                style={{
                                    marginBottom: theme.spacing(2),
                                    ...theme.typography.body1,
                                }}
                                required
                            />
                            <TextField
                                value={leaseLink}
                                onChange={(e) =>
                                    handleLeaseLinkChange(e, 'lease')
                                }
                                placeholder="Link To Leases"
                                error={!!leaseLinkError}
                                helperText={leaseLinkError}
                                style={{
                                    marginBottom: theme.spacing(2),
                                    ...theme.typography.body1,
                                }}
                                required
                            />

                            <Container
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: theme.spacing(2),
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ textTransform: 'none' }}
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading.createLeaseAudit}
                                    onClick={() => {
                                        if (
                                            !validateUrl(leaseLink) ||
                                            !validateUrl(rentRollLink)
                                        ) {
                                            return
                                        }

                                        if (!name) {
                                            return
                                        }

                                        createLeaseAudit(
                                            name,
                                            leaseLink,
                                            rentRollLink,
                                        )
                                        setOpen(false)
                                    }}
                                    style={{ textTransform: 'none' }}
                                >
                                    Submit
                                </Button>
                            </Container>
                        </Container>
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
