import React from 'react'

import classes from './TemplateServiceChartRow.module.css'

import CloseIcon from '@material-ui/icons/Close'
import TimerOffIcon from '@material-ui/icons/TimerOff'

import { MS_PER_DAY, setEndOfDay, setMidnight } from '../../../../helpers'
import { TemplateService, TemplateServiceVendor } from '../../../../models'
import { Container } from '../../../Container'
import { DAY_CELL_W, MARGIN, VENDOR_BAR_H } from '../../constants'
import {
    CalendarGridController,
    CursorState,
    useDragDateSelector,
} from '../../hooks'
import { BaseProps } from '../../types'
import { DragIcon } from '../DragIcon'

interface Props extends BaseProps {
    templateVendor: TemplateServiceVendor
    templateService: TemplateService
    gridState: CalendarGridController
    margin: number
    color: string
}

export const TemplateVendorChartBar = (props: Props) => {
    const {
        templateService,
        gridState,
        templateVendor,
        scheduleController,
    } = props

    const { handleMouseDown, dateChangeRenderState } = useDragDateSelector(
        gridState,
        (startPoint, selectedDate) => {
            // Update the UI state early
            scheduleController.setTemplateVendorState(
                templateService.id,
                templateVendor.id,
                { ...templateVendor, [startPoint]: selectedDate.toISOString() },
            )
            scheduleController.updateTemplateServiceVendor(
                templateService.id,
                templateVendor.id,
                {
                    [startPoint]: selectedDate.toISOString(),
                },
            )
        },
    )

    const vendorStartDate = new Date(templateVendor.start_date)
    const vendorEndDate = new Date(templateVendor.end_date)
    setMidnight(vendorStartDate)
    setEndOfDay(vendorEndDate)

    const vendorStartMs = vendorStartDate.getTime()
    const vendorEndMs = vendorEndDate.getTime()

    const startGridPos = gridState.getDateGridPosition(vendorStartDate)
    const endGridPos = gridState.getDateGridPosition(vendorEndDate)

    const projectEndDate = gridState.dateInfo.projectEnd
    const projectEndMs = projectEndDate.getTime()

    const displayEndMs =
        projectEndMs +
        MS_PER_DAY * gridState.gridPositionInfo.numStartPaddingDays

    const displayEndDate = new Date(displayEndMs)

    const lastGridPos = gridState.getDateGridPosition(displayEndDate)

    const dayOffMap: { [dateMs: number]: 'vendor' | 'global' } = {}

    scheduleController.schedule?.day_off_list.forEach((dayOff) => {
        const dayOffDate = new Date(dayOff.date)
        const dayOffMs = dayOffDate.getTime()
        dayOffMap[dayOffMs] = 'global'
    })

    templateVendor.day_off_list.forEach((dayOff) => {
        if (dayOff.template_service_vendor === templateVendor.id) {
            const dayOffDate = new Date(dayOff.date)
            const dayOffMs = dayOffDate.getTime()
            dayOffMap[dayOffMs] = 'vendor'
        }
    })

    return (
        <Container
            className={classes.VendorContainer}
            style={{ height: '100%' }}
        >
            {/* Start Spacers */}

            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    boxSizing: 'border-box',
                }}
            >
                <DayBlocks
                    {...props}
                    startGridPos={0}
                    endGridPos={startGridPos - 1}
                    dateChangeRenderState={dateChangeRenderState}
                    vendorStartMs={vendorStartMs}
                    vendorEndMs={vendorEndMs}
                    bgColor="transparent"
                />
            </div>

            {/* Work Days */}
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    boxSizing: 'border-box',
                    position: 'relative',
                }}
            >
                <DragIcon
                    {...props}
                    className={classes.Icon}
                    color={templateService.service.color}
                    size={VENDOR_BAR_H / 2}
                    left={8}
                    onMouseDown={() => {
                        handleMouseDown(
                            vendorStartMs,
                            vendorStartMs,
                            'start_date',
                        )
                    }}
                />
                <DragIcon
                    {...props}
                    className={classes.Icon}
                    color={templateService.service.color}
                    size={VENDOR_BAR_H / 2}
                    right={8}
                    onMouseDown={() => {
                        handleMouseDown(
                            vendorStartMs,
                            vendorStartMs,
                            'end_date',
                        )
                    }}
                />

                <DayBlocks
                    {...props}
                    startGridPos={startGridPos}
                    endGridPos={endGridPos}
                    dateChangeRenderState={dateChangeRenderState}
                    vendorStartMs={vendorStartMs}
                    vendorEndMs={vendorEndMs}
                    bgColor={props.color}
                    dayOffMap={dayOffMap}
                />
            </div>

            {/* End Spacers */}
            <div
                style={{
                    height: '100%',
                    display: 'flex',

                    boxSizing: 'border-box',
                }}
            >
                <DayBlocks
                    {...props}
                    startGridPos={endGridPos + 1}
                    endGridPos={lastGridPos - 1}
                    dateChangeRenderState={dateChangeRenderState}
                    vendorStartMs={vendorStartMs}
                    vendorEndMs={vendorEndMs}
                    bgColor="transparent"
                />
            </div>
        </Container>
    )
}

interface DayBlocksProps extends Props {
    startGridPos: number
    endGridPos: number
    dateChangeRenderState: CursorState | null
    vendorStartMs: number
    vendorEndMs: number
    bgColor: string
    dayOffMap?: DayOffMap
}

const DayBlocks = (props: DayBlocksProps) => {
    const {
        startGridPos,
        endGridPos,
        gridState,
        dateChangeRenderState,
        vendorEndMs,
        vendorStartMs,
        color,
        templateVendor,
        templateService,
        dayOffMap,
    } = props

    const dayBlocks: JSX.Element[] = []

    for (
        let curGridPos = startGridPos;
        curGridPos <= endGridPos;
        curGridPos += 1
    ) {
        let leftRad = 0
        let rightRad = 0

        if (curGridPos === startGridPos) {
            leftRad = props.theme.shape.borderRadius
        }

        if (curGridPos === endGridPos) {
            rightRad = props.theme.shape.borderRadius
        }

        const curDate = gridState.convertGridPositionToDate(curGridPos)
        const curDateMs = curDate.getTime()

        let dayOffMarker: JSX.Element | null = null

        if (dayOffMap) {
            const dayOffVal = dayOffMap[curDate.getTime()]

            if (dayOffVal) {
                dayOffMarker = (
                    <Container
                        style={{
                            backgroundColor: 'rgba(30,30,30,0.2)',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {dayOffVal === 'global' && (
                            <CloseIcon fontSize="large" />
                        )}
                        {dayOffVal === 'vendor' && (
                            <TimerOffIcon fontSize="large" />
                        )}
                    </Container>
                )
            }
        }

        const shiftedStyle = getShiftedStyle(
            dateChangeRenderState,
            vendorStartMs,
            vendorEndMs,
            curDateMs,
            color,
        )

        dayBlocks.push(
            <div
                key={`VND-${templateVendor.id}}-SRV-${templateService.id}-DAY-${curDateMs}`}
                style={{
                    cursor: 'pointer',
                    marginBottom: MARGIN,
                    marginTop: MARGIN,
                    width: DAY_CELL_W,
                    backgroundColor: props.bgColor,
                    boxSizing: 'border-box',
                    borderTopLeftRadius: leftRad,
                    borderBottomLeftRadius: leftRad,
                    borderTopRightRadius: rightRad,
                    borderBottomRightRadius: rightRad,
                    ...shiftedStyle,
                }}
            >
                {dayOffMarker}
            </div>,
        )
    }

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                boxSizing: 'border-box',
            }}
        >
            {dayBlocks}
        </div>
    )
}

const getShiftedStyle = (
    cursorState: CursorState | null,
    vendorServiceStartMs: number,
    vendorEndMs: number,
    blockMidnightMs: number,
    serviceColor: string,
): React.CSSProperties => {
    if (cursorState === null) return {}

    if (cursorState.startPoint === 'start_date') {
        // Which direction is the cursor moving relative to the start point?
        if (cursorState.hoverMidnightMs > vendorServiceStartMs) {
            // User is moving the cursor into the future relative to the start date

            // Check to see if this block should be displayed to be removed
            if (
                blockMidnightMs >= cursorState.startMidnightMs &&
                blockMidnightMs < cursorState.hoverMidnightMs
            ) {
                return {
                    backgroundColor: serviceColor,
                    opacity: 0.5,
                }
            }
        }

        if (cursorState.hoverMidnightMs < vendorServiceStartMs) {
            // User is moving the cursor into the past relative to the start date
            if (
                blockMidnightMs < cursorState.startMidnightMs &&
                blockMidnightMs >= cursorState.hoverMidnightMs
            ) {
                return {
                    backgroundColor: serviceColor,
                    opacity: 0.5,
                }
            }
        }
    }

    if (cursorState.startPoint === 'end_date') {
        // Which direction is the cursor moving relative to the start point?
        if (cursorState.hoverMidnightMs < vendorEndMs) {
            // User is moving the end date to an earlier point in time
            // Check to see if this block should be displayed to be removed
            if (
                cursorState.hoverMidnightMs < blockMidnightMs &&
                blockMidnightMs < vendorEndMs
            ) {
                return {
                    backgroundColor: serviceColor,
                    opacity: 0.5,
                }
            }
        }

        if (cursorState.hoverMidnightMs > vendorEndMs) {
            // User is moving the end date to a later point
            // Check to see if this block should be displayed as adding
            if (
                blockMidnightMs > vendorEndMs &&
                blockMidnightMs <= cursorState.hoverMidnightMs
            ) {
                return {
                    backgroundColor: serviceColor,
                    opacity: 0.5,
                }
            }
        }
    }

    return {}
}

type DayOffMap = { [dateMs: number]: 'vendor' | 'global' }
