import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { axiosInstance } from '../helpers'
import { Damage, DamageStatus, DamageTransaction, Lease } from '../models'

export interface DamageRequestFilters {
    statuses?: string
    excludeDenied?: boolean
    unitIds?: string
    issues?: boolean
    inspection?: number
    sort_by_created_date?: boolean
    created_before?: string
    created_after?: string
    search?: string
    hasAttachment?: boolean
    cost?: number
    cost_operator?: string
}

export const usePaginatedDamageList = (
    page_size: number,
    onSuccess?: (response: AxiosResponse<any>, damageList: Damage[]) => void,
    onError?: (e: any) => void,
    cleanUp?: () => void,
) => {
    const [loadingDamages, setLoadingDamages] = useState(false)

    const [damageList, setDamageList] = useState<Damage[]>()
    const [page, setPage] = useState(1)
    const [totalDamageCount, setTotalDamageCount] = useState(0)

    const handleCleanUp = () => {
        cleanUp && cleanUp()
        setTimeout(() => setLoadingDamages(false), 500)
    }

    const getDamageList = (page: number, filters?: DamageRequestFilters) => {
        setLoadingDamages(true)
        axiosInstance
            .get('lease/damage/', {
                params: {
                    transactions_created: false,
                    limit: page_size,
                    offset: (page - 1) * page_size,
                    statuses: filters?.statuses,
                    unitIds: filters?.unitIds,
                    issues: filters?.issues,
                    inspection: filters?.inspection,
                    sort_by_created_date: filters?.sort_by_created_date,
                    created_before: filters?.created_before,
                    created_after: filters?.created_after,
                    search: filters?.search,
                    has_attachment: filters?.hasAttachment,
                    cost: filters?.cost,
                    cost_operator: filters?.cost_operator,
                },
            })
            .then((res) => {
                onSuccess && onSuccess(res, res.data.results)
                setDamageList(res.data.results)
                setTotalDamageCount(res.data.count)
            })
            .catch(onError)
            .finally(handleCleanUp)
    }

    const updateDamageStatus = (damages: Damage[], status: DamageStatus) => {
        let shouldAlert = false

        if (status === DamageStatus.APPROVED) {
            damages.forEach((dmg) => {
                shouldAlert = dmg.damage_transactions.length === 0
            })
        }

        if (shouldAlert) {
            if (
                !confirm(
                    'One or more of your selected damages do not have any AR Transactions.  Damages without transactions will not be able to sync.',
                )
            ) {
                return
            }
        }

        setLoadingDamages(true)
        axiosInstance
            .post('lease/damage/update_status/', {
                damages: damages.map((d) => d.id),
                status: status,
            })
            .then((res) => {
                updateDamageState(res.data)
                onSuccess && onSuccess(res, damageList ?? [])
            })
            .catch(onError)
            .finally(handleCleanUp)
    }

    const updateDamage = async (
        damage: Damage,
        request: UpdateDamageRequest,
    ) => {
        try {
            const res = await axiosInstance.patch(
                `lease/damage/${damage.id}/`,
                request,
            )
            const updatedDamage: Damage = res.data
            updateDamageState([updatedDamage])
            return updatedDamage
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const removeDamageTransaction = (
        transaction: DamageTransaction,
        damage: Damage,
    ) => {
        setLoadingDamages(true)
        axiosInstance
            .post(`lease/damage/${damage.id}/remove_transaction/`, {
                transaction: transaction.id,
            })
            .then((res) => {
                updateDamageState([
                    {
                        ...damage,
                        damage_transactions: damage.damage_transactions.filter(
                            (dt) => dt.id !== transaction.id,
                        ),
                    },
                ])
                onSuccess && onSuccess(res, damageList ?? [])
            })
            .catch(onError)
            .finally(handleCleanUp)
    }

    const addDamageTransaction = (lease: Lease, damage: Damage) => {
        setLoadingDamages(true)
        axiosInstance
            .post(`lease/damage/${damage.id}/add_transaction/`, {
                tenant: lease.id,
            })
            .then((res) => {
                updateDamageState([res.data])
                onSuccess && onSuccess(res, damageList ?? [])
            })
            .catch(onError)
            .finally(handleCleanUp)
    }

    const updateDamageState = (updatedDamages: Damage[]) => {
        if (damageList === undefined) {
            return
        }

        const newDamageList: Damage[] = []

        let resIdx = 0
        for (let dmgIdx = 0; dmgIdx < damageList.length; dmgIdx++) {
            const currentDamage = damageList[dmgIdx]

            if (resIdx >= updatedDamages.length) {
                // If we have exhausted all of the udpated damages
                // push the remaining current damages
                newDamageList.push(currentDamage)
                continue
            }

            const updatedDamage = updatedDamages[resIdx]

            if (currentDamage.id === updatedDamage.id) {
                // The current damage was updated,
                // use the updated version and increment the response index
                newDamageList.push(updatedDamage)
                resIdx += 1
            } else {
                // This damage was not updated,
                // keep it and move on
                newDamageList.push(currentDamage)
            }
        }

        setDamageList(newDamageList)
    }

    return {
        damageList,
        getDamageList,
        loadingDamages,
        currentPage: page,
        setPage,
        totalDamageCount,
        updateDamageStatus,
        removeDamageTransaction,
        addDamageTransaction,
        updateDamageState,
        updateDamage,
    }
}

export interface UpdateDamageRequest {
    damage_config?: number
    cost?: string
    amount?: number
}
