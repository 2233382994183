import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Button, Theme } from '@material-ui/core'

import {
    DocumentOccurance,
    PageWithMarkdown,
} from '../../../models/DocumentAudit/types'
import { Container } from '../../../components'
import { LeaseAuditController } from '../../../hooks/documentAudit'
import { MODAL_H, HEADER_HEIGHT } from './constants'

interface MarkdownTabProps {
    leaseAuditController: LeaseAuditController
    documentOccurance: DocumentOccurance
    theme: Theme
}

export const MarkdownTab = ({
    leaseAuditController,
    documentOccurance,
    theme,
}: MarkdownTabProps) => {
    const { documentMarkdown, fetchDocumentMarkdown } = leaseAuditController

    const [selectedPageIndex, setSelectedPageIndex] = useState(0)

    useEffect(() => {
        fetchDocumentMarkdown(documentOccurance.id)
    }, [documentOccurance.id])

    const selectedPage = documentMarkdown?.[selectedPageIndex]
    const selectedMarkdown = selectedPage?.markdown_text?.markdown_text

    return (
        <Container
            style={{
                display: 'flex',
                minHeight: MODAL_H - HEADER_HEIGHT,
                maxHeight: MODAL_H - HEADER_HEIGHT,
            }}
        >
            {/* Preview Column */}
            <Container
                style={{
                    flexDirection: 'column',
                    overflow: 'auto',
                    borderRight: `1px solid ${theme.palette.divider}`,
                }}
            >
                {documentMarkdown?.map((page, index) => (
                    <PreviewCard
                        page={page}
                        index={index}
                        key={page.id}
                        selected={selectedPageIndex === index}
                        onClick={() => setSelectedPageIndex(index)}
                        theme={theme}
                    />
                ))}
            </Container>

            {/* Markdown Column */}
            <Container
                style={{
                    flex: 1,
                    padding: theme.spacing(2),
                    overflow: 'auto',
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightRegular,
                        color: theme.palette.text.primary,
                    }}
                >
                    {selectedMarkdown ?? 'No Markdown Available'}
                </span>
            </Container>
        </Container>
    )
}

interface PreviewCardProps {
    page: PageWithMarkdown
    index: number
    onClick: () => void
    selected: boolean
    theme: Theme
}

const useStyles = makeStyles((theme) => ({
    previewCard: {
        display: 'flex',
        flexDirection: 'column',
        border: (props: { selected: boolean }) =>
            `1px solid ${
                props.selected
                    ? theme.palette.primary.main
                    : theme.palette.divider
            }`,
        borderRadius: 8,
        backgroundColor: 'white',
        padding: theme.spacing(2),
        cursor: 'pointer',
        margin: theme.spacing(1),
        width: 250,
        transition: 'border-color 0.2s ease',
        '&:hover': {
            borderColor: theme.palette.primary.light,
        },
    },
    pageNumber: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(0.5),
    },
    previewText: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        lineHeight: 1.4,
    },
}))

const PreviewCard = ({
    page,
    index,
    onClick,
    selected,
    theme,
}: PreviewCardProps) => {
    const classes = useStyles({ selected })
    const pageNumber = index + 1

    let markdownText = page.markdown_text?.markdown_text
    if (!markdownText) {
        markdownText = 'No Markdown Available'
    }

    const truncatedText =
        markdownText.length > 100
            ? `${markdownText.slice(0, 100)}...`
            : markdownText

    return (
        <Container
            className={classes.previewCard}
            onClick={onClick}
            style={{
                padding: theme.spacing(2),
            }}
        >
            <span className={classes.pageNumber}>{`${pageNumber}.`}</span>

            <span className={classes.previewText}>{truncatedText}</span>
        </Container>
    )
}
